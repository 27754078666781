app.controller('ContratosCtrl', ['$scope', '$location', '$http', 'TraspasoLeadContrato', 'serviciosApi', '$rootScope', '$state', 'jwtHelper', '$mdEditDialog', '$mdDialog', function($scope, $location, $http, TraspasoLeadContrato, serviciosApi, $rootScope, $state, jwtHelper, $mdEditDialog, $mdDialog, $window) {
    'use strict';

    var IdAgente = localStorage.getItem('idAgente');
    var IdUsuario = localStorage.getItem('idUsuario');
    var Logueado = localStorage.getItem('logueado');
    var IdEmpresa = localStorage.getItem('idEmpresa');

    var VerEnergia;
    var VerGas;
    $scope.isClickedActualizarEstado=false;
    $scope.AgenteFinal=AgenteFinal;
    $scope.MostrarPotenciaMaxima=MostrarPotenciaMaxima;
    $scope.MostrarBotonDescargaExcelContratos = MostrarBotonDescargaExcelContratos;
    $scope.MostrarBotonDescargaMet = MostrarBotonDescargaMet;
    $scope.mostrarSiempreFechaAlta = mostrarSiempreFechaAlta;

    $scope.MostrarContratosComisionAnualEstimada = MostrarContratosComisionAnualEstimada;

    $scope.UrlApiCorrecionImprimirContratoBien = UrlApiCorrecionImprimirContratoBien;

    $scope.abrirContratoVentanaNueva = abrirContratoVentanaNueva;
	$scope.SimplificacionesTarifaAntiguaEleia = SimplificacionesTarifaAntiguaEleia;

	$scope.IdMarca = IdMarca;
	$scope.FiltrarListadoContratoPorIdMarca = FiltrarListadoContratoPorIdMarca;
    $scope.RenovacionContratosMesLuxam = RenovacionContratosMesLuxam;

    $scope.contratosPendientesDeActualizarEstado = [];

    $scope.MostrarReferenciaExterna1y2 = MostrarReferenciaExterna1y2;

    $scope.VerEnergia = localStorage.getItem('idEmpresaEnergia');
    $scope.VerGas = localStorage.getItem('idEmpresaGas');

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') == 'true') {
        $scope.VerEnergia = true;
        $scope.VerGas = false;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') == 'true') {
        $scope.VerGas = true;
        $scope.VerEnergia = false;
    }
    $scope.ModalImportacionExcelMasiva = ModalImportacionExcelMasiva;
    $scope.ModalImportacionExcelMasivaFormatoLumar = ModalImportacionExcelMasivaFormatoLumar;

    $scope.showModalMasivoFormatoLumar = function() {
        $mdDialog.show({
            templateUrl: 'myModalContentLumar.html',
            clickOutsideToClose: true,
            controller: 'ContratosCtrl'
                // 	controller: 'ContratosCtrl',
                // 	templateUrl: 'templates/contrato-importacion-masiva.html',
                // 	clickOutsideToClose: true,

            // });
        })
    }
	$scope.limpiaDolar = function(string) {
		if(string != undefined && string != ''){
			var aux = string.replace(/[^a-zA-Z 0-9.()]+/g,"");
			return aux;
		} else {
			return string;
		}
	}
    $scope.enviarContratoEmail = function(data) { // TODO: Aún no funciona
        //console.log(data);
        $http({
            method: "GET",
            url: urlApi + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + data.contrato.IdContrato,
            responseType: "arraybuffer",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;

            var file = new Blob([respuesta], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.setAttribute("download", "Any name");
            a.click();
            //window.open(fileURL);
        });
    }

    $scope.fileNameChangedFormatoLumar = function(ele) {
        var file = document.getElementById('file-upload-contrato').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            var ficheroSplit = reader.result.split(',');
            var ficheroExcel = ele.files[0].name;
            var extensionFichero = ficheroExcel.split('.');
            var datosApi = { "DocExcel": ficheroSplit[1], "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario, "Extension": "." + extensionFichero[1] };
            $http({
                method: "POST",
                url: urlApi + "ContratoPotencial/ImportExcel",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador')),
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(datosApi)
            }).then(function(response) {
                var respuesta = response.data.Contratos;

                $scope.errores = [];
                $scope.insercionesCorrectas = 0;
                $scope.insercionesErroneas = 0;
                for (var i = 0; i < respuesta.length; i++) {
                    var cups = respuesta[i].CodigoCUPS;
                    for (var e = 0; e < respuesta[i].Incidencias.length; e++) {
                        var error = respuesta[i].Incidencias[e].Mensaje;
                        if (error) {
                            $scope.insercionesErroneas = $scope.insercionesErroneas + 1;
                        } else {
                            $scope.insercionesCorrectas = $scope.insercionesCorrectas + 1
                        }
                        $scope.errores.push({
                            "CUPS": cups,
                            "Error": error
                        });
                    }
                }
                //$state.go("myModalContent.html", {}, { reload: true });
            });
        };
        reader.onerror = function(error) {
            console.log('Error: ', error);
        };
    }

    $scope.showModalMasivo = function() {
        $mdDialog.show({
            templateUrl: 'myModalContent.html',
            clickOutsideToClose: true,
            controller: 'ContratosCtrl'
                // 	controller: 'ContratosCtrl',
                // 	templateUrl: 'templates/contrato-importacion-masiva.html',
                // 	clickOutsideToClose: true,

            // });
        })
    }

    $scope.click = function(){
        $scope.isClickedActualizarEstado=true;

    }

    $scope.fileNameChanged = function(ele) {
        var file = document.getElementById('file-upload-contrato').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            var ficheroSplit = reader.result.split(',');
            var ficheroExcel = ele.files[0].name;
            var extensionFichero = ficheroExcel.split('.');
            var datosApi = { "DocExcel": ficheroSplit[1], "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario, "Extension": "." + extensionFichero[1] };
            $http({
                method: "POST",
                url: urlApi + "ContratoPotencial/ImportExcel",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador')),
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(datosApi)
            }).then(function(response) {
                var respuesta = response.data.Contratos;

                $scope.errores = [];
                $scope.insercionesCorrectas = 0;
                $scope.insercionesErroneas = 0;
                for (var i = 0; i < respuesta.length; i++) {
                    var cups = respuesta[i].CodigoCUPS;
                    for (var e = 0; e < respuesta[i].Incidencias.length; e++) {
                        var error = respuesta[i].Incidencias[e].Mensaje;
                        if (error) {
                            $scope.insercionesErroneas = $scope.insercionesErroneas + 1;
                        } else {
                            $scope.insercionesCorrectas = $scope.insercionesCorrectas + 1
                        }
                        $scope.errores.push({
                            "CUPS": cups,
                            "Error": error
                        });
                    }
                }
                //$state.go("myModalContent.html", {}, { reload: true });
            });
        };
        reader.onerror = function(error) {
            console.log('Error: ', error);
        };
    }

    $scope.actualizarEntradas= function(){
        location.reload();
    }

    $scope.MostrarContratosConsumoAnualEstimado = MostrarContratosConsumoAnualEstimado;
    $scope.MostrarContratosFacturas = MostrarContratosFacturas;
    $scope.MostrarContratosPDF = MostrarContratosPDF;
    $scope.MostrarContratosIdAgente = MostrarContratosIdAgente;
    $scope.MostrarContratosIdSubagente = MostrarContratosIdSubagente;
    $scope.MostrarContratosTarifaGrupo = MostrarContratosTarifaGrupo;
    $scope.MostrarContratosContrato = MostrarContratosContrato;
    $scope.MostrarContratosContratoDetalle = MostrarContratosContratoDetalle;
    $scope.MostrarContratosSituacion = MostrarContratosSituacion;
    $scope.MostrarContratosSituacionWeb = MostrarContratosSituacionWeb;
    $scope.SituacionCambio1 = SituacionCambio1;
    $scope.SituacionCambio1a = SituacionCambio1a;
    $scope.SituacionCambio2 = SituacionCambio2;
    $scope.SituacionCambio2a = SituacionCambio2a;
    $scope.SituacionCambio3 = SituacionCambio3;
    $scope.SituacionCambio3a = SituacionCambio3a;

    $scope.MostrarContratoBotonAltaElectricidad = MostrarContratoBotonAltaElectricidad;
    $scope.MostrarContratoBotonAltaGas = MostrarContratoBotonAltaGas;

    $scope.MostrarContratosDesplegableSituacion = MostrarContratosDesplegableSituacion;

    $scope.MostrarContratosNombre = MostrarContratosNombre;
    $scope.MostrarContratosDenominacion = MostrarContratosDenominacion;
    $scope.MostrarContratosRazonSocial = MostrarContratosRazonSocial;
    $scope.MostrarContratosNombreIndependiente = MostrarContratosNombreIndependiente;
    $scope.MostrarContratosApellidoUno = MostrarContratosApellidoUno;
    $scope.MostrarContratosApellidoDos = MostrarContratosApellidoDos;
    $scope.MostrarContratosCUPS = MostrarContratosCUPS;
    $scope.MostrarContratosDireccionCUPS = MostrarContratosDireccionCUPS;
    $scope.MostrarContratosCiudadCUPS = MostrarContratosCiudadCUPS;
    $scope.MostrarContratosFechaContrato = MostrarListadoContratosColumnaFechaContrato;
    $scope.MostrarContratosFechaPActivacion = MostrarListadoContratosColumnaFechaPActivacion;
    $scope.MostrarContratosFechaAlta = MostrarContratosFechaAlta;
    $scope.MostrarContratosFechaBaja = MostrarContratosFechaBaja;
    $scope.MostrarContratosFechaVto = MostrarContratosFechaVto;
    $scope.MostrarContratosRenovacion = MostrarContratosRenovacion;
    $scope.MostrarContratosPotencia = MostrarContratosPotencia;
    $scope.MostrarContratosCaudal = MostrarContratosCaudal;
    $scope.MostrarContratosTarifa = MostrarContratosTarifa;
    $scope.MostrarContratosDeuda = MostrarContratosDeuda;
    $scope.MostrarContratosValorCO = MostrarContratosValorCO;
    $scope.MostrarContratosDniCif = MostrarContratosDniCif;
    $scope.MostrarContratosEnlaceDniCif = MostrarContratosEnlaceDniCif;
    $scope.MostrarContratosCategoriaCliente = MostrarContratosCategoriaCliente;


    $scope.MostrarContratosEmail = MostrarContratosEmail;
    $scope.MostrarContratosTelefono = MostrarContratosTelefono;
    $scope.MostrarContratosMovil = MostrarContratosMovil;

    $scope.MostrarContratosAgenteFinal = MostrarContratosAgenteFinal;


    $scope.MostrarContratosEnvioEmail = MostrarContratosEnvioEmail;


    $scope.TraspasoLeadContrato = TraspasoLeadContrato;

    $scope.AvisosCertificados = AvisosCertificados;
    $scope.MostrarContratosPorMes = MostrarContratosPorMes;
	$scope.MostrarContratosProximaRenovacion = MostrarContratosProximaRenovacion;

    $scope.MostrarContratosNombreParticionado = MostrarContratosNombreParticionado;
    $scope.MostrarContratosSituacionWeb = MostrarContratosSituacionWeb;
    $scope.camposAdicionalesListadoContratoOvo = camposAdicionalesListadoContratoOvo;
    $scope.MostrarContratosCPCUPS = camposAdicionalesListadoContratoOvo;
    $scope.MostrarContratosFechaContratoNueva = camposAdicionalesListadoContratoOvo;
	$scope.CambioAgenteXCanalEleia = CambioAgenteXCanalEleia;
	$scope.CambioTextoAgenteXCanalEleia = ($scope.CambioAgenteXCanalEleia) ? "Canal": "Agente";
    $scope.MostrarOrdenacionCPCUPS = '';
    $scope.MostrarOrdenacionFechaContratoNueva = '';


    //filtrado por agente cuando viene de la pantalla comerciales
    if ($rootScope.filtradoAgente && $rootScope.filtradoAgente != undefined) {
        var nombreAgenteComercial = $rootScope.filtradoAgente;
        $scope.search_NombreAgente = nombreAgenteComercial;
        $rootScope.filtradoAgente = ''; //limpiamos el filtro para que no se quede guardado el nombre del agente al cambiar de pantallas.
    }

    //filtrado por subagente cuando viene de la pantalla comerciales
    if ($rootScope.filtradoSubagente && $rootScope.filtradoSubagente != undefined) {
        var nombreSubagenteComercial = $rootScope.filtradoSubagente;
        $scope.search_NombreSubagente = nombreSubagenteComercial;
        $rootScope.filtradoSubagente = ''; //limpiamos el filtro
    }

    if (MostrarContratosDesplegableSituacion) {

        $http({
            method: "GET",
            url: urlApi + "Contrato/GetDTOAllContratosSituaciones/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            //VALIDACION CON LA PALABRA SECRETA DE LA API
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
                    //verifyAt: IntDate.get('20150601000000Z')
            });

            var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.situacionesContrato = playloadJson;
                var situacionesConFechaAlta = [];
                $scope.situacionesContrato.forEach(function(obj,key) {
                    if(obj.IsActivo || obj.IsEstadoBaja) {
                        situacionesConFechaAlta.push(obj.TextoSituacion);
                    }
                });
                $scope.listaSituacionesFechaAlta = situacionesConFechaAlta;
                //console.log($scope.situacionesContrato);
            } else {
                $scope.situacionesContrato = "";
                $scope.listaSituacionesFechaAlta = "";
            }

        });
    }

    $scope.contrato = function(datos) {
        localStorage.setItem('tipoAlta', btoa(datos));
        $location.url("/contratos/alta");
    };

    $scope.sendCliente = function(datos) {
        localStorage.setItem('identificador', btoa(datos.contrato.IdCliente));
        $location.url("/clientes/perfil");
    };

    $scope.send = function (datos) {

        localStorage.setItem('cups', btoa(datos.contrato.CodigoCUPS));
        $location.url("/contratos/cups");
    };

    $scope.sendContrato = function(datos) {
        localStorage.setItem('numeroPoliza', btoa(datos.contrato.IdContrato));
        localStorage.setItem("idCliente", btoa(datos.contrato.IdCliente));
        localStorage.setItem("codigoContrato", btoa(datos.contrato.CodigoContrato));
        $location.url("/contratos/resumen");
    };

    $scope.sendContratoIncidencias = function(datos) {
        localStorage.setItem('numeroPoliza', btoa(datos.contrato.IdContrato));
        localStorage.setItem("idCliente", btoa(datos.contrato.IdCliente));
        localStorage.setItem("codigoContrato", btoa(datos.contrato.CodigoContrato));
        localStorage.setItem("activeTabContrato", btoa('Incidencias'));
        $location.url("/contratos/resumen");
    };

    $scope.sendFactura = function(datos) {
        localStorage.setItem('numeroPoliza', btoa(datos.contrato.CodigoContrato));
        $location.url("/facturas");
    };

    $scope.visualizarContrato = function(datos) {

        if($scope.abrirContratoVentanaNueva){

            // Abrir contrato en nueva ventana _blank
            window.open(
                (($scope.UrlApiCorrecionImprimirContratoBien != "") ? UrlApiCorrecionImprimirContratoBien : urlApi ) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + datos.contrato.IdContrato,
                "_blank"
            );

        }else{
            //     $http({
            //         method: "GET",
            //         url: ((nombreEmpresa == "Disa") ? "https://www.sigeenergia.com:8205/api/v1/" : urlApi ) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + datos.contrato.IdContrato,
            //         responseType: "arraybuffer",
            //         headers: {
            //             'Content-Type': 'application/json; charset=UTF-8',
            //             Authorization: atob(localStorage.getItem("TokenValidador"))
            //         }
            //     }).then(function successCallback(response) {
            //         var respuesta = response.data;

            //         var file = new Blob([respuesta], { type: "application/pdf" });
            //         var fileURL = URL.createObjectURL(file);
            //         window.open(fileURL);
            //     });
            // };
            $http({
                method: "GET",
                url: (($scope.UrlApiCorrecionImprimirContratoBien != "") ?
                UrlApiCorrecionImprimirContratoBien : urlApi ) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + datos.contrato.IdContrato,
                responseType: "arraybuffer",
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;

                var file = new Blob([respuesta], { type: "application/pdf" });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
        }
    };

    $scope.contratosPorMes = function(datos) {
        $location.url("/contratos/mes");
    };

    $scope.contratosRenovacion = function(datos) {
        if(RenovacionContratosMesLuxam){
            $location.url("/contratos/renovacion_contrato");
        }else{
            $location.url("/contratos/renovacion");
        }
    };

    $scope.busquedaContrato = function(datos) {
        $location.url("/contratos/busqueda");
    };

    $scope.updateEstado = function(data) {
        //console.log(data);
        //$scope.contratosPendientesDeActualizarEstado.push(data); //carga de contratos temporales a actualizar posteriormente dandole al boton
        //console.log($scope.contratosPendientesDeActualizarEstado);
        // TODO: PARA EVITAR ACTUALIZARCIÓN DE SITUACION AL DARLE AL PAGINADOR
        if(data.contrato.IdContratoSituacion!=data.Situacion){
			// console.log(data.contrato);
            //Solo tiene que acceder a llamar a la api cuando actua, que es en la situacion de Confirmado Oferta
            if ((data.contrato.Situacion == SituacionCambio1 || data.contrato.Situacion == SituacionCambio2 || data.contrato.Situacion == SituacionCambio3) && !$scope.MostrarContratosSituacionWeb ) {
                $http({
                    method: "GET",
                    url: urlApi + "Contrato/UpdateSituacionContrato/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente + "/Contrato/" + data.contrato.IdContrato + "/Situacion/" + data.Situacion,
                    headers: {
                        Authorization: atob(localStorage.getItem('TokenValidador'))
                    }
                }).then(function successCallback(response) {

                });
            }else{
                $http({
                    method: "GET",
                    url: urlApi + "Contrato/UpdateSituacionContrato/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente + "/Contrato/" + data.contrato.IdContrato + "/Situacion/" + data.Situacion,
                    headers: {
                        Authorization: atob(localStorage.getItem('TokenValidador'))
                    }
                }).then(function successCallback(response) {

                    // 9 == pendiente de revision || pediente de scoring
                    if(data.Situacion == 9 || data.Situacion == 8)
                        $scope.updateComunicadoEstado(data.contrato);

                });
            }
        }

    };

    $scope.actualizarEstadoContratos = function() {
        $http.get("lib/php/actualizar_estados_contratos.php").then(function(data) {
            var contratosConfirmados = data.data.ContratosConfirmados;
            var contratosRechazados = data.data.ContratosRechazados;

            if (contratosConfirmados) {
                contratosConfirmados.forEach(function(element) {
                    if (element > 0) {
                        $http({
                            method: "GET",
                            url: urlApi + "Contrato/UpdateSituacionContrato/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente + "/Contrato/" + element + "/Situacion/10",
                            headers: {
                                Authorization: atob(localStorage.getItem('TokenValidador'))
                            }
                        }).then(function successCallback(response) {

                        });
                    }
                });
            }

            if (contratosRechazados) {
                contratosRechazados.forEach(function(element) {
                    if (element > 0) {
                        $http({
                            method: "GET",
                            url: urlApi + "Contrato/UpdateSituacionContrato/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente + "/Contrato/" + element + "/Situacion/11",
                            headers: {
                                Authorization: atob(localStorage.getItem('TokenValidador'))
                            }
                        }).then(function successCallback(response) {

                        });
                    }
                });
            }
        });
    };


    // Actualiza el estado de los comunicados
    $scope.updateComunicadoEstado = function(contrato) {

            $http({
                method: "POST",
                url: urlApi + "ComunicadoCanal/UpdateComunicadosContrato/Empresa/" + IdEmpresa + "/Agente/" + IdAgente + "/IdContrato/" + contrato.IdContrato,
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador'))
                }
            }).then(function successCallback(response) {

            });

    };















    $scope.refrescarContratos = function() {

        if(nombreEmpresa === 'Eneluz' || nombreEmpresa === 'Capital Energy') {
            if(localStorage.getItem('IdMarca') != ''){
                localStorage.setItem('controlRefresh', true);
            }
        }

		if (localStorage.getItem('controlRefresh') != undefined && localStorage.getItem('controlRefresh') == 'true') {
            localStorage.setItem('IdMarca', $scope.IdMarca);
			serviciosApi.getListadoContratos($scope.FiltrarListadoContratoPorIdMarca)
				.then(function(response) {
					var respuesta = response.data;
					var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);
                    //VALIDACION CON LA PALABRA SECRETA DE LA API
					var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
						alg: ["HS256"]
							//verifyAt: IntDate.get('20150601000000Z')
					});
					var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
					var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
					if (tokenValido && !tokenExpirado) {
						$scope.contratos = playloadJson;
					} else {
						$scope.contratos = "";
					}

				});
		}
        localStorage.removeItem('controlRefresh'); //borro la cookie para que no se mantenga siempre con valor true
    }

    $scope.selected = [];


    // Asignacion del valor de orden de cada columna del config con posibilidad de no ordenar por esa columna
    if(MostrarContratosOrdenacionSituacion){ MostrarContratosOrdenacionSituacion = 'Situacion'; } else { MostrarContratosOrdenacionSituacion = ''; }
    if(MostrarContratosOrdenacionListadoFacturas){ MostrarContratosOrdenacionListadoFacturas = 'ListadoFacturas'; } else { MostrarContratosOrdenacionListadoFacturas = ''; }
    if(MostrarContratosOrdenacionContratoPDF){ MostrarContratosOrdenacionContratoPDF = 'ContratoPDF'; } else { MostrarContratosOrdenacionContratoPDF = ''; }
    if(MostrarContratosOrdenacionEnvioEmail){ MostrarContratosOrdenacionEnvioEmail = 'EnvioEmail'; } else { MostrarContratosOrdenacionEnvioEmail = ''; }
    if(MostrarContratosOrdenacionNombreAgente){ MostrarContratosOrdenacionNombreAgente = 'NombreAgente'; } else { MostrarContratosOrdenacionNombreAgente = ''; }
    if(MostrarContratosOrdenacionNombreSubagente){ MostrarContratosOrdenacionNombreSubagente = 'NombreSubagente'; } else { MostrarContratosOrdenacionNombreSubagente = ''; }
    if(MostrarContratosOrdenacionTarifaGrupo){ MostrarContratosOrdenacionTarifaGrupo = 'TarifaGrupo'; } else { MostrarContratosOrdenacionTarifaGrupo = ''; }
    if(MostrarContratosOrdenacionCodigoContrato){ MostrarContratosOrdenacionCodigoContrato = 'CodigoContrato'; } else { MostrarContratosOrdenacionCodigoContrato = ''; }
    if(MostrarContratosOrdenacionDniCif){ MostrarContratosOrdenacionDniCif = 'DniCif'; } else { MostrarContratosOrdenacionDniCif = ''; }
    if(MostrarContratosOrdenacionCategoriaCliente){ MostrarContratosOrdenacionCategoriaCliente = 'CategoriaCliente'; } else { MostrarContratosOrdenacionCategoriaCliente = ''; }
    if(MostrarContratosOrdenacionNombre){ MostrarContratosOrdenacionNombre = 'Nombre'; } else { MostrarContratosOrdenacionNombre = ''; }
    if(MostrarContratosOrdenacionApellido1){ MostrarContratosOrdenacionApellido1 = 'Apellido1'; } else { MostrarContratosOrdenacionApellido1 = ''; }
    if(MostrarContratosOrdenacionApellido2){ MostrarContratosOrdenacionApellido2 = 'Apellido2'; } else { MostrarContratosOrdenacionApellido2 = ''; }
    if(MostrarContratosOrdenacionCUPS){ MostrarContratosOrdenacionCUPS = 'CUPS'; } else { MostrarContratosOrdenacionCUPS = ''; }
    if(MostrarContratosOrdenacionConsumoAnualEstimado){ MostrarContratosOrdenacionConsumoAnualEstimado = 'ConsumoAnualEstimado'; } else { MostrarContratosOrdenacionConsumoAnualEstimado = ''; }
    if(MostrarContratosOrdenacionDireccionCUPS){ MostrarContratosOrdenacionDireccionCUPS = 'DireccionCUPS'; } else { MostrarContratosOrdenacionDireccionCUPS = ''; }
    if(MostrarContratosOrdenacionCiudadCUPS){ MostrarContratosOrdenacionCiudadCUPS = 'CiudadCUPS'; } else { MostrarContratosOrdenacionCiudadCUPS = ''; }
    if(camposAdicionalesListadoContratoOvo){ $scope.MostrarOrdenacionCPCUPS = 'CPCUPS'; } else { $scope.MostrarOrdenacionCPCUPS = ''; }
    if(MostrarContratosOrdenacionFechaAlta){ MostrarContratosOrdenacionFechaAlta = 'FechaAltaOrdena'; } else { MostrarContratosOrdenacionFechaAlta = ''; }
    if(MostrarContratosOrdenacionFechaBaja){ MostrarContratosOrdenacionFechaBaja = 'FechaBajaOrdena'; } else { MostrarContratosOrdenacionFechaBaja = ''; }
    if(camposAdicionalesListadoContratoOvo){ $scope.MostrarOrdenacionFechaContratoNueva = 'FechaContrato'; } else { $scope.MostrarOrdenacionFechaContratoNueva = ''; }
    if(MostrarContratosOrdenacionPotencia1){ MostrarContratosOrdenacionPotencia1 = 'Potencia1'; } else { MostrarContratosOrdenacionPotencia1 = ''; }
    if(MostrarContratosOrdenacionPotencia2){ MostrarContratosOrdenacionPotencia2 = 'Potencia2'; } else { MostrarContratosOrdenacionPotencia2 = ''; }
    if(MostrarContratosOrdenacionPotencia3){ MostrarContratosOrdenacionPotencia3 = 'Potencia3'; } else { MostrarContratosOrdenacionPotencia3 = ''; }
    if(MostrarContratosOrdenacionPotencia4){ MostrarContratosOrdenacionPotencia4 = 'Potencia4'; } else { MostrarContratosOrdenacionPotencia4 = ''; }
    if(MostrarContratosOrdenacionPotencia5){ MostrarContratosOrdenacionPotencia5 = 'Potencia5'; } else { MostrarContratosOrdenacionPotencia5 = ''; }
    if(MostrarContratosOrdenacionPotencia6){ MostrarContratosOrdenacionPotencia6 = 'Potencia6'; } else { MostrarContratosOrdenacionPotencia6 = ''; }
    if(MostrarContratosOrdenacionCaudal){ MostrarContratosOrdenacionCaudal = 'Caudal'; } else { MostrarContratosOrdenacionCaudal = ''; }
    if(MostrarContratosOrdenacionTarifa){ MostrarContratosOrdenacionTarifa = 'Tarifa'; } else { MostrarContratosOrdenacionTarifa = ''; }
    // if(MostrarContratosOrdenacionDeuda){ MostrarContratosOrdenacionDeuda = 'DeudaIndividual'; } else { MostrarContratosOrdenacionDeuda = ''; }
    if(MostrarContratosOrdenacionDeuda){ MostrarContratosOrdenacionDeuda = 'Deuda'; } else { MostrarContratosOrdenacionDeuda = ''; }
    if(MostrarContratosOrdenacionValorCO){ MostrarContratosOrdenacionValorCO = 'ValorCO'; } else { MostrarContratosOrdenacionValorCO = ''; }
    if(MostrarContratosOrdenacionRazonSocial){ MostrarContratosOrdenacionRazonSocial = 'RazonSocial'; } else { MostrarContratosOrdenacionRazonSocial = ''; }
    if(MostrarContratosOrdenacionDenominacion){ MostrarContratosOrdenacionDenominacion = 'Denominacion'; } else { MostrarContratosOrdenacionDenominacion = ''; }
    if(MostrarContratosOrdenacionEnvioEmail){ MostrarContratosOrdenacionEnvioEmail = 'EnvioEmail'; } else { MostrarContratosOrdenacionEnvioEmail = ''; }
    if(MostrarContratosOrdenacionNombreAgente){ MostrarContratosOrdenacionNombreAgente = 'NombreAgente'; } else { MostrarContratosOrdenacionNombreAgente = ''; }

    $scope.MostrarListadoContratosColumnaFechaContrato = MostrarListadoContratosColumnaFechaContrato;
    $scope.MostrarListadoContratosColumnaFechaPActivacion = MostrarListadoContratosColumnaFechaPActivacion;

// CON FILTRO DE VARIABLE
    //multisearch

    // Columnas con nombre partido en filtros | Nombre/razon social | apellido1 | apellido2 |
    if($scope.MostrarContratosNombreParticionado){
        $scope.autocolumn = [
            {
                name: "Situacion",
                display: "",
                oculta: "Situacion",
                ordenar: MostrarContratosOrdenacionSituacion
            }, {
                name: "ListadoFacturas",
                display: "Facturas",
                oculta: "Facturas",
                ordenar: MostrarContratosOrdenacionListadoFacturas
            }, {
                name: "ContratoPDF",
                display: "Condiciones Contractuales",
                oculta: "PDF",
                ordenar: MostrarContratosOrdenacionContratoPDF
            }, {
                name: "EnvioEmail",
                display: "Envio Email",
                oculta: "EnvioEmail",
                ordenar: MostrarContratosOrdenacionEnvioEmail
            }, {
                name: "NombreAgente",
                display: ($scope.CambioAgenteXCanalEleia) ? "Canal" : "Agente",
                oculta: "IdAgente",
                ordenar: MostrarContratosOrdenacionNombreAgente
            }, {
                name: "NombreSubagente",
                display: "Subagente",
                oculta: "IdSubagente",
                ordenar: MostrarContratosOrdenacionNombreSubagente
            }, {
                name: "TarifaGrupo",
                display: "Grupo Tarifa",
                oculta: "TarifaGrupo",
                ordenar: MostrarContratosOrdenacionTarifaGrupo
            }, {
                name: "CodigoContrato",
                display: "Contrato",
                oculta: "Contrato",
                ordenar: MostrarContratosOrdenacionCodigoContrato
            }, {
                name: "Situacion",
                display: "Situación",
                oculta: "Situacion",
                ordenar: "Situacion"
            }, {
                name: "DniCif",
                display: "DNI/CIF",
                oculta: "DniCif",
                ordenar: MostrarContratosOrdenacionDniCif
            }, {
                name: "CategoriaCliente",
                display: "Categoría Cliente",
                oculta: "CategoriaCliente",
                ordenar: MostrarContratosOrdenacionCategoriaCliente
            }, {
                name: "RazonSocial",
                display: "Razón Social",
                oculta: "RazonSocial",
                ordenar: MostrarContratosOrdenacionRazonSocial
            }, {
                name: "Nombre",
                display: "Nombre",
                oculta: "Nombre",
                ordenar: MostrarContratosOrdenacionNombre
            }, {
                name: "Apellido1",
                display: "Primer Apellido",
                oculta: "ApellidoUno",
                ordenar: MostrarContratosOrdenacionApellido1
            }, {
                name: "Apellido2",
                display: "Segundo Apellido",
                oculta: "ApellidoDos",
                ordenar: MostrarContratosOrdenacionApellido2
            }, {
                name: "CUPS",
                display: "CUPS",
                oculta: "CUPS",
                ordenar: MostrarContratosOrdenacionCUPS
            }, {
                name: "ConsumoAnualEstimado",
                display: "Consumo Anual Estimado (kWh)",
                oculta: "ConsumoAnualEstimado",
                ordenar: MostrarContratosOrdenacionConsumoAnualEstimado
            }, {
                name: "DireccionCUPS",
                display: "Dirección CUPS",
                oculta: "DireccionCUPS",
                ordenar: MostrarContratosOrdenacionDireccionCUPS
            }, {
                name: "CiudadCUPS",
                display: "Ciudad CUPS",
                oculta: "CiudadCUPS",
                ordenar: MostrarContratosOrdenacionCiudadCUPS
            }, {
                name: "CPCUPS",
                display: "CP CUPS",
                oculta: "CPCUPS",
                ordenar: $scope.MostrarOrdenacionCPCUPS
            }, {
                name: "FechaAlta",
                display: "Fecha Alta",
                oculta: "FechaAlta",
                ordenar: $scope.MostrarContratosOrdenacionFechaAlta
            }, {
                name: "FechaBaja",
                display: "Fecha Baja",
                oculta: "FechaBaja",
                ordenar: MostrarContratosOrdenacionFechaBaja
            }, {
                name: "FechaContrato",
                display: "Fecha Contrato",
                oculta: "FechaContratoNueva",
                ordenar: $scope.MostrarOrdenacionFechaContratoNueva
            }, {
                name: "Potencia1",
                display: "Potencia 1",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia1
            }, {
                name: "Potencia2",
                display: "Potencia 2",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia2
            }, {
                name: "Potencia3",
                display: "Potencia 3",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia3
            }, {
                name: "Potencia4",
                display: "Potencia 4",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia4
            }, {
                name: "Potencia5",
                display: "Potencia 5",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia5
            }, {
                name: "Potencia6",
                display: "Potencia 6",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia6
            }, {
                name: "Caudal",
                display: "Caudal",
                oculta: "Caudal && VerGas",
                ordenar: MostrarContratosOrdenacionCaudal
            }, {
                name: "Tarifa",
                display: "Tarifa",
                oculta: "Tarifa",
                ordenar: MostrarContratosOrdenacionTarifa
            }, {
                name: "Deuda",
                display: "Deuda",
                oculta: "Deuda",
                ordenar: MostrarContratosOrdenacionDeuda
            }, {
                name: "ValorCO",
                display: "ValorCO",
                oculta: "ValorCO",
                ordenar: MostrarContratosOrdenacionValorCO
            }, {
                name: "NombreAgente",
                display: "Agente",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionNombreAgente
            }, {
                name: "NombreSubagente",
                display: "Subagente",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionNombreSubagente

            }, {
                name: "NombreSubaasdasgente",
                display: "Subagedasdsante",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionNombreSubadasgente

            }];

            ($scope.MostrarListadoContratosComisionAnualEstimada) ? $scope.autocolumn.push({ name: "ComisionAnualEstimada", display: "Comision Anual Estimada",oculta: "ComisionAnualEstimada", ordenar: "ComisionAnualEstimada"}) : null;


    // Columnas sin nombre partido en filtros | Denominacion |
    }else{

                $scope.autocolumn = []

                $scope.autocolumn.push(
            {
                name: "Situacion",
                display: "",
                oculta: "Situacion",
                ordenar: MostrarContratosOrdenacionSituacion
            }, {
                name: "ListadoFacturas",
                display: "Facturas",
                oculta: "Facturas",
                ordenar: MostrarContratosOrdenacionListadoFacturas
            }, {
                name: "ContratoPDF",
                display: "Condiciones Contractuales",
                oculta: "PDF",
                ordenar: MostrarContratosOrdenacionContratoPDF
            }, {
                name: "EnvioEmail",
                display: "Envio Email",
                oculta: "EnvioEmail",
                ordenar: MostrarContratosOrdenacionEnvioEmail
            }, {
                name: "NombreAgente",
                display: ($scope.CambioAgenteXCanalEleia) ? "Canal" : "Agente",
                oculta: "IdAgente",
                ordenar: MostrarContratosOrdenacionNombreAgente
            }, {
                name: "NombreSubagente",
                display: "Subagente",
                oculta: "IdSubagente",
                ordenar: MostrarContratosOrdenacionNombreSubagente
            }, {
                name: "TarifaGrupo",
                display: "Grupo Tarifa",
                oculta: "TarifaGrupo",
                ordenar: MostrarContratosOrdenacionTarifaGrupo
            }, {
                name: "CodigoContrato",
                display: "Contrato",
                oculta: "Contrato",
                ordenar: MostrarContratosOrdenacionCodigoContrato
            }, {
                name: "Situacion",
                display: "Situación",
                oculta: "Situacion",
                ordenar: "Situacion"
            }, {
                name: "DniCif",
                display: "DNI/CIF",
                oculta: "DniCif",
                ordenar: MostrarContratosOrdenacionDniCif
            }, {
                name: "CategoriaCliente",
                display: "Categoría Cliente",
                oculta: "CategoriaCliente",
                ordenar: MostrarContratosOrdenacionCategoriaCliente
            }, {
                name: "Denominacion",
                display: "Nombre",
                oculta: "Denominacion",
                ordenar: MostrarContratosOrdenacionDenominacion
            }, {
                name: "CUPS",
                display: "CUPS",
                oculta: "CUPS",
                ordenar: MostrarContratosOrdenacionCUPS
            }, {
                name: "ConsumoAnualEstimado",
                display: "Consumo contratado (kWh)",
                oculta: "ConsumoAnualEstimado",
                ordenar: MostrarContratosOrdenacionConsumoAnualEstimado
            }, {
                name: "DireccionCUPS",
                display: "Dirección CUPS",
                oculta: "DireccionCUPS",
                ordenar: MostrarContratosOrdenacionDireccionCUPS
            }, {
                name: "CiudadCUPS",
                display: "Ciudad CUPS",
                oculta: "CiudadCUPS",
                ordenar: MostrarContratosOrdenacionCiudadCUPS
            }, {
				name: "CPCUPS",
                display: "CP CUPS",
                oculta: "CPCUPS",
                ordenar: $scope.MostrarOrdenacionCPCUPS
            }, {
                name: "FechaContrato",
                display: "Fecha de grabación",
                oculta: "FechaContrato",
                ordenar: "FechaGrabacionOrdena"
            }, {
                name: "FechaPActivacion",
                display: "Fecha Prevista Activación",
                oculta: "FechaPActivacion",
                ordenar: "FechaPActivacionOrdena"
            }, {
                name: "FechaAlta",
                display: "Fecha Alta",
                oculta: "FechaAlta",
                ordenar: MostrarContratosOrdenacionFechaAlta
            }, {
                name: "FechaBaja",
                display: "Fecha Baja",
                oculta: "FechaBaja",
                ordenar: MostrarContratosOrdenacionFechaBaja
            });

           ($scope.MostrarContratosRenovacion) ? $scope.autocolumn.push({ name: "Renovable", display: "Renovable",oculta: "Renovacion", ordenar: "Renovable"}) : null;
            ($scope.MostrarContratosRenovacion) ? $scope.autocolumn.push({ name: "Renovacion", display: "Renovacion",oculta: "Renovacion", ordenar: "Renovacion"}) : null;


            $scope.autocolumn.push(
            {
                name: "FechaContrato",
                display: "Fecha Contrato",
                oculta: "FechaContratoNueva",
                ordenar: $scope.MostrarOrdenacionFechaContratoNueva
            });

            ($scope.MostrarContratosFechaVto) ? $scope.autocolumn.push({ name: "FechaVto", display: "Fecha Vencimiento",oculta: "FechaVto", ordenar: "FechaVto"}) : null;


            ($scope.MostrarContratosEmail) ? $scope.autocolumn.push({ name: "Email", display: "Email",oculta: "Email", ordenar: "Email"}) : null;
            ($scope.MostrarContratosTelefono) ? $scope.autocolumn.push({ name: "Telefono", display: "Telefono",oculta: "Telefono", ordenar: "Telefono"}) : null;
            ($scope.MostrarContratosMovil) ? $scope.autocolumn.push({ name: "Movil", display: "Movil",oculta: "Movil", ordenar: "Movil"}) : null;

            $scope.autocolumn.push(
            {
                name: "Potencia1",
                display: "Potencia 1",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia1
            }, {
                name: "Potencia2",
                display: "Potencia 2",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia2
            }, {
                name: "Potencia3",
                display: "Potencia 3",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia3
            }, {
                name: "Potencia4",
                display: "Potencia 4",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia4
            }, {
                name: "Potencia5",
                display: "Potencia 5",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia5
            }, {
                name: "Potencia6",
                display: "Potencia 6",
                oculta: "Potencia && VerEnergia",
                ordenar: MostrarContratosOrdenacionPotencia6
            }, {
                name: "Caudal",
                display: "Caudal",
                oculta: "Caudal && VerGas",
                ordenar: MostrarContratosOrdenacionCaudal
            }, {
                name: "Tarifa",
                display: "Tarifa",
                oculta: "Tarifa",
                ordenar: MostrarContratosOrdenacionTarifa
            }, {
                name: "Deuda",
                display: "Deuda",
                oculta: "Deuda",
                ordenar: MostrarContratosOrdenacionDeuda
            });

            ($scope.MostrarContratosComisionAnualEstimada) ? $scope.autocolumn.push({ name: "ComisionAnualEstimada", display: "Comision Anual Estimada",oculta: "ComisionAnualEstimada", ordenar: "ComisionAnualEstimada"}) : null;

            $scope.autocolumn.push(
            {
                name: "ValorCO",
                display: "ValorCO",
                oculta: "ValorCO",
                ordenar: MostrarContratosOrdenacionValorCO
            }, {
                name: "NombreAgente",
                display: ($scope.CambioAgenteXCanalEleia) ? "Canal" : "Agente",
                oculta: "AgenteFinal",
                ordenar: MostrarContratosOrdenacionNombreAgente
            }, {
                name: "NombreSubagente",
                display: "Subagente",
                oculta: "AgenteFinal",
                ordenar: MostrarContratosOrdenacionNombreSubagente
            });

            // console.log($scope.autocolumn);

            ($scope.MostrarReferenciaExterna1y2) ? $scope.autocolumn.push({ name: "RefExt1", display: "Referencia Externa 1",  ordenar: "RefExt1" , oculta: "CUPS"},{ name: "RefExt2", display: "Referencia Externa 2",  ordenar: "RefExt2",oculta: "CUPS"}) : null;

    }

	$scope.seleccionaTodos = function(contratosFound){
		$scope.selected = [];
		contratosFound.forEach(function (item) {
			$scope.selected.push(item);
		});
	}

	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

    $scope.exportarDatosContratos = function(selected) {
        var contratos = [];

		/**
		 * Se filtran aqui, si los campos estan en la vista, los añadimos aqui y aparecen en la descarga.
		 */
            selected.forEach(function(obj,key) {
                // console.log(obj);
				if($scope.camposAdicionalesListadoContratoOvo) {
					contratos.push({
						'Agente': obj.NombreAgente,
						'Subagente': obj.NombreSubAgente,
						'Contrato': obj.CodigoContrato,
						'Situacion': ($scope.MostrarContratosSituacionWeb) ? obj.SituacionWeb : obj.Situacion,
						'Identificador': obj.Identidad,
						'Nombre': (obj.Denominacion) ? obj.Denominacion : obj.RazonSocial,
						'CUPS': obj.CodigoCUPS,
						'CAE(kWh)': obj.ConsumoAnualEstimado,
						'Direccion CUPS': obj.DireccionCUPS,
						'Ciudad CUPS': (obj.CiudadCUPS) ? obj.CiudadCUPS.toUpperCase() : "",
						'Codigo Postal CUPS': (obj.CPCUPS) ? obj.CPCUPS : "",
						'Fecha Prevista Activación': (obj.FechaPActivacion) ? obj.FechaPActivacion : "",
						'Fecha Grabación': (obj.FechaGrabacion) ? obj.FechaGrabacion : "",
						'Fecha Alta': (obj.FechaAlta) ? obj.FechaAlta : "",
						'Fecha Baja': (obj.FechaBaja) ? obj.FechaBaja : "",
                        'Fecha Contrato': (obj.FechaContrato) ? obj.FechaContrato : "",
						'Fecha Vto': (obj.FechaVto) ? obj.FechaVto : '',
						'Email': (obj.Email) ? obj.Email : "",
						'Telefono': (obj.Telefono) ? obj.Telefono : "",
                        'Movil': (obj.Movil) ? obj.Movil : "",
						'Potencia 1': (obj.PotenciaP1) ? parseFloat(obj.PotenciaP1).toFixed(2).replace('.',',') : 0,
						'Potencia 2': (obj.PotenciaP2) ? parseFloat(obj.PotenciaP2).toFixed(2).replace('.',',') : 0,
						'Potencia 3': (obj.PotenciaP3) ? parseFloat(obj.PotenciaP3).toFixed(2).replace('.',',') : 0,
						'Potencia 4': (obj.PotenciaP4) ? parseFloat(obj.PotenciaP4).toFixed(2).replace('.',',') : 0,
						'Potencia 5': (obj.PotenciaP5) ? parseFloat(obj.PotenciaP5).toFixed(2).replace('.',',') : 0,
						'Potencia 6': (obj.PotenciaP6) ? parseFloat(obj.PotenciaP6).toFixed(2).replace('.',',') : 0,
						'Tarifa': obj.TextoTarifa,
						'ValorCO': (obj.ValorCO) ? parseFloat(obj.ValorCO * 100).toFixed(6).replace('.',',') : 0,
						'Agente': obj.NombreAgente,
						'Subagente': obj.NombreSubAgente
					});
				} else if($scope.CambioAgenteXCanalEleia) {

                    var pasarFechaAlta = false;
                    if($scope.situacionesContrato){
                        $scope.situacionesContrato.forEach(function(obj2,key2) {
                            if(obj.Situacion == obj2.TextoSituacion && (obj2.IsActivo || obj2.IsEstadoBaja)) {
                                pasarFechaAlta = true;
                            }
                        });
                    }



					contratos.push({
						'Agente': obj.NombreAgente,
						'Subagente': obj.NombreSubAgente,
						'Grupo Tarifa': obj.TextoTarifaGrupo,
						'Contrato': obj.CodigoContrato,
						'Situacion': ($scope.MostrarContratosSituacionWeb) ? obj.SituacionWeb : obj.Situacion,
						'Identificador': obj.Identidad,
						'Nombre': (obj.Denominacion) ? obj.Denominacion : obj.RazonSocial,
						'CUPS': obj.CodigoCUPS,
						'CAE(kWh)': (obj.ConsumoAnualEstimado) ? obj.ConsumoAnualEstimado : obj.ConsumoEstimado,
						'Direccion CUPS': obj.DireccionCUPS,
						'Ciudad CUPS': (obj.CiudadCUPS) ? obj.CiudadCUPS.toUpperCase() : "",
						'Fecha Prevista Activación': (obj.FechaPActivacion) ? obj.FechaPActivacion : "",
						'Fecha Grabación': (obj.FechaGrabacion) ? obj.FechaGrabacion : "",
						'Fecha Alta': (pasarFechaAlta) ? obj.FechaAlta : "",
						'Fecha Baja': (obj.FechaBaja) ? obj.FechaBaja : "",
						'Renovable': obj.Renovable,
						'Renovacion': obj.Renovacion,
						'Potencia 1': (obj.PotenciaP1) ? parseFloat(obj.PotenciaP1).toFixed(2).replace('.',',') : (obj.P1) ? parseFloat(obj.P1).toFixed(2).replace('.',',') : 0,
						'Potencia 2': (obj.PotenciaP2) ? parseFloat(obj.PotenciaP2).toFixed(2).replace('.',',') : (obj.P2) ? parseFloat(obj.P2).toFixed(2).replace('.',',') : 0,
						'Potencia 3': (obj.PotenciaP3) ? parseFloat(obj.PotenciaP3).toFixed(2).replace('.',',') : (obj.P3) ? parseFloat(obj.P3).toFixed(2).replace('.',',') : 0,
						'Potencia 4': (obj.PotenciaP4) ? parseFloat(obj.PotenciaP4).toFixed(2).replace('.',',') : (obj.P4) ? parseFloat(obj.P4).toFixed(2).replace('.',',') : 0,
						'Potencia 5': (obj.PotenciaP5) ? parseFloat(obj.PotenciaP5).toFixed(2).replace('.',',') : (obj.P5) ? parseFloat(obj.P5).toFixed(2).replace('.',',') : 0,
						'Potencia 6': (obj.PotenciaP6) ? parseFloat(obj.PotenciaP6).toFixed(2).replace('.',',') : (obj.P6) ? parseFloat(obj.P6).toFixed(2).replace('.',',') : 0,
						'Tarifa': obj.TextoTarifa,
						'ValorCO': (obj.ValorCO) ? parseFloat(obj.ValorCO * 100).toFixed(6).replace('.',',') : 0,

                    });



            } else if($scope.nombreEmpresa=='N-ZWEI') {

                var pasarFechaAlta = false;
                if($scope.situacionesContrato){
                    $scope.situacionesContrato.forEach(function(obj2,key2) {
                        if(obj.Situacion == obj2.TextoSituacion && (obj2.IsActivo || obj2.IsEstadoBaja)) {
                            pasarFechaAlta = true;
                        }
                    });
                }
                contratos.push({
                    'Agente': obj.NombreAgente,
                    'Subagente': obj.NombreSubAgente,
                    'Grupo Tarifa': obj.TextoTarifaGrupo,
                    'Contrato': obj.CodigoContrato,
                    'Situacion': ($scope.MostrarContratosSituacionWeb) ? obj.SituacionWeb : obj.Situacion,
                    'Identificador': obj.Identidad,
                    'Nombre': (obj.Denominacion) ? obj.Denominacion : obj.RazonSocial,
                    'CUPS': obj.CodigoCUPS,
                    'CAE(kWh)': (obj.ConsumoAnualEstimado) ? obj.ConsumoAnualEstimado : obj.ConsumoEstimado,
                    'Direccion CUPS': obj.DireccionCUPS,
                    'Ciudad CUPS': (obj.CiudadCUPS) ? obj.CiudadCUPS.toUpperCase() : "",
                    'Fecha Prevista Activación': (obj.FechaPActivacion) ? obj.FechaPActivacion : "",
                    'Fecha Grabación': (obj.FechaGrabacion) ? obj.FechaGrabacion : "",
                    'Fecha Alta': (pasarFechaAlta) ? obj.FechaAlta : "",
                    'Fecha Baja': (obj.FechaBaja) ? obj.FechaBaja : "",
                    'Renovacion': obj.Renovacion,
                    'Potencia 1': (obj.PotenciaP1) ? parseFloat(obj.PotenciaP1).toFixed(2).replace('.',',') : (obj.P1) ? parseFloat(obj.P1).toFixed(2).replace('.',',') : 0,
                    'Potencia 2': (obj.PotenciaP2) ? parseFloat(obj.PotenciaP2).toFixed(2).replace('.',',') : (obj.P2) ? parseFloat(obj.P2).toFixed(2).replace('.',',') : 0,
                    'Potencia 3': (obj.PotenciaP3) ? parseFloat(obj.PotenciaP3).toFixed(2).replace('.',',') : (obj.P3) ? parseFloat(obj.P3).toFixed(2).replace('.',',') : 0,
                    'Potencia 4': (obj.PotenciaP4) ? parseFloat(obj.PotenciaP4).toFixed(2).replace('.',',') : (obj.P4) ? parseFloat(obj.P4).toFixed(2).replace('.',',') : 0,
                    'Potencia 5': (obj.PotenciaP5) ? parseFloat(obj.PotenciaP5).toFixed(2).replace('.',',') : (obj.P5) ? parseFloat(obj.P5).toFixed(2).replace('.',',') : 0,
                    'Potencia 6': (obj.PotenciaP6) ? parseFloat(obj.PotenciaP6).toFixed(2).replace('.',',') : (obj.P6) ? parseFloat(obj.P6).toFixed(2).replace('.',',') : 0,
                    'Tarifa': obj.TextoTarifa,
                    'ValorCO': (obj.ValorCO) ? parseFloat(obj.ValorCO * 100).toFixed(6).replace('.',',') : 0,

                });
            } else {
                // console.log("entra 3");
                    var pasarFechaAlta = false;
                    // console.log(obj);
                    if($scope.nombreEmpresa=="Vita energy group"){
                        pasarFechaAlta = true;
                    }
                    if($scope.situacionesContrato){
                        $scope.situacionesContrato.forEach(function(obj2,key2) {
                            if(obj.Situacion == obj2.TextoSituacion && (obj2.IsActivo || obj2.IsEstadoBaja)) {
                                pasarFechaAlta = true;
                            }
                        });
                     }else{ pasarFechaAlta = true; }


					contratos.push({
						'Agente': obj.NombreAgente,
						'Subagente': obj.NombreSubAgente,
						'Contrato': obj.CodigoContrato,
						'Situacion': ($scope.MostrarContratosSituacionWeb) ? obj.SituacionWeb : obj.Situacion,
						'Identificador': obj.Identidad,
						'Nombre': (obj.Denominacion) ? obj.Denominacion : obj.RazonSocial,
						'CUPS': obj.CodigoCUPS,
						'CAE(kWh)': (obj.ConsumoAnualEstimado) ? obj.ConsumoAnualEstimado : obj.ConsumoEstimado,
						'Direccion CUPS': obj.DireccionCUPS,
						'Ciudad CUPS': (obj.CiudadCUPS) ? obj.CiudadCUPS.toUpperCase() : "",
						'Fecha Prevista Activación': (obj.FechaPActivacion) ? obj.FechaPActivacion : "",
						'Fecha Grabación': (obj.FechaContrato) ? obj.FechaContrato : "",
						'Fecha Alta': (pasarFechaAlta) ? obj.FechaAlta : "",
						'Fecha Baja': (obj.FechaBaja) ? obj.FechaBaja : "",
						'Potencia 1': (obj.PotenciaP1) ? parseFloat(obj.PotenciaP1).toFixed(2).replace('.',',') : (obj.P1) ? parseFloat(obj.P1).toFixed(2).replace('.',',') : 0,
						'Potencia 2': (obj.PotenciaP2) ? parseFloat(obj.PotenciaP2).toFixed(2).replace('.',',') : (obj.P2) ? parseFloat(obj.P2).toFixed(2).replace('.',',') : 0,
						'Potencia 3': (obj.PotenciaP3) ? parseFloat(obj.PotenciaP3).toFixed(2).replace('.',',') : (obj.P3) ? parseFloat(obj.P3).toFixed(2).replace('.',',') : 0,
						'Potencia 4': (obj.PotenciaP4) ? parseFloat(obj.PotenciaP4).toFixed(2).replace('.',',') : (obj.P4) ? parseFloat(obj.P4).toFixed(2).replace('.',',') : 0,
						'Potencia 5': (obj.PotenciaP5) ? parseFloat(obj.PotenciaP5).toFixed(2).replace('.',',') : (obj.P5) ? parseFloat(obj.P5).toFixed(2).replace('.',',') : 0,
						'Potencia 6': (obj.PotenciaP6) ? parseFloat(obj.PotenciaP6).toFixed(2).replace('.',',') : (obj.P6) ? parseFloat(obj.P6).toFixed(2).replace('.',',') : 0,
						'Tarifa': obj.TextoTarifa,
						'ValorCO': (obj.ValorCO) ? parseFloat(obj.ValorCO * 100).toFixed(6).replace('.',',') : 0,

					});
				}
                if($scope.MostrarContratosComisionAnualEstimada) { contratos[key]['Comision Anual Estimada'] = obj.ComisionAnualEstimada; }
                if($scope.MostrarContratosDeuda) { contratos[key]['Deuda'] = obj.Deuda; }

                // console.log(obj);
                // if($scope.MostrarContratosDeuda) { contratos[key]['Fecha Vencimiento'] = obj.Deuda; }
                // if($scope.MostrarContratosFechaVto) { contratos[key]['Fecha Vencimiento'] = obj.FechaVTO; }



                // console.log(obj);

            });

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
		.excelexportjs({
		containerid: "dvjson",
		datatype: 'json',
		dataset: contratos,
		columns: getColumns(contratos)
		});
    };

	$scope.recargarDatos =  function(){
		$scope.getListadoContratos();
	}

    $scope.exportarDatosContratosMet = function (selected) {
        var contratos = [];

        selected.forEach(function (obj) {
            contratos.push({
                ccups: obj.CodigoCUPS,
                agent: obj.NombreAgente,
                subag: obj.NombreSubAgente,
                grupt: obj.TextoTarifaGrupo,
                ctrat: obj.CodigoContrato,
                situa: obj.Situacion,
                ident: obj.Identidad,
                nombr: obj.Denominacion,
                consu: obj.ConsumoAnualEstimado,
                direc: obj.DireccionCUPS,
                ciuda: (obj.CiudadCUPS) ? obj.CiudadCUPS.toUpperCase() : "",
                falta: (obj.FechaAlta) ? moment(obj.FechaAlta).format('L') : "",
                fbaja: (obj.FechaBaja) ? moment(obj.FechaBaja).format('L') : "",
                fVto: (obj.FechaVto) ? moment(obj.FechaVto).format('L') : "",
                poten1: (obj.PotenciaP1) ? obj.PotenciaP1 : 0,
                tarif: obj.TextoTarifa
            });
        });
        return contratos;
    };

}]);