app.controller('ListadoClientesCtrl', ['$scope', '$location', 'serviciosApi', 'jwtHelper', function($scope, $location, serviciosApi, jwtHelper) {
	'use strict';

    $scope.sendCliente = function(datos) {
        localStorage.setItem('identificador', btoa(datos.cliente.IdCliente));
        $location.url("/clientes/perfil");
    };

    $scope.sendContrato = function(datos) {
        localStorage.setItem("numeroPoliza", btoa(datos.cliente.IdContrato));
        localStorage.setItem("idCliente", btoa(datos.cliente.IdCliente));
        $location.url("/contratos/resumen");
    };

    $scope.MostrarClientesContrato = MostrarClientesContrato;
    $scope.MostrarClientesIdentificador = MostrarClientesIdentificador;
    $scope.MostrarClientesNombre = MostrarClientesNombre;
    $scope.MostrarClientesRazonSocial = MostrarClientesRazonSocial;
    $scope.MostrarClientesDireccion = MostrarClientesDireccion;
    $scope.MostrarClientesTelefono = MostrarClientesTelefono;
    $scope.MostrarClientesEmail = MostrarClientesEmail;
    $scope.MostrarTablaClientes = MostrarTablaClientes;
    $scope.MostrarEnlaceContrato=MostrarEnlaceContrato;


    $scope.autocolumn = [{
        name: "CodigoContrato",
        display: "Contrato",
        oculta: "Contrato"
    }, {
        name: "DNI",
        display: "Identificador",
        oculta: "Identificador"
    }, {
        name: "TitularNombre",
        display: "Nombre",
        oculta: "Nombre"
    }, {
        name: "RazonSocial",
        display: "Razón Social",
        oculta: "RazonSocial"
    }, {
        name: "SuministroCalle",
        display: "Dirección",
        oculta: "Direccion"
    }, {
        name: "SuministroTelefono1",
        display: "Teléfono",
        oculta: "Telefono"
    }, 
    {
        name: "SuministroTelefono2",
        display: "Telefono2",
        oculta: "Telefono2"
    },
    {
        name: "SuministroEMail",
        display: "Email",
        oculta: "Email"
    }];

    $scope.selected = [];

    $scope.exportarDatosClientes = function(selected) {
        var clientes = [];

        selected.forEach(function(obj) {
            clientes.push({
                'Codigo Contrato': obj.CodigoContrato,
                'Identidad': obj.Identidad,
                'Nombre': obj.Nombre,
                'Razon Social': obj.RazonSocial,
                'Direccion': obj.Direccion,
                'Telefono': obj.Telefono,
                'Telefono2': obj.Telefono2,
                'Email': obj.Email
            });
        });

        /**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
		.excelexportjs({
			containerid: "dvjson",
			datatype: 'json',
			dataset: clientes,
			columns: getColumns(clientes)
		});
	};

	$scope.seleccionaTodos = function(){
		$scope.clientes.forEach(function (item) {
			$scope.selected.push(item);
		});
	}

	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

	$scope.recargarDatos =  function(){

		serviciosApi.getListadoClientes()
		.then(function(response) {
			var respuesta = response.data;
			var decodeToken = jwtHelper.decodeToken(respuesta);
			var playloadJson = JSON.parse(decodeToken.iss);
			var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]	});

			var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
			if (tokenValido && !tokenExpirado) {
				$scope.clientes = angular.copy(playloadJson);
			} else {
				$scope.clientes = angular.copy("");
			}

		});
	}

}]);