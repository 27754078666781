app.controller("LoginController", ['$scope', '$http', '$location', '$document', '$mdDialog', 'jwtHelper', function($scope, $http, $location, $document, $mdDialog, jwtHelper) {
    'use strict';

    $scope.error = 'none';
    $scope.loginSpinner = 'hidden';


    //Entro al if de refrescar la cache solo cuando se cierra la sesion por expiracion de tiempo
    //En caso de cerrar sesion por el btn salir se limpia tb

    $scope.nombreEmpresaLogin = nombreEmpresaLogin;
    $scope.nombreEmpresa = nombreEmpresa;

    $scope.MostrarLoginPoliticaUso = MostrarLoginPoliticaUso;
    $scope.MostrarLoginURLPoliticaUso = MostrarLoginURLPoliticaUso;
    $scope.MostrarLoginURLPoliticaUso2 = MostrarLoginURLPoliticaUso2;
    $scope.MostrarLoginURLPoliticaUso3 = MostrarLoginURLPoliticaUso3;
    $scope.TextoPoliticaUso = TextoPoliticaUso;
    $scope.TextoPoliticaUso2 = TextoPoliticaUso2;
    $scope.TextoPoliticaUso3 = TextoPoliticaUso3;
    $scope.comprobacionAceptacionLogin = comprobacionAceptacionLogin;
    $scope.versionCondicionesUso = versionCondicionesUso;

    $scope.MostrarRecuperarContrasena = MostrarRecuperarContrasena;

    $scope.RedirectAfterLogin = RedirectAfterLogin;

    var temp = "";

    if (localStorage.getItem('refresh')) {
        window.location.reload();
    }

    localStorage.clear();

    // var cookies = $cookies.getAll();
    // angular.forEach(cookies, function(v, k) {
    //     $cookies.remove(k);
    // });

    /*var usuario = btoa(usuarioToken);
    var contra = btoa(contraToken);*/


    /*var usuario = btoa(usuarioToken);
    var contra = btoa(contraToken);*/

    //No está metido en servicios pq como se carga antes, mete en undefined todos los campos
    $scope.ValorDesplegableEmpresa = ValorDesplegableEmpresa;
    $http.get(urlApi + "Empresa/GetDTOAll")
        .then(function(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            // console.log(playloadJson);


            //VALIDACION CON LA PALABRA SECRETA DE LA API
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
                    //verifyAt: IntDate.get('20150601000000Z')
            });

            var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.empresas = playloadJson;
				// $scope.empresas = playloadJson.slice(0,1); //eliminar slice cuando tengan gas NO MERGEAR A ROOT  ESTE DEJA LUZ
				// $scope.empresas = playloadJson.slice(1,2); //eliminar slice cuando tengan gas NO MERGEAR A ROOT ESTE DEJA GAS
                if ($scope.empresas.length == 1 && $scope.empresas[0].Nombre == $scope.ValorDesplegableEmpresa) {
                    localStorage.clear();
                    localStorage.setItem('idEmpresa', $scope.empresas[0].IdEmpresa);
                }
                localStorage.setItem('idContratoSituacionWeb', ($scope.empresas[0].EmpresaParametro && $scope.empresas[0].EmpresaParametro.IdContratoSituacionWeb!= null ) ? $scope.empresas[0].EmpresaParametro.IdContratoSituacionWeb : "");
                // console.log("TEST " + localStorage.getItem('idContratoSituacionWeb'));
                temp = localStorage.getItem('idContratoSituacionWeb');
            } else {
                $scope.empresas = "";
                console.log("HASH DE VERIFICACION INCORRECTO");
            }
        });

    $http.get('./version.json').success(function(data) {
        if (data.version.length > 0) {
            $scope.pa_version = data.version;
        }
    });

    $scope.showAlert = function(ev) {
        $mdDialog.show(
            $mdDialog.alert()
            .clickOutsideToClose(false)
            .escapeToClose(false)
            .title('')
            .htmlContent('<i class="fa fa-times" aria-hidden="true"></i> <p class="centrado">Navegador no soportado.</p> <p> Por favor, utilice las últimas versiones de Chrome, Firefox o Edge.</p>')
            .ariaLabel('Navegador no soportado')
            .targetEvent(ev)
            .ok('Aceptar')
        );
    };

    $scope.cargarParametrosEmpresa = function() {
        var IdEmpresa = $scope.login.EmpresaLogin.IdEmpresa;
        localStorage.clear();
        //Cambio de IdEmpresa para DRK Gas
        if(nombreEmpresa == "DRK" && IdEmpresa == 5){
            localStorage.setItem('idEmpresa', 5);
        }else{
            localStorage.setItem('idEmpresa', IdEmpresa);
            localStorage.setItem('idContratoSituacionWeb', temp);
        }
    };


    $scope.comprobarAceptacionCondiciones = function(usuario) {
        if(usuario != undefined && $scope.comprobacionAceptacionLogin){
            // console.log(usuario);

            // consulta de aceptacion condiciones de condiciones de uso por nombre de usuario
            if($scope.comprobacionAceptacionLogin){
                var aceptacionData = {
                    // "Nombre": $scope.datosTitular.NombreTitular,
                    "Version": ($scope.versionCondicionesUso) ? $scope.versionCondicionesUso : '',
                    "Usuario": ($scope.login.usuario) ? $scope.login.usuario : '',
                    "Action": "checkUser"
                    };

                $http({
                    method: "POST",
                    url: "https://agentes.eleiaenergia.com/lib/php/ctrl_aceptacion_condiciones.php",
                    // url: "lib/php/insertar_aceptacion_condiciones.php",
                    data: JSON.stringify(aceptacionData)
                }).then(function(response) {
                    // console.log(response.data);
                    // Si existe el usuario y tiene la version actual aceptada ocultamos el check de las condiciones
                    if(response.data == "true"){
                        $scope.MostrarLoginPoliticaUso = false;
                        // console.log($scope.MostrarLoginPoliticaUso);
                    }

                });
            }


        }
    };


    $scope.submit = function() {

        var usuario = $scope.login.usuario;
        var password = $scope.login.password;
        var empresa = $scope.login.EmpresaLogin.CodSectorEmpresarial;
        $scope.loginSpinner = 'visible';

        localStorage.removeItem('idAgente'); // Limpiamos este parametros por problemas de cache
        localStorage.removeItem('idUsuario'); // Limpiamos este parametros por problemas de cache
		var loginConEntorno = {
			"IdEmpresa" : $scope.login.EmpresaLogin.IdEmpresa
		}
        $http({
            method: "GET",
            url: urlApi + "Usuario/Token",
            contentType: "application/x-www-form-urlencoded",
            headers: {
                User: btoa(usuario),
                Password: btoa(password)
            }
        }).then(function successCallback(response) {
			$scope.tokenValidador = response.data;
            localStorage.setItem('TokenValidador', btoa(response.data));

            $http({
                method: "POST",
                url: urlApi + 'Usuario/LoginPost/Login/' + btoa(usuario) + '/Password/' + btoa(password),
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador'))
                },
				data: JSON.stringify(loginConEntorno)
            }).then(function successCallback(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                //El usuario 0 se devuelve cuando el login es incorrecto
                if (playloadJson.IdUsuario != 0 && playloadJson.IdAgente != null && playloadJson.IsDesactivado == false) {
                    //DistribuidoraElectrica = 1 || ComercializadoraElectrica = 2
                    //ComercializadoraGas = 3 || DistribuidoraGas = 4
                    if (empresa == 1 || empresa == 2) {
                        localStorage.setItem('idEmpresaEnergia', true);
                        localStorage.setItem('idEmpresaGas', false);
                    } else if (empresa == 3 || empresa == 4) {
                        localStorage.setItem('idEmpresaGas', true);
                        localStorage.setItem('idEmpresaEnergia', false);
					}
					var verMenuOpciones = {
						VerAgenda: (playloadJson.VerAgenda) ? playloadJson.VerAgenda : null,
						VerClientes: (playloadJson.VerClientes) ? playloadJson.VerClientes : null,
						VerComisiones: (playloadJson.VerComisiones) ? playloadJson.VerComisiones : null,
						VerContratos: (playloadJson.VerContratos) ? playloadJson.VerContratos : null,
						VerDocumentacion: (playloadJson.VerDocumentacion) ? playloadJson.VerDocumentacion : null,
						VerIncidencias: (playloadJson.VerIncidencias) ? playloadJson.VerIncidencias : null,
						VerLead: (playloadJson.VerLead) ? playloadJson.VerLead : null,
						VerSIPS: (playloadJson.VerSIPS) ? playloadJson.VerSIPS : null
					}

					localStorage.setItem('verMenu', btoa(JSON.stringify(verMenuOpciones)));
                    localStorage.setItem('forzarPassword', !playloadJson.IsPasswordModificada);
                    localStorage.setItem('politicaPrivacidad', playloadJson.politicaPrivacidad);
                    localStorage.setItem('idAgente', playloadJson.IdAgente);
                    localStorage.setItem('idUsuario', playloadJson.IdUsuario);
                    localStorage.setItem('nombreAgente', btoa(playloadJson.NombreAgente));
                    localStorage.setItem('departamentoAgente', btoa(playloadJson.Departamento));
                    localStorage.setItem('tipoAgente', btoa(playloadJson.CodigoTipoAgente));
                    localStorage.setItem('isAdmin', btoa(playloadJson.IsApiAdmin));

                    // Registro de aceptacion condiciones de condiciones de uso
                    if($scope.comprobacionAceptacionLogin){
                        var aceptacionData = {
                            // "Nombre": $scope.datosTitular.NombreTitular,
                            "NombreAgente": (playloadJson.NombreAgente) ? playloadJson.NombreAgente : '',
                            "IdAgente": (playloadJson.IdAgente) ? playloadJson.IdAgente : '',
                            "Version": ($scope.versionCondicionesUso) ? $scope.versionCondicionesUso : '',
                            "Usuario": ($scope.login.usuario) ? $scope.login.usuario : '',
                            "Action": "insert"
                            };

                        $http({
                            method: "POST",
                            url: "https://agentes.eleiaenergia.com/lib/php/ctrl_aceptacion_condiciones.php",
                            // url: "lib/php/insertar_aceptacion_condiciones.php",
                            data: JSON.stringify(aceptacionData)
                        }).then(function(response) {
                            // console.log(response.data);
                        });
                    }


                    /*
                    	NotDefined = 0
                    	Distribuidor = 1
                    	Agente = 2
                    	SubAgente = 3
                    */
                    localStorage.setItem('logueado', true);

                    $location.path($scope.RedirectAfterLogin);

                    return false;

                } else {
                    $scope.error = 'block';
                    $scope.loginSpinner = 'hidden';
                }
            }, function errorCallback(response) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            });

        }, function errorCallback(response) {
            $scope.error = 'block';
            $scope.loginSpinner = 'hidden';
            // called asynchronously if an error occurs
            // or server returns response with an error status.
        });

    };

    var msie = $document[0].documentMode;

    if (msie) {
        $scope.showAlert();
        $scope.isIE = true;
        if (msie === 9) {
            // IE 9 logic here
        }
    }

}]);