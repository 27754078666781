app.service('servicioValidaciones', [ function () {

		var DNI_REGEX = /^(\d{8})([A-Z])$/;
		var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
		var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
	  
		this.ValidateSpanishID = function( str ) {
	  
		  // Ensure upcase and remove whitespace
		  str = str.toUpperCase().replace(/\s/, '');
	  
		  var valid = false;
		  var type = this.spainIdType( str );
	  
		  switch (type) {
			case 'dni':
			  valid = this.validDNI( str );
			  break;
			case 'nie':
			  valid = this.validNIE( str );
			  break;
			case 'cif':
			  valid = this.validCIF( str );
			  break;
		  }
	  
		  return {
			type: type,
			valid: valid
		  };
	  
		};
	  
		this.spainIdType = function( str ) {
		  if ( str.match( DNI_REGEX ) ) {
			return 'dni';
		  }
		  if ( str.match( CIF_REGEX ) ) {
			return 'cif';
		  }
		  if ( str.match( NIE_REGEX ) ) {
			return 'nie';
		  }
		};
	  
		this.validDNI = function( dni ) {
		  var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
		  var letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 );
		  
		  return letter == dni.charAt(8);
		};
	  
		this.validNIE = function( nie ) {
	  
		  // Change the initial letter for the corresponding number and validate as DNI
		  var nie_prefix = nie.charAt( 0 );
	  
		  switch (nie_prefix) {
			case 'X': nie_prefix = 0; break;
			case 'Y': nie_prefix = 1; break;
			case 'Z': nie_prefix = 2; break;
		  }
	  
		  return this.validDNI( nie_prefix + nie.substr(1) );
	  
		};
	  
		this.validCIF = function( cif ) {
	  
		  var match = cif.match( CIF_REGEX );
		  var letter  = match[1],
			  number  = match[2],
			  control = match[3];
	  
		  var even_sum = 0;
		  var odd_sum = 0;
		  var n;
	  
		  for ( var i = 0; i < number.length; i++) {
			n = parseInt( number[i], 10 );
	  
			// Odd positions (Even index equals to odd position. i=0 equals first position)
			if ( i % 2 === 0 ) {
			  // Odd positions are multiplied first.
			  n *= 2;
	  
			  // If the multiplication is bigger than 10 we need to adjust
			  odd_sum += n < 10 ? n : n - 9;
	  
			// Even positions
			// Just sum them
			} else {
			  even_sum += n;
			}
	  
		  }
		  
		//   var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
		  var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1)).toString().substr(-1) ;
		  var control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );

		  // Control must be a digit
		  if ( letter.match( /[ABEH]/ ) ) {
			return control == control_digit;
	  
		  // Control must be a letter
		  } else if ( letter.match( /[KPQS]/ ) ) {
			return control == control_letter;
	  
		  // Can be either
		  } else {
			return control == control_digit || control == control_letter;
		  }
	  
		};


		


		/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */





		/*
		 * Returns 1 if the IBAN is valid 
		 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
		 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
		 */
		this.isValidIBANNumber = function( input ) {

			// contamos si el string contiene mas de 2 letras
			var contadorLetras = 0;
			for (x= 0; x < input.length; x++) {
				if(input[x].match(/[A-Z]/i)!=null){
					contadorLetras = contadorLetras + 1;
				}
			};
			if(contadorLetras > 2){
				return false;
			}


			var CODE_LENGTHS = {
				AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
				CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
				FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
				HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
				LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
				MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
				RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
			};
			var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
					code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
					digits;
			// check syntax and length
			if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
				return false;
			}
			// rearrange country code and check digits, and convert chars to ints
			digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
				return letter.charCodeAt(0) - 55;
			});
			// final check
			return this.mod97(digits);
		}

		this.mod97 = function( string ) {
			var checksum = string.slice(0, 2), fragment;
			for (var offset = 2; offset < string.length; offset += 7) {
				fragment = String(checksum) + string.substring(offset, offset + 7);
				checksum = parseInt(fragment, 10) % 97;
			}
			return checksum;
		}

		/* ******************************************************************************************************************************************************* */



}]);