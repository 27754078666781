app.service('servicioListadoDistribuidoras', [ function () {
	this.getListadoDistribuidoras = function () {
		var distribuidoras = [
			{
				value: "AFRODISIO PASCUAL ALONSO, S.L.",
				display: "AFRODISIO PASCUAL ALONSO, S.L."
			}, {
				value: "AGRI ENERGIA ELECTRICA, S.A.",
				display: "AGRI ENERGIA ELECTRICA, S.A."
			}, {
				value: "AGRUPACION DISTRIBUIDORA DE ESCUER, S.L.",
				display: "AGRUPACION DISTRIBUIDORA DE ESCUER, S.L."
			}, {
				value: "AGUAS DE BARBASTRO ELECTRICIDAD, S.A.",
				display: "AGUAS DE BARBASTRO ELECTRICIDAD, S.A."
			}, {
				value: "ALARCON NAVARRO EMPRESA ELECTRICA, S.L.",
				display: "ALARCON NAVARRO EMPRESA ELECTRICA, S.L."
			}, {
				value: "ALCONERA DE ELECTRICIDAD, S.L.U.",
				display: "ALCONERA DE ELECTRICIDAD, S.L.U."
			}, {
				value: "ALGINET DISTRIBUCIÓN ENERGÍA ELÉCTRICA, S.L.",
				display: "ALGINET DISTRIBUCIÓN ENERGÍA ELÉCTRICA, S.L."
			}, {
				value: "ALSET ELECTRICA, S.L.",
				display: "ALSET ELECTRICA, S.L."
			}, {
				value: "ANSELMO LEON DISTRIBUCION, S.L.",
				display: "ANSELMO LEON DISTRIBUCION, S.L."
			}, {
				value: "ANTOLINA RUIZ RUIZ, S.L.U.",
				display: "ANTOLINA RUIZ RUIZ, S.L.U."
			}, {
				value: "ARAGONESA DE ACTIVIDADES ENERGETICAS, S.A. (AAESA)",
				display: "ARAGONESA DE ACTIVIDADES ENERGETICAS, S.A. (AAESA)"
			}, {
				value: "ARAMAIOKO ARGINDAR BANATZILEA, S.A.",
				display: "ARAMAIOKO ARGINDAR BANATZILEA, S.A."
			}, {
				value: "AURORA GINER REIG, S.L.",
				display: "AURORA GINER REIG, S.L."
			}, {
				value: "BARRAS ELÉCTRICAS GALAICO-ASTURIANAS S.A.",
				display: "BARRAS ELÉCTRICAS GALAICO-ASTURIANAS S.A."
			}, {
				value: "BASSOLS ENERGIA, S.A.",
				display: "BASSOLS ENERGIA, S.A."
			}, {
				value: "BERRUEZA, S.A.",
				display: "BERRUEZA, S.A."
			}, {
				value: "BLAZQUEZ, S.L.",
				display: "BLAZQUEZ, S.L."
			}, {
				value: "CASIMIRO MARCIAL CHACON E HIJOS, S.L.",
				display: "CASIMIRO MARCIAL CHACON E HIJOS, S.L."
			}, {
				value: "CATENERIBAS, S.L.",
				display: "CATENERIBAS, S.L."
			}, {
				value: "CENTRAL ELECTRICA DE POZO LORENTE, S. L.",
				display: "CENTRAL ELECTRICA DE POZO LORENTE, S. L."
			}, {
				value: "CENTRAL ELECTRICA INDUSTRIAL, S.L.",
				display: "CENTRAL ELECTRICA INDUSTRIAL, S.L."
			}, {
				value: "CENTRAL ELECTRICA MITJANS, S.L.",
				display: "CENTRAL ELECTRICA MITJANS, S.L."
			}, {
				value: "CENTRAL ELECTRICA SAN ANTONIO, S.L.",
				display: "CENTRAL ELECTRICA SAN ANTONIO, S.L."
			}, {
				value: "CENTRAL ELECTRICA SAN FRANCISCO, S.L.",
				display: "CENTRAL ELECTRICA SAN FRANCISCO, S.L."
			}, {
				value: "CENTRAL ELECTRICA SESTELO Y CIA, S.A.",
				display: "CENTRAL ELECTRICA SESTELO Y CIA, S.A."
			}, {
				value: "COMMODITY ENERGIA 2002, S.L.",
				display: "COMMODITY ENERGIA 2002, S.L."
			}, {
				value: "COMPAÑÍA DE ELECTRICIDAD DEL CONDADO, S.A.",
				display: "COMPAÑÍA DE ELECTRICIDAD DEL CONDADO, S.A."
			}, {
				value: "COMPAÑIA DE ELECTRIFICACION, S.L.",
				display: "COMPAÑIA DE ELECTRIFICACION, S.L."
			}, {
				value: "COMPAÑIA ELECTRICA DE FEREZ, S.L.",
				display: "COMPAÑIA ELECTRICA DE FEREZ, S.L."
			}, {
				value: "COMPAÑÍA MELILLENSE DE GAS Y ELECTRICIDAD, S.A.",
				display: "COMPAÑÍA MELILLENSE DE GAS Y ELECTRICIDAD, S.A."
			}, {
				value: "COOPERATIVA ELECTRICA ALBORENSE, S.A.",
				display: "COOPERATIVA ELECTRICA ALBORENSE, S.A."
			}, {
				value: "COOPERATIVA ELECTRICA BENEFICA ALBATERENSE, COOP. V.",
				display: "COOPERATIVA ELECTRICA BENEFICA ALBATERENSE, COOP. V."
			}, {
				value: "COOPERATIVA ELECTRICA DE CASTELLAR, S.C.V.",
				display: "COOPERATIVA ELECTRICA DE CASTELLAR, S.C.V."
			}, {
				value: "COOPERATIVA POPULAR DE FLUIDO ELECTRICO DE CAMPRODON S.C.C.L.",
				display: "COOPERATIVA POPULAR DE FLUIDO ELECTRICO DE CAMPRODON S.C.C.L."
			}, {
				value: "COOPERATIVA VALENCIANA ELECTRODISTRIBUIDORA DE FUERZA Y ALUMBRADO SERRALLO",
				display: "COOPERATIVA VALENCIANA ELECTRODISTRIBUIDORA DE FUERZA Y ALUMBRADO SERRALLO"
			}, {
				value: "DECAIL ENERGÍA,S.L.",
				display: "DECAIL ENERGÍA,S.L."
			}, {
				value: "DELGICHI, S.L.",
				display: "DELGICHI, S.L."
			}, {
				value: "DIELEC GUERRERO LORENTE, S.L.",
				display: "DIELEC GUERRERO LORENTE, S.L."
			}, {
				value: "DIELENOR, S.L.",
				display: "DIELENOR, S.L."
			}, {
				value: "DIELESUR, S.L.",
				display: "DIELESUR, S.L."
			}, {
				value: "DISTRIBUCION DE ELECTRICIDAD VALLE DE SANTA ANA, S.L.",
				display: "DISTRIBUCION DE ELECTRICIDAD VALLE DE SANTA ANA, S.L."
			}, {
				value: "Distribución Eléctrica Catralense, S.L.U.",
				display: "Distribución Eléctrica Catralense, S.L.U."
			}, {
				value: "Distribución Eléctrica Crevillent, S.L.U",
				display: "Distribución Eléctrica Crevillent, S.L.U"
			}, {
				value: "DISTRIBUCION ELECTRICA DE ALCOLECHA, S.L.",
				display: "DISTRIBUCION ELECTRICA DE ALCOLECHA, S.L."
			}, {
				value: "DISTRIBUCION ELECTRICA LAS MERCEDES, S.L.",
				display: "DISTRIBUCION ELECTRICA LAS MERCEDES, S.L."
			}, {
				value: "DISTRIBUCION ENERGIA ELECTRICA DE PARCENT, S.L.",
				display: "DISTRIBUCION ENERGIA ELECTRICA DE PARCENT, S.L."
			}, {
				value: "DISTRIBUCIONES ALNEGA, S.L.",
				display: "DISTRIBUCIONES ALNEGA, S.L."
			}, {
				value: "DISTRIBUCIONES DE ENERGIA ELECTRICA DEL NOROESTE, S.L.",
				display: "DISTRIBUCIONES DE ENERGIA ELECTRICA DEL NOROESTE, S.L."
			}, {
				value: "DISTRIBUCIONES ELÉCTRICAS DE GISTAÍN S.L.",
				display: "DISTRIBUCIONES ELÉCTRICAS DE GISTAÍN S.L."
			}, {
				value: "DISTRIBUCIONES ELECTRICAS DE POZUELO, S.A.",
				display: "DISTRIBUCIONES ELECTRICAS DE POZUELO, S.A."
			}, {
				value: "DISTRIBUCIONES ELECTRICAS DEL ERIA, S.L.",
				display: "DISTRIBUCIONES ELECTRICAS DEL ERIA, S.L."
			}, {
				value: "DISTRIBUCIONES ELECTRICAS PORTILLO, S.L.",
				display: "DISTRIBUCIONES ELECTRICAS PORTILLO, S.L."
			}, {
				value: "DISTRIBUCIONES ELÉCTRICAS TALAYUELAS, S.L.",
				display: "DISTRIBUCIONES ELÉCTRICAS TALAYUELAS, S.L."
			}, {
				value: "DISTRIBUIDORA DE ELECTRICIDAD LARRAÑAGA, S.L.",
				display: "DISTRIBUIDORA DE ELECTRICIDAD LARRAÑAGA, S.L."
			}, {
				value: "DISTRIBUIDORA DE ELECTRICIDAD MARTOS MARIN, S.L.",
				display: "DISTRIBUIDORA DE ELECTRICIDAD MARTOS MARIN, S.L."
			}, {
				value: "DISTRIBUIDORA DE ENERGIA ELECTRICA DEL BAGES, S.A.",
				display: "DISTRIBUIDORA DE ENERGIA ELECTRICA DEL BAGES, S.A."
			}, {
				value: "DISTRIBUIDORA DE ENERGIA ELECTRICA ENERQUINTA, S.L.",
				display: "DISTRIBUIDORA DE ENERGIA ELECTRICA ENERQUINTA, S.L."
			}, {
				value: "DISTRIBUIDORA DE ENERGIA ELECTRICA ENRIQUE GARCIA SERRANO, S.L.",
				display: "DISTRIBUIDORA DE ENERGIA ELECTRICA ENRIQUE GARCIA SERRANO, S.L."
			}, {
				value: "DISTRIBUIDORA DE ENERGIA ELECTRICA TORRECILLAS VIDAL, S.L.",
				display: "DISTRIBUIDORA DE ENERGIA ELECTRICA TORRECILLAS VIDAL, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA BRAVO SAEZ, S.L.",
				display: "DISTRIBUIDORA ELECTRICA BRAVO SAEZ, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA CARRION, S.L.",
				display: "DISTRIBUIDORA ELECTRICA CARRION, S.L."
			}, {
				value: "DISTRIBUIDORA ELÉCTRICA D'ALBATARREC, S.L.",
				display: "DISTRIBUIDORA ELÉCTRICA D'ALBATARREC, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE ARDALES, S.L.",
				display: "DISTRIBUIDORA ELECTRICA DE ARDALES, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE CASAS DE LAZARO, S.A.",
				display: "DISTRIBUIDORA ELECTRICA DE CASAS DE LAZARO, S.A."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE CATOIRA, S.A.",
				display: "DISTRIBUIDORA ELECTRICA DE CATOIRA, S.A."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE GAUCIN, S.L.",
				display: "DISTRIBUIDORA ELECTRICA DE GAUCIN, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE MELON, S.L.",
				display: "DISTRIBUIDORA ELECTRICA DE MELON, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE MONESTERIO, S.L.",
				display: "DISTRIBUIDORA ELECTRICA DE MONESTERIO, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE MONTOLIU, S.L. U.",
				display: "DISTRIBUIDORA ELECTRICA DE MONTOLIU, S.L. U."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DE RELLEU, S.L.",
				display: "DISTRIBUIDORA ELECTRICA DE RELLEU, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DEL PUERTO DE LA CRUZ, S.A.",
				display: "DISTRIBUIDORA ELECTRICA DEL PUERTO DE LA CRUZ, S.A."
			}, {
				value: "DISTRIBUIDORA ELECTRICA DEL SIL, S.L.",
				display: "DISTRIBUIDORA ELECTRICA DEL SIL, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA GRANJA DE TORREHERMOSA, S.L.",
				display: "DISTRIBUIDORA ELECTRICA GRANJA DE TORREHERMOSA, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA ISABA, S.L.U.",
				display: "DISTRIBUIDORA ELECTRICA ISABA, S.L.U."
			}, {
				value: "DISTRIBUIDORA ELECTRICA LOS BERMEJALES, S.A.",
				display: "DISTRIBUIDORA ELECTRICA LOS BERMEJALES, S.A."
			}, {
				value: "DISTRIBUIDORA ELÉCTRICA NAVASFRIAS, S.L.",
				display: "DISTRIBUIDORA ELÉCTRICA NAVASFRIAS, S.L."
			}, {
				value: "DISTRIBUIDORA ELECTRICA NIEBLA, S.L.",
				display: "DISTRIBUIDORA ELECTRICA NIEBLA, S.L."
			}, {
				value: "DISTRIBUIDORA ELÉCTRICA VALLE DE ANSÓ, S.L.",
				display: "DISTRIBUIDORA ELÉCTRICA VALLE DE ANSÓ, S.L."
			}, {
				value: "E. SAAVEDRA, S.A.",
				display: "E. SAAVEDRA, S.A."
			}, {
				value: "EBROFANAS, S.L.",
				display: "EBROFANAS, S.L."
			}, {
				value: "EL PROGRESO DEL PIRINEO, S.L.",
				display: "EL PROGRESO DEL PIRINEO, S.L."
			}, {
				value: "ELECTRA ADURIZ, S.A.",
				display: "ELECTRA ADURIZ, S.A."
			}, {
				value: "ELECTRA ALTO MIÑO, S.A.",
				display: "ELECTRA ALTO MIÑO, S.A."
			}, {
				value: "ELECTRA ALVARO BENITO, S.L.",
				display: "ELECTRA ALVARO BENITO, S.L."
			}, {
				value: "ELECTRA AUTOL, S.A.",
				display: "ELECTRA AUTOL, S.A."
			}, {
				value: "ELECTRA AVELLANA, S.L.",
				display: "ELECTRA AVELLANA, S.L."
			}, {
				value: "ELECTRA CALDENSE, S.A.",
				display: "ELECTRA CALDENSE, S.A."
			}, {
				value: "ELECTRA CASTILLEJENSE, S.A.",
				display: "ELECTRA CASTILLEJENSE, S.A."
			}, {
				value: "ELECTRA CONILENSE, S.L.U.",
				display: "ELECTRA CONILENSE, S.L.U."
			}, {
				value: "ELECTRA CUNTIENSE, S.L.",
				display: "ELECTRA CUNTIENSE, S.L."
			}, {
				value: "ELECTRA DE CABALAR, S.L.",
				display: "ELECTRA DE CABALAR, S.L."
			}, {
				value: "ELECTRA DE CARBAYIN, S.A.",
				display: "ELECTRA DE CARBAYIN, S.A."
			}, {
				value: "ELECTRA DE SANTA COMBA, S.L.",
				display: "ELECTRA DE SANTA COMBA, S.L."
			}, {
				value: "ELECTRA DE ZAS, S.L.",
				display: "ELECTRA DE ZAS, S.L."
			}, {
				value: "ELECTRA DEL CARDENER, S.A.",
				display: "ELECTRA DEL CARDENER, S.A."
			}, {
				value: "ELECTRA DEL GAYOSO, S.L.",
				display: "ELECTRA DEL GAYOSO, S.L."
			}, {
				value: "ELECTRA DEL LLOBREGAT ENERGIA, S.L.",
				display: "ELECTRA DEL LLOBREGAT ENERGIA, S.L."
			}, {
				value: "ELECTRA DEL NARAHIO, S.A.",
				display: "ELECTRA DEL NARAHIO, S.A."
			}, {
				value: "ELECTRA DO FOXO, S.L.",
				display: "ELECTRA DO FOXO, S.L."
			}, {
				value: "ELECTRA JOSE ANTONIO MARTINEZ, S.L.",
				display: "ELECTRA JOSE ANTONIO MARTINEZ, S.L."
			}, {
				value: "ELECTRA LA HONORINA, S.L.",
				display: "ELECTRA LA HONORINA, S.L."
			}, {
				value: "ELECTRA LA LOMA, S.L.",
				display: "ELECTRA LA LOMA, S.L."
			}, {
				value: "ELECTRA LA ROSA, S.L.",
				display: "ELECTRA LA ROSA, S.L."
			}, {
				value: "ELECTRA ORBAICETA, S.L.",
				display: "ELECTRA ORBAICETA, S.L."
			}, {
				value: "ELECTRA REDENERGIA, S.L.",
				display: "ELECTRA REDENERGIA, S.L."
			}, {
				value: "ELECTRA SALTEA, S.L.",
				display: "ELECTRA SALTEA, S.L."
			}, {
				value: "ELECTRA SAN BARTOLOME, S.L.",
				display: "ELECTRA SAN BARTOLOME, S.L."
			}, {
				value: "ELECTRA SAN CRISTOBAL, S.L.",
				display: "ELECTRA SAN CRISTOBAL, S.L."
			}, {
				value: "ELECTRA SIERRA MAGINA, S.L.",
				display: "ELECTRA SIERRA MAGINA, S.L."
			}, {
				value: "ELECTRA TUDANCA, S.L.",
				display: "ELECTRA TUDANCA, S.L."
			}, {
				value: "ELECTRA VALDIVIELSO, S.A.",
				display: "ELECTRA VALDIVIELSO, S.A."
			}, {
				value: "ELECTRA VALDIZARBE, S.A.",
				display: "ELECTRA VALDIZARBE, S.A."
			}, {
				value: "ELECTRADISTRIBUCIÓ CENTELLES, S.L.",
				display: "ELECTRADISTRIBUCIÓ CENTELLES, S.L."
			}, {
				value: "ELECTRICA ABENGIBRENSE DISTRIBUCION, S.L.",
				display: "ELECTRICA ABENGIBRENSE DISTRIBUCION, S.L."
			}, {
				value: "ELECTRICA ALGIMIA DE ALFARA, SOCIEDAD COOPERATIVA VALENCIANA",
				display: "ELECTRICA ALGIMIA DE ALFARA, SOCIEDAD COOPERATIVA VALENCIANA"
			}, {
				value: "ELECTRICA ANTONIO MADRID, S.L.",
				display: "ELECTRICA ANTONIO MADRID, S.L."
			}, {
				value: "ELECTRICA BAÑESA, S.L.",
				display: "ELECTRICA BAÑESA, S.L."
			}, {
				value: "ELECTRICA BELMEZANA, S.A.",
				display: "ELECTRICA BELMEZANA, S.A."
			}, {
				value: "ELECTRICA CAMPOSUR, S.L.",
				display: "ELECTRICA CAMPOSUR, S.L."
			}, {
				value: "ELECTRICA CONQUENSE DISTRIBUCION, S.A.U.",
				display: "ELECTRICA CONQUENSE DISTRIBUCION, S.A.U."
			}, {
				value: "ELECTRICA CORVERA, S.L.",
				display: "ELECTRICA CORVERA, S.L."
			}, {
				value: "ELÉCTRICA COSTUR, S.L.,",
				display: "ELÉCTRICA COSTUR, S.L.,"
			}, {
				value: "ELECTRICA CUROS, S.L.",
				display: "ELECTRICA CUROS, S.L."
			}, {
				value: "ELECTRICA DE ALBERGUERIA, S.A.",
				display: "ELECTRICA DE ALBERGUERIA, S.A."
			}, {
				value: "ELECTRICA DE BARCIADEMERA, S.L.",
				display: "ELECTRICA DE BARCIADEMERA, S.L."
			}, {
				value: "ELÉCTRICA DE BIAR DISTRIBUCIÓN, S.L.U.",
				display: "ELÉCTRICA DE BIAR DISTRIBUCIÓN, S.L.U."
			}, {
				value: "ELECTRICA DE CABAÑAS, S.L.",
				display: "ELECTRICA DE CABAÑAS, S.L."
			}, {
				value: "ELECTRICA DE CALLOSA DE SEGURA, S.V. L.",
				display: "ELECTRICA DE CALLOSA DE SEGURA, S.V. L."
			}, {
				value: "ELECTRICA DE CANILES, S.L.",
				display: "ELECTRICA DE CANILES, S.L."
			}, {
				value: "ELECTRICA DE CANTOÑA, S.L.",
				display: "ELECTRICA DE CANTOÑA, S.L."
			}, {
				value: "ELECTRICA DE CASTRO CALDELAS, S.L.",
				display: "ELECTRICA DE CASTRO CALDELAS, S.L."
			}, {
				value: "ELECTRICA DE CHERA, SOCIEDAD COOPERATIVA VALENCIANA",
				display: "ELECTRICA DE CHERA, SOCIEDAD COOPERATIVA VALENCIANA"
			}, {
				value: "ELECTRICA DE DURRO, S.L.",
				display: "ELECTRICA DE DURRO, S.L."
			}, {
				value: "ELECTRICA DE ERISTE, S.L.",
				display: "ELECTRICA DE ERISTE, S.L."
			}, {
				value: "ELECTRICA DE GRES, S.L.",
				display: "ELECTRICA DE GRES, S.L."
			}, {
				value: "ELECTRICA DE GUADASSUAR, SDAD. COOP. V.",
				display: "ELECTRICA DE GUADASSUAR, SDAD. COOP. V."
			}, {
				value: "ELECTRICA DE GUIXES, S.L.",
				display: "ELECTRICA DE GUIXES, S.L."
			}, {
				value: "ELECTRICA DE JAFRE, S.A.",
				display: "ELECTRICA DE JAFRE, S.A."
			}, {
				value: "ELECTRICA DE LA SERRANIA DE RONDA, S.L",
				display: "ELECTRICA DE LA SERRANIA DE RONDA, S.L"
			}, {
				value: "ELECTRICA DE LIJAR,S.L.",
				display: "ELECTRICA DE LIJAR,S.L."
			}, {
				value: "ELÉCTRICA DE MALCOCINADO, S.L.U.",
				display: "ELÉCTRICA DE MALCOCINADO, S.L.U."
			}, {
				value: "ELECTRICA DE MELIANA, SOCIEDAD COOPERATIVA VALENCIANA",
				display: "ELECTRICA DE MELIANA, SOCIEDAD COOPERATIVA VALENCIANA"
			}, {
				value: "ELECTRICA DE MOSCOSO, S.L.",
				display: "ELECTRICA DE MOSCOSO, S.L."
			}, {
				value: "ELÉCTRICA DE SOT DE CHERA, SOC. COOPERATIVA VALENCIANA",
				display: "ELÉCTRICA DE SOT DE CHERA, SOC. COOPERATIVA VALENCIANA"
			}, {
				value: "ELÉCTRICA DE SUDANELL, S.L.",
				display: "ELÉCTRICA DE SUDANELL, S.L."
			}, {
				value: "ELECTRICA DE TENTUDIA. S.A.",
				display: "ELECTRICA DE TENTUDIA. S.A."
			}, {
				value: "ELECTRICA DE VALDRIZ, S.L.",
				display: "ELECTRICA DE VALDRIZ, S.L."
			}, {
				value: "ELECTRICA DE VER, S.L.",
				display: "ELECTRICA DE VER, S.L."
			}, {
				value: "ELECTRICA DE VINALESA, S.C.V.",
				display: "ELECTRICA DE VINALESA, S.C.V."
			}, {
				value: "ELECTRICA DEL EBRO, S.A.",
				display: "ELECTRICA DEL EBRO, S.A."
			}, {
				value: "ELECTRICA DEL GUADALFEO, S.L.",
				display: "ELECTRICA DEL GUADALFEO, S.L."
			}, {
				value: "ELECTRICA DEL HUEBRA, S.L.",
				display: "ELECTRICA DEL HUEBRA, S.L."
			}, {
				value: "ELECTRICA DEL MONTSEC, S.L.",
				display: "ELECTRICA DEL MONTSEC, S.L."
			}, {
				value: "ELECTRICA DEL OESTE DISTRIBUCION, S.L.U.",
				display: "ELECTRICA DEL OESTE DISTRIBUCION, S.L.U."
			}, {
				value: "ELECTRICA DEL POZO S.COOP.MAD.",
				display: "ELECTRICA DEL POZO S.COOP.MAD."
			}, {
				value: "ELECTRICA GILENA, S.L.U.",
				display: "ELECTRICA GILENA, S.L.U."
			}, {
				value: "ELECTRICA HERMANOS CASTRO RODRIGUEZ, S.L",
				display: "ELECTRICA HERMANOS CASTRO RODRIGUEZ, S.L"
			}, {
				value: "ELECTRICA LA VICTORIA DE FUENCALIENTE, S.A.",
				display: "ELECTRICA LA VICTORIA DE FUENCALIENTE, S.A."
			}, {
				value: "ELECTRICA LATORRE, S.L.",
				display: "ELECTRICA LATORRE, S.L."
			}, {
				value: "ELECTRICA LOS LAURELES, S.L.",
				display: "ELECTRICA LOS LAURELES, S.L."
			}, {
				value: "ELECTRICA LOS MOLINOS, S.L.",
				display: "ELECTRICA LOS MOLINOS, S.L."
			}, {
				value: "ELECTRICA LOS PELAYOS, S.A.",
				display: "ELECTRICA LOS PELAYOS, S.A."
			}, {
				value: "ELECTRICA MESTANZA R.V., S.L.",
				display: "ELECTRICA MESTANZA R.V., S.L."
			}, {
				value: "ELECTRICA MORO BENITO, S.L.",
				display: "ELECTRICA MORO BENITO, S.L."
			}, {
				value: "ELÉCTRICA MUNICIPAL DE SANTA COLOMA DE QUERALT S.L.",
				display: "ELÉCTRICA MUNICIPAL DE SANTA COLOMA DE QUERALT S.L."
			}, {
				value: "ELECTRICA NUESTRA SEÑORA DE LOS SANTOS, S.L.",
				display: "ELECTRICA NUESTRA SEÑORA DE LOS SANTOS, S.L."
			}, {
				value: "ELECTRICA POPULAR, S. COOP. MAD.",
				display: "ELECTRICA POPULAR, S. COOP. MAD."
			}, {
				value: "ELECTRICA SAGRADO CORAZON DE JESUS, S.L.",
				display: "ELECTRICA SAGRADO CORAZON DE JESUS, S.L."
			}, {
				value: "ELECTRICA SALAS DE PALLARS, S.L.",
				display: "ELECTRICA SALAS DE PALLARS, S.L."
			}, {
				value: "ELÉCTRICA SAN GREGORIO, S.L.",
				display: "ELÉCTRICA SAN GREGORIO, S.L."
			}, {
				value: "ELECTRICA SAN JOSE OBRERO, S.L.",
				display: "ELECTRICA SAN JOSE OBRERO, S.L."
			}, {
				value: "ELECTRICA SAN MARCOS, S.L.",
				display: "ELECTRICA SAN MARCOS, S.L."
			}, {
				value: "ELECTRICA SAN SERVAN, S.L.",
				display: "ELECTRICA SAN SERVAN, S.L."
			}, {
				value: "ELECTRICA SANTA CLARA, S.L.",
				display: "ELECTRICA SANTA CLARA, S.L."
			}, {
				value: "ELECTRICA SANTA MARTA Y VILLALBA, S.L.",
				display: "ELECTRICA SANTA MARTA Y VILLALBA, S.L."
			}, {
				value: "ELECTRICA SEROSENSE DISTRIBUIDORA, S.L.",
				display: "ELECTRICA SEROSENSE DISTRIBUIDORA, S.L."
			}, {
				value: "ELECTRICA VAQUER, S.A.",
				display: "ELECTRICA VAQUER, S.A."
			}, {
				value: "ELÉCTRICAS COLLADO BLANCO, S.L.",
				display: "ELÉCTRICAS COLLADO BLANCO, S.L."
			}, {
				value: "ELECTRICAS DE BENUZA, S.L.",
				display: "ELECTRICAS DE BENUZA, S.L."
			}, {
				value: "ELÉCTRICAS DE VALLANCA, S.L.",
				display: "ELÉCTRICAS DE VALLANCA, S.L."
			}, {
				value: "ELECTRICAS DE VILLAHERMOSA, S.A.",
				display: "ELECTRICAS DE VILLAHERMOSA, S.A."
			}, {
				value: "ELÉCTRICAS HIDROBESORA, S.L.",
				display: "ELÉCTRICAS HIDROBESORA, S.L."
			}, {
				value: "ELÉCTRICAS LA ENGUERINA, S.L.",
				display: "ELÉCTRICAS LA ENGUERINA, S.L."
			}, {
				value: "ELECTRICAS PITARCH DISTRIBUCION, S.L.U.",
				display: "ELECTRICAS PITARCH DISTRIBUCION, S.L.U."
			}, {
				value: "ELECTRICAS SANTA LEONOR, S.L.",
				display: "ELECTRICAS SANTA LEONOR, S.L."
			}, {
				value: "ELECTRICAS TUEJAR, S.L.",
				display: "ELECTRICAS TUEJAR, S.L."
			}, {
				value: "ELECTRICIDAD DE PUERTO REAL, S.A. (EPRESA)",
				display: "ELECTRICIDAD DE PUERTO REAL, S.A. (EPRESA)"
			}, {
				value: "ELECTRICIDAD HIJATE, S.L.",
				display: "ELECTRICIDAD HIJATE, S.L."
			}, {
				value: "ELECTRICIDAD LA ASUNCION, S.L.",
				display: "ELECTRICIDAD LA ASUNCION, S.L."
			}, {
				value: "ELECTRICIDAD PASTOR, S.L.",
				display: "ELECTRICIDAD PASTOR, S.L."
			}, {
				value: "ELECTRICITAT L AURORA, S.A.",
				display: "ELECTRICITAT L AURORA, S.A."
			}, {
				value: "ELECTRO DISTRIBUCION DE ALMODOVAR DEL CAMPO, S.A.",
				display: "ELECTRO DISTRIBUCION DE ALMODOVAR DEL CAMPO, S.A."
			}, {
				value: "ELECTRO DISTRIBUIDORA CASTELLANO LEONESA, S.A.",
				display: "ELECTRO DISTRIBUIDORA CASTELLANO LEONESA, S.A."
			}, {
				value: "ELECTRO ESCARRILLA, S.L.",
				display: "ELECTRO ESCARRILLA, S.L."
			}, {
				value: "ELECTRO MANZANEDA, S.L.",
				display: "ELECTRO MANZANEDA, S.L."
			}, {
				value: "ELECTRO MOLINERA DE VALMADRIGAL, S.L.",
				display: "ELECTRO MOLINERA DE VALMADRIGAL, S.L."
			}, {
				value: "ELECTRO SALLENT DE GALLEGO, S.L.",
				display: "ELECTRO SALLENT DE GALLEGO, S.L."
			}, {
				value: "ELECTRODISTRIBUIDORA DE FUERZA Y ALUB. \"CASABLANCA\" SDAD. COOP. V.",
				display: "ELECTRODISTRIBUIDORA DE FUERZA Y ALUB. \"CASABLANCA\" SDAD. COOP. V."
			}, {
				value: "ELECTROHARINERA BELSETANA, SOCIEDAD COOPERATIVA",
				display: "ELECTROHARINERA BELSETANA, SOCIEDAD COOPERATIVA"
			}, {
				value: "ELEC-VALL BOI, S.L.",
				display: "ELEC-VALL BOI, S.L."
			}, {
				value: "ELEKTRA URDAZUBI, S.L.",
				display: "ELEKTRA URDAZUBI, S.L."
			}, {
				value: "EMDECORIA, S.L.",
				display: "EMDECORIA, S.L."
			}, {
				value: "EMILIO PADILLA E HIJOS, S.L.",
				display: "EMILIO PADILLA E HIJOS, S.L."
			}, {
				value: "EMPRESA DE ALUMBRADO ELECTRICO DE CEUTA DISTRIBUCION, S.A.U.",
				display: "EMPRESA DE ALUMBRADO ELECTRICO DE CEUTA DISTRIBUCION, S.A.U."
			}, {
				value: "EMPRESA DE ELECTRICIDAD SAN JOSE, S.A.",
				display: "EMPRESA DE ELECTRICIDAD SAN JOSE, S.A."
			}, {
				value: "EMPRESA ELECTRICA DE JORQUERA, S.L.",
				display: "EMPRESA ELECTRICA DE JORQUERA, S.L."
			}, {
				value: "EMPRESA ELECTRICA DE SAN PEDRO, S.L.",
				display: "EMPRESA ELECTRICA DE SAN PEDRO, S.L."
			}, {
				value: "EMPRESA ELÉCTRICA DEL CABRIEL, S.L.",
				display: "EMPRESA ELÉCTRICA DEL CABRIEL, S.L."
			}, {
				value: "EMPRESA ELECTRICA MARTIN SILVA POZO, S.L.",
				display: "EMPRESA ELECTRICA MARTIN SILVA POZO, S.L."
			}, {
				value: "EMPRESA MUNICIPAL DE DISTRIBUCIÓ DENERGIA ELECTRICA DALMENAR, S.L.U. (SOCIETAT-UNIPERSONAL)",
				display: "EMPRESA MUNICIPAL DE DISTRIBUCIÓ DENERGIA ELECTRICA DALMENAR, S.L.U. (SOCIETAT-UNIPERSONAL)"
			}, {
				value: "EMPRESA MUNICIPAL DE DISTRIBUCIÓ DENERGIA ELÈCTRICA DE PONTS, S.L.",
				display: "EMPRESA MUNICIPAL DE DISTRIBUCIÓ DENERGIA ELÈCTRICA DE PONTS, S.L."
			}, {
				value: "EMPRESA MUNICIPAL DENERGIA ELECTRICA TORRES DEL SEGRE, S.L.",
				display: "EMPRESA MUNICIPAL DENERGIA ELECTRICA TORRES DEL SEGRE, S.L."
			}, {
				value: "ENDESA DISTRIBUCION ELECTRICA, S.L.",
				display: "ENDESA DISTRIBUCION ELECTRICA, S.L."
			}, {
				value: "ENERFRIAS, S.L.",
				display: "ENERFRIAS, S.L."
			}, {
				value: "ENERGETICA DE ALCOCER, S.L.U.",
				display: "ENERGETICA DE ALCOCER, S.L.U."
			}, {
				value: "ENERGIA DE MIAJADAS, S.A.",
				display: "ENERGIA DE MIAJADAS, S.A."
			}, {
				value: "ENERGÍA ELÉCTRICA DE OLVERA, S.L.U",
				display: "ENERGÍA ELÉCTRICA DE OLVERA, S.L.U"
			}, {
				value: "ENERGIAS DE ARAGON I, S. L. U. (EASA)",
				display: "ENERGIAS DE ARAGON I, S. L. U. (EASA)"
			}, {
				value: "ENERGIAS DE BENASQUE, S.L.",
				display: "ENERGIAS DE BENASQUE, S.L."
			}, {
				value: "ENERGÍAS DE LA VILLA DE CAMPO, S.L.U.",
				display: "ENERGÍAS DE LA VILLA DE CAMPO, S.L.U."
			}, {
				value: "ENERGIAS DE PANTICOSA, S.L.",
				display: "ENERGIAS DE PANTICOSA, S.L."
			}, {
				value: "ENERGÍAS DEL ZINQUETA SL",
				display: "ENERGÍAS DEL ZINQUETA SL"
			}, {
				value: "ESTABANELL Y PAHISA ENERGIA, S.A.",
				display: "ESTABANELL Y PAHISA ENERGIA, S.A."
			}, {
				value: "FELIPE BLAZQUEZ, S.L.",
				display: "FELIPE BLAZQUEZ, S.L."
			}, {
				value: "FELIX GONZALEZ, S.A.",
				display: "FELIX GONZALEZ, S.A."
			}, {
				value: "FLUIDO ELECTRICO DE MUSEROS, S. C. VALENCIANA",
				display: "FLUIDO ELECTRICO DE MUSEROS, S. C. VALENCIANA"
			}, {
				value: "FUCIÑOS RIVAS, S.L.",
				display: "FUCIÑOS RIVAS, S.L."
			}, {
				value: "FUENTES Y COMPAÑIA, S.L.",
				display: "FUENTES Y COMPAÑIA, S.L."
			}, {
				value: "FUERZAS ELECTRICAS BOGARRA, S.A.",
				display: "FUERZAS ELECTRICAS BOGARRA, S.A."
			}, {
				value: "GESTION DEL SERVICIO ELÉCTRICO HECHO, S.L.",
				display: "GESTION DEL SERVICIO ELÉCTRICO HECHO, S.L."
			}, {
				value: "GLORIA MARISCAL, S.L.",
				display: "GLORIA MARISCAL, S.L."
			}, {
				value: "GRACIA UNZUETA HIDALGO E HIJOS, S.L.",
				display: "GRACIA UNZUETA HIDALGO E HIJOS, S.L."
			}, {
				value: "GRUPO DE ELECTRIFICACION RURAL DE BINEFAR Y COMARCA, S.COOP., R. L.",
				display: "GRUPO DE ELECTRIFICACION RURAL DE BINEFAR Y COMARCA, S.COOP., R. L."
			}, {
				value: "HELIODORA GOMEZ, S.A.",
				display: "HELIODORA GOMEZ, S.A."
			}, {
				value: "HELIODORO CHAFER, S.L.",
				display: "HELIODORO CHAFER, S.L."
			}, {
				value: "HEREDEROS DE CARLOS OLTRA, S.L.",
				display: "HEREDEROS DE CARLOS OLTRA, S.L."
			}, {
				value: "HEREDEROS DE EMILIO GAMERO, S.L.",
				display: "HEREDEROS DE EMILIO GAMERO, S.L."
			}, {
				value: "HEREDEROS DE GARCIA BAZ, S.L.",
				display: "HEREDEROS DE GARCIA BAZ, S.L."
			}, {
				value: "HEREDEROS DE MARIA ALONSO CALZADA-VENTA DE BAÑOS, S.L.",
				display: "HEREDEROS DE MARIA ALONSO CALZADA-VENTA DE BAÑOS, S.L."
			}, {
				value: "HERMANOS CABALLERO REBOLLO, S.L.",
				display: "HERMANOS CABALLERO REBOLLO, S.L."
			}, {
				value: "HIDROCANTABRICO DISTRIBUCION ELECTRICA, S.A.",
				display: "HIDROCANTABRICO DISTRIBUCION ELECTRICA, S.A."
			}, {
				value: "HIDROELECTRICA COTO MINERO DISTRIBUCION, S.L.U.",
				display: "HIDROELECTRICA COTO MINERO DISTRIBUCION, S.L.U."
			}, {
				value: "HIDROELECTRICA DE ALARAZ, S.L.",
				display: "HIDROELECTRICA DE ALARAZ, S.L."
			}, {
				value: "HIDROELECTRICA DE CATALUNYA, S.L.",
				display: "HIDROELECTRICA DE CATALUNYA, S.L."
			}, {
				value: "HIDROELECTRICA DE LARACHA, S.L.",
				display: "HIDROELECTRICA DE LARACHA, S.L."
			}, {
				value: "HIDROELECTRICA DE SILLEDA, S.L.",
				display: "HIDROELECTRICA DE SILLEDA, S.L."
			}, {
				value: "HIDROELECTRICA DEL ARNEGO, S.L.",
				display: "HIDROELECTRICA DEL ARNEGO, S.L."
			}, {
				value: "HIDROELECTRICA DEL CABRERA, S.L.",
				display: "HIDROELECTRICA DEL CABRERA, S.L."
			}, {
				value: "HIDROELECTRICA DEL GUADIELA I, S.A.",
				display: "HIDROELECTRICA DEL GUADIELA I, S.A."
			}, {
				value: "HIDROELECTRICA DOMINGUEZ, S.L.",
				display: "HIDROELECTRICA DOMINGUEZ, S.L."
			}, {
				value: "HIDROELECTRICA EL CARMEN, S.L.",
				display: "HIDROELECTRICA EL CARMEN, S.L."
			}, {
				value: "HIDROELECTRICA EL CERRAJON, S.L.",
				display: "HIDROELECTRICA EL CERRAJON, S.L."
			}, {
				value: "HIDROELECTRICA GOMEZ, S.L.",
				display: "HIDROELECTRICA GOMEZ, S.L."
			}, {
				value: "HIDROELECTRICA JOSE MATANZA GARCIA, S.L.",
				display: "HIDROELECTRICA JOSE MATANZA GARCIA, S.L."
			}, {
				value: "HIDROELECTRICA SAN BUENAVENTURA, S.L.",
				display: "HIDROELECTRICA SAN BUENAVENTURA, S.L."
			}, {
				value: "HIDROELECTRICA SAN CIPRIANO DE RUEDA, S.L.",
				display: "HIDROELECTRICA SAN CIPRIANO DE RUEDA, S.L."
			}, {
				value: "HIDROELECTRICA SANTA TERESA, S.L.",
				display: "HIDROELECTRICA SANTA TERESA, S.L."
			}, {
				value: "HIDROELECTRICA VEGA, S.A.",
				display: "HIDROELECTRICA VEGA, S.A."
			}, {
				value: "HIDROELECTRICA VIRGEN DE CHILLA, S.L.",
				display: "HIDROELECTRICA VIRGEN DE CHILLA, S.L."
			}, {
				value: "HIDROFLAMICELL, S.L.",
				display: "HIDROFLAMICELL, S.L."
			}, {
				value: "HIJO DE JORGE MARTIN, S.A.",
				display: "HIJO DE JORGE MARTIN, S.A."
			}, {
				value: "HIJOS DE CASIANO SANCHEZ, S.L.",
				display: "HIJOS DE CASIANO SANCHEZ, S.L."
			}, {
				value: "HIJOS DE FELIPE GARCIA ALVAREZ, S.L.",
				display: "HIJOS DE FELIPE GARCIA ALVAREZ, S.L."
			}, {
				value: "HIJOS DE FRANCISCO ESCASO S.L.",
				display: "HIJOS DE FRANCISCO ESCASO S.L."
			}, {
				value: "HIJOS DE JACINTO GUILLEN DISTRIBUIDORA ELECTRICA, S.L.",
				display: "HIJOS DE JACINTO GUILLEN DISTRIBUIDORA ELECTRICA, S.L."
			}, {
				value: "HIJOS DE MANUEL PERLES VICENS, S.L.",
				display: "HIJOS DE MANUEL PERLES VICENS, S.L."
			}, {
				value: "IBERDROLA DISTRIBUCION ELECTRICA, S.A.",
				display: "IBERDROLA DISTRIBUCION ELECTRICA, S.A."
			}, {
				value: "ICASA DISTRIBUCION ENERGIA, S.L.",
				display: "ICASA DISTRIBUCION ENERGIA, S.L."
			}, {
				value: "IGNALUZ JIMENEZ DE TORRES, S.L.",
				display: "IGNALUZ JIMENEZ DE TORRES, S.L."
			}, {
				value: "INDUSTRIAL BARCALESA, S.L.",
				display: "INDUSTRIAL BARCALESA, S.L."
			}, {
				value: "INPECUARIAS POZOBLANCO, S.L.",
				display: "INPECUARIAS POZOBLANCO, S.L."
			}, {
				value: "INPECUARIAS TORRECAMPO, S.L.",
				display: "INPECUARIAS TORRECAMPO, S.L."
			}, {
				value: "INPECUARIAS VILLARALTO, S.L.",
				display: "INPECUARIAS VILLARALTO, S.L."
			}, {
				value: "ISMAEL BIOSCA, S.L.",
				display: "ISMAEL BIOSCA, S.L."
			}, {
				value: "JOSE FERRE SEGURA E HIJOS, S.R.L.",
				display: "JOSE FERRE SEGURA E HIJOS, S.R.L."
			}, {
				value: "JOSE RIPOLL ALBANELL, S.L.",
				display: "JOSE RIPOLL ALBANELL, S.L."
			}, {
				value: "JOSEFA GIL COSTA, S.L.",
				display: "JOSEFA GIL COSTA, S.L."
			}, {
				value: "JUAN DE FRUTOS GARCIA, S.L.",
				display: "JUAN DE FRUTOS GARCIA, S.L."
			}, {
				value: "JUAN N. DIAZ GALVEZ Y HERMANOS, S.L.",
				display: "JUAN N. DIAZ GALVEZ Y HERMANOS, S.L."
			}, {
				value: "JUAN Y FRANCISCO ESTEVE MAS S.L.",
				display: "JUAN Y FRANCISCO ESTEVE MAS S.L."
			}, {
				value: "LA CONSTANCIA-ARÉN, S.L.",
				display: "LA CONSTANCIA-ARÉN, S.L."
			}, {
				value: "LA ELECTRICA DE VALL DE EBO, S.L.",
				display: "LA ELECTRICA DE VALL DE EBO, S.L."
			}, {
				value: "LA ERNESTINA, S.A.",
				display: "LA ERNESTINA, S.A."
			}, {
				value: "LA PROHIDA DISTRIBUCIÓN ELÉCTRICA, S.L.",
				display: "LA PROHIDA DISTRIBUCIÓN ELÉCTRICA, S.L."
			}, {
				value: "LA SINARQUENSE, S.L.U.",
				display: "LA SINARQUENSE, S.L.U."
			}, {
				value: "LEANDRO PEREZ ALFONSO, S.L.",
				display: "LEANDRO PEREZ ALFONSO, S.L."
			}, {
				value: "LEINTZARGI, S.L.",
				display: "LEINTZARGI, S.L."
			}, {
				value: "LERSA ELECTRICITAT, S.L.",
				display: "LERSA ELECTRICITAT, S.L."
			}, {
				value: "LLUM D'AIN, S.L.",
				display: "LLUM D'AIN, S.L."
			}, {
				value: "LUIS RANGEL Y HERMANOS, S.A.",
				display: "LUIS RANGEL Y HERMANOS, S.A."
			}, {
				value: "LUZ DE CELA, S.L.",
				display: "LUZ DE CELA, S.L."
			}, {
				value: "LUZ ELECTRICA DE ALGAR,S.L.U.",
				display: "LUZ ELECTRICA DE ALGAR,S.L.U."
			}, {
				value: "LUZ ELECTRICA LOS MOLARES, S.L.",
				display: "LUZ ELECTRICA LOS MOLARES, S.L."
			}, {
				value: "MAESTRAZGO DISTRIBUCIÓN ELÉCTRICA, S.L.",
				display: "MAESTRAZGO DISTRIBUCIÓN ELÉCTRICA, S.L."
			}, {
				value: "MANUEL ROBRES CELADES, S.L.",
				display: "MANUEL ROBRES CELADES, S.L."
			}, {
				value: "MEDINA GARVEY ELECTRICIDAD, S.L.U.",
				display: "MEDINA GARVEY ELECTRICIDAD, S.L.U."
			}, {
				value: "MILLARENSE DE ELECTRICIDAD, S.A.U",
				display: "MILLARENSE DE ELECTRICIDAD, S.A.U"
			}, {
				value: "MOLINO VIEJO DE VILALLER, S.A.",
				display: "MOLINO VIEJO DE VILALLER, S.A."
			}, {
				value: "MONTESLUZ DISTRIBUCION ELECTRICA, S.L.",
				display: "MONTESLUZ DISTRIBUCION ELECTRICA, S.L."
			}, {
				value: "MUNICIPAL ELECTRICA VILORIA, S.L.",
				display: "MUNICIPAL ELECTRICA VILORIA, S.L."
			}, {
				value: "OÑARGI, S.L.",
				display: "OÑARGI, S.L."
			}, {
				value: "PEDRO SANCHEZ IBAÑEZ, S.L.",
				display: "PEDRO SANCHEZ IBAÑEZ, S.L."
			}, {
				value: "PEUSA Distribució, S.L.",
				display: "PEUSA Distribució, S.L."
			}, {
				value: "REPSOL ELECTRICA DE DISTRIBUCION, S.L.",
				display: "REPSOL ELECTRICA DE DISTRIBUCION, S.L."
			}, {
				value: "RODALEC, S.L.",
				display: "RODALEC, S.L."
			}, {
				value: "ROMERO CANDAU, S.L.",
				display: "ROMERO CANDAU, S.L."
			}, {
				value: "RUIZ DE LA TORRE, S.L.",
				display: "RUIZ DE LA TORRE, S.L."
			}, {
				value: "SALTOS DEL CABRERA, S.L.",
				display: "SALTOS DEL CABRERA, S.L."
			}, {
				value: "SAMPOL ENERGÍA,S.L.",
				display: "SAMPOL ENERGÍA,S.L."
			}, {
				value: "SAN MIGUEL 2000 DISTRIBUCION, S.L.",
				display: "SAN MIGUEL 2000 DISTRIBUCION, S.L."
			}, {
				value: "SERVICIOS URBANOS DE CERLER, S.A. (SUCSA)",
				display: "SERVICIOS URBANOS DE CERLER, S.A. (SUCSA)"
			}, {
				value: "SERVICIOS Y SUMINISTROS MUNICIPALES ARAS, S.L.",
				display: "SERVICIOS Y SUMINISTROS MUNICIPALES ARAS, S.L."
			}, {
				value: "SERVICIOS Y SUMINISTROS MUNICIPALES DE CHULILLA, S.L.",
				display: "SERVICIOS Y SUMINISTROS MUNICIPALES DE CHULILLA, S.L."
			}, {
				value: "SERVILIANO GARCIA, S.A.",
				display: "SERVILIANO GARCIA, S.A."
			}, {
				value: "SIERRO DE ELECTRICIDAD, S.L.",
				display: "SIERRO DE ELECTRICIDAD, S.L."
			}, {
				value: "SOCIEDAD DISTRIBUIDORA ELECTRICA DE ELORRIO, S.A.",
				display: "SOCIEDAD DISTRIBUIDORA ELECTRICA DE ELORRIO, S.A."
			}, {
				value: "SOCIEDAD ELECTRICA DE RIBERA DEL FRESNO, S.A.",
				display: "SOCIEDAD ELECTRICA DE RIBERA DEL FRESNO, S.A."
			}, {
				value: "SOCIEDAD ELECTRICA JEREZ DEL MARQUESADO S.A.",
				display: "SOCIEDAD ELECTRICA JEREZ DEL MARQUESADO S.A."
			}, {
				value: "SOCIEDAD ELECTRICA NTRA. SRA. DE LOS DESAMPARADOS, S. L.",
				display: "SOCIEDAD ELECTRICA NTRA. SRA. DE LOS DESAMPARADOS, S. L."
			}, {
				value: "SOCIEDAD ELECTRICISTA DE TUY, S.A.",
				display: "SOCIEDAD ELECTRICISTA DE TUY, S.A."
			}, {
				value: "SOCIETAT MUNICIPAL DE DISTRIBUCIÓ ELÉCTRICA DE LLAVORSÍ, S.L.",
				display: "SOCIETAT MUNICIPAL DE DISTRIBUCIÓ ELÉCTRICA DE LLAVORSÍ, S.L."
			}, {
				value: "SOCIETAT MUNICIPAL DE DISTRIBUCIÓ ELECTRICA DE TIRVIA, S.L.",
				display: "SOCIETAT MUNICIPAL DE DISTRIBUCIÓ ELECTRICA DE TIRVIA, S.L."
			}, {
				value: "SUCESORES DE MANUEL LEIRA, S.L.",
				display: "SUCESORES DE MANUEL LEIRA, S.L."
			}, {
				value: "SUMINISTRADORA ELECTRICA DE CADIZ, S.A.",
				display: "SUMINISTRADORA ELECTRICA DE CADIZ, S.A."
			}, {
				value: "SUMINISTRO DE LUZ Y FUERZA, S.L.",
				display: "SUMINISTRO DE LUZ Y FUERZA, S.L."
			}, {
				value: "SUMINISTROS ELECTRICOS DE AMIEVA, S.L.",
				display: "SUMINISTROS ELECTRICOS DE AMIEVA, S.L."
			}, {
				value: "SUMINISTROS ELÉCTRICOS ISÁBENA, S.L.",
				display: "SUMINISTROS ELÉCTRICOS ISÁBENA, S.L."
			}, {
				value: "TALARN DISTRIBUCIÓ MUNINCIPAL ELÈCTRICA, S.L.,",
				display: "TALARN DISTRIBUCIÓ MUNINCIPAL ELÈCTRICA, S.L.,"
			}, {
				value: "TOLARGI, S.L.",
				display: "TOLARGI, S.L."
			}, {
				value: "UNION DE DISTRIBUIDORES DE ELECTRICIDAD, S.A. (UDESA)",
				display: "UNION DE DISTRIBUIDORES DE ELECTRICIDAD, S.A. (UDESA)"
			}, {
				value: "UNION FENOSA DISTRIBUCION, S.A.",
				display: "UNION FENOSA DISTRIBUCION, S.A."
			}, {
				value: "VALL DE SÓLLER ENERGÍA, S.L.U.",
				display: "VALL DE SÓLLER ENERGÍA, S.L.U."
			}, {
				value: "VARGAS Y COMPAÑIA ELECTRO HARINERA SAN RAMON, S.A.",
				display: "VARGAS Y COMPAÑIA ELECTRO HARINERA SAN RAMON, S.A."
			}, {
				value: "VIESGO DISTRIBUCIÓN ELÉCTRICA, S.L.",
				display: "VIESGO DISTRIBUCIÓN ELÉCTRICA, S.L."
			}];

		return distribuidoras;
	};
}]);