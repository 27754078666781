app.controller('ContratosMesCtrl', ['$scope', function($scope) {
  'use strict';

	var ResumenContratosPorMes = $scope.resumenContratosPorMes;

  var ArrayResumenContratosPorMesFecha = new Array("");
  var ArrayResumenContratosPorMesNumeroContratos = new Array("");

  for(var i=0; i<ResumenContratosPorMes.length; i++){
    ArrayResumenContratosPorMesFecha.push(ResumenContratosPorMes[i].FechaDeContrato);
    ArrayResumenContratosPorMesNumeroContratos.push(ResumenContratosPorMes[i].NumeroDeContratos);
  }

  var ctx = document.getElementById("graficaResumenContratos");
  var graficaResumenContratos = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      datasets: [{
        label: 'Contratos',
        data: ArrayResumenContratosPorMesNumeroContratos,
        backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero:true
          }
        }]
      }
    }
  });

}]);