angular.module('RDash')
	.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$breadcrumbProvider', 'cfpLoadingBarProvider', 'IdleProvider', 'KeepaliveProvider', 'TitleProvider', 'calendarConfig', '$mdDateLocaleProvider',
		function ($stateProvider, $urlRouterProvider, $httpProvider, $breadcrumbProvider, cfpLoadingBarProvider, IdleProvider, KeepaliveProvider, TitleProvider, calendarConfig, $mdDateLocaleProvider) {
			'use strict';

			$urlRouterProvider.when('/', '/login');
			$urlRouterProvider.when('', '/login');
			$urlRouterProvider.otherwise('/login');
			$stateProvider
				.state('base', {
					abstract: true,
					url: '',
					templateUrl: 'templates/base.html'
				})
				.state('login', {
					url: '/login',
					parent: 'base',
					templateUrl: 'templates/login.html',
					controller: 'LoginController'
				})
				.state('menu', {
					url: '',
					parent: 'base',
					templateUrl: 'templates/menu.html',
					controller: 'MenuCtrl',
					ncyBreadcrumb: {
						label: '{{nombreEmpresa}}'
					}
				})
				.state('recovery', {
					url: '/recovery-password',
					parent: 'base',
					templateUrl: 'templates/recovery-password.html',
					controller: 'RecoveryPasswordCtrl',
					ncyBreadcrumb: {
						label: 'Recuperar Contraseña'
					}
				})
				.state('password', {
					url: '/password',
					parent: 'menu',
					templateUrl: 'templates/password.html',
					controller: 'CambiarPasswordCtrl',
					ncyBreadcrumb: {
						label: 'Cambiar Contraseña'
					}
				})
				.state('resumen', {
					url: '/resumen',
					parent: 'menu',
					templateUrl: 'templates/resumen.html',
					controller: 'ResumenCtrl',
					ncyBreadcrumb: {
						label: 'Resumen'
					}
				})
				.state('sips', {
					url: '/sips',
					parent: 'menu',
					templateUrl: 'templates/sips.html',
					controller: 'SipsCtrl',
					ncyBreadcrumb: {
						label: 'SIPS'
					}
				})
				.state('comparador', {
					url: '/comparador',
					parent: 'menu',
					templateUrl: 'templates/comparador.html',
					controller: 'ComparadorCtrl',
					ncyBreadcrumb: {
						label: 'Comparador'
					}
				})
				.state('comparadorGas', {
					url: '/comparador-gas',
					parent: 'menu',
					templateUrl: 'templates/comparador-gas.html',
					controller: 'ComparadorGasCtrl',
					ncyBreadcrumb: {
						label: 'Comparador Gas'
					}
				})
				.state('facturas', {
					url: '/facturas',
					parent: 'menu',
					templateUrl: 'templates/factura-listado.html',
					controller: 'FacturasCtrl',
					ncyBreadcrumb: {
						label: 'Facturas'
					}
				})
				.state('contratos', {
					url: '/contratos',
					parent: 'menu',
					templateUrl: 'templates/contrato-listado.html',
					controller: 'ContratosCtrl',
					ncyBreadcrumb: {
						label: 'Contratos'
					}
				})
				.state('contratosPorMes', {
					url: '/contratos/mes',
					parent: 'menu',
					templateUrl: 'templates/contrato-grafico-mes.html',
					controller: 'ContratosMesCtrl',
					ncyBreadcrumb: {
						label: 'Resumen Mensual',
						parent: 'contratos'
					}
				})
				.state('contratosRenovacion', {
					url: '/contratos/renovacion',
					parent: 'menu',
					templateUrl: 'templates/contrato-renovacion-listado.html',
					controller: 'ContratosRenovacionCtrl',
					ncyBreadcrumb: {
						label: 'Renovación',
						parent: 'contratos'
					}
				})
				.state('resumen_contratos', {
					url: '/contratos/resumen',
					parent: 'menu',
					templateUrl: 'templates/contrato-perfil.html',
					controller: 'ResumenContratosCtrl',
					ncyBreadcrumb: {
						label: 'Resumen Contratos',
						parent: 'contratos'
					}
				})
				.state('alta_contratos', {
					url: '/contratos/alta',
					parent: 'menu',
					templateUrl: 'templates/contrato-alta.html',
					controller: 'AltaContratosCtrl',
					ncyBreadcrumb: {
						label: 'Alta Contratos',
						parent: 'contratos'
					}
				})
				.state('renovacion_contrato', {
					url: '/contratos/renovacion_contrato',
					parent: 'menu',
					templateUrl: 'templates/renovaciones-ultimo-mes.html',
					controller: 'RenovacionesUltimoMesCtrl',
					ncyBreadcrumb: {
						label: 'Renovación Contratos',
						parent: 'contratos'
					}
				})
				.state('alta_rapida_contratos', {
					url: '/contrato/alta-rapida',
					parent: 'menu',
					templateUrl: 'templates/contrato-alta-rapida.html',
					controller: 'AltaRapidaContratosCtrl',
					ncyBreadcrumb: {
						label: 'Alta Contratos',
						parent: 'contratos'
					}
				})
				.state('busqueda_contratos', {
					url: '/contratos/busqueda',
					parent: 'menu',
					templateUrl: 'templates/contratosBusqueda.html',
					controller: 'BusquedaContratosCtrl',
					ncyBreadcrumb: {
						label: 'Búsqueda',
						parent: 'contratos'
					}
				})
				.state('cups', {
					url: '/contratos/cups',
					parent: 'menu',
					templateUrl: 'templates/cups-perfil.html',
					controller: 'CupsCtrl',
					ncyBreadcrumb: {
						label: 'CUPS',
						parent: 'contratos'
					}
				})
				.state('clientes', {
					url: '/clientes',
					parent: 'menu',
					templateUrl: 'templates/cliente-listado.html',
					controller: 'ListadoClientesCtrl',
					ncyBreadcrumb: {
						label: 'Clientes'
					}
				})
				.state('perfil_cliente', {
					url: '/clientes/perfil',
					parent: 'menu',
					templateUrl: 'templates/cliente-perfil.html',
					controller: 'PerfilClienteCtrl',
					ncyBreadcrumb: {
						label: 'Perfil Cliente',
						parent: 'clientes'
					}
				})
				.state('comisiones', {
					url: '/comisiones',
					parent: 'menu',
					templateUrl: 'templates/comision-listado.html',
					controller: 'ComisionesCtrl',
					ncyBreadcrumb: {
						label: 'Comisiones'
					}
				})
				.state('comerciales', {
					url: '/comerciales',
					parent: 'menu',
					templateUrl: 'templates/comercial-listado.html',
					controller: 'ComercialesCtrl',
					ncyBreadcrumb: {
						label: 'Comerciales'
					}
				})
				.state('incidencias', {
					url: '/incidencias',
					parent: 'menu',
					templateUrl: 'templates/incidencia-listado.html',
					controller: 'IncidenciasCtrl',
					ncyBreadcrumb: {
						label: 'Comunicado'
					}
				})
				.state('reclamaciones', {
					url: '/reclamaciones',
					parent: 'menu',
					templateUrl: 'templates/incidencia-listado.html',
					controller: 'IncidenciasCtrl',
					ncyBreadcrumb: {
						label: 'Reclamaciones'
					}
				})
				.state('documentacion', {
					url: '/documentacion',
					parent: 'menu',
					templateUrl: 'templates/documentacion.html',
					controller: 'DocumentacionCtrl',
					ncyBreadcrumb: {
						label: 'Documentación'
					}
				})
				.state('agenda', {
					url: '/agenda',
					parent: 'menu',
					templateUrl: 'templates/agenda.html',
					controller: 'AgendaCtrl',
					ncyBreadcrumb: {
						label: 'Agenda'
					}
				})
				.state('lead', {
					url: '/lead',
					parent: 'menu',
					templateUrl: 'templates/lead-listado.html',
					controller: 'TeleventaCtrl',
					ncyBreadcrumb: {
						label: 'Lead'
					}
				})
				.state('televentaLeads', {
					url: '/lead/leads',
					parent: 'menu',
					templateUrl: 'templates/lead-alta.html',
					controller: 'TeleventaLeadsCtrl',
					ncyBreadcrumb: {
						label: 'Leads',
						parent: 'lead'
					}
				})
				.state('codigosPromocionales', {
					url: '/codigospromocionales/listado',
					parent: 'menu',
					templateUrl: 'templates/codigos-promocionales.html',
					controller: 'CodigosPromocionalesCtrl',
					ncyBreadcrumb: {
						label: 'CodigosPromocionales'

					}
				})
				.state('incidencia', {
					url: '/incidencia',
					parent: 'menu',
					templateUrl: 'templates/comunicado-listado.html',
					controller: 'ComunicadosCtrl',
					ncyBreadcrumb: {
						label: 'Incidencias'
					}
				})
				.state('renovaciones', {
					url: '/renovaciones',
					parent: 'menu',
					templateUrl: 'templates/renovaciones-listado.html',
					controller: 'RenovacionesCtrl',
					ncyBreadcrumb: {
						label: 'Renovaciones'
					}
				})
				.state('informes', {
					url: '/informes',
					parent: 'menu',
					templateUrl: 'templates/informes.html',
					controller: 'InformesCtrl',
					ncyBreadcrumb: {
						label: 'Informes'

					}
				});

			//Loader
			cfpLoadingBarProvider.includeSpinner = false;
			cfpLoadingBarProvider.includeBar = true;

			//Configuracion session usuario
			IdleProvider.idle(900); // 15 min 900
			IdleProvider.timeout(60); //60
			KeepaliveProvider.interval(1);
			TitleProvider.enabled(false);

			calendarConfig.allDateFormats.angular.title.week = 'Semana {week} de {year}';

			moment.locale('es', {
				week: {
					dow: 1,
					doy: 4
				}
			});

			//Configuracion de DatePicker
			$mdDateLocaleProvider.formatDate = function (date) {
				return moment(date).format('DD/MM/YYYY');
			};

		}]);

app.run(['Idle', function (Idle) {
	Idle.watch();
}]);