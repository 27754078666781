app.controller('TeleventaLeadsCtrl', ['$scope', '$http', 'serviciosApi', '$mdDialog', '$state', 'jwtHelper', 'serviciosBOE', function($scope, $http, serviciosApi, $mdDialog, $state, jwtHelper, serviciosBOE) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdAgente = localStorage.getItem('idAgente');
    var NombreAgente = atob(localStorage.getItem('nombreAgente'));
    var IdEmpresa = localStorage.getItem('idEmpresa');
    var Logueado = localStorage.getItem('logueado');

    /* Valores a mostrar */
    $scope.MostrarContratoAltaPerfilFacturacion = MostrarContratoAltaPerfilFacturacion;
    $scope.MostrarTarjetaClubDisa = MostrarTarjetaClubDisa;
    $scope.MostrarBonosDisa = MostrarBonosDisa;
    $scope.MostrarTeleventaCodigoEmpleado = MostrarTeleventaCodigoEmpleado;
    $scope.MostrarSegmento = MostrarSegmento;
    $scope.MostrarTipoCliente = MostrarTipoCliente;
    $scope.MostrarCanalOrigen = MostrarCanalOrigen;
    $scope.MostrarSubcanalOrigen = MostrarSubcanalOrigen;
    $scope.MostrarCodigoPromocional = MostrarCodigoPromocional;


    /* Valores requeridos */
    $scope.requiredGeneralCUPS = requiredGeneralCUPS;
    $scope.requiredGeneralCodDistribuidora = requiredGeneralCodDistribuidora;
    $scope.requiredGeneralCodComercializadora = requiredGeneralCodComercializadora;
    $scope.requiredGeneralCiudad = requiredGeneralCiudad;
    $scope.requiredGeneralCalle = requiredGeneralCalle;
    $scope.requiredGeneralNumero = requiredGeneralNumero;
    $scope.requiredGeneralAclarador = requiredGeneralAclarador;
    $scope.requiredGeneralCodPostal = requiredGeneralCodPostal;
    $scope.requiredGeneralProvincia = requiredGeneralProvincia;

    $scope.requiredPotenciasTarifaAcceso = requiredPotenciasTarifaAcceso;

    $scope.requiredServiciosGrupoProducto = requiredServiciosGrupoProducto;
    $scope.requiredServiciosProducto = requiredServiciosProducto;

    $scope.requiredContratoIdentificador = requiredContratoIdentificador;
    $scope.requiredContratoCodigoPostal = requiredContratoCodigoPostal;
    $scope.requiredContratoCiudad = requiredContratoCiudad;
    $scope.requiredContratoCalle = requiredContratoCalle;
    $scope.requiredContratoNumero = requiredContratoNumero;
    $scope.requiredContratoAclarador = requiredContratoAclarador;
    $scope.requiredContratoProvincia = requiredContratoProvincia;
    $scope.requiredContratoTelefono = requiredContratoTelefono;
    $scope.requiredContratoEmail = requiredContratoEmail;

    $scope.requiredDatosEnvioNombre = requiredDatosEnvioNombre;
    $scope.requiredDatosEnvioCodPostal = requiredDatosEnvioCodPostal;
    $scope.requiredDatosEnvioCiudad = requiredDatosEnvioCiudad;
    $scope.requiredDatosEnvioCalle = requiredDatosEnvioCalle;
    $scope.requiredDatosEnvioNumero = requiredDatosEnvioNumero;
    $scope.requiredDatosEnvioAclarador = requiredDatosEnvioAclarador;
    $scope.requiredDatosEnvioProvincia = requiredDatosEnvioProvincia;

    $scope.requiredOtrosNombrePagador = requiredOtrosNombrePagador;
    $scope.requiredOtrosIdentificador = requiredOtrosIdentificador;
    $scope.requiredOtrosTipoCobro = requiredOtrosTipoCobro;
    $scope.requiredOtrosIBAN = requiredOtrosIBAN;

    $scope.requiredOtrosPeriodo = requiredOtrosPeriodo;
    $scope.requiredOtrosTipoImpresion = requiredOtrosTipoImpresion;
    $scope.requiredOtrosTipoSolicitud = requiredOtrosTipoSolicitud;
    $scope.requiredOtrosModeloContrato = requiredOtrosModeloContrato;
    $scope.requiredOtrosCodigoVendedor = requiredOtrosCodigoVendedor;

    $scope.requiredLeadNombre = requiredLeadNombre;
    $scope.requiredLeadTelefono = requiredLeadTelefono;
    $scope.requiredCodigoEmpleado = requiredCodigoEmpleado;

    $scope.optionsegmento = "";

    $scope.ciudadItem = {
        desCiudad: ""
    };
    $scope.calleItem = {};

    var bonoCheckeado;

    $scope.cambiarEstadoBono = function(checkbox, bono) {
        if (checkbox.Bono10Checked == true && bono == "11") {
            checkbox.Bono10Checked = false;
            checkbox.Bono11Checked = true;
            bonoCheckeado = "10";
        } else if (checkbox.Bono11Checked == true && bono == "10") {
            checkbox.Bono10Checked = true;
            checkbox.Bono11Checked = false;
            bonoCheckeado = "11";
        } else if (checkbox.Bono11Checked == false && checkbox.Bono11Checked == false) {
            bonoCheckeado = "";
        } else {
            if (bono == 10) {
                bonoCheckeado = "10";
            } else {
                bonoCheckeado = "11";
            }
        }
    };

    $scope.showAlert = function(ev) {
        $mdDialog.show(
            $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('')
            .htmlContent('<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Lead insertado correctamente.</p>')
            .ariaLabel('Lead Insertado')
            .ok('Aceptar')
            .targetEvent(ev)
        );
    };

    if (atob(localStorage.getItem('tipoAlta')) == 'e') {
        //var IdEmpresa = atob(localStorage.getItem('idEmpresaEnergia');
        $scope.VerGas = false;
        $scope.VerElectricidad = true;
    }

    if (atob(localStorage.getItem('tipoAlta')) == 'g') {
        //var IdEmpresa = localStorage.getItem('idEmpresaGas');
        $scope.VerGas = true;
        $scope.VerElectricidad = false;
    }
    // $scope.$watch('Identificador', function() {
    //   return $scope.Identificador;
    // }, function(newVal) {
    // console.log(newVal);
    // });

    $http({
        method: "GET",
        url: urlApi + "Tarifa/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }

    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        //VALIDACION CON LA PALABRA SECRETA DE LA API
        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.TarifasAcceso = playloadJson;
        } else {
            $scope.TarifasAcceso = "";
            console.log("HASH DE VERIFICACION INCORRECTO");
        }

    });

    $scope.clonarDatos = function(formulario) {
        var evt = new CustomEvent('change');

        var identificadorTitularCopiado = document.getElementsByName("IdentificadorTitularCopiado")[0];
        var nombreTitularCopiado = document.getElementsByName("NombreTitularCopiado")[0];
        var apellidosTitularCopiado = document.getElementsByName("ApellidosTitularCopiado")[0];
        var emailTitularCopiado = document.getElementsByName("EmailTitularCopiado")[0];
        var razonSocialTitularCopiado = document.getElementsByName("RazonSocialTitularCopiado")[0];
        var telefonoTitularCopiado = document.getElementsByName("TelefonoTitularCopiado")[0];
        var codPostalTitularCopiado = document.getElementsByName("CodPostalTitularCopiado")[0];
        var ciudadTitularCopiado = document.getElementsByName("CiudadTitularCopiado")[0];
        var provinciaTitularCopiado = document.getElementsByName("ProvinciaTitularCopiado")[0];
        var calleTitularCopiado = document.getElementsByName("CalleTitularCopiado")[0];
        var numeroTitularCopiado = document.getElementsByName("NumeroTitularCopiado")[0];
        var aclaradorTitularCopiado = document.getElementsByName("AclaradorTitularCopiado")[0];

        identificadorTitularCopiado.value = document.getElementsByName("Identificador")[0].value;
        identificadorTitularCopiado.dispatchEvent(evt);
        nombreTitularCopiado.value = document.getElementsByName("NombreTitular")[0].value;
        nombreTitularCopiado.dispatchEvent(evt);
        apellidosTitularCopiado.value = document.getElementsByName("ApellidosTitular")[0].value;
        apellidosTitularCopiado.dispatchEvent(evt);
        emailTitularCopiado.value = document.getElementsByName("EmailTitular")[0].value;
        emailTitularCopiado.dispatchEvent(evt);
        razonSocialTitularCopiado.value = document.getElementsByName("RazonSocialTitular")[0].value;
        razonSocialTitularCopiado.dispatchEvent(evt);
        telefonoTitularCopiado.value = document.getElementsByName("TelefonoTitular")[0].value;
        telefonoTitularCopiado.dispatchEvent(evt);
        codPostalTitularCopiado.value = document.getElementsByName("CodPostalTitular")[0].value;
        codPostalTitularCopiado.dispatchEvent(evt);
        // ciudadTitularCopiado.value = document.getElementsByName("CiudadTitular")[0].value;
        // ciudadTitularCopiado.dispatchEvent(evt);
        ciudadTitularCopiado = $scope.ciudadItem;
        calleTitularCopiado = $scope.calleItem;
        provinciaTitularCopiado.value = document.getElementsByName("ProvinciaTitular")[0].value;
        provinciaTitularCopiado.dispatchEvent(evt);
        // calleTitularCopiado.value = document.getElementsByName("CalleTitular")[0].value;
        // calleTitularCopiado.dispatchEvent(evt);
        numeroTitularCopiado.value = document.getElementsByName("NumeroTitular")[0].value;
        numeroTitularCopiado.dispatchEvent(evt);
        aclaradorTitularCopiado.value = document.getElementsByName("AclaradorTitular")[0].value;
        aclaradorTitularCopiado.dispatchEvent(evt);
    };

    $scope.rellenar = function(Identificador, nTitular, apellidost, rSocial, cpTitular, ciudadTitular, calleTitular, numeroTitular, aclaradorTitular, provinciaTitular, telefonoTitular, emailTitular) {
        var dni = Identificador.$$rawModelValue;
        $http({
            method: "GET",
            url: urlApi + "Cliente/GetClientePorDni/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Dni/" + dni,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            // $scope.cliente = data;
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            // console.log(playloadJson);
            $scope.cliente = playloadJson;
            var nombre = document.getElementsByName("NombreTitular")[0];
            var apellido1 = document.getElementsByName("ApellidosTitular")[0];
            var razonSocial = document.getElementsByName("RazonSocialTitular")[0];
            var ciudad = document.getElementsByName("CiudadTitular")[0];
            var cp = document.getElementsByName("CodPostalTitular")[0];
            var calle = document.getElementsByName("CalleTitular")[0];
            var numero = document.getElementsByName("NumeroTitular")[0];
            var aclarador = document.getElementsByName("AclaradorTitular")[0];
            var telefono = document.getElementsByName("TelefonoTitular")[0];
            var email = document.getElementsByName("EmailTitular")[0];

            var evt = new CustomEvent('change');

            if ($scope.cliente.Nombre) {
                nombre.value = $scope.cliente.Nombre;
                nombre.dispatchEvent(evt);
                nTitular.$setValidity('required', true);

                apellido1.value = $scope.cliente.Apellido1 + " " + $scope.cliente.Apellido2;
                apellido1.dispatchEvent(evt);
                apellidost.$setValidity('required', true);
            }

            if ($scope.cliente.RazonSocial) {
                razonSocial.value = $scope.cliente.RazonSocial;
                razonSocial.dispatchEvent(evt);
                rSocial.$setValidity('required', true);
            }

            cp.value = $scope.cliente.CodPostal;
            cp.dispatchEvent(evt);

            $scope.ciudadItem = {
                desCiudad: {
                    desCiudad: playloadJson.Ciudad
                }
            };

            $scope.calleItem = {
                // desCalle: response.data.DatosTitular[0].DireccionSuministro
                desCompleta: {
                    desCompleta: playloadJson.Calle
                }
            };

            // ciudad.value = $scope.cliente.Ciudad;
            // ciudad.dispatchEvent(evt);

            // calle.value = $scope.cliente.Calle;
            // calle.dispatchEvent(evt);



            numero.value = $scope.cliente.Numero;
            numero.dispatchEvent(evt);

            var clienteContacto0 = $scope.cliente.ClientesContacto[0];
            var clienteContacto1 = $scope.cliente.ClientesContacto[1];

            if (clienteContacto0) {
                if (clienteContacto0.TipoContacto == "E") {
                    email.value = clienteContacto0["Valor"];
                    email.dispatchEvent(evt);
                    $scope.ClienteNoRegistrado = false;
                } else if (clienteContacto0.TipoContacto == "T") {
                    telefono.value = clienteContacto0["Valor"];
                    telefono.dispatchEvent(evt);
                    $scope.ClienteNoRegistrado = false;
                }
            }

            if (clienteContacto1) {
                if (clienteContacto1.TipoContacto == "E") {
                    email.value = clienteContacto1["Valor"];
                    email.dispatchEvent(evt);
                    $scope.ClienteNoRegistrado = false;
                } else if (clienteContacto1.TipoContacto == "T") {
                    telefono.value = clienteContacto1["Valor"];
                    telefono.dispatchEvent(evt);
                    $scope.ClienteNoRegistrado = false;
                }
            }

            if ($scope.cliente.Nombre == null && $scope.cliente.RazonSocial == null) {
                $scope.ClienteNoRegistrado = true;
            }
        });
    };

    $scope.rellenarCups = function(Cups, DireccionSuministro, Tarifa, PotenciaP1, PotenciaP2, PotenciaP3, PotenciaP4, PotenciaP5, PotenciaP6) {
        var cups = Cups.$viewValue;
        var direccionSuministro = document.getElementsByName("DireccionSuministro")[0];
        var tarifa = document.getElementsByName("TarifaAccesoElectricidad")[0];
        var potenciaP1 = document.getElementsByName("PotenciaP1")[0];
        var potenciaP2 = document.getElementsByName("PotenciaP2")[0];
        var potenciaP3 = document.getElementsByName("PotenciaP3")[0];
        var potenciaP4 = document.getElementsByName("PotenciaP4")[0];
        var potenciaP5 = document.getElementsByName("PotenciaP5")[0];
        var potenciaP6 = document.getElementsByName("PotenciaP6")[0];

        var evt = new CustomEvent('change');

        serviciosApi.getSips(cups, "", "", "", "", "", "")
            .then(function successCallback(responseSIPS) {

                var response = serviciosBOE.respuestaSIPS(responseSIPS);

                if (response.data.ClientesSips.length == 0) {
                    $scope.CupsNoRegistrado = true;
                } else {
					$scope.CupsNoRegistrado = false;

                    direccionSuministro.value = response.data.ClientesSips[0]["DesMunicipioPS"] + " " + response.data.ClientesSips[0]["DesProvinciaPS"] + " " + response.data.ClientesSips[0]["CodigoPostalPS"];
                    direccionSuministro.dispatchEvent(evt);

                    if ($scope.VerElectricidad) {
							switch (response.data.ClientesSips[0]["CodigoTarifaATREnVigor"]) {
                                case "001": case "004": case "005": case "006": case "007": case "008":
                                    $scope.Tarifa = "2.0TD ML"; //2.0A ML
                                    break;
                                case "003":
                                    $scope.Tarifa = "3.0TD ML"; //3.0A ML
                                    break;
                                case "011":
                                    $scope.Tarifa = "6.1TD ML"; //3.1A ML
                                    break;
                                case "012":
                                    $scope.Tarifa = "6.1TD ML"; //6.1A ML
                                    break;
                                case "013":
                                    $scope.Tarifa = "6.2TD ML"; //6.2 ML
                                    break;
                                case "014":
                                    $scope.Tarifa = "6.3TD ML"; //6.3 ML
                                    break;
                                case "015":
                                    $scope.Tarifa = "6.4TD ML"; //6.4 ML
                                    break;
                                case "016":
                                    $scope.Tarifa = "6.4TD ML"; //6.5 ML
                                    break;
                                case "017":
                                    $scope.Tarifa = "6.1B ML"; //6.1B ML
                                    break;
                                case "018":
                                    $scope.Tarifa = "2.0TD ML";
                                    break;
                                case "019":
                                    $scope.Tarifa = "3.0TD ML";
                                    break;
                                case "020":
                                    $scope.Tarifa = "6.1TD ML";
                                    break;
                                case "021":
                                    $scope.Tarifa = "6.2TD ML";
                                    break;
                                case "022":
                                    $scope.Tarifa = "6.3TD ML";
                                    break;
                                case "023":
                                    $scope.Tarifa = "6.4TD ML";
                                    break;
								}
						} else if ($scope.VerGas) {
							switch (response.data.ClientesSips[0]["CodigoPeajeEnVigor"]) {
								case "11":
									$scope.Tarifa = "1.1";
									break;
								case "12":
									$scope.Tarifa = "1.2";
									break;
								case "13":
									$scope.Tarifa = "1.3";
									break;
								case "21":
									$scope.Tarifa = "2.1";
									break;
								case "22":
									$scope.Tarifa = "2.2";
									break;
								case "23":
									$scope.Tarifa = "2.3";
									break;
								case "24":
									$scope.Tarifa = "2.4";
									break;
								case "25":
									$scope.Tarifa = "2.5";
									break;
								case "26":
									$scope.Tarifa = "2.6";
									break;
								case "31":
									$scope.Tarifa = "3.1";
									break;
								case "32":
									$scope.Tarifa = "3.2";
									break;
								case "33":
									$scope.Tarifa = "3.3";
									break;
								case "34":
									$scope.Tarifa = "3.4";
									break;
							}
						}

                    if ($scope.Tarifa == "2.0A ML" || $scope.Tarifa == "2.1A ML") {
                        potenciaP2.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP1"];
                        potenciaP2.dispatchEvent(evt);
                        PotenciaP2.$setValidity('required', true);

                        potenciaP1.value = "";
                        potenciaP3.value = "";
                        potenciaP4.value = "";
                        potenciaP5.value = "";
                        potenciaP6.value = "";
                    }

                    if ($scope.Tarifa == "2.0DHA ML" || $scope.Tarifa == "2.1DHA ML"  || $scope.Tarifa == "2.0TD ML") {
                        potenciaP1.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP1"];
                        potenciaP1.dispatchEvent(evt);
                        PotenciaP1.$setValidity('required', true);

                        potenciaP3.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP3"];
                        potenciaP3.dispatchEvent(evt);
                        PotenciaP3.$setValidity('required', true);

                        potenciaP2.value = "";
                        potenciaP4.value = "";
                        potenciaP5.value = "";
                        potenciaP6.value = "";
                    }

                    if ($scope.Tarifa == "2.0DHS ML" || $scope.Tarifa == "2.1DHS ML" || $scope.Tarifa == "3.0A ML" || $scope.Tarifa == "3.1A ML") {
                        potenciaP1.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP1"];
                        potenciaP1.dispatchEvent(evt);
                        PotenciaP1.$setValidity('required', true);

                        potenciaP2.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP2"];
                        potenciaP2.dispatchEvent(evt);
                        PotenciaP2.$setValidity('required', true);

                        potenciaP3.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP3"];
                        potenciaP3.dispatchEvent(evt);
                        PotenciaP3.$setValidity('required', true);

                        potenciaP4.value = "";
                        potenciaP5.value = "";
                        potenciaP6.value = "";
                    }

                    if ($scope.Tarifa == "6.1A ML" || $scope.Tarifa == "6.2 ML" || $scope.Tarifa == "6.3 ML" || $scope.Tarifa == "6.4 ML"
                     || $scope.Tarifa == "6.5 ML" || $scope.Tarifa == "6.1B ML" || $scope.Tarifa == "6.2TD ML" || $scope.Tarifa == "6.3TD ML" || $scope.Tarifa == "6.4TD ML"
                     || $scope.Tarifa == "6.1TD ML" || $scope.Tarifa == "6.1TDVE ML" || $scope.Tarifa == "3.0TDVE ML"  || $scope.Tarifa == "3.0TD ML") {
                        potenciaP1.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP1"];
                        potenciaP1.dispatchEvent(evt);
                        PotenciaP1.$setValidity('required', true);

                        potenciaP2.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP2"];
                        potenciaP2.dispatchEvent(evt);
                        PotenciaP2.$setValidity('required', true);

                        potenciaP3.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP3"];
                        potenciaP3.dispatchEvent(evt);
                        PotenciaP3.$setValidity('required', true);

                        potenciaP4.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP4"];
                        potenciaP4.dispatchEvent(evt);
                        PotenciaP4.$setValidity('required', true);

                        potenciaP5.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP5"];
                        potenciaP5.dispatchEvent(evt);
                        PotenciaP5.$setValidity('required', true);

                        potenciaP6.value = response.data.ClientesSips[0]["PotenciasContratadasEnWP6"];
                        potenciaP6.dispatchEvent(evt);
                        PotenciaP6.$setValidity('required', true);
                    }
                }
            });
    };

    $scope.update = function(datos) {
        var objeto = JSON.parse(datos.TarifaAccesoElectricidad);
        $http({
            method: "GET",
            url: urlApi + "Tarifa/GetDTOAllGruposTarifa/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Tarifa/" + objeto.IdTarifa,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            //VALIDACION CON LA PALABRA SECRETA DE LA API
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
                    //verifyAt: IntDate.get('20150601000000Z')
            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.GruposTarifa = playloadJson;
            } else {
                $scope.GruposTarifa = "";
                console.log("HASH DE VERIFICACION INCORRECTO");
            }
        });

        var textoTarifa = objeto.TextoTarifa;
        textoTarifa = textoTarifa.replace(" ML", "");

        if (textoTarifa == '2.0A' || textoTarifa == '2.1A' || datos.TarifaAccesoGas == '2.0A' || datos.TarifaAccesoGas == '2.1A') {
            //Habilito el campo a editar
            document.getElementById("PotenciaP1").disabled = true;
            document.getElementById("PotenciaP2").disabled = false;
            document.getElementById("PotenciaP3").disabled = true;
            document.getElementById("PotenciaP4").disabled = true;
            document.getElementById("PotenciaP5").disabled = true;
            document.getElementById("PotenciaP6").disabled = true;

            //Hago obligatorio el campo a editar
            document.getElementById("PotenciaP1").required = false;
            document.getElementById("PotenciaP2").required = true;
            document.getElementById("PotenciaP3").required = false;
            document.getElementById("PotenciaP4").required = false;
            document.getElementById("PotenciaP5").required = false;
            document.getElementById("PotenciaP6").required = false;

        }
        if (textoTarifa == '2.0DHA' || textoTarifa == '2.1DHA' || datos.TarifaAccesoGas == '2.0DHA' || datos.TarifaAccesoGas == '2.1DHA'
        || datos.TarifaAccesoGas == '2.0DTD' || textoTarifa == '2.0TD') {
            document.getElementById("PotenciaP1").disabled = false;
            document.getElementById("PotenciaP2").disabled = true;
            document.getElementById("PotenciaP3").disabled = false;
            document.getElementById("PotenciaP4").disabled = true;
            document.getElementById("PotenciaP5").disabled = true;
            document.getElementById("PotenciaP6").disabled = true;

            document.getElementById("PotenciaP1").required = true;
            document.getElementById("PotenciaP2").required = false;
            document.getElementById("PotenciaP3").required = true;
            document.getElementById("PotenciaP4").required = false;
            document.getElementById("PotenciaP5").required = false;
            document.getElementById("PotenciaP6").required = false;

        }
        if (textoTarifa == '3.0A' || textoTarifa == '3.1A' || textoTarifa == '2.0DHS' || textoTarifa == '2.1DHS' || datos.TarifaAccesoGas == '3.0A' || datos.TarifaAccesoGas == '3.1A' || datos.TarifaAccesoGas == '2.0DHS' || datos.TarifaAccesoGas == '2.1DHS') {
            document.getElementById("PotenciaP1").disabled = false;
            document.getElementById("PotenciaP2").disabled = false;
            document.getElementById("PotenciaP3").disabled = false;
            document.getElementById("PotenciaP4").disabled = true;
            document.getElementById("PotenciaP5").disabled = true;
            document.getElementById("PotenciaP6").disabled = true;

            document.getElementById("PotenciaP1").required = true;
            document.getElementById("PotenciaP2").required = true;
            document.getElementById("PotenciaP3").required = true;
            document.getElementById("PotenciaP4").required = false;
            document.getElementById("PotenciaP5").required = false;
            document.getElementById("PotenciaP6").required = false;

        }
        if (textoTarifa == '6.1A' || textoTarifa == '6.1B' || datos.TarifaAccesoGas == '6.1A' || datos.TarifaAccesoGas == '6.1B'
        || textoTarifa == '6.1TD' || datos.TarifaAccesoGas == '6.1TD' || textoTarifa == '6.2TD' || datos.TarifaAccesoGas == '6.2TD'
        || textoTarifa == '6.3TD' || datos.TarifaAccesoGas == '6.3TD' || textoTarifa == '6.4TD' || datos.TarifaAccesoGas == '6.4TD'
        || textoTarifa == '3.0TD' || datos.TarifaAccesoGas == '3.0TD' || textoTarifa == '6.1TDVE' || datos.TarifaAccesoGas == '6.1TDVE'
        || textoTarifa == '3.0TDVE' || datos.TarifaAccesoGas == '3.0TDVE') {
            document.getElementById("PotenciaP1").disabled = false;
            document.getElementById("PotenciaP2").disabled = false;
            document.getElementById("PotenciaP3").disabled = false;
            document.getElementById("PotenciaP4").disabled = false;
            document.getElementById("PotenciaP5").disabled = false;
            document.getElementById("PotenciaP6").disabled = false;

            document.getElementById("PotenciaP1").required = true;
            document.getElementById("PotenciaP2").required = true;
            document.getElementById("PotenciaP3").required = true;
            document.getElementById("PotenciaP4").required = true;
            document.getElementById("PotenciaP5").required = true;
            document.getElementById("PotenciaP6").required = true;
        }

    };

    $scope.compruebaIdentificador = function(dato) {

        /*var id = dato.Identificador;
        var primerCaracter = id.substr(0,1);

        if(primerCaracter == 'x' || primerCaracter == 'X'){
          //Habilitamos los campos nombre y apellidos
          document.getElementById("NombreTitular").disabled = false;
          document.getElementById("ApellidosTitular").disabled = false;
          document.getElementById("RazonSocialTitular").disabled = true;

          //Hacemos focus en el campo activado, en este caso el nombre, para que pueda utilizar el tab
          document.getElementById("NombreTitular").focus();

          //Añadimso el * para darle la sensacion al usuario de que esos campos lo requieren
          document.getElementById("RequiredNombre").innerHTML = 'Nombre *';
          document.getElementById("RequiredApellidos").innerHTML = 'Apellidos *';
          //Este lo meto por si cambia de cif a nif que no aparezca como obligatorio
          document.getElementById("RequiredRazonSocial").innerHTML = 'Razón Social';

          document.getElementById("NombreTitular").required = true;
          document.getElementById("ApellidosTitular").required = true;
          document.getElementById("RazonSocialTitular").required = false;

        }else if(primerCaracter == 'b' || primerCaracter == 'B' || primerCaracter == 'v' || primerCaracter == 'V' || primerCaracter == 'a' || primerCaracter == 'A' || primerCaracter == 'c' || primerCaracter == 'C' || primerCaracter == 'd' || primerCaracter == 'D' || primerCaracter == 'e' || primerCaracter == 'E' || primerCaracter == 'f' || primerCaracter == 'F' || primerCaracter == 'g' || primerCaracter == 'G' || primerCaracter == 'h' || primerCaracter == 'H' || primerCaracter == 'j' || primerCaracter == 'J' || primerCaracter == 'n' || primerCaracter == 'N' || primerCaracter == 'p' || primerCaracter == 'P' || primerCaracter == 'q' || primerCaracter == 'Q' || primerCaracter == 'r' || primerCaracter == 'R' || primerCaracter == 's' || primerCaracter == 'S' || primerCaracter == 'u' || primerCaracter == 'U' || primerCaracter == 'w' || primerCaracter == 'W'){

          document.getElementById("NombreTitular").disabled = true;
          document.getElementById("ApellidosTitular").disabled = true;
          document.getElementById("RazonSocialTitular").disabled = false;

          //Hacemos focus en el campo activado
          document.getElementById("RazonSocialTitular").focus();

          //Añadimso el * para darle la sensacion al usuario de que esos campos lo requieren
          document.getElementById("RequiredRazonSocial").innerHTML = 'Razón Social *';
          //Estos lo meto por si cambia de cif a nif que no aparezca como obligatorio
          document.getElementById("RequiredNombre").innerHTML = 'Nombre';
          document.getElementById("RequiredApellidos").innerHTML = 'Apellidos';

          document.getElementById("NombreTitular").required = false;
          document.getElementById("ApellidosTitular").required = false;
          document.getElementById("RazonSocialTitular").required = true;

        }else{

          document.getElementById("NombreTitular").disabled = false;
          document.getElementById("ApellidosTitular").disabled = false;
          document.getElementById("RazonSocialTitular").disabled = true;

          //Hacemos focus en el campo activado
          document.getElementById("NombreTitular").focus();

          //Añadimso el * para darle la sensacion al usuario de que esos campos lo requieren
          document.getElementById("RequiredNombre").innerHTML = 'Nombre *';
          document.getElementById("RequiredApellidos").innerHTML = 'Apellidos *';
          //Este lo meto por si cambia de cif a nif que no aparezca como obligatorio
          document.getElementById("RequiredRazonSocial").innerHTML = 'Razón Social';

          document.getElementById("NombreTitular").required = true;
          document.getElementById("ApellidosTitular").required = true;
          document.getElementById("RazonSocialTitular").required = false;

        }*/
    };

    /* Codigo Fichero */
    $scope.fileName = function(ele) {
        var files = ele.files;
        var l = files.length;
        var namesArr = [];


        for (var i = 0; i < l; i++) {
            namesArr.push(files[i].name);
            var texto = document.getElementById("textoDocumentacionLead");
            texto.setAttribute("value", files[0].name);
        }

    };

    $scope.limpiar = function() {
        document.getElementById('textoDocumentacionLead').setAttribute('value', '');
    };

    $scope.InsertarContrato = function() {
        var identificador = document.getElementsByName("Identificador")[0].value;
        var nombreTitular = document.getElementsByName("NombreTitular")[0].value;
        var apellidosTitular = document.getElementsByName("ApellidosTitular")[0].value;
        var emailTitular = document.getElementsByName("EmailTitular")[0].value;
        var razonSocialTitular = document.getElementsByName("RazonSocialTitular")[0].value;
        var telefonoTitular = document.getElementsByName("TelefonoTitular")[0].value;
        var codPostalTitular = document.getElementsByName("CodPostalTitular")[0].value;
        // var ciudadTitular = document.getElementsByName("CiudadTitular")[0].value;
        var ciudadTitular = $scope.altaContratoLeadsForm.CiudadTitular.$viewValue;
        var calleTitular = $scope.altaContratoLeadsForm.CalleTitular.$viewValue;
        var provinciaTitular = $scope.altaContratoLeadsForm.ProvinciaTitular.$viewValue;
        // var calleTitular = document.getElementsByName("CalleTitular")[0].value;
        var numeroTitular = document.getElementsByName("NumeroTitular")[0].value;
        var aclaradorTitular = document.getElementsByName("AclaradorTitular")[0].value;
        var cups = document.getElementsByName("Cups")[0].value;
        var tarifaAccesoElectricidad = document.getElementsByName("TarifaAccesoElectricidad")[0].getAttribute('aria-label').replace("Seleccione una Tarifa de Acceso: ", "");
        var grupoTarifa = document.getElementsByName("GrupoTarifa")[0].getAttribute('aria-label').replace("Seleccione una Tarifa de Grupo: ", "");
        var potenciaP1 = document.getElementsByName("PotenciaP1")[0].value;
        var potenciaP2 = document.getElementsByName("PotenciaP2")[0].value;
        var potenciaP3 = document.getElementsByName("PotenciaP3")[0].value;
        var potenciaP4 = document.getElementsByName("PotenciaP4")[0].value;
        var potenciaP5 = document.getElementsByName("PotenciaP5")[0].value;
        var potenciaP6 = document.getElementsByName("PotenciaP6")[0].value;
        var direccionSuministro = document.getElementsByName("DireccionSuministro")[0].value;
        var observaciones = document.getElementsByName("Observaciones")[0].value;
        var horarioLlamada = document.getElementsByName("HorarioLlamada")[0].value;
        var tarjetaDisa = document.getElementsByName("TarjetaClubDisa")[0].value;
        var bono = bonoCheckeado;
        var codigoEmpleado = document.getElementsByName("CodigoEmpleado")[0].value;
        var fichero = document.getElementById("file-upload");
        var file = fichero.files[0];
        var ficheroData = new FormData();
        ficheroData.append("fichero", file);
        ficheroData.append("nombreTitular", nombreTitular);

        var segmento = document.getElementsByName("Segmento")[0].getAttribute('aria-label').replace("Seleccione segmento: ", "");
        var tipocliente = document.getElementsByName("TipoCliente")[0].getAttribute('aria-label').replace("Seleccione tipo de cliente: ", "");
        // var canalorigen = document.getElementsByName("CanalOrigen")[0].getAttribute('aria-label').replace("Seleccione canal de origen: ", "");
        // var subcanalorigen = document.getElementsByName("SubcanalOrigen")[0].value;
        var codigopromocional = document.getElementsByName("CodigoPromocional")[0].value;

        if (identificador == undefined) { identificador = ""; }
        if (nombreTitular == undefined) { nombreTitular = ""; }
        if (apellidosTitular == undefined) { apellidosTitular = ""; }
        if (emailTitular == undefined) { emailTitular = ""; }
        if (razonSocialTitular == undefined) { razonSocialTitular = ""; }
        if (telefonoTitular == undefined) { telefonoTitular = ""; }
        if (codPostalTitular == undefined) { codPostalTitular = ""; }
        if (ciudadTitular == undefined || ciudadTitular == null) { ciudadTitular = ""; }
        if (provinciaTitular == undefined) { provinciaTitular = ""; }
        if (calleTitular == undefined || calleTitular == null) { calleTitular = ""; }
        if (numeroTitular == undefined) { numeroTitular = ""; }
        if (aclaradorTitular == undefined) { aclaradorTitular = ""; }
        if (cups == undefined) { cups = ""; }
        if (tarifaAccesoElectricidad == undefined || tarifaAccesoElectricidad == "Seleccione una Tarifa de Acceso") { tarifaAccesoElectricidad = ""; }
        if (grupoTarifa == undefined || grupoTarifa == "Seleccione una Tarifa de Grupo") { grupoTarifa = ""; }
        if (potenciaP1 == undefined) { potenciaP1 = ""; }
        if (potenciaP2 == undefined) { potenciaP2 = ""; }
        if (potenciaP3 == undefined) { potenciaP3 = ""; }
        if (potenciaP4 == undefined) { potenciaP4 = ""; }
        if (potenciaP5 == undefined) { potenciaP5 = ""; }
        if (potenciaP6 == undefined) { potenciaP6 = ""; }
        if (direccionSuministro == undefined) { direccionSuministro = ""; }
        if (observaciones == undefined) { observaciones = ""; }
        if (horarioLlamada == undefined) { horarioLlamada = ""; }
        if (tarjetaDisa == undefined) { tarjetaDisa = ""; }
        if (bono == undefined) { bono = ""; }
        if (bono == 10) { bono = "Gas"; } else if (bono == 11) { bono = "Combustible"; }
        if (codigoEmpleado == undefined) { codigoEmpleado = ""; }
        if (segmento == undefined || segmento == "Seleccione segmento") { segmento = ""; }
        if (tipocliente == undefined || tipocliente == "Seleccione tipo de cliente") { tipocliente = ""; }
        // if (canalorigen == undefined || canalorigen == "Seleccione canal de origen") { canalorigen = ""; }
        // if (subcanalorigen == undefined) { subcanalorigen = ""; }
        if (codigopromocional == undefined) { codigopromocional = ""; }

		var fecha = new Date();
		var fechaFormateada = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();
		var horaFormateada = fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
		fecha = fechaFormateada + " " + horaFormateada;

        var ContratoPotencial = {
            "NombreCreador": NombreAgente,
            "Asignado": NombreAgente,
            "CodigoCUPS": cups,
            "DireccionSuministro": direccionSuministro,
            "P1": potenciaP1,
            "P2": potenciaP2,
            "P3": potenciaP3,
            "P4": potenciaP4,
            "P5": potenciaP5,
            "P6": potenciaP6,
            "Identificador": identificador,
            "NombreTitular": nombreTitular,
            "ApellidosTitular": apellidosTitular,
            "RazonSocialTitular": razonSocialTitular,
            "CPTitular": codPostalTitular,
            "CiudadTitular": ciudadTitular,
            "CalleTitular": calleTitular,
            "NumeroTitular": numeroTitular,
            "AclaradorTitular": aclaradorTitular,
            "ProvinciaTitular": provinciaTitular,
            "TfnoTitular": telefonoTitular,
            "EmailTitular": emailTitular,
            "TarifaAcceso": tarifaAccesoElectricidad,
            "NombreTarifa": grupoTarifa,
            "Fecha": fecha,
            "Observaciones": observaciones,
            "HorarioLlamada": horarioLlamada,
            "TarjetaDisa": tarjetaDisa,
            "Bono": bono,
            "CodigoEmpleado": codigoEmpleado,
            "Segmento": segmento,
            "TipoCliente": tipocliente,
            // "CanalOrigen": canalorigen,
            // "SubcanalOrigen": subcanalorigen,
            "CodigoPromocional": codigopromocional,
            "Entorno": $scope.VerGas?'g':'e',

        };

        if (Logueado == 'true') {
            $.post("lib/php/insertar_leads.php", ContratoPotencial, function(data) {
                var idLead = data;
                ficheroData.append("idLead", idLead);
                if (file) {
                    $.ajax({
                        type: 'POST',
                        // url: 'http://188.210.216.60/domestica/pa/lib/php/subir_documentacion_lead.php',
                        url: 'lib/php/subir_documentacion_lead.php',
                        data: ficheroData,
                        processData: false,
                        contentType: false
                    }).done(function(data) {
                        //console.log(data);
                    });
                }

                $scope.showAlert();

                $state.go("televentaLeads", {}, { reload: true });
            });
        }
    };
	function isJsonVacio(json){
		/**
		 * Nos permite comprobar si un json está vacio
		 */
		if(json != null){
			return Object.keys(json).length === 0
		}else{
			return true;
		}

    }
	function isUndefined(t) {
		if (t === undefined) {
			return true;
		}
		return false;
	}
	$scope.BuscarCiudadTitular = function (textoCiudad) {
        var valorCiudad = document.getElementsByName("CiudadTitular")[0];
        valorCiudad.value = textoCiudad;

		$scope.ListadoCiudadesTitularBloqueado = true;
		$scope.spinnerListadoCiudadesTitular = true;

		/**
		 * Cada vez que busque, que borre la ciudad y la calle del Titular
		 */

		$scope.ciudadTitularItem = {};
		$scope.calleTitularItem = {};

        var dataCiudades = [{ "FiltroTexto": textoCiudad }];

        $http({
            method: "POST",
            url: urlCallejero + "Ciudad/GetDTOALLbyTexto",
            data: JSON.stringify(dataCiudades)
        }).then(function (response) {
            var respuesta = response.data;
            $scope.ciudadesTitular = respuesta;
            $scope.obtenerCiudadesTitular = function (text) {
                text = text.toUpperCase();
                var ret = $scope.ciudadesTitular.filter(function (d) {
                    if (d.desCiudad !== null)
                        return d.desCiudad.includes(text);
                });
                return ret;
			}
			$scope.ListadoCiudadesTitularBloqueado = false;
			$scope.spinnerListadoCiudadesTitular = false;

			/**
			 * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
			 * Si boras el timeout NO VA
			 */
			setTimeout(function() {
				document.querySelector('#CiudadTitular').focus();
			}, 0);
        });
    }
	$scope.cargarCiudadTitular = function (ciudad) {
		if(!isJsonVacio(ciudad)){

		//TODO: Ver como mejorar esto para que cuando busque o copie la estructura no sea diferente
		//TODO: Creo que es quitando un desciudad del html, pero revisarlo antes.
		if(ciudad.desCiudad.desProvincia == null){
			$scope.callejeroINETitular = {
				"codCiudad": angular.copy(ciudad.desCiudad.desCiudad.codCiudad),
				"desCiudad": angular.copy(ciudad.desCiudad.desCiudad.desCiudad),
				"codPais": angular.copy(ciudad.desCiudad.desCiudad.codPais),
				"desPais": angular.copy(ciudad.desCiudad.desCiudad.desPais),
				"codProvincia": angular.copy(ciudad.desCiudad.desCiudad.codProvincia),
				"desProvincia": angular.copy(ciudad.desCiudad.desCiudad.desProvincia),
				"codMunicipio": angular.copy(ciudad.desCiudad.desCiudad.codMunicipio),
				"desMunicipio": angular.copy(ciudad.desCiudad.desCiudad.desMunicipio),
				"codigoProveedorProvincia": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorProvincia),
				"codigoProveedorMunicipio": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorMunicipio),
				"codigoProveedorCiudad": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorCiudad)
			}
			$scope.ProvinciaTitular = ciudad.desCiudad.desCiudad.desProvincia;
		}

	}

	/**
	 * Si los dos codigos no son != de undefined que no haga la llamada, solo provoca errores de consola
	 */

	if(!$scope.bloqueoCalleTitularCopiando){
		if(ciudad.desCiudad != null){
			if(!isUndefined(ciudad.desCiudad.desCiudad.codProvincia) && !isUndefined(ciudad.desCiudad.desCiudad.codMunicipio)){

				$scope.ListadoCallesTitularBloqueado = true;
				$scope.spinnerListadoCallesTitular = true;

				$http({
					method: "GET",
					url: urlCallejeroNew + "Calle/GetDTOALLCalles/CodigoMunicipio/" + ciudad.desCiudad.desCiudad.codMunicipio + "/CodProvincia/" + ciudad.desCiudad.desCiudad.codProvincia
				}).then(function (response) {
					$scope.BuscadorCallesTitular = response.data; //Aqui guardamos el array de calles
					$scope.obtenerCallesTitular = function (text) {
						text = text.toUpperCase();
						var ret = $scope.BuscadorCallesTitular.filter(function (d) {
							var desCompleta = d.tipoCalle + ' ' + d.desCalle + ' ' + d.codigoPostal;
							if (desCompleta !== null)
								return desCompleta.includes(text);
						});
						/**
						 * Si no existe la calle les dejo meter a mano el codigo postal
						 */
						(ret.length === 0) ? ($scope.disabledCodPostalTitular = false) : ($scope.disabledCodPostalTitular = true);
						return ret;
					};
					$scope.ListadoCallesTitularBloqueado = false;
					$scope.spinnerListadoCallesTitular = false;

					/**
					* Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
					* Si boras el timeout NO VA
					*/
			setTimeout(function() {
				document.querySelector('#CalleTitular').focus();
			}, 0);
				});
			}
		}
	}
}
    $scope.buscarCodigoPostal = function(CodPostalTitular) {
        var codigoPostal = CodPostalTitular.$viewValue;
        // console.log(CodPostalTitular);

        $http({
            method: "POST",
            url: urlCallejero + "Ciudad/GetDTOALLCiudades/CodigoPostal/" + codigoPostal

        }).then(function(response) {

            var evt = new CustomEvent("change");
            var valorProvincia = document.getElementsByName("ProvinciaTitular")[0];
            valorProvincia.value = response.data.listaMunicipios[0].desProvincia;
            valorProvincia.dispatchEvent(evt);

            var codProvincia = response.data.listaMunicipios[0].codProvincia;
            var codMunicipio = response.data.listaMunicipios[0].codMunicipio;

            $http({
                method: "POST",
                url: urlCallejero + "Ciudad/GetDTOALLCiudades/CodigoProvincia/" + codProvincia + "/CodigoMunicipio/" + codMunicipio

            }).then(function(response) {
                $scope.ciudades = response.data; //Aqui guardamos el array de ciudades
                // console.log($scope.ciudades);
            });

            $http({
                method: "POST",
                url: urlCallejero + "Calle/GetDTOALLCalles/CodigoMunicipio/" + codMunicipio + "/CodProvincia/" + codProvincia + "/CodPostal/" + codigoPostal

            }).then(function(response) {
                $scope.calles = response.data; //Aqui guardamos el array de ciudades
            });

        });

    };

    $http({
        method: "POST",
        url: urlApi + "Contrato/GetAllDestinosEnergia",
        data: JSON.stringify({ "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario }),
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        if (tokenValido) {
            $scope.segmentos = playloadJson;
        } else {
            $scope.segmentos = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $http({
        method: "POST",
        url: urlApi + "Contrato/GetAllClienteCategoria",
        data: JSON.stringify({ "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario }),
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        if (tokenValido) {
            $scope.tipoclientes = playloadJson;
        } else {
            $scope.tipoclientes = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $scope.obtenerCiudades = function(text) {
        text = text.toUpperCase();
        var ret = $scope.ciudades.filter(function(d) {
            return d.desCiudad.startsWith(text);
        });
        return ret;
    };

    $scope.obtenerCalles = function(text) {
        text = text.toUpperCase();
        var ret = $scope.calles.filter(function(d) {
            return d.desCalle.startsWith(text);
        });
        return ret;
    };

}]);