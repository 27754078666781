app.service('servicioListadoTarifas', [function () {
	this.getListadoTarifas = function () {
		var tarifas = [{
			value: "2.0.A",
			display: "2.0 A"
		}, {
			value: "3.0A",
			display: "3.0 A"
		}, {
			value: "2.0DHA",
			display: "2.0 DHA"
		}, {
			value: "2.1.A",
			display: "2.1 A"
		}, {
			value: "2.1.DHA",
			display: "2.1 DHA"
		}, {
			value: "2.0.DHS",
			display: "2.0 DHS"
		}, {
			value: "2.1.DHS",
			display: "2.1 DHS"
		}, {
			value: "3.1A",
			display: "3.1 A"
		}, {
			value: "6.1A",
			display: "6.1 A"
		}, {
			value: "6.2",
			display: "6.2"
		}, {
			value: "6.3",
			display: "6.3"
		}, {
			value: "6.4",
			display: "6.4"
		}, {
			value: "6.5",
			display: "6.5"
		}, {
			value: "6.1B",
			display: "6.1 B"
		}, {
			value: "3.1",
			display: "3.1"
		}, {
			value: "3.2",
			display: "3.2"
		}, {
			value: "3.3",
			display: "3.3"
		}, {
			value: "3.4",
			display: "3.4"
		}];

		return tarifas;
	};
}]);