app.controller('DocumentacionCtrl', ['$scope', '$http', 'FileUploader', '$mdDialog', '$timeout',
	function ($scope, $http, FileUploader, $mdDialog, $timeout) {
	'use strict';

	$scope.breadcrumbs = [];
	$scope.path = "";
	const url = "lib/php/documentacion.php";
	const back = "../../";

	$scope.MostrarSubidaDocumentacion = MostrarSubidaDocumentacion;
	$scope.MostrarAccionesDocumentacion = MostrarAccionesDocumentacion;

	$http({
		method: 'GET',
		url: url
	}).then(function successCallback(response) {
		for (var a = 0; a < response.data.length; a++) {
			response.data[a].path = utf8_decode(response.data[a].path);
		}
		$scope.data = response.data;
	});

	$scope.breadcrumbs = [];
	$scope.breadcrumbs.push(
		{ 'path': '../', 'name': '../' },
		{ 'path': '../', 'name': '../' },
		{ 'path': 'documentacion', 'name': 'documentacion'}
	);

	// Abrir una carpeta
	$scope.navigate = function(path, data) {
		$scope.emptyData();
		$scope.search = null;
		angular.element(document.querySelector('.data')).addClass('hidden');
		angular.element(document.querySelector('.data')).removeClass('animated');
		var bc = path.split("/").filter(String).join("/");
		bc = (bc.startsWith(".")) ? bc : back + bc;
		$timeout(function() { $scope.searchPath(bc) }, 800);
	}

	// Renombrar fichero / directorio
	$scope.rename = function(data) {
		var path = $scope.breadcrumbs[$scope.breadcrumbs.length-1].path;
		var bc = path.split("/").filter(String).join("/");
		bc = (bc.startsWith(".")) ? bc : back + bc;

		var confirm = $mdDialog.prompt()
				.title('Renombrar ' + (data.type == 'folder' ?  'carpeta ' : 'archivo '))
				.textContent('Introduce un nuevo nombre para ' + (data.type == 'folder' ?  'la carpeta ' : 'el archivo '))
				.initialValue(data.name)
				.ok('Aceptar')
				.cancel('Cancelar');

		$mdDialog.show(confirm).then(function(result) {
			$http({
				method: "POST",
				url: url,
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: $.param({ "path": bc, "new": result, "old": data.name, "rename": true })
			}).success(function (response) {
				$scope.navigate(bc);
			});
		}, function() { });
	}

	// Crear nueva carpeta
	$scope.newFolder = function() {
		var path = $scope.breadcrumbs[$scope.breadcrumbs.length-1].path;
		var bc = path.split("/").filter(String).join("/");
		bc = (bc.startsWith(".")) ? bc : back + bc;

		var confirm = $mdDialog.prompt()
				.title('Crear nueva carpeta')
				.textContent('Introduce el nombre de la nueva carpeta')
				.placeholder('Nueva carpeta')
				.ok('Aceptar')
				.cancel('Cancelar');

		$mdDialog.show(confirm).then(function(result) {
			$http({
				method: "POST",
				url: url,
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: $.param({ "name": bc + '/' + result, "create": true })
			}).success(function (response) {
				$scope.navigate(bc);
			});
		}, function() { });
	}

	// Eliminar archivo / directorio vacío
	$scope.remove = function(data) {
		var path = $scope.breadcrumbs[$scope.breadcrumbs.length-1].path;
		var bc = path.split("/").filter(String).join("/");
		bc = (bc.startsWith(".")) ? bc : back + bc;

		var confirm = $mdDialog.confirm()
				.title('Confirmar el borrado')
				.textContent('Se va a eliminar ' + (data.type == 'folder' ?  'la carpeta ' : 'el archivo ') + data.name)
				.ok('Eliminar')
				.cancel('Cancelar');

		$mdDialog.show(confirm).then(function() {
			$http({
				method: "POST",
				url: url,
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: $.param({ "path": data.path, "remove": true, "type": data.type })
			}).success(function (response) {
				$scope.navigate(bc);
			});
		});
	}

	// Búsqueda por nombre
	$scope.searchFile = function(data) {
		$http({
			method: "POST",
			url: url,
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			data: $.param({ "path": $scope.getPath(), "lookup": true, "string": data })
		}).success(function (response) {
			$scope.emptyData();
			angular.element(document.querySelector('.data')).addClass('hidden').removeClass('animated');
			$timeout(function() { $scope.data = response }, 800);
			angular.element(document.querySelector('.data')).removeClass('hidden').addClass('animated');
		});

	}

	// Buscar por path
	$scope.searchPath = function(path) {
		$http({
			method: "POST",
			url: url,
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			data: $.param({ "path": path, "search": true })
		}).success(function (response) {
			$scope.data = response;
			$scope.changeBreadcrumb(response.path);
			angular.element(document.querySelector('.data')).addClass('animated').removeClass('hidden');
		});
	}

	// Vaciar datos
	$scope.emptyData = function() {
		$scope.data.length = 0;
		$scope.data = [];
		return true;
	}

	var uploader = $scope.uploader = new FileUploader({
		url: 'lib/php/documentacion.php',
		removeAfterUpload: true,
		formData: [{ path: '../../documentacion' }]
	});

	// Actualizar breadcrumbs
	$scope.changeBreadcrumb = function(path) {
		var bc = path.split("/").filter(String);
		var str = "";
		$scope.breadcrumbs = [];
		angular.forEach(bc, function(value) {
			str += '/' + value;
			$scope.breadcrumbs.push({
				'path': str,
				'name': value
			});
		});
		$scope.getPath();
	}

	$scope.getPath = function() {
		var path = $scope.breadcrumbs[$scope.breadcrumbs.length-1].path;
		var bc = path.split("/").filter(String).join("/");
		bc = (bc.startsWith(".")) ? bc : back + bc;
		uploader.formData = [{ path: bc }];
		return bc;
	}

	// Al completar la subida
	uploader.onCompleteAll = function() {
		$scope.navigate($scope.getPath());
		$scope.showAlert();
	};

	$scope.showAlert = function(ev) {
		$mdDialog.show(
			$mdDialog.alert()
					.title('Subida completada')
					.clickOutsideToClose(true)
					.targetEvent(ev)
					.textContent('Los archivos seleccionados se han subido al servidor.')
					.ok('Aceptar')
		);
	};

	angular.element(document.querySelector('.dropzone')).on('dragleave', function () {
		angular.element(document.querySelector('.dropzone')).removeClass('dropzone-over');
	});

}]);
