angular.module('RDash')

.controller('MasterCtrl', ['$scope', function($scope) {

    var mobileView = 992;

    $scope.getWidth = function() {
        return window.innerWidth;
    };

    $scope.$watch($scope.getWidth, function(newValue, oldValue) {
        if (newValue >= mobileView) {
            if (angular.isDefined(atob(localStorage.getItem('toggle')))) {
                $scope.toggle = !atob(localStorage.getItem('toggle')) ? false : true;
            } else {
                $scope.toggle = true;
            }
        } else {
            $scope.toggle = false;
        }

    });

    $scope.toggleSidebar = function(value) {

        value==undefined ? $scope.toggle = !$scope.toggle : $scope.toggle = value;
        localStorage.setItem('toggle', btoa($scope.toggle));
        
    };

    window.onresize = function() {
        $scope.$apply();
    };

    //Configure Table
    $scope.limitOptions = [5, 10, 15, 50];

    $scope.query = {
        order: 'name',
        limit: 10,
        page: 1
    };

    $scope.traducciones = {
        page: 'Página:',
        rowsPerPage: 'Filas por página:',
        of: 'de'
    };

}]);