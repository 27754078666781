app.controller('PerfilClienteCtrl', ['$scope', '$rootScope', '$http', 'jwtHelper', function($scope, $rootScope, $http, jwtHelper) {
    'use strict';

    var IdAgente = localStorage.getItem('idAgente');
    var IdUsuario = localStorage.getItem('idUsuario');
    var Identificador = atob(localStorage.getItem('identificador'));
    var Logueado = localStorage.getItem('logueado');
    var IdEmpresa = localStorage.getItem('idEmpresa');
    var IdCliente = localStorage.getItem('idCliente');

    $scope.MostrarClientesPerfilObservaciones = MostrarClientesPerfilObservaciones;

    if (Logueado == 'true') {
        //$http.get(urlApi+"Cliente/GetClienteToken/Empresa/"+IdEmpresa+"/Usuario/"+IdUsuario+"/Cliente/"+Identificador)
        $http({
            method: "GET",
            url: urlApi + "Cliente/GetCliente/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Cliente/" + Identificador,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            //VALIDACION CON LA PALABRA SECRETA DE LA API
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
                    //verifyAt: IntDate.get('20150601000000Z')
            });
            $scope.TextoNacionalidad = compruebaNacionalidad(playloadJson.Nacionalidad);

            var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.perfil = playloadJson;
            } else {
                $scope.perfil = "";
            }
        });
    }

    $scope.autocolumnEnvio = [{
        name: "nombre",
        display: "Nombre"
    }, {
        name: "direccionCups",
        display: "Dirección Envío"
    }, {
        name: "ciudadCups",
        display: "Ciudad Envío"
    }, {
        name: "numero",
        display: "Numero"
    }, {
        name: "aclarador",
        display: "Aclarador"
    }, {
        name: "cod_postal",
        display: "Cod Postal"
    }];


    $scope.autocolumnContactos = [{
        name: "tipo",
        display: "Tipo"
    }, {
        name: "valor",
        display: "Valor"
    }, {
        name: "contacto",
        display: "Contacto"
    }, {
        name: "departamento",
        display: "Departamento"
    }];


    $scope.autocolumnCobro = [{
        name: "nombre",
        display: "Nombre"
    }, {
        name: "colectivo",
        display: "Colectivo"
    }, {
        name: "identificador",
        display: "Identificador"
    }, {
        name: "tipoCobro",
        display: "Tipo Cobro"
    }, {
        name: "iban",
        display: "IBAN"
    }, {
        name: "banco",
        display: "Banco"
    }, {
        name: "bic",
        display: "BIC"
    }, {
        name: "libre",
        display: "Libre"
    }, {
        name: "diaCobro",
        display: "Día Cobro"
    }];
    function compruebaNacionalidad(CodigoNacionalidad){
        switch(CodigoNacionalidad){
            case "SB" :
                var nacionalidad = "ISLAS SALOMON";
                break;
            case "SC" :
                var nacionalidad = "ISL. SEYCHELLES";
                break;
            case "SD" :
                var nacionalidad = "SUDAN";
                break;
            case "SA" :
                var nacionalidad = "ARABIA SAUDI";
                break;
            case "RE" :
                var nacionalidad = "REUNION";
                break;
            case "QA" :
                var nacionalidad = "QATAR";
                break;
            case "PY" :
                var nacionalidad = "PARAGUAY";
                break;
            case "RW" :
                var nacionalidad = "RUANDA";
                break;
            case "RU" :
                var nacionalidad = "FEDERACION RUSA";
                break;
            case "RO" :
                var nacionalidad = "RUMANIA";
                break;
            case "SM" :
                var nacionalidad = "SAN MARINO";
                break;
            case "SL" :
                var nacionalidad = "SIERRA LEONA";
                break;
            case "SN" :
                var nacionalidad = "SENEGAL";
                break;
            case "SR" :
                var nacionalidad = "SURINAM";
                break;
            case "SO" :
                var nacionalidad = "SOMALIA";
                break;
            case "SK" :
                var nacionalidad = "ESLOVAQUIA";
                break;
            case "SG" :
                var nacionalidad = "SINGAPUR";
                break;
            case "SE" :
                var nacionalidad = "SUECIA";
                break;
            case "SH" :
                var nacionalidad = "SANTA ELENA";
                break;
            case "SJ" :
                var nacionalidad = "SVALBARD AND JAN MAYEN";
                break;
            case "SI" :
                var nacionalidad = "ESLOVENIA";
                break;
            case "PW" :
                var nacionalidad = "PALAU";
                break;
            case "NU" :
                var nacionalidad = "ISLAS NIUE";
                break;
            case "NR" :
                var nacionalidad = "NAURU";
                break;
            case "NZ" :
                var nacionalidad = "NUEVA ZELANDA";
                break;
            case "PA" :
                var nacionalidad = "PANAMA";
                break;
            case "OM" :
                var nacionalidad = "OMAN";
                break;
            case "NI" :
                var nacionalidad = "NICARAGUA";
                break;
            case "NG" :
                var nacionalidad = "NIGERIA";
                break;
            case "NL" :
                var nacionalidad = "HOLANDA";
                break;
            case "NP" :
                var nacionalidad = "NEPAL";
                break;
            case "NO" :
                var nacionalidad = "NORUEGA";
                break;
            case "PE" :
                var nacionalidad = "PERU";
                break;
            case "PN" :
                var nacionalidad = "ISLAS PITCAIRN";
                break;
            case "PM" :
                var nacionalidad = "SAINT PIERRE AND MIQUELON";
                break;
            case "PR" :
                var nacionalidad = "PUERTO RICO";
                break;
            case "PT" :
                var nacionalidad = "PORTUGAL";
                break;
            case "PS" :
                var nacionalidad = "PALESTINA, TERRITORIO OCUPADO DE";
                break;
            case "PG" :
                var nacionalidad = "PAPUA NUEVA GUINEA";
                break;
            case "PF" :
                var nacionalidad = "POLINESIA FRANCESA";
                break;
            case "PH" :
                var nacionalidad = "FILIPINAS";
                break;
            case "PL" :
                var nacionalidad = "POLONIA";
                break;
            case "PK" :
                var nacionalidad = "PAQUISTAN";
                break;
            case "VC" :
                var nacionalidad = "SAN VICENTE Y LAS GRANADINAS";
                break;
            case "VA" :
                var nacionalidad = "CIUDAD DEL VATICANO";
                break;
            case "VE" :
                var nacionalidad = "VENEZUELA";
                break;
            case "VI" :
                var nacionalidad = "ISLAS VIRGENES (ESTADOUNIDENSES)";
                break;
            case "VG" :
                var nacionalidad = "ISLAS VIRGENES (INGLESAS)";
                break;
            case "UM" :
                var nacionalidad = "ISLAS MINOR OUTLYNG";
                break;
            case "UG" :
                var nacionalidad = "UGANDA";
                break;
            case "US" :
                var nacionalidad = "ESTADOS UNIDOS";
                break;
            case "UZ" :
                var nacionalidad = "UZBEKISTAN";
                break;
            case "UY" :
                var nacionalidad = "URUGUAY";
                break;
            case "ZW" :
                var nacionalidad = "ZIMBABWE";
                break;
            case "WS" :
                var nacionalidad = "SAMOA";
                break;
            case "YE" :
                var nacionalidad = "YEMEN";
                break;
            case "WF" :
                var nacionalidad = "WALLIS AND FUTUNA";
                break;
            case "VN" :
                var nacionalidad = "VIETNAM";
                break;
            case "VU" :
                var nacionalidad = "VANUATU";
                break;
            case "ZM" :
                var nacionalidad = "ZAMBIA";
                break;
            case "ZR" :
                var nacionalidad = "REPUBLICA DEMOCRATICA DEL CONGO";
                break;
            case "ZA" :
                var nacionalidad = "SUDAFRICA";
                break;
            case "YT" :
                var nacionalidad = "MAYOTTE";
                break;
            case "YU" :
                var nacionalidad = "YUGOSLAVIA";
                break;
            case "UA" :
                var nacionalidad = "UCRANIA";
                break;
            case "TF" :
                var nacionalidad = "FRENCH SOUTHERN TERRITORIES";
                break;
            case "TD" :
                var nacionalidad = "CHAD";
                break;
            case "TG" :
                var nacionalidad = "TOGO";
                break;
            case "TJ" :
                var nacionalidad = "TAYIKISTAN";
                break;
            case "TH" :
                var nacionalidad = "TAILANDIA";
                break;
            case "SV" :
                var nacionalidad = "EL SALVADOR";
                break;
            case "ST" :
                var nacionalidad = "SANTO TOME Y PRINCIPE";
                break;
            case "SY" :
                var nacionalidad = "SIRIA, REPUBLICA ARABE DE";
                break;
            case "TC" :
                var nacionalidad = "ISLAS TURKS Y ISLA DE CAICOS";
                break;
            case "SZ" :
                var nacionalidad = "SWAZILANDIA";
                break;
            case "TK" :
                var nacionalidad = "ISLAS TOKELAU";
                break;
            case "TT" :
                var nacionalidad = "TRINIDAD Y TOBAGO";
                break;
            case "TR" :
                var nacionalidad = "TURQUIA";
                break;
            case "TV" :
                var nacionalidad = "TUVALU";
                break;
            case "TZ" :
                var nacionalidad = "TANZANIA, REPUBLICA DE";
                break;
            case "TW" :
                var nacionalidad = "TAIWAN, PROVINCIA DE CHINA";
                break;
            case "TM" :
                var nacionalidad = "TURKMENISTAN";
                break;
            case "TL" :
                var nacionalidad = "TIMOR ORIENTAL";
                break;
            case "TN" :
                var nacionalidad = "TUNEZ";
                break;
            case "TP" :
                var nacionalidad = "TIMOR ORIENTAL";
                break;
            case "TO" :
                var nacionalidad = "TONGA";
                break;
            case "NF" :
                var nacionalidad = "ISLA DE NORFOLK";
                break;
            case "BE" :
                var nacionalidad = "BELGICA";
                break;
            case "BF" :
                var nacionalidad = "BURKINA FASO";
                break;
            case "BB" :
                var nacionalidad = "BARBADOS, LAS";
                break;
            case "BD" :
                var nacionalidad = "BANGLADESH";
                break;
            case "BG" :
                var nacionalidad = "BULGARIA";
                break;
            case "BJ" :
                var nacionalidad = "BENIN";
                break;
            case "BM" :
                var nacionalidad = "BERMUDAS, LAS";
                break;
            case "BH" :
                var nacionalidad = "BAHREIN";
                break;
            case "BI" :
                var nacionalidad = "BURUNDI";
                break;
            case "BA" :
                var nacionalidad = "BOSNIA Y HERZEGOVINA";
                break;
            case "AQ" :
                var nacionalidad = "ANTARTICO";
                break;
            case "AR" :
                var nacionalidad = "ARGENTINA";
                break;
            case "AN" :
                var nacionalidad = "ANTILLAS HOLANDESAS";
                break;
            case "AO" :
                var nacionalidad = "ANGOLA";
                break;
            case "AS" :
                var nacionalidad = "SAMOA AMERICANA";
                break;
            case "AW" :
                var nacionalidad = "ARUBA";
                break;
            case "AZ" :
                var nacionalidad = "AZERBAIYAN";
                break;
            case "AT" :
                var nacionalidad = "AUSTRIA";
                break;
            case "AU" :
                var nacionalidad = "AUSTRALIA";
                break;
            case "CF" :
                var nacionalidad = "REPUBLICA CENTRO ? AFRICANA";
                break;
            case "CG" :
                var nacionalidad = "CONGO";
                break;
            case "CC" :
                var nacionalidad = "ISLAS COCO";
                break;
            case "CD" :
                var nacionalidad = "REPUBLICA DEMOCRATICA DEL CONGO";
                break;
            case "CH" :
                var nacionalidad = "SUIZA";
                break;
            case "IO" :
                var nacionalidad = "TERRITORIOS BRITANICOS DEL OCEANO INDICO";
                break;
            case "IQ" :
                var nacionalidad = "IRAK";
                break;
            case "CI" :
                var nacionalidad = "COSTA DE MARFIL";
                break;
            case "CK" :
                var nacionalidad = "ISLAS COOK";
                break;
            case "CA" :
                var nacionalidad = "CANADA";
                break;
            case "BR" :
                var nacionalidad = "BRASIL";
                break;
            case "BS" :
                var nacionalidad = "BAHAMAS, LAS";
                break;
            case "BN" :
                var nacionalidad = "BRUNEI (DARUSSALAM)";
                break;
            case "BO" :
                var nacionalidad = "BOLIVIA";
                break;
            case "BT" :
                var nacionalidad = "BUTAN";
                break;
            case "BY" :
                var nacionalidad = "BIELORRUSIA";
                break;
            case "BZ" :
                var nacionalidad = "BELICE";
                break;
            case "BV" :
                var nacionalidad = "ISLAS BOUVET";
                break;
            case "BW" :
                var nacionalidad = "BOTSWANA";
                break;
            case "AM" :
                var nacionalidad = "ARMENIA";
                break;
            case "GM" :
                var nacionalidad = "GAMBIA";
                break;
            case "GN" :
                var nacionalidad = "GUINEA";
                break;
            case "GI" :
                var nacionalidad = "GIBRALTAR";
                break;
            case "GL" :
                var nacionalidad = "GROENLANDIA";
                break;
            case "GP" :
                var nacionalidad = "GUADALUPE";
                break;
            case "GS" :
                var nacionalidad = "GEORGIA DEL SUR Y LAS ISLAS SANDWICH";
                break;
            case "GT" :
                var nacionalidad = "GUATEMALA";
                break;
            case "GQ" :
                var nacionalidad = "GUINEA ECUATORIAL";
                break;
            case "GR" :
                var nacionalidad = "GRECIA";
                break;
            case "GH" :
                var nacionalidad = "GHANA";
                break;
            case "FO" :
                var nacionalidad = "ISLAS FAROE";
                break;
            case "FR" :
                var nacionalidad = "FRANCIA";
                break;
            case "FK" :
                var nacionalidad = "ISLAS MALVINAS";
                break;
            case "FM" :
                var nacionalidad = "MICRONESIA, ESTADOS FEDERALES DE";
                break;
            case "GA" :
                var nacionalidad = "GABON";
                break;
            case "GE" :
                var nacionalidad = "GEORGIA";
                break;
            case "GF" :
                var nacionalidad = "GUAYANA FRANCESA";
                break;
            case "GB" :
                var nacionalidad = "GRAN BRETA?A";
                break;
            case "GD" :
                var nacionalidad = "GRANADA";
                break;
            case "IN" :
                var nacionalidad = "INDIA";
                break;
            case "AD" :
                var nacionalidad = "ANDORRA";
                break;
            case "IE" :
                var nacionalidad = "IRLANDA";
                break;
            case "IL" :
                var nacionalidad = "ISRAEL";
                break;
            case "AE" :
                var nacionalidad = "EMIRATOS ARABES (UNIDOS)";
                break;
            case "AI" :
                var nacionalidad = "ANGUILLA";
                break;
            case "AL" :
                var nacionalidad = "ALBANIA";
                break;
            case "AF" :
                var nacionalidad = "AFGANISTAN";
                break;
            case "AG" :
                var nacionalidad = "ANTIGUA Y BARBUDA";
                break;
            case "ID" :
                var nacionalidad = "INDONESIA";
                break;
            case "GY" :
                var nacionalidad = "GUAYANA";
                break;
            case "HK" :
                var nacionalidad = "HONG KONG";
                break;
            case "GU" :
                var nacionalidad = "GUAM";
                break;
            case "GW" :
                var nacionalidad = "GUINEA-BISSAU";
                break;
            case "HM" :
                var nacionalidad = "ISLA HEARD  Y ISLA MCDONALD";
                break;
            case "HT" :
                var nacionalidad = "HAITI";
                break;
            case "HU" :
                var nacionalidad = "HUNGRIA";
                break;
            case "HN" :
                var nacionalidad = "HONDURAS";
                break;
            case "HR" :
                var nacionalidad = "CROACIA";
                break;
            case "LV" :
                var nacionalidad = "LETONIA";
                break;
            case "LY" :
                var nacionalidad = "LIBIA";
                break;
            case "LT" :
                var nacionalidad = "LITUANIA";
                break;
            case "LU" :
                var nacionalidad = "LUXEMBURGO";
                break;
            case "MA" :
                var nacionalidad = "MARRUECOS";
                break;
            case "MG" :
                var nacionalidad = "MADAGASCAR";
                break;
            case "MH" :
                var nacionalidad = "ISLAS MARSHALL";
                break;
            case "MC" :
                var nacionalidad = "MONACO";
                break;
            case "MD" :
                var nacionalidad = "MOLDAVIA, REPUBLICA DE";
                break;
            case "LS" :
                var nacionalidad = "LESOTHO";
                break;
            case "KZ" :
                var nacionalidad = "KAZAJISTAN";
                break;
            case "LA" :
                var nacionalidad = "LAOS, REPUBLICA POPULAR DEMOCRATICA DE";
                break;
            case "KW" :
                var nacionalidad = "KUWAIT";
                break;
            case "KY" :
                var nacionalidad = "ISLAS CAIMAN";
                break;
            case "LB" :
                var nacionalidad = "LIBANO";
                break;
            case "LK" :
                var nacionalidad = "SRI LANKA";
                break;
            case "LR" :
                var nacionalidad = "LIBERIA";
                break;
            case "LC" :
                var nacionalidad = "SANTA LUCIA";
                break;
            case "LI" :
                var nacionalidad = "LIECHTENSTEIN";
                break;
            case "MW" :
                var nacionalidad = "MALAWI";
                break;
            case "MX" :
                var nacionalidad = "MEJICO";
                break;
            case "MU" :
                var nacionalidad = "MAURICIO(ISL.)";
                break;
            case "MV" :
                var nacionalidad = "MALDIVAS, LAS";
                break;
            case "MY" :
                var nacionalidad = "MALASIA";
                break;
            case "NC" :
                var nacionalidad = "NUEVA CALEDONIA";
                break;
            case "NE" :
                var nacionalidad = "NIGER";
                break;
            case "MZ" :
                var nacionalidad = "MOZAMBIQUE";
                break;
            case "NA" :
                var nacionalidad = "NAMIBIA";
                break;
            case "MT" :
                var nacionalidad = "MALTA";
                break;
            case "MM" :
                var nacionalidad = "MYANMAR";
                break;
            case "MN" :
                var nacionalidad = "MONGOLIA";
                break;
            case "MK" :
                var nacionalidad = "MACEDONIA";
                break;
            case "ML" :
                var nacionalidad = "MALI";
                break;
            case "MO" :
                var nacionalidad = "MACAO";
                break;
            case "MR" :
                var nacionalidad = "MAURITANIA";
                break;
            case "MS" :
                var nacionalidad = "MONTSERRAT";
                break;
            case "MP" :
                var nacionalidad = "ISLA N.MARIANA";
                break;
            case "MQ" :
                var nacionalidad = "MARTINICA";
                break;
            case "KR" :
                var nacionalidad = "COREA DEL SUR";
                break;
            case "CL" :
                var nacionalidad = "CHILE";
                break;
            case "CM" :
                var nacionalidad = "CAMERUN";
                break;
            case "KM" :
                var nacionalidad = "COMORAS";
                break;
            case "KN" :
                var nacionalidad = "SAINT KITTS AND NEVIS";
                break;
            case "CN" :
                var nacionalidad = "CHINA";
                break;
            case "CU" :
                var nacionalidad = "CUBA";
                break;
            case "CV" :
                var nacionalidad = "CABO VERDE";
                break;
            case "CO" :
                var nacionalidad = "COLOMBIA";
                break;
            case "CR" :
                var nacionalidad = "COSTA RICA";
                break;
            case "KI" :
                var nacionalidad = "KIRIBATI";
                break;
            case "JP" :
                var nacionalidad = "JAPON";
                break;
            case "JO" :
                var nacionalidad = "JORDANIA";
                break;
            case "KG" :
                var nacionalidad = "KIRGUIZISTAN";
                break;
            case "KE" :
                var nacionalidad = "KENIA";
                break;
            case "JM" :
                var nacionalidad = "JAMAICA";
                break;
            case "IR" :
                var nacionalidad = "IRAN, REPUBLICA ISLAMICA DE";
                break;
            case "KH" :
                var nacionalidad = "CAMBOYA";
                break;
            case "IT" :
                var nacionalidad = "ITALIA";
                break;
            case "IS" :
                var nacionalidad = "ISLANDIA";
                break;
            case "EH" :
                var nacionalidad = "SAHARA OCCIDENTAL";
                break;
            case "ER" :
                var nacionalidad = "ERITREA";
                break;
            case "EE" :
                var nacionalidad = "ESTONIA";
                break;
            case "EG" :
                var nacionalidad = "EGIPTO";
                break;
            case "ES" :
                var nacionalidad = "ESPAÑA";
                break;
            case "FJ" :
                var nacionalidad = "ISLAS FIJI";
                break;
            case "KP" :
                var nacionalidad = "COREA DEL NORTE";
                break;
            case "ET" :
                var nacionalidad = "ETIOPIA";
                break;
            case "FI" :
                var nacionalidad = "FINLANDIA";
                break;
            case "EC" :
                var nacionalidad = "ECUADOR";
                break;
            case "CZ" :
                var nacionalidad = "REPUBLICA CHECA";
                break;
            case "DE" :
                var nacionalidad = "ALEMANIA";
                break;
            case "CX" :
                var nacionalidad = "ISLAS NAVIDAD";
                break;
            case "CY" :
                var nacionalidad = "CHIPRE";
                break;
            case "DJ" :
                var nacionalidad = "DJIBOUTI";
                break;
            case "DO" :
                var nacionalidad = "REPUBLICA DOMINICANA";
                break;
            case "DZ" :
                var nacionalidad = "ARGELIA";
                break;
            case "DK" :
                var nacionalidad = "DINAMARCA";
                break;
            case "DM" :
                var nacionalidad = "DOMINICA";
                break;
            }
    return nacionalidad;
    }
}]);