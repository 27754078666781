app.controller('CambiarPasswordCtrl', ['$scope', '$location', '$http', 'jwtHelper', '$mdDialog',
	function ($scope, $location, $http, jwtHelper, $mdDialog) {
	'use strict';

	var idUsuario = localStorage.getItem('idUsuario');
	var idEmpresa = localStorage.getItem('idEmpresa');
	$scope.agente = atob(localStorage.getItem('nombreAgente'));

	$scope.changePassword = function() {
		var dialog = $mdDialog.alert().ok('Aceptar');
		// Comprobar que coinciden las nuevas contraseñas
		if ($scope.new_password !== $scope.repeat_password) {
			$mdDialog.show(dialog.title('Error').content('Las contraseñas no coinciden'));
			return false;
		}
		// Comprobar que la contraseña antigua y nueva son diferentes
		if ($scope.old_password == $scope.new_password) {
			$mdDialog.show(dialog.title('Error').content('La contraseña antigua es igual a la nueva'));
			return false;
		}
		try {
			var data = {
				"IdUsuario": idUsuario,
				"IdEmpresa": idEmpresa,
				"PasswordVieja": $scope.old_password,
				"PasswordNueva": $scope.new_password
			}
			// Llamada a la API
			$http({
                method: "POST",
				url: urlApi + "Usuario/CambioPassword",
				data: JSON.stringify(data),
                headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
            }).then(function successCallback(response) {
                var resp = response.data;
				var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
				var tokenExpirado = jwtHelper.isTokenExpired(resp);

				if (tokenValido && !tokenExpirado) {
					var token = jwtHelper.decodeToken(resp);
					var json = JSON.parse(token.iss);

					localStorage.setItem('forzarPassword', false);
					$mdDialog.show(dialog.title((json.IsModificada) ? 'Contraseña cambiada' : 'Error').content(json.Resultado));
				}
			});

		} catch (error) {
			$mdDialog.show(dialog.title('Error').content(error));
		}


	}

}]);