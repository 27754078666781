app.controller('RecoveryPasswordCtrl', ['$scope', '$location', '$http', 'jwtHelper', '$mdDialog',
	function ($scope, $location, $http, jwtHelper, $mdDialog) {
	'use strict';

	var idUsuario = localStorage.getItem('idUsuario');
	var idEmpresa = localStorage.getItem('idEmpresa');

	var code;

	createCaptcha();

	$scope.changePassword = function() {
		var dialog = $mdDialog.alert().ok('Aceptar');

		if(validateCaptcha()){

			try {

				var data = {
					"Login": $scope.usuario,
				}
				
				// Llamada a la API Recordar contraseña
				$http({
				    method: "POST",
					url: urlApi + "Usuario/RecordarPassword",
					data: JSON.stringify(data)
				}).then(function successCallback(response) {
				    var resp = response.data;
					// console.log(resp);
					var token = jwtHelper.decodeToken(resp);
					// console.log(token);
					var json = JSON.parse(token.iss);
					// console.log(json.Resultado);

					if(json.IsModificada){
						$mdDialog.show(dialog.title('Email enviado con su nueva contraseña').content(json.Resultado));
						$location.url('/#/Login');
					}else{
						$mdDialog.show(dialog.title('Se ha producido un error').content(json.Resultado));
					}
				});

			} catch (error) {
				$mdDialog.show(dialog.title('Error').content(error));
			}
		}

	}

	function createCaptcha(){
		//clear the contents of captcha div first 
		document.getElementById('captcha').innerHTML = "";
		var charsArray =
		"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
		var lengthOtp = 6;
		var captcha = [];
		for (var i = 0; i < lengthOtp; i++) {
			//below code will not allow Repetition of Characters
			var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
			if (captcha.indexOf(charsArray[index]) == -1)
			captcha.push(charsArray[index]);
			else i--;
		}
		var canv = document.createElement("canvas");
		canv.id = "captcha";
		canv.width = 140;
		canv.height = 38;
		var ctx = canv.getContext("2d");
		ctx.font = "25px Georgia";
		ctx.strokeText(captcha.join(""), 0, 30);
		//storing captcha so that can validate you can save it somewhere else according to your specific requirements
		code = captcha.join("");
		document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
	}

			
	function validateCaptcha(){
		var dialog = $mdDialog.alert().ok('Aceptar');
		if (document.getElementById("cpatchaTextBox").value == code) {
			return true;
		}else{
			$mdDialog.show(dialog.title('Captcha iválido').content('Escriba en el campo captcha el texto de la imagen.'));
			createCaptcha();
			return false;
		}
	}

}]);