app.controller('CupsCtrl', ['$scope', '$http', 'jwtHelper', function($scope, $http, jwtHelper) {
    'use strict';

    var cups = atob(localStorage.getItem('cups'));
    var IdUsuario = localStorage.getItem('idUsuario');
    var Logueado = localStorage.getItem('logueado');
    var IdEmpresa = localStorage.getItem('idEmpresa');

    $scope.MostrarContratosCupsObservaciones = MostrarContratosCupsObservaciones;

    if (Logueado == 'true') {
        $http({
            method: "GET",
            url: urlApi + "CUPS/GetCodigoCUPS/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CodigoCUPS/" + cups,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.cups = playloadJson;
            } else {
                $scope.cups = "";
            }

        });
    }

}]);