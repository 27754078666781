app.controller('RenovacionesUltimoMesCtrl', ['$scope', '$http', '$mdDialog', 'jwtHelper', 'serviciosApi', 'serviciosBOE', function ($scope, $http, $mdDialog, jwtHelper, serviciosApi, serviciosBOE) {

    $scope.urlApi = urlApi;
    $scope.MostrarPosiblesRenovaciones = true;
    $scope.RenovandoSinCambios = false;
    $scope.RenovandoConCambios = false;
    $scope.IndexadoOculta = IndexadoOculta;
    $scope.MostrarAltaTablaPreciosTarifas = MostrarAltaTablaPreciosTarifas;
    $scope.MostrarAltaContratoTantosFeesComoPeriodos = MostrarAltaContratoTantosFeesComoPeriodos;
    $scope.impuestoExtraPrecioEnergia = impuestoExtraPrecioEnergia;
    $scope.AvisoFinal = false;
    $scope.LlamadaCUPSfinalizada=false;

    $scope.Aviso = true;

    $scope.selected = [];
    $scope.Pfees = [];
    $scope.Efees = [];

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdEmpresa = localStorage.getItem('idEmpresa');
    var IdAgente = localStorage.getItem('idAgente');

    $scope.ConsumoEstimadoP1 = 0;
    $scope.ConsumoEstimadoP2 = 0;
    $scope.ConsumoEstimadoP3 = 0;
    $scope.ConsumoEstimadoP4 = 0;
    $scope.ConsumoEstimadoP5 = 0;
    $scope.ConsumoEstimadoP6 = 0;

    $scope.mesesRenovacion = 12;
    $scope.validacionMesesRenovacion = true;

    $scope.autocolumn = [{
        name: "Renovar",
        display: "Renovar"
    }, {
        name: "Denominacion",
        display: "Identidad"
    }, {
        name: "FechaVencimiento",
        display: "Fecha Vencimiento"
    }, {
        name: "TarifaGrupo",
        display: "Tarifa Grupo"
    }, {
        name: "Tarifa",
        display: "Tarifa"
    }, {
        name: "CodContrato",
        display: "Contrato"
    }, {
        name: "CUPS",
        display: "CUPS"
    }, {
        name: "Estado",
        display: "Estado"
    }, {
        name: "ConsumoAnual",
        display: "Consumo Anual"
    }, {
        name: "Identidad",
        display: "CIF / DNI"
    }];

    $scope.refrescarDatos = function (){
        $http({
            method: "POST",
            url: urlApi + "/RenovacionContrato/ContratosARenovar",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador')),
                'Content-Type': 'application/json'
            },
            data: {
                'IdAgente': IdAgente,
            }
    
        }).then(function (response) {
            var decodeToken = jwtHelper.decodeToken(response.data);
            var playloadJson = JSON.parse(decodeToken.iss);
            $scope.contratosParaRenovar = playloadJson;
            // console.log(playloadJson);
        })
    }

    $scope.refrescarDatos();

    //la funcion de consumo cups se emplea en la renovacion sin cambios para obtener el consumo Anual con la fecha correcta
    $scope.renovacionSinCambios = function () {
        $scope.ConsumoAnualTotalEstimado = false;
        $scope.MostrarPosiblesRenovaciones = false;
        $scope.RenovandoSinCambios = true;
        $scope.calcularConsumoPorPeriodosCUPS($scope.contrato.Cups);
    }

    $scope.renovacionConCambios = function (TextoTarifa) {
        $scope.TextoTarifa = TextoTarifa;
        $scope.ConsumoAnualTotalEstimado = false;
        $scope.MostrarPosiblesRenovaciones = false;
        $scope.RenovandoConCambios = true;
        $scope.MostrarAltaTablaPreciosTarifas = true;

        $scope.calcularConsumoPorPeriodosCUPS($scope.contrato.Cups);
    }

    //modal que se origina al clickar el botón 'RENOVAR'
    $scope.renovarPopup = function (contrato) {
        $scope.contrato = contrato;
        $mdDialog.show({
            scope: $scope,
            preserveScope: true,
            templateUrl: "modalRenovacionSinCambios.html",
            clickOutsideToClose: true,
            escapeToClose: true,
            onRemoving: function () {
                setTimeout(function () { $scope.MostrarPosiblesRenovaciones = true; }, 500);
                $scope.RenovandoSinCambios = false;
                $scope.RenovandoConCambios = false;
                $scope.mostrarTablasComisiones = false;
                $scope.AvisoFinal = false;

            },
            controller: [
                "$scope", "$http",
                function ($scope, $http) {
                    // console.log(contrato);

                    // console.log($scope.selected);
                }
            ]
        })
    }

    $scope.renovar = function (CodContrato) {
        // console.log($scope.RenovandoConCambios);

        if ($scope.RenovandoConCambios) {
            $scope.CalculoComision=$scope.CalculoComision.replace(',' , '.');
            // console.log($scope.Efees);

            // console.log($scope.Efees[0]);
            // console.log($scope.Efees[6]);
            // console.log(IdTarifa);
            $http({
                method: "POST",
                url: urlApi + "/RenovacionContrato/RenovarContratoCP",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador')),
                    'Content-Type': 'application/json'
                },
                data: {
                    'CodigoContrato': parseFloat(CodContrato.Contrato),
                    'MesesARenovar': $scope.mesesRenovacion,
                    'ComisionCalculada': parseFloat($scope.CalculoComision),
                    'IdTarifaGrupo' : parseFloat($scope.IdTarifaGrupo),
                    'ConsumoAnual' : $scope.ConsumoAnualTotalEstimado,
                    'IdTarifa' : IdTarifa,

                    'cfee1' : ($scope.Efees[0] != undefined) ? $scope.Efees[0] : 0,
                    'cfee2' : ($scope.Efees[1] != undefined) ? $scope.Efees[1] : 0,
                    'cfee3' : ($scope.Efees[2] != undefined) ? $scope.Efees[2] : 0,
                    'cfee4' : ($scope.Efees[3] != undefined) ? $scope.Efees[3] : 0,
                    'cfee5' : ($scope.Efees[4] != undefined) ? $scope.Efees[4] : 0,
                    'cfee6' : ($scope.Efees[5] != undefined) ? $scope.Efees[5] : 0,
                    
                    'pfee1' : ($scope.Pfees[0] != undefined) ? $scope.Pfees[0] : 0, 
                    'pfee2' : ($scope.Pfees[1] != undefined) ? $scope.Pfees[1] : 0, 
                    'pfee3' : ($scope.Pfees[2] != undefined) ? $scope.Pfees[2] : 0, 
                    'pfee4' : ($scope.Pfees[3] != undefined) ? $scope.Pfees[3] : 0, 
                    'pfee5' : ($scope.Pfees[4] != undefined) ? $scope.Pfees[4] : 0, 
                    'pfee6' : ($scope.Pfees[5] != undefined) ? $scope.Pfees[5] : 0, 

                }

            }).then(function (response) {

                $scope.refrescarDatos();

            })
        } else {
            $http({
                method: "POST",
                url: urlApi + "/RenovacionContrato/RenovarContratoAP",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador')),
                    'Content-Type': 'application/json'
                },
                data: {
                    'CodigoContrato': parseFloat(CodContrato.Contrato),
                    'MesesARenovar': 12,
                    'ConsumoAnual' : $scope.ConsumoAnualTotalEstimado,
                    'ComisionCalculada' : CodContrato.CO 
                }

            }).then(function (response) {

                $scope.refrescarDatos();

            })
        }
        $mdDialog.hide();


    }



    //Llamada para obtener listado de tarifas y sus códigos correspondientes
    $http({
        method: "GET",
        url: urlApi + "Tarifa/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }

    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        $scope.TarifasAcceso = playloadJson;
        // console.log($scope.TarifasAcceso);

    });



    $scope.updateGrupoTarifa = function () {
        if ($scope.tarifaGrupoSelec == undefined) { return }
        $scope.mostrarTablasComisiones = true;
        $scope.TextoTarifaFinal = "";
        var tarifaGrupoSeleccionada = JSON.parse($scope.tarifaGrupoSelec);
        $scope.IdTarifaGrupo = tarifaGrupoSeleccionada.IdTarifaGrupo;
        $scope.TextoTarifaFinal = tarifaGrupoSeleccionada.TextoTarifaGrupo;
        
        // $scope.TextosTarifaGrupo.forEach(function(datosTarifa){
        //     // console.log(datosTarifa);
        //     if(datosTarifa.IdTarifaGrupo = $scope.IdTarifaGrupo){
        //         $scope.TextoTarifaFinal = datosTarifa.TextoTarifaGrupo;
        //     }
        // })

        $http({
            method: "POST",
            url: urlApi + "Tarifa/GetDatosEsEspecialIndexado",
            data: JSON.stringify({ "IdTarifa": IdTarifa, "IdTarifaGrupo": $scope.IdTarifaGrupo }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            var tokenExpirado = jwtHelper.isTokenExpired(respuesta);

            if (tokenValido && !tokenExpirado) {

                // Variables temporales para pruebas mientras se termina la llamada de API
                $scope.especial = playloadJson[0].GrupoPrecioPropio;
                $scope.precioIndexado = playloadJson[0].EsPrecioIndexado;
                // console.log("ESPECIAL :" + $scope.especial);
                // console.log("INDEXADO :" + $scope.precioIndexado);

                // NO ESPECIAL Y PRECIO FIJO
                if (!$scope.especial && !$scope.precioIndexado) {
                    $scope.CalculoComision = 0;
                    $scope.MostrarPrecioEnergia = true;
                    $http({
                        method: "POST",
                        url: urlApi + "Tarifa/GetDatosPrecioFijoNoEspecial",
                        data: JSON.stringify({ "IdTarifa": IdTarifa, "IdTarifaGrupo": $scope.IdTarifaGrupo, "IdAgenteComision": IdAgente }),
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            Authorization: atob(localStorage.getItem("TokenValidador"))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);

                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                            alg: ["HS256"]
                        });

                        if (tokenValido) {
                            $scope.arrayPreciosPotenciaEnergia = playloadJson;
                            $scope.tipo = 'noespecial';
                            $scope.updateFeeValue($scope.fee, 'noespecial');
                            // console.log(playloadJson);
                        } else {
                            console.error("HASH DE VERIFICACION INCORRECTO");
                        }
                    });

                } else {
                    $scope.MostrarPrecioEnergia = false;
                }


                // ESPECIAL Y PRECIO INDEXADO
                if ($scope.especial && $scope.precioIndexado) {

                    if (IndexadoOculta) {
                        $scope.MostrarPrecioFEE = false;
                    } else {
                        $scope.MostrarPrecioFEE = true;
                    }

                    $scope.CalculoComision = 0;
                    if ($scope.contrato.ConsumoAnual != null) { LimpiarConsumoanual = $scope.ConsumoAnualTotalEstimado; }

                    $http({
                        method: "POST",
                        url: urlApi + "Tarifa/GetDatosPrecioIndexadoEspecial",
                        data: JSON.stringify({ "IdAgente": IdAgente, "IdTarifa": IdTarifa, "IdTarifaGrupo": $scope.IdTarifaGrupo, "ConsumoAnualSIPS": Math.round(LimpiarConsumoanual) }),
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            Authorization: atob(localStorage.getItem("TokenValidador"))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);


                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                            alg: ["HS256"]
                        });



                        if (tokenValido) {
                            $scope.arrayPreciosPotenciaEnergia = playloadJson;
                            $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                            $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                            $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                            $scope.PrecioVAlorVariable = PrecioVAlorVariable;
                            $scope.NuevaVAriable= newVariableInEnglish;
                            $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;

                            $scope.fee = $scope.MostrarPrecioFEEMaximo;

                            $scope.tipo = 'especial';
                            $scope.updateFeeValue($scope.fee, 'especial');
                            $scope.MostrarAltaInfoFeeMinMax = true;


                        } else {
                            console.error("HASH DE VERIFICACION INCORRECTO");
                        }
                    });

                } else {
                    $scope.MostrarPrecioFEE = false;
                }


                // NO ESPECIAL Y PRECIO INDEXADO
                if (!$scope.especial && $scope.precioIndexado) {

                    if (IndexadoOculta) {
                        $scope.MostrarPrecioFEE = false;
                    } else {

                        $scope.MostrarPrecioFEE = true;
                    }


                    $scope.CalculoComision = 0;

                    $scope.feedisabled = true;

                    $scope.MostrarAltaInfoFeeMinMax = false;

                    $http({
                        method: "POST",
                        url: urlApi + "Tarifa/GetDatosPrecioIndexadoNoEspecial",
                        data: JSON.stringify({ "IdAgenteComision": IdAgente, "IdTarifa": IdTarifa, "IdTarifaGrupo": $scope.IdTarifaGrupo }),
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            Authorization: atob(localStorage.getItem("TokenValidador"))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);

                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                            alg: ["HS256"]
                        });

                        if (tokenValido) {
                            $scope.arrayPreciosPotenciaEnergia = playloadJson;

                            if (IndexadoOculta) {
                                $scope.fee = playloadJson[0].newFEE;
                            } else {

                                $scope.fee = playloadJson[0].datoFEE;
                            }

                            $scope.tipo = 'noespecial';
                            $scope.updateFeeValue($scope.fee, 'noespecial');
                        } else {
                            console.error("HASH DE VERIFICACION INCORRECTO");
                        }
                    });


                } else {
                    $scope.feedisabled = false;
                }



                // ESPECIAL Y PRECIO FIJO
                if ($scope.especial && !$scope.precioIndexado) {

                    $scope.CalculoComision = 0;
                    $scope.MostrarPrecioEnergia = true;
                    $scope.MostrarPrecioFEE = true;
                    
                    if ($scope.contrato.ConsumoAnual != null) { LimpiarConsumoanual = $scope.ConsumoAnualTotalEstimado; }

                    $http({
                        method: "POST",
                        url: urlApi + "Tarifa/GetDatosPrecioFijoEspecial",
                        data: JSON.stringify({
                            "IdTarifa": IdTarifa, "IdTarifaGrupo": $scope.IdTarifaGrupo, "IdAgenteComision": IdAgente,
                            "IdAgente": IdAgente, "ConsumoAnualSIPS": Math.round(LimpiarConsumoanual)
                        }),
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            Authorization: atob(localStorage.getItem("TokenValidador"))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);

                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                            alg: ["HS256"]
                        });

                        if (tokenValido) {

                            $scope.arrayPreciosPotenciaEnergia = playloadJson;
                            // console.log(playloadJson);

                            $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                            $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                            $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                            $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;

                            $scope.fee = $scope.MostrarPrecioFEEMaximo;

                            if ($scope.RequerirFeeManual) {
                                $scope.fee = '';
                            }
                            $scope.tipo = 'especial';

                            $scope.updateFeeValue($scope.fee, 'especial');

                            $scope.MostrarAltaInfoFeeMinMax = true;
                            $scope.Pfees = [];
                            $scope.Efees = [];

                            if ($scope.MostrarAltaContratoTantosFeesComoPeriodos) {

                                // Recorremos el array de precios para insertar por cada periodo el valor maximo del fee a los arrays de fee potencia y energia
                                angular.forEach($scope.arrayPreciosPotenciaEnergia, function (value, key) {
                                    $scope.Pfees.push($scope.MostrarPrecioFEEMaximo);
                                    $scope.Efees.push($scope.MostrarPrecioFEEMaximo);
                                });

                                // CALCULAMOS LA COMISION
                                $scope.updateFeeValuePorPeriodos($scope.fee, 'especial');

                            }


                        } else {
                            console.error("HASH DE VERIFICACION INCORRECTO");
                        }
                    });


                } else {
                    // $scope.MostrarPrecioEnergia = false;
                    // $scope.MostrarPrecioFEE = false;
                }



            } else {
                $scope.GruposTarifa = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }

        }); // end then
    }

    $scope.updateFechaRenovacion = function(meses){
        $scope.mesesRenovacion = meses;
        $scope.validacionMesesRenovacion = $scope.mesesRenovacion <= 36 && $scope.mesesRenovacion > 0 ? true : false;
    }

    $scope.updateFeeValue = function (datos, tipo) {

        $scope.fee = datos;

        if (tipo == 'especial') {

            var flag = 0;
            $scope.PorcentajesComision.forEach(function (item) {

                if ($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]) {
                    // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
                    $scope.CalculoComision = (($scope.ConsumoAnualTotalEstimado * datos) / 1000) * (item["PorcentajeComision"] / 100);
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                    console.log($scope.CalculoComision);
                    flag = 1;
                }
            });
            if (flag == 0) { $scope.CalculoComision = "Datos no válidos"; }
        } else if (tipo == 'noespecial') {
            var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
            // console.log(valores);
            if (valores != undefined) {
                if (valores.TipoAgenteParametro == 5) {
                    // console.log("Entro al 5");
                    $scope.CalculoComision = $scope.ConsumoAnualTotalEstimado * valores.Valor;
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                } else if (valores.TipoAgenteParametro == 10) {
                    //Desarrollo Luxam/Madrid
                    if (IndexadoOculta) {
                        // console.log("Entro al 10");
                        $scope.CalculoComision = (($scope.ConsumoAnualTotalEstimado * datos) / 1000);
                    } else {
                        // console.log("Entro al 10");
                        $scope.CalculoComision = (($scope.ConsumoAnualTotalEstimado * datos) / 1000) * (valores.Valor / 100);
                    }

                    //    console.log("Entro al 10");
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                }
            }
        }
    }

    $scope.obtenerGruposTarifa = function (TextoTarifa) {
       
        angular.forEach($scope.TarifasAcceso, function (TarifaAcceso) {

            if (TarifaAcceso.TextoTarifa == TextoTarifa) {
                IdTarifa = TarifaAcceso.IdTarifa;
                return;
            }
        })

        $http({
            method: "GET",
            url: urlApi + "Tarifa/GetDTOAllGruposTarifa/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Tarifa/" + IdTarifa,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }

        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            $scope.TextosTarifaGrupo = playloadJson;
            // console.log($scope.TextosTarifaGrupo);
        });
    }


    $scope.calcularConsumoPorPeriodosCUPS = function (cups) {
        $scope.IdTarifaGrupo = undefined;
        $scope.TextosTarifaGrupo = [];
        $scope.LlamadaCUPSfinalizada=false;

        serviciosApi.getSips(cups.toUpperCase(), "", "", "", "", "", "")
            .then(
                function successCallback(responseSIPS) {

                    $scope.ConsumoEstimadoP1 = 0;
                    $scope.ConsumoEstimadoP2 = 0;
                    $scope.ConsumoEstimadoP3 = 0;
                    $scope.ConsumoEstimadoP4 = 0;
                    $scope.ConsumoEstimadoP5 = 0;
                    $scope.ConsumoEstimadoP6 = 0;

                    var response = serviciosBOE.respuestaSIPS(responseSIPS);
                    var resupuestaConsumos = response.data.ConsumosSips;
                    var resupuestaPotencias = response.data.ClientesSips;
                    $scope.respuestaSIPS = response;

                    if (resupuestaPotencias.length > 0) {
                        serviciosBOE.TarifasComunicacion()
                            .then(function successCallback(responseTarifas) {​  //Sacamos todas las tarifas antiguas
                                var respuesta = responseTarifas.data;
                                var decodeToken = jwtHelper.decodeToken(respuesta);
                                var playloadJson = JSON.parse(decodeToken.iss);
                                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                    alg: ["HS256"],
                                });
                                var tokenExpirado = jwtHelper.isTokenExpired(respuesta);

                                if (tokenValido && !tokenExpirado) {
                                    serviciosBOE.conversionTarifas(playloadJson, resupuestaPotencias[0].CodigoPostalPS, resupuestaConsumos) //Comaparamos tarifas del sips con la llamada anterior
                                        .then(function successCallback(responseValores) {
                                            var respuesta = responseValores.data;
                                            var decodeToken = jwtHelper.decodeToken(respuesta);
                                            var valorConversionTarifa = JSON.parse(decodeToken.iss);

                                            if (repartoconsumoBOEalta) {
                                                var respuestaConsumos = serviciosBOE.repartoConsumo(valorConversionTarifa, resupuestaConsumos);

                                                //obtenemos la ultima fechaFin disponible de los consumos
                                                var FechaFin = respuestaConsumos[respuestaConsumos.length - 1].FechaFin;
                                                //ahora creamos una fecha que es 12 meses menor a la fechafin
                                                var FechaFin2 = FechaFin.split('T');
                                                var FechaInicio = new Date(FechaFin2[0]);
                                                FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';

                                                var arraySipsFiltradoFechas = [];
                                                var sumatorioActivas = 0;
                                                $.grep(respuestaConsumos, function (n, i) {
                                                    if (n.FechaInicio >= FechaInicio) {

                                                        //Una vez filtrado, devolver un objeto con la suma de los campos correspondientes
                                                        arraySipsFiltradoFechas.push(n);
                                                        sumatorioActivas += respuestaConsumos[i]['Activa1'] + respuestaConsumos[i]['Activa2'] + respuestaConsumos[i]['Activa3'] +
                                                            respuestaConsumos[i]['Activa4'] + respuestaConsumos[i]['Activa5'] + respuestaConsumos[i]['Activa6'];

                                                        $scope.ConsumoEstimadoP1 += respuestaConsumos[i].Activa1;
                                                        $scope.ConsumoEstimadoP2 += respuestaConsumos[i].Activa2;
                                                        $scope.ConsumoEstimadoP3 += respuestaConsumos[i].Activa3;
                                                        $scope.ConsumoEstimadoP4 += respuestaConsumos[i].Activa4;
                                                        $scope.ConsumoEstimadoP5 += respuestaConsumos[i].Activa5;
                                                        $scope.ConsumoEstimadoP6 += respuestaConsumos[i].Activa6;
                                                    }
                                                });


                                                var fechaInicio = new Date(arraySipsFiltradoFechas[0]['FechaInicio']).getTime();
                                                var fechaFin = new Date(FechaFin).getTime();
                                                var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);

                                                // console.log("Num Dias: " + countDias);
                                                $scope.ConsumoEstimadoP1 = Math.round((($scope.ConsumoEstimadoP1) / countDias) * 365);
                                                $scope.ConsumoEstimadoP2 = Math.round((($scope.ConsumoEstimadoP2) / countDias) * 365);
                                                $scope.ConsumoEstimadoP3 = Math.round((($scope.ConsumoEstimadoP3) / countDias) * 365);
                                                $scope.ConsumoEstimadoP4 = Math.round((($scope.ConsumoEstimadoP4) / countDias) * 365);
                                                $scope.ConsumoEstimadoP5 = Math.round((($scope.ConsumoEstimadoP5) / countDias) * 365);
                                                $scope.ConsumoEstimadoP6 = Math.round((($scope.ConsumoEstimadoP6) / countDias) * 365);


                                                $scope.PotenciaP1 = $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP1
                                                $scope.PotenciaP2 = $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP2
                                                $scope.PotenciaP3 = $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP3
                                                $scope.PotenciaP4 = $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP4
                                                $scope.PotenciaP5 = $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP5
                                                $scope.PotenciaP6 = $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP6

                                                $scope.ConsumoAnualTotalEstimado =$scope.ConsumoEstimadoP1 + $scope.ConsumoEstimadoP2 + $scope.ConsumoEstimadoP3 + $scope.ConsumoEstimadoP4 + $scope.ConsumoEstimadoP5 + $scope.ConsumoEstimadoP6;
                                                // console.log($scope.ConsumoAnualTotalEstimado + " = " + $scope.ConsumoEstimadoP1 + " - " + $scope.ConsumoEstimadoP2 + " - " + $scope.ConsumoEstimadoP3 + " - " + $scope.ConsumoEstimadoP4 + " - " + $scope.ConsumoEstimadoP5 + " - " + $scope.ConsumoEstimadoP6);

                                                
                                                if($scope.RenovandoConCambios){
                                                    $scope.obtenerGruposTarifa($scope.TextoTarifa);
                                                }
                                                $scope.LlamadaCUPSfinalizada=true;

                                            } else {
                                                var respuestaConsumos = response.data.ConsumosSips;
                                            }
                                        })
                                }
                            })
                    }else{
                        $mdDialog.hide();
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .htmlContent('No se han encontrado datos.')
                                .ariaLabel('No se han encontrado datos.')
                                .ok('Aceptar')
                        );
                    }
                })
    }

    $scope.AvisoFinalTrigger = function(contrato){
        Today = new Date(contrato.FechaVencimiento);
        $scope.Today = new Date(Today.setMonth(Today.getMonth() + parseInt($scope.mesesRenovacion)));
        $scope.AvisoFinal = true;
        $scope.MostrarAltaTablaPreciosTarifas = false;
    }

    $scope.updateFeeValuePorPeriodos = function (datos, tipo) {
        if (tipo == 'especial') {

            $scope.CalculoComisionEnergia = 0;
            $scope.CalculoComisionPotencia = 0;

            $scope.arrayPreciosPotenciaEnergia.forEach(function (item, i) {
                $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP" + (i + 1)] * ($scope.Efees[i] / 1000)) + $scope.CalculoComisionEnergia;
                $scope.CalculoComisionPotencia = ($scope["PotenciaP" + (i + 1)] * $scope.Pfees[i]) + $scope.CalculoComisionPotencia;
            });

            if ($scope.PorcentajeComision == 0 && $scope.PorcentajesComision[0] && $scope.PorcentajesComision[0].PorcentajeComision) {
                $scope.PorcentajeComision = $scope.PorcentajesComision[0].PorcentajeComision;
            }

            $scope.CalculoComisionEnergia = $scope.CalculoComisionEnergia * ($scope.PorcentajeComision / 100) // dividimos para 100 para sacar el porcentaje
            $scope.CalculoComisionPotencia = $scope.CalculoComisionPotencia * ($scope.PorcentajeComision / 100) // dividimos para 100 para sacar el porcentaje

            $scope.CalculoComision = $scope.CalculoComisionEnergia + $scope.CalculoComisionPotencia;

            $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
            $scope.CalculoComision = $scope.CalculoComision + "€";
            $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");

        } else if (tipo == 'noespecial') {
            var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
            // console.log(valores);
            if (valores != undefined) {
                if (valores.TipoAgenteParametro == 5) {
                    //    console.log("Entro al 5");
                    $scope.CalculoComision = $scope.ConsumoAnualTotalEstimado * valores.Valor;
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                    // console.log($scope.CalculoComision);
                } else if (valores.TipoAgenteParametro == 10) {
                    //    console.log("Entro al 10");
                    //EJemplo de la cuenta que se produce: ((6609 * 9)/1000) * (50/100) = 29,74€
                    $scope.CalculoComision = (($scope.ConsumoAnualTotalEstimado * datos) / 1000) * (valores.Valor / 100);
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                }
            }
        }

    }
}])