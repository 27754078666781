app.controller('ContratosRenovacionCtrl', ['$scope', '$location', function($scope, $location) {
    'use strict';

    var IdAgente = localStorage.getItem('idAgente');
    var IdUsuario = localStorage.getItem('idUsuario');
    var Logueado = localStorage.getItem('logueado');

    $scope.send = function(datos) {
        localStorage.setItem('cups', btoa(datos.contratoRenovacion.CodigoCUPS));
        $location.url("/contratos/cups");
    };

    $scope.sendCliente = function(datos) {
        localStorage.setItem('identificador', btoa(datos.contratoRenovacion.IdCliente));
        $location.url("/clientes/perfil");
    };

    $scope.sendContrato = function(datos) {
        localStorage.setItem("numeroPoliza", btoa(datos.contratoRenovacion.IdContrato));
        localStorage.setItem("idCliente", btoa(datos.contratoRenovacion.IdCliente));
        $location.url("/contratos/resumen");
	};

	$scope.dateDiff = function(date) {
		return moment(date).fromNow();
	}

    $scope.selected = [];

    //multisearch
    $scope.autocolumn = [{
        name: "CodigoContrato",
        display: "Contrato",
    }, {
        name: "DenominacionCliente",
        display: "Cliente",
    }, {
        name: "CodigoCUPS",
        display: "CUPS",
    }, {
        name: "FechaContrato",
        display: "Fecha Contrato"
    }, {
        name: "FechaAlta",
        display: "Fecha Alta"
    }, {
        name: "FechaBaja",
        display: "Fecha Baja"
    }, {
        name: "FechaVto",
        display: "Fecha Vencimiento"
    }];

}]);