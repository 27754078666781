app.controller('AltaContratosCtrl', ['$scope', '$http', 'serviciosApi', '$rootScope', '$mdDialog', '$location', 'jwtHelper', '$element', 'servicioValidaciones', 'serviciosBOE', 'emailErrorContratos', function ($scope, $http, serviciosApi, $rootScope, $mdDialog, $location, jwtHelper, $element, servicioValidaciones, serviciosBOE, emailErrorContratos) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdAgente = localStorage.getItem('idAgente');
    var NombreAgente = atob(localStorage.getItem('nombreAgente'));
    var IdEmpresa = localStorage.getItem('idEmpresa');


    moment().locale('es');

    function isUndefined(t) {
        if (t === undefined) {
            return true;
        }
        return false;
    }

    var bonoCheckeado = "";
    var segmentacion = "";
    var alert;
    var checkPasaporte;
    var URL = "";
    var LimpiarConsumoanual = 0;
	$scope.controlcheckB70 = false;
	$scope.MostrarBonos = false;
	$scope.itemsB70 = [];
	$scope.arraygrupoProd = [];
	$scope.arrayProdB70 = [];
	$scope.GruposProd = [];
	$scope.ProdsB70 = [];
	$scope.arraycodigoCNMC = [];
	$scope.arrayplazo = [];
	$scope.ProdsB70_1 = [];
	$scope.ColeccionBonos = [];
    $scope.IdAgente = IdAgente;


    $scope.respuesta = "";

    $scope.ModelosContratoTemporal = [];



    /**
     * Los inicializo a true para que estén todos deshabilitados
     * Luego cada uno se modifica en funcion de lo que hagan
     */
    $scope.disabledCodPostalSuministro = disabledCodPostal;
    $scope.disabledCodPostalTitular = disabledCodPostal;
    $scope.disabledCodPostalEnvio = disabledCodPostal;
    $scope.disabledRazonSocial = disabledRazonSocial;
    $scope.disabledFechaEfecto = disabledFechaEfecto;
    $scope.ComentarioPiePaginaDisabled = ComentarioPiePaginaDisabled;
    $scope.SimplificacionesTarifaAntiguaEleia = SimplificacionesTarifaAntiguaEleia;
    $scope.PermitirEditarPotenciasDeSips = PermitirEditarPotenciasDeSips;
    $scope.DesarrolloFraccionamientoB70 = DesarrolloFraccionamientoB70;
    $scope.IsSistemaBonoActivo = IsSistemaBonoActivo;

    $scope.DesarrolloFraccionamientoB70Met = DesarrolloFraccionamientoB70Met;

    $scope.textoViaSuministro = "";
    $scope.textoViaTitular = "";
    $scope.textoViaEnvio = "";

    $scope.nombreEmpresaLogin = nombreEmpresaLogin;
    $scope.nombreEmpresa = nombreEmpresa;
    $scope.PrecioTarifaExistente = false;
    $scope.MostrarBotonCargaContrato = true;
    $scope.spinnerBotonCargaContrato = false;

    $scope.MostrarAltaDestinoEnergiaGas = MostrarAltaDestinoEnergiaGas;
    $scope.MostrarFechaAlta = MostrarFechaAlta;
    $scope.MostrarFechaFin = MostrarFechaFin;
    $scope.MostrarComisionesSubAgente = MostrarComisionesSubAgente;
    $scope.MostrarPorcentajeCO = MostrarPorcentajeCO;
    $scope.MostrarComisionCalculada = MostrarComisionCalculada;
    $scope.CampoTarifaEditable = CampoTarifaEditable;
    $scope.CampoProductoEditable = CampoProductoEditable;
    $scope.CampoPerfilFacturacionEditable = CampoPerfilFacturacionEditable;
    $scope.MostrarContratoTipoAlquiler = MostrarContratoTipoAlquiler;
    $scope.CampoPotenciaP1Editable = CampoPotenciaP1Editable;
    $scope.CampoPotenciaP2Editable = CampoPotenciaP2Editable;
    $scope.CampoPotenciaP3Editable = CampoPotenciaP3Editable;
    $scope.CampoPotenciaP4Editable = CampoPotenciaP4Editable;
    $scope.CampoPotenciaP5Editable = CampoPotenciaP5Editable;
    $scope.CampoPotenciaP6Editable = CampoPotenciaP6Editable;
    $scope.tipoFirmaDigitalSmsEmail = tipoFirmaDigitalSmsEmail;
    $scope.CampoCaudalEditable = CampoCaudalEditable;
    $scope.CampoPeriodoEditable = CampoPeriodoEditable;
    $scope.CampoTipoImpresionEditable = CampoTipoImpresionEditable;
    $scope.CampoTipoSolicitudEditable = CampoTipoSolicitudEditable;
    $scope.CampoModeloContratoEditable = CampoModeloContratoEditable;
    $scope.CampoIdiomaContratoEditable = CampoIdiomaContratoEditable;
    $scope.CampoIdiomaContratoEditableGas = CampoIdiomaContratoEditableGas;
    $scope.CampoPeriodoOcultable = CampoPeriodoOcultable;
    $scope.CampoTipoImpresionOcultable = CampoTipoImpresionOcultable;
    $scope.CampoTipoSolicitudOcultable = CampoTipoSolicitudOcultable;
    $scope.CampoModeloContratoOcultable = CampoModeloContratoOcultable;
    $scope.CampoIdiomaContratoOcultable = CampoIdiomaContratoOcultable;
    $scope.CampoTipoCobroOcultable = CampoTipoCobroOcultable;
    $scope.OcultarFeeAltaContrato = OcultarFeeAltaContrato;
    $scope.tipoCobroNzwei = tipoCobroNzwei;
    $scope.CampoGrupoProductoEditable = CampoGrupoProductoEditable;
    $scope.CampoTipoCobroEditable = CampoTipoCobroEditable;
    $scope.ValorDesplegableTipoCobro = ValorDesplegableTipoCobro;
    $scope.MostrarAltaCosteGestion = MostrarAltaCosteGestion;
    $scope.MostrarAltaDatosRepresentante = MostrarAltaDatosRepresentante;
    $scope.MostrarAltaMantenimiento = MostrarAltaMantenimiento;
    $scope.MostrarAltaObservaciones = MostrarAltaObservaciones;
    $scope.MostrarAltaComentarioPiePagina = MostrarAltaComentarioPiePagina;
    $scope.MostrarAltaPCTD = MostrarAltaPCTD;
    $scope.MostrarExportarDatosContrato = MostrarExportarDatosContrato;
    $scope.MostrarImportarDatosContrato = MostrarImportarDatosContrato;
    $scope.MostrarAltaContratoRefCatastral = MostrarAltaContratoRefCatastral;
    $scope.requiredContratoNombre = requiredContratoNombre;
    $scope.requiredContratoApellido1 = requiredContratoApellido1;
    $scope.requiredContratoApellido2 = requiredContratoApellido2;
    $scope.requiredContratoRazonSocial = requiredContratoRazonSocial;
    $scope.requiredContratoTelefono = requiredContratoTelefono;
    $scope.requiredContratoEmail = requiredContratoEmail;
    $scope.requiredContratoAltaDomestico = requiredContratoAltaDomestico;
    $scope.requiredContratoMovil = requiredContratoMovil;
    $scope.requiredContratoCosteOperacion = requiredContratoCosteOperacion;
    $scope.requiredAltaDatosRepresentanteSiEsNif = requiredAltaDatosRepresentanteSiEsNif;
    $scope.requiredOtrosColectivo = requiredOtrosColectivo;
    $scope.FechasDisa = FechasDisa;
    $scope.requiredRefCatastral = requiredRefCatastral;

    $scope.IndexadoOculta = IndexadoOculta;

    $scope.ValidacionCupsEmpieceXES = ValidacionCupsEmpieceXES;
    $scope.MostrarMotivoModificacion = MostrarMotivoModificacion;
    $scope.MostrarCheckCertificadoElectronico = MostrarCheckCertificadoElectronico;

    $scope.MustComprobarRepresentante = MustComprobarRepresentante;
    $scope.MustComprobarRepresentanteAux = MustComprobarRepresentante;

    $scope.EleiaComisionGasMTFyMTV = EleiaComisionGasMTFyMTV;

    $scope.MostrarAltaContratoTantosFeesComoPeriodos = MostrarAltaContratoTantosFeesComoPeriodos;

    $scope.TextoBotonInsertar = TextoBotonInsertar;

    $scope.ObligatorioRepresentantePersonaFisica = ObligatorioRepresentantePersonaFisica;
    $scope.AutoAsignacionColectivoRepresentante = AutoAsignacionColectivoRepresentante;
    $scope.permitircallenoencontrada = permitircallenoencontrada;

    $scope.ListadoCiudadesBloqueado = true;
    $scope.ListadoCiudadesTitularBloqueado = true;
    $scope.ListadoCiudadesEnvioBloqueado = true;

    $scope.ListadoCallesSuministroBloqueado = true;
    $scope.ListadoCallesTitularBloqueado = true;
    $scope.ListadoCallesEnvioBloqueado = true;
    $scope.bloqueoCalleEnvioCopiando = true;
    $scope.bloqueoCalleTitularCopiando = true;

    $scope.CosumoAnualDisabled = true;
    $scope.ObservacionesDisabled = ObservacionesDisabled;
    $scope.MostrarCheckPasaporte = MostrarCheckPasaporte;
    $scope.DiasInicioUsoDomestico = DiasInicioUsoDomestico;
    $scope.numeroDigitosMaximosTelefono = numeroDigitosMaximosTelefono;

    $scope.muestraocultaprecio = false;
    $scope.MostrarPrecioEnergia = true;
    $scope.MostrarPrecioFEEMinimo = 0;
    $scope.MostrarPrecioFEEMaximo = 0;
    $scope.MostrarPrecioFEE = true;

    $scope.MostrarTipoAlquiler = false;
    $scope.campoPotenciaP1disabled = true;
    $scope.campoPotenciaP2disabled = true;
    $scope.campoPotenciaP3disabled = true;
    $scope.campoPotenciaP4disabled = true;
    $scope.campoPotenciaP5disabled = true;
    $scope.campoPotenciaP6disabled = true;

    $scope.Es360 = false;

    $scope.PorcentajeComision = 0;
    $scope.PorcentajesComision = new Array();
    $scope.CalculoComision = 0;
    $scope.comisionTotalGas = 0;
    $scope.comisionTotalGasAgente = 0;

    $scope.fee = 0;
    $scope.mtv = 0;
    $scope.mtf = 0;

    $scope.especial = "";
    $scope.precioIndexado = "";

    $scope.Pfees = []; //  TODO: Se hizo para antonio madrid fees por periodos
    $scope.Efees = []; //  TODO: Se hizo para antonio madrid fees por periodos
    $scope.PorcentajeComisionFijo = 50;
    $scope.PorcentajeComisionFijo = (50 / 100);
    // ES0031405932590013QJ0F

    $scope.impuestoExtraPrecioEnergia = impuestoExtraPrecioEnergia;


    $scope.arrayPreciosPotenciaEnergia = new Array();
    $scope.feedisabled = feedisabled;
    $scope.MostrarAltaTablaPreciosTarifas = MostrarAltaTablaPreciosTarifas;

    $scope.MostrarAltaInfoFeeMinMax = MostrarAltaInfoFeeMinMax;
    $scope.OcultaEsteModeloContrato = OcultaEsteModeloContrato;
    $scope.LlamadaModeloContratoSinAgente = LlamadaModeloContratoSinAgente;
    $scope.repartoconsumoBOEalta = repartoconsumoBOEalta;

    $scope.tipo = "";
    $scope.requireFijoOrMovil = true;
    $scope.noBloquearDistribuidora = noBloquearDistribuidora;

    $scope.tipoModeloImpresion = "";
    $scope.SimplificacionesEnergiaGalega = SimplificacionesEnergiaGalega;
    $scope.SimplificacionesIdiomaLideraEnergia = SimplificacionesIdiomaLideraEnergia;
    $scope.ValidacionPatternTelefono = ValidacionPatternTelefono;
    $scope.MostrarMensajeAltaTelematica = MostrarMensajeAltaTelematica;
    $scope.RequerirFeeManual = RequerirFeeManual;
    $scope.RepresentanteColectivoSoloPersonaFisica = RepresentanteColectivoSoloPersonaFisica;

    $scope.asignacionModelosImpresionEleia = asignacionModelosImpresionEleia;
    $scope.asignacionModelosImpresionPorNombreProducto = asignacionModelosImpresionPorNombreProducto;
    $scope.TextoTarifaGrupo = "";

    $scope.ValidacionRequeridaIban = ValidacionRequeridaIban;

    $scope.AnularAlta = AnularAlta;

    $scope.UrlApiCorrecionImprimirContratoBien = UrlApiCorrecionImprimirContratoBien;

    $scope.CaudalPuedeSerNuloCero = CaudalPuedeSerNuloCero;

    $scope.MostrarAltaContratoFechaEfecto2017 = MostrarAltaContratoFechaEfecto2017;
    $scope.MostrarAltaContratoFechaEfectoSolo2017 = MostrarAltaContratoFechaEfectoSolo2017; // solo mostrará las opciones que contengan el valor 2017

    $scope.fechaActivacionPrevistaDisa = fechaActivacionPrevistaDisa;
    $scope.CopiarDatosTitularEnRepresentante = CopiarDatosTitularEnRepresentante;

    $scope.MotivosModificacion = [
        {
            IdSolicitudCambioTitular: "1",
            CodigoTipoCambioTitular: "T",
            TextoTipoCambioTitular: "Traspaso",
        },
        {
            IdSolicitudCambioTitular: "2",
            CodigoTipoCambioTitular: "S",
            TextoTipoCambioTitular: "Subrogación",
        },
        {
            IdSolicitudCambioTitular: "3",
            CodigoTipoCambioTitular: "J",
            TextoTipoCambioTitular: "Cambio pot Justo Titulo",
        },
        {
            IdSolicitudCambioTitular: "4",
            CodigoTipoCambioTitular: "A",
            TextoTipoCambioTitular: "Cambio datos administrativos",
        },
        {
            IdSolicitudCambioTitular: "5",
            CodigoTipoCambioTitular: "B",
            TextoTipoCambioTitular: "Alta y Baja",
        },
        {
            IdSolicitudCambioTitular: "6",
            CodigoTipoCambioTitular: "H",
            TextoTipoCambioTitular: "Alta y Baja en ciclo lectura",
        },
    ];




    $scope.IdMarca = IdMarca; // GUIXES - AREA
    $scope.IdMarcaGas = IdMarcaGas; //

    $scope.consultaErroresAlta = function (mostrarErroresAlta) {
        if (mostrarErroresAlta == undefined)
            mostrarErroresAlta = false;
        $scope.mostrarErroresAlta = !mostrarErroresAlta;
    };

    $scope.TiposImpresiones = [
        {
            Valor: "P",
            Texto: "Papel & email",
        },
        {
            Valor: "Q",
            Texto: "Papel",
        },
        {
            Valor: "E",
            Texto: "Email",
        },
        {
            Valor: "W",
            Texto: "Web",
        },
        {
            Valor: "R",
            Texto: "Recibo Bancario",
        },
    ];
    if (nombreEmpresa === 'ELEIA') {
        $scope.TiposImpresiones = [
            {
                Valor: "P",
                Texto: "Papel & email",
            },
            {
                Valor: "Q",
                Texto: "Papel",
            },
            {
                Valor: "E",
                Texto: "Email",
            },
        ];
    }
    if (nombreEmpresa === 'AEO' || nombreEmpresa === 'CHARRA' || nombreEmpresa === 'EBRO') {
        $scope.TiposImpresiones = [
            {
                Valor: "Q",
                Texto: "Papel",
            },
            {
                Valor: "E",
                Texto: "Email",
            },
        ];
    }
    if ($scope.SimplificacionesEnergiaGalega) {
        $scope.TiposImpresiones = [
            {
                Valor: "E",
                Texto: "Email",
            },
        ];
    }

    $scope.IdiomasContratos = [{
        "Valor": "1",
        "Texto": "Castellano"
    },
    {
        "Valor": "2",
        "Texto": "Asturiano"
    },
    {
        "Valor": "3",
        "Texto": "Euskera"
    },
    {
        "Valor": "4",
        "Texto": "Catalán"
    },
    {
        "Valor": "5",
        "Texto": "Gallego"
    },
    {
        "Valor": "6",
        "Texto": "Inglés"
    },
    {
        "Valor": "7",
        "Texto": "Danés"
    },
    {
        "Valor": "8",
        "Texto": "Alemán"
    },
    {
        "Valor": "9",
        "Texto": "Noruego"
    },
    {
        "Valor": "10",
        "Texto": "Sueco"
    },
    {
        "Valor": "11",
        "Texto": "Francés"
    },
    {
        "Valor": "12",
        "Texto": "Portugués"
    }
    ];
    if ($scope.SimplificacionesEnergiaGalega) {
        $scope.IdiomasContratos = [{
            "Valor": "1",
            "Texto": "Castellano"
        },
        {
            "Valor": "5",
            "Texto": "Gallego"
        },
        ];
    }
	if ($scope.SimplificacionesIdiomaLideraEnergia) {
        $scope.IdiomasContratos = [{
            "Valor": "1",
            "Texto": "Castellano"
        },
		{
			"Valor": "4",
			"Texto": "Catalán"
		},
        ];
    }
    $scope.FechaEstimadaInicioSuministroDisabled = FechaEstimadaInicioSuministroDisabled;
    $scope.FechaEstimadaFinSuministroDisabled = FechaEstimadaFinSuministroDisabled;
    $scope.FechaEstimadaFinSuministroDisabledLuz = FechaEstimadaFinSuministroDisabledLuz;
    $scope.DiasFechaEstimadaInicioSuministro = DiasFechaEstimadaInicioSuministro;

    $scope.FechaAlta = moment().add($scope.DiasFechaEstimadaInicioSuministro, 'd').toDate();
    $scope.FechaVto = moment().add({ days: $scope.DiasFechaEstimadaInicioSuministro, years: 1 }).toDate();


    $scope.manana = new Date();

    if(!$scope.fechaActivacionPrevistaDisa){ // Disa quiere poder poner la fecha desde el día actual
        $scope.manana.setDate($scope.manana.getDate() + 7); // fijar la fecha minima hoy + 1 = mañana para asignarsela a activacion prevista
    }

    $scope.ActivacionPrevista = $scope.manana;

    $scope.changeFechaAlta = function (date) {
        $scope.FechaAlta = moment(date).format('YYYY/MM/DD');
        $scope.FechaVto = moment(date).add({ years: 1 }).toDate(); // Pedido por eleia, una vez seleccionado una fecha inicio suministro que se asigne un año mas la fechaVTO
    }
    $scope.changeFechaVto = function (date) {
        $scope.FechaVto = moment(date).format('YYYY/MM/DD');
    }
    $scope.changeActivacionPrevista = function (date) {
        $scope.ActivacionPrevista = moment(date).format('YYYY/MM/DD');
    }

    $scope.CodigoREEComercializadora = CodigoREEComercializadora;
    $scope.MostrarPreciosFijosVigentes = MostrarPreciosFijosVigentes;
    $scope.ValorDesplegablePeriodo = ValorDesplegablePeriodo;
    $scope.ValorDesplegableTipoSolicitud = ValorDesplegableTipoSolicitud;
    $scope.ValorDesplegableTipoSolicitudGas = ValorDesplegableTipoSolicitudGas;

    $scope.ValorDesplegableModeloContrato = ValorDesplegableModeloContrato;
    $scope.ValorDesplegableModeloContratoGas = ValorDesplegableModeloContratoGas;

    $scope.ValorDesplegableTipoImpresion = ValorDesplegableTipoImpresion;
    $scope.ValorDesplegableIdiomaContrato = ValorDesplegableIdiomaContrato;
    $scope.ValorDesplegableIdiomaContratoGas = ValorDesplegableIdiomaContratoGas;
    $scope.ValorDesplegableFechaEfecto = ValorDesplegableFechaEfecto;
    $scope.ValorDesplegableFechaEfectoGas = ValorDesplegableFechaEfectoGas;
    $scope.ValorDesplegableGrupoProducto = ValorDesplegableGrupoProducto;
    $scope.ValorDesplegableDestinoEnergia = ValorDesplegableDestinoEnergia;
    $scope.MostrarAltaConsumoAnual = MostrarAltaConsumoAnual;

    $scope.MostrarAltaConsumoAnualPeriodos = MostrarAltaConsumoAnualPeriodos;

    $scope.MostrarAltaServiciosValorAnadido = MostrarAltaServiciosValorAnadido;
    $scope.MostrarContratoAdjuntarFicheroPDF = MostrarContratoAdjuntarFicheroPDF;

    $scope.MostrarBIC = MostrarBIC;

    $scope.TextoContratosGrupoTarifa = TextoContratosGrupoTarifa;
    $scope.TextoContratosGrupoTarifaDesplegable = TextoContratosGrupoTarifaDesplegable;

    $scope.MostrarParticularOEmpresaFiltroTarifas = MostrarParticularOEmpresaFiltroTarifas;

    $scope.AltaContratoIsElectronico = AltaContratoIsElectronico;
    $scope.OcultarComisionAnualEstimada = OcultarComisionAnualEstimada;

    $scope.selected = [];
    $scope.showAlert = showAlert;

    $scope.MostrarPreciosMixtos = false;

    $scope.ciudadItem = {};
    $scope.calleItem = {};
    $scope.ciudadTitularItem = {};
    $scope.calleTitularItem = {};
    $scope.ciudadEnvioItem = {};
    $scope.calleEnvioItem = {};

    $scope.ocultarPotenciasVacias = ocultarPotenciasVacias;
    $scope.ocultarPotencia1 = false;
    $scope.ocultarPotencia2 = false;
    $scope.ocultarPotencia3 = false;
    $scope.ocultarPotencia4 = false;
    $scope.ocultarPotencia5 = false;
    $scope.ocultarPotencia6 = false;

    $scope.mostrarFechasDisa = false;

    $scope.callejeroINESuministro = {
        "codCiudad": "",
        "desCiudad": "",
        "codPais": "",
        "desPais": "",
        "codProvincia": "",
        "desProvincia": "",
        "codMunicipio": "",
        "desMunicipio": "",
        "codigoProveedorProvincia": "",
        "codigoProveedorMunicipio": "",
        "codigoProveedorCiudad": ""
    }
    $scope.callejeroINETitular = {
        "codCiudad": "",
        "desCiudad": "",
        "codPais": "",
        "desPais": "",
        "codProvincia": "",
        "desProvincia": "",
        "codMunicipio": "",
        "desMunicipio": "",
        "codigoProveedorProvincia": "",
        "codigoProveedorMunicipio": "",
        "codigoProveedorCiudad": ""
    }
    $scope.callejeroINEEnvio = {
        "codCiudad": "",
        "desCiudad": "",
        "codPais": "",
        "desPais": "",
        "codProvincia": "",
        "desProvincia": "",
        "codMunicipio": "",
        "desMunicipio": "",
        "codigoProveedorProvincia": "",
        "codigoProveedorMunicipio": "",
        "codigoProveedorCiudad": ""
    }

    function showAlert() {
        alert = $mdDialog.confirm()
            .clickOutsideToClose(false)
            .title('')
            .htmlContent('<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Contrato insertado correctamente.</p>')
            .ariaLabel('Contrato Confirmado')
            .ok('Aceptar');

        $mdDialog
            .show(alert)
            .finally(function () {
                $location.url("contratos"); //redirijo a la pagina de contratos
            });
    }

    $scope.consumoCero = function () {
        if ($scope.consumoAnual == 0) {
            return false;
        } else {
            return true;
        }
    };

    $scope.showAlertError = function (ev) {
        $mdDialog.show(
            $mdDialog.alert()
                .clickOutsideToClose(false)
                .title('')
                .htmlContent('<i class="fa fa-times-circle" aria-hidden="true"></i> <p class="centrado">El Codigo CUPS ya se encuentra en el programa. </p>')
                .ariaLabel('Error')
                .ok('Aceptar')
        );
    };

    $scope.showAlertIncidencias = function (incidencias) {
        var string = "";
        angular.forEach(incidencias, function (value) {
            string = string + "<p>" + value.Mensaje + "</p>";
        });

        $mdDialog.show(
            $mdDialog
                .alert()
                .clickOutsideToClose(false)
                .title('')
                .htmlContent('<i class="fa fa-times-circle" aria-hidden="true"></i> ' + string)
                .ariaLabel('Error')
                .ok('Aceptar')
        );
    };

    //$scope.EmailCertificadoChecked = EmailCertificadoChecked;
    var checkIsRazonSocialValido = false;
    $scope.comprobarRepresentante = function () {
        if (true === checkIsRazonSocialValido || $scope.MustComprobarRepresentanteAux) {
            $scope.MustComprobarRepresentante = true;
        } else {
            $scope.MustComprobarRepresentante = false;
        }
    };

    $scope.AvisosCertificados = AvisosCertificados;
    $scope.EmailCertificadoChecked = EmailCertificadoChecked;
    $scope.SMSCertificadoChecked = SMSCertificadoChecked;

    $scope.cambiarEstado = function (checkbox) {
        var estadoEmail = $scope.EmailCertificadoChecked;
        var estadoSms = $scope.SMSCertificadoChecked;

        if (estadoEmail == true && checkbox == 'sms') {
            $scope.EmailCertificadoChecked = false;
            $scope.SMSCertificadoChecked = true;

        } else if (estadoSms == true && checkbox == 'email') {
            $scope.EmailCertificadoChecked = true;
            $scope.SMSCertificadoChecked = false;
        }

        if (!$scope.EmailCertificadoChecked) {
            $scope.requiredContratoEmail = false;
            document.getElementById("RequiredEmail").innerHTML = "Email";

        } else {
            $scope.requiredContratoEmail = true;
            document.getElementById("RequiredEmail").innerHTML = "Email *";
        }

        if (!$scope.SMSCertificadoChecked) {
            $scope.requiredContratoTelefono = false;
            document.getElementById("RequiredTelefono").innerHTML = "Telefono";
        } else {
            $scope.requiredContratoTelefono = true;
            document.getElementById("RequiredTelefono").innerHTML = "Telefono *";
        }

    };

    $scope.cambiarEstadoBono = function (checkbox, bono) {
        if (checkbox.Bono10Checked == true && bono == "11") {
            checkbox.Bono10Checked = false;
            checkbox.Bono11Checked = true;
            bonoCheckeado = "10";
        } else if (checkbox.Bono11Checked == true && bono == "10") {
            checkbox.Bono10Checked = true;
            checkbox.Bono11Checked = false;
            bonoCheckeado = "11";
        } else if (checkbox.Bono11Checked == false && checkbox.Bono11Checked == false) {
            bonoCheckeado = "";
        } else {
            if (bono == 10) {
                bonoCheckeado = "10";
            } else {
                bonoCheckeado = "11";
            }
        }
    };

    var CheckSistemaComercial = false;
    var CheckCamapanaPublicidad = false;
    var CheckUsoTerceros = false;

	$scope.restaCustomizadaEleia = function() {
		if(restaCargosComparadorEleia && !$scope.VerGas){
			console.log($scope.arrayPreciosPotenciaEnergia);
			console.log(JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue).TextoTarifa);
			console.log($scope.arrayPreciosPotenciaEnergia[0].PrecioP + " -  0.018962");
			if (JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue).TextoTarifa == '2.0TD ML'){
				$scope.arrayPreciosPotenciaEnergia[0].PrecioP < 0.018962? $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioP = ($scope.arrayPreciosPotenciaEnergia[0].PrecioP - 0.018962).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioP < 0.001220? $scope.arrayPreciosPotenciaEnergia[2].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioP = ($scope.arrayPreciosPotenciaEnergia[2].PrecioP - 0.001220).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[0].PrecioE < 0.101607? $scope.arrayPreciosPotenciaEnergia[0].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioE = ($scope.arrayPreciosPotenciaEnergia[0].PrecioE - 0.101607).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioE < 0.020321? $scope.arrayPreciosPotenciaEnergia[1].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioE = ($scope.arrayPreciosPotenciaEnergia[1].PrecioE - 0.020321).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioE < 0.005080? $scope.arrayPreciosPotenciaEnergia[2].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioE = ($scope.arrayPreciosPotenciaEnergia[2].PrecioE - 0.005080).toFixed(8);
			} else if (JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue).TextoTarifa == '3.0TD ML') {
				$scope.arrayPreciosPotenciaEnergia[0].PrecioP < 0.023562? $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioP = ($scope.arrayPreciosPotenciaEnergia[0].PrecioP - 0.023562).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioP < 0.011791? $scope.arrayPreciosPotenciaEnergia[1].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioP = ($scope.arrayPreciosPotenciaEnergia[1].PrecioP - 0.011791).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioP < 0.008567? $scope.arrayPreciosPotenciaEnergia[2].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioP = ($scope.arrayPreciosPotenciaEnergia[2].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioP < 0.008567? $scope.arrayPreciosPotenciaEnergia[3].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioP = ($scope.arrayPreciosPotenciaEnergia[3].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioP < 0.008567? $scope.arrayPreciosPotenciaEnergia[4].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioP = ($scope.arrayPreciosPotenciaEnergia[4].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioP < 0.003927? $scope.arrayPreciosPotenciaEnergia[5].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioP = ($scope.arrayPreciosPotenciaEnergia[5].PrecioP - 0.003927).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[0].PrecioE < 0.056643? $scope.arrayPreciosPotenciaEnergia[0].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioE = ($scope.arrayPreciosPotenciaEnergia[0].PrecioE - 0.056643).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioE < 0.041940? $scope.arrayPreciosPotenciaEnergia[1].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioE = ($scope.arrayPreciosPotenciaEnergia[1].PrecioE - 0.041940).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioE < 0.022657? $scope.arrayPreciosPotenciaEnergia[2].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioE = ($scope.arrayPreciosPotenciaEnergia[2].PrecioE - 0.022657).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioE < 0.011328? $scope.arrayPreciosPotenciaEnergia[3].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioE = ($scope.arrayPreciosPotenciaEnergia[3].PrecioE - 0.011328).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioE < 0.007262? $scope.arrayPreciosPotenciaEnergia[4].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioE = ($scope.arrayPreciosPotenciaEnergia[4].PrecioE - 0.007262).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioE < 0.004532? $scope.arrayPreciosPotenciaEnergia[5].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioE = ($scope.arrayPreciosPotenciaEnergia[5].PrecioE - 0.004532).toFixed(8);
			} else if(JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue).TextoTarifa == '6.1TD ML') {
				$scope.arrayPreciosPotenciaEnergia[0].PrecioP < 0.024459? $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioP = ($scope.arrayPreciosPotenciaEnergia[0].PrecioP - 0.024459).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioP < 0.012240? $scope.arrayPreciosPotenciaEnergia[1].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioP = ($scope.arrayPreciosPotenciaEnergia[1].PrecioP - 0.012240).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioP < 0.008894? $scope.arrayPreciosPotenciaEnergia[2].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioP = ($scope.arrayPreciosPotenciaEnergia[2].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioP < 0.008894? $scope.arrayPreciosPotenciaEnergia[3].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioP = ($scope.arrayPreciosPotenciaEnergia[3].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioP < 0.008894? $scope.arrayPreciosPotenciaEnergia[4].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioP = ($scope.arrayPreciosPotenciaEnergia[4].PrecioP - 0.008894).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioP < 0.004076? $scope.arrayPreciosPotenciaEnergia[5].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioP = ($scope.arrayPreciosPotenciaEnergia[5].PrecioP - 0.004076).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[0].PrecioE < 0.030800? $scope.arrayPreciosPotenciaEnergia[0].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioE = ($scope.arrayPreciosPotenciaEnergia[0].PrecioE - 0.030800).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioE < 0.022815? $scope.arrayPreciosPotenciaEnergia[1].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioE = ($scope.arrayPreciosPotenciaEnergia[1].PrecioE - 0.022815).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioE < 0.012320? $scope.arrayPreciosPotenciaEnergia[2].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioE = ($scope.arrayPreciosPotenciaEnergia[2].PrecioE - 0.012320).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioE < 0.006160? $scope.arrayPreciosPotenciaEnergia[3].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioE = ($scope.arrayPreciosPotenciaEnergia[3].PrecioE - 0.006160).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioE < 0.003948? $scope.arrayPreciosPotenciaEnergia[4].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioE = ($scope.arrayPreciosPotenciaEnergia[4].PrecioE - 0.003948).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioE < 0.002464? $scope.arrayPreciosPotenciaEnergia[5].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioE = ($scope.arrayPreciosPotenciaEnergia[5].PrecioE - 0.002464).toFixed(8);
			}
		}
	}
    $scope.cambiarEstadoLOPD = function (checkbox) {

        CheckSistemaComercial = checkbox.LOPD1Checked;
        CheckCamapanaPublicidad = checkbox.LOPD2Checked;
        CheckUsoTerceros = checkbox.LOPD3Checked;

        if (CheckSistemaComercial == undefined) {
            CheckSistemaComercial = false;
        }
        if (CheckCamapanaPublicidad == undefined) {
            CheckCamapanaPublicidad = false;
        }
        if (CheckUsoTerceros == undefined) {
            CheckUsoTerceros = false;
        }

    };

	$scope.addNewChoiceB70 = function() {
		if($scope.arraygrupoProd.length ==  $scope.itemsB70.length &&
			$scope.ProdsB70.length ==  $scope.itemsB70.length &&
			$scope.arraycodigoCNMC.length ==  $scope.itemsB70.length &&
			$scope.arrayplazo.length ==  $scope.itemsB70.length)
		{
			var avanzar = true;
			$scope.itemsB70.forEach(function (item, index) {
				if ($scope.arraygrupoProd[item] != null &&
					$scope.ProdsB70[item] != null &&
					$scope.arraycodigoCNMC[item] != '' &&
					$scope.arrayplazo[item] != null){

					} else {
						avanzar=false;
					}
			});
			if (avanzar){
				$scope.itemsB70.push($scope.itemsB70.length);
			} else {
				$mdDialog.show(
					$mdDialog.alert()
						.clickOutsideToClose(false)
						.textContent('Rellena todos los productos')
						.ariaLabel('No se añadió nueva linea').ok('Aceptar')
				);
			}
		} else {
			$mdDialog.show(
				$mdDialog.alert()
					.clickOutsideToClose(false)
					.textContent('Rellena todo el producto actual')
					.ariaLabel('No se añadió nueva linea').ok('Aceptar')
			);
		}
	  };

	  $scope.removeNewChoiceB70 = function() {
		var newItemNo = $scope.itemsB70.length-1;
		if ( newItemNo !== 0 ) {
			$scope.itemsB70.pop();
			if($scope.ProdsB70.length > newItemNo)$scope.ProdsB70.pop();
			if($scope.arraycodigoCNMC.length > newItemNo)$scope.arraycodigoCNMC.pop();
			if($scope.arrayplazo.length > newItemNo)$scope.arrayplazo.pop();
			if($scope.arraygrupoProd.length > newItemNo)$scope.arraygrupoProd.pop();
			if($scope.arrayProdB70.length > newItemNo)$scope.arrayProdB70.pop();

		}
	  };
    $scope.cambiarFraccionamientoB70 = function (checkbox) {
		$scope.itemsB70 = [];
		$scope.arraygrupoProd = [];
		$scope.arrayProdB70 = [];
		$scope.ProdsB70 = [];
		$scope.arraycodigoCNMC = [];
		$scope.arrayplazo = [];
        $scope.controlcheckB70 = checkbox.checkB70;
		if($scope.controlcheckB70){
			$scope.itemsB70.push(0);
		}
    };
    $scope.updateGrupoProductob70 = function (row) {
		// console.log($scope.arraygrupoProd[row.rowB70]);
		$scope.ProdsB70[row.rowB70] = [];
		$http({
            method: "GET",
            url: urlApi + "Producto/GetAllProducto/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/IdProductoGrupo/" + $scope.arraygrupoProd[row.rowB70],
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function (response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]

            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.ProdsB70[row.rowB70] = playloadJson;
				//CAPADO DE OPCIONES MET
				$scope.ProdsB70[row.rowB70] = [];
				playloadJson.forEach(function (item, index) {
                    if(item.IdProducto == 103 || item.IdProducto == 147){
						$scope.ProdsB70[row.rowB70].push(item);
					}
                })
            } else {
                $scope.ProdsB70[row.rowB70] = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }
        });
    };
    $scope.cambiarEstadoPasaporte = function (checkbox) {
        if (checkbox.CheckPasaporte == false || checkbox.CheckPasaporte == undefined) {
            checkbox.CheckPasaporte = true;
        } else {
            checkbox.CheckPasaporte = false;
        }
        checkPasaporte = checkbox.CheckPasaporte;
        document.getElementById("checkPasaporte").checked = checkPasaporte;
        return checkPasaporte;
    };

    if ($rootScope.AltaLead && $rootScope.AltaLead != undefined) {
        angular.element(document).ready(function () {
            var lead = $rootScope.AltaLead;
            localStorage.setItem('idLead', lead.Id);

            var evt = new CustomEvent('change');
            var altaContratoForm = $scope.altaContratoForm;
            var calleSuministro = document.getElementsByName("Calle")[0];
            calleSuministro.value = lead.DireccionSuministro;
            calleSuministro.dispatchEvent(evt);

            var identificador = document.getElementsByName("Identificador")[0];
            identificador.value = lead.Dni;
            identificador.dispatchEvent(evt);
            $scope.compruebaIdentificador(lead.Dni);
            altaContratoForm.Identificador.$setValidity('required', true);

            var aclaradorTitular = document.getElementsByName("AclaradorTitular")[0];
            aclaradorTitular.value = lead.AclaradorVia;
            aclaradorTitular.dispatchEvent(evt);

            var numeroTitular = document.getElementsByName("NumeroTitular")[0];
            numeroTitular.value = lead.NumeroVia;
            numeroTitular.dispatchEvent(evt);

            var codPostalTitular = document.getElementsByName("CodPostalTitular")[0];
            codPostalTitular.value = lead.CodPostal;
            codPostalTitular.dispatchEvent(evt);

            var cups = document.getElementsByName("Cups")[0];
            cups.value = lead.Cups;
            cups.dispatchEvent(evt);

            var email = document.getElementsByName("EmailTitular")[0];
            email.value = lead.Email;
            email.dispatchEvent(evt);

            var nombre = document.getElementsByName("NombreTitular")[0];
            nombre.value = lead.Nombre;
            nombre.dispatchEvent(evt);

            document.getElementById("NombreTitular").disabled = false;
            altaContratoForm.NombreTitular.$setValidity('required', true);

            var apellidos = lead.Apellidos.split(" ");

            var apellido1 = document.getElementsByName("Apellido1Titular")[0];
            if (apellidos[0] !== undefined) {
                apellido1.value = apellidos[0];
            } else {
                apellido1.value = "";
            }
            apellido1.dispatchEvent(evt);

            var apellido2 = document.getElementsByName("Apellido2Titular")[0];
            if (apellidos[1] !== undefined) {
                apellido2.value = apellidos[1];
            } else {
                apellido2.value = "";
            }
            apellido2.dispatchEvent(evt);

            var provincia = document.getElementsByName("ProvinciaTitular")[0];
            provincia.value = lead.Provincia;
            provincia.dispatchEvent(evt);

            var ciudad = document.getElementsByName("CiudadTitular")[0];
            ciudad.value = lead.Poblacion;
            ciudad.dispatchEvent(evt);

            var telefono = document.getElementsByName("TelefonoTitular")[0];
            telefono.value = lead.Telefono;
            telefono.dispatchEvent(evt);

            var calleTitular = document.getElementsByName("CalleTitular")[0];
            calleTitular.value = lead.NombreVia;
            calleTitular.dispatchEvent(evt);

            var tarifa = document.getElementsByName("TarifaAccesoElectricidad")[0];
            tarifa.value = lead.TarifaAcceso + " ML";
            tarifa.dispatchEvent(evt);

            var potenciaP1 = document.getElementsByName("PotenciaP1")[0];
            potenciaP1.value = lead.P1;
            potenciaP1.dispatchEvent(evt);

            var potenciaP2 = document.getElementsByName("PotenciaP2")[0];
            potenciaP2.value = lead.P2;
            potenciaP2.dispatchEvent(evt);

			var potenciaP3 = document.getElementsByName("PotenciaP3")[0];
            potenciaP3.value = lead.P3;
            potenciaP3.dispatchEvent(evt);

			var potenciaP4 = document.getElementsByName("PotenciaP4")[0];
            potenciaP4.value = lead.P4;
            potenciaP4.dispatchEvent(evt);

			var potenciaP5 = document.getElementsByName("PotenciaP5")[0];
            potenciaP5.value = lead.P5;
            potenciaP5.dispatchEvent(evt);

			var potenciaP6 = document.getElementsByName("PotenciaP6")[0];
            potenciaP6.value = lead.P6;
            potenciaP6.dispatchEvent(evt);

            var tarjetaDisa = document.getElementsByName("TarjetaClubDisa")[0];
            tarjetaDisa.value = lead.TarjetaDisa;
            tarjetaDisa.dispatchEvent(evt);

            if (lead.Bono == "Gas") {
                $scope.Bono10Checked = true;
                segmentacion = "DISABONO1";
            } else if (lead.Bono == "Combustible") {
                $scope.Bono11Checked = true;
                segmentacion = "DISABONO2";
            }

            if ((lead.TarifaAcceso != undefined || lead.TarifaAcceso != "") && lead.TarifaAcceso != "2.0DHS") {
                if (lead.TarifaAcceso.indexOf(" ML") !== -1) {
                    $scope.Tarifa = lead.TarifaAcceso;
                } else {
                    $scope.Tarifa = lead.TarifaAcceso + " ML";
                }
            } else if (lead.TarifaAcceso != undefined || lead.TarifaAcceso != "") {
                $scope.Tarifa = lead.TarifaAcceso;
            }

            if (ParametrosAltaContratoDefecto == true) {
                if (lead.NombreTarifa == "Tarifa Alisios") {
                    $scope.GrupoTarifa = GrupoTarifaDefecto;
                } else {
                    $scope.GrupoTarifa = lead.NombreTarifa;
                }

                $scope.PeriodoTarifa = PeriodoTarifaDefecto;
                $scope.TipoImpresionTarifa = TipoImpresionTarifaDefecto;
                $scope.CNAE = CNAEDefecto;
                $scope.TipoSolicitudTarifa = TipoSolicitudTarifaDefecto;
            }

            // if (lead.Iban) {
            //     $scope.TipoCobro = "DOMICILIADO";

            //     var tipoCobro = document.getElementsByName("TextoTipoCobro")[0];
            //     tipoCobro.dispatchEvent(evt);

            //     $scope.updateTipoCobro($scope.TipoCobro);

            //     var iban = document.getElementsByName("IBANPagador")[0];
            //     iban.value = lead.Iban;
            //     iban.dispatchEvent(evt);
            //     altaContratoForm.IBANPagador.$setValidity('required', true);
            // } else {
            //     $scope.TipoCobro = "NO DOMICILIADO";
            // }

            $rootScope.AltaLead = undefined;

            $scope.duplicarCamposTitular();
        });
    }

    /* Valores a mostrar */
    $scope.MostrarContratoAltaPerfilFacturacion = MostrarContratoAltaPerfilFacturacion;


    $scope.MostrarContratoAltaDomestico = MostrarContratoAltaDomestico;


    /* Valores requeridos */
    $scope.requiredGeneralCUPS = requiredGeneralCUPS;
    $scope.requiredGeneralCodDistribuidora = requiredGeneralCodDistribuidora;
    $scope.requiredGeneralCodComercializadora = requiredGeneralCodComercializadora;
    $scope.requiredGeneralCiudad = requiredGeneralCiudad;
    $scope.requiredGeneralCalle = requiredGeneralCalle;
    $scope.requiredGeneralNumero = requiredGeneralNumero;
    $scope.requiredGeneralAclarador = requiredGeneralAclarador;
    $scope.requiredGeneralCodPostal = requiredGeneralCodPostal;
    $scope.requiredGeneralProvincia = requiredGeneralProvincia;

    $scope.requiredPotenciasTarifaAcceso = requiredPotenciasTarifaAcceso;
    $scope.requiredPotenciasGrupoTarifa = requiredPotenciasGrupoTarifa;
    $scope.requiredPotenciasPerfilFacturacion = requiredPotenciasPerfilFacturacion;
    $scope.requiredCaudalContrato = requiredCaudalContrato;

    $scope.requiredServiciosGrupoProducto = requiredServiciosGrupoProducto;
    $scope.requiredServiciosProducto = requiredServiciosProducto;

    $scope.requiredContratoIdentificador = requiredContratoIdentificador;
    $scope.requiredContratoCodigoPostal = requiredContratoCodigoPostal;
    $scope.requiredContratoCiudad = requiredContratoCiudad;
    $scope.requiredContratoCalle = requiredContratoCalle;
    $scope.requiredContratoNumero = requiredContratoNumero;
    $scope.requiredContratoAclarador = requiredContratoAclarador;
    $scope.requiredContratoProvincia = requiredContratoProvincia;
    $scope.requiredContratoCategoriaCliente = requiredContratoCategoriaCliente;

    $scope.requiredDatosEnvioNombre = requiredDatosEnvioNombre;
    $scope.requiredDatosEnvioCodPostal = requiredDatosEnvioCodPostal;
    $scope.requiredDatosEnvioCiudad = requiredDatosEnvioCiudad;
    $scope.requiredDatosEnvioCalle = requiredDatosEnvioCalle;
    $scope.requiredDatosEnvioNumero = requiredDatosEnvioNumero;
    $scope.requiredDatosEnvioAclarador = requiredDatosEnvioAclarador;
    $scope.requiredDatosEnvioProvincia = requiredDatosEnvioProvincia;

    $scope.requiredOtrosNombrePagador = requiredOtrosNombrePagador;
    $scope.requiredOtrosIdentificador = requiredOtrosIdentificador;
    $scope.requiredOtrosTipoCobro = requiredOtrosTipoCobro;
    $scope.requiredOtrosIBAN = requiredOtrosIBAN;
    $scope.requiredOtrosCNAE = requiredOtrosCNAE;
    $scope.requiredOtrosPeriodo = requiredOtrosPeriodo;
    $scope.requiredOtrosTipoImpresion = requiredOtrosTipoImpresion;
    $scope.requiredOtrosTipoSolicitud = requiredOtrosTipoSolicitud;
    $scope.requiredOtrosModeloContrato = requiredOtrosModeloContrato;
    $scope.requiredOtrosIdiomaContrato = requiredOtrosIdiomaContrato;
    $scope.requiredOtrosCodigoVendedor = requiredOtrosCodigoVendedor;
    $scope.requiredOtrosFechaEfecto = requiredOtrosFechaEfecto;
    $scope.requiredOtrosActivacionPrevista = requiredOtrosActivacionPrevista;

    $scope.ibanMaxlength = 24;
    $scope.ibanMinlength = 24;

    $scope.MostrarTarjetaClubDisa = MostrarTarjetaClubDisa;
    $scope.MostrarBonosDisa = MostrarBonosDisa;
    $scope.MostrarFechaEfecto = MostrarFechaEfecto;
    $scope.MostrarActivacionPrevista = MostrarActivacionPrevista;
    $scope.MostrarLOPD = MostrarLOPD;

    $scope.MostrarSegmento = MostrarContratoSegmento;
    $scope.MostrarTipoCliente = MostrarContratoTipoCliente;
    $scope.MostrarCanalOrigen = MostrarContratoCanalOrigen;
    $scope.MostrarSubcanalOrigen = MostrarContratoSubcanalOrigen;
    $scope.MostrarCodigoPromocional = MostrarContratoCodigoPromocional;

    $scope.MostrarDatosIdentificadorDeAgente = MostrarDatosIdentificadorDeAgente;

    // var costeGestion = 0
    $scope.costeGestion = "";
    $scope.AvisoImpresionPapel = false;

    if (atob(localStorage.getItem('tipoAlta')) == 'e') {
        $scope.VerGas = false;
        $scope.VerElectricidad = true;
    }

    if (atob(localStorage.getItem('tipoAlta')) == 'g') {
        $scope.VerGas = true;
        $scope.VerElectricidad = false;
    }

    $http({
        method: "GET",
        url: urlApi + "GrupoProducto/GetAllProductoGrupo/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);
        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {

            $scope.GrupoProductos = playloadJson;

			if($scope.DesarrolloFraccionamientoB70) {
				for(var itemFraccionamiento in playloadJson){
					if(playloadJson[itemFraccionamiento]['IdProductoGrupo'] == 4){
						$scope.GrupoProductos = [];
						$scope.GrupoProductos.push(playloadJson[itemFraccionamiento]);
					}
				}
			}

            if($scope.DesarrolloFraccionamientoB70Met){
                for (var grupoProducto in $scope.GrupoProductos){
                    $scope.arrayProductosRelaccionados = [];
                    // console.log($scope.GrupoProductos[grupoProducto].IdProductoGrupo);
                    // console.log($scope.GrupoProductos);
                    $http({
                        method: "GET",
                        url: urlApi + "Producto/GetAllProducto/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/IdProductoGrupo/" + $scope.GrupoProductos[grupoProducto].IdProductoGrupo,
                        headers: {
                            Authorization: atob(localStorage.getItem('TokenValidador'))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);
                        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                        if (tokenValido && !tokenExpirado) {

                            for (var producto in playloadJson){
                                if(playloadJson[producto].AntesIE == true){ //antes estaba hecho mirando si el id era 100 o 140, unicamente 100 y 140 emplean antesIE = true
                                    $scope.arrayProductosRelaccionados.push({"TextoGrupoProducto" : playloadJson[producto].TextoGrupoProducto , "TextoProducto" : playloadJson[producto].TextoProducto, "CodigoComunicacion" : playloadJson[producto].CodigoComunicacion , "IdProducto" :playloadJson[producto].IdProducto  });
                                }
                            }

                                // console.log($scope.arrayProductosRelaccionados);
                        }

                    })
                }
            }


        } else {
            $scope.GrupoProductos = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }

    });

    $http({
        method: "GET",
        url: urlApi + "TipoCobro/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }

    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.TiposCobro = playloadJson;
        } else {
            $scope.TiposCobro = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }

    });

    $http({
        method: "GET",
        url: urlApi + "Tarifa/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }

    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.TarifasAcceso = playloadJson;
        } else {
            $scope.TarifasAcceso = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });
	//Cargar Tipos de alquiler si MostrarContratoTipoAlquiler
	if(MostrarContratoTipoAlquiler){
		$http({
			method: "GET",
			url: urlApi + "Producto/GetTipoAlquiler",
			headers: {
				Authorization: atob(localStorage.getItem('TokenValidador'))
			}

		}).then(function successCallback(response3) {
			var respuesta3 = response3.data;
			var decodeToken3 = jwtHelper.decodeToken(respuesta3);
			var playloadJson3 = JSON.parse(decodeToken3.iss);

			var tokenValido3 = KJUR.jws.JWS.verify(respuesta3, tokenKey, {
				alg: ["HS256"]
			});

			// var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
			var tokenExpirado3 = jwtHelper.isTokenExpired(respuesta3); //sabemos si esta expirado o no

			if (tokenValido3 && !tokenExpirado3) {
				$scope.TiposAlquileres = playloadJson3.ListaTipoAlquiler;
			}
		});
	}
    $http({
        method: "GET",
        url: urlApi + "PeriodoFactura/GetDTOAllPeriodos/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Linea/" + (($scope.VerElectricidad) ? "1" : "2"),
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }

    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.PerfilesFacturacion = playloadJson;
            if ($scope.SimplificacionesEnergiaGalega) {
                var auxPerfilesFacturacion = [];
                for (var index = 0; index < $scope.PerfilesFacturacion.length; index++) {
                    const element = $scope.PerfilesFacturacion[index];
                    // if('ML Factura BT <=15 Kw' == element['TextoPerfilFacturacion'] || 'ML Factura BT >15' == element['TextoPerfilFacturacion']) {
                    // 	auxPerfilesFacturacion.push(element);
                    // }
                }
                if (auxPerfilesFacturacion.length > 0) {
                    $scope.PerfilesFacturacion = auxPerfilesFacturacion;
                }
            }
        } else {
            $scope.PerfilesFacturacion = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }

    });

    $http({
        method: "GET",
        url: urlApi + "Cliente/GetDTOAllCategoria/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }

    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.CategoriasCliente = playloadJson;
        } else {
            $scope.CategoriasCliente = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }

    });

    $http({
        method: "GET",
        url: urlApi + "Empresa/GetAllCNAE/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.TipoCNAE = playloadJson;
        } else {
            $scope.TipoCNAE = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $http({
        method: "GET",
        url: urlApi + "SolicitudTipo/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.TiposSolicitud = playloadJson;
            if ($scope.SimplificacionesEnergiaGalega) {
                var auxTiposSolicitud = [];
                for (var index = 0; index < $scope.TiposSolicitud.length; index++) {
                    const element = $scope.TiposSolicitud[index];
                    if ('C1' == element['NombreSolicitudTipo']) {
                        auxTiposSolicitud.push(element);
                    }
                }
                if (auxTiposSolicitud.length > 0) {
                    $scope.TiposSolicitud = auxTiposSolicitud;
                }
            }
        } else {
            $scope.TiposSolicitud = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }

        for (var i = 0; i < playloadJson.length; i++) {
            if (playloadJson[i].OperacionAsociada == null) {
                playloadJson[i].OperacionAsociada = "Nulo" + Math.random();
            }
        }
    });

    /**
     * Llamada Colectivos
     */
    if ($scope.MostrarAltaDatosRepresentante) {
        $http({
            method: "POST",
            url: urlApi + "Contrato/GetAllColectivos",
            data: JSON.stringify({ "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            if (tokenValido) {
                $scope.ColectivosRepresentante = playloadJson;
                if ($scope.RepresentanteColectivoSoloPersonaFisica == true) {
                    var aux = [];
                    for (var i = 0; i < playloadJson.length; i++) {
                        if (playloadJson[i].TextoColectivo == 'PERSONAS FISICAS') {
                            aux.push(playloadJson[i]);
                        }
                    }
                    $scope.ColectivosRepresentante = aux;
                    $scope.ValorDesplegableColectivo = 'PERSONAS FISICAS';
                }
            } else {
                $scope.ColectivosRepresentante = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }
        });
    }

    /**
     * Llamada Destinos energía
     */
    if ($scope.VerGas || nombreEmpresa === "Disa") {
        $http({
            method: "POST",
            url: urlApi + "Contrato/GetAllDestinosEnergia",
            data: JSON.stringify({ "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            if (tokenValido) {
                $scope.DestinosEnergia = playloadJson;
            } else {
                $scope.DestinosEnergia = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }
        });
        $scope.FechaCapDesde = moment().toDate();
        $scope.FechaCapHasta = moment().add(1, 'y').toDate();
        $scope.changeFechaCapDesde = function (date) {
            $scope.FechaCapDesde = moment(date).toDate();
            $scope.FechaCapHasta = moment(date).add(1, 'y').toDate();
        }
    }

    if ($scope.VerGas || nombreEmpresa === "Disa") {
        $http({
            method: "POST",
            url: urlApi + "Contrato/GetAllClienteCategoria",
            data: JSON.stringify({ "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            if (tokenValido) {
                $scope.ClienteCategoria = playloadJson;
            } else {
                $scope.ClienteCategoria = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }
        });
        $scope.FechaCapDesde = moment().toDate();
        $scope.FechaCapHasta = moment().add(1, 'y').toDate();
        $scope.changeFechaCapDesde = function (date) {
            $scope.FechaCapDesde = moment(date).toDate();
            $scope.FechaCapHasta = moment(date).add(1, 'y').toDate();
        }
    }

    /**
     * Llamada Tipos capacidad
     */
    $http({
        method: "POST",
        url: urlApi + "Contrato/GetAllTiposCapacidad",
        data: JSON.stringify({ "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario }),
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        if (tokenValido) {
            $scope.TiposCapacidad = playloadJson;
        } else {
            $scope.TiposCapacidad = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $http({
        method: "GET",
        url: urlApi + "PeriodoFactura/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.PeriodosTarifa = playloadJson;
            if ($scope.SimplificacionesEnergiaGalega) {
                var auxPeriodosTarifa = [];
                for (var index = 0; index < $scope.PeriodosTarifa.length; index++) {
                    const element = $scope.PeriodosTarifa[index];
                    if ('MENSUAL' == element['TextoPeriodo']) {
                        auxPeriodosTarifa.push(element);
                    }
                }
                if (auxPeriodosTarifa.length > 0) {
                    $scope.PeriodosTarifa = auxPeriodosTarifa;
                }
            }
        } else {
            $scope.PeriodosTarifa = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $http({
        method: "GET",
        url: urlApi + "Agente/ListadoAgentes/" + localStorage.getItem('idAgente') + "/Empresa/" + localStorage.getItem('idEmpresa') + "/Usuario/" + localStorage.getItem('idUsuario'),
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }
    }).then(function successCallback(response) {

        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.CodigosVendedores = playloadJson;
        } else {
            $scope.CodigosVendedores = "";
        }

        var original = {
            "count": playloadJson.length,
            "data": playloadJson
        };

    });


    /**
     * Llamada Modelos contrato
     */
    var URLModeloContrato = "Contrato/GetDTOAllModelosContrato/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente;
    if (LlamadaModeloContratoSinAgente) {
        URLModeloContrato = "Contrato/GetDTOAllModelosContrato/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario;
    }

    $http({
        method: "GET",
        url: urlApi + URLModeloContrato,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {

            $scope.ModelosContrato = playloadJson;
            if ($scope.CampoModeloContratoEditable) {
                playloadJson.forEach(function (item, index) {
                    if ($scope.VerElectricidad && item.DescripcionModeloDeImpresion == $scope.ValorDesplegableModeloContrato) {
                        $scope.ModelosContrato = [];
                        $scope.ModelosContrato.push(item);
                    } else if ($scope.VerGas && item.DescripcionModeloDeImpresion == $scope.ValorDesplegableModeloContratoGas) {
                        $scope.ModelosContrato = [];
                        $scope.ModelosContrato.push(item);
                    }
                })
            }
            $scope.ModelosContratoTemporal = playloadJson;
            if ($scope.SimplificacionesEnergiaGalega) {
                var auxModelosContrato = [];
                for (var index = 0; index < $scope.ModelosContrato.length; index++) {
                    const element = $scope.ModelosContrato[index];
                    if ('Contrato General' == element['DescripcionModeloDeImpresion']) {
                        auxModelosContrato.push(element);
                    }
                }
                if (auxModelosContrato.length > 0) {
                    $scope.ModelosContrato = auxModelosContrato;
                }
            }

        } else {
            $scope.ModelosContrato = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $http({
        method: "GET",
        url: urlApi + "FechaEfecto/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: atob(localStorage.getItem("TokenValidador"))
        }
    }).then(function successCallback(response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            var arrayFechaEfectoAux = [];
            playloadJson.forEach(function (item, index) {

                if ($scope.MostrarAltaContratoFechaEfectoSolo2017) {
                    if (item.TextoFechaEfecto.includes("(2017)")) {
                        item.TextoFechaEfecto = item.TextoFechaEfecto.replace("(2017)", "");
                        arrayFechaEfectoAux.push(item);
                    }

                } else {
                    ($scope.MostrarAltaContratoFechaEfecto2017) ? arrayFechaEfectoAux.push(item) : (!item.TextoFechaEfecto.includes("2017")) ? arrayFechaEfectoAux.push(item) : null;
                    (arrayFechaEfectoAux[index] != undefined) ? arrayFechaEfectoAux[index].TextoFechaEfecto = arrayFechaEfectoAux[index].TextoFechaEfecto.replace("(2017)", "") : null;
                }

            });
            $scope.FechasEfecto = arrayFechaEfectoAux;
            // console.log(playloadJson);
        } else {
            $scope.FechasEfecto = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $scope.fileNameChanged = function (ele) {
        var files = ele.files;
        var l = files.length;
        var namesArr = [];

        for (var i = 0; i < l; i++) {
            namesArr.push(files[i].name);
            var texto = document.getElementById("textoDocumentacion");
            texto.setAttribute("value", files[0].name);
        }
    };

    $scope.limpiar = function () {
        document.getElementById('textoDocumentacion').setAttribute('value', '');
    };



    // TODO: Meter aquí todo el json de ciudad y calle
    /**
     * Modelos para copiar correctamente los campos
     */
    $scope.DataCups = {
        Cups: '',
        Distribuidora: '',
        Comercializadora: '',
        CiudadBuscador: '',
        NombreCiudadCups: '',
        Provincia: '',
        NombreCalleCups: '',
        Numero: '',
        Aclarador: '',
        CodPostal: ''
    }

    $scope.DataTitular = {
        Identificador: '',
        NombreTitular: '',
        Apellido1Titular: '',
        Apellido2Titular: '',
        RazonSocialTitular: '',
        CiudadTitular: '',
        NombreCiudadTitular: '',
        ProvinciaTitular: '',
        NombreCalleTitular: '',
        Numero: '',
        AclaradorTitular: '',
        CodPostalTitular: '',
        TelefonoTitular: '',
        MovilTitular: '',
        EmailTitular: ''
    }

    $scope.DataEnvio = {
        NombreEnvio: '',
        CiudadEnvio: '',
        NombreCiudad: '',
        ProvinciaEnvio: '',
        NombreCalleEnvio: '',
        NumeroEnvio: '',
        AclaradorEnvio: '',
        CodPostalEnvio: ''
    }

    $scope.DataPagador = {
        NombrePagador: '',
        IdentificadorPagador: ''
    }

    // $scope.Potencias = {
    // 	PotenciaP1: '',
    // 	PotenciaP2: '',
    // 	PotenciaP3: '',
    // 	PotenciaP4: '',
    // 	PotenciaP5: '',
    // 	PotenciaP6: ''
    // }

    $scope.duplicarCamposCups = function () {

        /**
         * Cargo los valores de ciudad y calle en el Json original
         */

        $scope.DataCups.NombreCiudadCups = $scope.ciudadItem.desCiudad.desCiudad;

        /**
         * De momento, no se borran, faltará optimizarlo para mover todo en un solo json
         */
        $scope.ciudadTitularItem = $scope.ciudadItem;
        $scope.calleTitularItem = $scope.calleSuministro;

        /**
         * Duplico los campos de callejero suministro en callejero titular para la inserción;
         *
         */

        $scope.callejeroINETitular = angular.copy($scope.callejeroINESuministro);
        $scope.callejeroINEEnvio = angular.copy($scope.callejeroINESuministro);

        $scope.DataTitular.CiudadTitular = $scope.ciudadItem.desCiudad.desCiudad;
        $scope.DataTitular.NombreCiudadTitular = $scope.ciudadItem.desCiudad.desCiudad;
        $scope.DataTitular.ProvinciaTitular = angular.copy($scope.DataCups.Provincia);

        /**
         * Si la calle es inventada, no seleccionada del callejero, lo controlo y la copio en el json que hace falta
         */
        if ($scope.calleTitularItem == null) {
            $scope.calleTitularItem = {
                ciudad: null,
                codCalle: "",
                codCiudad: "",
                codMunicipio: "",
                codPais: "",
                codProvincia: "",
                codigoPostal: "",
                codigoProveedorCalle: "",
                desCalle: angular.copy($scope.altaContratoForm.Calle.$viewValue),
                desCalleInd: "",
                desCompleta: null,
                id: "",
                idMunicipio: "",
                isPseudoVia: false,
                tipoCalle: ""
            }
            $scope.DataTitular.NombreCalleTitular = $scope.calleTitularItem.desCalle;
        } else {
            $scope.DataTitular.NombreCalleTitular = $scope.calleTitularItem.desCalle;
        }

        $scope.DataTitular.Numero = angular.copy($scope.DataCups.Numero);
        $scope.DataTitular.AclaradorTitular = angular.copy($scope.DataCups.Aclarador);
        $scope.DataTitular.CodPostalTitular = angular.copy($scope.DataCups.CodPostal);

        /**
         * Inicializo los controles visuales en la pantalla
         */

        $scope.spinnerListadoCallesTitular = false;
        $scope.ListadoCallesTitularBloqueado = true;
        $scope.bloqueoCalleTitularCopiando = true;
    };

    $scope.duplicarCamposTitular = function () {

        /**
         * Duplico los datos de callejero de titular a envío
         */
        $scope.callejeroINEEnvio = angular.copy($scope.callejeroINETitular);

        $scope.ciudadEnvioItem = $scope.ciudadTitularItem;
        $scope.calleEnvioItem = $scope.calleTitularItem;

        $scope.spinnerListadoCallesEnvio = false;
        $scope.ListadoCallesEnvioBloqueado = true;

        if ($scope.DataTitular.NombreTitular == "" &&
            $scope.DataTitular.Apellido1Titular == "" &&
            $scope.DataTitular.Apellido2Titular == "" &&
            $scope.DataTitular.RazonSocialTitular == "") {
            // No se hace nada si todo es en blanco
        } else if (
            ($scope.DataTitular.NombreTitular == "" && $scope.DataTitular.Apellido1Titular == "" && $scope.DataTitular.Apellido2Titular == "") ||
            ($scope.DataTitular.NombreTitular == null && $scope.DataTitular.Apellido1Titular == null && $scope.DataTitular.Apellido2Titular == null)) {
            // Es razón social
            $scope.DataEnvio.NombreEnvio = $scope.DataTitular.RazonSocialTitular;
            $scope.DataPagador.NombrePagador = $scope.DataTitular.RazonSocialTitular;

        } else {
            // Es nombre
            $scope.DataEnvio.NombreEnvio = $scope.DataTitular.NombreTitular + ' ' + $scope.DataTitular.Apellido1Titular + ' ' + $scope.DataTitular.Apellido2Titular;
            $scope.DataPagador.NombrePagador = $scope.DataTitular.NombreTitular + ' ' + $scope.DataTitular.Apellido1Titular + ' ' + $scope.DataTitular.Apellido2Titular;
        }

        // $scope.DataEnvio.NombreEnvio = $scope.DataTitular.NombreTitular + ' ' +  $scope.DataTitular.Apellido1Titular + ' ' +  $scope.DataTitular.Apellido2Titular;
        $scope.DataEnvio.CiudadEnvio = angular.copy($scope.DataTitular.CiudadTitular);
        $scope.DataEnvio.NombreCiudad = angular.copy($scope.DataTitular.NombreCiudadTitular);
        $scope.DataEnvio.ProvinciaEnvio = angular.copy($scope.DataTitular.ProvinciaTitular);
        /**
         * Si la calle es inventada, no seleccionada del callejero, lo controlo y la copio en el json que hace falta
         */
        if ($scope.calleTitularItem.desCalle == null) {
            if ($scope.altaContratoForm.CalleTitular.$viewValue != "") {
                $scope.calleTitularItem.desCalle = {
                    ciudad: null,
                    codCalle: "",
                    codCiudad: "",
                    codMunicipio: "",
                    codPais: "",
                    codProvincia: "",
                    codigoPostal: "",
                    codigoProveedorCalle: "",
                    desCalle: angular.copy($scope.altaContratoForm.CalleTitular.$viewValue),
                    desCalleInd: "",
                    desCompleta: null,
                    id: "",
                    idMunicipio: "",
                    isPseudoVia: false,
                    tipoCalle: ""
                }
                $scope.DataEnvio.NombreCalleEnvio = $scope.calleTitularItem.desCalle.desCalle;
            }
        } else {
            $scope.DataEnvio.NombreCalleEnvio = $scope.calleTitularItem.desCalle.desCalle;
        }
        // $scope.DataEnvio.NombreCalleEnvio = $scope.calleTitularItem.desCalle.desCalle;
        $scope.DataEnvio.NumeroEnvio = angular.copy($scope.DataTitular.Numero);
        $scope.DataEnvio.AclaradorEnvio = angular.copy($scope.DataTitular.AclaradorTitular);
        $scope.DataEnvio.CodPostalEnvio = angular.copy($scope.DataTitular.CodPostalTitular);

        /**
         * Duplico también al pagador
         */

        // $scope.DataPagador.NombrePagador = $scope.DataTitular.NombreTitular + ' ' +  $scope.DataTitular.Apellido1Titular + ' ' +  $scope.DataTitular.Apellido2Titular + ' ' +  $scope.DataTitular.RazonSocialTitular;
        $scope.DataPagador.IdentificadorPagador = $scope.DataTitular.Identificador;
    };

    $scope.buscarRangosCO = function () {
        if ($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue !== undefined && $scope.GrupoTarifa !== undefined && $scope.altaContratoForm.consumoSIPS.$viewValue !== undefined) {
            var data = {
                "IdEmpresa": IdEmpresa,
                "IdUsuario": IdUsuario,
                "IdAgente": IdAgente,
                "IdTarifa": JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue).IdTarifa,
                "IdTarifaGrupo": $scope.GrupoTarifa,
                // "FechaVigencia": moment().format('YYYY-MM-DD'), // Hoy
                "ConsumoAnual": Math.round($scope.altaContratoForm.consumoSIPS.$viewValue),
            }
            $http({
                method: "POST",
                url: urlApi + "AgenteComision/GetDatosComision",
                data: JSON.stringify(data),
                headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
            }).then(function (response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var json = JSON.parse(decodeToken.iss);
                $('#agenteFee').val('');
                $('#comisionCalculada').val('');
                if (json.Rangos !== null) {
                    $scope.CORangos = json.Rangos;
                    $scope.COImporte = null;
                } else if (json.Importe !== null) {
                    $scope.agenteFee = json.Importe.ValorCO; // Valor CO
                    $('#agenteFee').val(json.Importe.ValorCO);
                    $scope.COImporte = $scope.comisionCalculada = json.Importe.ImporteComision; // Importe
                    $('#comisionCalculada').val(json.Importe.ImporteComision);
                    $scope.CORangos = null;
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(false)
                            .textContent('No se han encontrado datos para calcular la comisión.')
                            .ariaLabel('Error').ok('Aceptar')
                    );
                }
            });
        } else {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(false)
                    .textContent('Los campos Tarifa, Producto y Consumo Anual son necesarios para buscar los rangos.')
                    .ariaLabel('Error')
                    .ok('Aceptar')
            );
        }
        /**
             * Control para las ROCK AF, en principio los ID van del 718 al 724
             * Producto es coste de gestion compra de energia PRECIO DIA CHECK 0,36
             * NUNCA LLEVA PRODUCTO DE IMPRESION PAPEL
             */
        if ($scope.GrupoTarifa == "718" ||
            $scope.GrupoTarifa == "719" ||
            $scope.GrupoTarifa == "720" ||
            $scope.GrupoTarifa == "721" ||
            $scope.GrupoTarifa == "722" ||
            $scope.GrupoTarifa == "723" ||
            $scope.GrupoTarifa == "724"
        ) {
            // 0.36 € dia
            $scope.costeGestion = "10.8";
        }

        /**
         * Control para las CLASICA BASE T5, en principio los ID van del 518 al 524
         * Producto es coste de gestion compra de energia PRECIO DIA CHECK 0,166
         * SI LLEVA PRODUCTO DE IMPRESION PAPEL AL SELECCIONARLO EN EL DESPLEGABLE
         */
        if ($scope.GrupoTarifa == "518" ||
            $scope.GrupoTarifa == "519" ||
            $scope.GrupoTarifa == "520" ||
            $scope.GrupoTarifa == "521" ||
            $scope.GrupoTarifa == "522" ||
            $scope.GrupoTarifa == "523" ||
            $scope.GrupoTarifa == "524"
        ) {
            // 0.166 € dia
            $scope.costeGestion = "4.98";
        }
    }

    $scope.calcularComision = function (data) {

        var fee = data.agenteFee.replace(",", ".");
        var entreRangos = false;

        $scope.feeIncorrecto = false;
        $.grep($scope.CORangos, function (n) {
            if (Number((n.FeeMinimo / 10).toFixed(4)) <= Number(fee) && Number((n.FeeMaximo / 10).toFixed(4)) >= Number(fee)) {
                $scope.PorcentajeComision = n.PorcentajeComision;
                var comision = (((Number(fee) * Number(n.PorcentajeComision / 100)) * Math.round($scope.consumoAnual)) / 100).toFixed(2);
                entreRangos = true;
                $scope.requiredContratoCosteOperacion = true;
                $scope.comisionCalculada = document.getElementsByName("comisionCalculada")[0].value = comision;
                return;
            }
        });
        if (!entreRangos) {
            $scope.feeIncorrecto = true;
            $scope.requiredContratoCosteOperacion = true;
            $scope.comisionCalculada = document.getElementsByName("comisionCalculada")[0].value = "";
        }
    };

    $scope.rellenarCliente = function () {

        if ($scope.MostrarDatosIdentificadorDeAgente == true) {
            URL = "Cliente/GetClientePorDni/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Dni/" + $scope.DataTitular.Identificador + "/IdAgente/" + IdAgente;
        } else {
            URL = "Cliente/GetClientePorDni/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Dni/" + $scope.DataTitular.Identificador;
        }

        if ($scope.DataTitular.Identificador != '') {
            $http({
                method: "GET",
                url: urlApi + URL,
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);
                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no


                // Limpiar campos
                // $scope.DataTitular.NombreTitular = "";
                // $scope.DataTitular.Apellido1Titular = "";
                // $scope.DataTitular.Apellido2Titular = "";
                // $scope.DataTitular.RazonSocialTitular = "";

                // $scope.DataTitular.EmailTitular = "";
                // $scope.DataTitular.MovilTitular = "";
                // $scope.DataTitular.TelefonoTitular = "";

                // $scope.calleTitularItem = {
                //     desCalle: "",
                // }

                if (playloadJson.Nombre == null && playloadJson.RazonSocial == null) {

                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(false)
                            .htmlContent('Introduzca los datos del titular manualmente.')
                            .ariaLabel('Introduzca los datos del titular manualmente.')
                            .ok('Aceptar')
                    );

                } else {

                    if (tokenValido && !tokenExpirado) {
                        $scope.cliente = playloadJson;

                    } else {
                        $scope.cliente = "";
                        console.error("HASH DE VERIFICACION INCORRECTO");
                    }

                    $scope.DataTitular.CiudadTitular = "";
                    $scope.DataTitular.NombreCiudadTitular = "";
                    $scope.DataTitular.ProvinciaTitular = "";
                    $scope.DataTitular.Numero = "";
                    $scope.DataTitular.AclaradorTitular = "";
                    $scope.DataTitular.CodPostalTitular = "";

                    $scope.DataTitular.NombreTitular = angular.copy(playloadJson.Nombre);
                    $scope.DataTitular.Apellido1Titular = angular.copy(playloadJson.Apellido1);
                    $scope.DataTitular.Apellido2Titular = angular.copy(playloadJson.Apellido2);
                    $scope.DataTitular.RazonSocialTitular = angular.copy(playloadJson.RazonSocial);

                    $scope.ciudadTitularItem = {
                        desCiudad: {
                            codCiudad: '',
                            codMunicipio: '',
                            codPais: '',
                            codPostal: '',
                            codProvincia: '',
                            codigoProveedorCiudad: '',
                            codigoProveedorMunicipio: '',
                            codigoProveedorPais: '',
                            codigoProveedorProvincia: '',
                            desCiudad: angular.copy(playloadJson.Ciudad),
                            desCiudadInd: '',
                            desMunicipio: '',
                            desMunicipioInd: '',
                            desPais: '',
                            desPaisInd: '',
                            desProvincia: '',
                            desProvinciaInd: '',
                            id: ''
                        }
                    }

                    $scope.DataTitular.CiudadTitular = angular.copy($scope.ciudadTitularItem.desCiudad.desCiudad);
                    $scope.DataTitular.NombreCiudadTitular = angular.copy(playloadJson.Ciudad);
                    //TODO: Falta en el Json de la respuesta, lo cargo en blanco para no dejar textos que no corresponden
                    $scope.DataTitular.ProvinciaTitular = angular.copy(playloadJson.Provincia);

                    $scope.calleTitularItem = {
                        ciudad: null,
                        codCalle: "",
                        codCiudad: "",
                        codMunicipio: "",
                        codPais: "",
                        codProvincia: "",
                        codigoPostal: "",
                        codigoProveedorCalle: "",
                        desCalle: angular.copy(playloadJson.Calle),
                        desCalleInd: "",
                        desCompleta: null,
                        id: "",
                        idMunicipio: "",
                        isPseudoVia: false,
                        tipoCalle: ""
                    }

                    $scope.DataTitular.NombreCalleTitular = angular.copy($scope.calleTitularItem.desCalle);
                    $scope.DataTitular.Numero = angular.copy(playloadJson.Numero);
                    //TODO: Falta en el Json de la respuesta, lo cargo en blanco para no dejar textos que no corresponden
                    $scope.DataTitular.AclaradorTitular = angular.copy(playloadJson.Aclarador);
                    // $scope.DataTitular.CodPostalTitular = angular.copy(parseInt(playloadJson.CodPostal,10));
                    if (playloadJson.CodPostal.length < 5) {
                        $scope.DataTitular.CodPostalTitular = angular.copy("0" + playloadJson.CodPostal);
                    } else {
                        $scope.DataTitular.CodPostalTitular = angular.copy(playloadJson.CodPostal);
                    }

                    /**
                     * Si tenemos datos contacto
                     * Recorro cada nodo del JSON y Según el tipoContacto lo meto en un lado o en otro
                     */

                    if (playloadJson.ClientesContacto.length > 0) {
                        for (var i = 0; i < playloadJson.ClientesContacto.length; i++) {
                            if (playloadJson.ClientesContacto[i].TipoContacto == "E") {
                                $scope.DataTitular.EmailTitular = angular.copy(playloadJson.ClientesContacto[i].Valor);
                            } else if (playloadJson.ClientesContacto[i].TipoContacto == "T") {
                                if (playloadJson.ClientesContacto[i].Valor.charAt(0) >= 8) {
                                    $scope.DataTitular.TelefonoTitular = angular.copy(playloadJson.ClientesContacto[i].Valor);
                                    $scope.validarCambioTelefono($scope.DataTitular.TelefonoTitular);
                                }
                            } else if (playloadJson.ClientesContacto[i].TipoContacto == "M") {
                                if (playloadJson.ClientesContacto[i].Valor.charAt(0) == 6 || playloadJson.ClientesContacto[i].Valor.charAt(0) == 7) {
                                    $scope.DataTitular.MovilTitular = angular.copy(playloadJson.ClientesContacto[i].Valor);
                                    $scope.validarCambioTelefono($scope.DataTitular.MovilTitular);
                                }
                            }
                        }
                    }
                }
                $scope.copiarDatosEnRepresentante();
            });
        }
    };

    $scope.rellenarCups = function (Cups, Distribuidora, Comercializadora, Ciudad, Calle, Numero, Aclarador, CodPostal, Provincia, Tarifa, PotenciaP1, PotenciaP2, PotenciaP3, PotenciaP4, PotenciaP5, PotenciaP6) {

        $scope.comprobarCUPScontratoactivo(Cups.$viewValue.toUpperCase());

        var comisionCalculada = document.getElementsByName("comisionCalculada")[0];
        var agenteFee = document.getElementsByName("agenteFee")[0];

        comisionCalculada.value = "";
        agenteFee.value = "";
        $scope.PorcentajeComision = "";

        var cups = Cups.$viewValue;
        var distribuidora = document.getElementsByName("Distribuidora")[0];
        var ciudad = document.getElementsByName("Ciudad")[0];
        var ciudadTitular = document.getElementsByName("CiudadTitular")[0];
        var calle = document.getElementsByName("Calle")[0];
        var calleTitular = document.getElementsByName("CalleTitular")[0];
        var numero = document.getElementsByName("Numero")[0];
        var numeroTitular = document.getElementsByName("NumeroTitular")[0];
        var aclarador = document.getElementsByName("Aclarador")[0];
        var aclaradorTitular = document.getElementsByName("AclaradorTitular")[0];
        var codPostal = document.getElementsByName("CodPostal")[0];
        var codPostalTitular = document.getElementsByName("CodPostalTitular")[0];
        var provincia = document.getElementsByName("Provincia")[0];
        var provinciaTitular = document.getElementsByName("ProvinciaTitular")[0];
        var tarifa = document.getElementsByName("TarifaAccesoElectricidad")[0];
        var potenciaP1 = document.getElementsByName("PotenciaP1")[0];
        var potenciaP2 = document.getElementsByName("PotenciaP2")[0];
        var potenciaP3 = document.getElementsByName("PotenciaP3")[0];
        var potenciaP4 = document.getElementsByName("PotenciaP4")[0];
        var potenciaP5 = document.getElementsByName("PotenciaP5")[0];
        var potenciaP6 = document.getElementsByName("PotenciaP6")[0];

        var identificador = document.getElementsByName("Identificador")[0];
        var nombreTitular = document.getElementsByName("NombreTitular")[0];
        var cnae = document.getElementsByName("CNAE")[0];

        var evt = new CustomEvent('change');

        $scope.ConsumoEstimadoP1 = 0;
        $scope.ConsumoEstimadoP2 = 0;
        $scope.ConsumoEstimadoP3 = 0;
        $scope.ConsumoEstimadoP4 = 0;
        $scope.ConsumoEstimadoP5 = 0;
        $scope.ConsumoEstimadoP6 = 0;
		if(cups != ''){
			serviciosApi.getSips(cups.toUpperCase(), "", "", "", "", "", "")
				.then(
					function successCallback(responseSIPS) {

						//COMIENZO CAMBIOS BOE HASTA LINEA 1976
						var response = serviciosBOE.respuestaSIPS(responseSIPS);

						console.log(response.data);
						var resupuestaConsumos = response.data.ConsumosSips;
						var resupuestaPotencias = response.data.ClientesSips;
						if(resupuestaPotencias.length > 0){
							serviciosBOE.TarifasComunicacion()
								.then(function successCallback(responseTarifas) {​  //Sacamos todas las tarifas antiguas
									var respuesta = responseTarifas.data;
									var decodeToken = jwtHelper.decodeToken(respuesta);
									var playloadJson = JSON.parse(decodeToken.iss);
									var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
										alg: ["HS256"],
									});
									var tokenExpirado = jwtHelper.isTokenExpired(respuesta);

									if (tokenValido && !tokenExpirado) {
										serviciosBOE.conversionTarifas(playloadJson, resupuestaPotencias[0].CodigoPostalPS, resupuestaConsumos) //Comaparamos tarifas del sips con la llamada anterior
											.then(function successCallback(responseValores) {
												var respuesta = responseValores.data;
												var decodeToken = jwtHelper.decodeToken(respuesta);
												var valorConversionTarifa = JSON.parse(decodeToken.iss);
												var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
													alg: ["HS256"],
												});


												if(repartoconsumoBOEalta){
													var respuestaConsumos = serviciosBOE.repartoConsumo(valorConversionTarifa, resupuestaConsumos);
												}else{
													var respuestaConsumos = response.data.ConsumosSips;
												}

												$scope.clientesSips = response.data.ClientesSips;

												// https: //sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost
												// GET DTO ALL de TARIFAS $scope.TarifasAcceso
												//Guardo todos los resultados de consumos en un array para luego usarlos en un ng-repeat en la vista
												$scope.consumosSips = response.data.ConsumosSips;
												if(response.data.DatosTitular != null){
													$scope.datosTitular = response.data.DatosTitular[0];
												}

												// $scope.actualizarConsumoEstimadoP($scope.ConsumoEstimadoP1,$scope.ConsumoEstimadoP2,$scope.ConsumoEstimadoP3,$scope.ConsumoEstimadoP4,$scope.ConsumoEstimadoP5,$scope.ConsumoEstimadoP6);

												$scope.variablePruebaPotencias = true;

												$scope.respuestaSIPS = response;

												var respuestaSips = response;
												//
												//FIN CAMBIOS BOE

												//Recuperamos la ultima fecha fin para realizar el calculo del año
												if (respuestaConsumos !== null) {
													//Estas variables esta puesta para controlar errores cuando se busca en el sips un cups que no esta y seguido uno que si
													$scope.CosumoAnualDisabled = true;
													$scope.ObservacionesDisabled = false;
													$scope.Observaciones = "";

													// PONERLAS A TRUE EN CASO DE QUERE QUE AL TRAER DATOS DEL SIPS NO PUEDA MODIFICAR LA INFORMACIÓN
													// $scope.ConsumoEstimadoP1Disabled = true;
													// $scope.ConsumoEstimadoP2Disabled = true;
													// $scope.ConsumoEstimadoP3Disabled = true;
													// $scope.ConsumoEstimadoP4Disabled = true;
													// $scope.ConsumoEstimadoP5Disabled = true;
													// $scope.ConsumoEstimadoP6Disabled = true;

													if (respuestaConsumos.length > 0 && $scope.VerElectricidad) {

														var FechaFin = respuestaConsumos[respuestaConsumos.length - 1].FechaFin;
														//A fecha fin le restamos un año, para filtrar con él el Json
														var FechaActual = new Date(FechaFin);

														//1- Formatear la fecha de incio para filtrar el json
														var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
														var FechaFin2 = FechaFin.split('T');
														var FechaInicio = new Date(FechaFin2[0]);
														FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';

														var arraySipsFiltradoFechas = [];
														var sumatorioActivas = 0;
														$.grep(respuestaConsumos, function (n, i) {
															if (n.FechaInicio >= FechaInicio) {
																// console.log(respuestaConsumos[i]);
																//Una vez filtrado, devolver un objeto con la suma de los campos correspondients
																arraySipsFiltradoFechas.push(n);
																sumatorioActivas += respuestaConsumos[i]['Activa1'] + respuestaConsumos[i]['Activa2'] + respuestaConsumos[i]['Activa3'] +
																	respuestaConsumos[i]['Activa4'] + respuestaConsumos[i]['Activa5'] + respuestaConsumos[i]['Activa6'];

																$scope.ConsumoEstimadoP1 += respuestaConsumos[i].Activa1;
																$scope.ConsumoEstimadoP2 += respuestaConsumos[i].Activa2;
																$scope.ConsumoEstimadoP3 += respuestaConsumos[i].Activa3;
																$scope.ConsumoEstimadoP4 += respuestaConsumos[i].Activa4;
																$scope.ConsumoEstimadoP5 += respuestaConsumos[i].Activa5;
																$scope.ConsumoEstimadoP6 += respuestaConsumos[i].Activa6;
															}
														});

														// console.log(sumatorioActivas);


														// console.log("respuestaSips.data.ClientesSips[0].CodigoTarifaATREnVigor");
														// console.log(respuestaSips.data.ClientesSips[0].CodigoTarifaATREnVigor);




														// console.log($scope.ConsumoEstimadoP1);
														// console.log($scope.ConsumoEstimadoP2);
														// console.log($scope.ConsumoEstimadoP3);
														// console.log($scope.ConsumoEstimadoP4);
														// console.log($scope.ConsumoEstimadoP5);
														// console.log($scope.ConsumoEstimadoP6);


														//Calculo de dias entre fechas
														var fechaInicio = new Date(arraySipsFiltradoFechas[0]['FechaInicio']).getTime();
														var fechaFin = new Date(FechaFin).getTime();
														var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
													} else if ($scope.VerGas) {
														if (respuestaSips.data.ClientesSips[0].Pctd != null) {
															$scope.PCTDGas = respuestaSips.data.ClientesSips[0].Pctd;
														}
														var FechaFin = respuestaConsumos[respuestaConsumos.length - 1].FechaFinMesConsumo;
														var FechaActual = new Date(FechaFin);
														var FechaFin2 = FechaFin.split('T');
														var FechaInicioMesConsumo = new Date(FechaFin2[0]);
														FechaInicioMesConsumo = FechaInicioMesConsumo.getFullYear() - 1 + "-" + ("0" + (FechaInicioMesConsumo.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicioMesConsumo.getDate()).slice(-2) + 'T00:00:00';

														var arraySipsFiltradoFechas = [];
														var sumatorioActivas = 0;
														$.grep(respuestaConsumos, function (n, i) {
															if (n.FechaInicioMesConsumo >= FechaInicioMesConsumo) {
																//Una vez filtrado, devolver un objeto con la suma de los campos correspondients
																arraySipsFiltradoFechas.push(n);
																sumatorioActivas += respuestaConsumos[i].ConsumoEnWhP1;
																$scope.ConsumoEstimadoP1 += respuestaConsumos[i].ConsumoEnWhP1;
															}
														});
														//Calculo de dias entre fechas
														var fechaInicio = new Date(arraySipsFiltradoFechas[0].FechaInicioMesConsumo).getTime();
														var fechaFin = new Date(FechaFin).getTime();
														var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
													}

													$scope.CampoTarifaEditable = CampoTarifaEditable;

													if (CaudalPuedeSerNuloCero == false && $scope.PotenciaP1 == null || CaudalPuedeSerNuloCero == false && $scope.PotenciaP1 == 0) {
														$scope.PotenciaP1 = 1;
													}

												}

												$scope.ConsumoEstimadoP1 = Math.round((($scope.ConsumoEstimadoP1) / countDias) * 365);
												$scope.ConsumoEstimadoP2 = Math.round((($scope.ConsumoEstimadoP2) / countDias) * 365);
												$scope.ConsumoEstimadoP3 = Math.round((($scope.ConsumoEstimadoP3) / countDias) * 365);
												$scope.ConsumoEstimadoP4 = Math.round((($scope.ConsumoEstimadoP4) / countDias) * 365);
												$scope.ConsumoEstimadoP5 = Math.round((($scope.ConsumoEstimadoP5) / countDias) * 365);
												$scope.ConsumoEstimadoP6 = Math.round((($scope.ConsumoEstimadoP6) / countDias) * 365);

												// Suma P1+P4 / P2+P5 / P3+P6 en tarifas 3.0 y 3.1
												//Control para que no de el error de la tarifa, que paraba el código


												var consumosSIPS = document.getElementsByName("consumoSIPS")[0];

												if ($scope.VerGas) { // Gas
													$scope.consumoAnual = $scope.consumoSIPS = Math.round((sumatorioActivas / countDias) * 365);
												} else { // Electricidad
													// console.log("sumatorioActivas");
													// console.log(sumatorioActivas);
													// console.log("countDias");
													// console.log(countDias);
													$scope.consumoAnual = $scope.consumoSIPS = Math.round((sumatorioActivas / countDias) * 365);
													// console.log("$scope.consumoAnual");
													// console.log($scope.consumoAnual);
													if ($scope.consumoAnual < 0) {
														$scope.consumoAnual = 0;
														$scope.CosumoAnualDisabled = false;

													}
												}
												$('#consumoSIPS').val($scope.consumoAnual);

												if ($scope.consumoAnual == 0 || $scope.consumoAnual == null || $scope.consumoAnual == undefined) {
													var valorMasAlto = Math.max($scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP1, $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP2, $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP3, $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP4, $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP5, $scope.respuestaSIPS.data.ClientesSips[0].PotenciasContratadasEnWP6);
													$scope.consumoAnual = $scope.consumoSIPS = valorMasAlto * 8760 * 0.2;
												}
												if (isNaN($scope.consumoAnual)) {
													$scope.consumoSIPS = 0;
													$scope.consumoAnual = 0;
												}

												isNaN($scope.ConsumoEstimadoP1) ? $scope.ConsumoEstimadoP1 = 0 : null
												isNaN($scope.ConsumoEstimadoP2) ? $scope.ConsumoEstimadoP2 = 0 : null
												isNaN($scope.ConsumoEstimadoP3) ? $scope.ConsumoEstimadoP3 = 0 : null
												isNaN($scope.ConsumoEstimadoP4) ? $scope.ConsumoEstimadoP4 = 0 : null
												isNaN($scope.ConsumoEstimadoP5) ? $scope.ConsumoEstimadoP5 = 0 : null
												isNaN($scope.ConsumoEstimadoP6) ? $scope.ConsumoEstimadoP6 = 0 : null

												consumosSIPS.dispatchEvent(evt);

												if (MostrarSipsPotenciasSige) {

													//llamamos a la API de verificarCUPS (si esta, poner potencias SIGE, sino potencias SIPS.)
													$http.get(urlApi + "/CUPS/VerificarCUPSExistente/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
														.then(function (response) {
															var playload = JSON.parse(atob(response.data.split('.')[1]));
															var playloadJson = JSON.parse(playload.iss);
                                                            console.log('El CUPS existe. ',playloadJson);

															if (playloadJson) {
                                                                $mdDialog.show(
                                                                    $mdDialog.alert()
                                                                    .clickOutsideToClose(false)
                                                                    .htmlContent("El cups introducido ya cuenta con contratos activos")
                                                                    .ariaLabel("El cups introducido ya cuenta con contratos activos")
                                                                    .ok('Aceptar')
                                                                );
																$http.get(urlApi + "/CUPS/GetCUPSPotencias/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
																	.success(function (response) {
																		var respuesta = response.data;
																		var decodeToken = jwtHelper.decodeToken(respuesta);
																		var playloadJsonPotencias = JSON.parse(decodeToken.iss);
																		$scope.GetCUPSPotencias = playloadJsonPotencias;
																		$scope.Tarifa = playloadJsonPotencias[0].TextoTarifa;

																		if ($scope.Tarifa == "2.0TD ML") {

																			potenciaP1.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP1;
																			potenciaP1.dispatchEvent(evt);
																			PotenciaP1.$setValidity("required", true);

																			potenciaP3.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP3;
																			potenciaP3.dispatchEvent(evt);
																			PotenciaP3.$setValidity("required", true);

																			potenciaP2.value = "";
																			potenciaP4.value = "";
																			potenciaP5.value = "";
																			potenciaP6.value = "";

																		}

																		if ($scope.Tarifa == "3.0TD ML" ||
																			$scope.Tarifa == "6.1TD ML" ||
																			$scope.Tarifa == "6.2TD ML" ||
																			$scope.Tarifa == "6.3TD ML" ||
																			$scope.Tarifa == "6.4TD ML")
																		{
																			potenciaP1.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP1;
																			potenciaP1.dispatchEvent(evt);
																			PotenciaP1.$setValidity("required", true);

																			potenciaP2.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP2;
																			potenciaP2.dispatchEvent(evt);
																			PotenciaP2.$setValidity("required", true);

																			potenciaP3.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP3;
																			potenciaP3.dispatchEvent(evt);
																			PotenciaP3.$setValidity("required", true);

																			potenciaP4.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP4;
																			potenciaP4.dispatchEvent(evt);
																			PotenciaP4.$setValidity("required", true);

																			potenciaP5.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP5;
																			potenciaP5.dispatchEvent(evt);
																			PotenciaP5.$setValidity("required", true);

																			potenciaP6.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP6;
																			potenciaP6.dispatchEvent(evt);
																			PotenciaP6.$setValidity("required", true);
																		}

																	});

															} else {
																switch (respuestaSips.data.ClientesSips[0].CodigoTarifaATREnVigor) {
																	case "001":
																		$scope.Tarifa = "2.0A ML";
																		break;
																	case "003":
																		$scope.Tarifa = "3.0A ML";
																		break;
																	case "004":
																		$scope.Tarifa = "2.0DHA ML";
																		break;
																	case "005":
																		$scope.Tarifa = "2.1A ML";
																		break;
																	case "006":
																		$scope.Tarifa = "2.1DHA ML";
																		break;
																	case "007":
																		$scope.Tarifa = "2.0DHS ML";
																		break;
																	case "008":
																		$scope.Tarifa = "2.1DHS ML";
																		break;
																	case "011":
																		$scope.Tarifa = "3.1A ML";
																		break;
																	case "012":
																		$scope.Tarifa = "6.1A ML";
																		break;
																	case "013":
																		$scope.Tarifa = "6.2A ML";
																		break;
																	case "014":
																		$scope.Tarifa = "6.3 ML";
																		break;
																	case "015":
																		$scope.Tarifa = "6.4 ML";
																		break;
																	case "016":
																		$scope.Tarifa = "6.5 ML";
																		break;
																	case "017":
																		$scope.Tarifa = "6.1B ML";
																		break;
																	case "018":
																		$scope.Tarifa = "2.0TD ML";
																		break;
																	case "019":
																		$scope.Tarifa = "3.0TD ML";
																		break;
																	case "020":
																		$scope.Tarifa = "6.1TD ML";
																		break;
																	case "021":
																		$scope.Tarifa = "6.2TD ML";
																		break;
																	case "022":
																		$scope.Tarifa = "6.3TD ML";
																		break;
																	case "023":
																		$scope.Tarifa = "6.4TD ML";
																		break;
																	case "024":
																		$scope.Tarifa = "3.0TDVE ML";
																		break;
																	case "025":
																		$scope.Tarifa = "6.1TDVE ML";
																		break;

																}

																if ($scope.Tarifa == "2.0TD ML") {

																	potenciaP1.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP1;
																	potenciaP1.dispatchEvent(evt);
																	PotenciaP1.$setValidity("required", true);

																	potenciaP3.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP3;
																	potenciaP3.dispatchEvent(evt);
																	PotenciaP3.$setValidity("required", true);

																	potenciaP2.value = "";
																	potenciaP4.value = "";
																	potenciaP5.value = "";
																	potenciaP6.value = "";

																}

																if ($scope.Tarifa == "3.0TD ML" ||
																	$scope.Tarifa == "6.1TD ML" ||
																	$scope.Tarifa == "6.2TD ML" ||
																	$scope.Tarifa == "6.3TD ML" ||
																	$scope.Tarifa == "6.4TD ML")
																{
																	potenciaP1.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP1;
																	potenciaP1.dispatchEvent(evt);
																	PotenciaP1.$setValidity("required", true);

																	potenciaP2.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP2;
																	potenciaP2.dispatchEvent(evt);
																	PotenciaP2.$setValidity("required", true);

																	potenciaP3.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP3;
																	potenciaP3.dispatchEvent(evt);
																	PotenciaP3.$setValidity("required", true);

																	potenciaP4.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP4;
																	potenciaP4.dispatchEvent(evt);
																	PotenciaP4.$setValidity("required", true);

																	potenciaP5.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP5;
																	potenciaP5.dispatchEvent(evt);
																	PotenciaP5.$setValidity("required", true);

																	potenciaP6.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP6;
																	potenciaP6.dispatchEvent(evt);
																	PotenciaP6.$setValidity("required", true);
																}
															}
														});

												} else {
													if ($scope.VerElectricidad) {
														if (respuestaSips.data.ClientesSips.length > 0) {
															switch (respuestaSips.data.ClientesSips[0].CodigoTarifaATREnVigor) {
																case "001": case "004": case "005": case "006": case "007": case "008":
																	$scope.Tarifa = "2.0TD ML"; //2.0A ML
																	break;
																case "003":
																	$scope.Tarifa = "3.0TD ML"; //3.0A ML
																	break;
																case "011":
																	$scope.Tarifa = "6.1TD ML"; //3.1A ML
																	break;
																case "012":
																	$scope.Tarifa = "6.1TD ML"; //6.1A ML
																	break;
																case "013":
																	$scope.Tarifa = "6.2TD ML"; //6.2 ML
																	break;
																case "014":
																	$scope.Tarifa = "6.3TD ML"; //6.3 ML
																	break;
																case "015":
																	$scope.Tarifa = "6.4TD ML"; //6.4 ML
																	break;
																case "016":
																	$scope.Tarifa = "6.4TD ML"; //6.5 ML
																	break;
																case "017":
																	$scope.Tarifa = "6.1B ML"; //6.1B ML
																	break;
																case "018":
																	$scope.Tarifa = "2.0TD ML";
																	break;
																case "019":
																	$scope.Tarifa = "3.0TD ML";
																	break;
																case "020":
																	$scope.Tarifa = "6.1TD ML";
																	break;
																case "021":
																	$scope.Tarifa = "6.2TD ML";
																	break;
																case "022":
																	$scope.Tarifa = "6.3TD ML";
																	break;
																case "023":
																	$scope.Tarifa = "6.4TD ML";
																	break;
																case "024":
																	$scope.Tarifa = "3.0TDVE ML";
																	break;
																case "025":
																	$scope.Tarifa = "6.1TDVE ML";
																	break;
															}
														}
													} else if ($scope.VerGas) {
														if(pruebaBoeGas){
															var tarifaBoeGas = '';
															if (respuestaSips.data.ClientesSips[0].CodigoPeajeEnVigor.charAt(0) == 'R' ||
																respuestaSips.data.ClientesSips[0].CodigoPeajeEnVigor.charAt(0) == 'L'||
																respuestaSips.data.ClientesSips[0].CodigoPeajeEnVigor.charAt(0) == 'S'){
																switch (respuestaSips.data.ClientesSips[0].CodigoPeajeEnVigor) {
																	case "R1":
																		tarifaBoeGas = 'RL. 1';
																	break;
																	case "R2":
																		tarifaBoeGas = 'RL. 2';
																	break;
																	case "R3":
																		tarifaBoeGas = 'RL. 3';
																	break;
																	case "R4":
																		tarifaBoeGas = 'RL. 4';
																	break;
																	case "R5":
																		tarifaBoeGas = 'RL. 5';
																	break;
																	case "R6":
																		tarifaBoeGas = 'RL. 6';
																	break;
																	case "R7":
																		tarifaBoeGas = 'RL. 7';
																	break;
																	case "R8":
																		tarifaBoeGas = 'RL. 8';
																	break;
																	case "R9":
																		tarifaBoeGas = 'RL. 9';
																	break;
																	case "L0":
																		tarifaBoeGas = 'RL. 10';
																	break;
																	case "L1":
																		tarifaBoeGas = 'RL. 11';
																	break;
																	case "S1":
																		tarifaBoeGas = 'RLPS. 1';
																	break;
																	case "S2":
																		tarifaBoeGas = 'RLPS. 2';
																	break;
																	case "S3":
																		tarifaBoeGas = 'RLPS. 3';
																	break;
																	case "S4":
																		tarifaBoeGas = 'RLPS. 4';
																	break;
																	case "S5":
																		tarifaBoeGas = 'RLPS. 5';
																	break;
																	case "S6":
																		tarifaBoeGas = 'RLPS. 6';
																	break;
																	case "S7":
																		tarifaBoeGas = 'RLPS. 7';
																	break;
																	case "S8":
																		tarifaBoeGas = 'RLPS. 8 ';
																	break;
																}
																$scope.Tarifa = tarifaBoeGas;
															} else {
																// console.log(resupuestaConsumos);
																// console.log(resupuestaPotencias);
																var fechaInicioGasBoe = new Date('2021-07-01T00:00:00').getTime();
																var fechaFinGasBoe = new Date('2020-06-01T00:00:00').getTime();
																var consumoTotalGasBoe = 0;
																respuestaConsumos.forEach(function (item) {

																	if (new Date('2020-06-01T00:00:00').getTime() <= new Date(item["FechaInicioMesConsumo"]).getTime() && new Date('2021-07-01T00:00:00').getTime() >= new Date(item["FechaFinMesConsumo"]).getTime()) {
																		consumoTotalGasBoe += item['ConsumoEnWhP1'];
																		if(fechaInicioGasBoe > new Date(item["FechaInicioMesConsumo"]).getTime()) fechaInicioGasBoe = new Date(item["FechaInicioMesConsumo"]).getTime();
																		if(fechaFinGasBoe < new Date(item["FechaFinMesConsumo"]).getTime()) fechaFinGasBoe = new Date(item["FechaFinMesConsumo"]).getTime();
																	}
																});
																var countDiasBoeGas = (fechaFinGasBoe - fechaInicioGasBoe) / (1000 * 60 * 60 * 24);
																consumoTotalGasBoe = (consumoTotalGasBoe / countDiasBoeGas) * 365;
																// console.log(countDiasBoeGas);
																// console.log(consumoTotalGasBoe);
																if(resupuestaPotencias[0]['ConectadoPlantaSatelite'] == true || resupuestaPotencias[0]['ConectadoPlantaSatelite'] == 1){
																	if (consumoTotalGasBoe >= 500000001){
																		tarifaBoeGas = 'RL. 11';
																	} else if(consumoTotalGasBoe >= 150000001){
																		tarifaBoeGas = 'RL. 10';
																	} else if(consumoTotalGasBoe >= 50000001){
																		tarifaBoeGas = 'RL. 9';
																	} else if(consumoTotalGasBoe >= 15000001){
																		tarifaBoeGas = 'RLPS. 8';
																	} else if(consumoTotalGasBoe >= 5000001){
																		tarifaBoeGas = 'RLPS. 7';
																	} else if(consumoTotalGasBoe >= 1500001){
																		tarifaBoeGas = 'RLPS. 6';
																	} else if(consumoTotalGasBoe >= 300001){
																		tarifaBoeGas = 'RLPS. 5';
																	} else if(consumoTotalGasBoe >= 50001){
																		tarifaBoeGas = 'RLPS. 4';
																	} else if(consumoTotalGasBoe >= 15001){
																		tarifaBoeGas = 'RLPS. 3';
																	} else if(consumoTotalGasBoe >= 5001){
																		tarifaBoeGas = 'RLPS. 2';
																	} else{
																		tarifaBoeGas = 'RLPS. 1';
																	}
																} else {
																	if(consumoTotalGasBoe >= 500000001){
																		tarifaBoeGas = 'RL. 11';
																	} else if(consumoTotalGasBoe >= 150000001){
																		tarifaBoeGas = 'RL. 10';
																	} else if(consumoTotalGasBoe >= 50000001){
																		tarifaBoeGas = 'RL. 9';
																	} else if(consumoTotalGasBoe >= 15000001){
																		tarifaBoeGas = 'RL. 8';
																	} else if(consumoTotalGasBoe >= 5000001){
																		tarifaBoeGas = 'RL. 7';
																	} else if(consumoTotalGasBoe >= 1500001){
																		tarifaBoeGas = 'RL. 6';
																	} else if(consumoTotalGasBoe >= 300001){
																		tarifaBoeGas = 'RL. 5';
																	} else if(consumoTotalGasBoe >= 50001){
																		tarifaBoeGas = 'RL. 4';
																	} else if(consumoTotalGasBoe >= 15001){
																		tarifaBoeGas = 'RL. 3';
																	} else if(consumoTotalGasBoe >= 5001){
																		tarifaBoeGas = 'RL. 2';
																	} else{
																		tarifaBoeGas = 'RL. 1';
																	}
																}
															}


															$scope.Tarifa = tarifaBoeGas;
														} else {
															switch (respuestaSips.data.ClientesSips[0].CodigoPeajeEnVigor) {
																case "11":
																	$scope.Tarifa = "1.1";
																	break;
																case "12":
																	$scope.Tarifa = "1.2";
																	break;
																case "13":
																	$scope.Tarifa = "1.3";
																	break;
																case "21":
																	$scope.Tarifa = "2.1";
																	break;
																case "22":
																	$scope.Tarifa = "2.2";
																	break;
																case "23":
																	$scope.Tarifa = "2.3";
																	break;
																case "24":
																	$scope.Tarifa = "2.4";
																	break;
																case "25":
																	$scope.Tarifa = "2.5";
																	break;
																case "26":
																	$scope.Tarifa = "2.6";
																	break;
																case "31":
																	$scope.Tarifa = "3.1";
																	break;
																case "32":
																	$scope.Tarifa = "3.2";
																	break;
																case "33":
																	$scope.Tarifa = "3.3";
																	break;
																case "34":
																	$scope.Tarifa = "3.4";
																	break;
															}
														}
													}


													if ($scope.Tarifa == "2.0TD ML") {

														potenciaP1.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP1;
														potenciaP1.dispatchEvent(evt);
														PotenciaP1.$setValidity("required", true);

														potenciaP3.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP3;
														potenciaP3.dispatchEvent(evt);
														PotenciaP3.$setValidity("required", true);

														potenciaP2.value = "";
														potenciaP4.value = "";
														potenciaP5.value = "";
														potenciaP6.value = "";

													}

													if ($scope.Tarifa == "3.0TD ML" ||
														$scope.Tarifa == "6.1TD ML" ||
														$scope.Tarifa == "6.2TD ML" ||
														$scope.Tarifa == "6.3TD ML" ||
														$scope.Tarifa == "6.4TD ML")
													{
														potenciaP1.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP1;
														potenciaP1.dispatchEvent(evt);
														PotenciaP1.$setValidity("required", true);

														potenciaP2.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP2;
														potenciaP2.dispatchEvent(evt);
														PotenciaP2.$setValidity("required", true);

														potenciaP3.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP3;
														potenciaP3.dispatchEvent(evt);
														PotenciaP3.$setValidity("required", true);

														potenciaP4.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP4;
														potenciaP4.dispatchEvent(evt);
														PotenciaP4.$setValidity("required", true);

														potenciaP5.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP5;
														potenciaP5.dispatchEvent(evt);
														PotenciaP5.$setValidity("required", true);

														potenciaP6.value = respuestaSips.data.ClientesSips[0].PotenciasContratadasEnWP6;
														potenciaP6.dispatchEvent(evt);
														PotenciaP6.$setValidity("required", true);
													}

												}

												if ((cups != null) || (cups != undefined)) {
													var cupsAcortado = cups.substring(2, 8);

													if (cupsAcortado == "012034") {
														distribuidora.value = "0120";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003032") {
														distribuidora.value = "0030";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003130") {
														distribuidora.value = "0029";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003144") {
														distribuidora.value = "0396";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003110") {
														distribuidora.value = "0023";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003150") {
														distribuidora.value = "0288";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "040166") {
														distribuidora.value = "0401";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003160") {
														distribuidora.value = "0363";
														distribuidora.dispatchEvent(evt);
													} else if (cupsAcortado == "003140") {
														if (respuestaSips.data.ClientesSips[0].CodigoPostalPS.startsWith("50")) {
															distribuidora.value = "0029";
															distribuidora.dispatchEvent(evt);
														} else {
															distribuidora.value = "0024";
															distribuidora.dispatchEvent(evt);
														}
													} else {
														distribuidora.value = cups.substring(2, 6);
														if(resupuestaPotencias[0]['CodigoEmpresaDistribuidora'] > 0){
															distribuidora.value = resupuestaPotencias[0]['CodigoEmpresaDistribuidora'];
														}
														distribuidora.dispatchEvent(evt);
													}
												}

												if (respuestaSips.data.ClientesSips.length > 0) {
													numero.value = respuestaSips.data.ClientesSips[0].NumFincaPS;
													numero.dispatchEvent(evt);
													Numero.$setValidity('required', true);
													numeroTitular.value = respuestaSips.data.ClientesSips[0].NumFincaPS;
													numeroTitular.dispatchEvent(evt);


													if (respuestaSips.data.ClientesSips[0].PortalPS == null || respuestaSips.data.ClientesSips[0].PortalPS == undefined) {
														respuestaSips.data.ClientesSips[0].PortalPS = "";
													}

													if (respuestaSips.data.ClientesSips[0].PisoPS == null || respuestaSips.data.ClientesSips[0].PisoPS == undefined) {
														respuestaSips.data.ClientesSips[0].PisoPS = "";
													}

													if (respuestaSips.data.ClientesSips[0].EscaleraPS == null || respuestaSips.data.ClientesSips[0].EscaleraPS == undefined) {
														respuestaSips.data.ClientesSips[0].EscaleraPS = "";
													}

													if (respuestaSips.data.ClientesSips[0].PuertaPS == null || respuestaSips.data.ClientesSips[0].PuertaPS == undefined) {
														respuestaSips.data.ClientesSips[0].PuertaPS = "";
													}

													aclarador.value = respuestaSips.data.ClientesSips[0].PortalPS + " " + respuestaSips.data.ClientesSips[0].PisoPS + " " + respuestaSips.data.ClientesSips[0].EscaleraPS + " " + respuestaSips.data.ClientesSips[0].PuertaPS;
													aclarador.value = aclarador.value.trim();
													aclarador.dispatchEvent(evt);
													Aclarador.$setValidity('required', true);
													aclaradorTitular.value = respuestaSips.data.ClientesSips[0].PortalPS + " " + respuestaSips.data.ClientesSips[0].PisoPS + " " + respuestaSips.data.ClientesSips[0].EscaleraPS + " " + respuestaSips.data.ClientesSips[0].PuertaPS;
													aclaradorTitular.value = aclaradorTitular.value.trim();
													aclaradorTitular.dispatchEvent(evt);

													var letras_prohibidas = Array("á", "é", "í", "ó", "ú", "Á", "É", "Í", "Ó", "Ú", "Ñ", "ñ", " ", "-");
													// var letras_validas = Array("a", "e", "i", "o", "u", "A", "E", "I", "O", "U", "N", "n", "_", "_");

													var str1 = letras_prohibidas.join("");
													var re = new RegExp('[' + str1 + ']', 'g');

													ciudadTitular.value = respuestaSips.data.ClientesSips[0].DesMunicipioPS;
													ciudadTitular.dispatchEvent(evt);

													codPostal.value = respuestaSips.data.ClientesSips[0].CodigoPostalPS;
													codPostal.dispatchEvent(evt);
													CodPostal.$setValidity('required', true);
													codPostalTitular.value = respuestaSips.data.ClientesSips[0].CodigoPostalPS;
													codPostalTitular.dispatchEvent(evt);

													// $scope.CupsNoRegistrado = false;

													/**
													 * Pongo la provincia del suministro como sugerencia, pero que busquen de forma manual
													 * Quito el autocomplete para que escriban
													 */
													provincia.value = respuestaSips.data.ClientesSips[0].DesProvinciaPS;
													provincia.dispatchEvent(evt);

													$scope.sugerenciaCiudadSuministro = ciudadTitular.value;

													Provincia.$setValidity('required', true);
													provinciaTitular.value = respuestaSips.data.ClientesSips[0].DesProvinciaPS;
													provinciaTitular.dispatchEvent(evt);

													$scope.callejeroINESuministro = {
														"codCiudad": "",
														"desCiudad": respuestaSips.data.ClientesSips[0].DesMunicipioPS,
														"codPais": "1",
														"desPais": "España",
														"codProvincia": respuestaSips.data.ClientesSips[0].CodigoProvinciaPS,
														"desProvincia": respuestaSips.data.ClientesSips[0].DesProvinciaPS,
														"codMunicipio": respuestaSips.data.ClientesSips[0].MunicipioPS.substr(2, 3),
														"desMunicipio": respuestaSips.data.ClientesSips[0].DesMunicipioPS,
														"codigoProveedorProvincia": "",
														"codigoProveedorMunicipio": "",
														"codigoProveedorCiudad": ""
													}

													if (respuestaSips.data.ClientesSips[0].Cnae == null || respuestaSips.data.ClientesSips[0].Cnae == undefined) {
														respuestaSips.data.ClientesSips[0].Cnae = "";
													}
													$scope.Cnae = respuestaSips.data.ClientesSips[0].Cnae;

													if (respuestaSips.data.ClientesSips[0].CodigoPeriodicidadFacturacion == null || respuestaSips.data.ClientesSips[0].CodigoPeriodicidadFacturacion == undefined) {
														$scope.ValorDesplegablePeriodo = ValorDesplegablePeriodo;

													} else {
														if (respuestaSips.data.ClientesSips[0].CodigoPeriodicidadFacturacion == '01') {
															$scope.ValorDesplegablePeriodo = "MENSUAL";
														}
														if (respuestaSips.data.ClientesSips[0].CodigoPeriodicidadFacturacion == '02') {
															$scope.ValorDesplegablePeriodo = "BIMESTRES";
														}
													}
												}

												if (informacionSipsCliente && nombreEmpresa != 'ELEIA') {
													// Controlo que el array de datos de titular contenga datos, para evitar el error de undefined
													if (response.data.DatosTitular !== null)
														if (response.data.DatosTitular.length > 0 && response.data.DatosTitular.length != null) {
															/**
															 * Añado el campo de sugerencia dirección para facilitar el autocomplete
															 * quito el rellenar el descalle, porque se meteria con la mala
															 */
															$scope.sugerenciaDireccionSuministro = response.data.DatosTitular[0].DireccionSuministro;

															ciudadTitular.value = response.data.DatosTitular[0].CiudadTitular;
															ciudadTitular.dispatchEvent(evt);

															calleTitular.value = response.data.DatosTitular[0].DireccionTitular;
															calleTitular.dispatchEvent(evt);

															nombreTitular.value = response.data.DatosTitular[0].NombreTitular;
															nombreTitular.dispatchEvent(evt);

															provinciaTitular.value = response.data.DatosTitular[0].ProvinciaTitular;
															provinciaTitular.dispatchEvent(evt);
														}
												}
												/**
												 * Este campo lo uso solo para saber si el SIPS esta KO, con el cada vez que actualizan tarifa, borro los valores que habia
												 */
												$scope.sipsCaido = false;

											});
									} else {
										console.error("HASH DE VERIFICACION INCORRECTO");
									}
								});
							}  else {
								var negacionEntorno = '';
								if($scope.VerElectricidad){
									negacionEntorno = 'Gas';
								} else if($scope.VerGas) {
									negacionEntorno = 'Luz';
								}
								serviciosApi.getSipsByEntorno(cups.toUpperCase(), "", "", "", "", "", "",negacionEntorno).then(
									function successCallback(responseInterna) {

										var respuestaSipsInterna = responseInterna;

										var respuestaConsumosInterna = responseInterna.data.ConsumosSips;

										//Si hay registros, el cups es del otro entorno
										if (respuestaConsumosInterna !== null){
											$scope.CosumoAnualDisabled = true;
											$scope.CampoTarifaEditable = true;
											$scope.EntornoEquivocado = true;
											if(negacionEntorno == "Gas"){
												$mdDialog.show(
													$mdDialog.alert()
													.clickOutsideToClose(false)
													.htmlContent("El cups introducido es de Gas y estas en el entorno de Electricidad")
													.ariaLabel("El cups introducido es de Gas y estas en el entorno de Electricidad")
													.ok('Aceptar')
												);
											} else {
												$mdDialog.show(
													$mdDialog.alert()
													.clickOutsideToClose(false)
													.htmlContent("El cups introducido es de Electricidad y estas en el entorno de Gas")
													.ariaLabel("El cups introducido es de Electricidad y estas en el entorno de Gas")
													.ok('Aceptar')
												);
											}
										} else {
											/**
											 * Controlo los CUPS que no tienen consumos, es decir no que esten a 0, sino que no esten en el SIPS (consumo === null)
											 * Lo primero desbloqueo el campo
											 * Luego muestro un error aclarando que no esta en SIPS y que lo piquen a mano
											 * Y vale porque el resto de controles los hace bien
											 */

											$scope.CosumoAnualDisabled = false;
											$scope.ConsumoEstimadoP1Disabled = false;
											$scope.ConsumoEstimadoP2Disabled = false;
											$scope.ConsumoEstimadoP3Disabled = false;
											$scope.ConsumoEstimadoP4Disabled = false;
											$scope.ConsumoEstimadoP5Disabled = false;
											$scope.ConsumoEstimadoP6Disabled = false;

											$scope.campoPotenciaP1disabled = false;
											$scope.campoPotenciaP2disabled = false;
											$scope.campoPotenciaP3disabled = false;
											$scope.campoPotenciaP4disabled = false;
											$scope.campoPotenciaP5disabled = false;
											$scope.campoPotenciaP6disabled = false;

											$mdDialog.show(
												$mdDialog.alert()
												.clickOutsideToClose(false)
												.htmlContent('No existen datos en SIPS. Introduzca los datos manualmente.')
												.ariaLabel('No existen datos en SIPS. Introduzca los datos manualmente.')
												.ok('Aceptar')
											);

											$scope.Observaciones = "Consumo Anual introducido de forma manual";
											$scope.ObservacionesDisabled = false;

											$scope.CosumoAnualDisabled = false;
											$scope.CampoTarifaEditable = false;
											$scope.EntornoEquivocado = false;
											/**
											 * Este campo lo uso solo para saber si el SIPS esta KO, con el cada vez que actualizan tarifa, borro los valores que habia
											 */
											$scope.sipsCaido = true;

											if(CaudalPuedeSerNuloCero == false && $scope.VerGas == true){
												$scope.PotenciaP1 = 1;
											}
										}
									},
									function errorCallback(responseInterna) {
										/**
										 * Controlo cuando la API de SIPS no da un estado correcto, es decir, el 200
										 */
										$mdDialog.show(
											$mdDialog.alert()
											.clickOutsideToClose(false)
											.htmlContent('SIPS No disponible temporalmente. Introduzca los datos manualmente.')
											.ariaLabel('SIPS No disponible temporalmente. Introduzca los datos manualmente.')
											.ok('Aceptar')
										);

										$scope.CosumoAnualDisabled = false;
										$scope.CampoTarifaEditable = false;
										$scope.EntornoEquivocado = false
										/**
										 * Este campo lo uso solo para saber si el SIPS esta KO, con el cada vez que actualizan tarifa, borro los valores que habia
										 */
										$scope.sipsCaido = true;
									}
								);
							}


					},
					function errorCallback(response) {
						/**
						 * Controlo cuando la API de SIPS no da un estado correcto, es decir, el 200
						 */
						$mdDialog.show(
							$mdDialog.alert()
								.clickOutsideToClose(false)
								.htmlContent('SIPS No disponible temporalmente. Introduzca los datos manualmente.')
								.ariaLabel('SIPS No disponible temporalmente. Introduzca los datos manualmente.')
								.ok('Aceptar')
						);

						$scope.CosumoAnualDisabled = false;
						$scope.CampoTarifaEditable = false;
						/**
						 * Este campo lo uso solo para saber si el SIPS esta KO, con el cada vez que actualizan tarifa, borro los valores que habia
						 */
						$scope.sipsCaido = true;
					}
				);
		}
    };
    $scope.comprobarCUPScontratoactivo = function(cups){
        $http({
            method: "POST",
            url: urlApi + "CUPS/ComprobarCupsActivo",
            data: '"'+cups+'"',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
                //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {
                    if(playloadJson>0){
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(false)
                                .htmlContent('El CUPS <b>'+cups+'</b> tiene actualmente el contrato <b>'+playloadJson+'</b> </br> en una situacion que no permite crear otro contrato.')
                                .ariaLabel('Bloqueo CUPS.')
                                .ok('Aceptar')
                        );
                    }
                }
        });
    }

    $scope.changeConsumoAnual = function (consumo) {
        $scope.consumoAnual = consumo.consumoSIPS;
    }

    $scope.actualizarConsumoEstimadoP = function (ConsumoEstimadoP1, ConsumoEstimadoP2, ConsumoEstimadoP3, ConsumoEstimadoP4, ConsumoEstimadoP5, ConsumoEstimadoP6) {

        // console.log("actualizarConsumoEstimadoP");

        $scope.ConsumoEstimadoP1 = ConsumoEstimadoP1;
        $scope.ConsumoEstimadoP2 = ConsumoEstimadoP2;
        $scope.ConsumoEstimadoP3 = ConsumoEstimadoP3;
        $scope.ConsumoEstimadoP4 = ConsumoEstimadoP4;
        $scope.ConsumoEstimadoP5 = ConsumoEstimadoP5;
        $scope.ConsumoEstimadoP6 = ConsumoEstimadoP6;

        $scope.updateFeeValuePorPeriodos($scope.fees, $scope.tipo);

    }
	$scope.limpiaDolar = function(string) {
		if(string != undefined && string != ''){
			var aux = string.replace(/[^a-zA-Z 0-9.()]+/g,"");
			return aux;
		} else {
			return string;
		}
	}
	$scope.updateBono = function (bono) {
		if(bono.SelectBono == 'NO'){
			$scope.valorBono = '';
		} else {
			$scope.ColeccionBonos.forEach(function (item) {
				if(item.IdBono == bono.SelectBono){
					$scope.valorBono = item.Importe;
				}
			});
		}

	};
    $scope.update = function (datos) {
        // console.log("update");
        $scope.muestraocultaprecio = false;

        var objeto = JSON.parse(datos.TarifaAccesoElectricidad);
        if ($scope.sipsCaido) {
            $('input#PotenciaP1').val('');
            $('input#PotenciaP2').val('');
            $('input#PotenciaP3').val('');
            $('input#PotenciaP4').val('');
            $('input#PotenciaP5').val('');
            $('input#PotenciaP6').val('');
        }
        $http({
            method: "GET",
            url: urlApi + "Tarifa/GetDTOAllGruposTarifa/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Tarifa/" + objeto.IdTarifa,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            // console.log(playloadJson);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.GruposTarifa = playloadJson;
                if ($scope.SimplificacionesEnergiaGalega) {
                    var auxGruposTarifa = [];
                    for (var index = 0; index < $scope.GruposTarifa.length; index++) {
                        const element = $scope.GruposTarifa[index];
                        if (element['TextoTarifaGrupo'].includes('CASHBACK')) {
                            auxGruposTarifa.push(element);
                        }
                    }
                    if (auxGruposTarifa.length > 0) {
                        $scope.GruposTarifa = auxGruposTarifa;
                    }
                }
				// Comprobar si mostrar BONOS
				if($scope.VerGas && $scope.IsSistemaBonoActivo) {
					$http({
						method: "POST",
						url: urlApi + "Tarifa/FilterBonos",
						data: JSON.stringify({ "IdTarifa": objeto.IdTarifa, "IdEmpresa": IdEmpresa}),
						headers: {
							'Content-Type': 'application/json; charset=UTF-8',
							Authorization: atob(localStorage.getItem("TokenValidador"))
						}
					}).then(function successCallback(responseBonos) {
						var respuestaBonos = responseBonos.data;
						//TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

						var decodeTokenBonos = jwtHelper.decodeToken(respuestaBonos);
						var playloadJsonBonos = JSON.parse(decodeTokenBonos.iss);
						if(playloadJsonBonos['IsSistemaBonoActivo']){
							$scope.MostrarBonos = true;
							$scope.ColeccionBonos = playloadJsonBonos['Bonos'];
                            for (var bono in $scope.ColeccionBonos) {
                                console.log('Entro');
                                if(bono.IdTarifaGrupo==$scope.GrupoTarifa){
                                    $scope.altaContratoForm.SelectBono.value=bono.IdBono;
                                    console.log('Encontrado bono');

                                }else{
                                    console.log('No encontrado bono');
                                }
                            }
						} else {
							$scope.MostrarBonos = false;
							$scope.ColeccionBonos = [];
						}
					});
				}
            } else {
                $scope.GruposTarifa = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }

            $scope.IdTarifaGrupo = playloadJson[0].IdTarifaGrupo;

        });

        var textoTarifa = objeto.TextoTarifa;
        textoTarifa = textoTarifa.replace(" ML", "");
        $scope.textoTarifaEleia = objeto.TextoTarifa;
        // console.log("textoTarifa");
        // console.log(textoTarifa);
        // console.log("$scope.MostrarAltaConsumoAnualPeriodos");
        // console.log(textoTarifa);

        switch (textoTarifa) {
            case "2.0TD":
                $scope.ocultarPotencia1 = false;
                $scope.ocultarPotencia2 = true;
                $scope.ocultarPotencia3 = false;
                $scope.ocultarPotencia4 = true;
                $scope.ocultarPotencia5 = true;
                $scope.ocultarPotencia6 = true;

                if (!$scope.variablePruebaPotencias) {
                    datos.PotenciaP1 = "";
                    datos.PotenciaP2 = "";
                    datos.PotenciaP3 = "";
                    datos.PotenciaP4 = "";
                    datos.PotenciaP5 = "";
                    datos.PotenciaP6 = "";
                }

                if (datos.PotenciaP1 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP1disabled = false;
                }
                if (datos.PotenciaP3 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP3disabled = false;
                }

                $scope.campoPotenciaP2disabled = true;
                $scope.campoPotenciaP4disabled = true;
                $scope.campoPotenciaP5disabled = true;
                $scope.campoPotenciaP6disabled = true;

                //Hago obligatorio el campo a editar
                document.getElementById("PotenciaP1").required = true;
                document.getElementById("PotenciaP2").required = false;
                document.getElementById("PotenciaP3").required = true;
                document.getElementById("PotenciaP4").required = false;
                document.getElementById("PotenciaP5").required = false;
                document.getElementById("PotenciaP6").required = false;

                if ($scope.MostrarAltaConsumoAnualPeriodos) {

                    $scope.ConsumoEstimadoP4 = 0;
                    $scope.ConsumoEstimadoP5 = 0;
                    $scope.ConsumoEstimadoP6 = 0;

                    $scope.actualizarConsumoEstimadoP($scope.ConsumoEstimadoP1, $scope.ConsumoEstimadoP2, $scope.ConsumoEstimadoP3, 0, 0, 0);

                    $scope.ConsumoEstimadoP1Disabled = false;
                    $scope.ConsumoEstimadoP2Disabled = false;
                    $scope.ConsumoEstimadoP3Disabled = false;
                    $scope.ConsumoEstimadoP4Disabled = true;
                    $scope.ConsumoEstimadoP5Disabled = true;
                    $scope.ConsumoEstimadoP6Disabled = true;
                }

                break;

            case "3.0TD":
            case "6.1TD":
            case "6.2TD":
            case "6.3TD":
            case "6.4TD":
            case "3.0TDVE":
            case "6.1TDVE":

                $scope.ocultarPotencia1 = false;
                $scope.ocultarPotencia2 = false;
                $scope.ocultarPotencia3 = false;
                $scope.ocultarPotencia4 = false;
                $scope.ocultarPotencia5 = false;
                $scope.ocultarPotencia6 = false;

                if (!$scope.variablePruebaPotencias) {
                    datos.PotenciaP1 = "";
                    datos.PotenciaP2 = "";
                    datos.PotenciaP3 = "";
                    datos.PotenciaP4 = "";
                    datos.PotenciaP5 = "";
                    datos.PotenciaP6 = "";
                }

                if (datos.PotenciaP1 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP1disabled = false;
                }
                if (datos.PotenciaP2 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP2disabled = false;
                }
                if (datos.PotenciaP3 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP3disabled = false;
                }
                if (datos.PotenciaP4 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP4disabled = false;
                }
                if (datos.PotenciaP5 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP5disabled = false;
                }
                if (datos.PotenciaP6 == "" || $scope.PermitirEditarPotenciasDeSips) {
                    $scope.campoPotenciaP6disabled = false;
                }

                //Hago obligatorio el campo a editar
                document.getElementById("PotenciaP1").required = true;
                document.getElementById("PotenciaP2").required = true;
                document.getElementById("PotenciaP3").required = true;
                document.getElementById("PotenciaP4").required = true;
                document.getElementById("PotenciaP5").required = true;
                document.getElementById("PotenciaP6").required = true;


                if ($scope.MostrarAltaConsumoAnualPeriodos) {

                    $scope.actualizarConsumoEstimadoP($scope.ConsumoEstimadoP1, $scope.ConsumoEstimadoP2, $scope.ConsumoEstimadoP3, $scope.ConsumoEstimadoP4, $scope.ConsumoEstimadoP5, $scope.ConsumoEstimadoP6);

                    $scope.ConsumoEstimadoP1Disabled = false;
                    $scope.ConsumoEstimadoP2Disabled = false;
                    $scope.ConsumoEstimadoP3Disabled = false;
                    $scope.ConsumoEstimadoP4Disabled = false;
                    $scope.ConsumoEstimadoP5Disabled = false;
                    $scope.ConsumoEstimadoP6Disabled = false;
                }
                break;
        }

        // CNAE automático para tarifas 3.1 y 3.2 en MET
        if (nombreEmpresa.localeCompare('MET') === 0 && !$scope.Cnae && (textoTarifa == '3.1' || textoTarifa == '3.2')) {
            $scope.Cnae = '9820';
        }

        $scope.checkFechaEfectoDomestico();
        $scope.variablePruebaPotencias = false;
    };

    $scope.updateFeeValue = function (datos, tipo) {

        $scope.fee = datos;

        if (tipo == 'especial') {

            // console.log($scope.arrayPreciosPotenciaEnergia);

            // console.log($scope.MostrarPrecioFEEMinimo);
            // console.log($scope.MostrarPrecioFEEMaximo);
            // console.log("PorcentajesComision");
            // console.log($scope.PorcentajesComision);


            var flag = 0;
            // console.log($scope.PorcentajesComision);
            $scope.PorcentajesComision.forEach(function (item) {

                if ($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]) {
                    // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
                    $scope.CalculoComision = (($scope.consumoAnual * datos) / 1000) * (item["PorcentajeComision"] / 100);
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                    // console.log($scope.CalculoComision);
                    flag = 1;
                }
            });
            if (flag == 0) { $scope.CalculoComision = "Datos no válidos"; }
        } else if (tipo == 'noespecial') {
            var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
            // console.log(valores);
            if (valores != undefined) {
                if (valores.TipoAgenteParametro == 5) {
                    //    console.log("Entro al 5");
                    $scope.CalculoComision = $scope.consumoAnual * valores.Valor;
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                    // console.log($scope.CalculoComision);
                } else if (valores.TipoAgenteParametro == 10) {
                    //Desarrollo Luxam/Madrid
                    if (IndexadoOculta) {
                        $scope.CalculoComision = (($scope.consumoAnual * datos) / 1000);
                    } else {
                        $scope.CalculoComision = (($scope.consumoAnual * datos) / 1000) * (valores.Valor / 100);
                    }

                    //    console.log("Entro al 10");
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                }
            }
        }

        //COMISIONES GAS ELEIA

        if (tipo == 360 && $scope.altaContratoForm.mtf.$viewValue >= 0 && $scope.altaContratoForm.mtv.$viewValue >= 0) {

            //Ejemplo calculo = (3€/mes *12 meses) + (5€/MWh* 10050KWh/1000) = 36 + 50.25 = 86.25
            $scope.comisionTotalGas = ($scope.altaContratoForm.mtf.$viewValue * 12) + ($scope.altaContratoForm.mtv.$viewValue * $scope.consumoAnual / 1000);

            $scope.comisionTotalGasAgente = $scope.comisionTotalGas * 0.70;
            $scope.comisionTotalGas = $scope.comisionTotalGas.toFixed(2);
            $scope.comisionTotalGasAgente = $scope.comisionTotalGasAgente.toFixed(2);

        }

        if (tipo == "no360") {

            $http({
                method: "POST",
                url: urlApi + "Tarifa/GetComisionProductoFijo",
                data: JSON.stringify({ "Tarifa": $scope.textoTarifaEleia, "Producto": $scope.TextoTarifaGrupo, "ConsumoEstimado": $scope.consumoSIPS }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);
                $scope.comisionTotalGas = playloadJson.Comision;
                $scope.comisionTotalGasAgente = $scope.comisionTotalGas * 0.70;

                $scope.comisionTotalGas = $scope.comisionTotalGas.toFixed(2);
                $scope.comisionTotalGasAgente = $scope.comisionTotalGasAgente.toFixed(2);
            });


        }



    }

    // TODO: FEE[potencia][energia] POR PERIODOS
    $scope.updateFeeValuePorPeriodos = function (datos, tipo) {

        // $scope.fee = datos;
        // console.log("tipo");
        // console.log(tipo);
        // console.log($scope.Tarifa);
        // console.log("$scope.TarifaAccesoElectricidad.$viewValue");
        // console.log($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue);

        // En caso de que no encuentre información del CUPS en el SIPS lo tomamos de la seleccion del input
        // if($scope.Tarifa == undefined && $scope.altaContratoForm.TarifaAccesoElectricidad){
        if ($scope.altaContratoForm.TarifaAccesoElectricidad) {
            var objeto = JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue);
            // var TextoTarifaAcceso = objeto.TextoTarifa.replace(" ML", "");
            $scope.Tarifa = objeto.TextoTarifa;
            // var TarifaGrupo = $scope.altaContratoForm.GrupoTarifa.$viewValue.TextoTarifaGrupo;
            // console.log(TextoTarifaAcceso);
            // console.log(Tarifa);
            // console.log(TarifaGrupo);
        }

        if (tipo == 'especial') {

            // console.log($scope.arrayPreciosPotenciaEnergia);

            // console.log($scope.MostrarPrecioFEEMinimo);
            // console.log($scope.MostrarPrecioFEEMaximo);
            // console.log("PorcentajesComision");
            // console.log($scope.PorcentajesComision);

            // console.log($scope.ConsumoEstimadoP1);
            // console.log($scope.ConsumoEstimadoP2);
            // console.log($scope.ConsumoEstimadoP3);
            // console.log($scope.ConsumoEstimadoP4);
            // console.log($scope.ConsumoEstimadoP5);
            // console.log($scope.ConsumoEstimadoP6);

            $scope.CalculoComisionEnergia = 0;
            $scope.CalculoComisionPotencia = 0;


            $scope.arrayPreciosPotenciaEnergia.forEach(function (item, i) {
                // console.log($scope.CalculoComision);
                // console.log(item.PrecioE);
                // console.log($scope["ConsumoEstimadoP"+(i+1)]);
                // console.log(item.PrecioE + ($scope.Efees[i]/1000));
                // CalculoComisionEnergia  = consumo estimado Px * precio energia Px + fee
                // $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+1)] * (item.PrecioE + ($scope.Efees[i]/1000) )) + $scope.CalculoComisionEnergia;

                // Asignamos 0 al fee potencia  en las siguientes tarifas ya que sige solo factura un periodo y asi lo han pedido
                if (($scope.Tarifa == '2.0DHA ML' || $scope.Tarifa == '2.1DHA ML' || $scope.Tarifa == '2.0DHS ML' || $scope.Tarifa == '2.1DHS ML') && i == 1) {
                    // $scope.Pfees[i] = $scope.Pfees[i-1];
                    $scope.Pfees[i] = 0;
                }

                // PARA los  2.1 Y 2.0 se va a la posicion 2 del array de los consumos
                if ($scope.Tarifa == '2.1A ML' || $scope.Tarifa == '2.0A ML') {

                    $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP" + (i + 2)] * ($scope.Efees[i] / 1000)) + $scope.CalculoComisionEnergia;
                    $scope.CalculoComisionPotencia = (parseFloat($scope.altaContratoForm["PotenciaP" + (i + 2)].$viewValue) * $scope.Pfees[i]) + $scope.CalculoComisionPotencia;

                } else {
                    // console.log($scope["ConsumoEstimadoP"+(i+1)]);
                    if (($scope.Tarifa == '2.0DHA ML' || $scope.Tarifa == '2.1DHA ML' || $scope.Tarifa == '2.0DHS ML' || $scope.Tarifa == '2.1DHS ML') && i == 1) {
                        $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP" + (i + 2)] * ($scope.Efees[i] / 1000)) + $scope.CalculoComisionEnergia;
                    } else {
                        $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP" + (i + 1)] * ($scope.Efees[i] / 1000)) + $scope.CalculoComisionEnergia;
                    }
                    console.log("$scope.altaContratoForm['PotenciaP'+(i+1)]" + i);
                    if (($scope.Tarifa == '2.0TD ML') && i == 1) {
                        $scope.CalculoComisionPotencia = (0 * $scope.Pfees[i]) + $scope.CalculoComisionPotencia;
                    } else {
                        $scope.CalculoComisionPotencia = ($scope.altaContratoForm["PotenciaP" + (i + 1)].$viewValue * $scope.Pfees[i]) + $scope.CalculoComisionPotencia;
                    }
                }

                // TODO: COMO ESTABA ANTES
                // if($scope.Tarifa == '2.1A ML' || $scope.Tarifa == '2.0A ML'){

                //     $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+2)] * (item.PrecioE + (($scope.Efees[i] * $scope.impuestoExtraPrecioEnergia)/1000) )) + $scope.CalculoComisionEnergia;
                //     $scope.CalculoComisionPotencia = ($scope.altaContratoForm["PotenciaP"+(i+1)].$viewValue * (item.PrecioP + ($scope.Pfees[i]/365) )) + $scope.CalculoComisionPotencia;

                // }else{

                //     $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+1)] * (item.PrecioE + (($scope.Efees[i] * $scope.impuestoExtraPrecioEnergia)/1000) )) + $scope.CalculoComisionEnergia;
                //     $scope.CalculoComisionPotencia = ($scope.altaContratoForm["PotenciaP"+(i+1)].$viewValue * (item.PrecioP + ($scope.Pfees[i]/365) )) + $scope.CalculoComisionPotencia;

                // }


                // console.log($scope.CalculoComisionPotencia );

                // console.log($scope.CalculoComision);

                // console.log(($scope["ConsumoEstimadoP"+(i+1)] * item.PrecioE))
                // console.log("------------------------");


            });


            // console.log($scope.PorcentajesComision);


            // console.log($scope.CalculoComisionPotencia);
            // console.log($scope.PorcentajesComision[0].PorcentajeComision);

            // TODO: EN CASO DEL FUTURO -> PORCENTAJE DINAMICO
            // $scope.PorcentajeComisionFijo = $scope.PorcentajesComision[?].PorcentajeComision

            // console.log($scope.CalculoComisionEnergia);
            // console.log($scope.CalculoComisionPotencia);

            if ($scope.PorcentajeComision == 0 && $scope.PorcentajesComision[0] && $scope.PorcentajesComision[0].PorcentajeComision) {
                $scope.PorcentajeComision = $scope.PorcentajesComision[0].PorcentajeComision;
            }
            // console.log("$scope.PorcentajeComision" + $scope.PorcentajeComision);

            // console.log("#" + ($scope.PorcentajeComisionFijo/100) + "#");

            // console.log("$scope.PorcentajeComision");
            // console.log($scope.PorcentajeComision);

            $scope.CalculoComisionEnergia = $scope.CalculoComisionEnergia * ($scope.PorcentajeComision / 100) // dividimos para 100 para sacar el porcentaje
            $scope.CalculoComisionPotencia = $scope.CalculoComisionPotencia * ($scope.PorcentajeComision / 100) // dividimos para 100 para sacar el porcentaje
            // $scope.CalculoComisionEnergia = $scope.CalculoComisionEnergia * $scope.PorcentajeComisionFijo; TODO: Porcentaje fijo, como antes
            // $scope.CalculoComisionPotencia = $scope.CalculoComisionPotencia * $scope.PorcentajeComisionFijo; TODO: Porcentaje fijo, como antes

            // console.log("$scope.CalculoComisionEnergia");
            // console.log($scope.CalculoComisionEnergia);
            // console.log("$scope.CalculoComisionPotencia");
            // console.log($scope.CalculoComisionPotencia);



            $scope.CalculoComision = $scope.CalculoComisionEnergia + $scope.CalculoComisionPotencia;

            $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
            $scope.CalculoComision = $scope.CalculoComision + "€";
            $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");


            // COMO ESTABA ANTES EN ELEIA COMPROBANDO EL RANG DE FEE ENTRE MIN Y MAX
            var flag = 0;
            // console.log($scope.PorcentajesComision);
            // $scope.PorcentajesComision.forEach(function(item){

            //     if($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]){
            //         // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
            //         $scope.CalculoComision = (($scope.consumoAnual*datos)/1000)*(item["PorcentajeComision"]/100);
            //         $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
            //         $scope.CalculoComision = $scope.CalculoComision + "€";
            //         $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
            //         // console.log($scope.CalculoComision);
            //         flag = 1;
            //     }
            // });

            // if(flag == 0){$scope.CalculoComision = "Datos no válidos";}

        } else if (tipo == 'noespecial') {
            var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
            // console.log(valores);
            if (valores != undefined) {
                if (valores.TipoAgenteParametro == 5) {
                    //    console.log("Entro al 5");
                    $scope.CalculoComision = $scope.consumoAnual * valores.Valor;
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                    // console.log($scope.CalculoComision);
                } else if (valores.TipoAgenteParametro == 10) {
                    //    console.log("Entro al 10");
                    //EJemplo de la cuenta que se produce: ((6609 * 9)/1000) * (50/100) = 29,74€
                    $scope.CalculoComision = (($scope.consumoAnual * datos) / 1000) * (valores.Valor / 100);
                    $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                    $scope.CalculoComision = $scope.CalculoComision + "€";
                    $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                }
            }
        }

    }

    $scope.asignarBonoPorTarifaGrupo = function (datos) {
       $scope.ColeccionBonos.forEach(function(bono) {
        if(bono.IdTarifaGrupo == datos){
            console.log('eureca');
            console.log(bono.Descripcion);
            $scope.ValorDefectoBonoPorTarifaGrupo = bono.Descripcion;
        }
       })
    }


    $scope.updateGrupoTarifa = function (datos) {

        $scope.ValorDefectoBonoPorTarifaGrupo = '';
        $scope.valorBono='';
        $scope.TextoTarifaGrupo = datos.TextoTarifaGrupo;

        // var id = datos.split('-');
        // var IdTarifaGrupo = id[0].trim();
        // $scope.GrupoTarifa = IdTarifaGrupo;
        // $scope.PerfilFacturacion = id[2];
        // console.log(datos);
        // var grupoTarifaSeleccionado = JSON.parse(datos);
        var grupoTarifaSeleccionado = datos;
        $scope.GrupoTarifa = angular.copy(datos.IdTarifaGrupo);
        if (IsSistemaBonoActivo){
            $scope.asignarBonoPorTarifaGrupo($scope.GrupoTarifa);
        }

        $scope.PerfilFacturacion = angular.copy(datos.TextoPerfilFacturacion);
        // console.log($scope.PerfilFacturacion);
		//Cargar Tipo Alquiler si MostrarContratoTipoAlquiler = true
		if(MostrarContratoTipoAlquiler){
			$http({
                method: "GET",
                url: urlApi + "Producto/MostrarTipoAlquiler/IdPerfilFacturacion/" + datos.IdPerfilFacturacion,
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
				var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);


                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]

                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {
					$scope.MostrarTipoAlquiler = playloadJson.EsAlquilerEquipo;
                } else {
                    $scope.PreciosFijosVigentes = "";
                    console.error("HASH DE VERIFICACION INCORRECTO");
                }
			});
		}
        // Para cargar solo los modelo impresion fijos o indexados en base al producto elegido
        if ($scope.asignacionModelosImpresionPorNombreProducto) {

            ($scope.ModelosContratoTemporal.length > 0) ? $scope.ModelosContrato = $scope.ModelosContratoTemporal : $scope.ModelosContratoTemporal = $scope.ModelosContrato;

            var auxModelosContrato = [];

            // cargamos en un array auxiliar los mdelos de impresion fijo o indexado en base al nombre de la tarifa
            for (var index = 0; index < $scope.ModelosContrato.length; index++) {

                if (
                    ($scope.ModelosContrato[index].DescripcionModeloDeImpresion.toLowerCase().includes("fijo") && $scope.TextoTarifaGrupo.toLowerCase().includes("fijo")) ||
                    ($scope.ModelosContrato[index].DescripcionModeloDeImpresion.toLowerCase().includes("fijo") && $scope.TextoTarifaGrupo.toLowerCase().includes("justa")) ||
                    ($scope.ModelosContrato[index].DescripcionModeloDeImpresion.toLowerCase().includes("fijo") && $scope.TextoTarifaGrupo.toLowerCase().includes("pyme")) ||
                    ($scope.ModelosContrato[index].DescripcionModeloDeImpresion.toLowerCase().includes("index") && $scope.TextoTarifaGrupo.toLowerCase().includes("index"))
                ) {
                    auxModelosContrato.push($scope.ModelosContrato[index]);
                }

            }

            // En caso de que no coincida con ningun indexo fijo muestra todos los modelos de impresion
            (auxModelosContrato.length > 0) ? $scope.ModelosContrato = auxModelosContrato : null;

        }

        // TOTAL - Comisión y CO
        if (MostrarPreciosFijosVigentes) {

            $http({
                method: "GET",
                url: urlApi + "TarifaPeaje/GetDTOAllPreciosFijosVigentes/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/TarifaGrupo/" + $scope.GrupoTarifa,
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);


                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]

                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {
                    $scope.PreciosFijosVigentes = playloadJson;
                } else {
                    $scope.PreciosFijosVigentes = "";
                    console.error("HASH DE VERIFICACION INCORRECTO");
                }

                if ($scope.PreciosFijosVigentes.length > 0) {
                    $scope.PrecioTarifaExistente = false;
                    $scope.ArrayIdsIndexado = [];
                    $scope.ArrayIdsFijos = [];

                    if ($scope.PreciosFijosVigentes[0].IsIndexado) {
                        $.grep($scope.PreciosFijosVigentes, function (n, i) {
                            if (n.IdIndexadoPrecio != 0) {
                                $scope.ArrayIdsIndexado.push(n.IdIndexadoPrecio);
                            }
                        });
                    } else {
                        $.grep($scope.PreciosFijosVigentes, function (n, i) {
                            if (n.IdTarifaPrecio != 0) {
                                $scope.ArrayIdsFijos.push(n.IdTarifaPrecio);
                            }
                        });
                    }
                } else {
                    // modal con mensaje de que no deje dar el alta.
                    $scope.PrecioTarifaExistente = true;
                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(false)
                            .textContent('No se han encontrado precios para este grupo tarifa. No se permitirá dar el alta.')
                            .ariaLabel('Error').ok('Aceptar')
                    );
                }

                /**
                 * Controlo que no sea NaN, evitamos una llamada si es NaN
                 */
                if (!isNaN(Math.round($scope.consumoAnual))) {
                    $http({
                        method: "GET",
                        url: urlApi + "AgenteComision/GetDTOAllAgentesEscaladoComision/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Agente/" + IdAgente + "/TarifaGrupo/" + $scope.PreciosFijosVigentes[0].IdTarifaGrupo + "/Tarifa/" + $scope.PreciosFijosVigentes[0].IdTarifa + "/ConsumoAnual/" + Math.round($scope.consumoAnual),
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            Authorization: atob(localStorage.getItem("TokenValidador"))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);

                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"] });
                        var tokenExpirado = jwtHelper.isTokenExpired(respuesta);

                        if (tokenValido && !tokenExpirado) {
                            $scope.CalculoComision = playloadJson;
                        } else {
                            $scope.CalculoComision = "";
                            console.error("HASH DE VERIFICACION INCORRECTO");
                        }
                    });
                }
            });

            $scope.buscarRangosCO();
        }

        // TABLA PRECIOS ELEIA
        if (MostrarAltaTablaPreciosTarifas) {
            $scope.CalculoComision = 0;
            /* *************** Llamada para sacar ESPECIAL/NOESPECIAL - INDEXADO/NOINDEXADO ************** */
            $http({
                method: "POST",
                url: urlApi + "Tarifa/GetDatosEsEspecialIndexado",
                data: JSON.stringify({ "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {

                    // $scope.muestraocultaprecio = true;

                    // Variables temporales para pruebas mientras se termina la llamada de API
                    $scope.especial = playloadJson[0].GrupoPrecioPropio;
                    $scope.precioIndexado = playloadJson[0].EsPrecioIndexado;

                    console.log("Especial: " + $scope.especial);
                    console.log("Precio indexado: " + $scope.precioIndexado);

                    // TODO: ASIGNACION DE MODELOS DE IMPRESION DE ELIA
                    if ($scope.asignacionModelosImpresionEleia) {
                        // CONTROLAMOS EL TIPO DE PRODUCTO INDEXADO / FIJO -> PARA ASI PODER ASIGNARLE UN MODELO DE CONTRATO DE IMPRESION
                        ($scope.precioIndexado) ? $scope.tipoModeloImpresion = "Indexado" : $scope.tipoModeloImpresion = "Fijo";

                        $scope.ModelosContratoFiltrados = [];

                        // Solo cargara los modelos de impresion de contrato que contenga el texto: fijo o indexado para que posteriormente se puedan asignar en el alta
                        if ($scope.tipoModeloImpresion != "") {

                            $scope.ModelosContratoTemporal.forEach(function (item, i) {
                                if (item.DescripcionModeloDeImpresion.includes($scope.tipoModeloImpresion) || item.DescripcionModeloDeImpresion.includes("Contrato General")) {
                                    $scope.ModelosContratoFiltrados.push(item);
                                }
                            });
                        }

                        $scope.ModelosContrato = $scope.ModelosContratoFiltrados;
                    }


					// NO ESPECIAL Y PRECIO FIJO
					if (!$scope.especial && !$scope.precioIndexado) {
						$scope.CalculoComision = 0;
						$scope.MostrarPrecioEnergia = true;

						$http({
							method: "POST",
							url: urlApi + "Tarifa/GetDatosPrecioFijoNoEspecial",
							data: JSON.stringify({ "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo, "IdAgenteComision": IdAgente }),
							headers: {
								'Content-Type': 'application/json; charset=UTF-8',
								Authorization: atob(localStorage.getItem("TokenValidador"))
							}
						}).then(function successCallback(response) {
							var respuesta = response.data;
							var decodeToken = jwtHelper.decodeToken(respuesta);
							var playloadJson = JSON.parse(decodeToken.iss);

							var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
								alg: ["HS256"]
							});

							if (tokenValido) {
								$scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
								$scope.tipo = 'noespecial';
								$scope.updateFeeValue($scope.fee, 'noespecial');
								// console.log("NO ESPECIAL Y PRECIO FIJO");
							} else {
								$scope.ColectivosRepresentante = "";
								console.error("HASH DE VERIFICACION INCORRECTO");
							}
						});

					} else {
						$scope.MostrarPrecioEnergia = false;
					}


					// ESPECIAL Y PRECIO INDEXADO
					if ($scope.especial && $scope.precioIndexado) {

						if (IndexadoOculta) {
							$scope.MostrarPrecioFEE = false;
						} else {
							$scope.MostrarPrecioFEE = true;
						}

						$scope.CalculoComision = 0;
						if ($scope.altaContratoForm.consumoSIPS.$viewValue != null) { LimpiarConsumoanual = $scope.altaContratoForm.consumoSIPS.$viewValue }

						$http({
							method: "POST",
							url: urlApi + "Tarifa/GetDatosPrecioIndexadoEspecial",
							data: JSON.stringify({ "IdAgente": IdAgente, "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo, "ConsumoAnualSIPS": Math.round(LimpiarConsumoanual) }),
							headers: {
								'Content-Type': 'application/json; charset=UTF-8',
								Authorization: atob(localStorage.getItem("TokenValidador"))
							}
						}).then(function successCallback(response) {
							var respuesta = response.data;
							var decodeToken = jwtHelper.decodeToken(respuesta);
							var playloadJson = JSON.parse(decodeToken.iss);
							// console.log(playloadJson);

							var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
								alg: ["HS256"]
							});



							if (tokenValido) {
								$scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
								// console.log($scope.arrayPreciosPotenciaEnergia);
								$scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
								$scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
								$scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
								$scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
								// console.log("ESPECIAL Y PRECIO INDEXADO");
								// console.log(playloadJson);

								$scope.fee = $scope.MostrarPrecioFEEMaximo;

								$scope.tipo = 'especial';
								$scope.updateFeeValue($scope.fee, 'especial');
								$scope.MostrarAltaInfoFeeMinMax = true;


							} else {
								$scope.ColectivosRepresentante = "";
								console.error("HASH DE VERIFICACION INCORRECTO");
							}
						});

					} else {
						$scope.MostrarPrecioFEE = false;
					}


					// NO ESPECIAL Y PRECIO INDEXADO
					if (!$scope.especial && $scope.precioIndexado) {

						if (IndexadoOculta) {
							$scope.MostrarPrecioFEE = false;
						} else {

							$scope.MostrarPrecioFEE = true;
						}
						$scope.CalculoComision = 0;

						$scope.feedisabled = true;

						$scope.MostrarAltaInfoFeeMinMax = false;

						$http({
							method: "POST",
							url: urlApi + "Tarifa/GetDatosPrecioIndexadoNoEspecial",
							data: JSON.stringify({ "IdAgenteComision": IdAgente, "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo }),
							headers: {
								'Content-Type': 'application/json; charset=UTF-8',
								Authorization: atob(localStorage.getItem("TokenValidador"))
							}
						}).then(function successCallback(response) {
							var respuesta = response.data;
							var decodeToken = jwtHelper.decodeToken(respuesta);
							var playloadJson = JSON.parse(decodeToken.iss);

							var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
								alg: ["HS256"]
							});

							if (tokenValido) {
								$scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
								if (IndexadoOculta) {
									$scope.fee = playloadJson[0].newFEE;
								} else {

									$scope.fee = playloadJson[0].datoFEE;
								}

								$scope.tipo = 'noespecial';
								$scope.updateFeeValue($scope.fee, 'noespecial');
								// console.log("NO ESPECIAL Y PRECIO INDEXADO");
								// console.log(playloadJson);
							} else {
								$scope.ColectivosRepresentante = "";
								console.error("HASH DE VERIFICACION INCORRECTO");
							}
						});


					} else {
						$scope.feedisabled = false;
					}



					// ESPECIAL Y PRECIO FIJO
					if ($scope.especial && !$scope.precioIndexado) {

						$scope.CalculoComision = 0;
						$scope.MostrarPrecioEnergia = true;
						$scope.MostrarPrecioFEE = true;
						if ($scope.altaContratoForm.consumoSIPS.$viewValue != null) { LimpiarConsumoanual = $scope.altaContratoForm.consumoSIPS.$viewValue }

						$http({
							method: "POST",
							url: urlApi + "Tarifa/GetDatosPrecioFijoEspecial",
							data: JSON.stringify({
								"IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo, "IdAgenteComision": IdAgente,
								"IdAgente": IdAgente, "ConsumoAnualSIPS": Math.round(LimpiarConsumoanual)
							}),
							headers: {
								'Content-Type': 'application/json; charset=UTF-8',
								Authorization: atob(localStorage.getItem("TokenValidador"))
							}
						}).then(function successCallback(response) {
							var respuesta = response.data;
							var decodeToken = jwtHelper.decodeToken(respuesta);
							var playloadJson = JSON.parse(decodeToken.iss);

							var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
								alg: ["HS256"]
							});

							if (tokenValido) {

								$scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
								// console.log("ESPECIAL Y PRECIO FIJO");
								// console.log(playloadJson);

								$scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
								$scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
								$scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
								$scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
								// console.log($scope.PorcentajesComision);

								$scope.fee = $scope.MostrarPrecioFEEMaximo;

								if ($scope.RequerirFeeManual) {
									$scope.fee = '';
								}
								$scope.tipo = 'especial';
								$scope.updateFeeValue($scope.fee, 'especial');
								$scope.MostrarAltaInfoFeeMinMax = true;

								//TODO: MostrarAltaContratoTantosFeesComoPeriodos -> VAR: TRUE  | CLIENTES:[A.MADRID,...]
								// METEMOS VALORES FIJOS PARA LOS CLIENTES QUE TENGAN A TRUE MostrarAltaContratoTantosFeesComoPeriodos EJ[ANTONIO MADRID,...]
								if ($scope.MostrarAltaContratoTantosFeesComoPeriodos) {
									// $scope.MostrarPrecioFEEMinimo = 0;
									// $scope.MostrarPrecioFEEMaximo = 30;

									// Recorremos el array de precios para insertar por cada periodo el valor maximo del fee a los arrays de fee potencia y energia
									angular.forEach($scope.arrayPreciosPotenciaEnergia, function (value, key) {
										$scope.Pfees.push($scope.MostrarPrecioFEEMaximo);
										$scope.Efees.push($scope.MostrarPrecioFEEMaximo);
									});

									// CALCULAMOS LA COMISION
									$scope.updateFeeValuePorPeriodos($scope.fee, 'especial');

								}


							} else {
								$scope.ColectivosRepresentante = "";
								console.error("HASH DE VERIFICACION INCORRECTO");
							}
						});


					} else {
						// $scope.MostrarPrecioEnergia = false;
						// $scope.MostrarPrecioFEE = false;
					}
                    //COMISIONES GAS DE ELEIA
					if (EleiaComisionGasMTFyMTV && $scope.VerGas) {

                        if (playloadJson[0].is360 == true) {
                            //muestra los campos para introducir MTV y MTF
                            $scope.Es360 = true;
                        } else {
                            $scope.Es360 = false;
                            $scope.altaContratoForm.mtf.$viewValue = 0;
                            $scope.altaContratoForm.mtv.$viewValue = 0;
                            $scope.updateFeeValue(playloadJson[0].ComisionGas, 'no360');
                        }

                    }

                } else {
                    $scope.GruposTarifa = "";
                    console.error("HASH DE VERIFICACION INCORRECTO");
                }

            }); // end then

        } // end if ELEIA

    };

    // $scope.inicializaCeroConsumosPorPeriodos = function (){
    //     console.log("llego");
    //     $scope.ConsumoEstimadoP1 = 0;
    //     $scope.ConsumoEstimadoP2 = 0;
    //     $scope.ConsumoEstimadoP3 = 0;
    //     $scope.ConsumoEstimadoP4 = 0;
    //     $scope.ConsumoEstimadoP5 = 0;
    //     $scope.ConsumoEstimadoP6 = 0;
    // }

    Math.fmod = function (a, b) {
        return Number((a - Math.floor(a / b) * b).toPrecision(8));
    };

    $scope.compruebaCups = function (dato) {
        if (dato != null) {

            var empieceCups = dato.substring(0, 2);

            if (dato.length == 20 || dato.length == 22) {

                if (($scope.ValidacionCupsEmpieceXES && empieceCups == "ES") || !$scope.ValidacionCupsEmpieceXES) {
                    serviciosApi.getSips(dato.toUpperCase(), "", "", "", "", "", "")
                        .then(function successCallback(response) {
                            var respuestaSips = response;

                            if ((dato != null) || (dato != undefined)) {
                                var cupsAcortado = dato.substring(2, 8);

                                if (cupsAcortado == "003110") {
                                    $scope.Distribuidora = "0023";
                                } else if (cupsAcortado == "003130") {
                                    $scope.Distribuidora = "0029";
                                } else if (cupsAcortado == "003144") {
                                    $scope.Distribuidora = "0396";
                                } else if (cupsAcortado == "003150") {
                                    $scope.Distribuidora = "0288";
                                } else if (cupsAcortado == "003160") {
                                    $scope.Distribuidora = "0363";
                                } else if (cupsAcortado == "003140") {
                                    if (respuestaSips.data.ClientesSips.length > 0) {
                                        if (respuestaSips.data.ClientesSips[0].CodigoPostalPS.startsWith("50")) {
                                            $scope.Distribuidora = "0029";
                                        } else {
                                            $scope.Distribuidora = "0024";
                                        }
                                    } else {
                                        $scope.Distribuidora = "0024";
                                    }
                                } else {
                                    $scope.Distribuidora = dato.substring(2, 6);
                                }
                            }
                        });

                    var data = {
                        "CodigoCUPS": dato.toUpperCase(),
                        "CodigoReeDistribuidora": $scope.Distribuidora,
                        "IdEmpresa": IdEmpresa,
                        "IdUsuario": IdUsuario,
                    }

                    $http({
                        method: "POST",
                        url: urlApi + "Verificacion/CUPS",
                        data: JSON.stringify(data),
                        headers: {
                            Authorization: atob(localStorage.getItem('TokenValidador'))
                        }
                    }).then(function (response) {
                        if (response.data == true) {
                            $scope.CupsCorrecto = true;
                            $scope.CupsIncorrecto = false;
                            $scope.LongitudIncorrecta = false;
                        } else {
                            $scope.CupsCorrecto = false;
                            $scope.CupsIncorrecto = true;
                            $scope.LongitudIncorrecta = false;
                        }
                    });

                } else {
                    $scope.CupsCorrecto = false;
                    $scope.CupsIncorrecto = true;
                    $scope.LongitudIncorrecta = false;
                }

            } else {
                if (dato.length < 20) {
                    $scope.CupsCorrecto = false;
                    $scope.CupsIncorrecto = false;
                    $scope.LongitudIncorrecta = true;
                }
            }
        }
    };

    $scope.compruebaIBAN = function (dato) {

        if ($scope.ValidacionRequeridaIban) {

            if (dato.IBANPagador != undefined) {

                var data = {
                    "IBAN": dato.IBANPagador.toUpperCase(),
                    "IdEmpresa": IdEmpresa,
                    "IdUsuario": IdUsuario,
                }

                // LLAMADA VALIDAR IBAN QUE SE PUEDE CAMBIAR DESDE SIGE R:TRUE/FALSE
                $http({
                    method: "POST",
                    url: urlApi + "Verificacion/IBANsinoValidar",
                    data: JSON.stringify(data),
                    headers: {
                        Authorization: atob(localStorage.getItem('TokenValidador'))
                    }
                }).then(function (response) {

                    // console.log(response.data);

                    // true -> no validar / false -> validar
                    if (!response) {

                        $http({
                            method: "POST",
                            url: urlApi + "Verificacion/IBAN",
                            data: JSON.stringify(data),
                            headers: {
                                Authorization: atob(localStorage.getItem('TokenValidador'))
                            }
                        }).then(function (response) {

                            var validacionIBANNumber = servicioValidaciones.isValidIBANNumber(dato.IBANPagador);

                            // ANTERIOR -> if (response.data == true) {
                            if (validacionIBANNumber) {
                                $scope.IBANCorrecto = true;
                                $scope.IBANIncorrecto = false;
                            } else {
                                $scope.IBANCorrecto = false;
                                $scope.IBANIncorrecto = true;
                            }
                        });

                    } else {
                        $scope.IBANCorrecto = true;
                        $scope.IBANIncorrecto = false;
                        $scope.ibanMaxlength = 99;
                        $scope.ibanMinlength = 1;
                    }

                }, function errorCallback(response) {

                    // Si la llamada no existe, realizara las validaciones en el servicio
                    if (response.status == 404) {

                        $http({
                            method: "POST",
                            url: urlApi + "Verificacion/IBAN",
                            data: JSON.stringify(data),
                            headers: {
                                Authorization: atob(localStorage.getItem('TokenValidador'))
                            }
                        }).then(function (response) {

                            var validacionIBANNumber = servicioValidaciones.isValidIBANNumber(dato.IBANPagador);


                            // ANTERIOR -> if (response.data == true) {
                            if (validacionIBANNumber) {
                                $scope.IBANCorrecto = true;
                                $scope.IBANIncorrecto = false;
                            } else {
                                $scope.IBANCorrecto = false;
                                $scope.IBANIncorrecto = true;
                            }

                        });

                    }
                });

            } else {

                $scope.IBANCorrecto = false;
                $scope.IBANIncorrecto = true;

            }
        }


    };

    $scope.compruebaIdentificador = function (dato) {
        var data = {
            "Identificador": dato.toUpperCase(),
            "IdEmpresa": IdEmpresa,
            "IdUsuario": IdUsuario,
        }

        $http({
            method: "POST",
            url: urlApi + "Verificacion/Identificador",
            data: JSON.stringify(data),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function (response) {
            if (response.data.length > 0) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                var evt = new CustomEvent("change");
                var nombre = document.getElementsByName("NombreTitular")[0];
                var apellido1 = document.getElementsByName("Apellido1Titular")[0];
                var apellido2 = document.getElementsByName("Apellido2Titular")[0];
                var razonSocial = document.getElementsByName("RazonSocialTitular")[0];
                checkIsRazonSocialValido = playloadJson.IsRazonSocialValido;
                $scope.comprobarRepresentante();

                var validacionIdentidad = servicioValidaciones.ValidateSpanishID(dato.toUpperCase());
                // console.log(validacionIdentidad);
                // console.log(checkPasaporte);
                // ANTIGUO POR API -> if (playloadJson.IsRazonSocialValido == true && playloadJson.IsIdentificadorPersonaValido == false && checkPasaporte == undefined || playloadJson.IsRazonSocialValido == true && playloadJson.IsIdentificadorPersonaValido == false && checkPasaporte == false) {
                if (validacionIdentidad.type == "cif" && validacionIdentidad.valid && (checkPasaporte == undefined || checkPasaporte == false)) {
                    //  Si es razón social
                    nombre.disabled = true;
                    apellido1.disabled = true;
                    apellido2.disabled = true;
                    razonSocial.disabled = false;

                    //Bloqueamos o desbloqueamos los campos
                    $scope.requiredContratoNombre = false;
                    $scope.requiredContratoApellido1 = false;
                    $scope.requiredContratoApellido2 = false;
                    $scope.requiredContratoRazonSocial = true;

                    document.getElementById("RequiredRazonSocial").innerHTML = 'Razón Social *';
                    document.getElementById("RequiredNombre").innerHTML = 'Nombre';
                    document.getElementById("RequiredApellido1").innerHTML = 'Apellido 1';
                    document.getElementById("RequiredApellido2").innerHTML = 'Apellido 2';

                    if ($scope.respuestaSIPS && $scope.respuestaSIPS.DatosTitular != null && $scope.respuestaSIPS.DatosTitular != undefined) {
                        razonSocial.value = $scope.respuestaSIPS.data.DatosTitular[0].NombreTitular;
                        razonSocial.dispatchEvent(evt);
                    }

                    if (nombre.value != "") {
                        nombre.value = "";
                        nombre.dispatchEvent(evt);
                        apellido1.value = "";
                        apellido1.dispatchEvent(evt);
                        apellido2.value = "";
                        apellido2.dispatchEvent(evt);
                    }

                    document.getElementById("RazonSocialTitular").focus();

                    // ANTIGUO POR API -> } else if (playloadJson.IsRazonSocialValido == false && playloadJson.IsIdentificadorPersonaValido == true || checkPasaporte == true) {
                } else if ((validacionIdentidad.type == "nie" || validacionIdentidad.type == "dni") && validacionIdentidad.valid || checkPasaporte == true) {

                    // Si es DNI
                    nombre.disabled = false;
                    apellido1.disabled = false;
                    apellido2.disabled = false;
                    razonSocial.disabled = true;

                    //Bloqueamos o desbloqueamos los campos
                    $scope.requiredContratoNombre = true;
                    $scope.requiredContratoApellido1 = true;
                    $scope.requiredContratoApellido2 = requiredContratoApellido2;
                    $scope.requiredContratoRazonSocial = false;

                    document.getElementById("RequiredRazonSocial").innerHTML = 'Razón Social';
                    document.getElementById("RequiredNombre").innerHTML = 'Nombre *';
                    document.getElementById("RequiredApellido1").innerHTML = 'Apellido 1 *';
                    if ($scope.requiredContratoApellido2) {
                        document.getElementById("RequiredApellido2").innerHTML = 'Apellido 2 *';
                    } else {
                        document.getElementById("RequiredApellido2").innerHTML = 'Apellido 2';
                    }

                    if (razonSocial.value != "") {
                        nombre.value = razonSocial.value;
                        nombre.dispatchEvent(evt);
                        razonSocial.value = "";
                        razonSocial.dispatchEvent(evt);
                    }

                    document.getElementById("NombreTitular").focus();
                    // } else if (playloadJson.IsRazonSocialValido == true && playloadJson.IsIdentificadorPersonaValido == true) {
                } else if (validacionIdentidad.valid) {

                    nombre.disabled = false;
                    apellido1.disabled = false;
                    apellido2.disabled = false;
                    razonSocial.disabled = false;
                    document.getElementById("RequiredRazonSocial").innerHTML = 'Razón Social';
                    document.getElementById("RequiredNombre").innerHTML = 'Nombre';
                    document.getElementById("RequiredApellido1").innerHTML = 'Apellido 1';
                    document.getElementById("RequiredApellido2").innerHTML = 'Apellido 2';

                    $scope.requiredContratoNombre = false;
                    $scope.requiredContratoApellido1 = false;
                    $scope.requiredContratoApellido2 = false;
                    $scope.requiredContratoRazonSocial = false;

                    document.getElementById("NombreTitular").focus();
                }

                // if (playloadJson.IsRazonSocialValido == true || playloadJson.IsIdentificadorPersonaValido == true  || checkPasaporte == true) {
                if (validacionIdentidad.valid || checkPasaporte == true) {
                    $scope.DniCorrecto = true;
                    $scope.DniIncorrecto = false;

                    if (dato.toUpperCase().charAt(0) === "H") {
                        $scope.Cnae = '9499';
                    }
                    /**
                     * SI EL IDENTIFICADOR ES CORRECTO, SI NO NO LANZAMOS NADA, MIRO SI EMPIEZA POR H PARA CARGAR EL CNAE
                     */

                } else {
                    $scope.DniCorrecto = false;
                    $scope.DniIncorrecto = true;
                }
            }
        });
    };

    $scope.compruebaIdentificadorPagador = function (dato) {
        var data = {
            "Identificador": dato.toUpperCase(),
            "IdEmpresa": IdEmpresa,
            "IdUsuario": IdUsuario,
        }

        $http({
            method: "POST",
            url: urlApi + "Verificacion/Identificador",
            data: JSON.stringify(data),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function (response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var validacionIdentidad = servicioValidaciones.ValidateSpanishID(dato.toUpperCase());

            // ANTERIOR -> if (playloadJson.IsRazonSocialValido == true || playloadJson.IsIdentificadorPersonaValido == true) {
            if (validacionIdentidad.valid) {
                $scope.DniPagadorCorrecto = true;
                $scope.DniPagadorIncorrecto = false;
            } else {
                $scope.DniPagadorCorrecto = false;
                $scope.DniPagadorIncorrecto = true;
            }
        });
    };

    $scope.compruebaIdentificadorRepresentante = function (dato) {
        var data = {
            "Identificador": dato.IdentificadorRepresentante.toUpperCase(),
            "IdEmpresa": IdEmpresa,
            "IdUsuario": IdUsuario,
        }

        $http({
            method: "POST",
            url: urlApi + "Verificacion/Identificador",
            data: JSON.stringify(data),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function (response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var validacionIdentidad = servicioValidaciones.ValidateSpanishID(dato.IdentificadorRepresentante.toUpperCase());
            // console.log(validacionIdentidad);
            if (validacionIdentidad.valid && (validacionIdentidad.type == "nie" || validacionIdentidad.type == "dni")) {

                $scope.DniRepresentanteCorrecto = true;
                $scope.DniRepresentanteIncorrecto = false;

                if ($scope.ObligatorioRepresentantePersonaFisica == true && validacionIdentidad.type == "cif") {
                    $scope.DniRepresentanteCorrecto = false;
                    $scope.DniRepresentanteIncorrecto = true;
                }

                // ASIGNACION AUTOMATICA DEL COLECTIVO DE PERSONA FISICA
                if ($scope.AutoAsignacionColectivoRepresentante == true && (validacionIdentidad.type == "nie" || validacionIdentidad.type == "dni")) {

                    var caracter = data.Identificador.substring(0, 1);

                    $scope.ValorDesplegableColectivo = !isNaN(caracter) ? "PERSONAS FISICAS" : (caracter == "Y" ? "EXTRANJEROS (Y)" : caracter == "X" ? "EXTRANJEROS (X)" : "");

                }


            } else {

                $scope.DniRepresentanteCorrecto = false;
                $scope.DniRepresentanteIncorrecto = true;
            }
        });
    };

    $scope.updateTipoCobro = function (datos) {
        //Recojo los tipos de cobro, de cuando lo cargo en el scope
        var TipoCobro = $scope.TiposCobro;
        var isRemesable;
        //hago un filtro, sobre todos los tipos de combro, con el texto de tipo de cobro que me pasan a la funcion
        $.grep(TipoCobro, function (n, i) {
            if (n.TextoTipoCobro == datos.TipoCobro) {
                //Me quedo unicamente con el campo de remesa (true o false) para ver si hay que habilitar el campo IBAN
                var isRemesable = n.Remesa;
                //Utilizamos la variable isRemesable para activar o desactivar
                if (isRemesable) {
                    document.getElementById("IBANPagador").disabled = false;
                    if ($scope.requiredOtrosIBAN)
                        document.getElementById("RequiredIBAN").innerHTML = "IBAN *";
                } else {
                    document.getElementById("IBANPagador").disabled = true;
                    document.getElementById("RequiredIBAN").innerHTML = "IBAN";
                }
            }
        });
    };
    $scope.changeFechaFirma = function (dato) {
        $scope.FechaFirma = moment(dato).toDate();
    }
    $scope.updateFechaFirma = function (dato) {
        $scope.SelectFechaFirma = dato;
        console.log(moment($scope.FechaFirma).format('YYYY/MM/DD'));
        if (dato == '1') {
            $scope.mostrarFechasDisa = false;
            if ($scope.FechaFirma === undefined) {
                $scope.FechaFirma = moment().toDate();
            }
        } else {
            $scope.mostrarFechasDisa = true;
        }
    };

    $scope.updateFechaEfecto = function (datos) {
        var FechasEfecto = $scope.FechasEfecto;
        $.grep(FechasEfecto, function (n, i) {
            if (n.IdSolicitudTipoFechaEfecto == datos) {
                $scope.MostrarActivacionPrevista = n.FechaRequerida;
                // Si la FechaEfecto requiere fecha se activa fecha ActivaciónPrevista
                if (!n.FechaRequerida) {
                    // $scope.ActivacionPrevista = ''; //Se pasa esta parte al body de alta con un if ternario controlado por esta variable '$scope.ActivacionPrevistaActivo'
                    $scope.ActivacionPrevistaActivo = false;
                } else {
                    // $scope.ActivacionPrevista = moment().toDate();
                    // $scope.ActivacionPrevista = $scope.manana; //Se cambia y se coge el valor del cambio en vez de la suma de 7 a partir del día actual 'incidencia WEB-3091'
                    $scope.ActivacionPrevistaActivo = true;
                }
            }
        });
    };

    $scope.TipoSolicitudChangue = function (datos) {
        if (MostrarMotivoModificacion) {
            console.log(datos);
            if (datos == "JZ" || datos == "MZ") {
                this.MotivoModificacion = 2;
            } else {
                this.MotivoModificacion = null;
            }
            console.log(this.MotivoModificacion);
        } else {

        }
    }

    $scope.updateProducto = function (datos) {
        $http({
            method: "GET",
            url: urlApi + "Producto/GetAllProducto/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/IdProductoGrupo/" + datos.GrupoProducto,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function (response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]

            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.productos = playloadJson;
            } else {
                $scope.productos = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }
        });
    };

    // En base a si es particular o empresa quitamos opciones a elegir de las tarifas
    $scope.updateTarifasEnbaseParticularEmpresa = function (tipo) {

        if ($scope.MostrarParticularOEmpresaFiltroTarifas) {

            if (tipo == "particular") {
                $.each($scope.TarifasAcceso, function (i) {
                    if ($scope.TarifasAcceso[i].TextoTarifa === "2.0A ML") {
                        $scope.TarifasAccesoAux = $scope.TarifasAcceso.splice(i, 1);
                        return false;
                    }
                });
                // console.log("particular");
            }
            if (tipo == "empresa") {
                ($scope.TarifasAccesoAux != undefined && $scope.TarifasAccesoAux.length > 0) ? $scope.TarifasAcceso.push($scope.TarifasAccesoAux[0]) : null;
                $scope.TarifasAccesoAux.shift();
                // console.log("empresa");
            }
        }

    };


    $scope.cargarProductoTipoImpresion = function (tipoImpresion) {
        /**
         * Se guarda el producto papel cuando no es ROCK AF y se ha seleccionado en el desplegable papel
         * Mostrar texto "Coste adicional 0,03€/dia"
         * El cargar el producto lo hago en el insert, si lo haces aqui y da error el alta, por ejemplo, CUPS duplicado, se borraba y se pasaba en blanco.
         */
        $scope.TipoImpresion = tipoImpresion;
        if ($scope.nombreEmpresa == "Total") {

            if (tipoImpresion == "P") {
                if ($scope.GrupoTarifa != "718" &&
                    $scope.GrupoTarifa != "719" &&
                    $scope.GrupoTarifa != "720" &&
                    $scope.GrupoTarifa != "721" &&
                    $scope.GrupoTarifa != "722" &&
                    $scope.GrupoTarifa != "723" &&
                    $scope.GrupoTarifa != "724"
                ) {
                    $scope.AvisoImpresionPapel = true;
                }
            } else {
                $scope.Producto = "";
                $scope.AvisoImpresionPapel = false;
            }
        }
    }

    $scope.InsertarContrato = function () {

        $scope.MostrarBotonCargaContrato = !$scope.MostrarBotonCargaContrato;
        $scope.spinnerBotonCargaContrato = !$scope.spinnerBotonCargaContrato;

        var PerfilFacturacion = '';
        //Segun comenta Javier, es preferible pasa el PerfilFacturacion vacio que mandarlo mal
        var PerfilFacturacionPeaje = $scope.altaContratoForm.PerfilFacturacion.$viewValue;
        // var PerfilFacturacion = $scope.altaContratoForm.PerfilFacturacion.$viewValue.replace("ML ", "");
        var p2 = $scope.altaContratoForm.PotenciaP2.$viewValue;
        var p1 = $scope.altaContratoForm.PotenciaP1.$viewValue;

        var idModeloContrato = $scope.altaContratoForm.ModeloDeContrato.$viewValue;

        if ($scope.nombreEmpresaLogin == 'MET') {

            PerfilFacturacion = 'Perfil Factura Gas Distribuidora';
            $scope.altaContratoForm.PotenciaP2.$viewValue = $scope.altaContratoForm.PotenciaP1.$viewValue;
            p2 = p1;
            $scope.altaContratoForm.PotenciaP1.$viewValue = "";

            if ($scope.altaContratoForm.PotenciaP2.$viewValue == undefined || $scope.altaContratoForm.PotenciaP2.$viewValue == "") {
                $scope.altaContratoForm.PotenciaP2.$viewValue = "0";
            }
        }

        //Segun el formulario elegido, cargamos la variable TextoTarifaAcceso y Tarifa, para evitar el error de campo null
        if (atob(localStorage.getItem('tipoAlta')) == 'e' || atob(localStorage.getItem('tipoAlta')) == 'g') {
            var objeto = JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue);
            var TextoTarifaAcceso = objeto.TextoTarifa.replace(" ML", "");
            var Tarifa = objeto.TextoTarifa;
            var TarifaGrupo = $scope.altaContratoForm.GrupoTarifa.$viewValue.TextoTarifaGrupo;
            var P1 = $scope.altaContratoForm.PotenciaP1.$viewValue;
        }

        if (bonoCheckeado == "10") {
            segmentacion = "DISABONO1";
        } else if (bonoCheckeado == "11") {
            segmentacion = "DISABONO2";
        }

        if ($scope.altaContratoForm.ProductoElectricidad) {
            if ($scope.altaContratoForm.ProductoElectricidad.$viewValue != undefined) {
                $scope.Producto = $scope.altaContratoForm.ProductoElectricidad.$viewValue;
            }
        }
        if ($scope.altaContratoForm.ProductoGas) {
            if ($scope.altaContratoForm.ProductoGas.$viewValue != undefined) {
                $scope.Producto = $scope.altaContratoForm.ProductoGas.$viewValue;
            }
        }

        //TODO: Este control quitarlo cuando se admitan varios productos

        if ($scope.TipoImpresion != "P") {
            //Control CHECK Energia Verde TOTAL
            if (MostrarPreciosFijosVigentes == true && $scope.Producto === "") {
                if ($scope.altaContratoForm.energiaVerdeChecked.$viewValue == true) {
                    $scope.Producto = "Energía Verde";
                } else {
                    $scope.Producto = "";
                }
            }
        }

        var fecha = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        var comisionCalculada = document.getElementsByName("comisionCalculada")[0];
        /**
         * Coste de gestion solo para Enerplus
         */
        if (nombreEmpresa === "Enerplus") {
            var costeGestionSinComa = document.getElementsByName("CosteGestion")[0].value;
            $scope.costeGestion = costeGestionSinComa.replace(",", ".");
        }

        /**
         * Enerplus destino energia: check domestico
         */
        var destinoEnergia = false
        if ($scope.MostrarContratoAltaDomestico == true) {
            var destinoEnergia = ($scope.altaContratoForm.domestico.$viewValue === 'true');
        }

        var urlPrimariaVerificacion = urlApi + "ContratoPotencial/Verificar/";
        var urlPrimariaInsercion = ($scope.AnularAlta) ? urlApi + "ContratoPotencial/NOINSERTAR/" : urlApi + "ContratoPotencial/POST/"; // URL PARA INVALIDAR INSERCCIÓN MATENIENDO EL INTENTO Y ASI, SACAR EL JSON
        // var urlPrimariaInsercion = urlApi + "ContratoPotencial/POST/";

        if ($scope.altaContratoForm.Ciudad.$viewValue == "") {
            var ciudadSuministro = $scope.selectCiudadSips;
        } else {
            var ciudadSuministro = $scope.altaContratoForm.Ciudad.$viewValue;
        }

        // Pasar los campos undefined a empty
        angular.forEach($scope.altaContratoForm, function (value, key) {
            if (!key.startsWith('$') && (value.$viewValue == undefined)) {
                value.$viewValue = "";
            }
        });

        var segmento = $scope.altaContratoForm.Segmento.$viewValue;
        var tipocliente = $scope.altaContratoForm.TipoCliente.$viewValue;
        var codigopromocional = $scope.altaContratoForm.CodigoPromocional.$viewValue;

        if (segmento == undefined || segmento == "Seleccione segmento") { segmento = ""; }
        if (tipocliente == undefined || tipocliente == "Seleccione tipo de cliente") { tipocliente = ""; }
        if (codigopromocional == undefined) { codigopromocional = ""; }

        // TODO: Revisar este campo a piñon
        if ($scope.nombreEmpresa == 'Total') {
            idModeloContrato = 4;

            if ($scope.TipoImpresion == "P") {
                if ($scope.GrupoTarifa != "718" &&
                    $scope.GrupoTarifa != "719" &&
                    $scope.GrupoTarifa != "720" &&
                    $scope.GrupoTarifa != "721" &&
                    $scope.GrupoTarifa != "722" &&
                    $scope.GrupoTarifa != "723" &&
                    $scope.GrupoTarifa != "724"
                ) {
                    $scope.Producto = angular.copy("Impresión en Papel");
                    $scope.AvisoImpresionPapel = true;
                }
            }
        }

        // CONTROL SITUACION ELEIA Y Empresa/GetDTOAll
        if (localStorage.getItem('idContratoSituacionWeb') != "") { IdContratoSituacionDefecto = localStorage.getItem('idContratoSituacionWeb'); }


        // INTERCAMBIO DE VALORES FEES ENERGIA Y POTENCIA DHA Y DHS - ANTONIO MADRID
        if ($scope.MostrarAltaContratoTantosFeesComoPeriodos && (Tarifa == "2.0DHS ML" || Tarifa == "2.1DHS ML" || Tarifa == "2.0DHA ML" || Tarifa == "2.1DHA ML") && $scope.Efees[1]) {
            $scope.Pfees[2] = $scope.Pfees[1];
            $scope.Pfees[1] = null;
            $scope.Efees[2] = $scope.Efees[1];
            $scope.Efees[1] = null;
        }
        //Desarrollo para guixes y luxam aparentemente preboe luz (da fallo en VITA y lo comento)
            // var tipotarifa = TextoTarifaAcceso.substring(0, 4);
            // // console.log(TextoTarifaAcceso);


            // if (tipotarifa == '2.0A' || tipotarifa == '2.1A') {
            //     $scope.Pfees[1] = $scope.Pfees[0];
            //     $scope.Efees[1] = $scope.Efees[0];

            //     $scope.Pfees[0] = 0;
            //     $scope.Efees[0] = 0;
            // }


        var InePaisEnvio;
        var InePaisTitular;
        var InePaisSuministro;

        if (nombreEmpresa == 'Remica') {
            this.InePaisEnvio = 'ES';
            this.InePaisTitular = 'ES';
            this.InePaisSuministro = 'ES';
        } else {
            this.InePaisEnvio = $scope.callejeroINESuministro.codPais;
            this.InePaisTitular = $scope.callejeroINESuministro.codPais;
            this.InePaisSuministro = $scope.callejeroINESuministro.codPais;
        }
		var auxFraccionamientos = [];
		if($scope.DesarrolloFraccionamientoB70 && $scope.DesarrolloFraccionamientoB70Met){
            $scope.arrayProductosRelaccionados.forEach(function (item, i) {

                    if($scope.arrayplazo[i] != null && $scope.arrayplazo[i] != ""){
                        auxFraccionamientos.push({"CodigoCNMC":item.CodigoComunicacion, "IdProducto":item.IdProducto,"Plazos":$scope.arrayplazo[i]});
                    }

			});

		}else if($scope.DesarrolloFraccionamientoB70){
            $scope.itemsB70.forEach(function (item, i) {
				auxFraccionamientos.push({"CodigoCNMC":$scope.arraycodigoCNMC[item], "IdProducto":$scope.arrayProdB70[item],"Plazos":$scope.arrayplazo[item]});
			});
        }

        // console.log(auxFraccionamientos);
		if(tipoFirmaDigitalSmsEmail && $scope.SelectorFirmaDigital > 0) $scope.CertificadoElectronico = true;
        var ContratoPotencial = angular.copy({
            "IdEmpresa": IdEmpresa,
            "IdUsuario": IdUsuario,
            "ContratosPotenciales": [{
                "CodigoCUPS": $scope.altaContratoForm.Cups.$viewValue.toUpperCase(),
                "CodigoREEDistribuidora": $scope.altaContratoForm.Distribuidora.$viewValue,
                "CallePuntoSuministro": $scope.altaContratoForm.Calle.$viewValue,
                "Numero": $scope.altaContratoForm.Numero.$viewValue,
                "Aclarador": $scope.altaContratoForm.Aclarador.$viewValue,
                "CodPostal": $scope.altaContratoForm.CodPostal.$viewValue,
                "PaisSuministro": $scope.callejeroINESuministro.desPais,
                "InePaisSuministro": $scope.callejeroINESuministro.codPais,
                "MunicipioSuministro": $scope.callejeroINESuministro.desMunicipio,
                "IneMunicipioSuministro": $scope.callejeroINESuministro.codMunicipio,
                "CiudadSuministro": $scope.altaContratoForm.Ciudad.$viewValue,
                "IneCiudadSuministro": $scope.callejeroINESuministro.codCiudad,
                "ProvinciaSuministro": $scope.altaContratoForm.Provincia.$viewValue,
                "IneProvinciaSuministro": $scope.callejeroINESuministro.codProvincia,
                "CodigoProveedorProvinciaSuministro": $scope.callejeroINESuministro.codigoProveedorProvincia,
                "CodigoProveedorMunicipioSuministro": $scope.callejeroINESuministro.codigoProveedorMunicipio,
                "CodigoProveedorCiudadSuministro": $scope.callejeroINESuministro.codigoProveedorCiudad,
                "ComercializadoraREE": $scope.altaContratoForm.Comercializadora.$viewValue,
                "TextoTarifaAcceso": TextoTarifaAcceso,
                "PerfilFacturacionPeaje": PerfilFacturacion,
                "P1": $scope.altaContratoForm.PotenciaP1.$viewValue.replace(",", "."),
                "P2": $scope.altaContratoForm.PotenciaP2.$viewValue.replace(",", "."),
                "P3": $scope.altaContratoForm.PotenciaP3.$viewValue.replace(",", "."),
                "P4": $scope.altaContratoForm.PotenciaP4.$viewValue.replace(",", "."),
                "P5": $scope.altaContratoForm.PotenciaP5.$viewValue.replace(",", "."),
                "P6": $scope.altaContratoForm.PotenciaP6.$viewValue.replace(",", "."),
                "ConsumoEstimadoP1": $scope.ConsumoEstimadoP1,
                "ConsumoEstimadoP2": $scope.ConsumoEstimadoP2,
                "ConsumoEstimadoP3": $scope.ConsumoEstimadoP3,
                "ConsumoEstimadoP4": $scope.ConsumoEstimadoP4,
                "ConsumoEstimadoP5": $scope.ConsumoEstimadoP5,
                "ConsumoEstimadoP6": $scope.ConsumoEstimadoP6,
                "Identificador": $scope.altaContratoForm.Identificador.$viewValue.toUpperCase(),
                "CheckPasaporte": document.getElementById("checkPasaporte").checked,
                "NombreTitular": $scope.altaContratoForm.NombreTitular.$viewValue,
                "Apellido1Titular": $scope.altaContratoForm.Apellido1Titular.$viewValue,
                "Apellido2Titular": $scope.altaContratoForm.Apellido2Titular.$viewValue,
                "RazonSocialTitular": $scope.altaContratoForm.RazonSocialTitular.$viewValue,
                "CPTitular": $scope.altaContratoForm.CodPostalTitular.$viewValue,
                "PaisTitular": $scope.callejeroINETitular.desPais,
                "InePaisTitular": this.InePaisTitular,
                "MunicipioTitular": $scope.callejeroINETitular.desMunicipio,
                "IneMunicipioTitular": $scope.callejeroINETitular.codMunicipio,
                "CiudadTitular": $scope.altaContratoForm.CiudadTitular.$viewValue,
                "IneCiudadTitular": $scope.callejeroINETitular.codCiudad,
                "CalleTitular": $scope.altaContratoForm.CalleTitular.$viewValue,
                "NumeroTitular": $scope.altaContratoForm.NumeroTitular.$viewValue,
                "AclaradorTitular": $scope.altaContratoForm.AclaradorTitular.$viewValue,
                "ProvinciaTitular": $scope.altaContratoForm.ProvinciaTitular.$viewValue,
                "IneProvinciaTitular": $scope.callejeroINETitular.codProvincia,
                "CodigoProveedorProvinciaTitular": $scope.callejeroINETitular.codigoProveedorProvincia,
                "CodigoProveedorMunicipioTitular": $scope.callejeroINETitular.codigoProveedorMunicipio,
                "CodigoProveedorCiudadTitular": $scope.callejeroINETitular.codigoProveedorCiudad,
                "TfnoTitular": $scope.altaContratoForm.TelefonoTitular.$viewValue,
                "Movil": $scope.altaContratoForm.MovilTitular.$viewValue,
                "EmailTitular": escape($scope.altaContratoForm.EmailTitular.$viewValue),
                "IsDestinoEnergia": destinoEnergia, // ENERPLUS check domestico
                "NombreEnvio": $scope.altaContratoForm.NombreEnvio.$viewValue,
                "PaisEnvio": $scope.callejeroINEEnvio.desPais,
                "InePaisEnvio": this.InePaisEnvio,
                "MunicipioEnvio": $scope.callejeroINEEnvio.desMunicipio,
                "IneMunicipioEnvio": $scope.callejeroINEEnvio.codMunicipio,
                "CPEnvio": $scope.altaContratoForm.CodPostalEnvio.$viewValue,
                "CiudadEnvio": $scope.altaContratoForm.CiudadEnvio.$viewValue,
                "IneCiudadEnvio": $scope.callejeroINEEnvio.codCiudad,
                "CalleEnvio": $scope.altaContratoForm.CalleEnvio.$viewValue,
                "NumeroEnvio": $scope.altaContratoForm.NumeroEnvio.$viewValue,
                "AclaradorEnvio": $scope.altaContratoForm.AclaradorEnvio.$viewValue,
                "ProvinciaEnvio": $scope.altaContratoForm.ProvinciaEnvio.$viewValue,
                "IneProvinciaEnvio": $scope.callejeroINEEnvio.codProvincia,
                "CodigoProveedorProvinciaEnvio": $scope.callejeroINEEnvio.codigoProveedorProvincia,
                "CodigoProveedorMunicipioEnvio": $scope.callejeroINEEnvio.codigoProveedorMunicipio,
                "CodigoProveedorCiudadEnvio": $scope.callejeroINEEnvio.codigoProveedorCiudad,
                "NombrePagador": $scope.altaContratoForm.NombrePagador.$viewValue,
                "IdentificadorPagador": $scope.altaContratoForm.IdentificadorPagador.$viewValue.toUpperCase(),
                "TextoTipoCobro": $scope.altaContratoForm.TextoTipoCobro.$viewValue,
                "IBAN": $scope.altaContratoForm.IBANPagador.$viewValue,
                "BIC": $scope.altaContratoForm.BICPagador.$viewValue,
                "Tarifa": Tarifa,
                "TarifaGrupo": TarifaGrupo,
                "PerfilFacturacionContratoTarifa": PerfilFacturacionPeaje,
                "FechaContrato": fecha,
                "IdIdiomaInforme": $scope.altaContratoForm.IdiomaContrato.$viewValue,
                "TextoPeriodo": $scope.altaContratoForm.PeriodoTarifa.$viewValue,
                "TextoTipoImpresion": $scope.altaContratoForm.TipoImpresion.$viewValue,
                "TipoSolicitud": $scope.altaContratoForm.TipoSolicitud.$viewValue,
                "MotivoModificacion": this.MotivoModificacion,
                "RefCatastral": $scope.altaContratoForm.RefCatastral.$viewValue,
                "RefExterna": "",
                "CodigoCNAE": $scope.altaContratoForm.CNAE.$viewValue,
                "Producto": $scope.Producto,
                "NombreAgente": NombreAgente,
                "CodigoVendedor": $scope.altaContratoForm.CodigoVendedor.$viewValue,
                "IdModeloContrato": $scope.altaContratoForm.ModeloDeContrato.$viewValue.split('-')[0],
                "TextoModeloContrato": $scope.altaContratoForm.ModeloDeContrato.$viewValue.split('-')[1],
                "Observaciones": $scope.altaContratoForm.Observaciones.$viewValue,
                "IdContratoSituacion": IdContratoSituacionDefecto,
                "IdClienteCategoria": tipocliente,
                "ReferenciaExterna": $scope.altaContratoForm.TarjetaClubDisa.$viewValue,
                "Segmentacion": segmentacion,
                "FechaEfecto": $scope.altaContratoForm.FechaEfecto.$viewValue,
                "ActivacionPrevista": $scope.ActivacionPrevistaActivo ? moment($scope.ActivacionPrevista).format('YYYY/MM/DD') : "",//fechaActivacionPrevista,
                "ComisionCalculada": ($scope.VerGas && $scope.EleiaComisionGasMTFyMTV) ? $scope.comisionTotalGas : (comisionCalculada.value && comisionCalculada.value != "") ? comisionCalculada.value : ($scope.CalculoComision != 0) ? $scope.CalculoComision.replace('€', '').replace(',', '.') : "",
                "IdsIndexado": $scope.ArrayIdsIndexado,
                "IdsTarifaPrecio": $scope.ArrayIdsFijos,
                //"PorcentajeComisionCalculada": CalculoComision,
                //"PorcentajeComisionCalculada": comisionCalculada.value,
                "ConsumoEstimado": Math.round($scope.consumoAnual),
                "Fee": $scope.altaContratoForm.agenteFee.$viewValue.replace(",", "."),
                "PorcentajeCO": ($scope.PorcentajeComision) ? $scope.PorcentajeComision : "",
                "FechaAlta": ($scope.FechaAlta) ? moment($scope.FechaAlta).format('YYYY/MM/DD') : "",//fechaAltaContrato,
                "FechaVto": ($scope.FechaVto) ? moment($scope.FechaVto).format('YYYY/MM/DD') : "",//fechaFinContrato,
                // "CosteGestion": ($scope.costeGestion == null) ? costeGestion.value.replace(",", ".") : $scope.costeGestion,
                "CosteGestion": $scope.costeGestion,
                "TextoViaSuministro": ($scope.textoViaSuministro === "") ? "CALLE" : $scope.textoViaSuministro,
                "TextoViaTitular": ($scope.textoViaTitular === "") ? "CALLE" : $scope.textoViaTitular,
                "TextoViaEnvio": ($scope.textoViaEnvio === "") ? "CALLE" : $scope.textoViaEnvio,
                "Representante": $scope.altaContratoForm.NombreRepresentante.$viewValue,
                "IdentificadorRep": $scope.altaContratoForm.IdentificadorRepresentante.$viewValue.toUpperCase(),
                "IdColectivoRep": $scope.altaContratoForm.IdColectivoRep.$viewValue,
                "Comentario": $scope.altaContratoForm.Comentario.$viewValue,
                "IdDestinoEnergia": segmento,
                "IdTipoCapacidad": ($scope.VerGas) ? $scope.altaContratoForm.TipoCapacidad.$viewValue : "",
                "Capacidad": ($scope.VerGas) ? $scope.altaContratoForm.Capacidad.$viewValue : "",
                "FechaCapDesde": ($scope.FechaCapDesde) ? moment($scope.FechaCapDesde).format('YYYY/MM/DD') : "",
                "FechaCapHasta": ($scope.FechaCapHasta) ? moment($scope.FechaCapHasta).format('YYYY/MM/DD') : "",
                "IdTipoLectura": ($scope.VerGas) ? $scope.altaContratoForm.TipoLecturaContrato.$viewValue : "",
                "PCTDGas": ($scope.VerGas) ? $scope.altaContratoForm.PCTDGas.$viewValue : "",
                "IdTarifaAnual": ($scope.altaContratoForm.TarifaAnual.$viewValue) ? JSON.parse($scope.altaContratoForm.TarifaAnual.$viewValue).IdTarifaPeaje : "",
                "CodigoPromocional": ($scope.altaContratoForm.CodigoPromocional.$viewValue) ? $scope.altaContratoForm.CodigoPromocional.$viewValue : "",
                "ComentarioPiePagina": ($scope.altaContratoForm.ComentarioPiePagina.$viewValue) ? $scope.altaContratoForm.ComentarioPiePagina.$viewValue : "",
                "BloqueoSistemaComercial": CheckSistemaComercial,
                "BloqueoPublicidadEmpresa": CheckCamapanaPublicidad,
                "BloqueoUsoTercero": CheckUsoTerceros,
                "IdSolicitudCambioTitular": ($scope.altaContratoForm.MotivoModificacion) ? $scope.altaContratoForm.MotivoModificacion.$viewValue: "",
                "AgenteFEE": ($scope.fee && $scope.especial) ? $scope.fee : 0,

                //TODO: ANTONIO MADRID FEES
                "PFEE1": ($scope.Pfees[0] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Pfees[0] : 0,
                "PFEE2": ($scope.Pfees[1] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Pfees[1] : 0,
                "PFEE3": ($scope.Pfees[2] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Pfees[2] : 0,
                "PFEE4": ($scope.Pfees[3] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Pfees[3] : 0,
                "PFEE5": ($scope.Pfees[4] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Pfees[4] : 0,
                "PFEE6": ($scope.Pfees[5] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Pfees[5] : 0,

                "CFEE1": ($scope.Efees[0] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Efees[0] / 10 : 0,
                "CFEE2": ($scope.Efees[1] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Efees[1] / 10 : 0,
                "CFEE3": ($scope.Efees[2] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Efees[2] / 10 : 0,
                "CFEE4": ($scope.Efees[3] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Efees[3] / 10 : 0,
                "CFEE5": ($scope.Efees[4] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Efees[4] / 10 : 0,
                "CFEE6": ($scope.Efees[5] && $scope.especial && $scope.MostrarAltaContratoTantosFeesComoPeriodos) ? $scope.Efees[5] / 10 : 0,

                //TODO:
                "IdMarca": (localStorage.getItem('idEmpresaGas') == "false") ? $scope.IdMarca : $scope.IdMarcaGas,

                // "PorcentajeComision": ($scope.PorcentajeComisionFijo) ? $scope.PorcentajeComisionFijo : 0, // A. MADRID, LUXAM
                "PorcentajeComision": ($scope.PorcentajeComision) ? $scope.PorcentajeComision : 0,
                "Booleanos": {
                    "IsFirmaDigital": $scope.CertificadoElectronico === true ? true : false,
                    "IsFirmaDigitalSMS": $scope.SelectorFirmaDigital > 1 ? true : false,
                    "IsElectronico": $scope.AltaContratoIsElectronico === true ? true : false,
				},
				"EmailRep": $scope.altaContratoForm.EmailRepresentante.$viewValue,
				"SMSRep": $scope.altaContratoForm.SMSRepresentante.$viewValue,
				"B2BFechaFirma": ($scope.FechasDisa && $scope.SelectFechaFirma == '2') ? moment($scope.FechaFirma).format('YYYY/MM/DD') : null,
				"CoreFechaFirma": ($scope.FechasDisa && $scope.SelectFechaFirma == '3') ? moment($scope.FechaFirma).format('YYYY/MM/DD') : null,
                "is360" : ($scope.VerGas &&  $scope.EleiaComisionGasMTFyMTV) ? $scope.Es360 : "",
                "ValorMTV" : ($scope.VerGas && $scope.altaContratoForm.mtv.$viewValue >= 0 && $scope.EleiaComisionGasMTFyMTV) ? $scope.altaContratoForm.mtv.$viewValue/1000 : "",
                "ValorMTF" : ($scope.VerGas && $scope.altaContratoForm.mtf.$viewValue >= 0 && $scope.EleiaComisionGasMTFyMTV) ? ($scope.altaContratoForm.mtf.$viewValue * 12) / 365 : "",
                "ComisionCalculadaAgente" : ($scope.VerGas) ? $scope.comisionTotalGasAgente : "",
				"IsAplicarFraccionamiento": ($scope.DesarrolloFraccionamientoB70)?$scope.controlcheckB70 : false,
				"Fraccionamientos": ($scope.DesarrolloFraccionamientoB70)?auxFraccionamientos:null,
				"IdBono": ($scope.IsSistemaBonoActivo && $scope.altaContratoForm.SelectBono.$viewValue != '' && $scope.altaContratoForm.SelectBono.$viewValue != '')?$scope.altaContratoForm.SelectBono.$viewValue:null,
				"IdTipoAlquiler": ($scope.MostrarContratoTipoAlquiler && $scope.MostrarTipoAlquiler && $scope.altaContratoForm.TipoAlquiler.$viewValue != '')?$scope.altaContratoForm.TipoAlquiler.$viewValue:null,
            }]

        });
        console.log( $scope.altaContratoForm);
        var fichero = document.getElementById("file-upload-contrato");
        //Controlamos que el fichero no esté vacio, sino, da error
        if (fichero != null) {
            var ficheroObtenido = fichero.files[0];
        }

        var ContratolPotencialString = JSON.stringify(ContratoPotencial);

        var dataFichero = new FormData();


        $http({
            method: "POST",
            url: urlPrimariaInsercion,
            data: ContratolPotencialString,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function successCallback(response) {
            //Si no hay incidencias, abro el documento asociado
            if (response.data.Contratos[0].IdContrato == '0') {

                $scope.MostrarBotonCargaContrato = !$scope.MostrarBotonCargaContrato;
                $scope.spinnerBotonCargaContrato = !$scope.spinnerBotonCargaContrato;
                $scope.showAlertIncidencias(response.data.Contratos[0].Incidencias);
                emailErrorContratos.GetNetworkErrors(response, $scope.VerGas);

            } else {
                $scope.respuesta = response.data;
                localStorage.setItem('controlRefresh', true); //creo una cookie para controlar el refresh de la pagina de contratos

                $scope.MostrarBotonCargaContrato = !$scope.MostrarBotonCargaContrato;
                $scope.spinnerBotonCargaContrato = !$scope.spinnerBotonCargaContrato;

                // Subida de documento en el alta contrato por API
                if (document.getElementById('file-upload-contrato').files[0] != null) {
                    var file = document.getElementById('file-upload-contrato').files[0];
                    $scope.subirDocumentacion(file, $scope.respuesta.Contratos[0].CodigoContrato, $scope.respuesta.Contratos[0].IdCliente, IdEmpresa, IdUsuario);
                }

                alert = $mdDialog.confirm()
                    .clickOutsideToClose(false)
                    .title('')
                    .htmlContent('<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Contrato insertado correctamente.</p>')
                    .ariaLabel('Contrato Confirmado')
                    .ok('Volver al listado')
                    .cancel('Abrir PDF');

                $mdDialog.show(alert).then(function () {
                    //TODO: Reset Form
                    // document.getElementById("form").reset();
                    $location.url("contratos"); //redirijo a la pagina de contratos
                }, function () {
                    $http({
                        method: "GET",
                        url: (($scope.UrlApiCorrecionImprimirContratoBien != "") ?
                            $scope.UrlApiCorrecionImprimirContratoBien : urlApi) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + response.data.Contratos[0].IdContrato,
                        responseType: 'blob',
                        headers: {
                            Authorization: atob(localStorage.getItem('TokenValidador'))
                        }
                    }).then(function successCallback(response) {
                        var respuesta = response.data;

                        /*
                        if (ficheroObtenido) {
                            dataFichero.append("file", ficheroObtenido);
                            dataFichero.append("IdUsuario", IdUsuario);
                            dataFichero.append("IdEmpresa", IdEmpresa);
                            dataFichero.append("IdCliente", $scope.respuesta.Contratos[0].IdCliente);
                            dataFichero.append("CodigoContrato", respuesta.Contratos[0].CodigoContrato);
                            // dataFichero.append("CodigoContrato", $scope.respuesta.Contratos[0].CodigoContrato);
                            dataFichero.append("api", urlApi);

                            $.ajax({
                                type: 'POST',
                                url: 'lib/php/subir_documentacion_contrato.php',
                                data: dataFichero,
                                processData: false,
                                contentType: false
                            }).done(function (data) { });
                        }*/

                        var file = new Blob([respuesta], { type: 'application/pdf' });

                        window.URL = window.URL || window.webkitURL;
                        var fileURL = window.URL.createObjectURL(file);

                        if (AvisosCertificados == true) {
                            var InformacionSms = {
                                "Dni": $scope.altaContratoForm.Identificador.$viewValue,
                                "Nombre": $scope.altaContratoForm.NombreTitular.$viewValue,
                                "Telefono": $scope.altaContratoForm.TelefonoTitular.$viewValue,
                                "Email": $scope.altaContratoForm.EmailTitular.$viewValue,
                                "Apellido1": $scope.altaContratoForm.Apellido1Titular.$viewValue,
                                "Apellido2": $scope.altaContratoForm.Apellido2Titular.$viewValue,
                                "IdContrato": $scope.respuesta.Contratos[0].IdContrato,
                                "EnvioEmail": $scope.EmailCertificadoChecked,
                                "EnvioSMS": $scope.SMSCertificadoChecked
                            };

                            var fd = new FormData();
                            fd.append('FicheroBlob', file);
                            fd.append('IdContrato', $scope.respuesta.Contratos[0].IdContrato);

                            var idLead = localStorage.getItem('idLead');

                            $.ajax({
                                type: 'POST',
                                url: 'lib/php/subirPdf.php',
                                data: fd,
                                processData: false,
                                contentType: false
                            }).done(function (data) {
                                InformacionSms["NombreFichero"] = data;
                                $.post("lib/php/avisos_certificados.php", InformacionSms, function (data) {
                                    // console.log(data);
                                    // var dataParsed = JSON.parse(data);
                                    var dataParsed = data;

                                    if (idLead) {

                                        if (dataParsed["IdEmail"] != null)
                                            $.post("lib/php/actualizar_id_email.php", { "Id": idLead, "IdEmail": dataParsed["IdEmail"], "IdContrato": $scope.respuesta.Contratos[0].IdContrato }, function (data) { });
                                        if (dataParsed["IdSms"] != null)
                                            $.post("lib/php/actualizar_id_sms.php", { "Id": idLead, "IdSms": dataParsed["IdSms"], "IdContrato": $scope.respuesta.Contratos[0].IdContrato }, function (data) { });

                                        if (dataParsed["IdSms"] == null && dataParsed["IdEmail"] == null) {
                                            $.post("lib/php/actualizar_sin_id_email_sms.php", { "Id": idLead, "IdContrato": $scope.respuesta.Contratos[0].IdContrato }, function (data) { });
                                        }

                                    } else {
                                        $.post("lib/php/insertar_certificados_contrato.php", { "IdEmail": dataParsed["IdEmail"], "IdSms": dataParsed["IdSms"], "IdContrato": $scope.respuesta.Contratos[0].IdContrato, "Id": idLead }, function (data) { });
                                    }

                                    //Mensaje de contrato completado
                                    localStorage.setItem('controlRefresh', true); //creo una cookie para controlar el refresh de la pagina de contratos
                                    $location.url("contratos"); //redirijo a la pagina de contratos
                                    //PDF generado mostrado por pantalla
                                    window.open(fileURL);
                                    //Actualizo lead a Oferta
                                    if (dataParsed["IdEmail"] != null || dataParsed["IdSms"] != null) {
                                        $.post("lib/php/actualizar_lead_oferta.php", { "Id": idLead }, function (data) { });
                                    }

                                });
                            });
                        } else {
                            //Mensaje de contrato completado
                            // $scope.showAlert();
                            //PDF generado mostrado por pantalla
                            window.open(fileURL);
                        }
                        //TODO: Reset Form
                        // document.getElementById("form").reset();
                        document.getElementById("formulario").reset();
                    }); //Fin get PDF
                });

            } // Fin IF comprobacion contrato = 0
        }, function errorCallback(response) {
            emailErrorContratos.GetNetworkErrors(response, $scope.VerGas);
            $scope.error = 'block';
            $scope.loginSpinner = 'hidden';

            var bodyHTML = '<h2><b>Error al procesar el alta </b></h2><p> <br> <i class="fa fa-times-circle" aria-hidden="true"></i> <br><br> Póngase en contacto con su proveedor e indíquele el CUPS - <b>'+ $scope.altaContratoForm.Cups.$viewValue.toUpperCase() +'</b> para gestionar la incidencia.</p>';

            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title('')
                    .htmlContent(bodyHTML)
                    .ariaLabel('Error al procesar el alta')
                    .ok('Aceptar')
            );

        }); //Fin post Url inserción
        //Fin if comprobación incidencias
    }

    //Fin post url verificación


    $scope.subirDocumentacion = function (file, CodigoContrato, IdCliente, IdEmpresa, IdUsuario) {
        // var file = document.getElementById('file-upload-contrato').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            var ficheroSplit = reader.result.split(',');
            var ficheroPdf = file.name;
            var extensionFichero = ficheroPdf.split('.');

            // var datosApi = { "DocPDF": ficheroSplit[1], "IdUsuario": IdUsuario, "IdEmpresa": IdEmpresa, "CodigoContrato": CodigoContrato, "NombrePDF": ficheroPdf, "Entorno": Entorno };
            var datosApi = { "Documento": ficheroSplit[1], "IdUsuario": IdUsuario, "IdEmpresa": IdEmpresa, "CodigoContrato": CodigoContrato, "NombreDocumento": ficheroPdf };
            $http({
                method: "POST",
                url: urlApi + "Documento/ImportDocumento",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador')),
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(datosApi)
            }).then(function (response) {
                // $scope.showAlert();
                // $state.go("resumen_contratos", {}, { reload: true });

            });

        };
    }

    $scope.exportarDatosAltaContratos = function () {
        var cups = document.getElementsByName("Cups")[0].value;
        var codDistribuidora = document.getElementsByName("Distribuidora")[0].value;
        var codComercializadora = document.getElementsByName("Comercializadora")[0].value;
        var ciudad = document.getElementsByName("Ciudad")[0].value;
        var calle = document.getElementsByName("Calle")[0].value;
        var numero = document.getElementsByName("Numero")[0].value;
        var aclarador = document.getElementsByName("Aclarador")[0].value;
        var codPostal = document.getElementsByName("CodPostal")[0].value;
        var provincia = document.getElementsByName("Provincia")[0].value;
        var tarifa = JSON.parse(document.getElementsByName("TarifaAccesoElectricidad")[1].value);
        var grupoTarifa = document.getElementsByName("GrupoTarifa")[1].value;
        var perfilFacturacion = document.getElementsByName("PerfilFacturacion")[1].value;
        var p1 = document.getElementsByName("PotenciaP1")[0].value;
        var p2 = document.getElementsByName("PotenciaP2")[0].value;
        var p3 = document.getElementsByName("PotenciaP3")[0].value;
        var p4 = document.getElementsByName("PotenciaP4")[0].value;
        var p5 = document.getElementsByName("PotenciaP5")[0].value;
        var p6 = document.getElementsByName("PotenciaP6")[0].value;
        var grupoProducto = document.getElementsByName("GrupoProducto")[1].value;
        var producto = document.getElementsByName("ProductoElectricidad")[1].value;
        var identificador = document.getElementsByName("Identificador")[0].value;
        var nombreTitular = document.getElementsByName("NombreTitular")[0].value;
        var apellido1 = document.getElementsByName("Apellido1Titular")[0].value;
        var apellido2 = document.getElementsByName("Apellido2Titular")[0].value;
        var razonSocial = document.getElementsByName("RazonSocialTitular")[0].value;
        var codPostalTitular = document.getElementsByName("CodPostalTitular")[0].value;
        var ciudadTitular = document.getElementsByName("CiudadTitular")[0].value;
        var calleTitular = document.getElementsByName("CalleTitular")[0].value;
        var numeroTitular = document.getElementsByName("NumeroTitular")[0].value;
        var aclaradorTitular = document.getElementsByName("AclaradorTitular")[0].value;
        var provinciaTitular = document.getElementsByName("ProvinciaTitular")[0].value;
        var telefonoTitular = document.getElementsByName("TelefonoTitular")[0].value;
        var emailTitular = document.getElementsByName("EmailTitular")[0].value;
        var nombreEnvio = document.getElementsByName("NombreEnvio")[0].value;
        var codPostalEnvio = document.getElementsByName("CodPostalEnvio")[0].value;
        var ciudadEnvio = document.getElementsByName("CiudadEnvio")[0].value;
        var calleEnvio = document.getElementsByName("CalleEnvio")[0].value;
        var numeroEnvio = document.getElementsByName("NumeroEnvio")[0].value;
        var aclaradorEnvio = document.getElementsByName("AclaradorEnvio")[0].value;
        var provinciaEnvio = document.getElementsByName("ProvinciaEnvio")[0].value;
        var observaciones = document.getElementsByName("Observaciones")[0].value;
        var nombrePagador = document.getElementsByName("NombrePagador")[0].value;
        var identificadorPagador = document.getElementsByName("IdentificadorPagador")[0].value;
        var tipoCobro = document.getElementsByName("TextoTipoCobro")[1].value;
        var iban = document.getElementsByName("IBANPagador")[0].value;
        var cnae = document.getElementsByName("CNAE")[1].value;
        var periodoTarifa = document.getElementsByName("PeriodoTarifa")[1].value;
        var tipoImpresion = document.getElementsByName("TipoImpresion")[1].value;
        var tipoSolicitud = document.getElementsByName("TipoSolicitud")[1].value;
        var modeloContrato = document.getElementsByName("ModeloDeContrato")[1].value;
        var idiomaContrato = document.getElementsByName("IdiomaContrato")[0].value;
        var codigoVendedor = document.getElementsByName("CodigoVendedor")[0].value;
        var MotivoModificacion = document.getElementsByName("MotivoModificacion")[0].value;


        var facturas = [];

        facturas.push({
            cups: cups,
            codDistribuidora: codDistribuidora,
            codComercializadora: codComercializadora,
            ciudad: ciudad,
            calle: calle,
            numero: numero,
            aclarador: aclarador,
            codPostal: codPostal,
            provincia: provincia,
            tarifa: tarifa.TextoTarifa,
            grupoTarifa: grupoTarifa,
            perfilFacturacion: perfilFacturacion,
            p1: p1,
            p2: p2,
            p3: p3,
            p4: p4,
            p5: p5,
            p6: p6,
            grupoProducto: grupoProducto,
            producto: producto,
            identificador: identificador,
            nombreTitular: nombreTitular,
            apellido1: apellido1,
            apellido2: apellido2,
            razonSocial: razonSocial,
            codPostalTitular: codPostalTitular,
            ciudadTitular: ciudadTitular,
            calleTitular: calleTitular,
            numeroTitular: numeroTitular,
            aclaradorTitular: aclaradorTitular,
            provinciaTitular: provinciaTitular,
            telefonoTitular: telefonoTitular,
            emailTitular: emailTitular,
            nombreEnvio: nombreEnvio,
            codPostalEnvio: codPostalEnvio,
            ciudadEnvio: ciudadEnvio,
            calleEnvio: calleEnvio,
            numeroEnvio: numeroEnvio,
            aclaradorEnvio: aclaradorEnvio,
            provinciaEnvio: provinciaEnvio,
            observaciones: observaciones,
            nombrePagador: nombrePagador,
            identificadorPagador: identificadorPagador,
            tipoCobro: tipoCobro,
            iban: iban,
            cnae: cnae,
            periodoTarifa: periodoTarifa,
            tipoImpresion: tipoImpresion,
            tipoSolicitud: tipoSolicitud,
            modeloContrato: modeloContrato,
            idiomaContrato: idiomaContrato,
            codigoVendedor: codigoVendedor,
            MotivoModificacion: MotivoModificacion,

        });

        return facturas;
    };

    $http({
        method: "GET",
        url: urlApi + "ContratoPotencial/GetDTOAllTiposCalle/Empresa/1/Usuario/1",
        headers: {
            Authorization: atob(localStorage.getItem('TokenValidador'))
        }
    }).then(function (response) {
        var respuesta = response.data;
        var decodeToken = jwtHelper.decodeToken(respuesta);
        var playloadJson = JSON.parse(decodeToken.iss);

        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
            alg: ["HS256"]
        });

        // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        if (tokenValido && !tokenExpirado) {
            $scope.tiposVias = playloadJson;
        } else {
            $scope.tiposVias = "";
            console.error("HASH DE VERIFICACION INCORRECTO");
        }
    });

    $scope.BuscarCiudad = function (textoCiudad) {
        var valorCiudad = document.getElementsByName("Ciudad")[0];
        valorCiudad.value = textoCiudad;

        $scope.ListadoCiudadesBloqueado = true;
        $scope.spinnerListadoCiudades = true;

        /**
         * Cada vez que busque, que borre la ciudad y la calle del CUPS
         */

        $scope.ciudadItem = {};
        $scope.calleItem = {};

        var dataCiudades = [{ "FiltroTexto": textoCiudad }];

        $http({
            method: "POST",
            url: urlCallejero + "Ciudad/GetDTOALLbyTexto",
            data: JSON.stringify(dataCiudades)
        }).then(function (response) {
            var respuesta = response.data;
            $scope.ciudades = respuesta;
            $scope.obtenerCiudades = function (text) {
                text = text.toUpperCase();
                var ret = $scope.ciudades.filter(function (d) {
                    if (d.desCiudad != null)
                        return d.desCiudad.includes(text);
                });
                return ret;
            };
            /**
             * Oculto el spinner de carga y desbloqueo el campo de ciudades
             */
            $scope.ListadoCiudadesBloqueado = false;
            $scope.spinnerListadoCiudades = false;

            /**
             * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
             * Si boras el timeout NO VA
             */
            setTimeout(function () {
                document.querySelector('#CiudadSuministro').focus();
            }, 0);
        });
    }

    $scope.BuscarCiudadTitular = function (textoCiudad) {
        var valorCiudad = document.getElementsByName("Ciudad")[0];
        valorCiudad.value = textoCiudad;

        $scope.ListadoCiudadesTitularBloqueado = true;
        $scope.spinnerListadoCiudadesTitular = true;

        /**
         * Cada vez que busque, que borre la ciudad y la calle del Titular
         */

        var tamanoCiudadItem = Object.keys($scope.ciudadTitularItem).length;
        if (tamanoCiudadItem > 0) {
            $scope.bloqueoCalleTitularCopiando = false;
        } else {
            $scope.bloqueoCalleTitularCopiando = true;
        }

        $scope.ciudadTitularItem = {};
        $scope.calleTitularItem = {};

        var dataCiudades = [{ "FiltroTexto": textoCiudad }];

        $http({
            method: "POST",
            url: urlCallejero + "Ciudad/GetDTOALLbyTexto",
            data: JSON.stringify(dataCiudades)
        }).then(function (response) {
            var respuesta = response.data;
            $scope.ciudadesTitular = respuesta;
            $scope.obtenerCiudadesTitular = function (text) {
                text = text.toUpperCase();
                var ret = $scope.ciudadesTitular.filter(function (d) {
                    if (d.desCiudad !== null)
                        return d.desCiudad.includes(text);
                });
                return ret;
            }
            $scope.ListadoCiudadesTitularBloqueado = false;
            $scope.spinnerListadoCiudadesTitular = false;

            /**
             * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
             * Si boras el timeout NO VA
             */
            setTimeout(function () {
                document.querySelector('#CiudadTitular').focus();
            }, 0);
        });
    }

    $scope.BuscarCiudadEnvio = function (textoCiudad) {
        var valorCiudad = document.getElementsByName("Ciudad")[0];
        valorCiudad.value = textoCiudad;

        var dataCiudades = [{ "FiltroTexto": textoCiudad }];

        $scope.ListadoCiudadesEnvioBloqueado = true;
        $scope.spinnerListadoCiudadesEnvio = true;

        /**
         * Cada vez que busque, que borre la ciudad y la calle del Envio
         * La variable de bloqueCalleEnvioCopiando es un control para activar o desactivar la calle de envio
         * Según sea copiar o buscar. SOLO SE EJECUTA LA VEZ QUE LIMPIAN, LA QUE BUSCAN NO
         */

        var tamanoCiudadItem = Object.keys($scope.ciudadEnvioItem).length;
        if (tamanoCiudadItem > 0) {
            $scope.bloqueoCalleEnvioCopiando = false;
        } else {
            $scope.bloqueoCalleEnvioCopiando = true;
        }
        $scope.ciudadEnvioItem = {};
        $scope.calleEnvioItem = {};

        $http({
            method: "POST",
            url: urlCallejero + "Ciudad/GetDTOALLbyTexto",
            data: JSON.stringify(dataCiudades)
        }).then(function (response) {
            var respuesta = response.data;
            $scope.ciudadesEnvio = respuesta;
            $scope.obtenerCiudadesEnvio = function (text) {
                var ret = $scope.ciudadesEnvio.filter(function (d) {
                    if (d.desCiudad !== null)
                        return d.desCiudad.includes(text);
                });
                return ret;
            }
            $scope.ListadoCiudadesEnvioBloqueado = false;
            $scope.spinnerListadoCiudadesEnvio = false;

            /**
             * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
             * Si boras el timeout NO VA
             */
            setTimeout(function () {
                document.querySelector('#CiudadEnvio').focus();
            }, 0);
        });
    }

    function isJsonVacio(json) {
        /**
         * Nos permite comprobar si un json está vacio
         */
        if (json != null) {
            return Object.keys(json).length === 0
        } else {
            return true;
        }

    }

    /**
        * Funcion IGNIS para asignar estados en fecha efecto en funcion del CNAE TARIFA Y VALOR DE POTENCIA
    */
    $scope.checkFechaEfectoDomestico = function () {

        if ($scope.DiasInicioUsoDomestico) {
            var checkTarifa = JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue).IdTarifa;

            if ($scope.altaContratoForm.CNAE.$viewValue == 9820 && ($scope.altaContratoForm.PotenciaP1.$viewValue <= 10 || $scope.altaContratoForm.PotenciaP2.$viewValue <= 10 || $scope.altaContratoForm.PotenciaP3.$viewValue <= 10) && (checkTarifa == 20020 || checkTarifa == 20021 || checkTarifa == 20024)) {
                $scope.ValorDesplegableFechaEfecto = "Segun la fecha solicitada (2017)";
                $scope.FechaAlta = moment().add(15, 'd').toDate();
                $scope.ActivacionPrevista = moment().add(15, 'd').toDate();
            } else {
                $scope.ValorDesplegableFechaEfecto = "Cuanto antes(2017)";
            }
        }

    }


    $scope.cargarCiudadSuministro = function (ciudad) {

        if (!isJsonVacio(ciudad)) {
            if (ciudad.desCiudad != null) {
                $scope.callejeroINESuministro = {
                    "codCiudad": angular.copy(ciudad.desCiudad.codCiudad),
                    "desCiudad": angular.copy(ciudad.desCiudad.desCiudad),
                    "codPais": angular.copy(ciudad.desCiudad.codPais),
                    "desPais": angular.copy(ciudad.desCiudad.desPais),
                    "codProvincia": angular.copy(ciudad.desCiudad.codProvincia),
                    "desProvincia": angular.copy(ciudad.desCiudad.desProvincia),
                    "codMunicipio": angular.copy(ciudad.desCiudad.codMunicipio),
                    "desMunicipio": angular.copy(ciudad.desCiudad.desMunicipio),
                    "codigoProveedorProvincia": angular.copy(ciudad.desCiudad.codigoProveedorProvincia),
                    "codigoProveedorMunicipio": angular.copy(ciudad.desCiudad.codigoProveedorMunicipio),
                    "codigoProveedorCiudad": angular.copy(ciudad.desCiudad.codigoProveedorCiudad)
                }
                if ($scope.DataTitular.Provincia != ciudad.desCiudad) {
                    $scope.DataCups.Provincia = ciudad.desCiudad.desProvincia;
                }
                $scope.codProvinciaSuministro = ciudad.desCiudad.codProvincia;
                $scope.codMunicipioSuministro = ciudad.desCiudad.codMunicipio;
            }
        }

        /**
         * Si los dos codigos no son != de undefined que no haga la llamada, solo provoca errores de consola
         */

        if ($scope.codMunicipioSuministro != null && $scope.codProvinciaSuministro != null) {

            $scope.ListadoCallesSuministroBloqueado = true;
            $scope.spinnerListadoCallesSuministro = true;

            $http({
                method: "GET",
                url: urlCallejeroNew + "Calle/GetDTOALLCalles/CodigoMunicipio/" + $scope.codMunicipioSuministro + "/CodProvincia/" + $scope.codProvinciaSuministro
            }).then(function (response) {
                $scope.buscadorCallesSuministro = response.data; //Aqui guardamos el array de calles
                $scope.obtenerCalles = function (text) {
                    text = text.toUpperCase();
                    var ret = $scope.buscadorCallesSuministro.filter(function (d) {
                        var desCompleta = d.tipoCalle + ' ' + d.desCalle + ' ' + d.codigoPostal;
                        if (desCompleta !== null)
                            return desCompleta.includes(text);
                    });
                    /**
                     * Si no existe la calle les dejo meter a mano el codigo postal
                     */
                    (ret.length === 0) ? ($scope.disabledCodPostalSuministro = false) : ($scope.disabledCodPostalSuministro = true);
                    return ret;
                };
                $scope.ListadoCallesSuministroBloqueado = false;
                $scope.spinnerListadoCallesSuministro = false;

                /**
                 * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
                 * Si boras el timeout NO VA
                 */
                setTimeout(function () {
                    document.querySelector('#CalleSuministro').focus();
                }, 0);
            });
        }
    }

    $scope.cargarValoresSuministro = function (item, ciudadItem) {

        $scope.calleSuministro = item.desCalle;

        if (item.desCalle != null) {
            $scope.textoViaSuministro = item.desCalle.tipoCalle;
            if (item.desCalle.codigoPostal.length < 5) {
                $scope.DataCups.CodPostal = angular.copy("0" + item.desCalle.codigoPostal);
            } else {
                $scope.DataCups.CodPostal = angular.copy(item.desCalle.codigoPostal);
            }
        } else {
            $scope.textoViaSuministro = "CALLE";
        }

    };

    $scope.cargarCiudadTitular = function (ciudad) {
        if (!isJsonVacio(ciudad)) {

            //TODO: Ver como mejorar esto para que cuando busque o copie la estructura no sea diferente
            //TODO: Creo que es quitando un desciudad del html, pero revisarlo antes.
            if (ciudad.desCiudad.desProvincia == null) {
                $scope.callejeroINETitular = {
                    "codCiudad": angular.copy(ciudad.desCiudad.desCiudad.codCiudad),
                    "desCiudad": angular.copy(ciudad.desCiudad.desCiudad.desCiudad),
                    "codPais": angular.copy(ciudad.desCiudad.desCiudad.codPais),
                    "desPais": angular.copy(ciudad.desCiudad.desCiudad.desPais),
                    "codProvincia": angular.copy(ciudad.desCiudad.desCiudad.codProvincia),
                    "desProvincia": angular.copy(ciudad.desCiudad.desCiudad.desProvincia),
                    "codMunicipio": angular.copy(ciudad.desCiudad.desCiudad.codMunicipio),
                    "desMunicipio": angular.copy(ciudad.desCiudad.desCiudad.desMunicipio),
                    "codigoProveedorProvincia": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorProvincia),
                    "codigoProveedorMunicipio": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorMunicipio),
                    "codigoProveedorCiudad": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorCiudad)
                }
                if ($scope.DataTitular.ProvinciaTitular != ciudad.desCiudad.desCiudad.desProvincia) {
                    $scope.DataTitular.ProvinciaTitular = ciudad.desCiudad.desCiudad.desProvincia;
                }
            }

        }

        /**
         * Si los dos codigos no son != de undefined que no haga la llamada, solo provoca errores de consola
         */

        if (!$scope.bloqueoCalleTitularCopiando) {
            if (ciudad.desCiudad != null) {
                if (!isUndefined(ciudad.desCiudad.desCiudad.codProvincia) && !isUndefined(ciudad.desCiudad.desCiudad.codMunicipio)) {

                    $scope.ListadoCallesTitularBloqueado = true;
                    $scope.spinnerListadoCallesTitular = true;

                    $http({
                        method: "GET",
                        url: urlCallejeroNew + "Calle/GetDTOALLCalles/CodigoMunicipio/" + ciudad.desCiudad.desCiudad.codMunicipio + "/CodProvincia/" + ciudad.desCiudad.desCiudad.codProvincia
                    }).then(function (response) {
                        $scope.BuscadorCallesTitular = response.data; //Aqui guardamos el array de calles
                        $scope.obtenerCallesTitular = function (text) {
                            text = text.toUpperCase();
                            var ret = $scope.BuscadorCallesTitular.filter(function (d) {
                                var desCompleta = d.tipoCalle + ' ' + d.desCalle + ' ' + d.codigoPostal;
                                if (desCompleta !== null)
                                    return desCompleta.includes(text);
                            });
                            /**
                             * Si no existe la calle les dejo meter a mano el codigo postal
                             */
                            (ret.length === 0) ? ($scope.disabledCodPostalTitular = false) : ($scope.disabledCodPostalTitular = true);
                            return ret;
                        };
                        $scope.ListadoCallesTitularBloqueado = false;
                        $scope.spinnerListadoCallesTitular = false;

                        /**
                        * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
                        * Si boras el timeout NO VA
                        */
                        setTimeout(function () {
                            document.querySelector('#CalleTitular').focus();
                        }, 0);
                    });
                }
            }
        }
    }

    $scope.cargarValoresTitular = function (item) {
        if (!isJsonVacio(item)) {
            /**
             * Si no es null que rellene el codigo postal
             */
            //TODO: Da error de consola, pero funciona, mirar como controlar ese nulo
            if (item.desCalle.codigoPostal.length < 5) {
                $scope.DataTitular.CodPostalTitular = angular.copy("0" + item.desCalle.codigoPostal);
            } else {
                $scope.DataTitular.CodPostalTitular = angular.copy(item.desCalle.codigoPostal);
            }

            if (item.desCompleta != null) {
                if (item.desCompleta.tipoCalle == undefined || item.desCompleta.tipoCalle == null || item.desCompleta.tipoCalle == "") {
                    $scope.textoViaTitular = $scope.textoViaSuministro;
                } else {
                    $scope.textoViaTitular = item.desCompleta.tipoCalle;
                }
            } else {
                $scope.textoViaTitular = "CALLE";
            }
        } else {
            $scope.textoViaTitular = "CALLE";
        }
    };

    $scope.cargarCiudadEnvio = function (ciudad) {
        if (!isJsonVacio(ciudad)) {
            //TODO: Ver como mejorar esto para que cuando busque o copie la estructura no sea diferente
            //TODO: Creo que es quitando un desciudad del html, pero revisarlo antes.
            if (ciudad.desCiudad.desCiudad.desProvincia != null) {
                $scope.callejeroINEEnvio = {
                    "codCiudad": angular.copy(ciudad.desCiudad.desCiudad.codCiudad),
                    "desCiudad": angular.copy(ciudad.desCiudad.desCiudad.desCiudad),
                    "codPais": angular.copy(ciudad.desCiudad.desCiudad.codPais),
                    "desPais": angular.copy(ciudad.desCiudad.desCiudad.desPais),
                    "codProvincia": angular.copy(ciudad.desCiudad.desCiudad.codProvincia),
                    "desProvincia": angular.copy(ciudad.desCiudad.desCiudad.desProvincia),
                    "codMunicipio": angular.copy(ciudad.desCiudad.desCiudad.codMunicipio),
                    "desMunicipio": angular.copy(ciudad.desCiudad.desCiudad.desMunicipio),
                    "codigoProveedorProvincia": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorProvincia),
                    "codigoProveedorMunicipio": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorMunicipio),
                    "codigoProveedorCiudad": angular.copy(ciudad.desCiudad.desCiudad.codigoProveedorCiudad)
                }
                if ($scope.DataEnvio.ProvinciaEnvio != ciudad.desCiudad.desCiudad.desProvincia) {
                    $scope.DataEnvio.ProvinciaEnvio = ciudad.desCiudad.desCiudad.desProvincia;
                }
                $scope.codProvinciaEnvio = ciudad.desCiudad.desCiudad.codProvincia;
                $scope.codMunicipioEnvio = ciudad.desCiudad.desCiudad.codMunicipio;
            }
        }

        /**
         * Si los dos codigos no son != de undefined que no haga la llamada, solo provoca errores de consola
         */

        if (!$scope.bloqueoCalleEnvioCopiando) {
            if ($scope.codMunicipioEnvio != null && $scope.codProvinciaEnvio != null) {

                $scope.ListadoCallesEnvioBloqueado = true;
                $scope.spinnerListadoCallesEnvio = true;

                $http({
                    method: "GET",
                    url: urlCallejeroNew + "Calle/GetDTOALLCalles/CodigoMunicipio/" + $scope.codMunicipioEnvio + "/CodProvincia/" + $scope.codProvinciaEnvio
                }).then(function (response) {
                    $scope.buscadorCallesEnvio = response.data; //Aqui guardamos el array de calles
                    $scope.obtenerCallesEnvio = function (text) {
                        text = text.toUpperCase();
                        var ret = $scope.buscadorCallesEnvio.filter(function (d) {
                            var desCompleta = d.tipoCalle + ' ' + d.desCalle + ' ' + d.codigoPostal;
                            if (desCompleta !== null)
                                return desCompleta.includes(text);
                        });
                        /**
                         * Si no existe la calle les dejo meter a mano el codigo postal
                         */
                        (ret.length === 0) ? ($scope.disabledCodPostalEnvio = false) : ($scope.disabledCodPostalEnvio = true);
                        return ret;
                    };
                    $scope.ListadoCallesEnvioBloqueado = false;
                    $scope.spinnerListadoCallesEnvio = false;

                    /**
                     * Hago focus sobre el campo, con Timeout para que renderice antes el md-autocomplete
                     * Si boras el timeout NO VA
                     */
                    setTimeout(function () {
                        document.querySelector('#CalleEnvio').focus();
                    }, 0);
                });
            }
        }
    }

    $scope.cargarValoresEnvio = function (item) {
        if (item.desCalle != null) {
            //TODO: Da error de consola, pero funciona, mirar como controlar ese nulo
            if (item.desCalle.codigoPostal.length < 5) {
                $scope.DataEnvio.CodPostalEnvio = angular.copy("0" + item.desCalle.codigoPostal);
            } else {
                $scope.DataEnvio.CodPostalEnvio = angular.copy(item.desCalle.codigoPostal);
            }
            if (item.desCalle.tipoCalle == undefined || item.desCalle.tipoCalle == null || item.desCalle.tipoCalle == "") {
                $scope.textoViaEnvio = $scope.textoViaTitular;
            } else {
                $scope.textoViaEnvio = item.desCalle.tipoCalle;
            }
        } else {
            $scope.textoViaEnvio = "CALLE";
        }
    };

    // Función para validar caracteres válidos
    $scope.validarCaracter = function ($event) {
        var regex = new RegExp("[A-Za-zÀ-ÖØ-öø-ÿ-@-ñ-Ñ ]|[0-9]");
        var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
        if (!regex.test(key)) {
            $event.preventDefault();
            return false;
        }
    }

    $scope.validarCambioTelefono = function (telefono) {
        var maxsize = 0;
        // Comprobar telefono numeric
        if (!isNaN(telefono)) {
            maxsize = telefono.length;
        }
        if (!$scope.altaContratoForm.TelefonoTitular.$viewValue) { } else if (0 < $scope.altaContratoForm.TelefonoTitular.$viewValue.length) {
            maxsize = $scope.altaContratoForm.TelefonoTitular.$viewValue.length;
        }
        if (!$scope.altaContratoForm.MovilTitular.$viewValue) { } else if (maxsize < $scope.altaContratoForm.MovilTitular.$viewValue.length) {
            maxsize = $scope.altaContratoForm.MovilTitular.$viewValue.length;
        }
        if (9 <= maxsize) {
            $scope.requireFijoOrMovil = false;
        } else {
            $scope.requireFijoOrMovil = true;
        }
        return;
    };
    // Función para validar caracteres válidos
    $scope.validarCaracterTelefono = function ($event) {
        var regex = new RegExp("[0-9]");
        var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
        if (!regex.test(key)) {
            $event.preventDefault();
            return false;
        }
    }

    $scope.copiarDatosEnRepresentante = function(){
        if($scope.DataTitular.Identificador != undefined && $scope.CopiarDatosTitularEnRepresentante)
        {
           var validacionIdentidad = servicioValidaciones.ValidateSpanishID($scope.DataTitular.Identificador.toUpperCase());

            if(validacionIdentidad.valid && (validacionIdentidad.type == "nie" || validacionIdentidad.type == "dni"))
            {
                $scope.IdentificadorRepresentante = angular.copy($scope.DataTitular.Identificador);
                $scope.NombreRepresentante = angular.copy($scope.DataTitular.NombreTitular + " " + $scope.DataTitular.Apellido1Titular + " " + $scope.DataTitular.Apellido2Titular);
                $scope.SMSRepresentante = angular.copy(($scope.DataTitular.MovilTitular) ? $scope.DataTitular.MovilTitular : $scope.DataTitular.TelefonoTitular);
                $scope.EmailRepresentante =  angular.copy($scope.DataTitular.EmailTitular);
            }else
            {
                $scope.IdentificadorRepresentante = '';
                $scope.NombreRepresentante = '';
                $scope.SMSRepresentante = '';
                $scope.EmailRepresentante = '';
            }
        }

    }

}]);

app.directive('fileReader', function () {
    return {
        scope: {
            fileReader: "="
        },
        link: function (scope, element) {
            $(element).on('change', function (changeEvent) {
                var files = changeEvent.target.files;
                if (files.length) {
                    var r = new FileReader();
                    r.onload = function (e) {
                        // console.log(e);
                        var contents = e.target.result;
                        // console.log(scope.$parent.altaContratoForm);

                        if (scope.$parent.altaContratoForm != undefined) {

                            scope.$parent.altaContratoForm.Identificador.$setValidity('required', true);

                            scope.$apply(function () {
                                var contenido = contents.split("Código Vendedor");
                                var variables = contenido[1].split(";");

                                //var altaContratoForm = $scope.altaContratoForm;

                                var cups = document.getElementsByName("Cups")[0];
                                var codDistribuidora = document.getElementsByName("Distribuidora")[0];
                                var codComercializadora = document.getElementsByName("Comercializadora")[0];
                                var ciudad = document.getElementsByName("Ciudad")[0];
                                var calle = document.getElementsByName("Calle")[0];
                                var numero = document.getElementsByName("Numero")[0];
                                var aclarador = document.getElementsByName("Aclarador")[0];
                                var codPostal = document.getElementsByName("CodPostal")[0];
                                var provincia = document.getElementsByName("Provincia")[0];
                                var tarifa = document.getElementsByName("TarifaAccesoElectricidad")[0];
                                var grupoTarifa = document.getElementsByName("GrupoTarifa")[0];
                                var perfilFacturacion = document.getElementsByName("PerfilFacturacion")[0];
                                var p1 = document.getElementsByName("PotenciaP1")[0];
                                var p2 = document.getElementsByName("PotenciaP2")[0];
                                var p3 = document.getElementsByName("PotenciaP3")[0];
                                var p4 = document.getElementsByName("PotenciaP4")[0];
                                var p5 = document.getElementsByName("PotenciaP5")[0];
                                var p6 = document.getElementsByName("PotenciaP6")[0];
                                var grupoProducto = document.getElementsByName("GrupoProducto")[0];
                                var producto = document.getElementsByName("ProductoElectricidad")[0];
                                var identificador = document.getElementsByName("Identificador")[0];
                                var nombreTitular = document.getElementsByName("NombreTitular")[0];
                                var apellido1 = document.getElementsByName("Apellido1Titular")[0];
                                var apellido2 = document.getElementsByName("Apellido2Titular")[0];
                                var razonSocial = document.getElementsByName("RazonSocialTitular")[0];
                                var codPostalTitular = document.getElementsByName("CodPostalTitular")[0];
                                var ciudadTitular = document.getElementsByName("CiudadTitular")[0];
                                var calleTitular = document.getElementsByName("CalleTitular")[0];
                                var numeroTitular = document.getElementsByName("NumeroTitular")[0];
                                var aclaradorTitular = document.getElementsByName("AclaradorTitular")[0];
                                var provinciaTitular = document.getElementsByName("ProvinciaTitular")[0];
                                var telefonoTitular = document.getElementsByName("TelefonoTitular")[0];
                                var emailTitular = document.getElementsByName("EmailTitular")[0];
                                var nombreEnvio = document.getElementsByName("NombreEnvio")[0];
                                var codPostalEnvio = document.getElementsByName("CodPostalEnvio")[0];
                                var ciudadEnvio = document.getElementsByName("CiudadEnvio")[0];
                                var calleEnvio = document.getElementsByName("CalleEnvio")[0];
                                var numeroEnvio = document.getElementsByName("NumeroEnvio")[0];
                                var aclaradorEnvio = document.getElementsByName("AclaradorEnvio")[0];
                                var provinciaEnvio = document.getElementsByName("ProvinciaEnvio")[0];
                                var observaciones = document.getElementsByName("Observaciones")[0];
                                var nombrePagador = document.getElementsByName("NombrePagador")[0];
                                var identificadorPagador = document.getElementsByName("IdentificadorPagador")[0];
                                var tipoCobro = document.getElementsByName("TextoTipoCobro")[0];
                                var iban = document.getElementsByName("IBANPagador")[0];
                                var cnae = document.getElementsByName("CNAE")[0];
                                var periodoTarifa = document.getElementsByName("PeriodoTarifa")[0];
                                var tipoImpresion = document.getElementsByName("TipoImpresion")[0];
                                var tipoSolicitud = document.getElementsByName("TipoSolicitud")[0];
                                var modeloContrato = document.getElementsByName("ModeloDeContrato")[0];
                                var idiomaContrato = document.getElementsByName("IdiomaContrato")[0];
                                var codigoVendedor = document.getElementsByName("CodigoVendedor")[0];

                                var evt = new CustomEvent('change');

                                cups.value = variables[0];
                                cups.dispatchEvent(evt);
                                codDistribuidora.value = variables[1];
                                codDistribuidora.dispatchEvent(evt);
                                codComercializadora.value = variables[2];
                                codComercializadora.dispatchEvent(evt);
                                ciudad.value = variables[3];
                                ciudad.dispatchEvent(evt);
                                calle.value = variables[4];
                                calle.dispatchEvent(evt);
                                numero.value = variables[5];
                                numero.dispatchEvent(evt);
                                aclarador.value = variables[6];
                                aclarador.dispatchEvent(evt);
                                codPostal.value = variables[7];
                                codPostal.dispatchEvent(evt);
                                provincia.value = variables[8];
                                provincia.dispatchEvent(evt);
                                tarifa.value = variables[9];
                                tarifa.dispatchEvent(evt);
                                grupoTarifa.value = variables[10];
                                grupoTarifa.dispatchEvent(evt);
                                perfilFacturacion.value = variables[11];
                                perfilFacturacion.dispatchEvent(evt);
                                p1.value = variables[12];
                                p1.dispatchEvent(evt);
                                p2.value = variables[13];
                                p2.dispatchEvent(evt);
                                p3.value = variables[14];
                                p3.dispatchEvent(evt);
                                p4.value = variables[15];
                                p4.dispatchEvent(evt);
                                p5.value = variables[16];
                                p5.dispatchEvent(evt);
                                p6.value = variables[17];
                                p6.dispatchEvent(evt);
                                grupoProducto.value = variables[18];
                                grupoProducto.dispatchEvent(evt);
                                producto.value = variables[19];
                                producto.dispatchEvent(evt);
                                identificador.value = variables[20];
                                identificador.dispatchEvent(evt);
                                scope.$parent.altaContratoForm.Identificador.$setValidity('required', true);
                                identificador.parentElement.className += " md-input-has-value";
                                nombreTitular.value = variables[21];
                                nombreTitular.dispatchEvent(evt);
                                apellido1.value = variables[22];
                                apellido1.dispatchEvent(evt);
                                apellido2.value = variables[23];
                                apellido2.dispatchEvent(evt);
                                razonSocial.value = variables[24];
                                razonSocial.dispatchEvent(evt);
                                codPostalTitular.value = variables[25];
                                codPostalTitular.dispatchEvent(evt);
                                ciudadTitular.value = variables[26];
                                ciudadTitular.dispatchEvent(evt);
                                calleTitular.value = variables[27];
                                calleTitular.dispatchEvent(evt);
                                numeroTitular.value = variables[28];
                                numeroTitular.dispatchEvent(evt);
                                aclaradorTitular.value = variables[29];
                                aclaradorTitular.dispatchEvent(evt);
                                provinciaTitular.value = variables[30];
                                provinciaTitular.dispatchEvent(evt);
                                telefonoTitular.value = variables[31];
                                telefonoTitular.dispatchEvent(evt);
                                emailTitular.value = variables[32];
                                emailTitular.dispatchEvent(evt);
                                nombreEnvio.value = variables[33];
                                nombreEnvio.dispatchEvent(evt);
                                codPostalEnvio.value = variables[34];
                                codPostalEnvio.dispatchEvent(evt);
                                ciudadEnvio.value = variables[35];
                                ciudadEnvio.dispatchEvent(evt);
                                calleEnvio.value = variables[36];
                                calleEnvio.dispatchEvent(evt);
                                numeroEnvio.value = variables[37];
                                numeroEnvio.dispatchEvent(evt);
                                aclaradorEnvio.value = variables[38];
                                aclaradorEnvio.dispatchEvent(evt);
                                provinciaEnvio.value = variables[39];
                                provinciaEnvio.dispatchEvent(evt);
                                observaciones.value = variables[40];
                                observaciones.dispatchEvent(evt);
                                nombrePagador.value = variables[41];
                                nombrePagador.dispatchEvent(evt);
                                identificadorPagador.value = variables[42];
                                identificadorPagador.dispatchEvent(evt);
                                tipoCobro.value = variables[43];
                                tipoCobro.dispatchEvent(evt);
                                iban.value = variables[44];
                                iban.dispatchEvent(evt);
                                cnae.value = variables[45];
                                cnae.dispatchEvent(evt);
                                periodoTarifa.value = variables[46];
                                periodoTarifa.dispatchEvent(evt);
                                tipoImpresion.value = variables[47];
                                tipoImpresion.dispatchEvent(evt);
                                tipoSolicitud.value = variables[48];
                                tipoSolicitud.dispatchEvent(evt);
                                modeloContrato.value = variables[49];
                                modeloContrato.dispatchEvent(evt);
                                idiomaContrato.value = variables[50];
                                idiomaContrato.dispatchEvent(evt);
                                codigoVendedor.value = variables[51];
                                codigoVendedor.dispatchEvent(evt);
                                MotivoModificacion.value = variables[52];
                                MotivoModificacion.dispatchEvent(evt);

                            });
                        }
                    };

                    r.readAsText(files[0]);
                }
            });
        }
    };
});