app.controller('TeleventaCtrl', ['$scope', '$http', 'TraspasoLeadContrato', '$rootScope', '$location', '$window', '$state', function ($scope, $http, TraspasoLeadContrato, $rootScope, $location, $window, $state) {
    'use strict';

    var NombreAgente = atob(localStorage.getItem('nombreAgente'));
    var Logueado = localStorage.getItem('logueado');
    var TipoAgente = atob(localStorage.getItem('tipoAgente'));

    $scope.MostrarTeleventaNombreCreador = MostrarTeleventaNombreCreador;
    $scope.MostrarTeleventaAsignado = MostrarTeleventaAsignado;
    $scope.MostrarTeleventaDni = MostrarTeleventaDni;
    $scope.MostrarTeleventaNombre = MostrarTeleventaNombre;
    $scope.MostrarTeleventaApellidos = MostrarTeleventaApellidos;
    $scope.MostrarTeleventaEmail = MostrarTeleventaEmail;
    $scope.MostrarTeleventaTelefono = MostrarTeleventaTelefono;
    $scope.MostrarTeleventaCodPostal = MostrarTeleventaCodPostal;
    $scope.MostrarTeleventaPoblacion = MostrarTeleventaPoblacion;
    $scope.MostrarTeleventaProvincia = MostrarTeleventaProvincia;
    $scope.MostrarTeleventaNombreVia = MostrarTeleventaNombreVia;
    $scope.MostrarTeleventaNumeroVia = MostrarTeleventaNumeroVia;
    $scope.MostrarTeleventaInformacionAdicional = MostrarTeleventaInformacionAdicional;
    $scope.MostrarTeleventaCups = MostrarTeleventaCups;
    $scope.MostrarTeleventaTarifaAcceso = MostrarTeleventaTarifaAcceso;
    $scope.MostrarTeleventaDireccionSuministro = MostrarTeleventaDireccionSuministro;
    $scope.MostrarTeleventaIban = MostrarTeleventaIban;
    $scope.MostrarTeleventaNombreTarifa = MostrarTeleventaNombreTarifa;
    $scope.MostrarTeleventaP1 = MostrarTeleventaP1;
    $scope.MostrarTeleventaP2 = MostrarTeleventaP2;
    $scope.MostrarTeleventaP3 = MostrarTeleventaP3;
    $scope.MostrarTeleventaP4 = MostrarTeleventaP4;
    $scope.MostrarTeleventaP5 = MostrarTeleventaP5;
    $scope.MostrarTeleventaP6 = MostrarTeleventaP6;
    $scope.MostrarTeleventaFechaInsercion = MostrarTeleventaFechaInsercion;
    $scope.MostrarTeleventaHorarioLlamada = MostrarTeleventaHorarioLlamada;
    $scope.MostrarTeleventaEstado = MostrarTeleventaEstado;
    $scope.MostrarTeleventaPlataforma = MostrarTeleventaPlataforma;
    $scope.MostrarTeleventaTarjetaDisa = MostrarTeleventaTarjetaDisa;
    $scope.MostrarTeleventaNombreArchivo = MostrarTeleventaNombreArchivo;
    $scope.MostrarTeleventaBono = MostrarTeleventaBono;
    $scope.MostrarTeleventaCodigoEmpleado = MostrarTeleventaCodigoEmpleado;
    $scope.MostrarTeleventaSegmento = MostrarTeleventaSegmento;
    $scope.MostrarTeleventaTipoCliente = MostrarTeleventaTipoCliente;
    $scope.MostrarTeleventaCanalOrigen = MostrarTeleventaCanalOrigen;
    $scope.MostrarTeleventaSubcanalOrigen = MostrarTeleventaSubcanalOrigen;
    $scope.MostrarTeleventaCodigoPromocional = MostrarTeleventaCodigoPromocional;

    $scope.MostrarTeleventaEliminar = MostrarTeleventaEliminar;
    $scope.MostrarTeleventaInsertar = MostrarTeleventaInsertar;
    $scope.MostrarTeleventaOferta = MostrarTeleventaOferta;
    $scope.MostrarTeleventaConfirmado = MostrarTeleventaConfirmado;
    $scope.MostrarTeleventaValidado = MostrarTeleventaValidado;
    $scope.MostrarTeleventaFalloLead = MostrarTeleventaFalloLead;
    $scope.MostrarTeleventaRechazaOferta = MostrarTeleventaRechazaOferta;
    $scope.MostrarTeleventaNoConfirmado = MostrarTeleventaNoConfirmado;
    $scope.MostrarTeleventaNoValidado = MostrarTeleventaNoValidado;
    $scope.MenuTeleventaFiltrado = MenuTeleventaFiltrado;
    $scope.URLAdjuntosLeads = URLAdjuntosLeads;

    $scope.MenuAltaRapida = MenuAltaRapida;

	$scope.VerEnergia = localStorage.getItem('idEmpresaEnergia');
    $scope.VerGas = localStorage.getItem('idEmpresaGas');

    if (TipoAgente == "3" && $scope.MenuTeleventaFiltrado) {
        $scope.MostrarTeleventaInsertar = false;
        $scope.MostrarTeleventaOferta = false;
        $scope.MostrarTeleventaConfirmado = false;
        $scope.MostrarTeleventaValidado = false;
        $scope.MostrarTeleventaRechazaOferta = false;
        $scope.MostrarTeleventaNoConfirmado = false;
        $scope.MostrarTeleventaNoValidado = false;
    }

    $scope.autocolumn = [{
        name: "",
        display: "",
        oculta: "Eliminar"
    }, {
        name: "",
        display: "",
        oculta: "Insertar"
    }, {
        name: "Oferta",
        display: "Oferta",
        oculta: "Oferta"
    }, {
        name: "Confirmado",
        display: "Confirmado",
        oculta: "Confirmado"
    }, {
        name: "Validado",
        display: "Validado",
        oculta: "Validado"
    }, {
        name: "Fallo Lead",
        display: "Fallo Lead",
        oculta: "FalloLead"
    }, {
        name: "Rechaza Oferta",
        display: "Rechaza Oferta",
        oculta: "RechazaOferta"
    }, {
        name: "No Confirmado",
        display: "No Confirmado",
        oculta: "NoConfirmado"
    }, {
        name: "No Validado",
        display: "No Validado",
        oculta: "NoValidado"
    }, {
        name: "NombreCreador",
        display: "Nombre Creador",
        oculta: "NombreCreador"
    }, {
        name: "Asignado",
        display: "Asignado",
        oculta: "Asignado"
    }, {
        name: "Dni",
        display: "Dni",
        oculta: "Dni"
    }, {
        name: "Nombre",
        display: "Nombre",
        oculta: "Nombre"
    }, {
        name: "Apellidos",
        display: "Apellidos",
        oculta: "Apellidos"
    }, {
        name: "Email",
        display: "Email",
        oculta: "Email"
    }, {
        name: "Telefono",
        display: "Telefono",
        oculta: "Telefono"
    }, {
        name: "FechaInsercion",
        display: "Fecha Insercion",
        oculta: "FechaInsercion"
    }, {
        name: "HorarioLlamada",
        display: "Horario Llamada",
        oculta: "HorarioLlamada"
    }, {
        name: "Estado",
        display: "Estado",
        oculta: "Estado"
    }, {
        name: "CodPostal",
        display: "Cod Postal",
        oculta: "CodPostal"
    }, {
        name: "Poblacion",
        display: "Poblacion",
        oculta: "Poblacion"
    }, {
        name: "Provincia",
        display: "Provincia",
        oculta: "Provincia"
    }, {
        name: "NombreVia",
        display: "Nombre Via",
        oculta: "NombreVia"
    }, {
        name: "NumeroVia",
        display: "Numero Via",
        oculta: "NumeroVia"
    }, {
        name: "InformacionAdicional",
        display: "Informacion Adicional",
        oculta: "InformacionAdicional"
    }, {
        name: "Cups",
        display: "Cups",
        oculta: "Cups"
    }, {
        name: "TarifaAcceso",
        display: "Tarifa Acceso",
        oculta: "TarifaAcceso"
    }, {
        name: "DireccionSuministro",
        display: "Direccion Suministro",
        oculta: "DireccionSuministro"
    }, {
        name: "Iban",
        display: "Iban",
        oculta: "Iban"
    }, {
        name: "NombreTarifa",
        display: "Nombre Tarifa",
        oculta: "NombreTarifa"
    }, {
        name: "P1",
        display: "P1",
        oculta: "P1"
    }, {
        name: "P2",
        display: "P2",
        oculta: "P2"
    }, {
        name: "P3",
        display: "P3",
        oculta: "P3"
    }, {
        name: "P4",
        display: "P4",
        oculta: "P4"
    }, {
        name: "P5",
        display: "P5",
        oculta: "P5"
    }, {
        name: "P6",
        display: "P6",
        oculta: "P6"
    }, {
        name: "Plataforma",
        display: "Plataforma",
        oculta: "Plataforma"
    }, {
        name: "TarjetaDisa",
        display: "Tarjeta Disa",
        oculta: "TarjetaDisa"
    }, {
        name: "NombreArchivo",
        display: "Nombre Archivo",
        oculta: "NombreArchivo"
    }, {
        name: "Bono",
        display: "Bono",
        oculta: "Bono"
    }, {
        name: "CodigoEmpleado",
        display: "Codigo Empleado",
        oculta: "CodigoEmpleado"
    }, {
        name: "Segmento",
        display: "Segmento",
        oculta: "Segmento"
    }, {
        name: "TipoCliente",
        display: "Tipo de cliente",
        oculta: "TipoCliente"
    }, {
        name: "CanalOrigen",
        display: "Canal de origen",
        oculta: "CanalOrigen"
    }, {
        name: "SubcanalOrigen",
        display: "Subcanal de origen",
        oculta: "SubcanalOrigen"
    }, {
        name: "CodigoPromocional",
        display: "Codigo promocional",
        oculta: "CodigoPromocional"
    }];

    $scope.DarAltaLead = function (lead) {
        $rootScope.AltaLead = lead.dato;
		if($scope.VerEnergia == 'true'){
			localStorage.setItem('tipoAlta', btoa('e'));
		} else if($scope.VerGas == 'true'){
			localStorage.setItem('tipoAlta', btoa('g'));
		}
        ($scope.MenuAltaRapida) ? $location.url("/contrato/alta-rapida") : $location.url("/contratos/alta");
    }

    $scope.televentaLeads = function (datos) {
        $location.url("/lead/leads");
    };

    $scope.selected = [];

    $scope.exportarDatosTeleventa = function (selected) {
        var televenta = [];

        selected.forEach(function (obj) {
            televenta.push({
                "Dni": obj.Dni,
                "Nombre": obj.Nombre,
                "Apellidos": obj.Apellidos,
                "Email": obj.Email,
                "Telefono": obj.Telefono,
                "Fecha Insercion": obj.FechaInsercion,
                "Horario Llamada": obj.HorarioLlamada,
                "Estado": obj.Estado,
                "Codigo Postal": obj.CodPostal,
                "Poblacion": obj.Poblacion,
                "Provincia": obj.Provincia,
                "Nombre Via": obj.NombreVia,
                "Numero Via": obj.NumeroVia,
                "Informacion Adicional": obj.InformacionAdicional,
                "Cups": obj.Cups,
                "Tarifa Acceso": obj.TarifaAcceso,
                "Direccion Suministro": obj.DireccionSuministro,
                "Iban": obj.Iban,
                "Nombre Tarifa": obj.NombreTarifa,
                "P1": obj.P1,
                "P2": obj.P2,
                "P3": obj.P3,
                "P4": obj.P4,
                "P5": obj.P5,
                "P6": obj.P6,
                "Plataforma": obj.Plataforma,
            });
        });

        $("#dvjson")
            .excelexportjs({
                containerid: "dvjson",
                datatype: 'json',
                dataset: televenta,
                columns: getColumns(televenta)
            });
    };

    $scope.refrescar = function () {
        var fecha = new Date();
        var fechaFormateada = fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
        var horaFormateada = fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
        $rootScope.UltimaHoraRefresco = "Último refresco: " + fechaFormateada + " " + horaFormateada;
        $state.go("lead", {}, { reload: true });
    };

    $scope.abrirDocumento = function (fichero, id_lead) {
        window.open('lib/php/subir_documentacion_lead.php?abrir=true&lead=' + id_lead, '_blank');
    };

    if (Logueado == 'true') {
		var entorno = 'e';
		if ($scope.VerGas == 'true'){
			entorno = 'g';
		}
        $http.get("lib/php/listado_leads.php", { params: { "NombreAgente": NombreAgente, "Entorno": entorno } }).then(function (response) {
            $scope.datos = response.data;
        });
    }

    $scope.confirmarLead = function (lead) {
        $.post("lib/php/confirmar_lead.php", lead.dato, function (data) { });
    }

    $scope.confirmarOferta = function (lead) {
        $.post("lib/php/confirmar_oferta.php", lead.dato, function (data) { });
    }

    $scope.validarLead = function (lead) {
        $.post("lib/php/validar_lead.php", lead.dato, function (data) { });
    }

    $scope.KOLead = function (lead) {
        $.post("lib/php/rechazar_lead.php", lead.dato, function (data) {
            $state.go("lead", {}, { reload: true })
        });
    }

    $scope.KOOferta = function (lead) {
        $.post("lib/php/rechazar_oferta.php", lead.dato, function (data) { });
    }

    $scope.KOConfirmado = function (lead) {
        $.post("lib/php/rechazar_confirmado.php", lead.dato, function (data) { });
    }

    $scope.KOValidado = function (lead) {
        $.post("lib/php/rechazar_validado.php", lead.dato, function (data) { });
    }

    $scope.updateAsignado = function (lead) {
        $.post("lib/php/actualizar_asignado.php", { "Id": lead.dato.Id, "Asignado": lead.Asignado }, function (data) { });
    }

    $scope.eliminarLead = function (lead) {
        $.post("lib/php/eliminar_lead.php", { "Id": lead.dato.Id }, function (data) {
            $state.go("lead", {}, { reload: true })
        });
    }

    $scope.seleccionaTodos = function () {
        $scope.datos.forEach(function (item) {
            $scope.selected.push(item);
        });
    }
    $scope.deseleccionaTodos = function () {
        $scope.selected = [];
    }
}]);