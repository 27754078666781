app.controller("ComparadorGasCtrl", ['$scope', '$http', '$timeout', 'cfpLoadingBar', 'serviciosApi', 'jwtHelper', '$mdDialog', '$rootScope', '$location', 'servicioListadoDistribuidoras', 'servicioListadoTarifas', function($scope, $http, $timeout, cfpLoadingBar, serviciosApi, jwtHelper, $mdDialog, $rootScope, $location, servicioListadoDistribuidoras, servicioListadoTarifas) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdAgente = localStorage.getItem('idAgente');
    var IdEmpresa = localStorage.getItem('idEmpresa');
    var Logueado = localStorage.getItem('logueado');
    var NombreAgente = atob(localStorage.getItem('nombreAgente'));
    var VerEnergia;
    var VerGas;
    moment().locale('es');

    // console.log(localStorage);
    // console.log('VerGas');

    $scope.today = new Date();
    $scope.nombreEmpresa = nombreEmpresa;

    $scope.loading = false;

    // variables comparador
    $scope.impuestoElectrico = "5.11269632";
    $scope.impuestoHidrocarburos = "0.00234";

    // LUZ
    $scope.baseImpuestoElectrico = 0;
    $scope.baseConImpuestoElectrico = 0;

    $scope.baseImpuestoElectricoSuyo = 0;
    $scope.baseConImpuestoElectricoSuyo = 0;
    //

    //GAS
    $scope.baseImpuestoHidrocarburos = 0;
    $scope.baseConImpuestoHidrocarburos = 0;

    $scope.baseImpuestoHidrocarburosSuyo = 0;
    $scope.baseConImpuestoHidrocarburosSuyo = 0;
    //

    $scope.baseImponibleNuestra = 0;
    $scope.baseImponibleSuya = 0;

    $scope.iva = 21;

    $scope.baseImponibleConIVANuestra = 0;
    $scope.baseImponibleConIVASuya = 0;

    $scope.totalNuestro = 0;
    $scope.totalSuyo = 0;

    $scope.costeAlquilerPropio = 0;
    $scope.serviciosAdicionalesPropios = 0;

    $scope.costeAlquilerSuyo = 0;
    $scope.serviciosAdicionalesSuyo = 0;

    $scope.suComercializadoraPreciosPotencia = [];
    $scope.suComercializadoraPreciosEnergia = [];

    $scope.diferenciaAhorroAnual = 0;
    $scope.diferenciaAhorroPorcentaje = 0;
    $scope.diferenciaPrecioPeriodo = 0;

    $scope.totalDias = 0;

    $scope.grupoTarifaTexto = "";

    // DESCUENTOS
    $scope.descuentoTerminoPotenciaSuyo = 0;
    $scope.descuentoTerminoEnergiaSuyo = 0;

    //IMPUESTO ESPECIAL
    $scope.impuestoExtraPrecioEnergia = impuestoExtraPrecioEnergia;

    $scope.AdjuntarFicheroComparador = AdjuntarFicheroComparador;

    // VARIABLES PARA MOSTRAR U OCULTAR INFORMACION
    $scope.MostrarComparadorGasCaudalContratado = MostrarComparadorGasCaudalContratado;
    $scope.MostrarComparadorGasCaudalFacturado = MostrarComparadorGasCaudalFacturado;
    $scope.MostrarComparadorGasConCalculoTerminoFijo = MostrarComparadorGasConCalculoTerminoFijo;
    $scope.ComparadorGasCalculoConTerminoFijo = ComparadorGasCalculoConTerminoFijo;


    // ARCHIVOS PARA LEADS
    // var fichero = document.getElementById("file-upload");
    // var file = fichero.files[0];
    // var ficheroData = new FormData();
    // ficheroData.append("fichero", file);
    // ficheroData.append("nombreTitular", nombreTitular);

    // Default export is a4 paper, portrait, using milimeters for units
    var pdf = new jsPDF();
    var source = "";

    $scope.selected = [];
    $scope.tablaConsumos = [];
    $scope.busquedaConsumos = [];

    $scope.informacionSipsCliente = informacionSipsCliente;
    $scope.TextoDescargarSips = TextoDescargarSips;

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') == 'true') {
        $scope.VerEnergia = true;
        $scope.VerGas = false;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') == 'true') {
        $scope.VerGas = true;
        $scope.VerEnergia = false;
    }

    $scope.distribuidoras = servicioListadoDistribuidoras.getListadoDistribuidoras();

    $scope.obtenerDistribuidoras = function(text) {
        text = text.toUpperCase();
        var ret = $scope.distribuidoras.filter(function(d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

    $scope.tarifas = servicioListadoTarifas.getListadoTarifas();

    $scope.obtenerTarifas = function(text) {
        text = text.toUpperCase();
        var ret = $scope.tarifas.filter(function(d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

    $scope.cliente = "";
    $scope.consumoAnual = 0;

    $scope.recalculoManual = 0; // variable calculoTerminoPotenciaSuyo y calculoTerminoEnergiaSuyo -> para poder meter los datos de termino potencia y termino energia manualmente para hacer los calbulos en facturacion comercializadora actual

    $scope.Cups = "";

    // $scope.Cups = "ES0238330000042418YV"; // GAS 3.1
    // $scope.Cups = "ES0217901000012583CM"; // GAS 2.3


    // $scope.formulas = ["BS3", "DSV", "EXD", "FOE", "FP1", "IN7", "MDI", "MIN", "PC3", "PS3", "RT3", "RT4", "RT6", "SI3"]; // IÑIGO ME HA FACILITADO ESTO SON LAS FORMULAS
    $scope.formulas = ["FOE", "BS3", "CFP", "FP1", "BCE", "MDI", "PC3", "PS3", "RT4", "RT3", "RT6", "EXD", "IN7", "SI3"]; // IÑIGO ME HA FACILITADO ESTO SON LAS FORMULAS

	$scope.MostrarSipsBuscadorCups = MostrarSipsBuscadorCups;
	$scope.MostrarSipsBuscadorDistribuidora = MostrarSipsBuscadorDistribuidora;
	$scope.MostrarSipsBuscadorCodigoPostal = MostrarSipsBuscadorCodigoPostal;
	$scope.MostrarSipsBuscadorDni = MostrarSipsBuscadorDni;
	$scope.MostrarSipsBuscadorDireccion = MostrarSipsBuscadorDireccion;
	$scope.MostrarSipsBuscadorTitular = MostrarSipsBuscadorTitular;
	$scope.MostrarSipsBuscadorTelefono = MostrarSipsBuscadorTelefono;
	$scope.MostrarSipsBuscadorTarifa = MostrarSipsBuscadorTarifa;
	$scope.MostrarSipsBuscadorListaCups = MostrarSipsBuscadorListaCups;
	$scope.MostrarSipsBuscadorGraficoConsumos = MostrarSipsBuscadorGraficoConsumos;

    $scope.MostrarSipsInfoGeneral = MostrarSipsInfoGeneral;
    $scope.MostrarSipsCups = MostrarSipsCups;
    $scope.MostrarSipsDistribuidora = MostrarSipsDistribuidora;
    $scope.MostrarSipsDireccionSuministro = informacionSipsCliente;
    $scope.MostrarSipsMunicipio = MostrarSipsMunicipio;
    $scope.MostrarSipsProvincia = MostrarSipsProvincia;
    $scope.MostrarSipsCodigoPostal = MostrarSipsCodigoPostal;
    $scope.MostrarSipsTarifa = MostrarSipsTarifa;
    $scope.MostrarSipsTension = MostrarSipsTension;
    $scope.MostrarSipsPresion = MostrarSipsPresion;
    $scope.MostrarSipsP1 = MostrarSipsP1;
    $scope.MostrarSipsP2 = MostrarSipsP2;
    $scope.MostrarSipsP3 = MostrarSipsP3;
    $scope.MostrarSipsP4 = MostrarSipsP4;
    $scope.MostrarSipsP5 = MostrarSipsP5;
    $scope.MostrarSipsP6 = MostrarSipsP6;
    $scope.MostrarSipsCaudal = MostrarSipsCaudal;
    $scope.MostrarSipsCaudalMaximo = MostrarSipsCaudalMaximo;
    $scope.MostrarSipsUltimoCambioComercializadora = MostrarSipsUltimoCambioComercializadora;
    $scope.MostrarSipsUltimoCambioBIE = MostrarSipsUltimoCambioBIE;
    $scope.MostrarSipsUltimoCambioContrato = MostrarSipsUltimoCambioContrato;
    $scope.MostrarSipsConsumoAnual = MostrarSipsConsumoAnual;
    $scope.MostrarSipsFechaFinContrato = MostrarSipsFechaFinContrato;
    $scope.MostrarSipsConsumoP1 = MostrarSipsConsumoP1;
    $scope.MostrarSipsConsumoP2 = MostrarSipsConsumoP2;
    $scope.MostrarSipsConsumoP3 = MostrarSipsConsumoP3;
    $scope.MostrarSipsConsumoP4 = MostrarSipsConsumoP4;
    $scope.MostrarSipsConsumoP5 = MostrarSipsConsumoP5;
    $scope.MostrarSipsConsumoP6 = MostrarSipsConsumoP6;
    $scope.MostrarSipsTrasladarContrato = MostrarSipsTrasladarContrato;

    $scope.comparadorLlamadaPreciosIndexadoEstandar = comparadorLlamadaPreciosIndexadoEstandar;

    $scope.sumaTarifaPeajeAPrecioEnergiaGas = sumaTarifaPeajeAPrecioEnergiaGas;

    $scope.selected = [];
    $scope.selectedConsumosPorPotencia = [];
	$scope.tablaConsumos = [];


    $scope.autocolumnTablaConsumos = [
    {
        name: "FechaInicio",
        display: "Fecha Inicio",
        oculta: "test"
    },{
        name: "FechaFin",
        display: "Fecha Fin",
        oculta: "test"
    }, {
        name: "ConsumoP1",
        display: "Consumo P1",
        oculta: "test"
    },
    // {
    //     name: "ConsumoP2",
    //     display: "Consumo P2",
    //     oculta: "test"
    // }, {
    //     name: "ConsumoP3",
    //     display: "Consumo P3",
    //     oculta: "test"
    // }, {
    //     name: "ConsumoP4",
    //     display: "Consumo P4",
    //     oculta: "test"
    // }, {
    //     name: "ConsumoP5",
    //     display: "Consumo P5",
    //     oculta: "test"
    // }, {
    //     name: "ConsumoP6",
    //     display: "Consumo P6",
    //     oculta: "test"
    // }
];

    $scope.autocolumnTablaConsumosAcumuladosYPotencias = [
    {
        name: "ConsumoP1",
        display: "P1",
        oculta: "test"
    }, {
        name: "ConsumoP2",
        display: "P2",
        oculta: "test"
    }, {
        name: "ConsumoP3",
        display: "P3",
        oculta: "test"
    }, {
        name: "ConsumoP4",
        display: "P4",
        oculta: "test"
    }, {
        name: "ConsumoP5",
        display: "P5",
        oculta: "test"
    }, {
        name: "ConsumoP6",
        display: "P6",
        oculta: "test"
	}];

    $scope.autocolumn = [
    {
        name: "InformacionGeneral",
        display: "Descarga Individual",
        oculta: "InfoGeneral"
    },{
        name: "Cups",
        display: "Cups",
        oculta: "Cups"
    }, {
        name: "DireccionSuministro",
        display: "Dirección Suministro",
        oculta: "DireccionSuministro"
    }, {
        name: "Municipio",
        display: "Municipio",
        oculta: "Municipio"
    }, {
        name: "Provincia",
        display: "Provincia",
        oculta: "Provincia"
    }, {
        name: "CodPostal",
        display: "Código Postal",
        oculta: "CodigoPostal"
    }, {
        name: "Tarifa",
        display: "Tarifa",
        oculta: "Tarifa"
    }, {
        name: "ConsumoAnual",
        display: "Consumo Anual",
        oculta: "ConsumoAnual  && VerEnergia"
    }, {
        name: "ConsumoP1",
        display: "Consumo Anual P1",
        oculta: "ConsumoP1  && VerEnergia"
    }, {
        name: "ConsumoP2",
        display: "Consumo Anual P2",
        oculta: "ConsumoP2  && VerEnergia"
    }, {
        name: "ConsumoP3",
        display: "Consumo Anual P3",
        oculta: "ConsumoP3  && VerEnergia"
    }, {
        name: "ConsumoP4",
        display: "Consumo Anual P4",
        oculta: "ConsumoP4  && VerEnergia"
    }, {
        name: "ConsumoP5",
        display: "Consumo Anual P5",
        oculta: "ConsumoP6  && VerEnergia"
    }, {
        name: "ConsumoP6",
        display: "Consumo Anual P6",
        oculta: "ConsumoP6  && VerEnergia"
    }, {
        name: "Caudal",
        display: "Caudal Horario",
        oculta: "Caudal  && VerGas"
    }, {
        name: "CaudalMaximo",
        display: "Caudal Máximo",
        oculta: "CaudalMaximo  && VerGas"
    }, {
        name: "P1",
        display: "P1",
        oculta: "P1  && VerEnergia"
    }, {
        name: "P2",
        display: "P2",
        oculta: "P2  && VerEnergia"
    }, {
        name: "P3",
        display: "P3",
        oculta: "P3  && VerEnergia"
    }, {
        name: "P4",
        display: "P4",
        oculta: "P4  && VerEnergia"
    }, {
        name: "P5",
        display: "P5",
        oculta: "P5  && VerEnergia"
    }, {
        name: "P6",
        display: "P6",
        oculta: "P6  && VerEnergia"
    }, {
        name: "Distribuidora",
        display: "Distribuidora",
        oculta: "Distribuidora"
    }, {
        name: "UltimoCambioComercializadora",
        display: "Último Cambio Comercializadora",
        oculta: "UltimoCambioComercializadora"
    }, {
        name: "UltimoCambioBIE",
        display: "Último Cambio BIE",
        oculta: "UltimoCambioBIE"
    }, {
        name: "Tension",
        display: "Tensión",
        oculta: "Tension && VerEnergia"
    }, {
        name: "Presion",
        display: "Presión",
        oculta: "Presion && VerGas"
    }, {
        name: "UltimoCambioContrato",
        display: "Último Cambio Contrato",
        oculta: "UltimoCambioContrato"
	}, {
        name: "TrasladarContrato",
        display: "Trasladar Contrato",
        oculta: "TrasladarContrato"
	}];

    $scope.MostrarCampos = function() {
		if ('' == $scope.Cups) {
			return;
		}
        // $scope.imprimirPdf(); // FUNCION PARA IMPRIMIR EN PDF Y DESCARGAR
        // $scope.toggleSidebar();
        if ($scope.clientesSips != undefined) {
            $scope.clientesSips = [];
            $scope.consumosAnuales = [];
            $scope.selected = [];
            $scope.selectedConsumosPorPotencia = [];
            $scope.tablaConsumos = [];
            $scope.ConsumosCliente = [];
            $scope.SumaConsumosPotencias = [];
		}
		$scope.consumosVisible = false;
        $scope.idlead = "";

		var Cups = ($scope.Cups === undefined) ? "" : $scope.Cups;
        var CodigoPostalPS = ($scope.CodigoPostalPS === undefined) ? "" : $scope.CodigoPostalPS;
        // var NombreEmpresaDistribuidora = ($scope.selectedItem === null) ? "" : $scope.selectedItem.value;
        var NombreEmpresaDistribuidora = "";
		var CodigoTarifaATREnVigor = $scope.selectedItemTarifa == undefined ? "" : $scope.selectedItemTarifa.value;
        var MunicipioPS = $scope.MunicipioPS == undefined ? "" : $scope.MunicipioPS.valor;
        var CodigoProvinciaPS = $scope.CodigoProvinciaPS == undefined ? "" : $scope.CodigoProvinciaPS.valor;
		// TODO: Nuevos inputs
		var DniTitular = ($scope.DniTitular === undefined) ? "" : $scope.DniTitular;
        var DireccionPS = ($scope.DireccionPS === undefined) ? "" : $scope.DireccionPS;
        var NombreTitular = ($scope.NombreTitular === undefined) ? "" : $scope.NombreTitular;
		var Telefono = ($scope.Telefono === undefined) ? "" : $scope.Telefono;

        var ListCUPS = $scope.ListCUPS == undefined ? "" : $scope.ListCUPS;
        var cups = "";
        var resultado = "";


        if (ListCUPS != "") {

            if (ListCUPS.indexOf('.') !== -1) {
                ListCUPS = ListCUPS.replace(/\./g, ",");
            }

            if (ListCUPS.indexOf(';') !== -1) {
                ListCUPS = ListCUPS.replace(/\;/g, ",");
            }

            if (ListCUPS.indexOf(' ') !== -1) {
                ListCUPS = ListCUPS.replace(/ /g, ",");
            }

            if (ListCUPS.indexOf(',,') !== -1) {
                ListCUPS = ListCUPS.replace(/\,\,/g, ",");
            }

            cups = ListCUPS.split(",");

            resultado = cups.map(function(elemento, i) {
                return elemento;
            });
        }

        if (Logueado == 'true') {
            serviciosApi.getSips(Cups, NombreEmpresaDistribuidora, MunicipioPS, CodigoProvinciaPS, CodigoPostalPS, CodigoTarifaATREnVigor, resultado)
                .then(function successCallback(response) {
                    // console.log(response);
                    //DatosSIPS
                    $scope.clientesSips = response.data.ClientesSips;

                    //https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost
                    // GET DTO ALL de TARIFAS
                    $scope.getDTOAllTarifa();

                    //Guardo todos los resultados de consumos en un array para luego usarlos en un ng-repeat en la vista
                    $scope.consumosSips = response.data.ConsumosSips;
                    $scope.calcularTablaConsumosPotencias();

                    if(response.data.DatosTitular != null && response.data.DatosTitular.length > 0){
                        $scope.datosTitular = response.data.DatosTitular[0];
                    }else{
                        if ($scope.clientesSips.length > 0) {
                            // console.log("$scope.clientesSips");
                            // console.log($scope.clientesSips);
                            $scope.datosTitular = {};
                            $scope.datosTitular.DireccionSuministro =
                            (($scope.clientesSips[0].TipoViaTitular!=null) ? $scope.clientesSips[0].TipoViaTitular + " " : "") +
                            (($scope.clientesSips[0].ViaTitular!=null) ? $scope.clientesSips[0].ViaTitular + " " : "") +
                            (($scope.clientesSips[0].NumFincaTitular!=null) ? $scope.clientesSips[0].NumFincaTitular + " " : "") +
                            (($scope.clientesSips[0].PortalTitular!=null) ? $scope.clientesSips[0].PortalTitular + " " : "") +
                            (($scope.clientesSips[0].EscaleraTitular!=null) ? $scope.clientesSips[0].EscaleraTitular + " " : "") +
                            (($scope.clientesSips[0].PisoTitular!=null) ? $scope.clientesSips[0].PisoTitular + " " : "") +
                            (($scope.clientesSips[0].PuertaTitular!=null) ? $scope.clientesSips[0].PuertaTitular : "");
                            $scope.datosTitular.NombreTitular = $scope.clientesSips[0].NombreTitular + " " + $scope.clientesSips[0].Apellido1Titular + " " + $scope.clientesSips[0].Apellido2Titular;
                            $scope.datosTitular.CiudadTitular = $scope.clientesSips[0].DesMunicipioPS;
                            $scope.datosTitular.ProvinciaTitular = $scope.clientesSips[0].DesProvinciaPS;
                        }
                    }



					if ($scope.clientesSips.length > 0) {

						// Si es 2.0 DHA / 2.1 DHA mover P2 a P33
						if ($scope.VerEnergia && ($scope.clientesSips[0].CodigoTarifaATREnVigor == "004" || $scope.clientesSips[0].CodigoTarifaATREnVigor == "006")) {
							$scope.clientesSips[0].PotenciasContratadasEnWP3 = $scope.clientesSips[0].PotenciasContratadasEnWP1;
							$scope.clientesSips[0].PotenciasContratadasEnWP2 = null;
						}

						// Inserimos
						if (MostrarSipsPotenciasSige) {
							for (var i = 0; i < $scope.clientesSips.length; i++) {
								var cups = $scope.clientesSips[i].CodigoCUPS;
								$http
									.get(urlApi + "CUPS/VerificarCUPSExistente/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
									.then(function(response) {
										var playload = JSON.parse(atob(response.data.split('.')[1]));
										var playloadJson = JSON.parse(playload.iss);

										if (playloadJson) {
											$http
												.get(urlApi + "CUPS/GetCUPSPotencias/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
												.success(function(response) {
													var decodeToken = jwtHelper.decodeToken(response);
													var playloadJsonPotencias = JSON.parse(decodeToken.iss);

													var potenciaP1 = document.getElementById('PotenciaP1').textContent;
													var potenciaP2 = document.getElementById('PotenciaP2').textContent;
													var potenciaP3 = document.getElementById('PotenciaP3').textContent;
													var potenciaP4 = document.getElementById('PotenciaP4').textContent;
													var potenciaP5 = document.getElementById('PotenciaP5').textContent;
                                                    var potenciaP6 = document.getElementById('PotenciaP6').textContent;

													if (playloadJsonPotencias[0].TextoTarifa == "2.0A ML" || playloadJsonPotencias[0].TextoTarifa == "2.1A ML") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = "0";
														document.getElementById('PotenciaP3').innerHTML = "0";
														document.getElementById('PotenciaP4').innerHTML = "0";
														document.getElementById('PotenciaP5').innerHTML = "0";
														document.getElementById('PotenciaP6').innerHTML = "0";
													}

                                                    if (playloadJsonPotencias[0].TextoTarifa == "2.0DHA ML" || playloadJsonPotencias[0].TextoTarifa == "2.1DHA ML") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = "0";
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = "0";
														document.getElementById('PotenciaP5').innerHTML = "0";
														document.getElementById('PotenciaP6').innerHTML = "0";
													}

													if (
														playloadJsonPotencias[0].TextoTarifa == "2.0DHS ML" ||
														playloadJsonPotencias[0].TextoTarifa == "2.1DHS ML" ||
														playloadJsonPotencias[0].TextoTarifa == "3.0A ML" ||
														playloadJsonPotencias[0].TextoTarifa == "3.1A ML"
													) {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = "0";
														document.getElementById('PotenciaP5').innerHTML = "0";
														document.getElementById('PotenciaP6').innerHTML = "0";
													}

													if (playloadJsonPotencias[0].TextoTarifa == "6.1A ML" || playloadJsonPotencias[0].TextoTarifa == "6.2 ML" || playloadJsonPotencias[0].TextoTarifa == "6.3 ML" || playloadJsonPotencias[0].TextoTarifa == "6.4 ML" || playloadJsonPotencias[0].TextoTarifa == "6.5 ML" || playloadJsonPotencias[0].TextoTarifa == "6.1B ML") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[3].PotenciaContratada;
														document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[4].PotenciaContratada;
														document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[5].PotenciaContratada;
													}
												});
										} else {}
									});
							}
                        }


						var original = {
							"count": response.data.ClientesSips.length,
							"data": response.data.ClientesSips
						};

                        $scope.cliente = $scope.clientesSips[0];
                        // $scope.cliente.nombreTarifa = $scope.obtenerNombreTarifa($scope.cliente.CodigoTarifaATREnVigor);
                        $scope.cliente.nombreTarifa = $scope.obtenerNombreTarifa($scope.cliente.CodigoPeajeEnVigor);
                        $scope.cliente.nombreTarifaConML = $scope.cliente.nombreTarifa + ' ML';

						$scope.datatable = angular.copy(original);
						$scope.smart = true;
					} else {
						$mdDialog.show(
							$mdDialog.alert()
							.clickOutsideToClose(true)
							.htmlContent('No se han encontrado datos.')
							.ariaLabel('No se han encontrado datos.')
							.ok('Aceptar')
						);
					}

                }, function errorCallback(response) {
					var alert = $mdDialog.alert()
						.clickOutsideToClose(true)
						.title('SIPS')
						.htmlContent('La información no está disponible.')
						.ariaLabel('La información no está disponible.')
						.ok('Aceptar');
					$mdDialog.show(alert);
				});
        }
    };

    $scope.calcularConsumoAnual = function(cliente) {
        $scope.consumosAnuales = [];
        var CupsLinea = cliente.clienteSips.CodigoCUPS;
        if (CupsLinea != undefined) {

            var urlApiSipsAux = (localStorage.getItem('idEmpresaGas') == 'true') ? urlApiSipsGas : urlApiSips;

            $http.get(urlApiSipsAux + "/ConsumoGet/?_SipsCupsFiltersDTO={\"CodigosCUPS\":[\"" + CupsLinea + "\"]}").then(function(response) {

				$scope.busquedaConsumos = response.data;
                //console.log($scope.busquedaConsumos);

                var BusquedaConsumos = $scope.busquedaConsumos;

                var ConsumosAnualesCups = [];
                var sumatorioActivas = 0;

				if (BusquedaConsumos.length > 0) {
					var FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFin;
					if (FechaFin == null) {
						FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFinMesConsumo;
					}
                    var FechaActual = new Date(FechaFin);


                    var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
                    var FechaFin2 = FechaFin.split('T');
					var FechaInicio = new Date(FechaFin2[0]);
					FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';
					$scope.P1 = 0;
					$scope.P2 = 0;
					$scope.P3 = 0;
					$scope.P4 = 0;
					$scope.P5 = 0;
                    $scope.P6 = 0;
                    $scope.ConsumoEnWhP1 = 0;
					$.grep(BusquedaConsumos, function(n, i) {
						if (BusquedaConsumos[i].FechaInicio == null) {
							BusquedaConsumos[i].FechaInicio = BusquedaConsumos[i].FechaInicioMesConsumo;
						}

						if (n.FechaInicio >= FechaInicio) {
							ConsumosAnualesCups.push(n);
							sumatorioActivas += BusquedaConsumos[i].Activa1 + BusquedaConsumos[i].Activa2 + BusquedaConsumos[i].Activa3 +
								BusquedaConsumos[i].Activa4 + BusquedaConsumos[i].Activa5 + BusquedaConsumos[i].Activa6;

							$scope.P1 += BusquedaConsumos[i].Activa1;
							$scope.P2 += BusquedaConsumos[i].Activa2;
							$scope.P3 += BusquedaConsumos[i].Activa3;
							$scope.P4 += BusquedaConsumos[i].Activa4;
							$scope.P5 += BusquedaConsumos[i].Activa5;
                            $scope.P6 += BusquedaConsumos[i].Activa6;
                            $scope.ConsumoEnWhP1 += BusquedaConsumos[i].ConsumoEnWhP1;
						}
					});
					var fechaInicialMesConsumo = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
					var fechaInicio = new Date();
					if (fechaInicialMesConsumo == null) {
						fechaInicio = new Date(ConsumosAnualesCups[0].FechaInicioMesConsumo).getTime();
					} else {
						fechaInicio = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
					}

					var fechaFin = new Date(FechaFin).getTime();
					var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
                    var consumos = Math.round(((sumatorioActivas / 1000) / countDias) * 365);

                    // console.log(countDias);

					$scope.P1 = Math.round((($scope.P1 / 1000) / countDias) * 365);
					$scope.P2 = Math.round((($scope.P2 / 1000) / countDias) * 365);
					$scope.P3 = Math.round((($scope.P3 / 1000) / countDias) * 365);
					$scope.P4 = Math.round((($scope.P4 / 1000) / countDias) * 365);
					$scope.P5 = Math.round((($scope.P5 / 1000) / countDias) * 365);
                    $scope.P6 = Math.round((($scope.P6 / 1000) / countDias) * 365);
                    $scope.ConsumoEnWhP1 = Math.round((($scope.ConsumoEnWhP1 / 1000) / countDias) * 365);
                }

                // console.log("$scope.ConsumoEnWhP1");
                // console.log($scope.ConsumoEnWhP1);

                var tarifa = cliente.clienteSips.CodigoTarifaATREnVigor;

                if ($scope.VerEnergia && (tarifa == "004" || tarifa == "006")) {
                    // Si es 2.0 DHA / 2.1 DHA mover P2 a P3
                    $scope.P3 = $scope.P2;
                    $scope.P2 = "0";
                } else if ($scope.VerEnergia && (tarifa == "003" || tarifa == "011")) {
                    // Suma P1+P4 / P2+P5 / P3+P6 en tarifas 3.0 y 3.1
					$scope.P1 = $scope.P1 + $scope.P4;
                    $scope.P2 = $scope.P2 + $scope.P5;
                    $scope.P3 = $scope.P3 + $scope.P6;
                    $scope.P4 = "0";
                    $scope.P5 = "0";
                    $scope.P6 = "0";
                }
                // if (consumos == 0) {
                // 	var valorMasAlto = Math.max(cliente.clientesSips[0].PotenciasContratadasEnWP1, cliente.clientesSips[0].PotenciasContratadasEnWP2, cliente.clientesSips[0].PotenciasContratadasEnWP3, cliente.clientesSips[0].PotenciasContratadasEnWP4, cliente.clientesSips[0].PotenciasContratadasEnWP5, cliente.clientesSips[0].PotenciasContratadasEnWP6);
                // 	consumos = valorMasAlto * 8760 * 0.2;
                // }

                $scope.consumosAnuales.push({
                    "CUPS": CupsLinea,
                    "ConsumoAnual": consumos ? Math.round(consumos) : 0,
                    "ConsumoP1": ($scope.P1) ? $scope.P1 : 0,
                    "ConsumoP2": ($scope.P2) ? $scope.P2 : 0,
                    "ConsumoP3": ($scope.P3) ? $scope.P3 : 0,
                    "ConsumoP4": ($scope.P4) ? $scope.P4 : 0,
                    "ConsumoP5": ($scope.P5) ? $scope.P5 : 0,
                    "ConsumoP6": ($scope.P6) ? $scope.P6 : 0,
                    "CaudalHorarioEnWh": cliente.clienteSips.CaudalHorarioEnWh,
                    "CaudalMaximoDiarioEnWh": cliente.clienteSips.CaudalMaximoDiarioEnWh,
                    "CodigoPeajeEnVigor": cliente.clienteSips.CodigoPeajeEnVigor,
                    "CodigoPostal": cliente.clienteSips.CodigoPostalPS,
                    "CodigoPresion": cliente.clienteSips.CodigoPresion,
                    "CodigoTensionV": cliente.clienteSips.CodigoTensionV,
                    "Distribuidora": cliente.clienteSips.NombreEmpresaDistribuidora,
                    "FechaEmisionBIE": cliente.clienteSips.FechaEmisionBIE,
                    "FechaUltimaLectura": cliente.clienteSips.FechaUltimaLectura,
                    "FechaUltimoCambioComercializador": cliente.clienteSips.FechaUltimoCambioComercializador,
                    "FechaUltimoMovimientoContrato": cliente.clienteSips.FechaUltimoMovimientoContrato,
                    "Municipio": cliente.clienteSips.DesMunicipioPS,
                    "PotenciasWP1": cliente.clienteSips.PotenciasContratadasEnWP1,
                    "PotenciasWP2": cliente.clienteSips.PotenciasContratadasEnWP2,
                    "PotenciasWP3": cliente.clienteSips.PotenciasContratadasEnWP3,
                    "PotenciasWP4": cliente.clienteSips.PotenciasContratadasEnWP4,
                    "PotenciasWP5": cliente.clienteSips.PotenciasContratadasEnWP5,
                    "PotenciasWP6": cliente.clienteSips.PotenciasContratadasEnWP6,
                    "Provincia": cliente.clienteSips.DesProvinciaPS,
                    "Tarifa": cliente.clienteSips.CodigoTarifaATREnVigor,
                });

                $scope.busquedaSips = $scope.consumosAnuales;
                // $scope.calcularTablaConsumosPotencias(); // TODO: EN EL AIRE
            });
        }

    };



    $scope.calcularSoloConsumoAnual = function(cliente) {
        $scope.consumosAnuales = [];
        var CupsLinea = cliente.CodigoCUPS;
        if (CupsLinea != undefined) {

            var urlApiSipsAux = (localStorage.getItem('idEmpresaGas') == 'true') ? urlApiSipsGas : urlApiSips;

            $http.get(urlApiSipsAux + "/ConsumoGet/?_SipsCupsFiltersDTO={\"CodigosCUPS\":[\"" + CupsLinea + "\"]}").then(function(response) {
				var BusquedaConsumos = response.data;

                var ConsumosAnualesCups = [];
                var sumatorioActivas = 0;

				if (BusquedaConsumos.length > 0) {
					var FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFin;
					if (FechaFin == null) {
						FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFinMesConsumo;
					}
                    var FechaActual = new Date(FechaFin);


                    var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
                    var FechaFin2 = FechaFin.split('T');
					var FechaInicio = new Date(FechaFin2[0]);
					FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';
					$scope.P1 = 0;
					$scope.P2 = 0;
					$scope.P3 = 0;
					$scope.P4 = 0;
					$scope.P5 = 0;
                    $scope.P6 = 0;
                    $scope.ConsumoEnWhP1 = 0; // gas
					$.grep(BusquedaConsumos, function(n, i) {

						if (BusquedaConsumos[i].FechaInicio == null) {
							BusquedaConsumos[i].FechaInicio = BusquedaConsumos[i].FechaInicioMesConsumo;
						}

						if (n.FechaInicio >= FechaInicio) {
							ConsumosAnualesCups.push(n);
							sumatorioActivas += BusquedaConsumos[i].Activa1 + BusquedaConsumos[i].Activa2 + BusquedaConsumos[i].Activa3 +
								BusquedaConsumos[i].Activa4 + BusquedaConsumos[i].Activa5 + BusquedaConsumos[i].Activa6;

							$scope.P1 = BusquedaConsumos[i].Activa1;
							$scope.P2 = BusquedaConsumos[i].Activa2;
							$scope.P3 = BusquedaConsumos[i].Activa3;
							$scope.P4 = BusquedaConsumos[i].Activa4;
							$scope.P5 = BusquedaConsumos[i].Activa5;
                            $scope.P6 = BusquedaConsumos[i].Activa6;
                            $scope.ConsumoEnWhP1 += BusquedaConsumos[i].ConsumoEnWhP1;
						}

					});
					var fechaInicialMesConsumo = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
					var fechaInicio = new Date();
					if (fechaInicialMesConsumo == null) {
						fechaInicio = new Date(ConsumosAnualesCups[0].FechaInicioMesConsumo).getTime();
					} else {
						fechaInicio = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
					}

					var fechaFin = new Date(FechaFin).getTime();
					var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
					var consumos = Math.round(((sumatorioActivas / 1000) / countDias) * 365); // luz
                    consumos = Math.round((($scope.ConsumoEnWhP1 / 1000) / countDias) * 365); // gas
                    // console.log("$scope.ConsumoEnWhP1");
                    // console.log($scope.ConsumoEnWhP1);
                    // console.log(consumos);

				}

                $scope.consumoAnual = consumos;

            });
        }

    };


    $scope.calcularTablaConsumosPotencias = function() {

        $scope.calcularSoloConsumoAnual($scope.clientesSips[0]);

        $scope.tablaConsumos = [];
        var CupsLinea = $scope.clientesSips[0].CodigoCUPS;
        if ($scope.consumosSips != undefined) {
            // if (localStorage.getItem('idEmpresaGas') == 'true') {
			// 	urlApiSips = urlApiSipsGas;
			// }

                var ConsumosCups = [];
                var sumatorioActivas = 0;
                var contadorConsumos = $scope.consumosSips.length;

                var FechaFin = $scope.consumosSips[contadorConsumos - 1].FechaFin;
                if (FechaFin == null) {
                    FechaFin = $scope.consumosSips[contadorConsumos - 1].FechaFinMesConsumo;
                }
                var FechaActual = new Date(FechaFin);


                var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
                var FechaFin2 = FechaFin.split('T');
                var FechaInicio = new Date(FechaFin2[0]);
                FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';
                $scope.P1 = 0;
                $scope.P2 = 0;
                $scope.P3 = 0;
                $scope.P4 = 0;
                $scope.P5 = 0;
                $scope.P6 = 0;
                $scope.ConsumoEnWhP1 = 0;

                var x = $scope.consumosSips.length-1; // contador
                // cargar en array 12 ultimos
                for (var i = 12; i > 0; i--) {

                    // Si no existe algun periodo no lo saltamos para que no pete
                    if($scope.consumosSips[x]!=undefined){

                        // console.log($scope.consumosSips[x]);
                        if ($scope.consumosSips[x].FechaInicio == null) {
                            $scope.consumosSips[x].FechaInicio = $scope.consumosSips[x].FechaInicioMesConsumo;
                        }

                        if ($scope.consumosSips[x].FechaInicio >= FechaInicio) {
                            ConsumosCups.push($scope.consumosSips[x]);
                            sumatorioActivas += $scope.consumosSips[x].Activa1 + $scope.consumosSips[x].Activa2 + $scope.consumosSips[x].Activa3 +
                                $scope.consumosSips[x].Activa4 + $scope.consumosSips[x].Activa5 + $scope.consumosSips[x].Activa6;

                            $scope.P1 = $scope.consumosSips[x].Activa1;
                            $scope.P2 = $scope.consumosSips[x].Activa2;
                            $scope.P3 = $scope.consumosSips[x].Activa3;
                            $scope.P4 = $scope.consumosSips[x].Activa4;
                            $scope.P5 = $scope.consumosSips[x].Activa5;
                            $scope.P6 = $scope.consumosSips[x].Activa6;
                        }

                        // Reordenación de las potencias que vienen del SIPS
                        var codigoTarifa = $scope.clientesSips[0].CodigoTarifaATREnVigor;
                        switch (codigoTarifa) {
                            case "001":
                                // tarifa = "2.0A";
                                $scope.P2 = $scope.P1;
                                $scope.P1 = 0;
                                $scope.P3 = 0;
                                $scope.P4 = 0;
                                $scope.P5 = 0;
                                $scope.P6 = 0;
                                break;
                            case "003":
                                // tarifa = "3.0A";
                                $scope.P1 = $scope.P1 + $scope.P4;
                                $scope.P2 = $scope.P2 + $scope.P5;
                                $scope.P3 = $scope.P3 + $scope.P6;
                                $scope.P4 = 0;
                                $scope.P5 = 0;
                                $scope.P6 = 0;
                                break;
                            case "004":
                                // tarifa = "2.0DHA";
                                $scope.P3 = $scope.P2;
                                $scope.P2 = 0;
                                $scope.P4 = 0;
                                $scope.P5 = 0;
                                $scope.P6 = 0;
                                break;
                            case "005":
                                // tarifa = "2.1A";
                                $scope.P2 = $scope.P1;
                                $scope.P1 = 0;
                                $scope.P3 = 0;
                                $scope.P4 = 0;
                                $scope.P5 = 0;
                                $scope.P6 = 0;
                                break;
                            case "006":
                                // tarifa = "2.1DHA";
                                $scope.P3 = $scope.P2;
                                $scope.P2 = 0;
                                $scope.P4 = 0;
                                $scope.P5 = 0;
                                $scope.P6 = 0;
                                break;
                            case "007":
                                // tarifa = "2.0DHS";
                                break;
                            case "008":
                                // tarifa = "2.1DHS";
                                break;
                            case "011":
                                // tarifa = "3.1A";
                                break;
                            case "012":
                                // tarifa = "6.1A";
                                break;
                            case "013":
                                // tarifa = "6.2";
                                break;
                            case "014":
                                // tarifa = "6.3";
                                break;
                            case "015":
                                // tarifa = "6.4";
                                break;
                            case "016":
                                // tarifa = "6.5";
                                break;
                            case "017":
                                // tarifa = "6.1B";
                                break;
                        }

                        // Carga en el array de la fila
                        $scope.tablaConsumos.push({
                            "CUPS": $scope.consumosSips[x].CodigoCUPS,
                            "FechaInicio": $scope.consumosSips[x].FechaInicio,
                            "FechaFin": $scope.consumosSips[x].FechaFin,
                            "ConsumoAnual": consumos ? Math.round(consumos) : 0,
                            "ConsumoP1": ($scope.P1) ? $scope.P1 : 0,
                            "ConsumoP2": ($scope.P2) ? $scope.P2 : 0,
                            "ConsumoP3": ($scope.P3) ? $scope.P3 : 0,
                            "ConsumoP4": ($scope.P4) ? $scope.P4 : 0,
                            "ConsumoP5": ($scope.P5) ? $scope.P5 : 0,
                            "ConsumoP6": ($scope.P6) ? $scope.P6 : 0,
                            "FechaInicioMesConsumo": $scope.consumosSips[x].FechaInicioMesConsumo,
                            "FechaFinMesConsumo": $scope.consumosSips[x].FechaFinMesConsumo,
                            "ConsumoEnWhP1": $scope.consumosSips[x].ConsumoEnWhP1,
                            "ConsumoEnWhP2": $scope.consumosSips[x].ConsumoEnWhP2,
                            // "CaudalHorarioEnWh": cliente.clienteSips.CaudalHorarioEnWh,
                            // "CaudalMaximoDiarioEnWh": cliente.clienteSips.CaudalMaximoDiarioEnWh,
                            // "CodigoPeajeEnVigor": cliente.clienteSips.CodigoPeajeEnVigor,
                            // "CodigoPostal": cliente.clienteSips.CodigoPostalPS,
                            // "CodigoPresion": cliente.clienteSips.CodigoPresion,
                            // "CodigoTensionV": cliente.clienteSips.CodigoTensionV,
                            // "Distribuidora": cliente.clienteSips.NombreEmpresaDistribuidora,
                            // "FechaEmisionBIE": cliente.clienteSips.FechaEmisionBIE,
                            // "FechaUltimaLectura": cliente.clienteSips.FechaUltimaLectura,
                            // "FechaUltimoCambioComercializador": cliente.clienteSips.FechaUltimoCambioComercializador,
                            // "FechaUltimoMovimientoContrato": cliente.clienteSips.FechaUltimoMovimientoContrato,
                            // "Municipio": cliente.clienteSips.DesMunicipioPS,
                            // "PotenciasWP1": cliente.clienteSips.PotenciasContratadasEnWP1,
                            // "PotenciasWP2": cliente.clienteSips.PotenciasContratadasEnWP2,
                            // "PotenciasWP3": cliente.clienteSips.PotenciasContratadasEnWP3,
                            // "PotenciasWP4": cliente.clienteSips.PotenciasContratadasEnWP4,
                            // "PotenciasWP5": cliente.clienteSips.PotenciasContratadasEnWP5,
                            // "PotenciasWP6": cliente.clienteSips.PotenciasContratadasEnWP6,
                            // "Provincia": cliente.clienteSips.DesProvinciaPS,
                            "Tarifa": $scope.clientesSips.CodigoTarifaATREnVigor,
                        });
                    }
                    x--; // contador inverso
                }

                var fechaInicialMesConsumo = new Date($scope.consumosSips[0].FechaInicio).getTime();
                var fechaInicio = new Date();
                if (fechaInicialMesConsumo == null) {
                    fechaInicio = new Date($scope.consumosSips[0].FechaInicioMesConsumo).getTime();
                } else {
                    fechaInicio = new Date($scope.consumosSips[0].FechaInicio).getTime();
                }

                var fechaFin = new Date(FechaFin).getTime();
                var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
                var consumos = Math.round(((sumatorioActivas / 1000) / countDias) * 365);

                $scope.P1 = Math.round((($scope.P1 / 1000) / countDias) * 365);
                $scope.P2 = Math.round((($scope.P2 / 1000) / countDias) * 365);
                $scope.P3 = Math.round((($scope.P3 / 1000) / countDias) * 365);
                $scope.P4 = Math.round((($scope.P4 / 1000) / countDias) * 365);
                $scope.P5 = Math.round((($scope.P5 / 1000) / countDias) * 365);
                $scope.P6 = Math.round((($scope.P6 / 1000) / countDias) * 365);
                $scope.ConsumoEnWhP1 = Math.round((($scope.ConsumoEnWhP1 / 1000) / countDias) * 365);

                var tarifa = $scope.clientesSips.CodigoTarifaATREnVigor;

                if ($scope.VerEnergia && (tarifa == "004" || tarifa == "006")) {
                    // Si es 2.0 DHA / 2.1 DHA mover P2 a P3
                    $scope.P3 = $scope.P2;
                    $scope.P2 = "0";
                } else if ($scope.VerEnergia && (tarifa == "003" || tarifa == "011")) {
                    // Suma P1+P4 / P2+P5 / P3+P6 en tarifas 3.0 y 3.1
                    $scope.P1 = $scope.P1 + $scope.P4;
                    $scope.P2 = $scope.P2 + $scope.P5;
                    $scope.P3 = $scope.P3 + $scope.P6;
                    $scope.P4 = "0";
                    $scope.P5 = "0";
                    $scope.P6 = "0";
                }

                // if (consumos == 0) {
                // 	var valorMasAlto = Math.max(cliente.clientesSips[0].PotenciasContratadasEnWP1, cliente.clientesSips[0].PotenciasContratadasEnWP2, cliente.clientesSips[0].PotenciasContratadasEnWP3, cliente.clientesSips[0].PotenciasContratadasEnWP4, cliente.clientesSips[0].PotenciasContratadasEnWP5, cliente.clientesSips[0].PotenciasContratadasEnWP6);
                // 	consumos = valorMasAlto * 8760 * 0.2;
                // }

        }

        // seleccionamos a checked todos los rows de la tabla
        $scope.allCheckboxChecked();

        $scope.calcularSumaConsumosPotencias();


    };

    // ALL CHECKBOX LIST CHECKED
    $scope.allCheckboxChecked = function() {
        $scope.selectedConsumosPorPotencia = [];
        $scope.tablaConsumos.forEach(function (item) {
            $scope.selectedConsumosPorPotencia.push(item);
        });
    }


    $scope.calcularSumaConsumosPotencias = function() {

        // POTENCIAS ACUMULADAS

        $scope.P1 = 0;
        $scope.P2 = 0;
        $scope.P3 = 0;
        $scope.P4 = 0;
        $scope.P5 = 0;
        $scope.P6 = 0;
        $scope.ConsumoEnWhP1 = 0;

        // RESETEAMOS LA FLAG  -> recalculoManual
        $scope.recalculoManual = 0;

        // CALCULO DE FECHAS DE LA SUMA DE LOS CONSUMOS SELECCIONADOS
        var fechaInicio = new Date();
        var fechaFin = new Date("December 17, 1970 03:24:00");
        // console.log($scope.selectedConsumosPorPotencia);
        $scope.selectedConsumosPorPotencia.forEach( function(obj, indice, array) {

            // ACUMULACION CONSUMO POR POTENCIA EN LOS SELECCIONADOS
            $scope.P1 = $scope.P1 + obj.ConsumoP1;
            $scope.P2 = $scope.P2 + obj.ConsumoP2;
            $scope.P3 = $scope.P3 + obj.ConsumoP3;
            $scope.P4 = $scope.P4 + obj.ConsumoP4;
            $scope.P5 = $scope.P5 + obj.ConsumoP5;
            $scope.P6 = $scope.P6 + obj.ConsumoP6;
            $scope.ConsumoEnWhP1 = $scope.ConsumoEnWhP1 + obj.ConsumoEnWhP1;


            // TOMAMOS LA FECHA MAS BAJA Y MAS ALTA DE LOS SELECCIONADOS INICIO/FIN
            var fechaTemporal = new Date(obj.FechaInicio); // luz
            var fechaTemporal = new Date(obj.FechaInicioMesConsumo); //gas

            if(fechaInicio > fechaTemporal){
                fechaInicio = fechaTemporal
            }

            fechaTemporal = new Date(obj.FechaFin); // luz
            fechaTemporal = new Date(obj.FechaFinMesConsumo); //gas

            if(fechaTemporal > fechaFin){
                fechaFin = fechaTemporal;
            }
        });

        // controlamos la tarifa 2.0 si no falla
        if($scope.cliente.nombreTarifa == "2.0A" || $scope.cliente.nombreTarifa == "2.1A"){
            $scope.P1 = $scope.P2;
            $scope.P2 = 0;
        }

        // TODO: controlamos la tarifa 2.1DHA y 2.0DHA si no falla
        if($scope.cliente.nombreTarifa == "2.0DHA" || $scope.cliente.nombreTarifa == "2.1DHA"){
            // console.log($scope.P2);
            // console.log($scope.P3);
            $scope.P2 = $scope.P3;
            $scope.P3 = 0;
        }


        // SUMATORIO CONSUMOS
        // console.log($scope.P1 + " " + $scope.P2 + " " + $scope.P3 + " " + $scope.P4 + " " + $scope.P5 + " " + $scope.P6);

        // Montamos el objeto que atacaremos en el fron
        $scope.SumaConsumosPotencias = {
            "fechaInicio":fechaInicio,
            "fechaFin":fechaFin,
            "ConsumoEnWhP1":$scope.ConsumoEnWhP1,
            "p1":$scope.P1,
            "p2":$scope.P2,
            "p3":$scope.P3,
            "p4":$scope.P4,
            "p5":$scope.P5,
            "p6":$scope.P6,
            "PotenciasContratadasEnWP1":$scope.clientesSips[0].PotenciasContratadasEnWP1,
            "PotenciasContratadasEnWP2":$scope.clientesSips[0].PotenciasContratadasEnWP2,
            "PotenciasContratadasEnWP3":$scope.clientesSips[0].PotenciasContratadasEnWP3,
            "PotenciasContratadasEnWP4":$scope.clientesSips[0].PotenciasContratadasEnWP4,
            "PotenciasContratadasEnWP5":$scope.clientesSips[0].PotenciasContratadasEnWP5,
            "PotenciasContratadasEnWP6":$scope.clientesSips[0].PotenciasContratadasEnWP6,
        }

        // console.log("$scope.SumaConsumosPotencias");
        // console.log($scope.SumaConsumosPotencias);


        // Asignamos valor a 0 los totales
        // $scope.calculoTerminoPotenciaSuyo = 0;
        // $scope.calculoTerminoEnergiaSuyo = 0;
        // $scope.costeAlquilerSuyo = 0;
        // $scope.serviciosAdicionalesSuyo = 0;
        // $scope.calcularTerminoPotenciaYEnergiaYTotalSuyo();
        // console.log($scope.TarifaAccesoElectricidad);
        // console.log($scope.GrupoTarifaModel);

        // $scope.calcularPreciosTarifa($scope.TarifaAccesoElectricidad,$scope.GrupoTarifaModel);



    };

    // actualiza la potencia contratada por si no es igual a la facturada -> pueden cambiarla desde el comparador manualmente

    $scope.actualizarPotenciaFacturada = function() {

        if($scope.potenciaFacturadaP1 != undefined) { $scope.SumaConsumosPotencias.PotenciasContratadasEnWP1 = $scope.potenciaFacturadaP1; }
        if($scope.potenciaFacturadaP2 != undefined) { $scope.SumaConsumosPotencias.PotenciasContratadasEnWP2 = $scope.potenciaFacturadaP2; }
        if($scope.potenciaFacturadaP3 != undefined) { $scope.SumaConsumosPotencias.PotenciasContratadasEnWP3 = $scope.potenciaFacturadaP3; }
        if($scope.potenciaFacturadaP4 != undefined) { $scope.SumaConsumosPotencias.PotenciasContratadasEnWP4 = $scope.potenciaFacturadaP4; }
        if($scope.potenciaFacturadaP5 != undefined) { $scope.SumaConsumosPotencias.PotenciasContratadasEnWP5 = $scope.potenciaFacturadaP5; }
        if($scope.potenciaFacturadaP6 != undefined) { $scope.SumaConsumosPotencias.PotenciasContratadasEnWP6 = $scope.potenciaFacturadaP6; }

        // console.log($scope.SumaConsumosPotencias);

    }



    $scope.getDTOAllTarifa = function() {

        $http({
            method: "GET",
            url: urlApi + "Tarifa/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }

        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.TarifasAcceso = playloadJson;
                // console.log("$scope.TarifasAcceso");
                // console.log($scope.TarifasAcceso);
            } else {
                $scope.TarifasAcceso = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }

        });

    };


    $scope.update = function (datos) {

        $scope.muestraocultaprecio = false;

		var objeto = JSON.parse(datos.TarifaAccesoElectricidad);
		if($scope.sipsCaido){
			$('input#PotenciaP1').val('');
			$('input#PotenciaP2').val('');
			$('input#PotenciaP3').val('');
			$('input#PotenciaP4').val('');
			$('input#PotenciaP5').val('');
			$('input#PotenciaP6').val('');
		}
        $http({
            method: "GET",
            url: urlApi + "Tarifa/GetDTOAllGruposTarifa/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Tarifa/" + objeto.IdTarifa,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
			var respuesta = response.data;
			//TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            // console.log(playloadJson);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
				$scope.GruposTarifa = playloadJson;
            } else {
                $scope.GruposTarifa = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }

            $scope.IdTarifaGrupo = playloadJson[0].IdTarifaGrupo;

        });

        var textoTarifa = objeto.TextoTarifa;
        $scope.valuePotenciaP1 = datos.PotenciaP1;
        $scope.valuePotenciaP2 = datos.PotenciaP2;
        $scope.valuePotenciaP3 = datos.PotenciaP3;
        $scope.valuePotenciaP4 = datos.PotenciaP4;
        $scope.valuePotenciaP5 = datos.PotenciaP5;
        $scope.valuePotenciaP6 = datos.PotenciaP6;
        textoTarifa = textoTarifa.replace(" ML", "");

        // document.getElementById("PotenciaP1").disabled = true;
        // document.getElementById("PotenciaP2").disabled = true;
        // document.getElementById("PotenciaP3").disabled = true;
        // document.getElementById("PotenciaP4").disabled = true;
        // document.getElementById("PotenciaP5").disabled = true;
        // document.getElementById("PotenciaP6").disabled = true;

        // if (textoTarifa == '2.0A' || textoTarifa == '2.1A' || datos.TarifaAccesoGas == '2.0A' || datos.TarifaAccesoGas == '2.1A') {

        //     // $scope.arrayPotenciasTabla = ["P2"];

        //     // Ocultar campos
        //     $scope.ocultarPotencia1 = true;
        //     $scope.ocultarPotencia2 = false;
        //     $scope.ocultarPotencia3 = true;
        //     $scope.ocultarPotencia4 = true;
        //     $scope.ocultarPotencia5 = true;
        //     $scope.ocultarPotencia6 = true;

        //     // limpiar potencias rellenas de tarifas anteriores seleccionadas
        //     $scope.valuePotenciaP1 = datos.PotenciaP1 = "";
        //     $scope.valuePotenciaP3 = datos.PotenciaP3 = "";
        //     $scope.valuePotenciaP4 = datos.PotenciaP4 = "";
        //     $scope.valuePotenciaP5 = datos.PotenciaP5 = "";
        //     $scope.valuePotenciaP6 = datos.PotenciaP6 = "";


        //     //Hago obligatorio el campo a editar
        //     document.getElementById("PotenciaP1").required = false;
        //     document.getElementById("PotenciaP2").required = true;
        //     document.getElementById("PotenciaP3").required = false;
        //     document.getElementById("PotenciaP4").required = false;
        //     document.getElementById("PotenciaP5").required = false;
        //     document.getElementById("PotenciaP6").required = false;

        //     document.getElementById("PotenciaP1").disabled = true;
        //     document.getElementById("PotenciaP2").disabled = false;
        //     document.getElementById("PotenciaP3").disabled = true;
        //     document.getElementById("PotenciaP4").disabled = true;
        //     document.getElementById("PotenciaP5").disabled = true;
        //     document.getElementById("PotenciaP6").disabled = true;
        // }

        // if (textoTarifa == '2.0DHA' || textoTarifa == '2.1DHA' || datos.TarifaAccesoGas == '2.0DHA' || datos.TarifaAccesoGas == '2.1DHA') {

        //     // $scope.arrayPotenciasTabla = ["P1", "P3"];

        //     // Ocultar campos
        //     $scope.ocultarPotencia1 = false;
        //     $scope.ocultarPotencia2 = true;
        //     $scope.ocultarPotencia3 = false;
        //     $scope.ocultarPotencia4 = true;
        //     $scope.ocultarPotencia5 = true;
        //     $scope.ocultarPotencia6 = true;

        //     // limpiar potencias rellenas de tarifas anteriores seleccionadas
        //     $scope.valuePotenciaP2 = datos.PotenciaP2 = "";
        //     $scope.valuePotenciaP4 = datos.PotenciaP4 = "";
        //     $scope.valuePotenciaP5 = datos.PotenciaP5 = "";
        //     $scope.valuePotenciaP6 = datos.PotenciaP6 = "";

        //     document.getElementById("PotenciaP1").required = true;
        //     document.getElementById("PotenciaP2").required = false;
        //     document.getElementById("PotenciaP3").required = true;
        //     document.getElementById("PotenciaP4").required = false;
        //     document.getElementById("PotenciaP5").required = false;
        //     document.getElementById("PotenciaP6").required = false;

        //     document.getElementById("PotenciaP1").disabled = false;
        //     document.getElementById("PotenciaP2").disabled = true;
        //     document.getElementById("PotenciaP3").disabled = false;
        //     document.getElementById("PotenciaP4").disabled = true;
        //     document.getElementById("PotenciaP5").disabled = true;
        //     document.getElementById("PotenciaP6").disabled = true;
        // }

        // if (textoTarifa == '3.0A' || textoTarifa == '3.1A' || textoTarifa == '2.0DHS' || textoTarifa == '2.1DHS' || datos.TarifaAccesoGas == '3.0A' || datos.TarifaAccesoGas == '3.1A' || datos.TarifaAccesoGas == '2.0DHS' || datos.TarifaAccesoGas == '2.1DHS') {

        //     // $scope.arrayPotenciasTabla = ["P1", "P2", "P3"];

        //     // Ocultar campos
        //     $scope.ocultarPotencia1 = false;
        //     $scope.ocultarPotencia2 = false;
        //     $scope.ocultarPotencia3 = false;
        //     $scope.ocultarPotencia4 = true;
        //     $scope.ocultarPotencia5 = true;
        //     $scope.ocultarPotencia6 = true;

        //     // limpiar potencias rellenas de tarifas anteriores seleccionadas
        //     $scope.valuePotenciaP4 = datos.PotenciaP4 = "";
        //     $scope.valuePotenciaP5 = datos.PotenciaP5 = "";
        //     $scope.valuePotenciaP6 = datos.PotenciaP6 = "";

        //     document.getElementById("PotenciaP1").required = true;
        //     document.getElementById("PotenciaP2").required = true;
        //     document.getElementById("PotenciaP3").required = true;
        //     document.getElementById("PotenciaP4").required = false;
        //     document.getElementById("PotenciaP5").required = false;
        //     document.getElementById("PotenciaP6").required = false;

        //     document.getElementById("PotenciaP1").disabled = false;
        //     document.getElementById("PotenciaP2").disabled = false;
        //     document.getElementById("PotenciaP3").disabled = false;
        //     document.getElementById("PotenciaP4").disabled = true;
        //     document.getElementById("PotenciaP5").disabled = true;
        //     document.getElementById("PotenciaP6").disabled = true;
        // }

        // if (textoTarifa == '6.1A' || textoTarifa == '6.1B' || datos.TarifaAccesoGas == '6.1A' || datos.TarifaAccesoGas == '6.1B') {

        //     // $scope.arrayPotenciasTabla = ["P1", "P2", "P3", "P4", "P5", "P6"];

        //     // Ocultar campos
        //     $scope.ocultarPotencia1 = false;
        //     $scope.ocultarPotencia2 = false;
        //     $scope.ocultarPotencia3 = false;
        //     $scope.ocultarPotencia4 = false;
        //     $scope.ocultarPotencia5 = false;
        //     $scope.ocultarPotencia6 = false;

        //     document.getElementById("PotenciaP1").required = true;
        //     document.getElementById("PotenciaP2").required = true;
        //     document.getElementById("PotenciaP3").required = true;
        //     document.getElementById("PotenciaP4").required = true;
        //     document.getElementById("PotenciaP5").required = true;
        //     document.getElementById("PotenciaP6").required = true;

        //     document.getElementById("PotenciaP1").disabled = false;
        //     document.getElementById("PotenciaP2").disabled = false;
        //     document.getElementById("PotenciaP3").disabled = false;
        //     document.getElementById("PotenciaP4").disabled = false;
        //     document.getElementById("PotenciaP5").disabled = false;
        //     document.getElementById("PotenciaP6").disabled = false;
        // }

        // // CNAE automático para tarifas 3.1 y 3.2 en MET
        // if (nombreEmpresa.localeCompare('MET') === 0 && !$scope.Cnae && (textoTarifa == '3.1' || textoTarifa == '3.2')) {
        //     $scope.Cnae = '9820';
        // }

        // $scope.checkFechaEfectoDomestico();
    };



    $scope.updateGrupoTarifa = function(tarifaGrupo) {

        $scope.limpiarDatos();


        // console.log(tarifaGrupo);
        $scope.grupoTarifa = JSON.parse(tarifaGrupo);
        $scope.grupoTarifaTexto = $scope.grupoTarifa.TextoTarifaGrupo;
        // console.log($scope.grupoTarifa.IdTarifaGrupo);

        // $scope.fee = 0;
        // $scope.feeAgente = 0;

        // TODO: TEMPORALMENTE PARA CARGAR API ANTIGUA DE COMPARADOR INDEXADOS PARA QUE NO PETE

        /* *************** Llamada para sacar ESPECIAL/NOESPECIAL - INDEXADO/NOINDEXADO ************** */
        $http({
            method: "POST",
            url: urlApi + "Tarifa/GetDatosEsEspecialIndexado",
            data: JSON.stringify({ "IdTarifa": $scope.grupoTarifa.IdTarifa, "IdTarifaGrupo": $scope.grupoTarifa.IdTarifaGrupo }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);

            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.especial = playloadJson[0].GrupoPrecioPropio;
                $scope.precioIndexado = playloadJson[0].EsPrecioIndexado;
                // console.log(playloadJson[0]);
                console.log("ES ESPECIAL: " + $scope.especial);
                console.log("ES INDEXADO: " + $scope.precioIndexado);
            }

        });

    }

    $scope.calcularPreciosTarifa = function(tarifa,tarifaGrupo) {

        tarifa = JSON.parse(tarifa);
        tarifaGrupo = JSON.parse(tarifaGrupo);

        $scope.fee = 0;


        // RESETEAMOS LA FLAG  -> recalculoManual
        $scope.recalculoManual = 0;

        // TABLA PRECIOS ELEIA
            $scope.CalculoComision = 0;
            /* *************** Llamada para sacar ESPECIAL/NOESPECIAL - INDEXADO/NOINDEXADO ************** */
            $http({
                method: "POST",
                url: urlApi + "Tarifa/GetDatosEsEspecialIndexado",
                data: JSON.stringify({ "IdTarifa": tarifa.IdTarifa, "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {

                    // $scope.muestraocultaprecio = true;

                    // Variables temporales para pruebas mientras se termina la llamada de API
                    $scope.especial = playloadJson[0].GrupoPrecioPropio;
                    $scope.precioIndexado = playloadJson[0].EsPrecioIndexado;

                    console.log("Especial: " + $scope.especial);
                    console.log("Precio indexado: " + $scope.precioIndexado);


                    // NO ESPECIAL Y PRECIO FIJO
                    if(!$scope.especial && !$scope.precioIndexado) {
                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioEnergia = true;

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioFijoNoEspecial",
                            data: JSON.stringify({ "IdTarifa": tarifa.IdTarifa, "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo, "IdAgenteComision": IdAgente, "sumaTarifaPeajeAPrecioEnergiaGas": $scope.sumaTarifaPeajeAPrecioEnergiaGas }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                $scope.tipo = 'noespecial';
                                $scope.updateFeeValue($scope.fee,'noespecial');
                                $scope.calcularTerminoPotenciaYEnergiaYTotal();
                                console.log("NO ESPECIAL Y PRECIO FIJO");
                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });

                    } else {
                        $scope.MostrarPrecioEnergia = false;
                    }


                    // ESPECIAL Y PRECIO INDEXADO
                    if($scope.especial && $scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioFEE = true;
                        // if($scope.consumoAnual != null){LimpiarConsumoanual = $scope.consumoAnual}

                        if($scope.comparadorLlamadaPreciosIndexadoEstandar){

                            // METODO 1 ESTANDAR -> SACAR PRECIOS POTENCIA INDEXADO
                            $http({
                                method: "POST",
                                url: urlApi + "Tarifa/PreciosPotenciaIndexados",
                                data: JSON.stringify({ "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo}),
                                headers: {
                                    'Content-Type': 'application/json; charset=UTF-8',
                                    Authorization: atob(localStorage.getItem("TokenValidador"))
                                }
                            }).then(function successCallback(response) {
                                var respuesta = response.data;
                                var decodeToken = jwtHelper.decodeToken(respuesta);
                                var playloadJson = JSON.parse(decodeToken.iss);
                                // console.log(playloadJson);

                                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                    alg: ["HS256"]
                                });

                                if (tokenValido) {
                                    $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                    // console.log($scope.arrayPreciosPotenciaEnergia);
                                    $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                    $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                    $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                    $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
                                    console.log("ESPECIAL Y PRECIO INDEXADO");

                                    $scope.fee = $scope.MostrarPrecioFEEMaximo;
                                    $scope.tipo = 'especial';
                                    $scope.updateFeeValue($scope.fee,'especial');
                                    $scope.MostrarAltaInfoFeeMinMax = true;

                                    $scope.getPreciosIndexado();

                                } else {
                                    $scope.ColectivosRepresentante = "";
                                    console.error("HASH DE VERIFICACION INCORRECTO");
                                }
                            });


                        }else{

                            // METODO 2 NO ESTANDAR -> SACAR PRECIOS POTENCIA INDEXADO: EJ CLIENTES [ELEIA,ANTONIO MADRID,...]
                            $http({
                                method: "POST",
                                url: urlApi + "Tarifa/GetDatosPrecioIndexadoEspecial",
                                data: JSON.stringify({ "IdAgente": IdAgente, "IdTarifa": tarifa.IdTarifa, "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo, "ConsumoAnualSIPS": Math.round($scope.consumoAnual) }),
                                headers: {
                                    'Content-Type': 'application/json; charset=UTF-8',
                                    Authorization: atob(localStorage.getItem("TokenValidador"))
                                }
                            }).then(function successCallback(response) {
                                var respuesta = response.data;
                                var decodeToken = jwtHelper.decodeToken(respuesta);
                                var playloadJson = JSON.parse(decodeToken.iss);
                                // console.log(playloadJson);

                                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                    alg: ["HS256"]
                                });

                                if (tokenValido) {
                                    $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                    // console.log($scope.arrayPreciosPotenciaEnergia);
                                    $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                    $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                    $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                    $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
                                    console.log("ESPECIAL Y PRECIO INDEXADO");
                                    /*alert("PRODUCTO ESPECIAL E INDEXADO");*/
                                    // console.log(playloadJson);

                                    $scope.fee = $scope.MostrarPrecioFEEMaximo;
                                    $scope.tipo = 'especial';
                                    $scope.updateFeeValue($scope.fee,'especial');
                                    $scope.MostrarAltaInfoFeeMinMax = true;


                                    $scope.getPreciosIndexado();


                                } else {
                                    $scope.ColectivosRepresentante = "";
                                    console.error("HASH DE VERIFICACION INCORRECTO");
                                }
                            });

                        }

                    } else {
                        $scope.MostrarPrecioFEE = false;
                    }


                    // NO ESPECIAL Y PRECIO INDEXADO
                    if(!$scope.especial && $scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.feedisabled = true;
                        $scope.MostrarPrecioFEE = true;
                        $scope.MostrarAltaInfoFeeMinMax = false;

                        if($scope.comparadorLlamadaPreciosIndexadoEstandar){
                            console.log("METODO 1 ESTANDAR");
                            // METODO 1 ESTANDAR -> SACAR PRECIOS POTENCIA INDEXADO
                            $http({
                                method: "POST",
                                url: urlApi + "Tarifa/PreciosPotenciaIndexados",
                                data: JSON.stringify({ "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo }),
                                headers: {
                                    'Content-Type': 'application/json; charset=UTF-8',
                                    Authorization: atob(localStorage.getItem("TokenValidador"))
                                }
                            }).then(function successCallback(response) {
                                var respuesta = response.data;
                                var decodeToken = jwtHelper.decodeToken(respuesta);
                                var playloadJson = JSON.parse(decodeToken.iss);

                                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                    alg: ["HS256"]
                                });

                                if (tokenValido) {
                                    // alert("Productos indexados no disponibles.")
                                    // console.log(playloadJson);
                                    $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                    //console.log($scope.arrayPreciosPotenciaEnergia);
                                    // $scope.fee = playloadJson[0].datoFEE;
                                    $scope.tipo = 'noespecial';
                                    $scope.updateFeeValue($scope.fee,'noespecial');
                                    console.log("NO ESPECIAL Y PRECIO INDEXADO");
                                    $scope.getPreciosIndexado();

                                    // console.log(playloadJson);

                                } else {
                                    $scope.ColectivosRepresentante = "";
                                    console.error("HASH DE VERIFICACION INCORRECTO");
                                }
                            });

                        }else{
                            // METODO 2 NO ESTANDAR -> SACAR PRECIOS POTENCIA INDEXADO: EJ CLIENTES [ELEIA,ANTONIO MADRID,...]
                            $http({
                                method: "POST",
                                url: urlApi + "Tarifa/GetDatosPrecioIndexadoNoEspecial",
                                data: JSON.stringify({ "IdAgenteComision": IdAgente, "IdTarifa": tarifa.IdTarifa, "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo }),
                                headers: {
                                    'Content-Type': 'application/json; charset=UTF-8',
                                    Authorization: atob(localStorage.getItem("TokenValidador"))
                                }
                            }).then(function successCallback(response) {
                                var respuesta = response.data;
                                var decodeToken = jwtHelper.decodeToken(respuesta);
                                var playloadJson = JSON.parse(decodeToken.iss);

                                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                    alg: ["HS256"]
                                });

                                if (tokenValido) {
                                    // alert("Productos indexados no disponibles.")

                                    $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                    //console.log($scope.arrayPreciosPotenciaEnergia);
                                    $scope.fee = playloadJson[0].datoFEE;
                                    $scope.tipo = 'noespecial';
                                    $scope.updateFeeValue($scope.fee,'noespecial');
                                    console.log("NO ESPECIAL Y PRECIO INDEXADO");
                                    $scope.getPreciosIndexado();

                                    // console.log(playloadJson);

                                } else {
                                    $scope.ColectivosRepresentante = "";
                                    console.error("HASH DE VERIFICACION INCORRECTO");
                                }
                            });

                        }

                    } else {
                        $scope.feedisabled = false;
                    }


                    // ESPECIAL Y PRECIO FIJO
                    if($scope.especial && !$scope.precioIndexado) {
                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioEnergia = true;
                        $scope.MostrarPrecioFEE = true;

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioFijoEspecial",
                            data: JSON.stringify({ "IdTarifa": tarifa.IdTarifa, "IdTarifaGrupo": tarifaGrupo.IdTarifaGrupo, "IdAgenteComision": IdAgente,
                            "IdAgente": IdAgente, "ConsumoAnualSIPS": Math.round($scope.consumoAnual) }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {

                                //console.log("#GetDatosPrecioFijoEspecial");
                                // console.log(playloadJson);
                                $scope.arrayPreciosPotenciaEnergia = playloadJson;

                                // console.log($scope.arrayPreciosPotenciaEnergia);

                                // Recorremos los precios potencia y al ser especial sumamos el margen del comercial al precio de cada una de las potencias
                                $scope.arrayPreciosPotenciaEnergia.forEach( function(arrayPrecioPotenciaEnergia, i, array) {

                                    //console.log(arrayPrecioPotenciaEnergia.PrecioE + " + " +  ($scope.margen/1000));
                                    // console.log(arrayPrecioPotenciaEnergia.PrecioE  + ($scope.margen * $scope.impuestoExtraPrecioEnergia)/1000);

                                    // Comprobamos si no tiene el impuesto municipal comparandolo con 0
                                    if($scope.impuestoExtraPrecioEnergia == 0){
                                        arrayPrecioPotenciaEnergia.PrecioE = arrayPrecioPotenciaEnergia.PrecioE + ($scope.margen/1000);
                                    }else{
                                        arrayPrecioPotenciaEnergia.PrecioE = arrayPrecioPotenciaEnergia.PrecioE + (($scope.margen * $scope.impuestoExtraPrecioEnergia)/1000);

                                    }
                                    // console.log(arrayPrecioPotenciaEnergia);


                                });

                                // console.log($scope.arrayPreciosPotenciaEnergia);

                                console.log("ESPECIAL Y PRECIO FIJO");
                                $scope.calcularTerminoPotenciaYEnergiaYTotal();

                                $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;

                                $scope.fee = $scope.MostrarPrecioFEEMaximo;
                                $scope.tipo = 'especial';
                                $scope.updateFeeValue($scope.fee,'especial');
                                $scope.MostrarAltaInfoFeeMinMax = true;

                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });


                    } else {
                        // $scope.MostrarPrecioEnergia = false;
                        // $scope.MostrarPrecioFEE = false;
                    }



                } else {
                    $scope.GruposTarifa = "";
                    console.error("HASH DE VERIFICACION INCORRECTO");
                }


                // Si su comercializadora tiene algun precio metido de potencia o energia recalculamos con las nuevas fechas
                if(
                ($scope.suComercializadoraPreciosPotencia[0]!= undefined && $scope.suComercializadoraPreciosPotencia[0]['PrecioP']) ||
                ($scope.suComercializadoraPreciosPotencia[1]!= undefined && $scope.suComercializadoraPreciosPotencia[1]['PrecioP']) ||
                ($scope.suComercializadoraPreciosPotencia[2]!= undefined && $scope.suComercializadoraPreciosPotencia[2]['PrecioP']) ||
                ($scope.suComercializadoraPreciosPotencia[3]!= undefined && $scope.suComercializadoraPreciosPotencia[3]['PrecioP']) ||
                ($scope.suComercializadoraPreciosPotencia[4]!= undefined && $scope.suComercializadoraPreciosPotencia[4]['PrecioP']) ||
                ($scope.suComercializadoraPreciosPotencia[5]!= undefined && $scope.suComercializadoraPreciosPotencia[5]['PrecioP']) ||
                ($scope.suComercializadoraPreciosPotencia[6]!= undefined && $scope.suComercializadoraPreciosPotencia[6]['PrecioP'])){

                    $scope.calcularTerminoPotenciaYEnergiaYTotalSuyo();

                }


            }); // end then

            // calcularTerminoPotenciaYEnergiaYTotalSuyo(); // recalculamos los precios suyos

    }


    // FUNCION PARA TRAERME LOS PRECIOS DE NO ESPECIAL INDEXADO
    $scope.getPreciosIndexado = function () {

        $scope.loading = true;
        //console.log($scope.grupoTarifa.IdTarifaGrupo);
        //$scope.grupoTarifa = JSON.parse($scope.GrupoTarifaModel);
        // Nos traemos los conceptos/formulas
        $http({

            method: "POST",
            url: urlApi + "Comparador/Conceptos",
            data: JSON.stringify({ "IdTarifaGrupo": $scope.grupoTarifa.IdTarifaGrupo }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            },
        }).then(function successCallback(formulas) {

            $scope.formulas = formulas.data;

            // console.log($scope.margen);
            if($scope.margen !=null && $scope.margen!=0 && $scope.margen!="0" && $scope.margen!=undefined)
                $scope.fee = $scope.margen; // En vez de sumarlo abajo se lo pasamos a la llamada y lo suma en el calculo de precios

            // CALL traer precios indexado en base al FEE las operaciones de iñigo y el cups
            $http({

                method: "POST",
                url: (urlApiComparadorTemporal!="") ? urlApiComparadorTemporal + "Comparador/Comparar" : urlApi + "Comparador/Comparar",
                data: JSON.stringify({ "CUPS": $scope.cliente.CodigoCUPS, "Fee": $scope.fee, "Formulas": $scope.formulas }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                },
            }).then(function successCallback(response) {
                // console.log('Entra');
                // console.log(JSON.parse(response.data));
                // var respuesta = response.data;
                // var decodeToken = jwtHelper.decodeToken(respuesta);
                // var playloadJson = JSON.parse(decodeToken.iss);
                // console.log(playloadJson);

                // var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                //     alg: ["HS256"]
                // });

                // if (tokenValido) {

                    // Calculo de dias entre las fechas seleccionadas en la tabla de consumos y suma de consumos
                    const diffTime = Math.abs($scope.SumaConsumosPotencias.fechaFin - $scope.SumaConsumosPotencias.fechaInicio);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    $scope.totalDias = diffDays;
                    //console.log("Dias:" + diffDays);

                    var contador = 0;
                    var sumatorioPrecioEnergiaP1 = 0;
                    var sumatorioPrecioEnergiaP2 = 0;
                    var sumatorioPrecioEnergiaP3 = 0;
                    var sumatorioPrecioEnergiaP4 = 0;
                    var sumatorioPrecioEnergiaP5 = 0;
                    var sumatorioPrecioEnergiaP6 = 0;
                    var preciosEnergiaIndexado = JSON.parse(response.data);


                    // Hacemos el sumatorio de los precios de todos los meses para despues dividirlos y sacar la media
                    preciosEnergiaIndexado.forEach( function(precioEnergiaIndexado, i, array) {
                        // console.log(precioEnergiaIndexado);
                        if(precioEnergiaIndexado.PrecioP1  != 0 ||
                        precioEnergiaIndexado.PrecioP2 != 0 ||
                        precioEnergiaIndexado.PrecioP3 != 0 ||
                        precioEnergiaIndexado.PrecioP4 != 0 ||
                        precioEnergiaIndexado.PrecioP5 != 0 ||
                        precioEnergiaIndexado.PrecioP6 != 0
                        ){
                            precioEnergiaIndexado.FechaInicio = new Date(precioEnergiaIndexado.FechaInicio);

                            // Se suma 1 día por que el SIPS me trae los datos a dia 1/xx/xxxx y el programa de iñigo a dia 30/xx/xxx o 31/xx/xx (si no no entraría en el if xq no conciden fechas)
                            precioEnergiaIndexado.FechaInicio.setDate(precioEnergiaIndexado.FechaInicio.getDate() + 1);

                            precioEnergiaIndexado.FechaFin = new Date(precioEnergiaIndexado.FechaFin);
                            //console.log(precioEnergiaIndexado.FechaInicio  + ">=" + $scope.SumaConsumosPotencias.fechaInicio);
                            // Controla los seleccionados en el comparador con las fechas traidas de las llamadas
                            if(precioEnergiaIndexado.FechaInicio >= $scope.SumaConsumosPotencias.fechaInicio && precioEnergiaIndexado.FechaFin <= $scope.SumaConsumosPotencias.fechaFin){

                                //console.log("#precioEnergiaIndexado");
                                //console.log(precioEnergiaIndexado);

                                contador++;
                                sumatorioPrecioEnergiaP1 = sumatorioPrecioEnergiaP1 + precioEnergiaIndexado.PrecioP1;
                                sumatorioPrecioEnergiaP2 = sumatorioPrecioEnergiaP2 + precioEnergiaIndexado.PrecioP2;
                                sumatorioPrecioEnergiaP3 = sumatorioPrecioEnergiaP3 + precioEnergiaIndexado.PrecioP3;
                                sumatorioPrecioEnergiaP4 = sumatorioPrecioEnergiaP4 + precioEnergiaIndexado.PrecioP4;
                                sumatorioPrecioEnergiaP5 = sumatorioPrecioEnergiaP5 + precioEnergiaIndexado.PrecioP5;
                                sumatorioPrecioEnergiaP6 = sumatorioPrecioEnergiaP6 + precioEnergiaIndexado.PrecioP6;

                            }

                        }
                    });

                    // FORMA #1 CON DIVISION POR PERIODOS
                    // Divimos con contador que son el numero de meses con precio distinto de 0 y sacamos el precio medio
                    sumatorioPrecioEnergiaP1 = sumatorioPrecioEnergiaP1/contador;
                    sumatorioPrecioEnergiaP2 = sumatorioPrecioEnergiaP2/contador;
                    sumatorioPrecioEnergiaP3 = sumatorioPrecioEnergiaP3/contador;
                    sumatorioPrecioEnergiaP4 = sumatorioPrecioEnergiaP4/contador;
                    sumatorioPrecioEnergiaP5 = sumatorioPrecioEnergiaP5/contador;
                    sumatorioPrecioEnergiaP6 = sumatorioPrecioEnergiaP6/contador;

                    // FORMA #2 CON DIVISION POR DIAS
                    // tomamos los sumatiorios de energia y los dividimos por los dias comprendidos entre los rangos de fecha inicio y fin y luego los multiplicamos por 30
                    /*
                    sumatorioPrecioEnergiaP1 = (sumatorioPrecioEnergiaP1/$scope.totalDias)*30;
                    sumatorioPrecioEnergiaP2 = (sumatorioPrecioEnergiaP2/$scope.totalDias)*30;
                    sumatorioPrecioEnergiaP3 = (sumatorioPrecioEnergiaP3/$scope.totalDias)*30;
                    sumatorioPrecioEnergiaP4 = (sumatorioPrecioEnergiaP4/$scope.totalDias)*30;
                    sumatorioPrecioEnergiaP5 = (sumatorioPrecioEnergiaP5/$scope.totalDias)*30;
                    sumatorioPrecioEnergiaP6 = (sumatorioPrecioEnergiaP6/$scope.totalDias)*30;
                    */

                    //console.log($scope.arrayPreciosPotenciaEnergia);

                    // Rellenamos el array a mostrar
                    $scope.arrayPreciosPotenciaEnergia.forEach( function(arrayPrecioPotenciaEnergia, i, array) {
                        if(arrayPrecioPotenciaEnergia.txtPrecioP == "P1"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP1; }
                        if(arrayPrecioPotenciaEnergia.txtPrecioP == "P2"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP2; }
                        if(arrayPrecioPotenciaEnergia.txtPrecioP == "P3"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP3; }
                        if(arrayPrecioPotenciaEnergia.txtPrecioP == "P4"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP4; }
                        if(arrayPrecioPotenciaEnergia.txtPrecioP == "P5"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP5; }
                        if(arrayPrecioPotenciaEnergia.txtPrecioP == "P6"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP6; }

                        // arrayPrecioPotenciaEnergia.PrecioP = 0; // Limpiamos los precio potencia
                    })

                    // SUMAMOS EL MARGEN(FEE) EN ESPECIAL INDEXADO
                    // TODO: ASIGNAMOS AL FEE EL MARGEN EN Y SE LO ENVIAMOS EN LA LLAMADA DE COMPARAR EN VEZ DE SUMARSELO AQUI MANUALMENTE
                    // if($scope.tipo == 'especial'){
                    //     console.log("// SUMAMOS EL MARGEN(FEE) EN ESPECIAL INDEXADO")
                    //     $scope.arrayPreciosPotenciaEnergia.forEach( function(arrayPrecioPotenciaEnergia, i, array) {

                    //         //console.log(arrayPrecioPotenciaEnergia.PrecioE + " + " +  ($scope.margen/1000));
                    //         arrayPrecioPotenciaEnergia.PrecioE = arrayPrecioPotenciaEnergia.PrecioE + ($scope.margen/1000);
                    //         //console.log(arrayPrecioPotenciaEnergia);

                    //     });
                    // }


                    // console.log("llego");
                    $scope.loading = false;
                    $scope.calcularTerminoPotenciaYEnergiaYTotal();

                // } else {
                //     $scope.ColectivosRepresentante = "";
                //     console.error("HASH DE VERIFICACION INCORRECTO");
                // }
            }, function errorCallback(response) {
                $scope.loading = false;
                // console.log(response);
            });

        }, function errorCallback(response) {
            $scope.loading = false;
            // console.log(response);
        });

    }




    // calculo de termino potencia =(potenciaFacturadaP1*precioPotenciaP1 + potenciaFacturadaP2*precioPotenciaP2 + potenciaFacturadaP3*precioPotenciaP3 + potenciaFacturadaP4*precioPotenciaP4
    // + potenciaFacturadaP5*precioPotenciaP5 + potenciaFacturadaP6*precioPotenciaP6) * DIAS
    $scope.calcularTerminoPotenciaYEnergiaYTotal = function () {

        $scope.calculoTerminoPotencia = 0;
        $scope.calculoTerminoEnergia = 0;

        // Calculo de dias entre las fechas seleccionadas en la tabla de consumos y suma de consumos
        const diffTime = Math.abs($scope.SumaConsumosPotencias.fechaFin - $scope.SumaConsumosPotencias.fechaInicio);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        $scope.totalDias = diffDays;
        // console.log("Dias:" + diffDays);

        // console.log("$scope.arrayPreciosPotenciaEnergia");
        // console.log($scope.arrayPreciosPotenciaEnergia);

        // Recorremos todos los valores
        $scope.arrayPreciosPotenciaEnergia.forEach( function(PrecioPotenciaEnergia, i, array) {
            // console.log("En el índice " + indice + " hay este valor: " + valor);

            // Si es una 2.0 DHA O 2.1 DHA Hacemos los calculos con el palor de p1
            // if( ($scope.cliente.nombreTarifa == '2.0DHA' || $scope.cliente.nombreTarifa == '2.1DHA' ) && i == 1){
            //     $scope.calculoTerminoPotencia = $scope.calculoTerminoPotencia + ($scope.arrayPreciosPotenciaEnergia[0].PrecioP * $scope.SumaConsumosPotencias["PotenciasContratadasEnWP" + (i+1)]);
            // }else{
            //     $scope.calculoTerminoPotencia = $scope.calculoTerminoPotencia + (PrecioPotenciaEnergia.PrecioP * $scope.SumaConsumosPotencias["PotenciasContratadasEnWP" + (i+1)]);
            // }

            if($scope.cliente.nombreTarifa == '3.1' || $scope.cliente.nombreTarifa == '3.2' || $scope.cliente.nombreTarifa == '3.3' || $scope.cliente.nombreTarifa == '3.4'){
                // Si la empresa tiene un termino fijo lo hacemos en base a la tarifa, si no no ejecuta operacion -> 0
                if($scope.ComparadorGasCalculoConTerminoFijo){
                    switch($scope.cliente.nombreTarifa) {
                        case '3.1':
                            $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 4.44;
                        break;
                        case '3.2':
                            $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 8.88;
                        break;
                        case '3.3':
                            $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 50;
                        break;
                        case '3.4':
                            $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0;
                        break;
                        default:
                            $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0;
                      }
                      $scope.mesesEnPeriodos = $scope.totalDias/30;
                    //   console.log('$scope.totalDias',$scope.totalDias);
                    //   console.log('$scope.mesesEnPeriodos',$scope.mesesEnPeriodos);
                      $scope.calculoTerminoPotencia = $scope.arrayPreciosPotenciaEnergia[0].PrecioP * ($scope.totalDias/30); // TODO: NUMERO DE MESES REALES EN EL TOTAL DE DÍAS
                    //   $scope.calculoTerminoPotencia = $scope.calculoTerminoPotencia * $scope.selected.length; // TODO: NUMERO DE PERIODOS SELECCIONADOS

                }else{
                    $scope.calculoTerminoPotencia = 0;
                }

            }else{
                $scope.calculoTerminoPotencia = (($scope.arrayPreciosPotenciaEnergia[0].PrecioP * 365)/12) * ($scope.cliente.CaudalMaximoDiarioEnWh/1000) ;
            }
            // console.log("$scope.calculoTerminoPotencia");
            // console.log($scope.calculoTerminoPotencia);
            // console.log($scope.SumaConsumosPotencias.ConsumoEnWhP1);
            $scope.calculoTerminoEnergia = $scope.calculoTerminoEnergia + (PrecioPotenciaEnergia.PrecioE * $scope.SumaConsumosPotencias.ConsumoEnWhP1);

            // $scope.calculoTerminoEnergia = $scope.calculoTerminoEnergia + (PrecioPotenciaEnergia.PrecioE * $scope.SumaConsumosPotencias["p" + (i+1)]);


        });

        // LUZ
        // $scope.calculoTerminoPotencia = $scope.calculoTerminoPotencia * diffDays;

        // $scope.baseImpuestoElectrico = $scope.calculoTerminoPotencia + $scope.calculoTerminoEnergia;

        // $scope.baseConImpuestoElectrico = $scope.baseImpuestoElectrico*($scope.impuestoElectrico/100);

        // $scope.baseImponibleNuestra = $scope.baseImpuestoElectrico + $scope.baseConImpuestoElectrico + $scope.costeAlquilerPropio + $scope.serviciosAdicionalesPropios;
        // $scope.baseImponibleConIVANuestra = $scope.baseImponibleNuestra*($scope.iva/100);

        // $scope.totalNuestro = $scope.baseImponibleNuestra + $scope.baseImponibleConIVANuestra;

        // GAS
        $scope.calculoTerminoPotencia = ($scope.ComparadorGasCalculoConTerminoFijo) ? $scope.calculoTerminoPotencia : $scope.calculoTerminoPotencia * diffDays; // TODO: NO IDEA


        // $scope.baseImpuestoElectrico = $scope.calculoTerminoPotencia + $scope.calculoTerminoEnergia;

        $scope.baseConImpuestoHidrocarburos = $scope.SumaConsumosPotencias.ConsumoEnWhP1*$scope.impuestoHidrocarburos;

        $scope.baseImpuestoHidrocarburos = $scope.calculoTerminoEnergia + $scope.baseConImpuestoHidrocarburos;

        $scope.baseImponibleNuestra = $scope.baseConImpuestoHidrocarburos + $scope.calculoTerminoEnergia + $scope.calculoTerminoPotencia + $scope.costeAlquilerPropio + $scope.serviciosAdicionalesPropios;
        $scope.baseImponibleConIVANuestra = $scope.baseImponibleNuestra*($scope.iva/100);

        $scope.totalNuestro = $scope.baseImponibleNuestra + $scope.baseImponibleConIVANuestra;

    }

    $scope.calcularTerminoPotenciaYEnergiaYTotalSuyo = function () {

        // console.log($scope.suComercializadoraPreciosPotencia); 6.1

        // Calculo de dias entre las fechas seleccionadas en la tabla de consumos y suma de consumos
        const diffTime = Math.abs($scope.SumaConsumosPotencias.fechaFin - $scope.SumaConsumosPotencias.fechaInicio);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        $scope.totalDias = diffDays;
        $scope.mesesEnPeriodos = $scope.totalDias/30;
        //console.log("Dias:" + diffDays);

        // comprobamos si ha metido termino potencia total y/o termino energia total
        if($scope.recalculoManual == 0){

            $scope.calculoTerminoPotenciaSuyo = 0;
            $scope.calculoTerminoEnergiaSuyo = 0;

            // Recorremos todos los valores
            $scope.suComercializadoraPreciosPotencia.forEach( function(PrecioPotenciaEnergia, i, array) {
                // console.log("En el índice " + indice + " hay este valor: " + valor);

                if(PrecioPotenciaEnergia.PrecioP && PrecioPotenciaEnergia.PrecioE == undefined){ PrecioPotenciaEnergia.PrecioE = 0; } // HECHO PARA TARIFAS 6.1 POR QUE SI NO INICILIZAMOS A 0 PETA EL CALCULO

                // Si es una 2.0 DHA O 2.1 DHA Hacemos los calculos con el palor de p1
                // if( ($scope.cliente.nombreTarifa == '2.0DHA' || $scope.cliente.nombreTarifa == '2.1DHA' ) && i == 1){
                //     $scope.calculoTerminoPotenciaSuyo = $scope.calculoTerminoPotenciaSuyo + ($scope.suComercializadoraPreciosPotencia[0].PrecioP * $scope.SumaConsumosPotencias["PotenciasContratadasEnWP" + (i+1)]);
                // }else{
                //     $scope.calculoTerminoPotenciaSuyo = $scope.calculoTerminoPotenciaSuyo + (PrecioPotenciaEnergia.PrecioP * $scope.SumaConsumosPotencias["PotenciasContratadasEnWP" + (i+1)]);
                // }

                // GAS
                if($scope.cliente.nombreTarifa == '3.1' || $scope.cliente.nombreTarifa == '3.2' || $scope.cliente.nombreTarifa == '3.3' || $scope.cliente.nombreTarifa == '3.4'){
                    $scope.calculoTerminoPotenciaSuyo = ($scope.ComparadorGasCalculoConTerminoFijo) ? ($scope.MostrarComparadorGasConCalculoTerminoFijo) ? PrecioPotenciaEnergia.PrecioP * $scope.mesesEnPeriodos : PrecioPotenciaEnergia.PrecioP : 0;
                }else{
                    $scope.calculoTerminoPotenciaSuyo = ((PrecioPotenciaEnergia.PrecioP * 365)/12) * ($scope.cliente.CaudalMaximoDiarioEnWh/1000) ;
                }

                $scope.calculoTerminoEnergiaSuyo = $scope.calculoTerminoEnergiaSuyo + (PrecioPotenciaEnergia.PrecioE * $scope.SumaConsumosPotencias.ConsumoEnWhP1);

                // $scope.calculoTerminoEnergiaSuyo = Math.round($scope.calculoTerminoEnergiaSuyo * 100) / 100; // limitar a 2 decimales


                // $scope.calculoTerminoEnergiaSuyo = $scope.calculoTerminoEnergiaSuyo + (PrecioPotenciaEnergia.PrecioE * $scope.SumaConsumosPotencias["p" + (i+1)]);

                // console.log($scope.calculoTerminoPotenciaSuyo);
            });






            // LUZ
            // $scope.calculoTerminoPotenciaSuyo = $scope.calculoTerminoPotenciaSuyo * diffDays;


            // $scope.baseImpuestoElectricoSuyo =
            // ($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))) +
            // ($scope.calculoTerminoEnergiaSuyo - ($scope.calculoTerminoEnergiaSuyo*($scope.descuentoTerminoEnergiaSuyo/100)));

            // console.log(($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))));
            // console.log($scope.baseImpuestoElectricoSuyo);


            // $scope.baseConImpuestoElectricoSuyo = $scope.baseImpuestoElectricoSuyo*($scope.impuestoElectrico/100);

            // $scope.baseImponibleSuya =
            // ($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))) +
            // ($scope.calculoTerminoEnergiaSuyo - ($scope.calculoTerminoEnergiaSuyo*($scope.descuentoTerminoEnergiaSuyo/100))) +
            // $scope.baseConImpuestoElectricoSuyo +
            // $scope.costeAlquilerSuyo +
            // $scope.serviciosAdicionalesSuyo;


            // $scope.baseImponibleConIVASuya = $scope.baseImponibleSuya*($scope.iva/100);

            // $scope.totalSuyo = $scope.baseImponibleSuya + $scope.baseImponibleConIVASuya;




            // console.log("$scope.calculoTerminoPotenciaSuyo");
            // console.log($scope.calculoTerminoPotenciaSuyo);
            // console.log("diffDays");
            // console.log(diffDays);

            // GAS
            // $scope.calculoTerminoPotenciaSuyo = $scope.calculoTerminoPotenciaSuyo * diffDays;
            $scope.calculoTerminoPotenciaSuyo = ($scope.ComparadorGasCalculoConTerminoFijo) ? $scope.calculoTerminoPotenciaSuyo : $scope.calculoTerminoPotenciaSuyo * diffDays; // TODO: NO IDEA


            // $scope.baseImpuestoElectrico = $scope.calculoTerminoPotencia + $scope.calculoTerminoEnergia;

            $scope.baseConImpuestoHidrocarburosSuyo = $scope.SumaConsumosPotencias.ConsumoEnWhP1*$scope.impuestoHidrocarburos;

            $scope.baseImpuestoHidrocarburosSuyo = $scope.calculoTerminoEnergiaSuyo + $scope.baseConImpuestoHidrocarburosSuyo;

            $scope.baseImponibleSuya =
            ($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))) +
            ($scope.calculoTerminoEnergiaSuyo - ($scope.calculoTerminoEnergiaSuyo*($scope.descuentoTerminoEnergiaSuyo/100))) +
            $scope.baseConImpuestoHidrocarburosSuyo +
            $scope.costeAlquilerSuyo +
            $scope.serviciosAdicionalesSuyo;


            $scope.baseImponibleConIVASuya = $scope.baseImponibleSuya*($scope.iva/100);

            $scope.totalSuyo = $scope.baseImponibleSuya + $scope.baseImponibleConIVASuya;



        }else{

            // console.log("Ha metido manual");
            $scope.recalcularManual();

        }

    }

    // RECALCULO CUANDO CAMBIAMOS EL IVA
    $scope.reCalcularIvas = function () {
        $scope.calcularTerminoPotenciaYEnergiaYTotalSuyo();
        $scope.calcularTerminoPotenciaYEnergiaYTotal();
    }


    // RECALCULA EL TOTAL CON LOS DATOS METIDOS MANUALMENTE
    $scope.recalcularManual = function () {

        // console.log("test");
        $scope.recalculoManual = 1;

        // LUZ
        // $scope.baseImpuestoElectricoSuyo =
        // ($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))) +
        // ($scope.calculoTerminoEnergiaSuyo - ($scope.calculoTerminoEnergiaSuyo*($scope.descuentoTerminoEnergiaSuyo/100)));


        // $scope.baseConImpuestoElectricoSuyo = $scope.baseImpuestoElectricoSuyo*($scope.impuestoElectrico/100);

        // $scope.baseImponibleSuya =
        // ($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))) +
        // ($scope.calculoTerminoEnergiaSuyo - ($scope.calculoTerminoEnergiaSuyo*($scope.descuentoTerminoEnergiaSuyo/100))) +
        // $scope.baseConImpuestoElectricoSuyo +
        // $scope.costeAlquilerSuyo +
        // $scope.serviciosAdicionalesSuyo;

        // $scope.baseImponibleConIVASuya = $scope.baseImponibleSuya*($scope.iva/100);

        // $scope.totalSuyo = $scope.baseImponibleSuya + $scope.baseImponibleConIVASuya;


        // GAS
        // $scope.calculoTerminoPotenciaSuyo = $scope.calculoTerminoPotenciaSuyo * $scope.totalDias; // TODO: NO IDEA

        $scope.baseConImpuestoHidrocarburosSuyo = $scope.SumaConsumosPotencias.ConsumoEnWhP1*$scope.impuestoHidrocarburos;

        $scope.baseImpuestoHidrocarburosSuyo = $scope.calculoTerminoEnergiaSuyo + $scope.baseConImpuestoHidrocarburosSuyo;

        $scope.baseImponibleSuya =
        ($scope.calculoTerminoPotenciaSuyo - ($scope.calculoTerminoPotenciaSuyo*($scope.descuentoTerminoPotenciaSuyo/100))) +
        ($scope.calculoTerminoEnergiaSuyo - ($scope.calculoTerminoEnergiaSuyo*($scope.descuentoTerminoEnergiaSuyo/100))) +
        $scope.baseConImpuestoHidrocarburosSuyo +
        $scope.costeAlquilerSuyo +
        $scope.serviciosAdicionalesSuyo;

        $scope.baseImponibleConIVASuya = $scope.baseImponibleSuya*($scope.iva/100);

        $scope.totalSuyo = $scope.baseImponibleSuya + $scope.baseImponibleConIVASuya;


    }

    // COMPARA LOS PRECIOS TOTALES Y SACA MENSUAL Y %
    $scope.compararPrecios = function () {
        // console.log("entro");
        $scope.diferenciaAhorroPeriodo = $scope.totalSuyo - $scope.totalNuestro;
        $scope.diferenciaAhorroPorcentaje = ($scope.diferenciaAhorroPeriodo / $scope.totalNuestro)*100;
        $scope.diferenciaAhorroAnual = ($scope.diferenciaAhorroPeriodo/$scope.totalDias)*365;

        // console.log($scope.diferenciaAhorroPeriodo);
        // console.log($scope.diferenciaAhorroPorcentaje);
        // console.log($scope.diferenciaAhorroAnual);
    }


    $scope.updateFeeValue = function (datos,tipo) {

        $scope.fee = datos;

       if(tipo=='especial'){

        // console.log($scope.arrayPreciosPotenciaEnergia);

        // console.log($scope.MostrarPrecioFEEMinimo);
        // console.log($scope.MostrarPrecioFEEMaximo);
        // console.log("PorcentajesComision");
        // console.log($scope.PorcentajesComision);


        var flag = 0;
        // console.log($scope.PorcentajesComision);
        $scope.PorcentajesComision.forEach(function(item){

            if($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]){
                // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
                $scope.CalculoComision = (($scope.consumoAnual*datos)/1000)*(item["PorcentajeComision"]/100);
                $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                $scope.CalculoComision = $scope.CalculoComision + "€";
                $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                // console.log($scope.CalculoComision);
                flag = 1;
            }
        });
        if(flag == 0){$scope.CalculoComision = "Datos no válidos";}
       }else if(tipo=='noespecial'){
        //    console.log($scope.arrayPreciosPotenciaEnergia);

            if($scope.arrayPreciosPotenciaEnergia[0] && !$scope.comparadorLlamadaPreciosIndexadoEstandar){
                if($scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0] != undefined && $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0] ){
                        var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
                        //console.log(valores);
                        if(valores.TipoAgenteParametro == 5){
                            //    console.log("Entro al 5");
                                $scope.CalculoComision = $scope.consumoAnual * valores.Valor;
                                $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                                $scope.CalculoComision = $scope.CalculoComision + "€";
                                $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                                // console.log($scope.CalculoComision);
                        }else if (valores.TipoAgenteParametro == 10){
                            //    console.log("Entro al 10");
                                $scope.CalculoComision = (($scope.consumoAnual * datos)/1000) * (valores.Valor/100);
                                $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                                $scope.CalculoComision = $scope.CalculoComision + "€";
                                $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                        }
                }
            }
       }

    }


	$scope.VerConsumos = function(selected) {
		$scope.consumosVisible = !$scope.consumosVisible;
		var cons = [];
		var cupsSelected = [];
		// CUPS seleccionados
		$.grep(selected, function(n) { cupsSelected.push(n.CodigoCUPS.toString()); });
		$.grep($scope.busquedaSips, function(n) {
			// Si el CUPS seleccionado se encuentra
			if (cupsSelected.indexOf(n.CUPS) > -1) {
				cons.push({
					name: n.CUPS,
					data: [ Number(n.ConsumoP1), Number(n.ConsumoP2), Number(n.ConsumoP3), Number(n.ConsumoP4), Number(n.ConsumoP5), Number(n.ConsumoP6) ]
				});
			}
		});
		new Highcharts.Chart({
			chart: { renderTo: 'grafico', type: 'column' },
			title: { text: 'Gráfico de Consumos' },
			yAxis: { title: { text: 'Consumo en kWh' }, min: 0 },
			xAxis: { categories: [ 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', ] },
			series: cons,
			credits: false
		});
	};

	// $scope.TrasladarContrato = function(contrato) {
	// 	if ($scope.VerEnergia === true && $scope.VerGas === false) { localStorage.setItem('tipoAlta', btoa('e')); }
	// 	if ($scope.VerEnergia === false && $scope.VerGas === true) { localStorage.setItem('tipoAlta', btoa('g')); }
    //     $rootScope.AltaSips = contrato;
    //     $location.url("/contratos/alta");
	// }

    // $scope.exportarDatosSIPS = function(selected) {
    //     var myData = [];
    //     var consumos = [];

    //     selected.forEach(function(obj) {
    //         var cups = obj.CodigoCUPS;
    //         var tarifa = "";
    //         var tension = "";
    //         var consumosA = [];
    //         var consAnual = "";
    //         var consAnualp1 = "";
    //         var consAnualp2 = "";
    //         var consAnualp3 = "";
    //         var consAnualp4 = "";
    //         var consAnualp5 = "";
    //         var consAnualp6 = "";

    //         // if (selected.length == 1) {
    //         //     //consumoAnual = $scope.consumosAnuales;
    //         //     $scope.TextoFichero = cups + "_General.xls";
    //         // }

    //         // if (selected.length > 1) {
    //         //     $scope.TextoFichero = "DescargaMasiva_General.xls";

    //         // }

    //         consumosA = $scope.busquedaSips.map(function(e) {
    //             if (e.CUPS == cups) {
    //                 consAnual = e.ConsumoAnual;
    //                 consAnualp1 = e.ConsumoP1;
    //                 consAnualp2 = parseInt(e.ConsumoP2);
    //                 consAnualp3 = e.ConsumoP3;
    //                 consAnualp4 = e.ConsumoP4;
    //                 consAnualp5 = e.ConsumoP5;
    //                 consAnualp6 = e.ConsumoP6;
    //             }
    //         });

    //         switch (obj.CodigoTarifaATREnVigor) {
    //             case "001":
    //                 tarifa = "2.0A";
    //                 break;
    //             case "003":
    //                 tarifa = "3.0A";
    //                 break;
    //             case "004":
    //                 tarifa = "2.0DHA";
    //                 break;
    //             case "005":
    //                 tarifa = "2.1A";
    //                 break;
    //             case "006":
    //                 tarifa = "2.1DHA";
    //                 break;
    //             case "007":
    //                 tarifa = "2.0DHS";
    //                 break;
    //             case "008":
    //                 tarifa = "2.1DHS";
    //                 break;
    //             case "011":
    //                 tarifa = "3.1A";
    //                 break;
    //             case "012":
    //                 tarifa = "6.1A";
    //                 break;
    //             case "013":
    //                 tarifa = "6.2";
    //                 break;
    //             case "014":
    //                 tarifa = "6.3";
    //                 break;
    //             case "015":
    //                 tarifa = "6.4";
    //                 break;
    //             case "016":
    //                 tarifa = "6.5";
    //                 break;
    //             case "017":
    //                 tarifa = "6.1B";
    //                 break;
    //         }

    //         switch (obj.CodigoTensionV) {
    //             case "01":
    //                 tension = "1X220";
    //                 break;
    //             case "02":
    //                 tension = "1X230";
    //                 break;
    //             case "03":
    //                 tension = "3X380";
    //                 break;
    //             case "04":
    //                 tension = "3X380/220";
    //                 break;
    //             case "05":
    //                 tension = "3X400";
    //                 break;
    //             case "06":
    //                 tension = "3X400/230";
    //                 break;
    //             case "07":
    //                 tension = "1X127";
    //                 break;
    //             case "08":
    //                 tension = "1X133";
    //                 break;
    //             case "09":
    //                 tension = "2X220";
    //                 break;
    //             case "10":
    //                 tension = "2X230";
    //                 break;
    //             case "11":
    //                 tension = "3X220";
    //                 break;
    //             case "12":
    //                 tension = "3X220/127";
    //                 break;
    //             case "13":
    //                 tension = "3X230";
    //                 break;
    //             case "14":
    //                 tension = "3X230/133";
    //                 break;
    //             case "15":
    //                 tension = "5.000";
    //                 break;
    //             case "16":
    //                 tension = "6.000";
    //                 break;
    //             case "17":
    //                 tension = "7.200";
    //                 break;
    //             case "18":
    //                 tension = "8.000";
    //                 break;
    //             case "19":
    //                 tension = "10.000";
    //                 break;
    //             case "20":
    //                 tension = "11.000";
    //                 break;
    //             case "21":
    //                 tension = "12.000";
    //                 break;
    //             case "22":
    //                 tension = "13.000";
    //                 break;
    //             case "23":
    //                 tension = "13.200";
    //                 break;
    //             case "24":
    //                 tension = "15.000";
    //                 break;
    //             case "25":
    //                 tension = "16.500";
    //                 break;
    //             case "26":
    //                 tension = "17.000";
    //                 break;
    //             case "27":
    //                 tension = "20.000";
    //                 break;
    //             case "28":
    //                 tension = "22.000";
    //                 break;
    //             case "29":
    //                 tension = "25.000";
    //                 break;
    //             case "30":
    //                 tension = "26.500";
    //                 break;
    //             case "31":
    //                 tension = "30.000";
    //                 break;
    //             case "32":
    //                 tension = "36.000";
    //                 break;
    //             case "33":
    //                 tension = "44.000";
    //                 break;
    //             case "34":
    //                 tension = "45.000";
    //                 break;
    //             case "35":
    //                 tension = "66.000";
    //                 break;
    //             case "36":
    //                 tension = "110.000";
    //                 break;
    //             case "37":
    //                 tension = "132.000";
    //                 break;
    //             case "38":
    //                 tension = "220.000";
    //                 break;
    //             case "39":
    //                 tension = "400.000";
    //                 break;
    //             case "40":
    //                 tension = "1.200";
    //                 break;
    //             case "41":
    //                 tension = "2.000";
    //                 break;
    //             case "42":
    //                 tension = "5.500";
    //                 break;
    //             case "43":
    //                 tension = "55.000";
    //                 break;
    //             case "44":
    //                 tension = "130.000";
    //                 break;
    //             case "45":
    //                 tension = "100";
    //                 break;
    //             case "46":
    //                 tension = "150";
    //                 break;
    //             case "47":
    //                 tension = "3x100";
    //                 break;
    //             case "48":
    //                 tension = "3x150/260";
    //                 break;
    //             case "49":
    //                 tension = "3x260";
    //                 break;
    //         }

    //         myData.push({
    //             'Codigo CUPS': obj.CodigoCUPS,
    //             'Codigo Empresa Distribuidora': (obj.CodigoEmpresaDistribuidora != null) ? obj.CodigoEmpresaDistribuidora : '',
    //             'Nombre Empresa Distribuidora': (obj.NombreEmpresaDistribuidora != null) ? obj.NombreEmpresaDistribuidora : '',
    //             'Codigo Postal PS': (obj.CodigoPostalPS != null) ? obj.CodigoPostalPS : '',
    //             'Municipio PS': parseInt(obj.MunicipioPS),
    //             'Codigo Provincia PS': (obj.CodigoProvinciaPS != null) ? obj.CodigoProvinciaPS : '',
    //             'Tension': tension,
    //             'Tarifa': tarifa,
    //             'Fecha Alta Suministro': (obj.FechaAltaSuministro != null) ? obj.FechaAltaSuministro : '',
    //             'Codigo TensionV': (obj.CodigoTensionV != null) ? obj.CodigoTensionV : '',
    //             'Potencia Maxima BIEW': (obj.PotenciaMaximaBIEW != null) ? parseInt(obj.PotenciaMaximaBIEW) : '',
    //             'Potencia Maxima APMW': (obj.PotenciaMaximaAPMW != null) ? parseInt(obj.PotenciaMaximaAPMW) : '',
    //             'Codigo Clasificacion PS': (obj.CodigoClasificacionPS != null) ? obj.CodigoClasificacionPS : '',
    //             'Codigo Disponibilidad ICP': (obj.CodigoDisponibilidadICP != null) ? obj.CodigoDisponibilidadICP : '',
    //             'Tipo Perfil Consumo': (obj.TipoPerfilConsumo != null) ? obj.TipoPerfilConsumo : '',
    //             'Valor Derechos ExtensionW': parseInt(obj.ValorDerechosExtensionW),
    //             'Valor Derechos AccesoW': parseInt(obj.ValorDerechosAccesoW),
    //             'Codigo Propiedad EquipoMedida': (obj.CodigoPropiedadEquipoMedida != null) ? obj.CodigoPropiedadEquipoMedida : '',
    //             'Codigo Propiedad ICP': (obj.CodigoPropiedadICP != null) ? obj.CodigoPropiedadICP : '',
    //             'Consumo Anual': parseInt(consAnual),
    //             'Consumo Anual P1': parseInt(consAnualp1),
    //             'Consumo Anual P2': parseInt(consAnualp2),
    //             'Consumo Anual P3': parseInt(consAnualp3),
    //             'Consumo Anual P4': parseInt(consAnualp4),
    //             'Consumo Anual P5': parseInt(consAnualp5),
    //             'Consumo Anual P6': parseInt(consAnualp6),
    //             'Potencias Contratadas En W P1': (obj.PotenciasContratadasEnWP1 != null) ? parseFloat(obj.PotenciasContratadasEnWP1).toFixed(2).replace('.',',') : '',
    //             'Potencias Contratadas En W P2': (obj.PotenciasContratadasEnWP2 != null) ? parseFloat(obj.PotenciasContratadasEnWP2).toFixed(2).replace('.',',') : '',
    //             'Potencias Contratadas En W P3': (obj.PotenciasContratadasEnWP3 != null) ? parseFloat(obj.PotenciasContratadasEnWP3).toFixed(2).replace('.',',') : '',
    //             'Potencias Contratadas En W P4': (obj.PotenciasContratadasEnWP4 != null) ? parseFloat(obj.PotenciasContratadasEnWP4).toFixed(2).replace('.',',') : '',
    //             'Potencias Contratadas En W P5': (obj.PotenciasContratadasEnWP5 != null) ? parseFloat(obj.PotenciasContratadasEnWP5).toFixed(2).replace('.',',') : '',
    //             'Potencias Contratadas En W P6': (obj.PotenciasContratadasEnWP6 != null) ? parseFloat(obj.PotenciasContratadasEnWP6).toFixed(2).replace('.',',') : '',
    //             'Fecha Ultimo Movimiento Contrato': (obj.FechaUltimoMovimientoContrato != null) ? obj.FechaUltimoMovimientoContrato : '',
    //             'Fecha Ultimo Cambio Comercializador': (obj.FechaUltimoCambioComercializador != null) ? obj.FechaUltimoCambioComercializador : '',
    //             'Fecha Limite Derechos Reconocidos': (obj.FechaLimiteDerechosReconocidos != null) ? obj.FechaLimiteDerechosReconocidos : '',
    //             'Fecha Ultima Lectura': (obj.FechaUltimaLectura != null) ? obj.FechaUltimaLectura : '',
    //             'Informacion Impagos': (obj.InformacionImpagos != null) ? obj.InformacionImpagos : '',
    //             'Importe Deposito Garantia Euros': parseInt(obj.ImporteDepositoGarantiaEuros),
    //             'Tipo Id Titular': (obj.TipoIdTitular != null) ? obj.TipoIdTitular : '',
    //             'Es Vivienda Habitual': (obj.EsViviendaHabitual != null) ? obj.EsViviendaHabitual : '',
    //             'Codigo Comercializadora': (obj.CodigoComercializadora != null) ? obj.CodigoComercializadora : '',
    //             'Codigo Telegestion': (obj.CodigoTelegestion != null) ? obj.CodigoTelegestion : '',
    //             'Codigo Fases Equipo Medida': (obj.CodigoFasesEquipoMedida != null) ? obj.CodigoFasesEquipoMedida : '',
    //             'Codigo Autoconsumo': (obj.CodigoAutoconsumo != null) ? obj.CodigoAutoconsumo : '',
    //             'Codigo TipoContrato': (obj.CodigoTipoContrato != null) ? obj.CodigoTipoContrato : '',
    //             'Codigo Periodicidad Facturacion': (obj.CodigoPeriodicidadFacturacion != null) ? obj.CodigoPeriodicidadFacturacion : '',
    //             'Codigo BIE': (obj.CodigoBIE != null) ? obj.CodigoBIE : '',
    //             'Fecha Emision BIE': (obj.FechaEmisionBIE != null) ? obj.FechaEmisionBIE : '',
    //             'Fecha Caducidad BIE': (obj.FechaCaducidadBIE != null) ? obj.FechaCaducidadBIE : '',
    //             'Codigo APM': (obj.CodigoAPM != null) ? obj.CodigoAPM : '',
    //             'Fecha Emision APM': (obj.FechaEmisionAPM != null) ? obj.FechaEmisionAPM : '',
    //             'Fecha Caducidad APM': (obj.FechaCaducidadAPM != null) ? obj.FechaCaducidadAPM : '',
    //             'Relacion Transformacion Intensidad': (obj.RelacionTransformacionIntensidad != null) ? obj.RelacionTransformacionIntensidad : '',
    //             'CNAE': (obj.Cnae != null) ? obj.Cnae : '',
    //             'Codigo Modo Control Potencia': (obj.CodigoModoControlPotencia != null) ? obj.CodigoModoControlPotencia : '',
    //             'Potencia CGPW': (obj.PotenciaCGPW != null) ? obj.PotenciaCGPW : '',
    //             'Codigo DH Equipo De Medida': (obj.CodigoDHEquipoDeMedida != null) ? obj.CodigoDHEquipoDeMedida : '',
    //             'Codigo Accesibilidad Contador': (obj.CodigoAccesibilidadContador != null) ? obj.CodigoAccesibilidadContador : '',
    //             'Codigo PS Contratable': (obj.CodigoPSContratable != null) ? obj.CodigoPSContratable : '',
    //             'Motivo Estado No Contratable': (obj.MotivoEstadoNoContratable != null) ? obj.MotivoEstadoNoContratable : '',
    //             'Codigo Tension Medida': (obj.CodigoTensionMedida != null) ? obj.CodigoTensionMedida : '',
    //             'Codigo Clase Expediente': (obj.CodigoClaseExpediente != null) ? obj.CodigoClaseExpediente : '',
    //             'Codigo Motivo Expediente': (obj.CodigoMotivoExpediente != null) ? obj.CodigoMotivoExpediente : '',
    //             'Codigo Tipo Suministro': (obj.CodigoTipoSuministro != null) ? obj.CodigoTipoSuministro : '',
    //             'Aplicacion Bono Social': (obj.AplicacionBonoSocial != null) ? obj.AplicacionBonoSocial : '',
    //             'Descripcion Provincia PS': (obj.DesProvinciaPS != null) ? obj.DesProvinciaPS : '',
    //             'Descripcion Municipio PS': (obj.DesMunicipioPS != null) ? obj.DesMunicipioPS : '',
    //             'Tipo Via PS': (obj.TipoViaPS != null) ? obj.TipoViaPS : '',
    //             'Via PS': (obj.ViaPS != null) ? obj.ViaPS : '',
    //             'Num Finca PS': (obj.NumFincaPS != null) ? obj.NumFincaPS : '',
    //             'Portal PS': (obj.PortalPS != null) ? obj.PortalPS : '',
    //             'Escalera PS': (obj.EscaleraPS != null) ? obj.EscaleraPS : '',
    //             'Piso PS': (obj.PisoPS != null) ? obj.PisoPS : '',
    //             'Puerta PS': (obj.PuertaPS != null) ? obj.PuertaPS : '',
    //             'Codigo Presion': (obj.CodigoPresion != null) ? obj.CodigoPresion : '',
    //             'Codigo Peaje En Vigor': (obj.CodigoPeajeEnVigor != null) ? obj.CodigoPeajeEnVigor : '',
    //             'Caudal Maximo Diario En Wh': (obj.CaudalMaximoDiarioEnWh != null) ? obj.CaudalMaximoDiarioEnWh : '',
    //             'Caudal Horario En Wh': (obj.CaudalHorarioEnWh != null) ? obj.CaudalHorarioEnWh : '',
    //             'Derecho TUR': (obj.DerechoTUR != null) ? obj.DerechoTUR : '',
    //             'Fecha Ultima Inspeccion': (obj.FechaUltimaInspeccion != null) ? obj.FechaUltimaInspeccion : '',
    //             'Codigo Resultado Inspeccion': (obj.CodigoResultadoInspeccion != null) ? obj.CodigoResultadoInspeccion : '',
    //             'Codigo Contador': (obj.CodigoContador != null) ? obj.CodigoContador : '',
    //             'Calibre Contador': (obj.CalibreContador != null) ? obj.CalibreContador : '',
    //             'Tipo Contador': (obj.TipoContador != null) ? obj.TipoContador : '',
    //             'Propiedad Equipo Medida': (obj.PropiedadEquipoMedida != null) ? obj.PropiedadEquipoMedida : '',
    //             'Codigo Telemedida': (obj.CodigoTelemedida != null) ? obj.CodigoTelemedida : ''

    //         });

    //     });

	// 	/**
	// 	 * Descargamos el excel limpio de arriba
	// 	 */

	// 	$("#dvjson")
    //     .excelexportjs({
    //       containerid: "dvjson",
    //       datatype: 'json',
    //       dataset: myData,
    //       columns: getColumns(myData)
    //     });

    // };

    // $scope.exportarDatosSIPSGas = function(selected) {

    //     var myData = [];
    //     var consumos = [];

    //     $scope.busquedaSips.forEach(function(obj) {
    //         var cups = obj.CUPS;
    //         var tarifa = "";
    //         var presion = "";

    //         if (selected.length == 1) {
    //             $scope.TextoFichero = cups + "_General.xls";
    //         }

    //         if (selected.length > 1) {
    //             $scope.TextoFichero = "DescargaMasiva_General.xls";
    //         }

    //         switch (obj.CodigoPeajeEnVigor) {
    //             case "11":
    //                 tarifa = "1.1";
    //                 break;
    //             case "12":
    //                 tarifa = "1.2";
    //                 break;
    //             case "13":
    //                 tarifa = "1.3";
    //                 break;
    //             case "21":
    //                 tarifa = "2.1";
    //                 break;
    //             case "22":
    //                 tarifa = "2.2";
    //                 break;
    //             case "23":
    //                 tarifa = "2.3";
    //                 break;
    //             case "24":
    //                 tarifa = "2.4";
    //                 break;
    //             case "25":
    //                 tarifa = "2.5";
    //                 break;
    //             case "26":
    //                 tarifa = "2.6";
    //                 break;
    //             case "1B":
    //                 tarifa = "2.1 bis";
    //                 break;
    //             case "2B":
    //                 tarifa = "2.2 bis";
    //                 break;
    //             case "3B":
    //                 tarifa = "2.3 bis";
    //                 break;
    //             case "4B":
    //                 tarifa = "2.4 bis";
    //                 break;
    //             case "5B":
    //                 tarifa = "2.5 bis";
    //                 break;
    //             case "6B":
    //                 tarifa = "2.6 bis";
    //                 break;
    //             case "31":
    //                 tarifa = "3.1";
    //                 break;
    //             case "32":
    //                 tarifa = "3.2";
    //                 break;
    //             case "33":
    //                 tarifa = "3.3";
    //                 break;
    //             case "34":
    //                 tarifa = "3.4";
    //                 break;
    //             case "35":
    //                 tarifa = "3.5";
    //                 break;
    //             case "41":
    //                 tarifa = "4.1";
    //                 break;
    //             case "42":
    //                 tarifa = "4.2";
    //                 break;
    //             case "43":
    //                 tarifa = "4.3";
    //                 break;
    //             case "44":
    //                 tarifa = "4.4";
    //                 break;
    //             case "45":
    //                 tarifa = "4.5";
    //                 break;
    //             case "46":
    //                 tarifa = "4.6";
    //                 break;
    //             case "47":
    //                 tarifa = "4.7";
    //                 break;
    //             case "A1":
    //                 tarifa = "A1";
    //                 break;
    //             case "A2":
    //                 tarifa = "A2";
    //                 break;
    //             case "A3":
    //                 tarifa = "A3";
    //                 break;
    //             case "B1":
    //                 tarifa = "B1";
    //                 break;
    //             case "B2":
    //                 tarifa = "B2";
    //                 break;
    //             case "C1":
    //                 tarifa = "C1";
    //                 break;
    //             case "C2":
    //                 tarifa = "C2";
    //                 break;
    //             case "D1":
    //                 tarifa = "D1";
    //                 break;
    //             case "D2":
    //                 tarifa = "D2";
    //                 break;
    //             case "M1":
    //                 tarifa = "M1";
    //                 break;
    //             case "M2":
    //                 tarifa = "M2";
    //                 break;
    //             case "99":
    //                 tarifa = "99";
    //                 break;
    //         }

    //         var consumoAnual = 0;

    //         consumos = $scope.consumosSips.filter(function(n) { return n.CodigoCUPS == obj.CodigoCUPS; });
    //         consumos = consumos.filter(function(n) { return n != undefined; });

    //         if (consumos.length >= 12) {
    //             for (var i = consumos.length - 1; i >= consumos.length - 12; i--) {
    //                 consumoAnual += consumos[i].ConsumoEnWhP1;
    //             }
    //         } else {
    //             for (var z = 0; z < consumos.length; z++) {
    //                 consumoAnual += consumos[z].ConsumoEnWhP1;
    //             }
    //         }

    //         myData.push({
    //             CodigoCUPS: obj.CodigoCUPS,
    //             CodigoEmpresaDistribuidora: obj.CodigoEmpresaDistribuidora,
    //             NombreEmpresaDistribuidora: obj.NombreEmpresaDistribuidora,
    //             CodigoPostalPS: obj.CodigoPostalPS,
    //             MunicipioPS: obj.MunicipioPS,
    //             CodigoProvinciaPS: obj.CodigoProvinciaPS,
    //             t: tarifa,
    //             p: presion,
    //             consumoAnual: consumoAnual,
    //             FechaAltaSuministro: obj.FechaAltaSuministro,
    //             CodigoTarifaATREnVigor: obj.CodigoTarifaATREnVigor,
    //             CodigoTensionV: obj.CodigoTensionV,
    //             PotenciaMaximaBIEW: obj.PotenciaMaximaBIEW,
    //             PotenciaMaximaAPMW: obj.PotenciaMaximaAPMW,
    //             CodigoClasificacionPS: obj.CodigoClasificacionPS,
    //             CodigoDisponibilidadICP: obj.CodigoDisponibilidadICP,
    //             TipoPerfilConsumo: obj.TipoPerfilConsumo,
    //             ValorDerechosExtensionW: obj.ValorDerechosExtensionW,
    //             ValorDerechosAccesoW: obj.ValorDerechosAccesoW,
    //             CodigoPropiedadEquipoMedida: obj.CodigoPropiedadEquipoMedida,
    //             CodigoPropiedadICP: obj.CodigoPropiedadICP,
    //             PotenciasContratadasEnWP1: obj.PotenciasContratadasEnWP1,
    //             PotenciasContratadasEnWP2: obj.PotenciasContratadasEnWP2,
    //             PotenciasContratadasEnWP3: obj.PotenciasContratadasEnWP3,
    //             PotenciasContratadasEnWP4: obj.PotenciasContratadasEnWP4,
    //             PotenciasContratadasEnWP5: obj.PotenciasContratadasEnWP5,
    //             PotenciasContratadasEnWP6: obj.PotenciasContratadasEnWP6,
    //             FechaUltimoMovimientoContrato: obj.FechaUltimoMovimientoContrato,
    //             FechaUltimoCambioComercializador: obj.FechaUltimoCambioComercializador,
    //             FechaLimiteDerechosReconocidos: obj.FechaLimiteDerechosReconocidos,
    //             FechaUltimaLectura: obj.FechaUltimaLectura,
    //             InformacionImpagos: obj.InformacionImpagos,
    //             ImporteDepositoGarantiaEuros: obj.ImporteDepositoGarantiaEuros,
    //             TipoIdTitular: obj.TipoIdTitular,
    //             EsViviendaHabitual: obj.EsViviendaHabitual,
    //             CodigoComercializadora: obj.CodigoComercializadora,
    //             CodigoTelegestion: obj.CodigoTelegestion,
    //             CodigoFasesEquipoMedida: obj.CodigoFasesEquipoMedida,
    //             CodigoAutoconsumo: obj.CodigoAutoconsumo,
    //             CodigoTipoContrato: obj.CodigoTipoContrato,
    //             CodigoPeriodicidadFacturacion: obj.CodigoPeriodicidadFacturacion,
    //             CodigoBIE: obj.CodigoBIE,
    //             FechaEmisionBIE: obj.FechaEmisionBIE,
    //             FechaCaducidadBIE: obj.FechaCaducidadBIE,
    //             CodigoAPM: obj.CodigoAPM,
    //             FechaEmisionAPM: obj.FechaEmisionAPM,
    //             FechaCaducidadAPM: obj.FechaCaducidadAPM,
    //             RelacionTransformacionIntensidad: obj.RelacionTransformacionIntensidad,
    //             Cnae: obj.Cnae,
    //             CodigoModoControlPotencia: obj.CodigoModoControlPotencia,
    //             PotenciaCGPW: obj.PotenciaCGPW,
    //             CodigoDHEquipoDeMedida: obj.CodigoDHEquipoDeMedida,
    //             CodigoAccesibilidadContador: obj.CodigoAccesibilidadContador,
    //             CodigoPSContratable: obj.CodigoPSContratable,
    //             MotivoEstadoNoContratable: obj.MotivoEstadoNoContratable,
    //             CodigoTensionMedida: obj.CodigoTensionMedida,
    //             CodigoClaseExpediente: obj.CodigoClaseExpediente,
    //             CodigoMotivoExpediente: obj.CodigoMotivoExpediente,
    //             CodigoTipoSuministro: obj.CodigoTipoSuministro,
    //             AplicacionBonoSocial: obj.AplicacionBonoSocial,
    //             DesProvinciaPS: obj.DesProvinciaPS,
    //             DesMunicipioPS: obj.DesMunicipioPS,
    //             TipoViaPS: obj.TipoViaPS,
    //             ViaPS: obj.ViaPS,
    //             NumFincaPS: obj.NumFincaPS,
    //             PortalPS: obj.PortalPS,
    //             EscaleraPS: obj.EscaleraPS,
    //             PisoPS: obj.PisoPS,
    //             PuertaPS: obj.PuertaPS,
    //             CodigoPresion: obj.CodigoPresion,
    //             CodigoPeajeEnVigor: obj.CodigoPeajeEnVigor,
    //             CaudalMaximoDiarioEnWh: obj.CaudalMaximoDiarioEnWh,
    //             CaudalHorarioEnWh: obj.CaudalHorarioEnWh,
    //             DerechoTUR: obj.DerechoTUR,
    //             FechaUltimaInspeccion: obj.FechaUltimaInspeccion,
    //             CodigoResultadoInspeccion: obj.CodigoResultadoInspeccion,
    //             CodigoContador: obj.CodigoContador,
    //             CalibreContador: obj.CalibreContador,
    //             TipoContador: obj.TipoContador,
    //             PropiedadEquipoMedida: obj.PropiedadEquipoMedida,
    //             CodigoTelemedida: obj.CodigoTelemedida
    //         });

    //     });

	// 	// return myData;
	// 	/**
	// 	 * Descargamos el excel limpio de arriba
	// 	 */

	// 	$("#dvjson")
    //     .excelexportjs({
    //       containerid: "dvjson",
    //       datatype: 'json',
    //       dataset: myData,
    //       columns: getColumns(myData)
    //     });
    // };

    // $scope.exportarDatosSIPSConsumos = function(selected) {

    //     var myData = [];
    //     var consumos = [];

    //     selected.forEach(function(obj) {
    //         var cups = obj.CodigoCUPS;
    //         var tarifa = "";
    //         var tension = "";

    //         // if (selected.length == 1) {
    //         //     $scope.TextoFicheroConsumos = cups + "_Consumos.xls";
    //         // }

    //         // if (selected.length > 1) {
    //         //     $scope.TextoFicheroConsumos = "DescargaMasiva_Consumos.xls";
    //         // }

    //         myData.push({
    //             'CUPS': obj.CodigoCUPS,
    //             'Fecha Lectura Inicial': "",
    //             'Fecha Lectura Final': "",
    //             'P1 Activa': "",
    //             'P2 Activa': "",
    //             'P3 Activa': "",
    //             'P4 Activa': "",
    //             'P5 Activa': "",
    //             'P6 Activa': "",
    //             'P1 Reactiva': "",
    //             'P2 Reactiva': "",
    //             'P3 Reactiva': "",
    //             'P4 Reactiva': "",
    //             'P5 Reactiva': "",
    //             'P6 Reactiva': "",
    //             'P1 Maximetro': "",
    //             'P2 Maximetro': "",
    //             'P3 Maximetro': "",
    //             'P4 Maximetro': "",
    //             'P5 Maximetro': "",
    //             'P6 Maximetro': ""
    //         });

    //         consumos = $scope.consumosSips.map(function(obj) {
    //             if (obj.CodigoCUPS == cups) {
    //                 if (obj.FechaInicio == null && obj.FechaFin == null) {} else {
    //                     return {
    //                         'CUPS' : "",
    //                         'Fecha Lectura Inicial' : obj.FechaInicio,
	// 						'Fecha Lectura Final' : obj.FechaFin,
	// 						// parseFloat(obj.PotenciasContratadasEnWP1).toFixed(2).replace('.',',')
    //                         'P1 Activa' : (obj.Activa1 == null) ? "0" : parseFloat(obj.Activa1).toFixed(3).replace('.',','),
    //                         'P2 Activa' : (obj.Activa2 == null) ? "0" : parseFloat(obj.Activa2).toFixed(3).replace('.',','),
    //                         'P3 Activa' : (obj.Activa3 == null) ? "0" : parseFloat(obj.Activa3).toFixed(3).replace('.',','),
    //                         'P4 Activa' : (obj.Activa4 == null) ? "0" : parseFloat(obj.Activa4).toFixed(3).replace('.',','),
    //                         'P5 Activa' : (obj.Activa5 == null) ? "0" : parseFloat(obj.Activa5).toFixed(3).replace('.',','),
    //                         'P6 Activa' : (obj.Activa6 == null) ? "0" : parseFloat(obj.Activa6).toFixed(3).replace('.',','),
    //                         'P1 Reactiva' : (obj.Reactiva1 == null) ? "0" : parseFloat(obj.Reactiva1).toFixed(3).replace('.',','),
    //                         'P2 Reactiva' : (obj.Reactiva2 == null) ? "0" : parseFloat(obj.Reactiva2).toFixed(3).replace('.',','),
    //                         'P3 Reactiva' : (obj.Reactiva3 == null) ? "0" : parseFloat(obj.Reactiva3).toFixed(3).replace('.',','),
    //                         'P4 Reactiva' : (obj.Reactiva4 == null) ? "0" : parseFloat(obj.Reactiva4).toFixed(3).replace('.',','),
    //                         'P5 Reactiva' : (obj.Reactiva5 == null) ? "0" : parseFloat(obj.Reactiva5).toFixed(3).replace('.',','),
    //                         'P6 Reactiva' : (obj.Reactiva6 == null) ? "0" : parseFloat(obj.Reactiva6).toFixed(3).replace('.',','),
    //                         'P1 Maximetro' : (obj.Potencia1 == null) ? "0" : parseFloat(obj.Potencia1).toFixed(3).replace('.',','),
    //                         'P2 Maximetro' : (obj.Potencia2 == null) ? "0" : parseFloat(obj.Potencia2).toFixed(3).replace('.',','),
    //                         'P3 Maximetro' : (obj.Potencia3 == null) ? "0" : parseFloat(obj.Potencia3).toFixed(3).replace('.',','),
    //                         'P4 Maximetro' : (obj.Potencia4 == null) ? "0" : parseFloat(obj.Potencia4).toFixed(3).replace('.',','),
    //                         'P5 Maximetro' : (obj.Potencia5 == null) ? "0" : parseFloat(obj.Potencia5).toFixed(3).replace('.',','),
    //                         'P6 Maximetro' : (obj.Potencia6 == null) ? "0" : parseFloat(obj.Potencia6).toFixed(3).replace('.',',')
    //                     };
    //                 }
    //             }
    //         });

    //         consumos = consumos.filter(function(n) { return n != undefined; });

    //         myData.push.apply(myData, consumos);
    //     });

	// 	// return myData;
	// 	/**
	// 	 * Descargamos el excel limpio de arriba
	// 	 */

	// 	$("#dvjson")
    //     .excelexportjs({
    //       containerid: "dvjson",
    //       datatype: 'json',
    //       dataset: myData,
    //       columns: getColumns(myData)
    //     });
    // };

    // $scope.exportarDatosSIPSGasConsumos = function(selected) {

    //     var myData = [];
    //     var consumos = [];

    //     selected.forEach(function(obj) {
    //         var cups = obj.CodigoCUPS;
    //         var tarifa = "";
    //         var tension = "";

    //         if (selected.length == 1) {
    //             $scope.TextoFicheroConsumos = cups + "_Consumos.xls";
    //         }

    //         if (selected.length > 1) {
    //             $scope.TextoFicheroConsumos = "DescargaMasiva_Consumos.xls";
    //         }


    //         myData.push({
    //             CUPS: obj.CodigoCUPS,
    //             FechaInicioMesConsumo: "",
    //             FechaFinMesConsumo: "",
    //             ConsumoEnWhP1: "",
    //             CaudalMaximoDiario: "",
    //             CaudalMedioEnWhDia: "",
    //             CaudalMinimoDiario: "",
    //             TarifaPeaje: "",
    //             p1reactiva: "",
    //             p2reactiva: "",
    //             p3reactiva: "",
    //             p4reactiva: "",
    //             p5reactiva: "",
    //             p6reactiva: "",
    //             p1maximetro: "",
    //             p2maximetro: "",
    //             p3maximetro: "",
    //             p4maximetro: "",
    //             p5maximetro: "",
    //             p6maximetro: ""
    //         });

    //         consumos = $scope.consumosSips.map(function(obj) {
    //             if (obj.CodigoCUPS == cups) {
    //                 if (obj.FechaInicioMesConsumo == null && obj.FechaFinMesConsumo == null) {} else {
    //                     // console.log(obj);
    //                     return {
    //                         CUPS: "",
    //                         FechaInicioMesConsumo: obj.FechaInicioMesConsumo,
    //                         FechaFinMesConsumo: obj.FechaFinMesConsumo,
    //                         ConsumoEnWhP1: (obj.ConsumoEnWhP1 == null ? "0" : obj.ConsumoEnWhP1),
    //                         CaudalMaximoDiario: (obj.CaudalMaximoDiario == null ? "0" : obj.CaudalMaximoDiario),
    //                         CaudalMedioEnWhDia: (obj.CaudalMedioEnWhDia == null ? "0" : obj.CaudalMedioEnWhDia),
    //                         CaudalMinimoDiario: (obj.CaudalMinimoDiario == null ? "0" : obj.CaudalMinimoDiario),
    //                         TarifaPeaje: obj.CodigoTarifaPeaje.toString().replace(/(\d)(?=(\d)+(?!\d))/g, "$1.")
    //                     };
    //                 }
    //             }
    //         });

    //         consumos = consumos.filter(function(n) { return n != undefined; });

    //         myData.push.apply(myData, consumos);
    //     });

	// 	// return myData;
	// 	/**
	// 	 * Descargamos el excel limpio de arriba
	// 	 */

	// 	$("#dvjson")
    //     .excelexportjs({
    //       containerid: "dvjson",
    //       datatype: 'json',
    //       dataset: myData,
    //       columns: getColumns(myData)
    //     });
    // };

    // $scope.exportarDatosSIPSBoton = function (data) {
    //     var myData = [];
    //     var tarifa = "";
    //     var tension = "";
    //     $scope.TextoFichero = data.CUPS + "_General.xls";

    //     switch (data.Tarifa) {
    //         case "001":
    //             tarifa = "2.0A";
    //             break;
    //         case "003":
    //             tarifa = "3.0A";
    //             break;
    //         case "004":
    //             tarifa = "2.0DHA";
    //             break;
    //         case "005":
    //             tarifa = "2.1A";
    //             break;
    //         case "006":
    //             tarifa = "2.1DHA";
    //             break;
    //         case "007":
    //             tarifa = "2.0DHS";
    //             break;
    //         case "008":
    //             tarifa = "2.1DHS";
    //             break;
    //         case "011":
    //             tarifa = "3.1A";
    //             break;
    //         case "012":
    //             tarifa = "6.1A";
    //             break;
    //         case "013":
    //             tarifa = "6.2";
    //             break;
    //         case "014":
    //             tarifa = "6.3";
    //             break;
    //         case "015":
    //             tarifa = "6.4";
    //             break;
    //         case "016":
    //             tarifa = "6.5";
    //             break;
    //         case "017":
    //             tarifa = "6.1B";
    //             break;
    //     }

    //     switch (data.CodigoTensionV) {
    //         case "01":
    //             tension = "1X220";
    //             break;
    //         case "02":
    //             tension = "1X230";
    //             break;
    //         case "03":
    //             tension = "3X380";
    //             break;
    //         case "04":
    //             tension = "3X380/220";
    //             break;
    //         case "05":
    //             tension = "3X400";
    //             break;
    //         case "06":
    //             tension = "3X400/230";
    //             break;
    //         case "07":
    //             tension = "1X127";
    //             break;
    //         case "08":
    //             tension = "1X133";
    //             break;
    //         case "09":
    //             tension = "2X220";
    //             break;
    //         case "10":
    //             tension = "2X230";
    //             break;
    //         case "11":
    //             tension = "3X220";
    //             break;
    //         case "12":
    //             tension = "3X220/127";
    //             break;
    //         case "13":
    //             tension = "3X230";
    //             break;
    //         case "14":
    //             tension = "3X230/133";
    //             break;
    //         case "15":
    //             tension = "5.000";
    //             break;
    //         case "16":
    //             tension = "6.000";
    //             break;
    //         case "17":
    //             tension = "7.200";
    //             break;
    //         case "18":
    //             tension = "8.000";
    //             break;
    //         case "19":
    //             tension = "10.000";
    //             break;
    //         case "20":
    //             tension = "11.000";
    //             break;
    //         case "21":
    //             tension = "12.000";
    //             break;
    //         case "22":
    //             tension = "13.000";
    //             break;
    //         case "23":
    //             tension = "13.200";
    //             break;
    //         case "24":
    //             tension = "15.000";
    //             break;
    //         case "25":
    //             tension = "16.500";
    //             break;
    //         case "26":
    //             tension = "17.000";
    //             break;
    //         case "27":
    //             tension = "20.000";
    //             break;
    //         case "28":
    //             tension = "22.000";
    //             break;
    //         case "29":
    //             tension = "25.000";
    //             break;
    //         case "30":
    //             tension = "26.500";
    //             break;
    //         case "31":
    //             tension = "30.000";
    //             break;
    //         case "32":
    //             tension = "36.000";
    //             break;
    //         case "33":
    //             tension = "44.000";
    //             break;
    //         case "34":
    //             tension = "45.000";
    //             break;
    //         case "35":
    //             tension = "66.000";
    //             break;
    //         case "36":
    //             tension = "110.000";
    //             break;
    //         case "37":
    //             tension = "132.000";
    //             break;
    //         case "38":
    //             tension = "220.000";
    //             break;
    //         case "39":
    //             tension = "400.000";
    //             break;
    //         case "40":
    //             tension = "1.200";
    //             break;
    //         case "41":
    //             tension = "2.000";
    //             break;
    //         case "42":
    //             tension = "5.500";
    //             break;
    //         case "43":
    //             tension = "55.000";
    //             break;
    //         case "44":
    //             tension = "130.000";
    //             break;
    //         case "45":
    //             tension = "100";
    //             break;
    //         case "46":
    //             tension = "150";
    //             break;
    //         case "47":
    //             tension = "3x100";
    //             break;
    //         case "48":
    //             tension = "3x150/260";
    //             break;
    //         case "49":
    //             tension = "3x260";
    //             break;
    //     }

    //     myData.push({
    //         CodigoCUPS: data.CUPS,
    //         DireccionSuministro: data.DireccionSuministro,
    //         Municipio: data.Municipio,
    //         Provincia: data.Provincia,
    //         CodigoPostal: data.CodigoPostal,
    //         Tarifa: tarifa,
    //         ConsumoAnual: data.ConsumoAnual,
    //         ConsumoAnualP1: data.ConsumoP1,
    //         ConsumoAnualP2: data.ConsumoP2,
    //         ConsumoAnualP3: data.ConsumoP3,
    //         ConsumoAnualP4: data.ConsumoP4,
    //         ConsumoAnualP5: data.ConsumoP5,
    //         ConsumoAnualP6: data.ConsumoP6,
    //         P1: data.PotenciasWP1,
    //         P2: data.PotenciasWP2,
    //         P3: data.PotenciasWP3,
    //         P4: data.PotenciasWP4,
    //         P5: data.PotenciasWP5,
    //         P6: data.PotenciasWP6,
    //         Distribuidora: data.Distribuidora,
    //         UltCambioComer: data.FechaUltimoCambioComercializador,
    //         UltCambioBIE: data.FechaEmisionBIE,
    //         Tension: tension,
    //         UltCambioCont: data.FechaUltimoMovimientoContrato

    //     });
    //     return myData;

	// }

	$scope.seleccionaTodos = function(){
		$scope.clientesSips.forEach(function (item) {
			$scope.selected.push(item);
		});
	}

	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}


    // FUNCION PARA GUARDAR LEAD DEL COMPARADOR
	$scope.guardarLead =  function(){

        if($scope.identificador && $scope.nombre && $scope.telefono && $scope.email){
            // console.log("#guardamos leads");
            // console.log($scope.cliente);
            // console.log($scope.consumoAnual);
            // console.log($scope.cliente);
            var InformacionAdicional = "";
            var temporal = "";

            InformacionAdicional = InformacionAdicional + " CNAE: ";
            temporal = ($scope.cliente.CNAE) ? $scope.cliente.CNAE : ""
            InformacionAdicional = InformacionAdicional + temporal;

            InformacionAdicional = InformacionAdicional + " Codigo Autoconsumo: ";
            temporal = ($scope.cliente.CodigoAutoconsumo) ? $scope.cliente.CodigoAutoconsumo : ""
            InformacionAdicional = InformacionAdicional + temporal;

            InformacionAdicional = InformacionAdicional + " Fecha Emision BIE: ";
            temporal = ($scope.cliente.FechaEmisionBIE) ? $scope.cliente.FechaEmisionBIE : ""
            InformacionAdicional = InformacionAdicional + temporal;

            InformacionAdicional = InformacionAdicional + " Fecha Emision APM: ";
            temporal = ($scope.cliente.FechaEmisionAPM) ? $scope.cliente.FechaEmisionAPM : ""
            InformacionAdicional = InformacionAdicional + temporal;

            InformacionAdicional = InformacionAdicional + " Fecha de alta suministro: ";
            temporal = ($scope.cliente.FechaAltaSuministro) ? $scope.cliente.FechaAltaSuministro : ""
            InformacionAdicional = InformacionAdicional + temporal;

            InformacionAdicional = InformacionAdicional + " Impagos: ";
            temporal = ($scope.cliente.InformacionImpagos) ? $scope.cliente.InformacionImpagos : ""
            InformacionAdicional = InformacionAdicional + temporal;

            InformacionAdicional = InformacionAdicional + " Consumo Anual: ";
            temporal = ($scope.consumoAnual) ? $scope.consumoAnual : "0"
            InformacionAdicional = InformacionAdicional + temporal;

            var fichero = document.getElementById("file-upload");
            var file = fichero.files[0];
            var ficheroData = new FormData();
            ficheroData.append("fichero", file);
            ficheroData.append("nombreTitular", $scope.nombre);

            var datosLead = {
                // "Nombre": $scope.datosTitular.NombreTitular,
                "Identificador": ($scope.identificador) ? $scope.identificador : '',
                "Nombre": ($scope.nombre) ? $scope.nombre : '',
                "Email": ($scope.email) ? $scope.email : '',
                "Telefono": ($scope.telefono) ? $scope.telefono : '',
                "Cups": ($scope.cliente!=undefined && $scope.cliente.CodigoCUPS) ? $scope.cliente.CodigoCUPS : '',
                "P1": ($scope.cliente!=undefined && $scope.cliente.PotenciasContratadasEnWP1) ? $scope.cliente.PotenciasContratadasEnWP1 : 0,
                "P2": ($scope.cliente!=undefined && $scope.cliente.PotenciasContratadasEnWP2) ? $scope.cliente.PotenciasContratadasEnWP2 : 0,
                "P3": ($scope.cliente!=undefined && $scope.cliente.PotenciasContratadasEnWP3) ? $scope.cliente.PotenciasContratadasEnWP3 : 0,
                "P4": ($scope.cliente!=undefined && $scope.cliente.PotenciasContratadasEnWP4) ? $scope.cliente.PotenciasContratadasEnWP4 : 0,
                "P5": ($scope.cliente!=undefined && $scope.cliente.PotenciasContratadasEnWP5) ? $scope.cliente.PotenciasContratadasEnWP5 : 0,
                "P6": ($scope.cliente!=undefined && $scope.cliente.PotenciasContratadasEnWP6) ? $scope.cliente.PotenciasContratadasEnWP6 : 0,
                "NombreTarifa": ($scope.cliente!=undefined && $scope.cliente.nombreTarifa) ? $scope.cliente.nombreTarifa : '',
                "TarifaAcceso": ($scope.cliente!=undefined && $scope.cliente.CodigoTarifaATREnVigor) ? $scope.cliente.CodigoTarifaATREnVigor : '',
                "codPostal": ($scope.cliente!=undefined && $scope.cliente.CodigoPostalPS) ? $scope.cliente.CodigoPostalPS : '',
                "Poblacion": ($scope.cliente!=undefined && $scope.cliente.DesMunicipioPS) ? $scope.cliente.DesMunicipioPS : '',
                "Provincia": ($scope.cliente!=undefined && $scope.cliente.DesProvinciaPS) ? $scope.cliente.DesProvinciaPS : '',
                "NombreVia": ($scope.datosTitular!=undefined && $scope.datosTitular.DireccionSuministro) ? $scope.datosTitular.DireccionSuministro : '',
                "BonoSocial": ($scope.cliente.AplicacionBonoSocial!=0) ? 1 : 0 ,
                "Asignado": (NombreAgente) ? NombreAgente : '',
                "InformacionAdicional": (InformacionAdicional) ? InformacionAdicional : '',
                "Entorno": 'g',
                // "InformacionAdicional": "CNAE: " + ($scope.cliente.CNAE) ? $scope.cliente.CNAE : "" + "Codigo Autoconsumo: " + ($scope.cliente.CodigoAutoconsumo) ? $scope.cliente.CodigoAutoconsumo : 0 + " Fecha Emision BIE: " + ($scope.cliente.FechaEmisionBIE) ? $scope.cliente.FechaEmisionBIE : "" + " Fecha Emision APM: " + ($scope.cliente.FechaEmisionAPM) ? $scope.cliente.FechaEmisionAPM : '' + " Fecha de alta suministro: " + ($scope.cliente.FechaAltaSuministro) ? $scope.cliente.FechaAltaSuministro : '' + " Impagos: " + ($scope.cliente.InformacionImpagos) ? $scope.cliente.InformacionImpagos : "Sin información" ,
            };

            // console.log(datosLead);

            $http({
                method: "POST",
                // url: "http://188.210.216.60/domestica/pa/lib/php/insertar_leads_comparador.php",
                url: "lib/php/insertar_leads_comparador.php",
                data: JSON.stringify(datosLead)
            }).then(function(response) {
                // console.log("response");
                // console.log(response);
                $scope.showAlert(response.data);

                // si es un numero guardamos el valor del id del lead
                // if(!isNaN(response)){
                    $scope.idlead = response.data;
                // }

                // $state.go("resumen_contratos", {}, { reload: true });
                // console.log($scope.idlead);
                ficheroData.append("idLead", $scope.idlead);

                // console.log("file");
                // console.log(file);
                if (file && $scope.AdjuntarFicheroComparador) {
                    $.ajax({
                        type: 'POST',
                        // url: 'http://188.210.216.60/domestica/pa/lib/php/subir_documentacion_lead.php',
                        url: 'lib/php/subir_documentacion_lead.php',
                        data: ficheroData,
                        processData: false,
                        contentType: false
                    }).done(function(data) {
                        //console.log(data);
                    });
                }

            });

        }else{ alert("Faltan campos por rellenar."); }
	}

    $scope.limpiarDatos = function(){

        $scope.margen = 0;

        //console.log("limpiarDatos " + $scope.margen);

        // ELEIA
        $scope.calculoTerminoPotencia = 0;
        $scope.calculoTerminoEnergia = 0;
        $scope.baseImpuestoElectrico = 0;
        $scope.baseConImpuestoElectrico = 0;
        $scope.baseImponibleNuestra = 0;
        $scope.baseImponibleConIVANuestra = 0;
        $scope.costeAlquilerPropio = 0;
        $scope.serviciosAdicionalesPropios = 0;
        $scope.totalNuestro = 0;

        // Su COMERCIALIZADORA
        $scope.calculoTerminoPotenciaSuyo = 0;
        $scope.calculoTerminoEnergiaSuyo = 0;
        $scope.baseImpuestoElectricoSuyo = 0;
        $scope.baseConImpuestoElectricoSuyo = 0;
        $scope.costeAlquilerSuyo = 0;
        $scope.serviciosAdicionalesSuyo = 0;
        $scope.baseImponibleSuya = 0;
        $scope.baseImponibleConIVASuya = 0;
        $scope.totalSuyo = 0;

    }

    // FUNCION PARA IMPRIMIR DE PANTALLA
    $scope.imprimirNormal = function(){

        // if(navigator.userAgent.toLowerCase().indexOf('chrome') > -1){   // Chrome Browser Detected?
            window.PPClose = false;                                     // Clear Close Flag
            window.onbeforeunload = function(){                         // Before Window Close Event
                if(window.PPClose === false){                           // Close not OK?
                    return 'Leaving this page will block the parent window!\nPlease select "Stay on this Page option" and use the\nCancel button instead to close the Print Preview Window.\n';
                }
            }
            window.print();                                             // Print preview
            window.PPClose = true;                                      // Set Close Flag to OK.
        // }

    }

    // FUNCION PARA IMPRIMIR EN PDF
	$scope.imprimirPdf =  function(){

        // var pdf = new jsPDF('p', 'pt', 'letter');
        // pdf.text('<h1>Hello world!</h1>', 10, 10);
        // pdf.text('<h2>Hello world!</h2>', 10, 50);
        // pdf.save('a4.pdf');

        source = document.getElementById("infoSuministro").innerHTML;
        source = source + document.getElementById("infoPrecios").innerHTML;

        var margins = {
            top : 10,
            bottom : 10,
            left : 10,
            width : 850
        };
        // all coords and widths are in jsPDF instance's declared units
        // 'inches' in this case
        pdf.fromHTML(source, // HTML string or DOM elem ref.
        margins.left, // x coord
        margins.top, { // y coord
            'width' : margins.width, // max width of content on PDF
        },

        function(dispose) {
            // dispose: object with X, Y of the last line add to the PDF
            //          this allow the insertion of new lines after html
            pdf.save('fileNameOfGeneretedPdf.pdf');
        }, margins);


	}

    $scope.obtenerNombreTarifa = function(text) {
        // console.log(text);
        // text = text.toUpperCase();
        var tarifa = "";
        if(pruebaBoeGas){
            var tarifaBoeGas = '';
            if (    text.charAt(0) == 'R' ||
                    text.charAt(0) == 'L'||
                    text.charAt(0) == 'S'){
                switch (text) {
// LUZ




            // GAS
            case "R1":
            tarifaBoeGas = 'RL. 1';
			break;
			case "R2":
			tarifaBoeGas = 'RL. 2';
			break;
			case "R3":
			tarifaBoeGas = 'RL. 3';
			break;
			case "R4":
			tarifaBoeGas = 'RL. 4';
			break;
			case "R5":
			tarifaBoeGas = 'RL. 5';
			break;
			case "R6":
				tarifaBoeGas = 'RL. 6';
			break;
			case "R7":
				tarifaBoeGas = 'RL. 7';
			break;
			case "R8":
				tarifaBoeGas = 'RL. 8';
			break;
			case "R9":
				tarifaBoeGas = 'RL. 9';
			break;
			case "L0":
			    tarifaBoeGas = 'RL. 10';
			break;
			case "L1":
				tarifaBoeGas = 'RL. 11';
			break;
			case "S1":
				tarifaBoeGas = 'RLPS. 1';
			break;
			case "S2":
				tarifaBoeGas = 'RLPS. 2';
			break;
			case "S3":
				tarifaBoeGas = 'RLPS. 3';
			break;
			case "S4":
				tarifaBoeGas = 'RLPS. 4';
			break;
			case "S5":
				tarifaBoeGas = 'RLPS. 5';
			break;
			case "S6":
			    tarifaBoeGas = 'RLPS. 6';
			break;
			case "S7":
				tarifaBoeGas = 'RLPS. 7';
			break;
			case "S8":
				tarifaBoeGas = 'RLPS. 8 ';
			break;
        }
		this.tarifa = tarifaBoeGas;
    } else {
        // console.log(resupuestaConsumos);
        // console.log(resupuestaPotencias);
        var fechaInicioGasBoe = new Date('2021-07-01T00:00:00').getTime();
        var fechaFinGasBoe = new Date('2020-06-01T00:00:00').getTime();
        var consumoTotalGasBoe = 0;
        var respuestaConsumos = $scope.consumosSips;
        console.log(respuestaConsumos);
        respuestaConsumos.forEach(function (item) {

            if (new Date('2020-06-01T00:00:00').getTime() <= new Date(item["FechaInicioMesConsumo"]).getTime() && new Date('2021-07-01T00:00:00').getTime() >= new Date(item["FechaFinMesConsumo"]).getTime()) {
                consumoTotalGasBoe += item['ConsumoEnWhP1'];
                if(fechaInicioGasBoe > new Date(item["FechaInicioMesConsumo"]).getTime()) fechaInicioGasBoe = new Date(item["FechaInicioMesConsumo"]).getTime();
                if(fechaFinGasBoe < new Date(item["FechaFinMesConsumo"]).getTime()) fechaFinGasBoe = new Date(item["FechaFinMesConsumo"]).getTime();
            }
        });
        var countDiasBoeGas = (fechaFinGasBoe - fechaInicioGasBoe) / (1000 * 60 * 60 * 24);
        consumoTotalGasBoe = (consumoTotalGasBoe / countDiasBoeGas) * 365;
        // console.log(countDiasBoeGas);
        // console.log(consumoTotalGasBoe);
        if(respuestaConsumos[0]['ConectadoPlantaSatelite'] == true || respuestaConsumos[0]['ConectadoPlantaSatelite'] == 1){
            if (consumoTotalGasBoe >= 500000001){
                tarifaBoeGas = 'RL. 11';
            } else if(consumoTotalGasBoe >= 150000001){
                tarifaBoeGas = 'RL. 10';
            } else if(consumoTotalGasBoe >= 50000001){
                tarifaBoeGas = 'RL. 9';
            } else if(consumoTotalGasBoe >= 15000001){
                tarifaBoeGas = 'RLPS. 8';
            } else if(consumoTotalGasBoe >= 5000001){
                tarifaBoeGas = 'RLPS. 7';
            } else if(consumoTotalGasBoe >= 1500001){
                tarifaBoeGas = 'RLPS. 6';
            } else if(consumoTotalGasBoe >= 300001){
                tarifaBoeGas = 'RLPS. 5';
            } else if(consumoTotalGasBoe >= 50001){
                tarifaBoeGas = 'RLPS. 4';
            } else if(consumoTotalGasBoe >= 15001){
                tarifaBoeGas = 'RLPS. 3';
            } else if(consumoTotalGasBoe >= 5001){
                tarifaBoeGas = 'RLPS. 2';
            } else{
                tarifaBoeGas = 'RLPS. 1';
            }
        } else {
            if(consumoTotalGasBoe >= 500000001){
                tarifaBoeGas = 'RL. 11';
            } else if(consumoTotalGasBoe >= 150000001){
                tarifaBoeGas = 'RL. 10';
            } else if(consumoTotalGasBoe >= 50000001){
                tarifaBoeGas = 'RL. 9';
            } else if(consumoTotalGasBoe >= 15000001){
                tarifaBoeGas = 'RL. 8';
            } else if(consumoTotalGasBoe >= 5000001){
                tarifaBoeGas = 'RL. 7';
            } else if(consumoTotalGasBoe >= 1500001){
                tarifaBoeGas = 'RL. 6';
            } else if(consumoTotalGasBoe >= 300001){
                tarifaBoeGas = 'RL. 5';
            } else if(consumoTotalGasBoe >= 50001){
                tarifaBoeGas = 'RL. 4';
            } else if(consumoTotalGasBoe >= 15001){
                tarifaBoeGas = 'RL. 3';
            } else if(consumoTotalGasBoe >= 5001){
                tarifaBoeGas = 'RL. 2';
            } else{
                tarifaBoeGas = 'RL. 1';
            }
        }
        this.tarifa = tarifaBoeGas;
    }
}else{
    switch (text) {
        case "11":
            $scope.Tarifa = "1.1";
            break;
        case "12":
            $scope.Tarifa = "1.2";
            break;
        case "13":
            $scope.Tarifa = "1.3";
            break;
        case "21":
            $scope.Tarifa = "2.1";
            break;
        case "22":
            $scope.Tarifa = "2.2";
            break;
        case "23":
            $scope.Tarifa = "2.3";
            break;
        case "24":
            $scope.Tarifa = "2.4";
            break;
        case "25":
            $scope.Tarifa = "2.5";
            break;
        case "26":
            $scope.Tarifa = "2.6";
            break;
        case "31":
            $scope.Tarifa = "3.1";
            break;
        case "32":
            $scope.Tarifa = "3.2";
            break;
        case "33":
            $scope.Tarifa = "3.3";
            break;
        case "34":
            $scope.Tarifa = "3.4";
            break;

    }
    this.tarifa =  $scope.Tarifa;
}


        return this.tarifa;
    };

    $scope.showAlert = function(value) {

        var htmlContent = "";

        // console.log(value.data);

        // Si  es un valor numero valido
        if(value){
            htmlContent = '<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Lead insertado correctamente.</p>';
        // si trae string error
        }else{
            htmlContent = '<i class="fa fa-times-circle" aria-hidden="true"></i> <p class="centrado">El Lead no ha podido ser insertado.</p>';
        }


        $mdDialog.show(
            $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('')
            .htmlContent(htmlContent)
            .ariaLabel('Lead')
            .ok('Aceptar')
            // .targetEvent(ev)
        );

    };

    /* Codigo Fichero */
    $scope.fileName = function(ele) {
        var files = ele.files;
        var l = files.length;
        var namesArr = [];


        for (var i = 0; i < l; i++) {
            namesArr.push(files[i].name);
            var texto = document.getElementById("textoDocumentacionLead");
            texto.setAttribute("value", files[0].name);
        }

    };

    $scope.limpiar = function() {
        document.getElementById('textoDocumentacionLead').setAttribute('value', '');
    };


}]);