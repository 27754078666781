app.service('serviciosApi', ['$http', function($http) {

    var codigoSituacion = 2;


	var fecha = new Date();
	var fechaVencimiento = new Date();
	fechaVencimiento.setMonth(fecha.getMonth() + 3);

	var haceDosSemanas = new Date();
	haceDosSemanas.setDate(fecha.getDate() - 14);

    var AnioActual = fecha.getFullYear();
    var MesActual = fecha.getMonth() + 1;

    /*var MesVencimiento = fecha.getMonth() + 3;
    if (fMesVencimiento == 11 || MesVencimiento == 12) {
        AnioActual = AnioActual + 1;
    }*/
    var DiaActual = fecha.getDate();

    this.getTotalResumen = function() {
        return $http({
            method: "GET",
            url: urlApi + "Agente/ResumenTotal/Empresa/" + localStorage.getItem('idEmpresa') + "/Usuario/" + localStorage.getItem('idUsuario') + "/Agente/" + localStorage.getItem('idAgente'),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getUltimosContratos = function() {
        return $http({
            method: "GET",
            url: urlApi + "Agente/ResumenContratosAlta/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"FechaDesde\":'" + formatFecha(haceDosSemanas) + "'}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });


    };

    this.getResumenComisionistas = function() {

        return $http({
            method: "GET",
            url: urlApi + "Agente/ResumenUltimasComisiones/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"FechaDesde\":'" + formatFecha(haceDosSemanas) + "'}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });

	};

	this.getListadoRenovaciones = function() {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/GetRenovacionesContratos",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"IdAgente": localStorage.getItem('idAgente'),
			}),
		});
	};
	this.PostRenovacionContratoIndividualProducto = function(renovacionIndividual) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/PostRenovacionProducto",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"Entorno": renovacionIndividual['Entorno'],
				"IdContrato": renovacionIndividual['IdContrato'],
				"CodigoContrato": renovacionIndividual['CodigoContrato'],
				"TextoTarifaGrupo": renovacionIndividual['TextoTarifaGrupo'],
				"IdTarifaGrupo": renovacionIndividual['IdTarifaGrupo'],
				"IdTarifa": renovacionIndividual['IdTarifa'],
				"IdPerfilFacturacion": renovacionIndividual['IdPerfilFacturacion'],
				"Margen": renovacionIndividual['Margen'],
				"FechaVtoContrato": renovacionIndividual['FechaVtoContrato'],
				"FechaActivacion": renovacionIndividual['FechaActivacion'],
				"MesesRenovacion": renovacionIndividual['MesesRenovacion'],
				"P1": renovacionIndividual['P1']? renovacionIndividual['P1'] : 0,
				"P2": renovacionIndividual['P2']? renovacionIndividual['P2'] : 0,
				"P3": renovacionIndividual['P3']? renovacionIndividual['P3'] : 0,
				"P4": renovacionIndividual['P4']? renovacionIndividual['P4'] : 0,
				"P5": renovacionIndividual['P5']? renovacionIndividual['P5'] : 0,
				"P6": renovacionIndividual['P6']? renovacionIndividual['P6'] : 0,
			}),
		});
	};
	this.PostRenovacionContratoIndividual = function(renovacionIndividual) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/PostRenovacionContratoIndividual",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"Entorno": renovacionIndividual['Entorno'],
				"IdContrato": renovacionIndividual['IdContrato'],
				"CodigoContrato": renovacionIndividual['CodigoContrato'],
				"TextoTarifaGrupo": renovacionIndividual['TextoTarifaGrupo'],
				"IdTarifaGrupo": renovacionIndividual['IdTarifaGrupo'],
				"IdTarifa": renovacionIndividual['IdTarifa'],
				"IdPerfilFacturacion": renovacionIndividual['IdPerfilFacturacion'],
				"Margen": renovacionIndividual['Margen'],
				"FechaVtoContrato": renovacionIndividual['FechaVtoContrato'],
				"FechaActivacion": renovacionIndividual['FechaActivacion'],
				"AccionRenovacion": renovacionIndividual['AccionRenovacion'],
				"Email": renovacionIndividual['Email'],
				"MesesRenovacion": renovacionIndividual['MesesRenovacion'],
				"P1": renovacionIndividual['P1']? renovacionIndividual['P1'] : 0,
				"P2": renovacionIndividual['P2']? renovacionIndividual['P2'] : 0,
				"P3": renovacionIndividual['P3']? renovacionIndividual['P3'] : 0,
				"P4": renovacionIndividual['P4']? renovacionIndividual['P4'] : 0,
				"P5": renovacionIndividual['P5']? renovacionIndividual['P5'] : 0,
				"P6": renovacionIndividual['P6']? renovacionIndividual['P6'] : 0,
			}),
		});
	};

	this.PostRenovacionesMultiples = function(idContrato) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/PostRenovacionesMultiples",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"ListadoIdsContratos": idContrato,
				"IdEmpresa": localStorage.getItem('idEmpresa'),
			}),
		});
    };

    this.getNotificaciones = function() {

        return $http({
            method: "GET",
            url: urlApi + "Avisos/Avisos/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"FechaDesde\":'" + formatFecha(haceDosSemanas) + "'}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getProximasTareas = function() {

        return $http({
            method: "GET",
            url: urlApi + "AgendaTarea/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"FechaDesde\":'" + formatFecha(haceDosSemanas) + "'}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
	};

	this.getRankingAgentes = function() {
		return $http({
			method: "POST",
			url: urlApi + "Agente/GetRanking",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"IdUsuario": localStorage.getItem('idUsuario'),
				"IdAgente": localStorage.getItem('idAgente'),
				"CodigoTipoAgente": atob(localStorage.getItem('tipoAgente'))
			}),
		});
	};

    this.getListadoFacturas = function() {

        return $http({
            method: "GET",
            url: urlApi + "Contrato/GetDTOAllContratos/?Contratacion_FinderFiltersContratacionAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + "}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getFacturaPDF = function(documento) {
        $http({
            method: "GET",
            url: urlApi + "FacturaVentaCabecera/FacturaPreviewPDF/Empresa/" + localStorage.getItem('idEmpresa') + "/Usuario/" + localStorage.getItem('idUsuario') + "/Documento/" + documento,
            responseType: "arraybuffer",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;

            var file = new Blob([respuesta], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    };

    this.getListadoContratos = function(FiltrarListadoContratoPorIdMarca) {
		if(localStorage.getItem('idMarca') != '' && FiltrarListadoContratoPorIdMarca == true) {
			return $http({
				method: "GET",
				url: urlApi + "Contrato/GetDTOAllContratos/?Contratacion_FinderFiltersContratacionAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"IdMarca\":" + localStorage.getItem('IdMarca') + "}",
				headers: {
					Authorization: atob(localStorage.getItem('TokenValidador'))
				}
			});

		} else {
			return $http({
				method: "GET",
				url: urlApi + "Contrato/GetDTOAllContratos/?Contratacion_FinderFiltersContratacionAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + "}",
				headers: {
					Authorization: atob(localStorage.getItem('TokenValidador'))
				}
			});
		}
	};

	this.postUpdateSubestado = function(IdContrato) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/UpdateSubestado",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdContrato": IdContrato,
			}),
		});
	};
	this.getListadoRenovaciones = function() {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/GetRenovacionesContratos",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"IdAgente": localStorage.getItem('idAgente'),
			}),
		});
	};
	this.PostRenovacionContratoIndividualProducto = function(renovacionIndividual) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/PostRenovacionProducto",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"Entorno": renovacionIndividual['Entorno'],
				"IdContrato": renovacionIndividual['IdContrato'],
				"CodigoContrato": renovacionIndividual['CodigoContrato'],
				"TextoTarifaGrupo": renovacionIndividual['TextoTarifaGrupo'],
				"IdTarifaGrupo": renovacionIndividual['IdTarifaGrupo'],
				"IdTarifa": renovacionIndividual['IdTarifa'],
				"IdPerfilFacturacion": renovacionIndividual['IdPerfilFacturacion'],
				"Margen": renovacionIndividual['Margen'],
				"FechaVtoContrato": renovacionIndividual['FechaVtoContrato'],
				"FechaActivacion": renovacionIndividual['FechaActivacion'],
				"MesesRenovacion": renovacionIndividual['MesesRenovacion'],
				"P1": renovacionIndividual['P1']? renovacionIndividual['P1'] : 0,
				"P2": renovacionIndividual['P2']? renovacionIndividual['P2'] : 0,
				"P3": renovacionIndividual['P3']? renovacionIndividual['P3'] : 0,
				"P4": renovacionIndividual['P4']? renovacionIndividual['P4'] : 0,
				"P5": renovacionIndividual['P5']? renovacionIndividual['P5'] : 0,
				"P6": renovacionIndividual['P6']? renovacionIndividual['P6'] : 0,
			}),
		});
	};
	this.PostRenovacionContratoIndividual = function(renovacionIndividual) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/PostRenovacionContratoIndividual",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"IdEmpresa": localStorage.getItem('idEmpresa'),
				"Entorno": renovacionIndividual['Entorno'],
				"IdContrato": renovacionIndividual['IdContrato'],
				"CodigoContrato": renovacionIndividual['CodigoContrato'],
				"TextoTarifaGrupo": renovacionIndividual['TextoTarifaGrupo'],
				"IdTarifaGrupo": renovacionIndividual['IdTarifaGrupo'],
				"IdTarifa": renovacionIndividual['IdTarifa'],
				"IdPerfilFacturacion": renovacionIndividual['IdPerfilFacturacion'],
				"Margen": renovacionIndividual['Margen'],
				"FechaVtoContrato": renovacionIndividual['FechaVtoContrato'],
				"FechaActivacion": renovacionIndividual['FechaActivacion'],
				"AccionRenovacion": renovacionIndividual['AccionRenovacion'],
				"Email": renovacionIndividual['Email'],
				"MesesRenovacion": renovacionIndividual['MesesRenovacion'],
				"P1": renovacionIndividual['P1']? renovacionIndividual['P1'] : 0,
				"P2": renovacionIndividual['P2']? renovacionIndividual['P2'] : 0,
				"P3": renovacionIndividual['P3']? renovacionIndividual['P3'] : 0,
				"P4": renovacionIndividual['P4']? renovacionIndividual['P4'] : 0,
				"P5": renovacionIndividual['P5']? renovacionIndividual['P5'] : 0,
				"P6": renovacionIndividual['P6']? renovacionIndividual['P6'] : 0,
			}),
		});
	};

	this.PostRenovacionesMultiples = function(idContrato) {
		return $http({
			method: "POST",
			url: urlApi + "RenovacionContrato/PostRenovacionesMultiples",
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) },
			data: JSON.stringify({
				"ListadoIdsContratos": idContrato,
				"IdEmpresa": localStorage.getItem('idEmpresa'),
			}),
		});
    };

    this.getContratosPorMes = function() {
        return $http({
            method: "GET",
            url: urlApi + "Contrato/ContratoMensualPorAgente/Empresa/" + localStorage.getItem('idEmpresa') + "/Usuario/" + localStorage.getItem('idUsuario') + "/Agente/" + localStorage.getItem('idAgente') + "/Anio/" + AnioActual,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getContratosRenovacion = function() {
        return $http({
            method: "GET",
            url: urlApi + "RenovacionContrato/ContratosProximosRenovacion/?Contratacion_FinderFilterRenovacionContratoAPIDTO={\"IdEmpresa\": " + localStorage.getItem('idEmpresa') + ", \"IdUsuario\": " + localStorage.getItem('idUsuario') + ", \"IdAgente\": " + localStorage.getItem('idAgente') + ", \"DesdeFechaVTO\": \"" + formatFecha(fecha) + "\", \"HastaFechaVTO\": \"" + formatFecha(fechaVencimiento) + "\", \"PaginationCount\": 200, \"PaginationSkip\": 0}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getListadoClientes = function() {
        return $http({
            method: "GET",
            url: urlApi + "Cliente/GetDTOAllClientes/?Contratacion_FinderFiltersContratacionAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + "}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getListadoComisiones = function() {
        return $http({
            method: "GET",
            url: urlApi + "AgenteComision/Find/?TablasMaestras_FinderFiltersAgenteComisionAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"CodigoSituacion\":" + codigoSituacion + "}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getListadoComerciales = function() {
        return $http({
            method: "GET",
            url: urlApi + "Agente/ListadoAgentes/" + localStorage.getItem('idAgente') + "/Empresa/" + localStorage.getItem('idEmpresa') + "/Usuario/" + localStorage.getItem('idUsuario'),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getListadoIncidencias = function() {
        return $http({
            method: "GET",
            url: urlApi + "CallCenter/FindCallCenter/?Contratacion_FinderFilterCallCenterAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + "}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });

    };


    this.getListadoComunicados = function() {
        return $http({
            method: "GET",
            url: urlApi + "ComunicadoCanal/GetComunicadosAll/Empresa/" + localStorage.getItem('idEmpresa') + "/Agente/" + localStorage.getItem('idAgente'),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getListadoComunicadosAllActivosEmpresa = function() {
        return $http({
            method: "GET",
            url: urlApi + "ComunicadoCanal/GetComunicadosAllActivosEmpresaUsuario/Empresa/" + localStorage.getItem('idEmpresa') + "/Usuario/" + localStorage.getItem('idUsuario') + "/Agente/" + localStorage.getItem('idAgente'),
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    this.getAgenda = function() {
        return $http({
            method: "GET",
            url: urlApi + "AgendaTarea/AgendaTarea/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={\"IdEmpresa\":" + localStorage.getItem('idEmpresa') + ",\"IdUsuario\":" + localStorage.getItem('idUsuario') + ",\"IdAgente\":" + localStorage.getItem('idAgente') + ",\"FechaDesde\":'" + formatFecha(haceDosSemanas) + "'}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        });
    };

    // if (localStorage.getItem('idEmpresaGas') == 'true') {
    //     urlApiSips = urlApiSipsGas;
    // }


	//SIPS PARA PRUEBAS NUEVAS FACTURAS BOE
	//NO SUBIR A PRODUCIÖN BAJO NINGÜN CONCEPTO

    this.getSips = function(Cups, NombreEmpresaDistribuidora, MunicipioPS, CodigoProvinciaPS, CodigoPostalPS, CodigoTarifa, ListCUPS) {
		// return $http.get(urlApiSips + "/?_SipsFinderFiltersDTO={\"CodigoCUPS\":\"" + Cups + "\",\"NombreEmpresaDistribuidora\":\"" + NombreEmpresaDistribuidora + "\",\"MunicipioPS\":\"" + MunicipioPS + "\",\"CodigoProvinciaPS\":\"" + CodigoProvinciaPS + "\",\"CodigoPostalPS\":\"" + CodigoPostalPS + "\",\"CodigoTarifaATREnVigor\":\"" + CodigoTarifa + "\",\"ListCUPS\":" + ListCUPS + ",\"LoadAllDatosCliente\":true,\"LoadConsumos\":true,\"IsExist\":" + informacionSipsCliente + "}");

        /*var urlApiSipsAux = (localStorage.getItem('idEmpresaGas') == 'true') ? urlApiSipsGas : urlApiSips;

        return $http({
			method: "GET",
			url: urlApiSipsAux + '/' + Cups
			// data: JSON.stringify({
			// 	"CodigoCUPS": Cups,
			// 	"NombreEmpresaDistribuidora": NombreEmpresaDistribuidora,
			// 	"MunicipioPS": MunicipioPS,
			// 	"CodigoProvinciaPS": CodigoProvinciaPS,
			// 	"CodigoPostalPS": CodigoPostalPS,
			// 	"CodigoTarifaATREnVigor": CodigoTarifa,
			// 	"ListCUPS": ListCUPS,
			// 	"LoadAllDatosCliente": true,
			// 	"LoadConsumos": true,
			// 	"IsExist": informacionSipsCliente
			// })
		});*/

		var urlApiSipsAux = (localStorage.getItem('idEmpresaGas') == 'true') ? urlApiSipsGas : urlApiSips;

        return $http({
			method: "POST",
			url: urlApiSipsAux + "/GetClientesPost",
			data: JSON.stringify({
				"CodigoCUPS": Cups,
				"NombreEmpresaDistribuidora": NombreEmpresaDistribuidora,
				"MunicipioPS": MunicipioPS,
				"CodigoProvinciaPS": CodigoProvinciaPS,
				"CodigoPostalPS": CodigoPostalPS,
				"CodigoTarifaATREnVigor": CodigoTarifa,
				"ListCUPS": ListCUPS,
				"LoadAllDatosCliente": true,
				"LoadConsumos": true,
				"IsExist": informacionSipsCliente
			})
		});
    };
	this.getSipsByEntorno = function(Cups, NombreEmpresaDistribuidora, MunicipioPS, CodigoProvinciaPS, CodigoPostalPS, CodigoTarifa, ListCUPS, Entorno) {
		// return $http.get(urlApiSips + "/?_SipsFinderFiltersDTO={\"CodigoCUPS\":\"" + Cups + "\",\"NombreEmpresaDistribuidora\":\"" + NombreEmpresaDistribuidora + "\",\"MunicipioPS\":\"" + MunicipioPS + "\",\"CodigoProvinciaPS\":\"" + CodigoProvinciaPS + "\",\"CodigoPostalPS\":\"" + CodigoPostalPS + "\",\"CodigoTarifaATREnVigor\":\"" + CodigoTarifa + "\",\"ListCUPS\":" + ListCUPS + ",\"LoadAllDatosCliente\":true,\"LoadConsumos\":true,\"IsExist\":" + informacionSipsCliente + "}");

        var urlApiSipsAux = (Entorno == 'Gas') ? urlApiSipsGas : urlApiSips;

        return $http({
			method: "POST",
			url: urlApiSipsAux + "/GetClientesPost",
			data: JSON.stringify({
				"CodigoCUPS": Cups,
				"NombreEmpresaDistribuidora": NombreEmpresaDistribuidora,
				"MunicipioPS": MunicipioPS,
				"CodigoProvinciaPS": CodigoProvinciaPS,
				"CodigoPostalPS": CodigoPostalPS,
				"CodigoTarifaATREnVigor": CodigoTarifa,
				"ListCUPS": ListCUPS,
				"LoadAllDatosCliente": true,
				"LoadConsumos": true,
				"IsExist": informacionSipsCliente
			})
		});
    };

	
	//SIPS PARA PRUEBAS NUEVAS FACTURAS BOE
	//NO SUBIR A PRODUCIÖN BAJO NINGÜN CONCEPTO

    this.getSipsTitular = function(NombreTitular, CiudadTitular, ProvinciaTitular, DireccionTitular, NumeroTitular,CodigoPostalTitular) {
		// return $http.get(urlApiSips + "/?_SipsFinderFiltersDTO={\"CodigoCUPS\":\"" + Cups + "\",\"NombreEmpresaDistribuidora\":\"" + NombreEmpresaDistribuidora + "\",\"MunicipioPS\":\"" + MunicipioPS + "\",\"CodigoProvinciaPS\":\"" + CodigoProvinciaPS + "\",\"CodigoPostalPS\":\"" + CodigoPostalPS + "\",\"CodigoTarifaATREnVigor\":\"" + CodigoTarifa + "\",\"ListCUPS\":" + ListCUPS + ",\"LoadAllDatosCliente\":true,\"LoadConsumos\":true,\"IsExist\":" + informacionSipsCliente + "}");

        var urlApiSipsAux = (localStorage.getItem('idEmpresaGas') == 'true') ? urlApiSipsGas : urlApiSips;
		return $http({
			method: "GET",
			url: urlApiSipsAux + '/' + Cups,
			// data: JSON.stringify({
			// 	"CodigoCUPS": Cups,
			// 	"NombreEmpresaDistribuidora": NombreEmpresaDistribuidora,
			// 	"MunicipioPS": MunicipioPS,
			// 	"CodigoProvinciaPS": CodigoProvinciaPS,
			// 	"CodigoPostalPS": CodigoPostalPS,
			// 	"CodigoTarifaATREnVigor": CodigoTarifa,
			// 	"ListCUPS": ListCUPS,
			// 	"LoadAllDatosCliente": true,
			// 	"LoadConsumos": true,
			// 	"IsExist": informacionSipsCliente
			// })
		});

        // return $http({
		// 	method: "POST",
		// 	url: urlApiSipsAux + "/GetClienteByFilterPost",
		// 	data: JSON.stringify({
        //         "BusquedaSoloNombre":false,
        //         "Nombre": (NombreTitular) ? NombreTitular : '',
        //         "CiudadTitular": (CiudadTitular) ? CiudadTitular : '',
        //         "ProvinciaTitular": (ProvinciaTitular) ? ProvinciaTitular : '',
        //         "Calle": (DireccionTitular) ? DireccionTitular : '',
        //         "Numero": (NumeroTitular) ? NumeroTitular : '',
        //         "Aclarador": '',
        //         "CP": CodigoPostalTitular,

		// 	})
		// });
    };

    this.getCodigoPostal = function(CodigoPostal) {
		// return $http.get(urlApiSips + "/?_SipsFinderFiltersDTO={\"CodigoCUPS\":\"" + Cups + "\",\"NombreEmpresaDistribuidora\":\"" + NombreEmpresaDistribuidora + "\",\"MunicipioPS\":\"" + MunicipioPS + "\",\"CodigoProvinciaPS\":\"" + CodigoProvinciaPS + "\",\"CodigoPostalPS\":\"" + CodigoPostalPS + "\",\"CodigoTarifaATREnVigor\":\"" + CodigoTarifa + "\",\"ListCUPS\":" + ListCUPS + ",\"LoadAllDatosCliente\":true,\"LoadConsumos\":true,\"IsExist\":" + informacionSipsCliente + "}");
		return $http({
			method: "POST",
			// url: "https://www.sigeenergia.com:8182/api/v1/Ciudad/GetDTOALLCiudades/CodigoPostal/" + CodigoPostal, // TODO: CAMBIAR URL
			// url: "https://apicallejero.sigeenergia.com/api/v1/Ciudad/GetDTOALLCiudades/CodigoPostal/" + CodigoPostal, // TODO: CAMBIAR URL
			url: "https://apicallejerocp.sigeenergia.com/api/v1/Ciudad/GetDTOALLCiudades/CodigoPostal/" + CodigoPostal, // TODO: CAMBIAR URL
            data: JSON.stringify({})
		});
    };

    // TODO: CAMBIAR ENDPOINT
    this.getCalleParticionada = function(cups) {

        return $http({
			method: "POST",
            url: urlApi + "CUPS/DireccionCUPS", // TODO: CAMBIAR URL
            data: JSON.stringify({
				"CodigoCups": cups
			})
		});
    };

    // TODO: CAMBIAR ENDPOINT
    // this.getCalleParticionada = function(cups) {

    //     return $http({
	// 		method: "POST",
	// 		url: "http://192.168.2.33:9000/api/CUPS/CallesCPFiltro",
    //         data: JSON.stringify({
	// 			"CUPS": cups
	// 		})
	// 	});
    // };


	this.getTiposImpresion = function() {
		var imp = [
			{ "Valor": "P", "Texto": "Papel & email" },
        	{ "Valor": "E", "Texto": "Email" },
        	{ "Valor": "W", "Texto": "Web" },
        	{ "Valor": "Q", "Texto": "Papel" },
            { "Valor": "R", "Texto": "Recibo Bancario" }
    	];
		return imp;
	}

	this.getIdiomasContrato = function() {
		var idc = IdiomasContratos = [
			{ "Valor": "1", "Texto": "Castellano" },
			{ "Valor": "2", "Texto": "Asturiano" },
			{ "Valor": "3", "Texto": "Euskera" },
			{ "Valor": "4", "Texto": "Catalán" },
			{ "Valor": "5", "Texto": "Gallego" },
			{ "Valor": "6", "Texto": "Inglés" },
			{ "Valor": "7", "Texto": "Danés" },
			{ "Valor": "8", "Texto": "Alemán" },
			{ "Valor": "9", "Texto": "Noruego" },
			{ "Valor": "10", "Texto": "Sueco" },
			{ "Valor": "11", "Texto": "Francés" },
			{ "Valor": "12", "Texto": "Portugués" }
		];
		return idc;
	}

	function formatFecha(fecha) {
		return fecha.toJSON().slice(0, 10);
	}

}]);