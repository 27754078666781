app.filter('cambiarGuionBajo',function() {
	return function (input) {
		if (input) {
			//Quito los barra baja
			var i = 0, strLength = input.length;
			for (i; i < strLength; i++) {
				input = input.replace("_", " ");
			}
			//Elimino la extension de PDF
			return input.replace(".pdf", "");
		}

	};
});