app.controller("SipsCtrl", ['$scope', '$http', '$timeout', 'cfpLoadingBar', 'serviciosApi', 'jwtHelper', '$mdDialog', '$rootScope', '$location', 'servicioListadoDistribuidoras', 'servicioListadoTarifas', function($scope, $http, $timeout, cfpLoadingBar, serviciosApi, jwtHelper, $mdDialog, $rootScope, $location, servicioListadoDistribuidoras, servicioListadoTarifas) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdEmpresa = localStorage.getItem('idEmpresa');
    var Logueado = localStorage.getItem('logueado');
    var VerEnergia;
    var VerGas;

    // $scope.Cups = "ES0227070006232977YG"; //GAS
    $scope.selected = [];
    $scope.selectedTitulares = [];
    $scope.OcultarBotonesSips = OcultarBotonesSips;

    $scope.NombreSipsTitular = "";
    $scope.CiudadTitular = "";

    $scope.tablaConsumos = [];
    $scope.busquedaConsumos = [];
    $scope.consumosAnuales = [];

    $scope.mostrarGrafico = false;

    $scope.consumoAnual = 0;

    $scope.myTabIndex = 0;

    $scope.loading = false;
    $scope.cpValidat = false;

    $scope.informacionSipsCliente = informacionSipsCliente;
    $scope.TextoDescargarSips = TextoDescargarSips;

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') == 'true') {
        $scope.VerEnergia = true;
        $scope.VerGas = false;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') == 'true') {
        $scope.VerGas = true;
        $scope.VerEnergia = false;
    }

    $scope.distribuidoras = servicioListadoDistribuidoras.getListadoDistribuidoras();

    $scope.obtenerDistribuidoras = function(text) {
        text = text.toUpperCase();
        var ret = $scope.distribuidoras.filter(function(d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

    $scope.tarifas = servicioListadoTarifas.getListadoTarifas();

    $scope.obtenerTarifas = function(text) {
        text = text.toUpperCase();
        var ret = $scope.tarifas.filter(function(d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

	$scope.MostrarSipsBuscadorCups = MostrarSipsBuscadorCups;
	$scope.MostrarSipsBuscadorDistribuidora = MostrarSipsBuscadorDistribuidora;
	$scope.MostrarSipsBuscadorCodigoPostal = MostrarSipsBuscadorCodigoPostal;
	$scope.MostrarSipsBuscadorDni = MostrarSipsBuscadorDni;
	$scope.MostrarSipsBuscadorDireccion = MostrarSipsBuscadorDireccion;
	$scope.MostrarSipsBuscadorTitular = MostrarSipsBuscadorTitular;
	$scope.MostrarSipsBuscadorTelefono = MostrarSipsBuscadorTelefono;
	$scope.MostrarSipsBuscadorTarifa = MostrarSipsBuscadorTarifa;
	$scope.MostrarSipsBuscadorListaCups = MostrarSipsBuscadorListaCups;
	$scope.MostrarSipsBuscadorGraficoConsumos = MostrarSipsBuscadorGraficoConsumos;

    $scope.MostrarSipsInfoGeneral = MostrarSipsInfoGeneral;
    $scope.MostrarSipsCups = MostrarSipsCups;
    $scope.MostrarSipsDistribuidora = MostrarSipsDistribuidora;
    $scope.MostrarSipsCnae = MostrarSipsCnae;
    $scope.MostrarSipsDireccionSuministro = informacionSipsCliente;
    $scope.MostrarSipsMunicipio = MostrarSipsMunicipio;
    $scope.MostrarSipsProvincia = MostrarSipsProvincia;
    $scope.MostrarSipsCodigoPostal = MostrarSipsCodigoPostal;
    $scope.MostrarSipsTarifa = MostrarSipsTarifa;
    $scope.MostrarSipsTension = MostrarSipsTension;
    $scope.MostrarSipsPresion = MostrarSipsPresion;
    $scope.MostrarSipsP1 = MostrarSipsP1;
    $scope.MostrarSipsP2 = MostrarSipsP2;
    $scope.MostrarSipsP3 = MostrarSipsP3;
    $scope.MostrarSipsP4 = MostrarSipsP4;
    $scope.MostrarSipsP5 = MostrarSipsP5;
    $scope.MostrarSipsP6 = MostrarSipsP6;
    $scope.MostrarSipsCaudal = MostrarSipsCaudal;
    $scope.MostrarSipsCaudalMaximo = MostrarSipsCaudalMaximo;
    $scope.MostrarSipsUltimoCambioComercializadora = MostrarSipsUltimoCambioComercializadora;
    $scope.MostrarSipsUltimoCambioBIE = MostrarSipsUltimoCambioBIE;
    $scope.MostrarSipsUltimoCambioContrato = MostrarSipsUltimoCambioContrato;
    $scope.MostrarSipsConsumoAnual = MostrarSipsConsumoAnual;
    $scope.MostrarSipsFechaFinContrato = MostrarSipsFechaFinContrato;
    $scope.MostrarSipsConsumoP1 = MostrarSipsConsumoP1;
    $scope.MostrarSipsConsumoP2 = MostrarSipsConsumoP2;
    $scope.MostrarSipsConsumoP3 = MostrarSipsConsumoP3;
    $scope.MostrarSipsConsumoP4 = MostrarSipsConsumoP4;
    $scope.MostrarSipsConsumoP5 = MostrarSipsConsumoP5;
    $scope.MostrarSipsConsumoP6 = MostrarSipsConsumoP6;
    $scope.MostrarSipsTrasladarContrato = MostrarSipsTrasladarContrato;
    $scope.TextoPeriodoConsumo = TextoPeriodoConsumo;
    $scope.PeriodoConsumo = PeriodoConsumo;

    $scope.MostrarSipsBusquedaPorTitular = MostrarSipsBusquedaPorTitular;
    $scope.DescargaExcelSoloAdministradores = DescargaExcelSoloAdministradores;


    // sips titular tab
    $scope.MostrarSipsCupsTitular = MostrarSipsCups;
    $scope.MostrarSipsNombreTitular = MostrarSipsCups;
    $scope.MostrarSipsDireccionTitular = MostrarSipsCups;
    $scope.MostrarSipsDireccionSuministroTitular = MostrarSipsCups;
    $scope.MostrarSipsCodPostal = MostrarSipsCups;
    $scope.MostrarSipsCiudadTitular = MostrarSipsCups;

    $scope.SoyAdmin = atob(localStorage.getItem('isAdmin'));



    $scope.autocolumn = [
    {
        name: "InformacionGeneral",
        display: "Descarga Individual",
        oculta: "InfoGeneral"
    },{
        name: "Cups",
        display: "Cups",
        oculta: "Cups"
    }, {
        name: "DireccionSuministro",
        display: "Dirección Suministro",
        oculta: "DireccionSuministro"
    }, {
        name: "Municipio",
        display: "Municipio",
        oculta: "Municipio"
    }, {
        name: "Provincia",
        display: "Provincia",
        oculta: "Provincia"
    }, {
        name: "CodPostal",
        display: "Código Postal",
        oculta: "CodigoPostal"
    }, {
        name: "Tarifa",
        display: "Tarifa",
        oculta: "Tarifa"
    }, {
        name: "ConsumoAnual",
        display: $scope.TextoPeriodoConsumo,
        oculta: "ConsumoAnual && VerEnergia"
    }, {
        name: "ConsumoAnual",
        display: "CAE",
        oculta: "ConsumoAnual && VerGas"
    }, {
        name: "ConsumoP1",
        display: $scope.TextoPeriodoConsumo + " P1",
        oculta: "ConsumoP1  && VerEnergia"
    }, {
        name: "ConsumoP2",
        display: $scope.TextoPeriodoConsumo + " P2",
        oculta: "ConsumoP2  && VerEnergia"
    }, {
        name: "ConsumoP3",
        display: $scope.TextoPeriodoConsumo + " P3",
        oculta: "ConsumoP3  && VerEnergia"
    }, {
        name: "ConsumoP4",
        display: $scope.TextoPeriodoConsumo + " P4",
        oculta: "ConsumoP4  && VerEnergia"
    }, {
        name: "ConsumoP5",
        display: $scope.TextoPeriodoConsumo + " P5",
        oculta: "ConsumoP6  && VerEnergia"
    }, {
        name: "ConsumoP6",
        display: $scope.TextoPeriodoConsumo + " P6",
        oculta: "ConsumoP6  && VerEnergia"
    }, {
        name: "Caudal",
        display: "Caudal Horario",
        oculta: "Caudal && VerGas"
    }, {
        name: "CaudalMaximo",
        display: "Caudal Máximo",
        oculta: "CaudalMaximo && VerGas"
    }, {
        name: "P1",
        display: "P1",
        oculta: "P1  && VerEnergia"
    }, {
        name: "P2",
        display: "P2",
        oculta: "P2  && VerEnergia"
    }, {
        name: "P3",
        display: "P3",
        oculta: "P3  && VerEnergia"
    }, {
        name: "P4",
        display: "P4",
        oculta: "P4  && VerEnergia"
    }, {
        name: "P5",
        display: "P5",
        oculta: "P5  && VerEnergia"
    }, {
        name: "P6",
        display: "P6",
        oculta: "P6  && VerEnergia"
    }, {
        name: "Cnae",
        display: "CNAE",
        oculta: "Cnae"
    }, {
        name: "Distribuidora",
        display: "Distribuidora",
        oculta: "Distribuidora"
    }, {
        name: "UltimoCambioComercializadora",
        display: "Último Cambio Comercializadora",
        oculta: "UltimoCambioComercializadora"
    }, {
        name: "UltimoCambioBIE",
        display: "Último Cambio BIE",
        oculta: "UltimoCambioBIE"
    }, {
        name: "Tension",
        display: "Tensión",
        oculta: "Tension && VerEnergia"
    }, {
        name: "Presion",
        display: "Presión",
        oculta: "Presion && VerGas"
    }, {
        name: "UltimoCambioContrato",
        display: "Último Cambio Contrato",
        oculta: "UltimoCambioContrato"
	}, {
        name: "TrasladarContrato",
        display: "Trasladar Contrato",
        oculta: "TrasladarContrato"
	}];

    $scope.MostrarCampos = function() {
        if ($scope.clientesSips != undefined) {
            $scope.clientesSips = [];
            $scope.consumosAnuales = [];
            $scope.selected = [];
		}
		$scope.consumosVisible = false;

		var Cups = ($scope.Cups === undefined) ? "" : $scope.Cups;
        var CodigoPostalPS = ($scope.CodigoPostalPS === undefined) ? "" : $scope.CodigoPostalPS;
        var NombreEmpresaDistribuidora = ($scope.selectedItem === null) ? "" : $scope.selectedItem.value;
		var CodigoTarifaATREnVigor = $scope.selectedItemTarifa == undefined ? "" : $scope.selectedItemTarifa.value;
        var MunicipioPS = $scope.MunicipioPS == undefined ? "" : $scope.MunicipioPS.valor;
        var CodigoProvinciaPS = $scope.CodigoProvinciaPS == undefined ? "" : $scope.CodigoProvinciaPS.valor;
		// TODO: Nuevos inputs
		var DniTitular = ($scope.DniTitular === undefined) ? "" : $scope.DniTitular;
        var DireccionPS = ($scope.DireccionPS === undefined) ? "" : $scope.DireccionPS;
        var NombreTitular = ($scope.NombreTitular === undefined) ? "" : $scope.NombreTitular;
		var Telefono = ($scope.Telefono === undefined) ? "" : $scope.Telefono;

        var ListCUPS = $scope.ListCUPS == undefined ? "" : $scope.ListCUPS;
        var cups = "";
        var resultado = "";

        if (ListCUPS != "") {

            if (ListCUPS.indexOf('.') !== -1) {
                ListCUPS = ListCUPS.replace(/\./g, ",");
            }

            if (ListCUPS.indexOf(';') !== -1) {
                ListCUPS = ListCUPS.replace(/\;/g, ",");
            }

            if (ListCUPS.indexOf(' ') !== -1) {
                ListCUPS = ListCUPS.replace(/ /g, ",");
            }

            if (ListCUPS.indexOf(',,') !== -1) {
                ListCUPS = ListCUPS.replace(/\,\,/g, ",");
            }

            cups = ListCUPS.split(",");

            resultado = cups.map(function(elemento, i) {
                return elemento;
            });
        }
        if (
            Logueado == 'true' &&
            (Cups != '' || CodigoPostalPS != '' || NombreEmpresaDistribuidora != '' || CodigoTarifaATREnVigor != '' || MunicipioPS != '' || CodigoProvinciaPS != '' || resultado.length > 0 )
        ) {
            $scope.loading = true;
            serviciosApi.getSips(Cups, NombreEmpresaDistribuidora, MunicipioPS, CodigoProvinciaPS, CodigoPostalPS, CodigoTarifaATREnVigor, resultado)
                .then(function successCallback(response) {
                    $scope.loading = false;
                    //DatosSIPS
                    $scope.clientesSips = response.data.ClientesSips;
					// console.log($scope.clientesSips);
                    //Guardo todos los resultados de consumos en un array para luego usarlos en un ng-repeat en la vista
                    $scope.consumosSips = response.data.ConsumosSips;
					$scope.datosTitular = response.data.DatosTitular;

                    $scope.respuestaSIPS = response;
                    $scope.generarGraficoConsumos($scope.respuestaSIPS);

                    $scope.cliente = $scope.clientesSips[0];

                    $scope.cliente = $scope.clientesSips[0]; //
                    $scope.cliente.nombreTarifa = $scope.obtenerNombreTarifa($scope.cliente.CodigoTarifaATREnVigor);
                    $scope.cliente.nombreTarifaConML = $scope.cliente.nombreTarifa + ' ML';

					if ($scope.clientesSips.length > 0) {

						// Si es 2.0 DHA / 2.1 DHA mover P2 a P33
						if ($scope.VerEnergia && ($scope.clientesSips[0].CodigoTarifaATREnVigor == "004" || $scope.clientesSips[0].CodigoTarifaATREnVigor == "006")) {
							$scope.clientesSips[0].PotenciasContratadasEnWP3 = $scope.clientesSips[0].PotenciasContratadasEnWP1;
							$scope.clientesSips[0].PotenciasContratadasEnWP2 = null;
						}

						// Inserimos
						if (MostrarSipsPotenciasSige) {
							for (var i = 0; i < $scope.clientesSips.length; i++) {
								var cups = $scope.clientesSips[i].CodigoCUPS;
								$http
									.get(urlApi + "CUPS/VerificarCUPSExistente/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
									.then(function(response) {
										var playload = JSON.parse(atob(response.data.split('.')[1]));
										var playloadJson = JSON.parse(playload.iss);

										if (playloadJson) {
											$http
												.get(urlApi + "CUPS/GetCUPSPotencias/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
												.success(function(response) {
													var decodeToken = jwtHelper.decodeToken(response);
													var playloadJsonPotencias = JSON.parse(decodeToken.iss);

													var potenciaP1 = document.getElementById('PotenciaP1').textContent;
													var potenciaP2 = document.getElementById('PotenciaP2').textContent;
													var potenciaP3 = document.getElementById('PotenciaP3').textContent;
													var potenciaP4 = document.getElementById('PotenciaP4').textContent;
													var potenciaP5 = document.getElementById('PotenciaP5').textContent;
                                                    var potenciaP6 = document.getElementById('PotenciaP6').textContent;

													if (playloadJsonPotencias[0].TextoTarifa == "2.0A ML" || playloadJsonPotencias[0].TextoTarifa == "2.1A ML") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = "0";
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = "0";
														document.getElementById('PotenciaP5').innerHTML = "0";
														document.getElementById('PotenciaP6').innerHTML = "0";
													}

                                                    if (playloadJsonPotencias[0].TextoTarifa == "2.0DHA ML" || playloadJsonPotencias[0].TextoTarifa == "2.1DHA ML") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = "0";
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = "0";
														document.getElementById('PotenciaP5').innerHTML = "0";
														document.getElementById('PotenciaP6').innerHTML = "0";
													}

													if (
														playloadJsonPotencias[0].TextoTarifa == "2.0DHS ML" ||
														playloadJsonPotencias[0].TextoTarifa == "2.1DHS ML" ||
														playloadJsonPotencias[0].TextoTarifa == "3.0A ML" ||
														playloadJsonPotencias[0].TextoTarifa == "3.1A ML"
													) {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
													}

													if (playloadJsonPotencias[0].TextoTarifa == "6.1A ML" || playloadJsonPotencias[0].TextoTarifa == "6.2 ML" || playloadJsonPotencias[0].TextoTarifa == "6.3 ML" || playloadJsonPotencias[0].TextoTarifa == "6.4 ML" || playloadJsonPotencias[0].TextoTarifa == "6.5 ML" || playloadJsonPotencias[0].TextoTarifa == "6.1B ML") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[3].PotenciaContratada;
														document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[4].PotenciaContratada;
														document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[5].PotenciaContratada;
													}
                                                    if (playloadJsonPotencias[0].TextoTarifa == "2.0TD") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = "0";
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = "0";
														document.getElementById('PotenciaP5').innerHTML = "0";
														document.getElementById('PotenciaP6').innerHTML = "0";
													}
                                                    if (playloadJsonPotencias[0].TextoTarifa == "3.0TD" ) {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[0].PotenciaContratada;;
														document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
													}
                                                    if (playloadJsonPotencias[0].TextoTarifa == "6.1TD" || playloadJsonPotencias[0].TextoTarifa == "6.2TD"  ||
                                                            playloadJsonPotencias[0].TextoTarifa == "6.3TD" || playloadJsonPotencias[0].TextoTarifa == "6.4TD") {
														document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
														document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
														document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
														document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
														document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[3].PotenciaContratada;
														document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[4].PotenciaContratada;
														document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[5].PotenciaContratada;
													}

												});
										} else {}
									});
							}
						}

						var original = {
							"count": response.data.ClientesSips.length,
							"data": response.data.ClientesSips
						};

						$scope.datatable = angular.copy(original);
						$scope.smart = true;
					} else {
						$mdDialog.show(
							$mdDialog.alert()
							.clickOutsideToClose(true)
							.htmlContent('No se han encontrado datos.')
							.ariaLabel('No se han encontrado datos.')
							.ok('Aceptar')
						);
					}

                }, function errorCallback(response) {
                    $scope.loading = false;
					var alert = $mdDialog.alert()
						.clickOutsideToClose(true)
						.title('SIPS')
						.htmlContent('La información no está disponible.')
						.ariaLabel('La información no está disponible.')
						.ok('Aceptar');
					$mdDialog.show(alert);
				});
        }else{ console.log("Datos vacios"); }
    };


    $scope.autocolumnTitular = [
        {
            name: "CupsTitular",
            display: "Cups",
            oculta: "CupsTitular"
        }, {
            name: "NombreTitular",
            display: "Nombre Titular",
            oculta: "NombreTitular"
        }, {
            name: "CiudadTitular",
            display: "Ciudad Titular",
            oculta: "CiudadTitular"
        }, {
            name: "DireccionSuministro",
            display: "Dirección Suministro",
            oculta: "DireccionSuministro"
        }, {
            name: "CodPostal",
            display: "Código Postal",
            oculta: "CodPostal"
        }
    ];


    $scope.MostrarCamposTitular = function() {
        if ($scope.clientesSips != undefined) {
            $scope.clientesSips = [];
            $scope.consumosAnuales = [];
            $scope.selected = [];
		}

        if (Logueado == 'true') {

            $scope.loading = true;
            // console.log("NombreSipsTitular", this.NombreSipsTitular);
            // console.log("DireccionSuministro", $scope.DireccionSuministro);
            // console.log("NumeroTitular", $scope.NumeroTitular);
            // console.log("CodigoPostalTitular", $scope.CodigoPostalTitular);
            // console.log("CiudadTitular", $scope.CiudadTitular);
            // console.log("ProvinciaTitular", $scope.ProvinciaTitular);

            // console.log("NombreSipsTitular", this.NombreSipsTitular);
            // console.log("CiudadTitular", this.CiudadTitular);
            // console.log("ProvinciaTitular", this.ProvinciaTitular);

            serviciosApi.getSipsTitular(this.NombreSipsTitular, this.CiudadTitular, this.ProvinciaTitular, this.DireccionSuministro, this.NumeroTitular, this.CodigoPostalTitular)

                .then(function successCallback(response) {
                    $scope.loading = false;
                    //DatosSIPS
                    // $scope.clientesSips = response.data.ClientesSips;
                    //Guardo todos los resultados de consumos en un array para luego usarlos en un ng-repeat en la vista
                    // $scope.consumosSips = response.data.ConsumosSips;
					$scope.datosTitulares = response.data.DatosTitular;

					if ($scope.datosTitulares.length > 0) {

						var original = {
							"count": response.data.DatosTitular.length,
							"data": response.data.DatosTitular
						};

						$scope.datatable = angular.copy(original);
						$scope.smart = true;
					} else {
						$mdDialog.show(
							$mdDialog.alert()
							.clickOutsideToClose(true)
							.htmlContent('No se han encontrado datos.')
							.ariaLabel('No se han encontrado datos.')
							.ok('Aceptar')
						);
					}

                }, function errorCallback(response) {
                    $scope.loading = false;
					var alert = $mdDialog.alert()
						.clickOutsideToClose(true)
						.title('SIPS')
						.htmlContent('La información no está disponible.')
						.ariaLabel('La información no está disponible.')
						.ok('Aceptar');
					$mdDialog.show(alert);
				});
        }else{ console.log("Datos vacios"); }
    };


    $scope.calcularConsumoAnual = function(cliente) {
        $scope.consumosAnuales = [];
        var CupsLinea = cliente.clienteSips.CodigoCUPS;
        if (CupsLinea != undefined) {
            if (localStorage.getItem('idEmpresaGas') == 'true') {
				urlApiSips = urlApiSipsGas;
			}

            $http.get(urlApiSips).then(function(response) {
				var BusquedaConsumos = response.data;

                var ConsumosAnualesCups = [];
                var sumatorioActivas = 0;

				if (BusquedaConsumos.length > 0) {
					var FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFin;
					if (FechaFin == null) {
						FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFinMesConsumo;
					}
                    var FechaActual = new Date(FechaFin);


                    var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
                    var FechaFin2 = FechaFin.split('T');
					var FechaInicio = new Date(FechaFin2[0]);
					FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';
					$scope.P1 = 0;
					$scope.P2 = 0;
					$scope.P3 = 0;
					$scope.P4 = 0;
					$scope.P5 = 0;
                    $scope.P6 = 0;

                    if($scope.VerGas){
                        var auxCaudalMedioEnWhDia = 0;
                        var auxCaudalMedioEnWhAnual = 0;
                        var auxCaudalMaximoDiario = 0;
                        var doceUltimosMesesInteracion = BusquedaConsumos.length-12;
                        var doceUltimosConsumosArrayGas = new Array();
                    }
					$.grep(BusquedaConsumos, function(n, i) {


						if (BusquedaConsumos[i].FechaInicio == null) {
							BusquedaConsumos[i].FechaInicio = BusquedaConsumos[i].FechaInicioMesConsumo;
						}

                        if (n.FechaInicio >= FechaInicio || n.FechaInicio == null) {

							ConsumosAnualesCups.push(n);
							sumatorioActivas += BusquedaConsumos[i].Activa1 + BusquedaConsumos[i].Activa2 + BusquedaConsumos[i].Activa3 +
								BusquedaConsumos[i].Activa4 + BusquedaConsumos[i].Activa5 + BusquedaConsumos[i].Activa6;

							$scope.P1 += BusquedaConsumos[i].Activa1;
							$scope.P2 += BusquedaConsumos[i].Activa2;
							$scope.P3 += BusquedaConsumos[i].Activa3;
							$scope.P4 += BusquedaConsumos[i].Activa4;
							$scope.P5 += BusquedaConsumos[i].Activa5;
                            $scope.P6 += BusquedaConsumos[i].Activa6;

                        }


                        if($scope.VerGas){

                            if(n.CaudalMedioEnWhDia > 0){
                                auxCaudalMedioEnWhDia = n.CaudalMedioEnWhDia;
                                cliente.clienteSips.CaudalHorarioEnWh = auxCaudalMedioEnWhDia;
                            }

                            // consumo anual gas
                            if( i >= doceUltimosMesesInteracion){
                                doceUltimosConsumosArrayGas.push(n);
                                auxCaudalMedioEnWhAnual = n.ConsumoEnWhP1 + auxCaudalMedioEnWhAnual;
                            }

                            // console.log(n.CaudalMaximoDiario + " > " + auxCaudalMaximoDiario);
                            if(n.CaudalMaximoDiario != null && n.CaudalMaximoDiario > auxCaudalMaximoDiario){
                                cliente.clienteSips.CaudalMaximoDiarioEnWh = auxCaudalMaximoDiario = n.auxCaudalMaximoDiario;
                            }

                        }

                    });

                    // console.log("*****");
                    // console.log(cliente.clienteSips.CaudalHorarioEnWh);
                    // console.log(cliente.clienteSips.CaudalMaximoDiarioEnWh);
                    // console.log("*****");

					var fechaInicialMesConsumo = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
                    var fechaInicio = new Date();
                    var fechaFin = new Date(FechaFin).getTime();
                    // console.log(fechaFin);

                    if(!$scope.VerGas){
                        fechaInicio = (fechaInicialMesConsumo == null) ? new Date(ConsumosAnualesCups[0].FechaInicioMesConsumo).getTime() : new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
                    }else{
                        fechaInicio = (fechaInicialMesConsumo == null) ? new Date(doceUltimosConsumosArrayGas[0].FechaInicioMesConsumo).getTime() : new Date(doceUltimosConsumosArrayGas[0].FechaInicio).getTime();
                    }

					var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
                    var consumos = Math.round(((sumatorioActivas / 1000) / countDias) * 365)/$scope.PeriodoConsumo;
                    // console.log("ConsumosAnualesCups[0].FechaInicio");
                    // console.log(ConsumosAnualesCups[0].FechaInicio);
                    // console.log("Fecha inicio del primer periodo del año");
                    // console.log(doceUltimosConsumosArrayGas[0].FechaInicio);
                    // console.log("fecha final del ultimo periodo del año");
                    // console.log(FechaFin);
                    // console.log("Numero de dias de los 12 ultimos periodos del SIPS");
                    // console.log(countDias);

					$scope.P1 = Math.round((($scope.P1 / 1000) / countDias) * 365)/$scope.PeriodoConsumo;
					$scope.P2 = Math.round((($scope.P2 / 1000) / countDias) * 365)/$scope.PeriodoConsumo;
					$scope.P3 = Math.round((($scope.P3 / 1000) / countDias) * 365)/$scope.PeriodoConsumo;
					$scope.P4 = Math.round((($scope.P4 / 1000) / countDias) * 365)/$scope.PeriodoConsumo;
					$scope.P5 = Math.round((($scope.P5 / 1000) / countDias) * 365)/$scope.PeriodoConsumo;
                    $scope.P6 = Math.round((($scope.P6 / 1000) / countDias) * 365)/$scope.PeriodoConsumo;

                    // console.log("Caudal Medio EnWh en los 12 ultimos periodos");
                    // console.log(auxCaudalMedioEnWhAnual / 1000);

                    // GAS
                    auxCaudalMedioEnWhAnual = ($scope.VerGas) ? Math.round((auxCaudalMedioEnWhAnual / countDias) * 365) : 0;

                    // console.log("Caudal Medio EnWh Anual dividido por los dias entre los 12 periodos " + countDias + " y multiplicado por 365");
                    // console.log(auxCaudalMedioEnWhAnual);

				}

                var tarifa = cliente.clienteSips.CodigoTarifaATREnVigor;

                if ($scope.VerEnergia && (tarifa == "004" || tarifa == "006")) {
                    // Si es 2.0 DHA / 2.1 DHA mover P2 a P3
                    $scope.P3 = $scope.P2;
                    $scope.P2 = "0";
                } else if ($scope.VerEnergia && (tarifa == "003" || tarifa == "011")) {
                    // Suma P1+P4 / P2+P5 / P3+P6 en tarifas 3.0 y 3.1
                    $scope.P1 = $scope.P1 + $scope.P4;
                    $scope.P2 = $scope.P2 + $scope.P5;
                    $scope.P3 = $scope.P3 + $scope.P6;
                    $scope.P4 = "0";
                    $scope.P5 = "0";
                    $scope.P6 = "0";
                }

                // if (consumos == 0) {
                // 	var valorMasAlto = Math.max(cliente.clientesSips[0].PotenciasContratadasEnWP1, cliente.clientesSips[0].PotenciasContratadasEnWP2, cliente.clientesSips[0].PotenciasContratadasEnWP3, cliente.clientesSips[0].PotenciasContratadasEnWP4, cliente.clientesSips[0].PotenciasContratadasEnWP5, cliente.clientesSips[0].PotenciasContratadasEnWP6);
                // 	consumos = valorMasAlto * 8760 * 0.2;
                // }

                if($scope.VerGas){
                    consumos = auxCaudalMedioEnWhAnual/1000;
                }

                $scope.consumosAnuales.push({
                    "CUPS": CupsLinea,
                    "ConsumoAnual": consumos ? Math.round(consumos) : 0,
                    "ConsumoP1": ($scope.P1) ? $scope.P1 : 0,
                    "ConsumoP2": ($scope.P2) ? $scope.P2 : 0,
                    "ConsumoP3": ($scope.P3) ? $scope.P3 : 0,
                    "ConsumoP4": ($scope.P4) ? $scope.P4 : 0,
                    "ConsumoP5": ($scope.P5) ? $scope.P5 : 0,
                    "ConsumoP6": ($scope.P6) ? $scope.P6 : 0,
                    "CaudalHorarioEnWh": cliente.clienteSips.CaudalHorarioEnWh,
                    "CaudalMaximoDiarioEnWh": cliente.clienteSips.CaudalMaximoDiarioEnWh,
                    "CodigoPeajeEnVigor": cliente.clienteSips.CodigoPeajeEnVigor,
                    "CodigoPostal": cliente.clienteSips.CodigoPostalPS,
                    "CodigoPresion": cliente.clienteSips.CodigoPresion,
                    "CodigoTensionV": cliente.clienteSips.CodigoTensionV,
                    "Distribuidora": cliente.clienteSips.NombreEmpresaDistribuidora,
                    "FechaEmisionBIE": cliente.clienteSips.FechaEmisionBIE,
                    "FechaUltimaLectura": cliente.clienteSips.FechaUltimaLectura,
                    "FechaUltimoCambioComercializador": cliente.clienteSips.FechaUltimoCambioComercializador,
                    "FechaUltimoMovimientoContrato": cliente.clienteSips.FechaUltimoMovimientoContrato,
                    "Municipio": cliente.clienteSips.DesMunicipioPS,
                    "PotenciasWP1": cliente.clienteSips.PotenciasContratadasEnWP1,
                    "PotenciasWP2": cliente.clienteSips.PotenciasContratadasEnWP2,
                    "PotenciasWP3": cliente.clienteSips.PotenciasContratadasEnWP3,
                    "PotenciasWP4": cliente.clienteSips.PotenciasContratadasEnWP4,
                    "PotenciasWP5": cliente.clienteSips.PotenciasContratadasEnWP5,
                    "PotenciasWP6": cliente.clienteSips.PotenciasContratadasEnWP6,
                    "Provincia": cliente.clienteSips.DesProvinciaPS,
                    "Tarifa": cliente.clienteSips.CodigoTarifaATREnVigor,
                });

                $scope.busquedaSips = $scope.consumosAnuales;
            });
        }

    };

	$scope.selected = [];

	$scope.VerConsumos = function(selected) {
		$scope.consumosVisible = !$scope.consumosVisible;
		var cons = [];
		var cupsSelected = [];
		// CUPS seleccionados
		$.grep(selected, function(n) { cupsSelected.push(n.CodigoCUPS.toString()); });
		$.grep($scope.busquedaSips, function(n) {
			// Si el CUPS seleccionado se encuentra
			if (cupsSelected.indexOf(n.CUPS) > -1) {
				cons.push({
					name: n.CUPS,
					data: [ Number(n.ConsumoP1), Number(n.ConsumoP2), Number(n.ConsumoP3), Number(n.ConsumoP4), Number(n.ConsumoP5), Number(n.ConsumoP6) ]
				});
			}
		});
		new Highcharts.Chart({
			chart: { renderTo: 'grafico', type: 'column' },
			title: { text: 'Gráfico de Consumos' },
			yAxis: { title: { text: 'Consumo en kWh' }, min: 0 },
			xAxis: { categories: [ 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', ] },
			series: cons,
			credits: false
		});
	};

	$scope.TrasladarContrato = function(contrato) {
		if ($scope.VerEnergia === true && $scope.VerGas === false) { localStorage.setItem('tipoAlta', btoa('e')); }
		if ($scope.VerEnergia === false && $scope.VerGas === true) { localStorage.setItem('tipoAlta', btoa('g')); }
        $rootScope.AltaSips = contrato;
        $location.url("/contratos/alta");
	}

    $scope.exportarDatosSIPS = function(selected) {
        var myData = [];
        var consumos = [];

        selected.forEach(function(obj) {
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var tension = "";
            var consumosA = [];
            var consAnual = "";
            var consAnualp1 = "";
            var consAnualp2 = "";
            var consAnualp3 = "";
            var consAnualp4 = "";
            var consAnualp5 = "";
            var consAnualp6 = "";

            // if (selected.length == 1) {
            //     //consumoAnual = $scope.consumosAnuales;
            //     $scope.TextoFichero = cups + "_General.xls";
            // }

            // if (selected.length > 1) {
            //     $scope.TextoFichero = "DescargaMasiva_General.xls";

            // }

            consumosA = $scope.busquedaSips.map(function(e) {
                if (e.CUPS == cups) {
                    consAnual = e.ConsumoAnual;
                    consAnualp1 = e.ConsumoP1;
                    consAnualp2 = parseInt(e.ConsumoP2);
                    consAnualp3 = e.ConsumoP3;
                    consAnualp4 = e.ConsumoP4;
                    consAnualp5 = e.ConsumoP5;
                    consAnualp6 = e.ConsumoP6;
                }
            });

            switch (obj.CodigoTarifaATREnVigor) {
                case "001":
                    tarifa = "2.0A";
                    break;
                case "003":
                    tarifa = "3.0A";
                    break;
                case "004":
                    tarifa = "2.0DHA";
                    break;
                case "005":
                    tarifa = "2.1A";
                    break;
                case "006":
                    tarifa = "2.1DHA";
                    break;
                case "007":
                    tarifa = "2.0DHS";
                    break;
                case "008":
                    tarifa = "2.1DHS";
                    break;
                case "011":
                    tarifa = "3.1A";
                    break;
                case "012":
                    tarifa = "6.1A";
                    break;
                case "013":
                    tarifa = "6.2";
                    break;
                case "014":
                    tarifa = "6.3";
                    break;
                case "015":
                    tarifa = "6.4";
                    break;
                case "016":
                    tarifa = "6.5";
                    break;
                case "017":
                    tarifa = "6.1B";
                    break;
                case "018":
                    tarifa = "2.0TD";
                    break;
                case "019":
                    tarifa = "3.0TD";
                    break;
                case "020":
                    tarifa = "6.1TD";
                    break;
                case "021":
                    tarifa = "6.2TD";
                    break;
                case "022":
                    tarifa = "6.3TD";
                    break;
                case "023":
                    tarifa = "6.4TD";
                    break;

            }

            switch (obj.CodigoTensionV) {
                case "01":
                    tension = "1X220";
                    break;
                case "02":
                    tension = "1X230";
                    break;
                case "03":
                    tension = "3X380";
                    break;
                case "04":
                    tension = "3X380/220";
                    break;
                case "05":
                    tension = "3X400";
                    break;
                case "06":
                    tension = "3X400/230";
                    break;
                case "07":
                    tension = "1X127";
                    break;
                case "08":
                    tension = "1X133";
                    break;
                case "09":
                    tension = "2X220";
                    break;
                case "10":
                    tension = "2X230";
                    break;
                case "11":
                    tension = "3X220";
                    break;
                case "12":
                    tension = "3X220/127";
                    break;
                case "13":
                    tension = "3X230";
                    break;
                case "14":
                    tension = "3X230/133";
                    break;
                case "15":
                    tension = "5.000";
                    break;
                case "16":
                    tension = "6.000";
                    break;
                case "17":
                    tension = "7.200";
                    break;
                case "18":
                    tension = "8.000";
                    break;
                case "19":
                    tension = "10.000";
                    break;
                case "20":
                    tension = "11.000";
                    break;
                case "21":
                    tension = "12.000";
                    break;
                case "22":
                    tension = "13.000";
                    break;
                case "23":
                    tension = "13.200";
                    break;
                case "24":
                    tension = "15.000";
                    break;
                case "25":
                    tension = "16.500";
                    break;
                case "26":
                    tension = "17.000";
                    break;
                case "27":
                    tension = "20.000";
                    break;
                case "28":
                    tension = "22.000";
                    break;
                case "29":
                    tension = "25.000";
                    break;
                case "30":
                    tension = "26.500";
                    break;
                case "31":
                    tension = "30.000";
                    break;
                case "32":
                    tension = "36.000";
                    break;
                case "33":
                    tension = "44.000";
                    break;
                case "34":
                    tension = "45.000";
                    break;
                case "35":
                    tension = "66.000";
                    break;
                case "36":
                    tension = "110.000";
                    break;
                case "37":
                    tension = "132.000";
                    break;
                case "38":
                    tension = "220.000";
                    break;
                case "39":
                    tension = "400.000";
                    break;
                case "40":
                    tension = "1.200";
                    break;
                case "41":
                    tension = "2.000";
                    break;
                case "42":
                    tension = "5.500";
                    break;
                case "43":
                    tension = "55.000";
                    break;
                case "44":
                    tension = "130.000";
                    break;
                case "45":
                    tension = "100";
                    break;
                case "46":
                    tension = "150";
                    break;
                case "47":
                    tension = "3x100";
                    break;
                case "48":
                    tension = "3x150/260";
                    break;
                case "49":
                    tension = "3x260";
                    break;
            }
			// console.log(obj);
            myData.push({
                'Codigo CUPS': obj.CodigoCUPS,
                'Codigo Empresa Distribuidora': (obj.CodigoEmpresaDistribuidora != null) ? obj.CodigoEmpresaDistribuidora : '',
                'Nombre Empresa Distribuidora': (obj.NombreEmpresaDistribuidora != null) ? obj.NombreEmpresaDistribuidora : '',
                'Codigo Postal PS': (obj.CodigoPostalPS != null) ? obj.CodigoPostalPS : '',
                'Municipio PS': parseInt(obj.MunicipioPS),
                'Codigo Provincia PS': (obj.CodigoProvinciaPS != null) ? obj.CodigoProvinciaPS : '',
                'Tension': tension,
                'Tarifa': tarifa,
                'Fecha Alta Suministro': (obj.FechaAltaSuministro != null) ? obj.FechaAltaSuministro : '',
                'Codigo TensionV': (obj.CodigoTensionV != null) ? obj.CodigoTensionV : '',
                'Potencia Maxima BIEW': (obj.PotenciaMaximaBIEW != null) ? parseInt(obj.PotenciaMaximaBIEW) : '',
                'Potencia Maxima APMW': (obj.PotenciaMaximaAPMW != null) ? parseInt(obj.PotenciaMaximaAPMW) : '',
                'Codigo Clasificacion PS': (obj.CodigoClasificacionPS != null) ? obj.CodigoClasificacionPS : '',
                'Codigo Disponibilidad ICP': (obj.CodigoDisponibilidadICP != null) ? obj.CodigoDisponibilidadICP : '',
                'Tipo Perfil Consumo': (obj.TipoPerfilConsumo != null) ? obj.TipoPerfilConsumo : '',
                'Valor Derechos ExtensionW': parseInt(obj.ValorDerechosExtensionW),
                'Valor Derechos AccesoW': parseInt(obj.ValorDerechosAccesoW),
                'Codigo Propiedad EquipoMedida': (obj.CodigoPropiedadEquipoMedida != null) ? obj.CodigoPropiedadEquipoMedida : '',
                'Codigo Propiedad ICP': (obj.CodigoPropiedadICP != null) ? obj.CodigoPropiedadICP : '',
                'Consumo Anual': parseInt(consAnual),
                'Consumo Anual P1': parseInt(consAnualp1),
                'Consumo Anual P2': parseInt(consAnualp2),
                'Consumo Anual P3': parseInt(consAnualp3),
                'Consumo Anual P4': parseInt(consAnualp4),
                'Consumo Anual P5': parseInt(consAnualp5),
                'Consumo Anual P6': parseInt(consAnualp6),
                'Potencias Contratadas En W P1': (obj.PotenciasContratadasEnWP1 != null) ? parseFloat(obj.PotenciasContratadasEnWP1).toFixed(3).replace('.',',') : '',
                'Potencias Contratadas En W P2': (obj.PotenciasContratadasEnWP2 != null) ? parseFloat(obj.PotenciasContratadasEnWP2).toFixed(3).replace('.',',') : '',
                'Potencias Contratadas En W P3': (obj.PotenciasContratadasEnWP3 != null) ? parseFloat(obj.PotenciasContratadasEnWP3).toFixed(3).replace('.',',') : '',
                'Potencias Contratadas En W P4': (obj.PotenciasContratadasEnWP4 != null) ? parseFloat(obj.PotenciasContratadasEnWP4).toFixed(3).replace('.',',') : '',
                'Potencias Contratadas En W P5': (obj.PotenciasContratadasEnWP5 != null) ? parseFloat(obj.PotenciasContratadasEnWP5).toFixed(3).replace('.',',') : '',
                'Potencias Contratadas En W P6': (obj.PotenciasContratadasEnWP6 != null) ? parseFloat(obj.PotenciasContratadasEnWP6).toFixed(3).replace('.',',') : '',
                'Fecha Ultimo Movimiento Contrato': (obj.FechaUltimoMovimientoContrato != null) ? obj.FechaUltimoMovimientoContrato : '',
                'Fecha Ultimo Cambio Comercializador': (obj.FechaUltimoCambioComercializador != null) ? obj.FechaUltimoCambioComercializador : '',
                'Fecha Limite Derechos Reconocidos': (obj.FechaLimiteDerechosReconocidos != null) ? obj.FechaLimiteDerechosReconocidos : '',
                'Fecha Ultima Lectura': (obj.FechaUltimaLectura != null) ? obj.FechaUltimaLectura : '',
                'Informacion Impagos': (obj.InformacionImpagos != null) ? obj.InformacionImpagos : '',
                'Importe Deposito Garantia Euros': parseInt(obj.ImporteDepositoGarantiaEuros),
                'Tipo Id Titular': (obj.TipoIdTitular != null) ? obj.TipoIdTitular : '',
                'Es Vivienda Habitual': (obj.EsViviendaHabitual != null) ? obj.EsViviendaHabitual : '',
                'Codigo Comercializadora': (obj.CodigoComercializadora != null) ? obj.CodigoComercializadora : '',
                'Codigo Telegestion': (obj.CodigoTelegestion != null) ? obj.CodigoTelegestion : '',
                'Codigo Fases Equipo Medida': (obj.CodigoFasesEquipoMedida != null) ? obj.CodigoFasesEquipoMedida : '',
                'Codigo Autoconsumo': (obj.CodigoAutoconsumo != null) ? obj.CodigoAutoconsumo : '',
                'Codigo TipoContrato': (obj.CodigoTipoContrato != null) ? obj.CodigoTipoContrato : '',
                'Codigo Periodicidad Facturacion': (obj.CodigoPeriodicidadFacturacion != null) ? obj.CodigoPeriodicidadFacturacion : '',
                'Codigo BIE': (obj.CodigoBIE != null) ? obj.CodigoBIE : '',
                'Fecha Emision BIE': (obj.FechaEmisionBIE != null) ? obj.FechaEmisionBIE : '',
                'Fecha Caducidad BIE': (obj.FechaCaducidadBIE != null) ? obj.FechaCaducidadBIE : '',
                'Codigo APM': (obj.CodigoAPM != null) ? obj.CodigoAPM : '',
                'Fecha Emision APM': (obj.FechaEmisionAPM != null) ? obj.FechaEmisionAPM : '',
                'Fecha Caducidad APM': (obj.FechaCaducidadAPM != null) ? obj.FechaCaducidadAPM : '',
                'Relacion Transformacion Intensidad': (obj.RelacionTransformacionIntensidad != null) ? obj.RelacionTransformacionIntensidad : '',
                'CNAE': (obj.Cnae != null) ? obj.Cnae : '',
                'Codigo Modo Control Potencia': (obj.CodigoModoControlPotencia != null) ? obj.CodigoModoControlPotencia : '',
                'Potencia CGPW': (obj.PotenciaCGPW != null) ? obj.PotenciaCGPW : '',
                'Codigo DH Equipo De Medida': (obj.CodigoDHEquipoDeMedida != null) ? obj.CodigoDHEquipoDeMedida : '',
                'Codigo Accesibilidad Contador': (obj.CodigoAccesibilidadContador != null) ? obj.CodigoAccesibilidadContador : '',
                'Codigo PS Contratable': (obj.CodigoPSContratable != null) ? obj.CodigoPSContratable : '',
                'Motivo Estado No Contratable': (obj.MotivoEstadoNoContratable != null) ? obj.MotivoEstadoNoContratable : '',
                'Codigo Tension Medida': (obj.CodigoTensionMedida != null) ? obj.CodigoTensionMedida : '',
                'Codigo Clase Expediente': (obj.CodigoClaseExpediente != null) ? obj.CodigoClaseExpediente : '',
                'Codigo Motivo Expediente': (obj.CodigoMotivoExpediente != null) ? obj.CodigoMotivoExpediente : '',
                'Codigo Tipo Suministro': (obj.CodigoTipoSuministro != null) ? obj.CodigoTipoSuministro : '',
                'Aplicacion Bono Social': (obj.AplicacionBonoSocial != null) ? obj.AplicacionBonoSocial : '',
                'Descripcion Provincia PS': (obj.DesProvinciaPS != null) ? obj.DesProvinciaPS : '',
                'Descripcion Municipio PS': (obj.DesMunicipioPS != null) ? obj.DesMunicipioPS : '',
                'Tipo Via PS': (obj.TipoViaPS != null) ? obj.TipoViaPS : '',
                'Via PS': (obj.ViaPS != null) ? obj.ViaPS : '',
                'Num Finca PS': (obj.NumFincaPS != null) ? obj.NumFincaPS : '',
                'Portal PS': (obj.PortalPS != null) ? obj.PortalPS : '',
                'Escalera PS': (obj.EscaleraPS != null) ? obj.EscaleraPS : '',
                'Piso PS': (obj.PisoPS != null) ? obj.PisoPS : '',
                'Puerta PS': (obj.PuertaPS != null) ? obj.PuertaPS : '',
                'Codigo Presion': (obj.CodigoPresion != null) ? obj.CodigoPresion : '',
                'Codigo Peaje En Vigor': (obj.CodigoPeajeEnVigor != null) ? obj.CodigoPeajeEnVigor : '',
                'Caudal Maximo Diario En Wh': (obj.CaudalMaximoDiarioEnWh != null) ? obj.CaudalMaximoDiarioEnWh : '',
                'Caudal Horario En Wh': (obj.CaudalHorarioEnWh != null) ? obj.CaudalHorarioEnWh : '',
                'Derecho TUR': (obj.DerechoTUR != null) ? obj.DerechoTUR : '',
                'Fecha Ultima Inspeccion': (obj.FechaUltimaInspeccion != null) ? obj.FechaUltimaInspeccion : '',
                'Codigo Resultado Inspeccion': (obj.CodigoResultadoInspeccion != null) ? obj.CodigoResultadoInspeccion : '',
                'Codigo Contador': (obj.CodigoContador != null) ? obj.CodigoContador : '',
                'Calibre Contador': (obj.CalibreContador != null) ? obj.CalibreContador : '',
                'Tipo Contador': (obj.TipoContador != null) ? obj.TipoContador : '',
                'Propiedad Equipo Medida': (obj.PropiedadEquipoMedida != null) ? obj.PropiedadEquipoMedida : '',
                'Codigo Telemedida': (obj.CodigoTelemedida != null) ? obj.CodigoTelemedida : ''

            });

        });

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: myData,
          columns: getColumns(myData)
        });

    };

    $scope.exportarDatosSIPSGas = function(selected) {

        var myData = [];
        var consumos = [];
		// console.log($scope.selected);
        selected.forEach(function(obj) {
			// console.log(obj.CUPS);
			// console.log(obj);
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var presion = "";

            if (selected.length == 1) {
                $scope.TextoFichero = cups + "_General.xls";
            }

            if (selected.length > 1) {
                $scope.TextoFichero = "DescargaMasiva_General.xls";
            }

            switch (obj.CodigoPeajeEnVigor) {
                case "11":
                    tarifa = "1.1";
                    break;
                case "12":
                    tarifa = "1.2";
                    break;
                case "13":
                    tarifa = "1.3";
                    break;
                case "21":
                    tarifa = "2.1";
                    break;
                case "22":
                    tarifa = "2.2";
                    break;
                case "23":
                    tarifa = "2.3";
                    break;
                case "24":
                    tarifa = "2.4";
                    break;
                case "25":
                    tarifa = "2.5";
                    break;
                case "26":
                    tarifa = "2.6";
                    break;
                case "1B":
                    tarifa = "2.1 bis";
                    break;
                case "2B":
                    tarifa = "2.2 bis";
                    break;
                case "3B":
                    tarifa = "2.3 bis";
                    break;
                case "4B":
                    tarifa = "2.4 bis";
                    break;
                case "5B":
                    tarifa = "2.5 bis";
                    break;
                case "6B":
                    tarifa = "2.6 bis";
                    break;
                case "31":
                    tarifa = "3.1";
                    break;
                case "32":
                    tarifa = "3.2";
                    break;
                case "33":
                    tarifa = "3.3";
                    break;
                case "34":
                    tarifa = "3.4";
                    break;
                case "35":
                    tarifa = "3.5";
                    break;
                case "41":
                    tarifa = "4.1";
                    break;
                case "42":
                    tarifa = "4.2";
                    break;
                case "43":
                    tarifa = "4.3";
                    break;
                case "44":
                    tarifa = "4.4";
                    break;
                case "45":
                    tarifa = "4.5";
                    break;
                case "46":
                    tarifa = "4.6";
                    break;
                case "47":
                    tarifa = "4.7";
                    break;
                case "A1":
                    tarifa = "A1";
                    break;
                case "A2":
                    tarifa = "A2";
                    break;
                case "A3":
                    tarifa = "A3";
                    break;
                case "B1":
                    tarifa = "B1";
                    break;
                case "B2":
                    tarifa = "B2";
                    break;
                case "C1":
                    tarifa = "C1";
                    break;
                case "C2":
                    tarifa = "C2";
                    break;
                case "D1":
                    tarifa = "D1";
                    break;
                case "D2":
                    tarifa = "D2";
                    break;
                case "M1":
                    tarifa = "M1";
                    break;
                case "M2":
                    tarifa = "M2";
                    break;
                case "99":
                    tarifa = "99";
                    break;
            }

            var consumoAnual = 0;

            consumos = $scope.consumosSips.filter(function(n) { return n.CodigoCUPS == obj.CodigoCUPS; });
            consumos = consumos.filter(function(n) { return n != undefined; });

            if (consumos.length >= 12) {
                for (var i = consumos.length - 1; i >= consumos.length - 12; i--) {
                    consumoAnual += consumos[i].ConsumoEnWhP1;
                }
            } else {
                for (var z = 0; z < consumos.length; z++) {
                    consumoAnual += consumos[z].ConsumoEnWhP1;
                }
            }
            // console.log(obj);
            myData.push({
                CodigoCUPS: obj.CodigoCUPS,
                CodigoEmpresaDistribuidora: (obj.CodigoEmpresaDistribuidora === null) ?"": obj.CodigoEmpresaDistribuidora,
                NombreEmpresaDistribuidora: (obj.NombreEmpresaDistribuidora === null) ?"": obj.NombreEmpresaDistribuidora,
                CodigoPostalPS: (obj.CodigoPostalPS === null) ?"": obj.CodigoPostalPS,
                MunicipioPS: (obj.MunicipioPS === null) ?"": obj.MunicipioPS,
                CodigoProvinciaPS: (obj.CodigoProvinciaPS === null) ?"": obj.CodigoProvinciaPS,
                t: tarifa,
                p: presion,
                consumoAnual: consumoAnual,
                FechaAltaSuministro: (obj.FechaAltaSuministro === null) ?"": obj.FechaAltaSuministro,
                CodigoTarifaATREnVigor: (obj.CodigoTarifaATREnVigor === null) ?"": obj.CodigoTarifaATREnVigor,
                CodigoTensionV: (obj.CodigoTensionV === null) ?"": obj.CodigoTensionV,
                PotenciaMaximaBIEW: (obj.PotenciaMaximaBIEW === null) ?"": obj.PotenciaMaximaBIEW,
                PotenciaMaximaAPMW: (obj.PotenciaMaximaAPMW === null) ?"": obj.PotenciaMaximaAPMW,
                CodigoClasificacionPS: (obj.CodigoClasificacionPS === null) ?"": obj.CodigoClasificacionPS,
                CodigoDisponibilidadICP: (obj.CodigoDisponibilidadICP === null) ?"": obj.CodigoDisponibilidadICP,
                TipoPerfilConsumo: (obj.TipoPerfilConsumo === null) ?"": obj.TipoPerfilConsumo,
                ValorDerechosExtensionW: (obj.ValorDerechosExtensionW === null) ?"": obj.ValorDerechosExtensionW,
                ValorDerechosAccesoW: (obj.ValorDerechosAccesoW === null) ?"": obj.ValorDerechosAccesoW,
                CodigoPropiedadEquipoMedida: (obj.CodigoPropiedadEquipoMedida === null) ?"": obj.CodigoPropiedadEquipoMedida,
                CodigoPropiedadICP: (obj.CodigoPropiedadICP === null) ?"": obj.CodigoPropiedadICP,
                FechaUltimoMovimientoContrato: (obj.FechaUltimoMovimientoContrato === null) ?"": obj.FechaUltimoMovimientoContrato,
                FechaUltimoCambioComercializador: (obj.FechaUltimoCambioComercializador === null) ?"": obj.FechaUltimoCambioComercializador,
                FechaLimiteDerechosReconocidos: (obj.FechaLimiteDerechosReconocidos === null) ?"": obj.FechaLimiteDerechosReconocidos,
                FechaUltimaLectura: (obj.FechaUltimaLectura === null) ?"": obj.FechaUltimaLectura,
                InformacionImpagos: (obj.InformacionImpagos === null) ?"": obj.InformacionImpagos,
                ImporteDepositoGarantiaEuros: (obj.ImporteDepositoGarantiaEuros === null) ?"": obj.ImporteDepositoGarantiaEuros,
                TipoIdTitular: (obj.TipoIdTitular === null) ?"": obj.TipoIdTitular,
                EsViviendaHabitual: (obj.EsViviendaHabitual === null) ?"": obj.EsViviendaHabitual,
                CodigoComercializadora: (obj.CodigoComercializadora === null) ?"": obj.CodigoComercializadora,
                CodigoTelegestion: (obj.CodigoTelegestion === null) ?"": obj.CodigoTelegestion,
                CodigoFasesEquipoMedida: (obj.CodigoFasesEquipoMedida === null) ?"": obj.CodigoFasesEquipoMedida,
                CodigoAutoconsumo: (obj.CodigoAutoconsumo === null) ?"": obj.CodigoAutoconsumo,
                CodigoTipoContrato: (obj.CodigoTipoContrato === null) ?"": obj.CodigoTipoContrato,
                CodigoPeriodicidadFacturacion: (obj.CodigoPeriodicidadFacturacion === null) ?"": obj.CodigoPeriodicidadFacturacion,
                CodigoBIE: (obj.CodigoBIE === null) ?"": obj.CodigoBIE,
                FechaEmisionBIE: (obj.FechaEmisionBIE === null) ?"": obj.FechaEmisionBIE,
                FechaCaducidadBIE: (obj.FechaCaducidadBIE === null) ?"": obj.FechaCaducidadBIE,
                CodigoAPM: (obj.CodigoAPM === null) ?"": obj.CodigoAPM,
                FechaEmisionAPM: (obj.FechaEmisionAPM === null) ?"": obj.FechaEmisionAPM,
                FechaCaducidadAPM: (obj.FechaCaducidadAPM === null) ?"": obj.FechaCaducidadAPM,
                RelacionTransformacionIntensidad: (obj.RelacionTransformacionIntensidad === null) ?"": obj.RelacionTransformacionIntensidad,
                Cnae: (obj.Cnae === null) ?"": obj.Cnae,
                CodigoModoControlPotencia: (obj.CodigoModoControlPotencia === null) ?"": obj.CodigoModoControlPotencia,
                PotenciaCGPW: (obj.PotenciaCGPW === null) ?"": obj.PotenciaCGPW,
                CodigoDHEquipoDeMedida: (obj.CodigoDHEquipoDeMedida === null) ?"": obj.CodigoDHEquipoDeMedida,
                CodigoAccesibilidadContador: (obj.CodigoAccesibilidadContador === null) ?"": obj.CodigoAccesibilidadContador,
                CodigoPSContratable: (obj.CodigoPSContratable === null) ?"": obj.CodigoPSContratable,
                MotivoEstadoNoContratable: (obj.MotivoEstadoNoContratable === null) ?"": obj.MotivoEstadoNoContratable,
                CodigoTensionMedida: (obj.CodigoTensionMedida === null) ?"": obj.CodigoTensionMedida,
                CodigoClaseExpediente: (obj.CodigoClaseExpediente === null) ?"": objCodigoClaseExpediente,
                CodigoMotivoExpediente: (obj.CodigoMotivoExpediente === null) ?"": obj.CodigoMotivoExpediente,
                CodigoTipoSuministro: (obj.CodigoTipoSuministro === null) ?"": obj.CodigoTipoSuministro,
                AplicacionBonoSocial: (obj.AplicacionBonoSocial === null) ?"": obj.AplicacionBonoSocial,
                DesProvinciaPS: (obj.DesProvinciaPS === null) ?"": obj.DesProvinciaPS,
                DesMunicipioPS: (obj.DesMunicipioPS === null) ?"": obj.DesMunicipioPS,
                TipoViaPS: (obj.TipoViaPS === null) ?"": obj.TipoViaPS,
                ViaPS: (obj.ViaPS === null) ?"": obj.ViaPS,
                NumFincaPS: (obj.NumFincaPS === null) ?"": obj.NumFincaPS,
                PortalPS: (obj.PortalPS === null) ?"": obj.PortalPS,
                EscaleraPS: (obj.EscaleraPS === null) ?"": obj.EscaleraPS,
                PisoPS: (obj.PisoPS === null) ?"": obj.PisoPS,
                PuertaPS: (obj.PuertaPS === null) ?"": obj.PuertaPS,
                CodigoPresion: (obj.CodigoPresion === null) ?"": obj.CodigoPresion,
                CodigoPeajeEnVigor: (obj.CodigoPeajeEnVigor === null) ?"": obj.CodigoPeajeEnVigor,
                CaudalMaximoDiarioEnWh: (obj.CaudalMaximoDiarioEnWh === null) ?"": obj.CaudalMaximoDiarioEnWh,
                CaudalHorarioEnWh: (obj.CaudalHorarioEnWh === null) ?"": obj.CaudalHorarioEnWh,
                DerechoTUR: (obj.DerechoTUR === null) ?"": obj.DerechoTUR,
                FechaUltimaInspeccion: (obj.FechaUltimaInspeccion === null) ?"": obj.FechaUltimaInspeccion,
                CodigoResultadoInspeccion: (obj.CodigoResultadoInspeccion === null) ?"": obj.CodigoResultadoInspeccion,
                CodigoContador: (obj.CodigoContador === null) ?"": obj.CodigoContador,
                CalibreContador: (obj.CalibreContador === null) ?"": obj.CalibreContador,
                TipoContador: (obj.TipoContador === null) ?"": obj.TipoContador,
                PropiedadEquipoMedida: (obj.PropiedadEquipoMedida === null) ?"": obj.PropiedadEquipoMedida,
                CodigoTelemedida: (obj.CodigoTelemedida === null) ?"": obj.CodigoTelemedida
            });

        });

		// return myData;
		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: myData,
          columns: getColumns(myData)
        });
    };



    $scope.exportarDatosSIPSConsumos = function(selected) {

        var myData = [];
        var consumos = [];

        selected.forEach(function(obj) {
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var tension = "";

            // if (selected.length == 1) {
            //     $scope.TextoFicheroConsumos = cups + "_Consumos.xls";
            // }

            // if (selected.length > 1) {
            //     $scope.TextoFicheroConsumos = "DescargaMasiva_Consumos.xls";
            // }

            myData.push({
                'CUPS': obj.CodigoCUPS,
                'Fecha Lectura Inicial': "",
                'Fecha Lectura Final': "",
                'P1 Activa': "",
                'P2 Activa': "",
                'P3 Activa': "",
                'P4 Activa': "",
                'P5 Activa': "",
                'P6 Activa': "",
                'P1 Reactiva': "",
                'P2 Reactiva': "",
                'P3 Reactiva': "",
                'P4 Reactiva': "",
                'P5 Reactiva': "",
                'P6 Reactiva': "",
                'P1 Maximetro': "",
                'P2 Maximetro': "",
                'P3 Maximetro': "",
                'P4 Maximetro': "",
                'P5 Maximetro': "",
                'P6 Maximetro': ""
            });

            consumos = $scope.consumosSips.map(function(obj) {
                if (obj.CodigoCUPS == cups) {
                    if (obj.FechaInicio == null && obj.FechaFin == null) {} else {
                        return {
                            'CUPS' : "",
                            'Fecha Lectura Inicial' : obj.FechaInicio,
							'Fecha Lectura Final' : obj.FechaFin,
							// parseFloat(obj.PotenciasContratadasEnWP1).toFixed(2).replace('.',',')
                            'P1 Activa' : (obj.Activa1 == null) ? "0" : parseFloat(obj.Activa1).toFixed(3).replace('.',','),
                            'P2 Activa' : (obj.Activa2 == null) ? "0" : parseFloat(obj.Activa2).toFixed(3).replace('.',','),
                            'P3 Activa' : (obj.Activa3 == null) ? "0" : parseFloat(obj.Activa3).toFixed(3).replace('.',','),
                            'P4 Activa' : (obj.Activa4 == null) ? "0" : parseFloat(obj.Activa4).toFixed(3).replace('.',','),
                            'P5 Activa' : (obj.Activa5 == null) ? "0" : parseFloat(obj.Activa5).toFixed(3).replace('.',','),
                            'P6 Activa' : (obj.Activa6 == null) ? "0" : parseFloat(obj.Activa6).toFixed(3).replace('.',','),
                            'P1 Reactiva' : (obj.Reactiva1 == null) ? "0" : parseFloat(obj.Reactiva1).toFixed(3).replace('.',','),
                            'P2 Reactiva' : (obj.Reactiva2 == null) ? "0" : parseFloat(obj.Reactiva2).toFixed(3).replace('.',','),
                            'P3 Reactiva' : (obj.Reactiva3 == null) ? "0" : parseFloat(obj.Reactiva3).toFixed(3).replace('.',','),
                            'P4 Reactiva' : (obj.Reactiva4 == null) ? "0" : parseFloat(obj.Reactiva4).toFixed(3).replace('.',','),
                            'P5 Reactiva' : (obj.Reactiva5 == null) ? "0" : parseFloat(obj.Reactiva5).toFixed(3).replace('.',','),
                            'P6 Reactiva' : (obj.Reactiva6 == null) ? "0" : parseFloat(obj.Reactiva6).toFixed(3).replace('.',','),
                            'P1 Maximetro' : (obj.Potencia1 == null) ? "0" : parseFloat(obj.Potencia1).toFixed(3).replace('.',','),
                            'P2 Maximetro' : (obj.Potencia2 == null) ? "0" : parseFloat(obj.Potencia2).toFixed(3).replace('.',','),
                            'P3 Maximetro' : (obj.Potencia3 == null) ? "0" : parseFloat(obj.Potencia3).toFixed(3).replace('.',','),
                            'P4 Maximetro' : (obj.Potencia4 == null) ? "0" : parseFloat(obj.Potencia4).toFixed(3).replace('.',','),
                            'P5 Maximetro' : (obj.Potencia5 == null) ? "0" : parseFloat(obj.Potencia5).toFixed(3).replace('.',','),
                            'P6 Maximetro' : (obj.Potencia6 == null) ? "0" : parseFloat(obj.Potencia6).toFixed(3).replace('.',',')
                        };
                    }
                }
            });

            consumos = consumos.filter(function(n) { return n != undefined; });

            myData.push.apply(myData, consumos);
        });

		// return myData;
		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: myData,
          columns: getColumns(myData)
        });
    };

    $scope.exportarDatosSIPSGasConsumos = function(selected) {

        var myData = [];
        var consumos = [];

        selected.forEach(function(obj) {
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var tension = "";

            if (selected.length == 1) {
                $scope.TextoFicheroConsumos = cups + "_Consumos.xls";
            }

            if (selected.length > 1) {
                $scope.TextoFicheroConsumos = "DescargaMasiva_Consumos.xls";
            }


            myData.push({
                CUPS: obj.CodigoCUPS,
                FechaInicioMesConsumo: "",
                FechaFinMesConsumo: "",
                ConsumoEnWhP1: "",
                CaudalMaximoDiario: "",
                CaudalMedioEnWhDia: "",
                CaudalMinimoDiario: "",
                TarifaPeaje: "",
                p1reactiva: "",
                p2reactiva: "",
                p3reactiva: "",
                p4reactiva: "",
                p5reactiva: "",
                p6reactiva: "",
                p1maximetro: "",
                p2maximetro: "",
                p3maximetro: "",
                p4maximetro: "",
                p5maximetro: "",
                p6maximetro: ""
            });

            consumos = $scope.consumosSips.map(function(obj) {
                if (obj.CodigoCUPS == cups) {
                    if (obj.FechaInicioMesConsumo == null && obj.FechaFinMesConsumo == null) {} else {
                        // console.log(obj);
                        return {
                            CUPS: "",
                            FechaInicioMesConsumo: obj.FechaInicioMesConsumo,
                            FechaFinMesConsumo: obj.FechaFinMesConsumo,
                            ConsumoEnWhP1: (obj.ConsumoEnWhP1 == null ? "0" : obj.ConsumoEnWhP1),
                            CaudalMaximoDiario: (obj.CaudalMaximoDiario == null ? "0" : obj.CaudalMaximoDiario),
                            CaudalMedioEnWhDia: (obj.CaudalMedioEnWhDia == null ? "0" : obj.CaudalMedioEnWhDia),
                            CaudalMinimoDiario: (obj.CaudalMinimoDiario == null ? "0" : obj.CaudalMinimoDiario),
                            TarifaPeaje: obj.CodigoTarifaPeaje.toString().replace(/(\d)(?=(\d)+(?!\d))/g, "$1.")
                        };
                    }
                }
            });

            consumos = consumos.filter(function(n) { return n != undefined; });

            myData.push.apply(myData, consumos);
        });

		// return myData;
		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: myData,
          columns: getColumns(myData)
        });
    };

    $scope.exportarDatosSIPSBoton = function (data) {
        var myData = [];
        var tarifa = "";
        var tension = "";
        $scope.TextoFichero = data.CUPS + "_General.xls";

        switch (data.Tarifa) {
                case "001":
                    tarifa = "2.0A";
                    break;
                case "003":
                    tarifa = "3.0A";
                    break;
                case "004":
                    tarifa = "2.0DHA";
                    break;
                case "005":
                    tarifa = "2.1A";
                    break;
                case "006":
                    tarifa = "2.1DHA";
                    break;
                case "007":
                    tarifa = "2.0DHS";
                    break;
                case "008":
                    tarifa = "2.1DHS";
                    break;
                case "011":
                    tarifa = "3.1A";
                    break;
                case "012":
                    tarifa = "6.1A";
                    break;
                case "013":
                    tarifa = "6.2";
                    break;
                case "014":
                    tarifa = "6.3";
                    break;
                case "015":
                    tarifa = "6.4";
                    break;
                case "016":
                    tarifa = "6.5";
                    break;
                case "017":
                    tarifa = "6.1B";
                    break;
                case "018":
                    tarifa = "2.0TD";
                    break;
                case "019":
                    tarifa = "3.0TD";
                    break;
                case "020":
                    tarifa = "6.1TD";
                    break;
                case "021":
                    tarifa = "6.2TD";
                    break;
                case "022":
                    tarifa = "6.3TD";
                    break;
                case "023":
                    tarifa = "6.4TD";
                    break;

        }

        switch (data.CodigoTensionV) {
            case "01":
                tension = "1X220";
                break;
            case "02":
                tension = "1X230";
                break;
            case "03":
                tension = "3X380";
                break;
            case "04":
                tension = "3X380/220";
                break;
            case "05":
                tension = "3X400";
                break;
            case "06":
                tension = "3X400/230";
                break;
            case "07":
                tension = "1X127";
                break;
            case "08":
                tension = "1X133";
                break;
            case "09":
                tension = "2X220";
                break;
            case "10":
                tension = "2X230";
                break;
            case "11":
                tension = "3X220";
                break;
            case "12":
                tension = "3X220/127";
                break;
            case "13":
                tension = "3X230";
                break;
            case "14":
                tension = "3X230/133";
                break;
            case "15":
                tension = "5.000";
                break;
            case "16":
                tension = "6.000";
                break;
            case "17":
                tension = "7.200";
                break;
            case "18":
                tension = "8.000";
                break;
            case "19":
                tension = "10.000";
                break;
            case "20":
                tension = "11.000";
                break;
            case "21":
                tension = "12.000";
                break;
            case "22":
                tension = "13.000";
                break;
            case "23":
                tension = "13.200";
                break;
            case "24":
                tension = "15.000";
                break;
            case "25":
                tension = "16.500";
                break;
            case "26":
                tension = "17.000";
                break;
            case "27":
                tension = "20.000";
                break;
            case "28":
                tension = "22.000";
                break;
            case "29":
                tension = "25.000";
                break;
            case "30":
                tension = "26.500";
                break;
            case "31":
                tension = "30.000";
                break;
            case "32":
                tension = "36.000";
                break;
            case "33":
                tension = "44.000";
                break;
            case "34":
                tension = "45.000";
                break;
            case "35":
                tension = "66.000";
                break;
            case "36":
                tension = "110.000";
                break;
            case "37":
                tension = "132.000";
                break;
            case "38":
                tension = "220.000";
                break;
            case "39":
                tension = "400.000";
                break;
            case "40":
                tension = "1.200";
                break;
            case "41":
                tension = "2.000";
                break;
            case "42":
                tension = "5.500";
                break;
            case "43":
                tension = "55.000";
                break;
            case "44":
                tension = "130.000";
                break;
            case "45":
                tension = "100";
                break;
            case "46":
                tension = "150";
                break;
            case "47":
                tension = "3x100";
                break;
            case "48":
                tension = "3x150/260";
                break;
            case "49":
                tension = "3x260";
                break;
        }

        myData.push({
            CodigoCUPS: data.CUPS,
            DireccionSuministro: data.DireccionSuministro,
            Municipio: data.Municipio,
            Provincia: data.Provincia,
            CodigoPostal: data.CodigoPostal,
            Tarifa: tarifa,
            ConsumoAnual: data.ConsumoAnual,
            ConsumoAnualP1: data.ConsumoP1,
            ConsumoAnualP2: data.ConsumoP2,
            ConsumoAnualP3: data.ConsumoP3,
            ConsumoAnualP4: data.ConsumoP4,
            ConsumoAnualP5: data.ConsumoP5,
            ConsumoAnualP6: data.ConsumoP6,
            P1: data.PotenciasWP1,
            P2: data.PotenciasWP2,
            P3: data.PotenciasWP3,
            P4: data.PotenciasWP4,
            P5: data.PotenciasWP5,
            P6: data.PotenciasWP6,
            Distribuidora: data.Distribuidora,
            UltCambioComer: data.FechaUltimoCambioComercializador,
            UltCambioBIE: data.FechaEmisionBIE,
            Tension: tension,
            UltCambioCont: data.FechaUltimoMovimientoContrato

        });
        return myData;

	}

	$scope.seleccionaTodos = function(){
		$scope.clientesSips.forEach(function (item) {
			$scope.selected.push(item);
		});
	}

	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

    $scope.exportarDatosTitulares = function(selected) {
        var myData = [];

        // console.log(selected);

        selected.forEach(function(obj) {

            myData.push({
                'Codigo CUPS': (obj.Identificador != null) ? obj.Identificador : '',
                'Nombre Titular': (obj.NombreSipsTitular != null) ? obj.NombreSipsTitular : '',
                'Ciudad Titular': (obj.CiudadTitular != null) ? obj.CiudadTitular : '',
                'Direccion suministro': (obj.DireccionSuministro != null) ? obj.DireccionSuministro : '',
                'CP': (obj.CP != null) ? obj.CP : ''
            });

        });

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: myData,
          columns: getColumns(myData)
        });

    };

    $scope.seleccionaTodosTitulares = function(){
        // console.log("seleccionaTodosTitulares");
		$scope.datosTitulares.forEach(function (item) {
			$scope.selectedTitulares.push(item);
		});

        // console.log($scope.selectedTitulares);
	}

	$scope.deseleccionaTodosTitulares =  function(){
        // console.log("deseleccionaTodosTitulares");
		$scope.selectedTitulares = [];
	}

    $scope.buscarPorCupsTitular =  function(cups){
        // console.log("deseleccionaTodosTitulares");
        $scope.Cups = cups;
        // $scope.myTabIndex = 0; // CAMBIAR DE TAB A LA PRIMERA
		$scope.MostrarCampos();
	}







    $scope.calcularConsumoAnual = function(cliente) {
        $scope.consumosAnuales = [];
        var CupsLinea = cliente.clienteSips.CodigoCUPS;
        if (CupsLinea != undefined) {
            var urlApiSipsAux = (localStorage.getItem('idEmpresaGas') == 'true') ? urlApiSipsGas : urlApiSips;

            $http.get(urlApiSipsAux + "/ConsumoGet/?_SipsCupsFiltersDTO={\"CodigosCUPS\":[\"" + CupsLinea + "\"]}").then(function(response) {

				$scope.busquedaConsumos = response.data;
                //console.log($scope.busquedaConsumos);

                var BusquedaConsumos = $scope.busquedaConsumos;

                var ConsumosAnualesCups = [];
                var sumatorioActivas = 0;

				if (BusquedaConsumos.length > 0) {
					var FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFin;
					if (FechaFin == null) {
						FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFinMesConsumo;
					}
                    var FechaActual = new Date(FechaFin);


                    var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
                    var FechaFin2 = FechaFin.split('T');
					var FechaInicio = new Date(FechaFin2[0]);
					FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" + ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';
					$scope.P1 = 0;
					$scope.P2 = 0;
					$scope.P3 = 0;
					$scope.P4 = 0;
					$scope.P5 = 0;
					$scope.P6 = 0;
					if($scope.VerGas){
                        var auxCaudalMedioEnWhDia = 0;
                        var auxCaudalMedioEnWhAnual = 0;
                        var auxCaudalMaximoDiario = 0;
                        var doceUltimosMesesInteracion = BusquedaConsumos.length-12;
                        var doceUltimosConsumosArrayGas = new Array();
                    }
					$.grep(BusquedaConsumos, function(n, i) {
						if (BusquedaConsumos[i].FechaInicio == null) {
							BusquedaConsumos[i].FechaInicio = BusquedaConsumos[i].FechaInicioMesConsumo;
						}
						if (n.FechaInicio >= FechaInicio) {
							ConsumosAnualesCups.push(n);
							sumatorioActivas += BusquedaConsumos[i].Activa1 + BusquedaConsumos[i].Activa2 + BusquedaConsumos[i].Activa3 +
								BusquedaConsumos[i].Activa4 + BusquedaConsumos[i].Activa5 + BusquedaConsumos[i].Activa6;

							$scope.P1 += BusquedaConsumos[i].Activa1;
							$scope.P2 += BusquedaConsumos[i].Activa2;
							$scope.P3 += BusquedaConsumos[i].Activa3;
							$scope.P4 += BusquedaConsumos[i].Activa4;
							$scope.P5 += BusquedaConsumos[i].Activa5;
							$scope.P6 += BusquedaConsumos[i].Activa6;
						}
						if($scope.VerGas){

                            if(n.CaudalMedioEnWhDia > 0){
                                auxCaudalMedioEnWhDia = n.CaudalMedioEnWhDia;
                                cliente.clienteSips.CaudalHorarioEnWh = auxCaudalMedioEnWhDia;
                            }

                            // consumo anual gas
                            if(n.FechaInicioMesConsumo >= FechaInicio){
                                doceUltimosConsumosArrayGas.push(n);
                                auxCaudalMedioEnWhAnual = n.ConsumoEnWhP1 + auxCaudalMedioEnWhAnual;
                            }

                            // console.log(n.CaudalMaximoDiario + " > " + auxCaudalMaximoDiario);
                            if(n.CaudalMaximoDiario != null && n.CaudalMaximoDiario > auxCaudalMaximoDiario){
                                cliente.clienteSips.CaudalMaximoDiarioEnWh = auxCaudalMaximoDiario = n.auxCaudalMaximoDiario;
                            }

                        }
					});
					var fechaInicialMesConsumo = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
					var fechaInicio = new Date();
					if(!$scope.VerGas){
                        fechaInicio = (fechaInicialMesConsumo == null) ? new Date(ConsumosAnualesCups[0].FechaInicioMesConsumo).getTime() : new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
                    }else{
                        fechaInicio = (fechaInicialMesConsumo == null) ? new Date(doceUltimosConsumosArrayGas[0].FechaInicioMesConsumo).getTime() : new Date(doceUltimosConsumosArrayGas[0].FechaInicio).getTime();
                    }

					var fechaFin = new Date(FechaFin).getTime();
					var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
					var consumos = Math.round(((sumatorioActivas / 1000) / countDias) * 365);

					$scope.P1 = Math.round((($scope.P1 / 1000) / countDias) * 365);
					$scope.P2 = Math.round((($scope.P2 / 1000) / countDias) * 365);
					$scope.P3 = Math.round((($scope.P3 / 1000) / countDias) * 365);
					$scope.P4 = Math.round((($scope.P4 / 1000) / countDias) * 365);
					$scope.P5 = Math.round((($scope.P5 / 1000) / countDias) * 365);
					$scope.P6 = Math.round((($scope.P6 / 1000) / countDias) * 365);
					// GAS
                    auxCaudalMedioEnWhAnual = ($scope.VerGas) ? Math.round((auxCaudalMedioEnWhAnual / countDias) * 365) : 0;
				}

                $scope.consumoAnual = consumos;

                var tarifa = cliente.clienteSips.CodigoTarifaATREnVigor;

                if ($scope.VerEnergia && (tarifa == "004" || tarifa == "006")) {
                    // Si es 2.0 DHA / 2.1 DHA mover P2 a P3
                    $scope.P3 = $scope.P2;
                    $scope.P2 = "0";
                } else if ($scope.VerEnergia && (tarifa == "003" || tarifa == "011")) {
                    // Suma P1+P4 / P2+P5 / P3+P6 en tarifas 3.0 y 3.1
					$scope.P1 = $scope.P1 + $scope.P4;
                    $scope.P2 = $scope.P2 + $scope.P5;
                    $scope.P3 = $scope.P3 + $scope.P6;
                    $scope.P4 = "0";
                    $scope.P5 = "0";
                    $scope.P6 = "0";
                }
                // if (consumos == 0) {
                // 	var valorMasAlto = Math.max(cliente.clientesSips[0].PotenciasContratadasEnWP1, cliente.clientesSips[0].PotenciasContratadasEnWP2, cliente.clientesSips[0].PotenciasContratadasEnWP3, cliente.clientesSips[0].PotenciasContratadasEnWP4, cliente.clientesSips[0].PotenciasContratadasEnWP5, cliente.clientesSips[0].PotenciasContratadasEnWP6);
                // 	consumos = valorMasAlto * 8760 * 0.2;
                // }
				if($scope.VerGas){
                    consumos = auxCaudalMedioEnWhAnual/1000;
                }
                $scope.consumosAnuales.push({
                    "CUPS": CupsLinea,
                    "ConsumoAnual": consumos ? Math.round(consumos) : 0,
                    "ConsumoP1": ($scope.P1) ? $scope.P1 : 0,
                    "ConsumoP2": ($scope.P2) ? $scope.P2 : 0,
                    "ConsumoP3": ($scope.P3) ? $scope.P3 : 0,
                    "ConsumoP4": ($scope.P4) ? $scope.P4 : 0,
                    "ConsumoP5": ($scope.P5) ? $scope.P5 : 0,
                    "ConsumoP6": ($scope.P6) ? $scope.P6 : 0,
                    "CaudalHorarioEnWh": cliente.clienteSips.CaudalHorarioEnWh,
                    "CaudalMaximoDiarioEnWh": cliente.clienteSips.CaudalMaximoDiarioEnWh,
                    "CodigoPeajeEnVigor": cliente.clienteSips.CodigoPeajeEnVigor,
                    "CodigoPostal": cliente.clienteSips.CodigoPostalPS,
                    "CodigoPresion": cliente.clienteSips.CodigoPresion,
                    "CodigoTensionV": cliente.clienteSips.CodigoTensionV,
                    "Distribuidora": cliente.clienteSips.NombreEmpresaDistribuidora,
                    "FechaEmisionBIE": cliente.clienteSips.FechaEmisionBIE,
                    "FechaUltimaLectura": cliente.clienteSips.FechaUltimaLectura,
                    "FechaUltimoCambioComercializador": cliente.clienteSips.FechaUltimoCambioComercializador,
                    "FechaUltimoMovimientoContrato": cliente.clienteSips.FechaUltimoMovimientoContrato,
                    "Municipio": cliente.clienteSips.DesMunicipioPS,
                    "PotenciasWP1": cliente.clienteSips.PotenciasContratadasEnWP1,
                    "PotenciasWP2": cliente.clienteSips.PotenciasContratadasEnWP2,
                    "PotenciasWP3": cliente.clienteSips.PotenciasContratadasEnWP3,
                    "PotenciasWP4": cliente.clienteSips.PotenciasContratadasEnWP4,
                    "PotenciasWP5": cliente.clienteSips.PotenciasContratadasEnWP5,
                    "PotenciasWP6": cliente.clienteSips.PotenciasContratadasEnWP6,
                    "Provincia": cliente.clienteSips.DesProvinciaPS,
                    "CNAE": cliente.clienteSips.Cnae,
                    "Tarifa": cliente.clienteSips.CodigoTarifaATREnVigor,
                });

                $scope.busquedaSips = $scope.consumosAnuales;

                // console.log('Cliente: ', cliente);
                // console.log('Busqueda Sips: ', $scope.busquedaSips);
            });
        }

    };




    $scope.generarGraficoConsumos = function(respuestaSips) {

        ($scope.myChartST!=undefined) ? $scope.myChartST.reset() : null;


        $scope.mostrarGrafico = true;

        //Consumo Grafico
        var mesesArray = ['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre','Octubre', 'Noviembre', 'Diciembre'];

        var ayudaMes = [];
        var ayudaAno = [];

        for(var i = respuestaSips.data.ConsumosSips.length - 12; i<respuestaSips.data.ConsumosSips.length;i++){
            var str = respuestaSips.data.ConsumosSips[i].FechaFin;
            var mesConsumo = str.slice(5, 7);
            var anoConsumo = str.slice(0, 4);
            var quitarCero = mesConsumo.slice(0, 1);
            if(quitarCero == 0){
                mesConsumo = mesConsumo.replace(/0/g,"");
            }
            ayudaMes.push(mesConsumo);
            ayudaAno.push(anoConsumo);
        }

        var consumo = respuestaSips.data.ConsumosSips;
        var longConsumo = respuestaSips.data.ConsumosSips.length;

        var ctx = document.getElementById('GraficoConsumoContrato');

        var data = {
            labels: [
                ayudaAno[0] + " / " + mesesArray[ayudaMes[0]],
                ayudaAno[1] + " / " + mesesArray[ayudaMes[1]],
                ayudaAno[2] + " / " + mesesArray[ayudaMes[2]],
                ayudaAno[3] + " / " + mesesArray[ayudaMes[3]],
                ayudaAno[4] + " / " + mesesArray[ayudaMes[4]],
                ayudaAno[5] + " / " + mesesArray[ayudaMes[5]],
                ayudaAno[6] + " / " + mesesArray[ayudaMes[6]],
                ayudaAno[7] + " / " + mesesArray[ayudaMes[7]],
                ayudaAno[8] + " / " + mesesArray[ayudaMes[8]],
                ayudaAno[9] + " / " + mesesArray[ayudaMes[9]],
                ayudaAno[10] + " / " + mesesArray[ayudaMes[10]],
                ayudaAno[11] + " / " + mesesArray[ayudaMes[11]]
                ],
                datasets: [
                    //Activa 1
                    {
                        label: 'Activa 1',
                        data: [
                        consumo[longConsumo-12].Activa1, consumo[longConsumo-11].Activa1, consumo[longConsumo-10].Activa1,
                        consumo[longConsumo-9].Activa1,consumo[longConsumo-8].Activa1, consumo[longConsumo-7].Activa1,
                        consumo[longConsumo-6].Activa1, consumo[longConsumo-5].Activa1, consumo[longConsumo-4].Activa1,
                        consumo[longConsumo-3].Activa1, consumo[longConsumo-2].Activa1, consumo[longConsumo-1].Activa1
                        ],
                        backgroundColor: '#6B79C3',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 0
                    },
                    //Activa 2
                    {
                        label: 'Activa 2',
                        data: [
                        consumo[longConsumo-12].Activa2, consumo[longConsumo-11].Activa2, consumo[longConsumo-10].Activa2,
                        consumo[longConsumo-9].Activa2,consumo[longConsumo-8].Activa2, consumo[longConsumo-7].Activa2,
                        consumo[longConsumo-6].Activa2, consumo[longConsumo-5].Activa2, consumo[longConsumo-4].Activa2,
                        consumo[longConsumo-3].Activa2, consumo[longConsumo-2].Activa2, consumo[longConsumo-1].Activa2
                        ],
                        backgroundColor: '#4FC5EA',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 0
                    },
                    //Activa 3
                    {
                        label: 'Activa 3',
                        data: [
                        consumo[longConsumo-12].Activa3, consumo[longConsumo-11].Activa3, consumo[longConsumo-10].Activa3,
                        consumo[longConsumo-9].Activa3,consumo[longConsumo-8].Activa3, consumo[longConsumo-7].Activa3,
                        consumo[longConsumo-6].Activa3, consumo[longConsumo-5].Activa3, consumo[longConsumo-4].Activa3,
                        consumo[longConsumo-3].Activa3, consumo[longConsumo-2].Activa3, consumo[longConsumo-1].Activa3
                        ],
                        backgroundColor: 'rgba(255, 206, 86, 0.2)',
                        borderColor: 'rgba(255, 206, 86, 1)',
                        borderWidth: 0
                    },
                    //Activa 4
                    {
                        label: 'Activa 4',
                        data: [
                        consumo[longConsumo-12].Activa4, consumo[longConsumo-11].Activa4, consumo[longConsumo-10].Activa4,
                        consumo[longConsumo-9].Activa4,consumo[longConsumo-8].Activa4, consumo[longConsumo-7].Activa4,
                        consumo[longConsumo-6].Activa4, consumo[longConsumo-5].Activa4, consumo[longConsumo-4].Activa4,
                        consumo[longConsumo-3].Activa4, consumo[longConsumo-2].Activa4, consumo[longConsumo-1].Activa4
                        ],
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 0
                    },
                    //Activa 5
                    {
                        label: 'Activa 5',
                        data: [
                        consumo[longConsumo-12].Activa5, consumo[longConsumo-11].Activa5, consumo[longConsumo-10].Activa5,
                        consumo[longConsumo-9].Activa5,consumo[longConsumo-8].Activa5, consumo[longConsumo-7].Activa5,
                        consumo[longConsumo-6].Activa5, consumo[longConsumo-5].Activa5, consumo[longConsumo-4].Activa5,
                        consumo[longConsumo-3].Activa5, consumo[longConsumo-2].Activa5, consumo[longConsumo-1].Activa5
                        ],
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 0
                    },
                    //Activa 6
                    {
                        label: 'Activa 6',
                        data: [
                        consumo[longConsumo-12].Activa6, consumo[longConsumo-11].Activa6, consumo[longConsumo-10].Activa6,
                        consumo[longConsumo-9].Activa6,consumo[longConsumo-8].Activa6, consumo[longConsumo-7].Activa6,
                        consumo[longConsumo-6].Activa6, consumo[longConsumo-5].Activa6, consumo[longConsumo-4].Activa6,
                        consumo[longConsumo-3].Activa6, consumo[longConsumo-2].Activa6, consumo[longConsumo-1].Activa6
                        ],
                        backgroundColor: 'rgba(33, 174, 64, 0.2)',
                        borderColor: 'rgba(33, 174, 64, 1)',
                        borderWidth: 0
                    }

            ]
        }


        var sumaTotalxBar = 0;
        var arraySuma = [0];

        $scope.myChartST = new Chart(ctx, {
            type: 'bar',
            data: data,
            chart: {
                height: 100
            },
            options: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                    text: 'Gráfico Consumo'
                },
                scales: {
                    xAxes: [{
                    stacked: true,
                    }],
                    yAxes: [{
                    stacked: true,
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    }
                    }]
                },

                tooltips: {
                    enabled: true
                },
                hover: {
                    animationDuration: 1
                },
                animation: {
                    duration: 1,
                    onComplete: function () {
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                            ctx.textAlign = 'center';
                            ctx.fillStyle = "black";
                            ctx.textBaseline = 'bottom';

                        this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function(bar, index) {

                                var data = dataset.data[index];
                                arraySuma.push( data);

                                if(bar._datasetIndex == 5){
                                sumaTotalxBar = arraySuma[1 + index ] + arraySuma[13 + index] + arraySuma[25 + index] + arraySuma[37 +index] +arraySuma[49 +index] + arraySuma[61 +index];
                                ctx.fillText(sumaTotalxBar, bar._model.x, bar._model.y);

                                }
                            });
                        });
                    }
                }
            }
        });
    };

    $scope.obtenerNombreTarifa = function(text) {
        // text = text.toUpperCase();
        var tarifa = "";
        switch (text) {
            case "001":
                tarifa = "2.0A";
                break;
            case "003":
                tarifa = "3.0A";
                break;
            case "004":
                tarifa = "2.0DHA";
                break;
            case "005":
                tarifa = "2.1A";
                break;
            case "006":
                tarifa = "2.1DHA";
                break;
            case "007":
                tarifa = "2.0DHS";
                break;
            case "008":
                tarifa = "2.1DHS";
                break;
            case "011":
                tarifa = "3.1A";
                break;
            case "012":
                tarifa = "6.1A";
                break;
            case "013":
                tarifa = "6.2";
                break;
            case "014":
                tarifa = "6.3";
                break;
            case "015":
                tarifa = "6.4";
                break;
            case "016":
                tarifa = "6.5";
                break;
            case "017":
                tarifa = "6.1B";
                break;
            case "018":
                tarifa = "2.0TD";
                break;
            case "019":
                tarifa = "3.0TD";
                break;
            case "020":
                tarifa = "6.1TD";
                break;
            case "021":
                tarifa = "6.2TD";
                break;
            case "022":
                tarifa = "6.3TD";
                break;
            case "023":
                tarifa = "6.4TD";
                break;
        }
        return tarifa;
    };

    $scope.scrollToTop = function($var) {
        // 'html, body' denotes the html element, to go to any other custom element, use '#elementID'
        $('html, body').animate({
            scrollTop: 0
        }, 'fast'); // 'fast' is for fast animation
    };

    $scope.cpValidate = function() {
        if($scope.CodigoPostalPS.length == 5 && parseInt($scope.CodigoPostalPS) >= 1000 && parseInt($scope.CodigoPostalPS) <= 52999)
        {
            $scope.cpValidat = false;
            return;
        }
        $scope.cpValidat = true;

    }

}]);