app.controller('ComercialesCtrl', ['$scope', '$http', 'serviciosApi', '$location', '$rootScope', 'jwtHelper', function($scope, $http, serviciosApi, $location, $rootScope, jwtHelper) {
	'use strict';

    var Logueado = localStorage.getItem('logueado');

    $scope.filtrarAgente = function(datos) {
        $rootScope.filtradoAgente = datos.comercial.NombreAgente;
        $location.url("/contratos");
    };

    $scope.filtrarSubagente = function(datos) {
        $rootScope.filtradoSubagente = datos.comercial.NombreAgente;
        $location.url("/contratos");
    };

    if (Logueado == 'true') {

        $scope.MostrarComercialesIdAgente = MostrarComercialesIdAgente;
        $scope.MostrarComercialesNombreAgente = MostrarComercialesNombreAgente;
        $scope.MostrarComercialesDireccion = MostrarComercialesDireccion;
        $scope.MostrarComercialesCodigoPostal = MostrarComercialesCodigoPostal;
        $scope.MostrarComercialesCiudad = MostrarComercialesCiudad;
        $scope.MostrarComercialesTelefono = MostrarComercialesTelefono;
        $scope.MostrarComercialesMovil = MostrarComercialesMovil;
        $scope.MostrarComercialesEmail = MostrarComercialesEmail;
        $scope.MostrarComercialesWeb = MostrarComercialesWeb;
        $scope.MostrarComercialesIdAgenteGrupo = MostrarComercialesIdAgenteGrupo;
        $scope.MostrarComercialesNotas = MostrarComercialesNotas;
        $scope.MostrarComercialesCodigoTipoAgente = MostrarComercialesCodigoTipoAgente;
        $scope.MostrarComercialesEmailSolicitud = MostrarComercialesEmailSolicitud;
        $scope.MostrarComercialesCodigoVendedor = MostrarComercialesCodigoVendedor;

        $scope.autocolumn = [{
            name: "IdAgente",
            display: "Agente",
            oculta: "IdAgente"
        }, {
            name: "CodigoVendedor",
            display: "Referencia Comercial",
            oculta: "CodigoVendedor"
        }, {
            name: "NombreAgente",
            display: "Nombre",
            oculta: "NombreAgente"
        }, {
            name: "CodigoTipoAgente",
            display: "Tipo Agente",
            oculta: "CodigoTipoAgente"
        }, {
            name: "Ciudad",
            display: "Ciudad",
            oculta: "Ciudad"
        }, {
            name: "Direccion",
            display: "Dirección",
            oculta: "Direccion"
        }, {
            name: "CodigoPostal",
            display: "Código Postal",
            oculta: "CodigoPostal"
        }, {
            name: "Telefono",
            display: "Teléfono",
            oculta: "Telefono"
        }, {
            name: "Movil",
            display: "Móvil",
            oculta: "Movil"
        }, {
            name: "Email",
            display: "Email",
            oculta: "Email"
        }, {
            name: "Web",
            display: "Web",
            oculta: "Web"
        }, {
            name: "Notas",
            display: "Notas",
            oculta: "Notas"
        }, {
            name: "EmailSolicitud",
            display: "Email Solicitud",
            oculta: "EmailSolicitud"
        }];

		$scope.selected = [];

		$scope.exportarDatosComerciales = function(selected) {
			var comerciales = [];
			selected.forEach(function(obj) {
				switch (obj.CodigoTipoAgente) {
					case 1: obj.TipoAgente = "Distribuidor"; break;
					case 2: obj.TipoAgente = "Agente"; break;
					case 3: obj.TipoAgente = "Subagente"; break;
				}
				comerciales.push({
					'Id Agente': obj.IdAgente,
					'Referencia Canal': obj.CodigoVendedor,
					'Nombre': obj.NombreAgente,
					'Direccion': obj.Direccion,
					'Codigo Postal': obj.CodigoPostal,
					'Ciudad': obj.Ciudad,
					'Telefono': obj.Telefono,
					'Movil': obj.Movil,
					'Email': obj.email,
					'Web': obj.web,
					'Notas': obj.Notas,
					'Tipo Agente': obj.TipoAgente,
					'Email Solicitud': obj.EmailSolicitud
				});
			});
			/**
			 * Descargamos el excel limpio de arriba
			 */

			$("#dvjson")
			.excelexportjs({
				containerid: "dvjson",
				datatype: 'json',
				dataset: comerciales,
				columns: getColumns(comerciales)
			});
		};

		$scope.seleccionaTodos = function(){
			$scope.comerciales.forEach(function (item) {
				$scope.selected.push(item);
			});
		}
		$scope.deseleccionaTodos =  function(){
			$scope.selected = [];
		}

		$scope.recargarDatos =  function(){

			serviciosApi.getListadoComerciales()
			.then(function(response) {
				var respuesta = response.data;
				var decodeToken = jwtHelper.decodeToken(respuesta);
				var playloadJson = JSON.parse(decodeToken.iss);
				var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]	});

				var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
				if (tokenValido && !tokenExpirado) {
					$scope.comerciales = angular.copy(playloadJson);
				} else {
					$scope.comerciales = angular.copy("");
				}

			});
		}
    }

}]);