app.controller('CodigosPromocionalesCtrl', ['$scope', '$location', '$http', 'TraspasoLeadContrato', 'serviciosApi', '$rootScope', '$state', 'jwtHelper', '$mdEditDialog', '$mdDialog', function($scope, $location, $http, TraspasoLeadContrato, serviciosApi, $rootScope, $state, jwtHelper, $mdEditDialog, $mdDialog) {
    'use strict';

    var NombreAgente = atob(localStorage.getItem('nombreAgente'));
    var Logueado = localStorage.getItem('logueado');
    var TipoAgente = atob(localStorage.getItem('tipoAgente'));
    $scope.codigosPromocionales = "";
    
    // $scope.url = 'http://80.35.34.4:8383/formulario-altas/eleia/libs/'; // Desarrollo HTTP
    $scope.url = 'https://formularioalta.eleiaenergia.com/libs/'; // Real con HTTPS

    $scope.autocolumn = [{
        name: "CodigoEmpleado",
        display: "Codigo Empleado",
        oculta: "CodigoEmpleado"
    }, {
        name: "CodigoPromocional",
        display: "Codigo Promocional",
        oculta: "CodigoPromocional"
    }, {
        name: "Multiuso",
        display: "Multiuso",
        oculta: "Multiuso"
    }, {
        name: "Producto",
        display: "Producto",
        oculta: "Producto"
    }, {
        name: "Activo",
        display: "Activo",
        oculta: "Activo"
    }, 
     {
        name: "FechaAlta",
        display: "Fecha Alta",
        oculta: "FechaAlta"
    },{
        name: "FechaCaducidad",
        display: "Fecha Caducidad",
        oculta: "FechaCaducidad"
    }, {
        name: "CupsAlta",
        display: "Cups Alta",
        oculta: "Cups Alta"
    }, {
        name: "IdentificadorAlta",
        display: "Identificador Alta",
        oculta: "IdentificadorAlta"
    }, {
        name: "CheckGestionActivacion",
        display: "Check Gestión Activación",
        oculta: "CheckGestionActivacion"
    }, {
        name: "CheckFacturaElectronica",
        display: "Check Factura Electrónica",
        oculta: "CheckFacturaElectronica"
    }, {
        name: "CheckInformacionComercial",
        display: "Check Información Comercial",
        oculta: "CheckInformacionComercial"
    }];

    $scope.selected = [];


    if (Logueado == 'true') {


        // $.post("http://80.35.34.4:8383/formulario-altas/eleia/libs/codigoPromocionalController.php", { 
        //     dataType: 'json',
        //     data: '{"accion": "listado"}',
        //     }).then(function (response) {
        //     console.log(response);
        //  });
        $.ajax({
            type: "POST",
            url: $scope.url + 'codigoPromocionalController.php',
            dataType: 'json',
            async: true,
            data: '{"accion": "listado"}',
        }).done(function(response){
                $scope.codigosPromocionales = response;
                // console.log($scope.codigosPromocionales);
            })

        // $http.post("http://80.35.34.4:8383/formulario-altas/eleia/libs/codigoPromocionalController.php", {
        //     data: '{"accion": "listado"}',
        //     dataType: 'json',
        // }).success(function(respuesta){
        //         console.log(respuesta);
        //     });


    }

}]);