var app = angular.module("RDash", [
	"ui.bootstrap",
	"ui.router",
	"ngCookies",
	"ngMaterial",
	"md.data.table",
	"mwl.calendar",
	"ngAnimate",
	"colorpicker.module",
	"angularFileUpload",
	"ncy-angular-breadcrumb",
	"ngSanitize",
	"ngCsv",
	"angular-loading-bar",
	"ngIdle",
	"tc.chartjs",
	"chart.js",
    "angular-jwt",
    "ngMessages"
]);

app.factory('TraspasoLeadContrato', function(){
	return {
		Dni: '',
		Nombre: '',
		Apellidos: '',
		Email: '',
		Telefono: '',
		CodPostal: '',
		Poblacion: '',
		Provincia: '',
		NombreVia: '',
		NumeroVia: '',
		InformacionAdicional: '',
		Cups: '',
		TarifaAcceso: '',
		DireccionSuministro: '',
		NombreTarifa: '',
		P1: '',
		P2: '',
		P3: '',
		P4: '',
		P5: '',
		P6: '',
		FechaInsercion: '',
		Estado: '',
		Plataforma: ''
	};
});