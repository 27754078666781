app.filter('parseInt', function () {
    return function (string) {
        var string = parseFloat(string);
        return string;
    }
});

app.filter('diasRestantes', function () {
    return function (string) {
        var today = new Date;
        var numMes = string.split("/")[1];

        if((today.getMonth() +1) != parseFloat(numMes)){
            string = string + " (en 1 mes)";
         }else{
            var numDias = string.split("/")[0] - today.getDate();
            string = string + " (en "+  numDias +" días)";

         }
        return string;
    }
});