app.controller("ResumenContratosCtrl", ["$scope", '$location', "$http", "FileUploader", "jwtHelper", '$mdEditDialog', '$mdDialog', '$state', function($scope, $location, $http, FileUploader, jwtHelper, $mdEditDialog, $mdDialog, $state) {
    "use strict";

    var IdUsuario = localStorage.getItem("idUsuario");
    var IdAgente = localStorage.getItem('idAgente');
    var Contrato = atob(localStorage.getItem("numeroPoliza"));
    var Logueado = localStorage.getItem("logueado");
    var IdEmpresa = localStorage.getItem("idEmpresa");
    var IdCliente = localStorage.getItem("idCliente");
    var CodigoContrato = atob(localStorage.getItem("codigoContrato"));
	var Entorno = atob(localStorage.getItem('tipoAlta'));
    var TipoAgente = atob(localStorage.getItem('tipoAgente'));

    $scope.MostrarContratosFacturacionRevisionFacturas = MostrarContratosFacturacionRevisionFacturas;
	$scope.SimplificacionesTarifaAntiguaEleia = SimplificacionesTarifaAntiguaEleia;
    $scope.MostrarContratosGeneralObservaciones = MostrarContratosGeneralObservaciones;
    $scope.MostrarContratosFacturacionSegmentacion = MostrarContratosFacturacionSegmentacion;
    $scope.MostrarPreciosFijosVigentes = MostrarPreciosFijosVigentes;
    $scope.InsertarDocumentoPerfilPDF = InsertarDocumentoPerfilPDF;
    $scope.CondicionesContractuales = CondicionesContractuales;
    $scope.MostrarContratoFacturacionCuadroTarifaAcceso = MostrarContratoFacturacionCuadroTarifaAcceso;
    $scope.MostrarPeriodoFacturacion = MostrarPeriodoFacturacion;
    $scope.ContratoPerfilDocumentosBloqueadoEstado = ContratoPerfilDocumentosBloqueadoEstado;
    $scope.MostrarPotenciaMaxima = MostrarPotenciaMaxima;
    $scope.MostrarContratoPerfilFacturacionPerfilFacturacion = MostrarContratoPerfilFacturacionPerfilFacturacion;
    $scope.MostrarContratoPerfilFacturacionSistemaContable = MostrarContratoPerfilFacturacionSistemaContable;
    $scope.MostrarContratoFacturacionCuadroPrecioContrato = MostrarContratoFacturacionCuadroPrecioContrato;
    $scope.MostrarContratosSituacionWeb = MostrarContratosSituacionWeb;
    $scope.MostrarContratoPerfilComunicados = MostrarContratoPerfilComunicados;

    // COMUNICADOS
    $scope.MostrarComunicadosIdComunicado = MostrarComunicadosIdComunicado;
    $scope.MostrarComunicadosContrato = MostrarComunicadosContrato;
    $scope.MostrarComunicadosFechaBackOffice = MostrarComunicadosFechaBackOffice;
    $scope.MostrarComunicadosNombre = MostrarComunicadosNombre;
    $scope.MostrarComunicadosCUPS = MostrarComunicadosCUPS;
    $scope.MostrarComunicadosUsuario = MostrarComunicadosUsuario;
    $scope.MostrarComunicadosFechaRegistro = MostrarComunicadosFechaRegistro;
    $scope.MostrarComunicadosEstadobackoffice = MostrarComunicadosEstadobackoffice;
    $scope.MostrarComunicadosCategoria = MostrarComunicadosCategoria;
    $scope.MostrarComunicadosSubcategoria = MostrarComunicadosSubcategoria;
    $scope.comunicadosAbiertos = 0;

    $scope.MostrarPerfilContratoDocumentosFacturas = MostrarPerfilContratoDocumentosFacturas;

    // $scope.formulas = ["BS3", "DSV", "EXD", "FOE", "FP1", "IN7", "MDI", "MIN", "PC3", "PS3", "RT3", "RT4", "RT6", "SI3"]; // IÑIGO ME HA FACILITADO ESTO SON LAS FORMULAS
    $scope.formulas = ["FOE", "BS3", "CFP", "FP1", "BCE", "MDI", "PC3", "PS3", "RT4", "RT3", "RT6", "EXD", "IN7", "SI3"]; // IÑIGO ME HA FACILITADO ESTO SON LAS FORMULAS

    $scope.consumoAnual = 0;

    if (localStorage.getItem('activeTabContrato') != undefined && localStorage.getItem('activeTabContrato') != null) {
        $scope.ActiveTabIncidencias = true;
        localStorage.removeItem('activeTabContrato');
    } else {
        $scope.ActiveTabIncidencias = false;
    }

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') != null) {
        $scope.VerEnergia = true;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') != null) {
        $scope.VerGas = true;
    }

    $scope.borrarDocumentoPerfil = function(data) {
        var datosApi = { "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario, "IdEmpresa": IdEmpresa, "IdDocumento": data.Documento.IdDocumento };
        $http({
            method: "POST",
            url: urlApi + "Documento/BorrarDocumento",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador')),
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(datosApi)
        }).then(function(response) {
            $scope.showBorrado();
            $state.go("resumen_contratos", {}, { reload: true });

        });
    };

    $scope.fileNameChanged = function(ele) {
        var files = ele.files;
        var l = files.length;
        var namesArr = [];

        for (var i = 0; i < l; i++) {
            namesArr.push(files[i].name);
            var texto = document.getElementById("textoDocumentacion");
            texto.setAttribute("value", files[0].name);
        }
    };

    $scope.subirDocumentacion = function() {
        var file = document.getElementById('file-upload-contrato').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            var ficheroSplit = reader.result.split(',');
            var ficheroPdf = file.name;
            var extensionFichero = ficheroPdf.split('.');

            // var datosApi = { "DocPDF": ficheroSplit[1], "IdUsuario": IdUsuario, "IdEmpresa": IdEmpresa, "CodigoContrato": CodigoContrato, "NombrePDF": ficheroPdf, "Entorno": Entorno };
            var datosApi = { "Documento": ficheroSplit[1], "IdUsuario": IdUsuario, "IdEmpresa": IdEmpresa, "CodigoContrato": CodigoContrato, "NombreDocumento": ficheroPdf };
            $http({
                method: "POST",
                url: urlApi + "Documento/ImportDocumento",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador')),
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(datosApi)
            }).then(function(response) {
                $scope.showAlert();
                $state.go("resumen_contratos", {}, { reload: true });

            });

        };
    }

    $scope.showAlert = function(ev) {
        $mdDialog.show(
            $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('')
            .htmlContent('<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Documento insertado correctamente.</p>')
            .ariaLabel('Documento Insertado')
            .ok('Aceptar')
            .targetEvent(ev)
        );
    };

    $scope.showBorrado = function(ev) {
        $mdDialog.show(
            $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('')
            .htmlContent('<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Documento borrado correctamente.</p>')
            .ariaLabel('Documento Borrado')
            .ok('Aceptar')
            .targetEvent(ev)
        );
    };

    if (Logueado == 'true') {
		$scope.TipoAgente = TipoAgente;
        $scope.SubirFicheros = SubirFicheros;
		$scope.query = { order: '-creacion', limit: 15, page: 1 };
        $http({
            method: "GET",
            url: urlApi + "Contrato/GetContratoBag/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/IdContrato/" + Contrato,
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function successCallback(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            //VALIDACION CON LA PALABRA SECRETA DE LA API
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                alg: ["HS256"]
            });

            var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.contrato = playloadJson;

                //console.log('json contrato');
                //console.log($scope.contrato);
            } else {
                $scope.contrato = "";
            }

            $scope.NoFacturar = playloadJson.NoFacturar;

            if (playloadJson.Estimar == "N") {
                $scope.Estimar = false;
            } else {
                $scope.Estimar = true;
            }
            $scope.arrayPreciosPotenciaEnergia=playloadJson.PreciosPotencias;
            $scope.Revision = playloadJson.Revision;
            $scope.fee = playloadJson.Fee;
            $scope.BloquearInformacionComercial = playloadJson.NoEnviarInformacion;
            $scope.First = playloadJson.IsFirst;
            $scope.NoRenovarContrato = playloadJson.NoRenovar;
            $scope.ComunicarCambioPrecios = playloadJson.EnviarPrecios;
            $scope.ComunicarRenovacion = playloadJson.ComunicarRenovacion;
            $scope.NoCortable = playloadJson.NoCortable;
            $scope.BloquearTraspasoWeb = playloadJson.BloquearTraspasosWeb;
            $scope.SinGastosImpago = playloadJson.SinGastoImpago;

            $scope.consumoAnual = playloadJson.ConsumoEstimado;

            $scope.TextoImprimir = "";
            if (playloadJson.TipoImprimir == "E") {
                $scope.TextoImprimir = "Email";
            } else if (playloadJson.TipoImprimir == "P") {
                $scope.TextoImprimir = "Papel & email";
            } else if (playloadJson.TipoImprimir == "W") {
                $scope.TextoImprimir = "Web";
            } else if (playloadJson.TipoImprimir == "R") {
                $scope.TextoImprimir = "Recibo Bancario";
            } else if (playloadJson.TipoImprimir == "Q") {
                $scope.TextoImprimir = "Papel";
            }

            $http({
                method: "GET",
                url: urlApi + "Documento/GetDatosDocumento/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + playloadJson.CodigoContrato,
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador'))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);
                //VALIDACION CON LA PALABRA SECRETA DE LA API
                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {

                    $scope.Documentos = [];

					playloadJson.forEach(function(item, i, obj) {

                        if(item.IdDocumento != null && !item.Nombre.includes('Factura_9041N') && !item.Nombre.includes('Factura_9041A')){
                            // Si tiene la variable MostrarPerfilContratoDocumentosFacturas a falso no cargan las facturas en documentacion de contrato
                            if(!$scope.MostrarPerfilContratoDocumentosFacturas && item.Nombre.includes('Factura_')){
                                // $scope.Documentos.push(item);
                            }else{
                                $scope.Documentos.push(item);
                            }
                        } // cargo en el array los documentos válidos

                    });

                } else {
                    $scope.Documentos = "";
                }

            });

            $scope.verDocumento = function(data) {
                var doc = data.Documento.FileName.match(/(.*)\.(.*)/);
				var filename = doc[1];
				var extension = doc[2];
                $http({
                    method: "GET",
                    url: urlApi + "Documento/Test/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Documento/" + data.Documento.IdDocumento + "/Extension/" + extension,
                    responseType: "blob",
                    headers: { Authorization: atob(localStorage.getItem("TokenValidador")) }
                }).then(function successCallback(response) {
                    var respuesta = response.data;
                    var file = new Blob([respuesta], { type: "application/octet-stream" });
                    var a = document.createElement("a");
                    var fileURL = URL.createObjectURL(file);
                    a.download = filename + "." + extension;
                    a.href = fileURL;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    // window.open(fileURL);
                });
            };

            if($scope.MostrarContratoFacturacionCuadroPrecioContrato){
                $scope.getPreciosContrato();
            }

            if($scope.MostrarContratoPerfilComunicados){
                $scope.recargarDatosComunicados();
            }

        });
		$scope.limpiaDolar = function(string) {
			if(string != undefined && string != ''){
				var aux = string.replace(/[^a-zA-Z 0-9.()]+/g,"");
				return aux;
			} else {
				return string;
			}
		}
        $scope.recargarDatosComunicados =  function(){

            // 0 -> no filtra por agente, IdAgente -> filtra por el id agente
            $http({
                method: "GET",
                url: urlApi + "ComunicadoCanal/GetComunicadosAllContrato/Empresa/" + IdEmpresa + "/Agente/" + 0 + "/Contrato/" + Contrato,
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador'))
                }
            }).then(function(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);
                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]	});

                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
                if (tokenValido && !tokenExpirado) {
                    $scope.comunicadosContratoPerfil = angular.copy(playloadJson);
                    // console.log(playloadJson);
                    $scope.comunicadosAbiertos = 0;

                    //contamos los comunicados abiertos
                    $scope.comunicadosContratoPerfil.forEach( function(item, i, array) {

                        var numeroAcciones = 0;
                        var acciones = item.ListadoAcciones;

                        if(item.Estadobackoffice=="Abierto")
                            $scope.comunicadosAbiertos++;

                        // Recorremos las acciones del comunicado
                        acciones.forEach(function callback(accion, indexAccion, arrayAccion) {
                            if(accion.Estado == 'Abierto')
                                numeroAcciones++;
                        });

                        item.accionesAbiertas = numeroAcciones;

                    });


                } else {
                    $scope.comunicadosContratoPerfil = angular.copy("");
                }
            });
        }





        $scope.getPreciosContrato = function() {

            // console.log($scope.contrato);

            // TABLA PRECIOS ELEIA
            $scope.CalculoComision = 0;
            /* *************** Llamada para sacar ESPECIAL/NOESPECIAL - INDEXADO/NOINDEXADO ************** */
            $http({
                method: "POST",
                url: urlApi + "Tarifa/GetDatosEsEspecialIndexado",
                data: JSON.stringify({ "IdTarifa": $scope.contrato.idTarifa, "IdTarifaGrupo": $scope.contrato.idTarifaGrupo }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {

                    // $scope.muestraocultaprecio = true;

                    // Variables temporales para pruebas mientras se termina la llamada de API
                    $scope.especial = playloadJson[0].GrupoPrecioPropio;
                    $scope.precioIndexado = playloadJson[0].EsPrecioIndexado;

                    console.log("Especial: " + $scope.especial);
                    console.log("Precio indexado: " + $scope.precioIndexado);


                    // NO ESPECIAL Y PRECIO FIJO
                    if(!$scope.especial && !$scope.precioIndexado) {
                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioEnergia = true;

                        // console.log($scope.contrato.idTarifa + " " + " " + $scope.contrato.idTarifaGrupo);

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioFijoNoEspecial",
                            data: JSON.stringify({ "IdTarifa": $scope.contrato.idTarifa, "IdTarifaGrupo": $scope.contrato.idTarifaGrupo, "IdAgenteComision": IdAgente }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                //$scope.arrayPreciosPotenciaEnergia = playloadJson;
                                $scope.tipo = 'noespecial';
                                $scope.updateFeeValue($scope.fee,'noespecial');
                                // $scope.calcularTerminoPotenciaYEnergiaYTotal();
                                console.log("NO ESPECIAL Y PRECIO FIJO");
                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });

                    } else {
                        $scope.MostrarPrecioEnergia = false;
                    }


                    // ESPECIAL Y PRECIO INDEXADO
                    if($scope.especial && $scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioFEE = true;
                        // if($scope.consumoAnual != null){LimpiarConsumoanual = $scope.consumoAnual}

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioIndexadoEspecial",
                            data: JSON.stringify({ "IdAgente": IdAgente, "IdTarifa": $scope.contrato.idTarifa, "IdTarifaGrupo": $scope.contrato.idTarifaGrupo, "ConsumoAnualSIPS": Math.round($scope.consumoAnual) }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);
                            // console.log(playloadJson);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                // console.log($scope.arrayPreciosPotenciaEnergia);
                                $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
                                console.log("ESPECIAL Y PRECIO INDEXADO");
                                // alert("PRODUCTO ESPECIAL E INDEXADO");
                                // console.log(playloadJson);

                                // $scope.fee = $scope.MostrarPrecioFEEMaximo;
                                $scope.tipo = 'especial';
                                $scope.updateFeeValue($scope.fee,'especial');
                                $scope.MostrarAltaInfoFeeMinMax = true;

                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });

                    } else {
                        $scope.MostrarPrecioFEE = false;
                    }


                    // NO ESPECIAL Y PRECIO INDEXADO
                    if(!$scope.especial && $scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.feedisabled = true;
                        $scope.MostrarPrecioFEE = true;
                        $scope.MostrarAltaInfoFeeMinMax = false;

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioIndexadoNoEspecial",
                            data: JSON.stringify({ "IdAgenteComision": IdAgente, "IdTarifa": $scope.contrato.idTarifa, "IdTarifaGrupo": $scope.contrato.idTarifaGrupo }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                // $scope.arrayPreciosPotenciaEnergia = playloadJson;
                                // console.log($scope.arrayPreciosPotenciaEnergia);
                                //$scope.fee = playloadJson[0].datoFEE;
                                $scope.tipo = 'noespecial';
                                // $scope.updateFeeValue($scope.fee,'noespecial');
                                console.log("NO ESPECIAL Y PRECIO INDEXADO");
                                // alert("PRODUCTO NO ESPECIAL E INDEXADO");
                                // $scope.getPreciosIndexado();
                                // console.log(playloadJson);
                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });


                    } else {
                        $scope.feedisabled = false;
                    }


                    // ESPECIAL Y PRECIO FIJO
                    if($scope.especial && !$scope.precioIndexado) {
                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioEnergia = true;
                        $scope.MostrarPrecioFEE = true;
                        // if($scope.consumoAnual != null){LimpiarConsumoanual = $scope.consumoAnual}

                        // console.log("idTarifa: " +$scope.contrato.idTarifa + " " + "idTarifaGrupo: " + $scope.contrato.idTarifaGrupo);

                        //REUTILIZO LA LLAMADA DE NO ESPECIAL Y FIJO PARA COGER LOS PRECIOS DE POTENVIA Y ENERGIA
                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioFijoNoEspecial",
                            data: JSON.stringify({ "IdTarifa": $scope.contrato.idTarifa, "IdTarifaGrupo": $scope.contrato.idTarifaGrupo, "IdAgenteComision": IdAgente }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {


                                // $scope.arrayPreciosPotenciaEnergia = playloadJson;

                                // Recorremos los precios potencia y al ser especial sumamos el margen del comercial al precio de cada una de las potencias
                                $scope.arrayPreciosPotenciaEnergia.forEach( function(arrayPrecioPotenciaEnergia, i, array) {

                                    // console.log(arrayPrecioPotenciaEnergia.PrecioE + " + " +  ($scope.margen/1000));
                                    arrayPrecioPotenciaEnergia.PrecioE = arrayPrecioPotenciaEnergia.PrecioE + ($scope.margen/1000);
                                    // console.log(arrayPrecioPotenciaEnergia);

                                });

                                console.log("ESPECIAL Y PRECIO FIJO");
                                // $scope.calcularTerminoPotenciaYEnergiaYTotal();

                                // $scope.tipo = 'especial';
                                // $scope.updateFeeValue($scope.fee,'especial');

                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });


                        //REUTILIZO LA LLAMADA DE ESPECIA E INDEXADO, PARA COGER LOS DATOS DEL FEE Y EL PORCENTAJE
                        //console.log("REUTILIZO");
                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioIndexadoEspecial",
                            data: JSON.stringify({ "IdAgente": IdAgente, "IdTarifa": $scope.contrato.idTarifa, "IdTarifaGrupo": $scope.contrato.idTarifaGrupo, "ConsumoAnualSIPS": Math.round($scope.consumoAnual) }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            // console.log('Entra');
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);
                            // console.log(playloadJson);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
                                // console.log($scope.PorcentajesComision);
                                // console.log("ESPECIAL Y PRECIO INDEXADO");
                                // console.log(playloadJson);

                                //$scope.fee = $scope.MostrarPrecioFEEMaximo;
                                $scope.tipo = 'especial';
                                $scope.updateFeeValue($scope.fee,'especial');
                                $scope.MostrarAltaInfoFeeMinMax = true;

                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });



                    } else {
                        // $scope.MostrarPrecioEnergia = false;
                        // $scope.MostrarPrecioFEE = false;
                    }



                } else {
                    $scope.GruposTarifa = "";
                    console.error("HASH DE VERIFICACION INCORRECTO");
                }


                // Si su comercializadora tiene algun precio metido de potencia o energia recalculamos con las nuevas fechas
                // if(
                // ($scope.suComercializadoraPreciosPotencia[0]!= undefined && $scope.suComercializadoraPreciosPotencia[0]['PrecioP']) ||
                // ($scope.suComercializadoraPreciosPotencia[1]!= undefined && $scope.suComercializadoraPreciosPotencia[1]['PrecioP']) ||
                // ($scope.suComercializadoraPreciosPotencia[2]!= undefined && $scope.suComercializadoraPreciosPotencia[2]['PrecioP']) ||
                // ($scope.suComercializadoraPreciosPotencia[3]!= undefined && $scope.suComercializadoraPreciosPotencia[3]['PrecioP']) ||
                // ($scope.suComercializadoraPreciosPotencia[4]!= undefined && $scope.suComercializadoraPreciosPotencia[4]['PrecioP']) ||
                // ($scope.suComercializadoraPreciosPotencia[5]!= undefined && $scope.suComercializadoraPreciosPotencia[5]['PrecioP']) ||
                // ($scope.suComercializadoraPreciosPotencia[6]!= undefined && $scope.suComercializadoraPreciosPotencia[6]['PrecioP'])){

                //     $scope.calcularTerminoPotenciaYEnergiaYTotalSuyo();
                //     // console.log("¿entro?");

                // }


            }); // end then
        }

        $scope.updateFeeValue = function (datos,tipo) {

                $scope.fee = datos;

            if(tipo=='especial'){

                // console.log($scope.arrayPreciosPotenciaEnergia);

                // console.log($scope.MostrarPrecioFEEMinimo);
                // console.log($scope.MostrarPrecioFEEMaximo);
                // console.log("PorcentajesComision");
                // console.log($scope.PorcentajesComision);


                var flag = 0;
                // console.log($scope.PorcentajesComision);
                $scope.PorcentajesComision.forEach(function(item){

                    if($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]){
                        // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
                        $scope.CalculoComision = (($scope.consumoAnual*datos)/1000)*(item["PorcentajeComision"]/100);
                        $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                        $scope.CalculoComision = $scope.CalculoComision + "€";
                        $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                        // console.log($scope.CalculoComision);
                        flag = 1;
                    }
                });
                if(flag == 0){$scope.CalculoComision = "Datos no válidos";}
            }else if(tipo=='noespecial'){
                //    console.log($scope.arrayPreciosPotenciaEnergia);
                if($scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro != undefined && $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0] != undefined && $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0]){

                        var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
                        //console.log(valores);
                        if(valores.TipoAgenteParametro == 5){
                            //    console.log("Entro al 5");
                                $scope.CalculoComision = $scope.consumoAnual * valores.Valor;
                                $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                                $scope.CalculoComision = $scope.CalculoComision + "€";
                                $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                                // console.log($scope.CalculoComision);
                        }else if (valores.TipoAgenteParametro == 10){
                            //    console.log("Entro al 10");
                                $scope.CalculoComision = (($scope.consumoAnual * datos)/1000) * (valores.Valor/100);
                                $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                                $scope.CalculoComision = $scope.CalculoComision + "€";
                                $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                        }
                }
            }

        }

        // FUNCION PARA TRAERME LOS PRECIOS DE NO ESPECIAL INDEXADO
        $scope.getPreciosIndexado = function () {

            $scope.loading = true;



            $http({

                method: "POST",
                url: urlApi + "Comparador/Conceptos",
                data: JSON.stringify({ "IdTarifaGrupo": $scope.contrato.idTarifa }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                },
            }).then(function successCallback(formulas) {

                $scope.formulas = formulas.data;

                // CALL traer precios indexado en base al FEE las operaciones de iñigo y el cups
                $http({

                    method: "POST",
                    url: urlApi + "Comparador/Comparar",
                    data: JSON.stringify({ "CUPS": $scope.contrato.CodigoCUPS, "Fee": $scope.fee, "Formulas": $scope.formulas }),
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        Authorization: atob(localStorage.getItem("TokenValidador"))
                    },
                }).then(function successCallback(response) {
                    // console.log('Entra');
                    // console.log(JSON.parse(response.data));
                    // var respuesta = response.data;
                    // var decodeToken = jwtHelper.decodeToken(respuesta);
                    // var playloadJson = JSON.parse(decodeToken.iss);
                    // console.log(playloadJson);

                    // var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    //     alg: ["HS256"]
                    // });

                    // if (tokenValido) {
                        var contador = 0;
                        var sumatorioPrecioEnergiaP1 = 0;
                        var sumatorioPrecioEnergiaP2 = 0;
                        var sumatorioPrecioEnergiaP3 = 0;
                        var sumatorioPrecioEnergiaP4 = 0;
                        var sumatorioPrecioEnergiaP5 = 0;
                        var sumatorioPrecioEnergiaP6 = 0;
                        var preciosEnergiaIndexado = JSON.parse(response.data);


                        // Hacemos el sumatorio de los precios de todos los meses para despues dividirlos y sacar la media
                        preciosEnergiaIndexado.forEach( function(precioEnergiaIndexado, i, array) {
                            // console.log(precioEnergiaIndexado);
                            if(precioEnergiaIndexado.PrecioP1  != 0 ||
                            precioEnergiaIndexado.PrecioP2 != 0 ||
                            precioEnergiaIndexado.PrecioP3 != 0 ||
                            precioEnergiaIndexado.PrecioP4 != 0 ||
                            precioEnergiaIndexado.PrecioP5 != 0 ||
                            precioEnergiaIndexado.PrecioP6 != 0
                            ){
                                contador++;
                                sumatorioPrecioEnergiaP1 = sumatorioPrecioEnergiaP1 + precioEnergiaIndexado.PrecioP1;
                                sumatorioPrecioEnergiaP2 = sumatorioPrecioEnergiaP2 + precioEnergiaIndexado.PrecioP2;
                                sumatorioPrecioEnergiaP3 = sumatorioPrecioEnergiaP3 + precioEnergiaIndexado.PrecioP3;
                                sumatorioPrecioEnergiaP4 = sumatorioPrecioEnergiaP4 + precioEnergiaIndexado.PrecioP4;
                                sumatorioPrecioEnergiaP5 = sumatorioPrecioEnergiaP5 + precioEnergiaIndexado.PrecioP5;
                                sumatorioPrecioEnergiaP6 = sumatorioPrecioEnergiaP6 + precioEnergiaIndexado.PrecioP6;

                            }
                        });

                        // Divimos con contador que son el numero de meses con precio distinto de 0 y sacamos el precio medio
                        sumatorioPrecioEnergiaP1 = sumatorioPrecioEnergiaP1/contador;
                        sumatorioPrecioEnergiaP2 = sumatorioPrecioEnergiaP2/contador;
                        sumatorioPrecioEnergiaP3 = sumatorioPrecioEnergiaP3/contador;
                        sumatorioPrecioEnergiaP4 = sumatorioPrecioEnergiaP4/contador;
                        sumatorioPrecioEnergiaP5 = sumatorioPrecioEnergiaP5/contador;
                        sumatorioPrecioEnergiaP6 = sumatorioPrecioEnergiaP6/contador;

                        // console.log(sumatorioPrecioEnergiaP1);
                        // console.log(sumatorioPrecioEnergiaP2);
                        // console.log(sumatorioPrecioEnergiaP3);
                        // console.log(sumatorioPrecioEnergiaP4);
                        // console.log(sumatorioPrecioEnergiaP5);
                        // console.log(sumatorioPrecioEnergiaP6);

                        // console.log($scope.arrayPreciosPotenciaEnergia);

                        // Rellenamos el array a mostrar
                        $scope.arrayPreciosPotenciaEnergia.forEach( function(arrayPrecioPotenciaEnergia, i, array) {
                            if(arrayPrecioPotenciaEnergia.TextoPeriodo == "P1"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP1.toFixed(2); }
                            if(arrayPrecioPotenciaEnergia.TextoPeriodo== "P2"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP2; }
                            if(arrayPrecioPotenciaEnergia.TextoPeriodo == "P3"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP3; }
                            if(arrayPrecioPotenciaEnergia.TextoPeriodo == "P4"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP4; }
                            if(arrayPrecioPotenciaEnergia.TextoPeriodo == "P5"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP5; }
                            if(arrayPrecioPotenciaEnergia.TextoPeriodo == "P6"){ arrayPrecioPotenciaEnergia.PrecioE = sumatorioPrecioEnergiaP6; }

                            // arrayPrecioPotenciaEnergia.PrecioP = 0; // Limpiamos los precio potencia
                        })


                        // console.log("llego");
                        $scope.loading = false;
                        $scope.calcularTerminoPotenciaYEnergiaYTotal();

                    // } else {
                    //     $scope.ColectivosRepresentante = "";
                    //     console.error("HASH DE VERIFICACION INCORRECTO");
                    // }
                }, function errorCallback(response) {
                    $scope.loading = false;
                    // console.log(response);
                });

            }, function errorCallback(response) {
                $scope.loading = false;
                // console.log(response);
            });

        }

        $scope.selected = [];

        $scope.send = function(datos) {
            localStorage.setItem("cups", btoa(datos.contrato.CodigoCUPS));
            $location.url("/contratos/cups");
        };

        $scope.sendCliente = function(datos) {
            localStorage.setItem("identificador", btoa(datos.contrato.IdCliente));
            $location.url("/clientes/perfil");
        };

    $scope.autocolumnComunicados = [
        {
            name: "IdComunicado",
            display: "Expediente",
            oculta: "IdComunicado"

        }, {
            name: "Estadobackoffice",
            display: "Estado back office",
            oculta: "Estadobackoffice"
        }, {
            name: "CodigoContrato",
            display: "Codigo Contrato",
            oculta: "Contrato"
        }, {
            name: "Nombre",
            display: "Nombre",
            oculta: "Nombre"
        }, {
            name: "CodigoCUPS",
            display: "CUPS",
            oculta: "CUPS"
        }, {
            name: "Usuario",
            display: "Usuario",
            oculta: "Usuario"
        }, {
            name: "FechaHoraRegistro",
            display: "FechaRegistro",
            oculta: "FechaRegistro"
        }, {
            name: "FechaBackOffice",
            display: "FechaBackOffice",
            oculta: "FechaBackOffice"
        }, {
            name: "Categoria",
            display: "Categoria",
            oculta: "Categoria"
        }, {
            name: "Subcategoria",
            display: "Subcategoria",
            oculta: "Subcategoria"
        }
    ];


        $scope.autocolumnDocumentos = [
            { name: "nombre", display: "Nombre" },
            { name: "comentario", display: "Comentario" },
            { name: "nombrefichero", display: "Nombre Fichero" },
            { name: "tamano", display: "Tamaño" },
            { name: "creacion", display: "Creación" },
            { name: "modificacion", display: "Modificación" },
            { name: "borrardocumento", display: "Borrar Documento" }
        ];

        var tokenCookie = atob(localStorage.getItem("TokenValidador"));
        var uploader = ($scope.uploader = new FileUploader({
            // url: "./lib/php/upload_gestion_documental.php?IdUsuario=" + IdUsuario + "&IdEmpresa=" + IdEmpresa + "&IdCliente=" + IdCliente + "&CodigoContrato=" + CodigoContrato + "&api=" + urlApi
        }));

        uploader.filters.push({
            name: 'Extensions',
            fn: function(item, options) {
                var allowedExtensions = ['pdf', 'jpg', 'png', 'docx', 'xlsx', 'doc', 'xls', 'PDF'];
                var ext = item.name.split('.').pop(); //get the file extension

                return allowedExtensions.indexOf(ext) !== -1;
            }
        });

        uploader.onWhenAddingFileFailed = function(
            item,
            filter,
            options
        ) {};
        uploader.onAfterAddingFile = function(fileItem) {};
        uploader.onAfterAddingAll = function(addedFileItems) {};
        uploader.onBeforeUploadItem = function(item) {};
        uploader.onProgressItem = function(fileItem, progress) {};
        uploader.onProgressAll = function(progress) {};
        uploader.onSuccessItem = function(fileItem, response, status, headers) {};
        uploader.onErrorItem = function(fileItem, response, status, headers) {};
        uploader.onCancelItem = function(fileItem, response, status, headers) {};
        uploader.onCompleteItem = function(fileItem, response, status, headers) {};
        uploader.onCompleteAll = function() {
            $scope.showAlert();
            $state.go("resumen_contratos", {}, { reload: true });
        };


    }
}]);