app.controller('RenovacionesCtrl', ['$scope', '$location', '$http', 'TraspasoLeadContrato', 'serviciosApi', '$rootScope', '$state', 'jwtHelper', '$mdEditDialog', '$mdDialog', '$sce', function($scope, $location, $http, TraspasoLeadContrato, serviciosApi, $rootScope, $state, jwtHelper, $mdEditDialog, $mdDialog, $sce, $window) {
    'use strict';

    var IdAgente = localStorage.getItem('idAgente');
    var IdUsuario = localStorage.getItem('idUsuario');
    var Logueado = localStorage.getItem('logueado');
    var IdEmpresa = localStorage.getItem('idEmpresa');

    var VerEnergia;
    var VerGas;
    $scope.isClickedActualizarEstado=false;
    $scope.AgenteFinal=AgenteFinal;
    $scope.MostrarPotenciaMaxima=MostrarPotenciaMaxima;
    $scope.MostrarBotonDescargaExcelContratos = MostrarBotonDescargaExcelContratos;
    $scope.MostrarBotonDescargaMet = MostrarBotonDescargaMet;
	$scope.SimplificacionesTarifaAntiguaEleia = SimplificacionesTarifaAntiguaEleia;

    $scope.MostrarContratosComisionAnualEstimada = MostrarContratosComisionAnualEstimada;

    $scope.UrlApiCorrecionImprimirContratoBien = UrlApiCorrecionImprimirContratoBien;

    $scope.abrirContratoVentanaNueva = abrirContratoVentanaNueva;

    $scope.contratosPendientesDeActualizarEstado = [];

    $scope.VerEnergia = localStorage.getItem('idEmpresaEnergia');
    $scope.VerGas = localStorage.getItem('idEmpresaGas');

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') == 'true') {
        $scope.VerEnergia = true;
        $scope.VerGas = false;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') == 'true') {
        $scope.VerGas = true;
        $scope.VerEnergia = false;
    }
    $scope.ModalImportacionExcelMasiva = ModalImportacionExcelMasiva;
    $scope.ModalImportacionExcelMasivaFormatoLumar = ModalImportacionExcelMasivaFormatoLumar;

    $scope.actualizarEntradas= function(){
        location.reload();
    }
	$scope.visualizarContrato = function(idContrato) {
		$scope.mensajeCargaPDF = true;
        if($scope.abrirContratoVentanaNueva){

            // Abrir contrato en nueva ventana _blank
            window.open(
                (($scope.UrlApiCorrecionImprimirContratoBien != "") ? UrlApiCorrecionImprimirContratoBien : urlApi ) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + idContrato,
                "_blank"
            );

        }else{
            $http({
                method: "GET",
                url: (($scope.UrlApiCorrecionImprimirContratoBien != "") ?
                UrlApiCorrecionImprimirContratoBien : urlApi ) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + idContrato,
                responseType: "arraybuffer",
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
				$scope.mensajeCargaPDF = false;
                var respuesta = response.data;

                var file = new Blob([respuesta], { type: "application/pdf" });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
		}
    };
    $scope.MostrarContratosConsumoAnualEstimado = MostrarContratosConsumoAnualEstimado;
    $scope.MostrarContratosFacturas = MostrarContratosFacturas;
    $scope.MostrarContratosPDF = MostrarContratosPDF;
    $scope.MostrarContratosIdAgente = MostrarContratosIdAgente;
    $scope.MostrarContratosIdSubagente = MostrarContratosIdSubagente;
    $scope.MostrarContratosTarifaGrupo = MostrarContratosTarifaGrupo;
    $scope.MostrarContratosContrato = MostrarContratosContrato;
    $scope.MostrarContratosContratoDetalle = MostrarContratosContratoDetalle;
    $scope.MostrarContratosSituacion = MostrarContratosSituacion;
    $scope.MostrarContratosSituacionWeb = MostrarContratosSituacionWeb;
    $scope.SituacionCambio1 = SituacionCambio1;
    $scope.SituacionCambio1a = SituacionCambio1a;
    $scope.SituacionCambio2 = SituacionCambio2;
    $scope.SituacionCambio2a = SituacionCambio2a;
    $scope.SituacionCambio3 = SituacionCambio3;
    $scope.SituacionCambio3a = SituacionCambio3a;

    $scope.MostrarContratoBotonAltaElectricidad = MostrarContratoBotonAltaElectricidad;
    $scope.MostrarContratoBotonAltaGas = MostrarContratoBotonAltaGas;

    $scope.MostrarContratosDesplegableSituacion = MostrarContratosDesplegableSituacion;

    $scope.MostrarContratosNombre = MostrarContratosNombre;
    $scope.MostrarContratosDenominacion = MostrarContratosDenominacion;
    $scope.MostrarContratosRazonSocial = MostrarContratosRazonSocial;
    $scope.MostrarContratosNombreIndependiente = MostrarContratosNombreIndependiente;
    $scope.MostrarContratosApellidoUno = MostrarContratosApellidoUno;
    $scope.MostrarContratosApellidoDos = MostrarContratosApellidoDos;
    $scope.MostrarContratosCUPS = MostrarContratosCUPS;
    $scope.MostrarContratosDireccionCUPS = MostrarContratosDireccionCUPS;
    $scope.MostrarContratosCiudadCUPS = MostrarContratosCiudadCUPS;
    $scope.MostrarContratosFechaContrato = MostrarListadoContratosColumnaFechaContrato;
    $scope.MostrarContratosFechaPActivacion = MostrarListadoContratosColumnaFechaPActivacion;
    $scope.MostrarContratosFechaAlta = MostrarContratosFechaAlta;
    $scope.MostrarContratosFechaBaja = MostrarContratosFechaBaja;
    $scope.MostrarContratosFechaVto = MostrarContratosFechaVto;
    $scope.MostrarContratosPotencia = MostrarContratosPotencia;
    $scope.MostrarContratosCaudal = MostrarContratosCaudal;
    $scope.MostrarContratosTarifa = MostrarContratosTarifa;
    $scope.MostrarContratosDeuda = MostrarContratosDeuda;
    $scope.MostrarContratosValorCO = MostrarContratosValorCO;
    $scope.MostrarContratosDniCif = MostrarContratosDniCif;
    $scope.MostrarContratosEnlaceDniCif = MostrarContratosEnlaceDniCif;
    $scope.MostrarContratosCategoriaCliente = MostrarContratosCategoriaCliente;


    $scope.MostrarContratosEmail = MostrarContratosEmail;
    $scope.MostrarContratosTelefono = MostrarContratosTelefono;
    $scope.MostrarContratosMovil = MostrarContratosMovil;

    $scope.MostrarContratosAgenteFinal = MostrarContratosAgenteFinal;


    $scope.MostrarContratosEnvioEmail = MostrarContratosEnvioEmail;


    $scope.TraspasoLeadContrato = TraspasoLeadContrato;

    $scope.AvisosCertificados = AvisosCertificados;
    $scope.MostrarContratosPorMes = MostrarContratosPorMes;
	$scope.MostrarContratosProximaRenovacion = MostrarContratosProximaRenovacion;

    $scope.MostrarContratosNombreParticionado = MostrarContratosNombreParticionado;
    $scope.MostrarContratosSituacionWeb = MostrarContratosSituacionWeb;
    $scope.camposAdicionalesListadoContratoOvo = camposAdicionalesListadoContratoOvo;
    $scope.MostrarContratosCPCUPS = camposAdicionalesListadoContratoOvo;
    $scope.MostrarContratosFechaContratoNueva = camposAdicionalesListadoContratoOvo;
	$scope.CambioAgenteXCanalEleia = CambioAgenteXCanalEleia;
	$scope.CambioTextoAgenteXCanalEleia = ($scope.CambioAgenteXCanalEleia) ? "Canal": "Agente";
    $scope.MostrarOrdenacionCPCUPS = '';
    $scope.MostrarOrdenacionFechaContratoNueva = '';
    $scope.mensajeCargaPDF = false;
	$scope.RenovacionHecha = false;
	$scope.procesandoRenovacion = false;

    $scope.updateSubEstadoRenovacion = function(IdRenovacion) {
		if(IdRenovacion.SubEstado > 0) {
			serviciosApi.postUpdateSubestado(IdRenovacion.SubEstado)
				.then(function(response) {
					$scope.getListadoRenovaciones(false, false);
				});
		}
	}
	$scope.sendContratoRenovacion = function(datos) {
        localStorage.setItem('numeroPoliza', btoa(datos.contrato['IdContrato']));
        localStorage.setItem("idCliente", btoa(datos.contrato['IdCliente']));
        localStorage.setItem("codigoContrato", btoa(datos.contrato['CodigoContrato']));
        $location.url("/contratos/resumen");
    };
	$scope.restaCustomizadaEleia = function() {
		if(restaCargosComparadorEleia && !$scope.VerGas){
			console.log($scope.arrayPreciosPotenciaEnergia);
			console.log($scope.headersRenovacionInd.Tarifa);
			if ($scope.headersRenovacionInd.Tarifa == '2.0TD ML'){
				$scope.arrayPreciosPotenciaEnergia[0].PrecioP < 0.018962? $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioP = ($scope.arrayPreciosPotenciaEnergia[0].PrecioP - 0.018962).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioP < 0.001220? $scope.arrayPreciosPotenciaEnergia[2].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioP = ($scope.arrayPreciosPotenciaEnergia[2].PrecioP - 0.001220).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[0].PrecioE < 0.101607? $scope.arrayPreciosPotenciaEnergia[0].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioE = ($scope.arrayPreciosPotenciaEnergia[0].PrecioE - 0.101607).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioE < 0.020321? $scope.arrayPreciosPotenciaEnergia[1].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioE = ($scope.arrayPreciosPotenciaEnergia[1].PrecioE - 0.020321).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioE < 0.005080? $scope.arrayPreciosPotenciaEnergia[2].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioE = ($scope.arrayPreciosPotenciaEnergia[2].PrecioE - 0.005080).toFixed(8);
			} else if ($scope.headersRenovacionInd.Tarifa == '3.0TD ML') {
				$scope.arrayPreciosPotenciaEnergia[0].PrecioP < 0.023562? $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioP = ($scope.arrayPreciosPotenciaEnergia[0].PrecioP - 0.023562).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioP < 0.011791? $scope.arrayPreciosPotenciaEnergia[1].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioP = ($scope.arrayPreciosPotenciaEnergia[1].PrecioP - 0.011791).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioP < 0.008567? $scope.arrayPreciosPotenciaEnergia[2].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioP = ($scope.arrayPreciosPotenciaEnergia[2].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioP < 0.008567? $scope.arrayPreciosPotenciaEnergia[3].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioP = ($scope.arrayPreciosPotenciaEnergia[3].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioP < 0.008567? $scope.arrayPreciosPotenciaEnergia[4].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioP = ($scope.arrayPreciosPotenciaEnergia[4].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioP < 0.003927? $scope.arrayPreciosPotenciaEnergia[5].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioP = ($scope.arrayPreciosPotenciaEnergia[5].PrecioP - 0.003927).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[0].PrecioE < 0.056643? $scope.arrayPreciosPotenciaEnergia[0].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioE = ($scope.arrayPreciosPotenciaEnergia[0].PrecioE - 0.056643).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioE < 0.041940? $scope.arrayPreciosPotenciaEnergia[1].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioE = ($scope.arrayPreciosPotenciaEnergia[1].PrecioE - 0.041940).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioE < 0.022657? $scope.arrayPreciosPotenciaEnergia[2].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioE = ($scope.arrayPreciosPotenciaEnergia[2].PrecioE - 0.022657).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioE < 0.011328? $scope.arrayPreciosPotenciaEnergia[3].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioE = ($scope.arrayPreciosPotenciaEnergia[3].PrecioE - 0.011328).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioE < 0.007262? $scope.arrayPreciosPotenciaEnergia[4].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioE = ($scope.arrayPreciosPotenciaEnergia[4].PrecioE - 0.007262).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioE < 0.004532? $scope.arrayPreciosPotenciaEnergia[5].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioE = ($scope.arrayPreciosPotenciaEnergia[5].PrecioE - 0.004532).toFixed(8);
			} else if($scope.headersRenovacionInd.Tarifa == '6.1TD ML') {
				$scope.arrayPreciosPotenciaEnergia[0].PrecioP < 0.024459? $scope.arrayPreciosPotenciaEnergia[0].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioP = ($scope.arrayPreciosPotenciaEnergia[0].PrecioP - 0.024459).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioP < 0.012240? $scope.arrayPreciosPotenciaEnergia[1].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioP = ($scope.arrayPreciosPotenciaEnergia[1].PrecioP - 0.012240).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioP < 0.008894? $scope.arrayPreciosPotenciaEnergia[2].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioP = ($scope.arrayPreciosPotenciaEnergia[2].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioP < 0.008894? $scope.arrayPreciosPotenciaEnergia[3].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioP = ($scope.arrayPreciosPotenciaEnergia[3].PrecioP - 0.008567).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioP < 0.008894? $scope.arrayPreciosPotenciaEnergia[4].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioP = ($scope.arrayPreciosPotenciaEnergia[4].PrecioP - 0.008894).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioP < 0.004076? $scope.arrayPreciosPotenciaEnergia[5].PrecioP = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioP = ($scope.arrayPreciosPotenciaEnergia[5].PrecioP - 0.004076).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[0].PrecioE < 0.030800? $scope.arrayPreciosPotenciaEnergia[0].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[0].PrecioE = ($scope.arrayPreciosPotenciaEnergia[0].PrecioE - 0.030800).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[1].PrecioE < 0.022815? $scope.arrayPreciosPotenciaEnergia[1].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[1].PrecioE = ($scope.arrayPreciosPotenciaEnergia[1].PrecioE - 0.022815).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[2].PrecioE < 0.012320? $scope.arrayPreciosPotenciaEnergia[2].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[2].PrecioE = ($scope.arrayPreciosPotenciaEnergia[2].PrecioE - 0.012320).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[3].PrecioE < 0.006160? $scope.arrayPreciosPotenciaEnergia[3].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[3].PrecioE = ($scope.arrayPreciosPotenciaEnergia[3].PrecioE - 0.006160).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[4].PrecioE < 0.003948? $scope.arrayPreciosPotenciaEnergia[4].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[4].PrecioE = ($scope.arrayPreciosPotenciaEnergia[4].PrecioE - 0.003948).toFixed(8);
				$scope.arrayPreciosPotenciaEnergia[5].PrecioE < 0.002464? $scope.arrayPreciosPotenciaEnergia[5].PrecioE = 0: $scope.arrayPreciosPotenciaEnergia[5].PrecioE = ($scope.arrayPreciosPotenciaEnergia[5].PrecioE - 0.002464).toFixed(8);
			}
		}
	}
    $scope.refrescarRenovaciones = function() {
		if (localStorage.getItem('controlRefresh') != undefined && localStorage.getItem('controlRefresh') == 'true') {
			serviciosApi.getListadoRenovaciones()
				.then(function(response) {
					var respuesta = response.data;
					var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);
					//VALIDACION CON LA PALABRA SECRETA DE LA API
					var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
						alg: ["HS256"]
							//verifyAt: IntDate.get('20150601000000Z')
					});
					var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
					var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
					if (tokenValido && !tokenExpirado) {
						$scope.renovaciones = playloadJson;
						// console.log($scope.renovaciones);
					} else {
						$scope.renovaciones = "";
					}
				});
		}
        localStorage.removeItem('controlRefresh'); //borro la cookie para que no se mantenga siempre con valor true
    }

    $scope.selected = [];

// CON FILTRO DE VARIABLE
    //multisearch

    // Columnas con nombre partido en filtros | Nombre/razon social | apellido1 | apellido2 |
		$scope.autocolumn = []

		$scope.autocolumn.push(
	{
		name: "Renovar",
		display: "Renovar",
		oculta: "Renovar",
		ordenar: ""
	}, {
		name: "CanalAgente",
		display: "Canal Agente",
		oculta: "IdAgente",
		ordenar: "CanalAgente"
	}, {
		name: "ComercialSubAgente",
		display: "Comercial SubAgente",
		oculta: "IdSubagente",
		ordenar: "ComercialSubAgente"
	}, {
		name: "FechaVtoOriginal",
		display: "Fecha Vencimiento Original",
		oculta: "FechaVtoOriginal",
		ordenar: "FechaVtoOriginal"
	}, {
		name: "productoOriginal",
		display: "Producto Original",
		oculta: "productoOriginal",
		ordenar: "productoOriginal"
	}, {
		name: "Tarifa",
		display: "Tarifa Acceso",
		oculta: "Tarifa",
		ordenar: "Tarifa"
	}, {
		name: "CodigoContratoOriginal",
		display: "Contrato Original",
		oculta: "ContratoOriginal",
		ordenar: "CodigoContratoOriginal"
	},{
		name: "CUPS",
		display: "CUPS",
		oculta: "CUPS",
		ordenar: "CUPS"
	}, {
		name: "EstadoRenovacion",
		display: "Estado Renovacion",
		oculta: "EstadoRenovacion",
		ordenar: "EstadoRenovacion"
	}, {
		name: "ConsumoAnual",
		display: "Consumo Anual",
		oculta: "ConsumoAnual",
		ordenar: "ConsumoAnual"
	}, {
		name: "RazonSocial",
		display: "Razón Social",
		oculta: "RazonSocial",
		ordenar: "RazonSocial"
	}, {
		name: "producto",
		display: "Producto Propuesto",
		oculta: "producto",
		ordenar: "producto"
	}, {
		name: "CodigoContrato",
		display: "Contrato",
		oculta: "Contrato",
		ordenar: "CodigoContrato"
	});
	// console.log($scope.autocolumn);

	$scope.seleccionaTodos = function(renovacionesFound){
		$scope.selected = [];
		renovacionesFound.forEach(function (item) {
			$scope.selected.push(item);
		});
	}

	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

	$scope.recargarDatos =  function(){
		$scope.getListadoRenovaciones(false,false);
	}
	$scope.exportarDatosRenovaciones = function(selected) {
        var contratos = [];

		/**
		 * Se filtran aqui, si los campos estan en la vista, los añadimos aqui y aparecen en la descarga.
		 */
            selected.forEach(function(obj,key) {
                // console.log(obj);

				var pasarFechaAlta = false;
				if($scope.situacionesContrato){
					$scope.situacionesContrato.forEach(function(obj2,key2) {
						if(obj.Situacion == obj2.TextoSituacion && (obj2.IsActivo || obj2.IsEstadoBaja)) {
							pasarFechaAlta = true;
						}
					});
				}
				contratos.push({
					'Canal': obj.CanalAgente,
					'SubAgente': obj.ComercialSubAgente,
					'Fecha Vencimiento Original': obj.FechaVtoOriginalFormat,
					'Producto Original': obj.productoOriginal,
					'Estado Renovacion': obj.EstadoRenovacion,
					'Tarifa': obj.Tarifa,
					'Codigo Contrato Original': obj.CodigoContratoOriginal,
					'CUPS': obj.CUPS,
					'ConsumoAnual': obj.ConsumoAnual,
					'Razon Social': obj.RazonSocial,
					'Producto': obj.producto,
					'Codigo Contrato': obj.CodigoContrato,

				});
                console.log(obj);

            });

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
		.excelexportjs({
		containerid: "dvjson",
		datatype: 'json',
		dataset: contratos,
		columns: getColumns(contratos)
		});
    };
	$scope.renovacionIndividual = function(renovacionInd){
		console.log(renovacionInd);
	}
	$scope.renovacionMultiple = function(renovacionesMultiples){
		var idsRenovacionMultiple = [];
		renovacionesMultiples.forEach(function (item) {
			idsRenovacionMultiple.push(item.IdContrato);
		});
		serviciosApi.PostRenovacionesMultiples(idsRenovacionMultiple)
			.then(function(response) {
				var respuesta = response.data;
				var decodeToken = jwtHelper.decodeToken(respuesta);
				var playloadJson = JSON.parse(decodeToken.iss);
				//VALIDACION CON LA PALABRA SECRETA DE LA API
				var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
					alg: ["HS256"]
						//verifyAt: IntDate.get('20150601000000Z')
				});
				var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
				var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
				$scope.getListadoRenovaciones(true, false);
				if (tokenValido && !tokenExpirado) {
					$scope.RenovacionHecha = true;
					//Mostrar mensaje OK en pantalla
					// if(playloadJson == 'Contratos Renovados Correctamente'){}
				} else {
					//Mostrar mensaje KO en pantalla
				}
			});
	}

	$scope.updateFeeValue = function (datos,tipo) {

        $scope.fee = datos;

       if(tipo=='especial'){

        // console.log($scope.arrayPreciosPotenciaEnergia);

        // console.log($scope.MostrarPrecioFEEMinimo);
        // console.log($scope.MostrarPrecioFEEMaximo);
        // console.log("PorcentajesComision");
        // console.log($scope.PorcentajesComision);


		var flag = 0;
		$scope.informacionCO = false;
		$scope.bloqueoContinuarRenovacion = false;
        // console.log($scope.PorcentajesComision);
        $scope.PorcentajesComision.forEach(function(item){
            if($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]){
                // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
                $scope.CalculoComision = (($scope.consumoAnual*datos)/1000)*(item["PorcentajeComision"]/100);
                $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                $scope.CalculoComision = $scope.CalculoComision + "€";
                $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                // console.log($scope.CalculoComision);
                flag = 1;
            }
        });
		if(flag == 0){
			$scope.CalculoComision = "Datos no válidos";
			$scope.informacionCO = true;
			$scope.bloqueoContinuarRenovacion = true;
		}
       }else if(tipo=='noespecial'){
			$scope.informacionCO = false;
			$scope.bloqueoContinuarRenovacion = false;
			if(!$scope.precioIndexado && !$scope.especial){
				$scope.fee = 0;
			}
            var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
                // console.log(valores);
            if(valores!=undefined){
                if(valores.TipoAgenteParametro == 5){
                    //    console.log("Entro al 5");
                        $scope.CalculoComision = $scope.consumoAnual * valores.Valor;
                        $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                        $scope.CalculoComision = $scope.CalculoComision + "€";
                        $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                        // console.log($scope.CalculoComision);
                }else if (valores.TipoAgenteParametro == 10){
                    //    console.log("Entro al 10");
                        $scope.CalculoComision = (($scope.consumoAnual * datos)/1000) * (valores.Valor/100);
                        $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                        $scope.CalculoComision = $scope.CalculoComision + "€";
                        $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                }
			}
       }

    }

    // TODO: FEE[potencia][energia] POR PERIODOS
    $scope.updateFeeValuePorPeriodos = function (datos,tipo) {

        // $scope.fee = datos;
        console.log("tipo");
        console.log(tipo);
        console.log($scope.Tarifa);
        console.log("$scope.TarifaAccesoElectricidad.$viewValue");
        console.log($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue);

        // En caso de que no encuentre información del CUPS en el SIPS lo tomamos de la seleccion del input
        // if($scope.Tarifa == undefined && $scope.altaContratoForm.TarifaAccesoElectricidad){
        if($scope.altaContratoForm.TarifaAccesoElectricidad){
            var objeto = JSON.parse($scope.altaContratoForm.TarifaAccesoElectricidad.$viewValue);
            // var TextoTarifaAcceso = objeto.TextoTarifa.replace(" ML", "");
            $scope.Tarifa = objeto.TextoTarifa;
            // var TarifaGrupo = $scope.altaContratoForm.GrupoTarifa.$viewValue.TextoTarifaGrupo;
            // console.log(TextoTarifaAcceso);
            // console.log(Tarifa);
            // console.log(TarifaGrupo);
        }

       if(tipo=='especial'){

        // console.log($scope.arrayPreciosPotenciaEnergia);

        // console.log($scope.MostrarPrecioFEEMinimo);
        // console.log($scope.MostrarPrecioFEEMaximo);
        // console.log("PorcentajesComision");
        // console.log($scope.PorcentajesComision);

        // console.log($scope.ConsumoEstimadoP1);
        // console.log($scope.ConsumoEstimadoP2);
        // console.log($scope.ConsumoEstimadoP3);
        // console.log($scope.ConsumoEstimadoP4);
        // console.log($scope.ConsumoEstimadoP5);
        // console.log($scope.ConsumoEstimadoP6);

        $scope.CalculoComisionEnergia = 0;
        $scope.CalculoComisionPotencia = 0;


        $scope.arrayPreciosPotenciaEnergia.forEach(function(item,i){
            // console.log($scope.CalculoComision);
            // console.log(item.PrecioE);
            // console.log($scope["ConsumoEstimadoP"+(i+1)]);
            // console.log(item.PrecioE + ($scope.Efees[i]/1000));
            // CalculoComisionEnergia  = consumo estimado Px * precio energia Px + fee
            // $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+1)] * (item.PrecioE + ($scope.Efees[i]/1000) )) + $scope.CalculoComisionEnergia;

            // Asignamos 0 al fee potencia  en las siguientes tarifas ya que sige solo factura un periodo y asi lo han pedido
            if(($scope.Tarifa == '2.0DHA ML' || $scope.Tarifa == '2.1DHA ML' || $scope.Tarifa == '2.0DHS ML' || $scope.Tarifa == '2.1DHS ML') && i == 1){
                // $scope.Pfees[i] = $scope.Pfees[i-1];
                $scope.Pfees[i] = 0;
            }

            // PARA los  2.1 Y 2.0 se va a la posicion 2 del array de los consumos
            if($scope.Tarifa == '2.1A ML' || $scope.Tarifa == '2.0A ML'){

                $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+2)] * ($scope.Efees[i]/1000 )) + $scope.CalculoComisionEnergia;
                $scope.CalculoComisionPotencia = (parseFloat($scope.altaContratoForm["PotenciaP"+(i+2)].$viewValue) * $scope.Pfees[i] ) + $scope.CalculoComisionPotencia;

            }else{
                // console.log($scope["ConsumoEstimadoP"+(i+1)]);
                if(($scope.Tarifa == '2.0DHA ML' || $scope.Tarifa == '2.1DHA ML' || $scope.Tarifa == '2.0DHS ML' || $scope.Tarifa == '2.1DHS ML') && i == 1){
                    $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+2)] * ($scope.Efees[i]/1000 )) + $scope.CalculoComisionEnergia;
                }else{
                    $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+1)] * ($scope.Efees[i]/1000 )) + $scope.CalculoComisionEnergia;
                }
                $scope.CalculoComisionPotencia = ($scope.altaContratoForm["PotenciaP"+(i+1)].$viewValue * $scope.Pfees[i] ) + $scope.CalculoComisionPotencia;
            }

            // TODO: COMO ESTABA ANTES
            // if($scope.Tarifa == '2.1A ML' || $scope.Tarifa == '2.0A ML'){

            //     $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+2)] * (item.PrecioE + (($scope.Efees[i] * $scope.impuestoExtraPrecioEnergia)/1000) )) + $scope.CalculoComisionEnergia;
            //     $scope.CalculoComisionPotencia = ($scope.altaContratoForm["PotenciaP"+(i+1)].$viewValue * (item.PrecioP + ($scope.Pfees[i]/365) )) + $scope.CalculoComisionPotencia;

            // }else{

            //     $scope.CalculoComisionEnergia = ($scope["ConsumoEstimadoP"+(i+1)] * (item.PrecioE + (($scope.Efees[i] * $scope.impuestoExtraPrecioEnergia)/1000) )) + $scope.CalculoComisionEnergia;
            //     $scope.CalculoComisionPotencia = ($scope.altaContratoForm["PotenciaP"+(i+1)].$viewValue * (item.PrecioP + ($scope.Pfees[i]/365) )) + $scope.CalculoComisionPotencia;

            // }


            // console.log($scope.CalculoComisionPotencia );

            // console.log($scope.CalculoComision);

            // console.log(($scope["ConsumoEstimadoP"+(i+1)] * item.PrecioE))
            // console.log("------------------------");


        });


        // console.log($scope.PorcentajesComision);


        // console.log($scope.CalculoComisionPotencia);
        // console.log($scope.PorcentajesComision[0].PorcentajeComision);

        // TODO: EN CASO DEL FUTURO -> PORCENTAJE DINAMICO
        // $scope.PorcentajeComisionFijo = $scope.PorcentajesComision[?].PorcentajeComision

        // console.log($scope.CalculoComisionEnergia);
        // console.log($scope.CalculoComisionPotencia);

        if($scope.PorcentajeComision == 0 && $scope.PorcentajesComision[0] && $scope.PorcentajesComision[0].PorcentajeComision){
            $scope.PorcentajeComision = $scope.PorcentajesComision[0].PorcentajeComision;
        }
        // console.log("$scope.PorcentajeComision" + $scope.PorcentajeComision);

        // console.log("#" + ($scope.PorcentajeComisionFijo/100) + "#");

        console.log("$scope.PorcentajeComision");
        console.log($scope.PorcentajeComision);

        $scope.CalculoComisionEnergia = $scope.CalculoComisionEnergia * ($scope.PorcentajeComision/100) // dividimos para 100 para sacar el porcentaje
        $scope.CalculoComisionPotencia = $scope.CalculoComisionPotencia * ($scope.PorcentajeComision/100) // dividimos para 100 para sacar el porcentaje
        // $scope.CalculoComisionEnergia = $scope.CalculoComisionEnergia * $scope.PorcentajeComisionFijo; TODO: Porcentaje fijo, como antes
        // $scope.CalculoComisionPotencia = $scope.CalculoComisionPotencia * $scope.PorcentajeComisionFijo; TODO: Porcentaje fijo, como antes

        console.log("$scope.CalculoComisionEnergia");
        console.log($scope.CalculoComisionEnergia);
        console.log("$scope.CalculoComisionPotencia");
        console.log($scope.CalculoComisionPotencia);



        $scope.CalculoComision = $scope.CalculoComisionEnergia + $scope.CalculoComisionPotencia;

        $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
        $scope.CalculoComision = $scope.CalculoComision + "€";
        $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");


        // COMO ESTABA ANTES EN ELEIA COMPROBANDO EL RANG DE FEE ENTRE MIN Y MAX
        var flag = 0;
        // console.log($scope.PorcentajesComision);
        // $scope.PorcentajesComision.forEach(function(item){

        //     if($scope.fee >= item["FeeMin"] && $scope.fee <= item["FeeMax"]){
        //         // console.log("El porcentaje comsion es:" + item["PorcentajeComision"]);
        //         $scope.CalculoComision = (($scope.consumoAnual*datos)/1000)*(item["PorcentajeComision"]/100);
        //         $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
        //         $scope.CalculoComision = $scope.CalculoComision + "€";
        //         $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
        //         // console.log($scope.CalculoComision);
        //         flag = 1;
        //     }
        // });

        // if(flag == 0){$scope.CalculoComision = "Datos no válidos";}

       }else if(tipo=='noespecial'){
            var valores = $scope.arrayPreciosPotenciaEnergia[0].ValoresAgenteParametro[0];
                // console.log(valores);
            if(valores!=undefined){
                if(valores.TipoAgenteParametro == 5){
                    //    console.log("Entro al 5");
                        $scope.CalculoComision = $scope.consumoAnual * valores.Valor;
                        $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                        $scope.CalculoComision = $scope.CalculoComision + "€";
                        $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                        // console.log($scope.CalculoComision);
                }else if (valores.TipoAgenteParametro == 10){
                    //    console.log("Entro al 10");
                        $scope.CalculoComision = (($scope.consumoAnual * datos)/1000) * (valores.Valor/100);
                        $scope.CalculoComision = $scope.CalculoComision.toFixed(2);
                        $scope.CalculoComision = $scope.CalculoComision + "€";
                        $scope.CalculoComision = $scope.CalculoComision.replace(".", ",");
                }
            }
       }

	}
	$scope.procesarRenovaciones = function(){
		// console.log("Entro");
		console.log($scope.arrayPreciosPotenciaEnergia);
		$scope.DatosParaRenovacionIndividual = [];
		$scope.DatosParaRenovacionIndividual['Entorno'] = $scope.TarifaSeleccionadaRenovacion.Entorno;
		$scope.DatosParaRenovacionIndividual['CodigoContrato'] = $scope.headersRenovacionInd.CodigoContrato;
		$scope.DatosParaRenovacionIndividual['IdContrato'] = $scope.headersRenovacionInd.IdContrato;
		$scope.DatosParaRenovacionIndividual['IdTarifa'] = $scope.headersRenovacionInd.IdTarifa;
		$scope.DatosParaRenovacionIndividual['FechaActivacion'] = $scope.headersRenovacionInd.FechaActivacion;
		$scope.DatosParaRenovacionIndividual['FechaVtoContrato'] = $scope.headersRenovacionInd.FechaVto;
		$scope.DatosParaRenovacionIndividual['MesesRenovacion'] = $scope.MesesRenovacion;
		$scope.DatosParaRenovacionIndividual['Email'] = $scope.headersRenovacionInd.CorreoCliente;
		$scope.DatosParaRenovacionIndividual['Margen'] = $scope.fee ? $scope.fee : 0,
		$scope.arrayPreciosPotenciaEnergia.forEach(function(item,i){
			//Paso las potencias relevantes para la tarifa
			$scope.DatosParaRenovacionIndividual[item['txtPrecioP']] = item['PrecioE'];
		});
		$scope.DatosParaRenovacionIndividual['IdTarifaGrupo'] = $scope.TarifaSeleccionadaRenovacion.IdTarifaGrupo;//PRODUCTO SELECCIONADA $scope.headersRenovacionInd.CodigoContrato;
		$scope.DatosParaRenovacionIndividual['TextoTarifaGrupo'] = $scope.TarifaSeleccionadaRenovacion.TextoTarifaGrupo;//PRODUCTO SELECCIONADA $scope.headersRenovacionInd.CodigoContrato;
		$scope.DatosParaRenovacionIndividual['IdPerfilFacturacion'] = $scope.TarifaSeleccionadaRenovacion.IdPerfilFacturacion;//PerfilFacturacion SELECCIONADA $scope.headersRenovacionInd.CodigoContrato;
		console.log($scope.DatosParaRenovacionIndividual);
		//Nueva llamada
		serviciosApi.PostRenovacionContratoIndividualProducto($scope.DatosParaRenovacionIndividual)
			.then(function(response) {
				$scope.showRenovacionContratos2($scope.DatosParaRenovacionIndividual);
				$mdDialog.cancel();
				// var respuesta = response.data;
				// if (respuesta == "Renovacion Realizada Correctamente") {
				// // CERRAR POPUP y refrescar listado
				// 	// $mdDialog.cancel();
				// 	//SI PDF O IMPRIMIR,GENERAMOS PDF
				// 	if(2 == datosParaRenovacion['AccionRenovacion'] || 3 == datosParaRenovacion['AccionRenovacion']){
				// 		$scope.mensajeCargaPDF = true;
				// 		$scope.getListadoRenovaciones(true, true);
				// 		$scope.visualizarContrato(datosParaRenovacion['IdContrato']);
				// 	} else {
				// 		$scope.mensajeCargaPDF = false;
				// 		$scope.getListadoRenovaciones(true, false);
				// 	}
				// 	$scope.RenovacionHecha = true;
				// } else {
				// 	alert("Ha habido un error interno, por favor intentelo de nuevo más tarde.")
				// }
			});

	}
	$scope.updateProducto = function(datos){
		 // TABLA PRECIOS ELEIA
			$scope.CalculoComision = 0;
			$scope.TarifaSeleccionadaRenovacion = datos;
            /* *************** Llamada para sacar ESPECIAL/NOESPECIAL - INDEXADO/NOINDEXADO ************** */
            $http({
                method: "POST",
                url: urlApi + "Tarifa/GetDatosEsEspecialIndexado",
                data: JSON.stringify({ "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo }),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: atob(localStorage.getItem("TokenValidador"))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                //TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                // var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {

                    // $scope.muestraocultaprecio = true;

                    // Variables temporales para pruebas mientras se termina la llamada de API
                    $scope.especial = playloadJson[0].GrupoPrecioPropio;
                    $scope.precioIndexado = playloadJson[0].EsPrecioIndexado;

                    console.log("Especial: " + $scope.especial);
                    console.log("Precio indexado: " + $scope.precioIndexado);
					if ($scope.precioIndexado) {
						$scope.tipoProducto = "Indexado";
					} else {
						$scope.tipoProducto = "Fijo";
					}
                    // TODO: ASIGNACION DE MODELOS DE IMPRESION DE ELIA
                    if($scope.asignacionModelosImpresionEleia){
                        // CONTROLAMOS EL TIPO DE PRODUCTO INDEXADO / FIJO -> PARA ASI PODER ASIGNARLE UN MODELO DE CONTRATO DE IMPRESION
                        ($scope.precioIndexado) ? $scope.tipoModeloImpresion = "Indexado" : $scope.tipoModeloImpresion  = "Fijo";

                        $scope.ModelosContratoFiltrados = [];

                        // Solo cargara los modelos de impresion de contrato que contenga el texto: fijo o indexado para que posteriormente se puedan asignar en el alta
                        if($scope.tipoModeloImpresion !=""){

                            $scope.ModelosContratoTemporal.forEach(function(item, i) {
                                if(item.DescripcionModeloDeImpresion.includes($scope.tipoModeloImpresion) || item.DescripcionModeloDeImpresion.includes("Contrato General")){
                                    $scope.ModelosContratoFiltrados.push(item);
                                }
                            });
                        }

                        $scope.ModelosContrato = $scope.ModelosContratoFiltrados;
                    }
                    // TODO: */

                    // NO ESPECIAL Y PRECIO FIJO
                    if(!$scope.especial && !$scope.precioIndexado) {
                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioEnergia = true;

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioFijoNoEspecial",
                            data: JSON.stringify({ "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo, "IdAgenteComision": IdAgente }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                $scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
                                $scope.tipo = 'noespecial';
                                $scope.updateFeeValue($scope.fee,'noespecial');
                                // console.log("NO ESPECIAL Y PRECIO FIJO");
                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });

                    } else {
                        $scope.MostrarPrecioEnergia = false;
                    }
					var LimpiarConsumoanual = 0;

                    // ESPECIAL Y PRECIO INDEXADO
                    if($scope.especial && $scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioFEE = true;
                        // if($scope.altaContratoForm.consumoSIPS.$viewValue != null){LimpiarConsumoanual = $scope.altaContratoForm.consumoSIPS.$viewValue}

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioIndexadoEspecial",
                            data: JSON.stringify({ "IdAgente": IdAgente, "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo, "ConsumoAnualSIPS": Math.round(LimpiarConsumoanual) }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);
                            // console.log(playloadJson);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                $scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
                                // console.log($scope.arrayPreciosPotenciaEnergia);
                                $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
                                // console.log("ESPECIAL Y PRECIO INDEXADO");
                                // console.log(playloadJson);

                                $scope.fee = $scope.MostrarPrecioFEEMaximo;
                                $scope.tipo = 'especial';
                                $scope.updateFeeValue($scope.fee,'especial');
                                $scope.MostrarAltaInfoFeeMinMax = true;


                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });

                    } else {
                        $scope.MostrarPrecioFEE = false;
                    }


                    // NO ESPECIAL Y PRECIO INDEXADO
                    if(!$scope.especial && $scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.feedisabled = true;
                        $scope.MostrarPrecioFEE = true;
                        $scope.MostrarAltaInfoFeeMinMax = false;

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioIndexadoNoEspecial",
                            data: JSON.stringify({ "IdAgenteComision": IdAgente, "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {
                                $scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
                                $scope.fee = playloadJson[0].datoFEE;
                                $scope.tipo = 'noespecial';
                                $scope.updateFeeValue($scope.fee,'noespecial');
                                // console.log("NO ESPECIAL Y PRECIO INDEXADO");
                                // console.log(playloadJson);
                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });


                    } else {
                        $scope.feedisabled = false;
                    }



                    // ESPECIAL Y PRECIO FIJO
                    if($scope.especial && !$scope.precioIndexado) {

                        $scope.CalculoComision = 0;
                        $scope.MostrarPrecioEnergia = true;
                        $scope.MostrarPrecioFEE = true;
                        // if($scope.altaContratoForm.consumoSIPS.$viewValue != null){LimpiarConsumoanual = $scope.altaContratoForm.consumoSIPS.$viewValue}

                        $http({
                            method: "POST",
                            url: urlApi + "Tarifa/GetDatosPrecioFijoEspecial",
                            data: JSON.stringify({ "IdTarifa": datos.IdTarifa, "IdTarifaGrupo": datos.IdTarifaGrupo, "IdAgenteComision": IdAgente,
                            "IdAgente": IdAgente, "ConsumoAnualSIPS": Math.round(LimpiarConsumoanual) }),
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                Authorization: atob(localStorage.getItem("TokenValidador"))
                            }
                        }).then(function successCallback(response) {
                            var respuesta = response.data;
                            var decodeToken = jwtHelper.decodeToken(respuesta);
                            var playloadJson = JSON.parse(decodeToken.iss);

                            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                                alg: ["HS256"]
                            });

                            if (tokenValido) {

                                $scope.arrayPreciosPotenciaEnergia = playloadJson;
								$scope.restaCustomizadaEleia();
                                // console.log("ESPECIAL Y PRECIO FIJO");
                                // console.log(playloadJson);

                                $scope.MostrarPrecioFEEMinimo = playloadJson[0].FeeMin;
                                $scope.MostrarPrecioFEEMaximo = playloadJson[0].FeeMax;
                                $scope.PorcentajeComision = playloadJson[0].PorcentajeComision;
                                $scope.PorcentajesComision = playloadJson[0].PorecentajesComision;
                                // console.log($scope.PorcentajesComision);

                                $scope.fee = $scope.MostrarPrecioFEEMaximo;

								if($scope.RequerirFeeManual){
									$scope.fee = '';
								}
                                $scope.tipo = 'especial';
                                $scope.updateFeeValue($scope.fee,'especial');
                                $scope.MostrarAltaInfoFeeMinMax = true;

                                //TODO: MostrarAltaContratoTantosFeesComoPeriodos -> VAR: TRUE  | CLIENTES:[A.MADRID,...]
                                // METEMOS VALORES FIJOS PARA LOS CLIENTES QUE TENGAN A TRUE MostrarAltaContratoTantosFeesComoPeriodos EJ[ANTONIO MADRID,...]
                                if($scope.MostrarAltaContratoTantosFeesComoPeriodos){
                                    // $scope.MostrarPrecioFEEMinimo = 0;
                                    // $scope.MostrarPrecioFEEMaximo = 30;

                                    // Recorremos el array de precios para insertar por cada periodo el valor maximo del fee a los arrays de fee potencia y energia
                                    angular.forEach($scope.arrayPreciosPotenciaEnergia, function (value, key) {
                                        $scope.Pfees.push($scope.MostrarPrecioFEEMaximo);
                                        $scope.Efees.push($scope.MostrarPrecioFEEMaximo);
                                    });

                                    // CALCULAMOS LA COMISION
                                    // $scope.updateFeeValuePorPeriodos($scope.fee,'especial');

                                }


                            } else {
                                $scope.ColectivosRepresentante = "";
                                console.error("HASH DE VERIFICACION INCORRECTO");
                            }
                        });


                    } else {
                        // $scope.MostrarPrecioEnergia = false;
                        // $scope.MostrarPrecioFEE = false;
                    }


                } else {
                    $scope.GruposTarifa = "";
                    console.error("HASH DE VERIFICACION INCORRECTO");
                }

            }); // end then

	};
	$scope.limpiaDolar = function(string) {
		if(string != undefined && string != ''){
			var aux = string.replace(/[^a-zA-Z 0-9.()]+/g,"");
			return aux;
		} else {
			return string;
		}
	}
	$scope.updateOpcion = function(opcionSeleccionada) {
		if('4' == opcionSeleccionada) {
			$scope.mostrarEmailEnvio = true;
			$scope.emailInvalido = false;
			$scope.emailConditional = $scope.headersRenovacionInd.CorreoCliente;//"test@test.com";
		} else {
			$scope.mostrarEmailEnvio = false;
			$scope.emailInvalido = false;
		}
	};
	$scope.updateEmail = function(emailValidar){
		var regex = new RegExp('^[^@]+@[^@.]+.[A-Za-z]{2,}$');
		console.log(emailValidar);
		console.log(regex.test(emailValidar));
		if (regex.test(emailValidar)) {
			console.log("paso");
			$scope.emailInvalido = false;
		} else {
			console.log("NO paso");
			$scope.emailInvalido = true;
		}
	}
	$scope.sendToAPI = function(datosParaRenovacion){
		datosParaRenovacion['AccionRenovacion'] = $scope.AccionRenovacion;
		datosParaRenovacion['Email'] = $scope.emailConditional ? $scope.emailConditional : $scope.headersRenovacionInd.CorreoCliente;
		console.log(datosParaRenovacion);
		//LLAMAR API PARA RENOVAR, SI TODO OK
		$scope.procesandoRenovacion = true;
		serviciosApi.PostRenovacionContratoIndividual(datosParaRenovacion)
			.then(function(response) {
				$scope.procesandoRenovacion = false;
				var respuesta = response.data;
				if (respuesta == "Renovacion Realizada Correctamente") {
				// CERRAR POPUP y refrescar listado
					// $mdDialog.cancel();
					//SI PDF O IMPRIMIR,GENERAMOS PDF
					if(2 == datosParaRenovacion['AccionRenovacion'] || 3 == datosParaRenovacion['AccionRenovacion']){
						$scope.mensajeCargaPDF = true;
						$scope.getListadoRenovaciones(true, true);
						$scope.visualizarContrato(datosParaRenovacion['IdContrato']);
					} else {
						$scope.mensajeCargaPDF = false;
						$scope.getListadoRenovaciones(true, false);
					}
					$scope.RenovacionHecha = true;
				} else {
					alert("Ha habido un error interno, por favor intentelo de nuevo más tarde.")
				}
			});

	};
	$scope.showRenovacionContratos2 = function(datosApi) {
		$mdDialog.show({
			templateUrl: "modalRenovaciones2.html",
			scope: $scope,
			preserveScope: true,
			clickOutsideToClose: true,
			escapeToClose: true,
			controller: [
				"$scope",
				"$http",
				"jwtHelper",
				function ($scope, $http, jwtHelper) {
					$scope.datosAPIRenovacion = datosApi;
					console.log($scope.datosAPIRenovacion);

				}
			]});
		$scope.generandopdf = true;
		$http({
			method: "GET",
			url: (($scope.UrlApiCorrecionImprimirContratoBien != "") ?
			UrlApiCorrecionImprimirContratoBien : urlApi ) + "Contrato/ContratoPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Contrato/" + datosApi['IdContrato'],
			responseType: "arraybuffer",
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				Authorization: atob(localStorage.getItem("TokenValidador"))
			}
		}).then(function successCallback(response) {
			var respuesta = response.data;

			var file = new Blob([respuesta], { type: "application/pdf" });
			var fileURL = URL.createObjectURL(file);
			$scope.pdfCondiciones = $sce.trustAsResourceUrl(fileURL);
			$scope.generandopdf = false;
		});
	};

	$scope.showRenovacionContratos = function (renovacionInd) {
		$scope.RenovacionHecha = false;
		console.log('Renovar contratos', renovacionInd);
		$scope.mensajeCargaPDF = false
		$scope.GruposTarifa = [];
        $scope.bloqueoContinuarRenovacion = true;
		$scope.headersRenovacionInd = renovacionInd;
		// $scope.headersRenovacionInd.IdTarifaGrupo = 1883;
		// $scope.PostGetDatosContratoParaRenovacion(renovacionInd.IdContrato);
		$mdDialog.show({
			templateUrl: "modalRenovaciones.html",
			scope: $scope,
			preserveScope: true,
			clickOutsideToClose: true,
			escapeToClose: true,
			controller: [
				"$scope",
				"$http",
				"jwtHelper",
				function ($scope, $http, jwtHelper) {
					//LLAMADA API AQUI RECUPERAR DATOS DEL POPUP

					$scope.updateProducto = function () {
						//FALTA IdProdductoGrupo, pedir a gaspi
						$http({
							method: "GET",
							url: urlApi + "Tarifa/GetDTOAllGruposTarifa/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Tarifa/" + renovacionInd.IdTarifa,
							headers: {
								'Content-Type': 'application/json; charset=UTF-8',
								Authorization: atob(localStorage.getItem("TokenValidador"))
							}
						}).then(function successCallback(response) {
							var respuesta = response.data;
							//TODO: Si el estado es 200 no hace nada, si es !200 que habilite un boton para hacer la llamada de nuevo

							var decodeToken = jwtHelper.decodeToken(respuesta);
							var playloadJson = JSON.parse(decodeToken.iss);
							console.log(playloadJson);

							var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
								alg: ["HS256"]
							});

							// var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
							var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

							if (tokenValido && !tokenExpirado) {
								$scope.GruposTarifa = playloadJson;
								if ($scope.SimplificacionesEnergiaGalega) {
									var auxGruposTarifa = [];
									for (var index = 0; index < $scope.GruposTarifa.length; index++) {
										const element =  $scope.GruposTarifa[index];
										if(element['TextoTarifaGrupo'].includes('CASHBACK')){
											auxGruposTarifa.push(element);
										}
									}
									if(auxGruposTarifa.length > 0) {
										$scope.GruposTarifa = auxGruposTarifa;
									} else {
										$scope.GruposTarifa = "";
									}
								}
							} else {
								$scope.GruposTarifa = "";
								console.error("HASH DE VERIFICACION INCORRECTO");
							}

							$scope.IdTarifaGrupo = playloadJson[0].IdTarifaGrupo;

						});
					};
					$scope.updateProducto();
				}
			]
		});
	};
}]);