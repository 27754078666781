app.controller('ComisionesCtrl', ['$scope', '$location', '$mdDialog', '$http', 'serviciosApi', 'jwtHelper', function ($scope, $location, $mdDialog, $http, serviciosApi, jwtHelper) {
	'use strict';

    var IdAgente = localStorage.getItem('idAgente');
    var IdUsuario = localStorage.getItem('idUsuario');
    var Logueado = localStorage.getItem('logueado');
    var IdEmpresa = localStorage.getItem('idEmpresa');
	var TipoAgente = atob(localStorage.getItem('tipoAgente'));
	var fecha = new Date;
	var fechaMes = "" + fecha.getMonth();
	
	$scope.selected = [];
	$scope.selectedParametros = [];
	$scope.selectedOne = [];
	$scope.loading = false;

	$scope.jsonOneShot = [];
	$scope.jsonCarterizado = [];

	$scope.fechaMes= fechaMes;
	$scope.fechaYear= 2021;

	$scope.MostrarTablaComisionLuxam = MostrarTablaComisionLuxam;


	$scope.OcultarComisionAgentes = OcultarComisionAgentes;
	$scope.OcultarTabComisiones = OcultarTabComisiones;
    $scope.MostrarComisionesNombreAgente = MostrarComisionesNombreAgente;
    $scope.MostrarComisionesTexto = MostrarComisionesTexto;
    $scope.MostrarComisionesFechaCalculoComision = MostrarComisionesFechaCalculoComision;
    $scope.MostrarComisionesFechaFactura = MostrarComisionesFechaFactura;
    $scope.MostrarComisionesImporteCalculado = MostrarComisionesImporteCalculado;
    $scope.MostrarComisionesImporteBase = MostrarComisionesImporteBase;
    $scope.MostrarComisionesNumeroFactura = MostrarComisionesNumeroFactura;

	$scope.MostrarComisionesCodigoCUPS = MostrarComisionesCodigoCUPS;
	$scope.MostrarComisionesTipoComision = MostrarComisionesTipoComision;
	$scope.MostrarComisionesCliente = MostrarComisionesCliente;
	$scope.MostrarComisionesValor = MostrarComisionesValor;
	$scope.MostrarComisionesContrato = MostrarComisionesContrato;
	$scope.MostrarComisionesTarifaGrupo = MostrarComisionesTarifaGrupo;

	$scope.MostrarComisionesParametrosTipoComision = MostrarComisionesParametrosTipoComision;
	$scope.MostrarComisionesParametrosCliente = MostrarComisionesParametrosCliente;
	$scope.MostrarComisionesParametrosTarifaPeaje = MostrarComisionesParametrosTarifaPeaje;
	$scope.MostrarComisionesParametrosValor = MostrarComisionesParametrosValor;
	$scope.MostrarComisionesParametrosContrato = MostrarComisionesParametrosContrato;
	$scope.MostrarComisionesParametrosCUPS = MostrarComisionesParametrosCUPS;
	$scope.ModalImportacionExcelMasivaComisiones = ModalImportacionExcelMasivaComisiones;
	$scope.MostrarComisionesParametrosTarifaGrupo = MostrarComisionesParametrosTarifaGrupo;
	$scope.MostrarContratosSituacionWeb = MostrarContratosSituacionWeb;

	$scope.ValorComisionesTabComisiones = ValorComisionesTabComisiones;
	$scope.ValorComisionesTabAgentes = ValorComisionesTabAgentes;
	$scope.UnidadesValorSeguimientoComisiones = UnidadesValorSeguimientoComisiones;

	$scope.MostrarComisionListadoCarterizado = MostrarComisionListadoCarterizado;
	$scope.MostrarComisionListadoOneShot = MostrarComisionListadoOneShot;

	$scope.dateMonth = new Date();

	// JSON DE EJEMPLO PARA ONESHOT
	$scope.jsonDeEjemploOneShot = [
		{ 'IdAgenteComision': '11-11-2021', 'Entorno': 'Pedro BonOneshot', 'IdFacturaVentaCabecera': 'ES000665002896', 'Serie': '2.0A', 'NumeroFactura': '10500', 'FechaFactura': '7', 'IdContrato': '8690554', 'IdAgente': '1', 'IdAgenteParametro': 147897 , 'FechaCalculoComision': 147897 , 'ImporteBase': 147897 , 'ImporteCalculado': 147897 , 'IdFacturaCompraCabecera': 147897 , 'IdEsquemaComision': 147897 , 'CodigoTipoComision': 147897 , 'IdCUPS': 147897 , },
		{ 'IdAgenteComision': '12-11-2021', 'Entorno': 'Pedro Bacon', 'IdFacturaVentaCabecera': 'ES000665002896', 'Serie': '2.0A', 'NumeroFactura': '10500', 'FechaFactura': '7', 'IdContrato': '8690554', 'IdAgente': '1', 'IdAgenteParametro': 147897 , 'FechaCalculoComision': 147897 , 'ImporteBase': 147897 , 'ImporteCalculado': 147897 , 'IdFacturaCompraCabecera': 147897 , 'IdEsquemaComision': 147897 , 'CodigoTipoComision': 147897 , 'IdCUPS': 147897 , },
		{ 'IdAgenteComision': '13-11-2021', 'Entorno': 'Pedro Boacn', 'IdFacturaVentaCabecera': 'ES000665002896', 'Serie': '2.0A', 'NumeroFactura': '10500', 'FechaFactura': '7', 'IdContrato': '8690554', 'IdAgente': '1', 'IdAgenteParametro': 147897 , 'FechaCalculoComision': 147897 , 'ImporteBase': 147897 , 'ImporteCalculado': 147897 , 'IdFacturaCompraCabecera': 147897 , 'IdEsquemaComision': 147897 , 'CodigoTipoComision': 147897 , 'IdCUPS': 147897 , },
	];

	$scope.jsonDeEjemploCarterizado = [
		{ 'IdAgenteComision': '11-11-2021', 'Entorno': 'Pedro BonCarterizado', 'IdFacturaVentaCabecera': 'ES000665002896', 'Serie': '2.0A', 'NumeroFactura': '10500', 'FechaFactura': '7', 'IdContrato': '8690554', 'IdAgente': '1', 'IdAgenteParametro': 147897 , 'FechaCalculoComision': 147897 , 'ImporteBase': 147897 , 'ImporteCalculado': 147897 , 'IdFacturaCompraCabecera': 147897 , 'IdEsquemaComision': 147897 , 'CodigoTipoComision': 147897 , 'IdCUPS': 147897 , },
		{ 'IdAgenteComision': '12-11-2021', 'Entorno': 'Pedro Bacon', 'IdFacturaVentaCabecera': 'ES000665002896', 'Serie': '2.0A', 'NumeroFactura': '10500', 'FechaFactura': '7', 'IdContrato': '8690554', 'IdAgente': '1', 'IdAgenteParametro': 147897 , 'FechaCalculoComision': 147897 , 'ImporteBase': 147897 , 'ImporteCalculado': 147897 , 'IdFacturaCompraCabecera': 147897 , 'IdEsquemaComision': 147897 , 'CodigoTipoComision': 147897 , 'IdCUPS': 147897 , },
		{ 'IdAgenteComision': '13-11-2021', 'Entorno': 'Pedro Boacn', 'IdFacturaVentaCabecera': 'ES000665002896', 'Serie': '2.0A', 'NumeroFactura': '10500', 'FechaFactura': '7', 'IdContrato': '8690554', 'IdAgente': '1', 'IdAgenteParametro': 147897 , 'FechaCalculoComision': 147897 , 'ImporteBase': 147897 , 'ImporteCalculado': 147897 , 'IdFacturaCompraCabecera': 147897 , 'IdEsquemaComision': 147897 , 'CodigoTipoComision': 147897 , 'IdCUPS': 147897 , },

	];



	$scope.showModalMasivo = function () {
		$mdDialog.show({
			templateUrl: 'myModalContentComisiones.html',
			clickOutsideToClose: true,
			controller: 'ComisionesCtrl'
			// 	controller: 'ComisionesCtrl',
			// 	templateUrl: 'templates/contrato-importacion-masiva.html',
			// 	clickOutsideToClose: true,

			// });
		})
	}

	$scope.fileNameChanged = function (ele) {
		var file = document.getElementById('file-upload-contrato').files[0];
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			var ficheroSplit = reader.result.split(',');
			var ficheroExcel = ele.files[0].name;
			var extensionFichero = ficheroExcel.split('.');
			var datosApi = { "DocExcel": ficheroSplit[1], "IdEmpresa": IdEmpresa, "IdUsuario": IdUsuario, "Extension": "." + extensionFichero[1] };
			$http({
				method: "POST",
				url: urlApi + "ContratoPotencial/ImportExcel",
				headers: {
					Authorization: atob(localStorage.getItem('TokenValidador')),
					'Content-Type': 'application/json'
				},
				data: JSON.stringify(datosApi)
			}).then(function (response) {
				var respuesta = response.data.Contratos;

				$scope.errores = [];
				$scope.insercionesCorrectas = 0;
				$scope.insercionesErroneas = 0;
				for (var i = 0; i < respuesta.length; i++) {
					var cups = respuesta[i].CodigoCUPS;
					for (var e = 0; e < respuesta[i].Incidencias.length; e++) {
						var error = respuesta[i].Incidencias[e].Mensaje;
						if (error) {
							$scope.insercionesErroneas = $scope.insercionesErroneas + 1;
						} else {
							$scope.insercionesCorrectas = $scope.insercionesCorrectas + 1
						}
						$scope.errores.push({
							"CUPS": cups,
							"Error": error
						});
					}
				}
				//$state.go("myModalContent.html", {}, { reload: true });
			});
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}


	if (MostrarTablaComisionLuxam) {

		$scope.autocolumn = [
			{
				name: "NombreCliente",
				display: "Nombre Cliente",
			},{
				name: "CodigoContrato",
				display: "Código Contrato",
			},{
				name: "ValorCo",
				display: "Comisión",
			}, {
				name: "FechaCalculoComision",
				display: "Fecha Cálculo",
			},
			{
				name: "Deuda",
				display: "Deuda",
			}, {
				name: "CodigoCUPS",
				display: "Código CUPS",
			}, {
				name: "FechaFacturaAgente",
				display: "Fecha Factura",
			}, {
				name: "EstadoFactura",
				display: "Estado Factura",
			}, {
				name: "FechaAlta",
				display: "Fecha Alta",
			}, {
				name: "NombreTarifa",
				display: "Tarifa",
			}, {
				name: "NombreTarifaGrupo",
				display: "Grupo Tarifa",
			}

		];

	} else {
		$scope.autocolumn = [
			{
				name: "NombreAgente",
				display: "Nombre Agente",
				oculta: "NombreAgente"
			}, {
				name: "TarifaGrupo",
				display: "Tarifa Grupo",
				oculta: "TarifaGrupo"
			}, {
				name: "TipoComision",
				display: "Tipo Comisión",
				oculta: "TipoComision"
			}, {
				name: "Valor",
				display: "Valor",
				oculta: "Valor"
			}, {
				name: "FechaCalculoComision",
				display: "Fecha Calculo Comisión",
				oculta: "FechaCalculoComision"
			}, {
				name: "FechaFactura",
				display: "Fecha Factura",
				oculta: "FechaFactura"
			}, {
				name: "Contrato",
				display: "Contrato",
				oculta: "Contrato"
			}, {
				name: "ImporteCalculado",
				display: "Importe Calculado",
				oculta: "ImporteCalculado"
			}, {
				name: "ImporteBase",
				display: "Importe Base",
				oculta: "ImporteBase"
			}, {
				name: "NumeroFactura",
				display: "Numero Factura",
				oculta: "NumeroFactura"
			},
			{
				name: "Cliente",
				display: "Cliente",
				oculta: "Cliente"
			},
			{
				name: "CodigoCUPS",
				display: "Codigo CUPS",
				oculta: "CodigoCUPS"
			}
		];


	}



	$scope.columnas = [
		{
			name: "TipoComision",
			display: "Tipo Comision",
			oculta: "TipoComision"
		}, {
			name: "TarifaGrupo",
			display: "Tarifa Grupo",
			oculta: "TarifaGrupo"
		}, {
			name: "TarifaPeaje",
			display: "Tarifa Peaje",
			oculta: "TarifaPeaje"
		}, {
			name: "Valor",
			display: "Valor",
			oculta: "Valor"
		}, {
			name: "Contrato",
			display: "Contrato",
			oculta: "Contrato"

		},
		{
			name: "Cliente",
			display: "Cliente",
			oculta: "Cliente"
		}, {
			name: "CUPS",
			display: "CUPS",
			oculta: "CUPS"
		}
	];


	$scope.columnasOneShot = [
		{name: "FechaAlta", display: "Fecha Alta", oculta: "FechaAlta"}, 
		{name: "NombreCliente", display: "Nombre Cliente", oculta: "NombreCliente"}, 
		{name: "Cups", display: "Cups", oculta: "Cups"}, 
		{name: "Tarifa", display: "Tarifa", oculta: "Tarifa"}, 
		{name: "Consumo", display: "Consumo", oculta: "Consumo"	}, 
		{name: "FeeEnergia", display: "Fee Energía", oculta: "FeeEnergia"}, 
		{name: "TotalEnergia", display: "Total Energía", oculta: "TotalEnergia"},
		{name: "FeePotencia", display: "Fee Potencia", oculta: "FeePotencia"},
		{name: "ImporteCalculado", display: "Comisión Total", oculta: "ImporteCalculado"},
	];

	$scope.columnasCarterizado = [
		{name: "FechaAlta", display: "Fecha Alta", oculta: "FechaAlta"}, 
		{name: "NombreCliente", display: "Nombre Cliente", oculta: "NombreCliente"}, 
		{name: "Cups", display: "Cups", oculta: "Cups"}, 
		{name: "NumeroFactura", display: "Número Factura", oculta: "NumeroFactura"}, 
		{name: "FechaInicioFactura", display: "Fecha Inicio Factura", oculta: "FechaInicioFactura"	}, 
		{name: "FechaFinFactura", display: "Fecha Fin Factura", oculta: "FechaFinFactura"}, 
		{name: "FeeEnergia", display: "Fee Energía", oculta: "FeeEnergia"}, 
		{name: "TotalEnergia", display: "Total Energía", oculta: "TotalEnergia"},
		{name: "TotalPotencia", display: "Total Potencia", oculta: "TotalPotencia"},
		{name: "ImporteCalculado", display: "Comisión Total", oculta: "ImporteCalculado"},
	];


	$scope.exportarDatosComisiones = function (selected) {
		var comisiones = [];

		if(MostrarTablaComisionLuxam){
			selected.forEach(function (obj) {
				comisiones.push({
					'Nombre Cliente': (obj.NombreCliente) ? obj.NombreCliente : '',
					'Codigo Contrato': (obj.CodigoContrato) ? obj.CodigoContrato : '',
					'Comisión': (obj.ValorCo) ? obj.ValorCo : '',
					'Fecha Calculo Comisión': (obj.FechaCalculoComision) ? obj.FechaCalculoComision : '',
					'Deuda': (obj.Deuda) ? obj.Deuda : '',
					'Código CUPS': (obj.CodigoCUPS) ? obj.CodigoCUPS : '',
					'Fecha Factura Agente': (obj.FechaFacturaAgente) ? obj.FechaFacturaAgente : '',
					'Estado Factura': (obj.EstadoFactura) ? obj.EstadoFactura : '',
					'Fecha Alta': (obj.FechaAlta) ? obj.FechaAlta : '',
					'Tarifa': (obj.NombreTarifa) ? obj.NombreTarifa : '',
					'Grupo Tarifa': (obj.NombreTarifaGrupo) ? obj.NombreTarifaGrupo : '',
					
				});
			});
		}else{
			selected.forEach(function (obj) {
				comisiones.push({
					'Nombre Agente': (obj.NombreAgente) ? obj.NombreAgente : '',
					'Agente Parametro': (obj.TipoAgenteParametroTexto) ? obj.TipoAgenteParametroTexto : '',
					'Fecha Calculo Comision': (obj.FechaCalculoComision) ? obj.FechaCalculoComision : '',
					'Fecha Factura': (obj.FechaFactura) ? obj.FechaFactura : '',
					'Importe Calculado': (obj.ImporteCalculado) ? parseFloat(obj.ImporteCalculado).toFixed(2).replace('.', ',') : '',
					'Importe Base': (obj.ImporteBase) ? parseFloat(obj.ImporteBase).toFixed(2).replace('.', ',') : '',
					'Serie': (obj.Serie) ? obj.Serie : '',
					'CUPS': (obj.CodigoCUPS) ? obj.CodigoCUPS : ''
				});
			});
		}

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
			.excelexportjs({
				containerid: "dvjson",
				datatype: 'json',
				dataset: comisiones,
				columns: getColumns(comisiones)
			});
	};

	$scope.exportarDatosParametros = function (selected) {
		var parametros = [];

		selected.forEach(function (obj) {
			parametros.push({
				'Tipo Comision': (obj.TipoComision) ? obj.TipoComision : '',
				'Cliente': (obj.Cliente) ? obj.Cliente : '',
				'Tarifa Grupo': (obj.TarifaGrupo) ? obj.TarifaGrupo : '',
				'Tarifa Peaje': (obj.TarifaPeaje) ? obj.TarifaPeaje : '',
				'Valor': (obj.Valor) ? parseFloat(obj.Valor).toFixed(2).replace('.', ',') : '',
				'Contrato': (obj.Contrato) ? obj.Contrato : '',
				'CUPS': (obj.CUPS) ? obj.CUPS : ''
			});
		});

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
			.excelexportjs({
				containerid: "dvjson",
				datatype: 'json',
				dataset: parametros,
				columns: getColumns(parametros)
			});
	};

	$scope.exportarComisionesEnExcelOneshot= function(selected) {
        var comisiones = [];
		console.log(selected);
        selected.forEach(function(obj) {
            comisiones.push({
				'Fecha Alta' : (obj.FechaAlta) ? obj.FechaAlta : '',
				'Nombre Cliente' : (obj.NombreCliente) ? obj.NombreCliente : '',
				'Cups' : (obj.CodigoCups) ? obj.CodigoCups : '',
                'Tarifa' : (obj.Tarifa) ? obj.Tarifa : '',
				'Consumo' : (obj.Consumo) ? obj.Consumo : '',
				'Fee Energía' : (obj.FeeEnergia) ? obj.FeeEnergia : '',
				'Total Energía' : (obj.TotalEnergia) ? obj.TotalEnergia : '',
				'Fee Potencia' : (obj.FeePotencia) ? obj.FeePotencia : '',
				'Comisión Total' : (obj.ImporteCalculado) ? obj.ImporteCalculado : '',
            });
        });

		comisiones.push({
			'Fecha Alta' :  '',
			'Nombre Cliente' :  '',
			'Cups' :  '',
			'Tarifa' : '',
			'Consumo' :  '',
			'Fee Energía' :  '',
			'Total Energía' : '',
			'Fee Potencia' :  'TOTAL',
			'Comisión Total' : $scope.total2,
		});

        /**

		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
			.excelexportjs({
				containerid: "dvjson",
				datatype: 'json',
				dataset: comisiones,
				columns: getColumns(comisiones)
			});
	};

	$scope.exportarComisionesEnExcelCarterizado= function(selected) {
        var comisiones = [];
		console.log(selected);
        selected.forEach(function(obj) {
            comisiones.push({
				'Fecha Alta' : (obj.FechaAlta) ? obj.FechaAlta : '',
				'Nombre Cliente' : (obj.NombreCliente) ? obj.NombreCliente : '',
				'Cups' : (obj.CodigoCups) ? obj.CodigoCups : '',
                'Número Factura' : (obj.NumeroFactura) ? obj.NumeroFactura : '',
				'Fecha Inicio Factura' : (obj.FechaInicioFactura) ? obj.FechaInicioFactura : '',
				'Fecha Fin Factura' : (obj.FechaFinFactura) ? obj.FechaFinFactura : '',
				'Fee Energía' : (obj.FeeEnergia) ? obj.FeeEnergia : '',
				'Total Energía' : (obj.TotalEnergia) ? obj.TotalEnergia : '',
				'Total Potencia' : (obj.TotalPotencia) ? obj.TotalPotencia : '',
				'Comisión Total' : (obj.ImporteCalculado) ? obj.ImporteCalculado : '',
            });
        });

		comisiones.push({
			'Fecha Alta' :  '',
			'Nombre Cliente' :  '',
			'Cups' :  '',
			'Número Factura' : '',
			'Fecha Inicio Factura' :  '',
			'Fecha Fin Factura' :  '',
			'Fee Energía' :  '',
			'Total Energía' : '',
			'Total Potencia' :  'TOTAL',
			'Comisión Total' : $scope.total2,
		});

        /**

		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
			.excelexportjs({
				containerid: "dvjson",
				datatype: 'json',
				dataset: comisiones,
				columns: getColumns(comisiones)
			});
	};

	$scope.sendCliente = function (datos) {
		localStorage.setItem('identificador', btoa(datos.comision.IdCliente));
		$location.url("/clientes/perfil");
	};

	$scope.sendContrato = function (datos) {
		localStorage.setItem('numeroPoliza', btoa(datos.comision.IdContrato));
		localStorage.setItem("idCliente", btoa(datos.comision.IdCliente));
		localStorage.setItem("codigoContrato", btoa(datos.comision.CodigoContrato));
		$location.url("/contratos/resumen");
	};

	$scope.convertTipoComision = function (id) {
		var ret = "";
		switch (id) {
			case 2: ret = "% Término Energía (con BOE)"; break;
			case 4: ret = "% Gestión Compra"; break;
			case 5: ret = "Importe kWh facturado"; break;
			case 6: ret = "Importe por Factura Energía"; break;
			case 7: ret = "% BI Factura Energía"; break;
			case 8: ret = "% BI Facturas Varios"; break;
			case 10: ret = "% Sobre Importe Fee"; break;
			case 11: ret = "Importe Único"; break;
		}
		return ret;
	}

	$scope.seleccionaTodosComisiones = function () {
		$scope.comisiones.forEach(function (item) {
			$scope.selected.push(item);
		});
	}
	$scope.deseleccionaTodosComisiones = function () {
		$scope.selected = [];
	}

	$scope.seleccionaTodosParametros = function () {
		$scope.parametros.forEach(function (item) {
			$scope.selectedParametros.push(item);

		});
	}
	$scope.deseleccionaTodosParametros =  function(){

		$scope.selectedParametros = [];
		$scope.selectedOne = [];
	}

	$scope.seleccionaTodosComisionesOne = function(TipoComision){	//11 ONESHOT , 13 CARTERIZADO
		$scope.selectedOne = [];

		if(TipoComision == 11){
			$scope.jsonOneShot.forEach(function (item) {
				$scope.selectedOne.push(item);
			});
		}else if(TipoComision == 13){
			$scope.jsonCarterizado.forEach(function (item) {
				$scope.selectedOne.push(item);
			});
		}
		

		$scope.total= 0;
		$scope.selectedOne.forEach(function (item) {
			$scope.total= $scope.total + item.ImporteCalculado;

		});

		$scope.total2=$scope.total.toLocaleString();

	}

	$scope.calcularTotalSelecionados= function(){
		
		$scope.total= 0;
		$scope.selectedOne.forEach(function (item) {
			$scope.total= $scope.total + item.ImporteCalculado;
		});

		$scope.total2=$scope.total.toLocaleString();

	}

	$scope.recargarDatos = function () {

		serviciosApi.getListadoComisiones()

        .then(function(response) {
            var respuesta = response.data;
            var decodeToken = jwtHelper.decodeToken(respuesta);
            var playloadJson = JSON.parse(decodeToken.iss);
            var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]    });

            var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

            if (tokenValido && !tokenExpirado) {
                $scope.comerciales = angular.copy(playloadJson);
                $scope.jsonOneShot = "playloadJson";
            } else {
                $scope.comerciales = angular.copy("");
                $scope.jsonOneShot = "";
                console.error("HASH DE VERIFICACION INCORRECTO");
            }
        })

	}

	$scope.buscarComisiones =  function(TipoComision){//11 ONESHOT , 13 CARTERIZADO

		if($scope.Mes == undefined || $scope.Year == undefined){
			return;
		}

		var FechaInicio="";
		var FechaFin="";



		switch($scope.Mes){
			case "Enero":
				FechaInicio=  ($scope.Year -1) + "/01/01";
				FechaFin=  $scope.Year + "/01/31";
			break;
			case "Febrero":
				FechaInicio=  ($scope.Year -1) + "/02/01";
				FechaFin=  $scope.Year + "/02/28";
			break;
			case "Marzo":
				FechaInicio=  ($scope.Year -1) + "/03/01";
				FechaFin=  $scope.Year + "/03/31";
			break;
			case "Abril":
				FechaInicio=  ($scope.Year -1) + "/04/01";
				FechaFin=  $scope.Year + "/04/30";
			break;
			case "Mayo":
				FechaInicio=  ($scope.Year -1) + "/05/01";
				FechaFin=  $scope.Year + "/05/31";
			break;
			case "Junio":
				FechaInicio=  ($scope.Year -1) + "/06/01";
				FechaFin=  $scope.Year + "/06/31";
			break;
			case "Julio":
				FechaInicio=  ($scope.Year -1) + "/07/01";
				FechaFin=  $scope.Year + "/07/31";;
			break;
			case "Agosto":
				FechaInicio=  ($scope.Year -1) + "/08/01";
				FechaFin=  $scope.Year + "/08/31";
			break;
			case "Septiembre":
				FechaInicio=  ($scope.Year -1) + "/09/01";
				FechaFin=  $scope.Year + "/09/30";
			break;
			case "Octubre":
				FechaInicio=  ($scope.Year -1) + "/10/01";
				FechaFin=  $scope.Year + "/10/31";
			break;
			case "Noviembre":
				FechaInicio=  ($scope.Year -1) + "/11/01";
				FechaFin=  $scope.Year + "/11/30";
			break;
			case "Diciembre":
				FechaInicio=  ($scope.Year -1) + "/12/01";
				FechaFin=  $scope.Year + "/12/31";
			break;

			default:
			break;
		}

		$http({
            method: "POST",
            url: urlApi + "AgenteComision/ComisionFiltroAgenteFecha",
			headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            },
			data:{
				IdAgente: IdAgente,
				TipoAgenteParametro: TipoComision,
				FechaFin: FechaFin,
				FechaInicio: FechaInicio,
			}
        }).then(function(response){
		
			var respuesta = response.data;
			var decodeToken = jwtHelper.decodeToken(respuesta);
			var playloadJson = JSON.parse(decodeToken.iss);
			var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]	});
			var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
			console.log(playloadJson);
			if (tokenValido && !tokenExpirado) {//11 ONESHOT , 13 CARTERIZADO
				if(TipoComision == 11){
					$scope.jsonOneShot = playloadJson;
					$scope.jsonCarterizado = [];
					$scope.selectedOne = [];

				}else if(TipoComision == 13){
					$scope.jsonCarterizado = playloadJson;
					$scope.jsonOneShot = [];
					$scope.selectedOne = [];

				}
			} else {
				$scope.jsonOneShot = [];
				$scope.jsonCarterizado = [];

				console.error("HASH DE VERIFICACION INCORRECTO");
			}
		})
		

	}




}]);