app.controller('AltaContratosElectricidadCtrl', ['$scope', '$location', '$http', 'serviciosApi', '$rootScope', 'jwtHelper', '$mdToast',
	function ($scope, $location, $http, serviciosApi, $rootScope, jwtHelper, $mdToast) {
	'use strict';

	var IdUsuario = localStorage.getItem('idUsuario');
    var IdAgente = localStorage.getItem('idAgente');
    var IdEmpresa = localStorage.getItem('idEmpresa');

	// Vars configuración
	$scope.requiredGeneralCUPS = requiredGeneralCUPS;
	$scope.requiredGeneralCodDistribuidora = requiredGeneralCodDistribuidora;
	$scope.requiredGeneralCodComercializadora = requiredGeneralCodComercializadora;
	$scope.requiredGeneralCiudad = requiredGeneralCiudad;
	$scope.requiredGeneralCalle = requiredGeneralCalle;
	$scope.requiredGeneralNumero = requiredGeneralNumero;
	$scope.requiredGeneralAclarador = requiredGeneralAclarador;
	$scope.requiredGeneralCodPostal = requiredGeneralCodPostal;
	$scope.requiredGeneralProvincia = requiredGeneralProvincia;
	$scope.requiredPotenciasTarifaAcceso = requiredPotenciasTarifaAcceso;
	$scope.requiredPotenciasGrupoTarifa = requiredPotenciasGrupoTarifa;
	$scope.requiredPotenciasPerfilFacturacion = requiredPotenciasPerfilFacturacion;
	$scope.requiredServiciosGrupoProducto = requiredServiciosGrupoProducto;
	$scope.requiredServiciosProducto = requiredServiciosProducto;
	$scope.requiredContratoIdentificador = requiredContratoIdentificador;
	$scope.requiredContratoCodigoPostal = requiredContratoCodigoPostal;
	$scope.requiredContratoCiudad = requiredContratoCiudad;
	$scope.requiredContratoCalle = requiredContratoCalle;
	$scope.requiredContratoNumero = requiredContratoNumero;
	$scope.requiredContratoAclarador = requiredContratoAclarador;
	$scope.requiredContratoProvincia = requiredContratoProvincia;
	$scope.requiredContratoCategoriaCliente = requiredContratoCategoriaCliente;
	$scope.requiredDatosEnvioNombre = requiredDatosEnvioNombre;
	$scope.requiredDatosEnvioCodPostal = requiredDatosEnvioCodPostal;
	$scope.requiredDatosEnvioCiudad = requiredDatosEnvioCiudad;
	$scope.requiredDatosEnvioCalle = requiredDatosEnvioCalle;
	$scope.requiredDatosEnvioNumero = requiredDatosEnvioNumero;
	$scope.requiredDatosEnvioAclarador = requiredDatosEnvioAclarador;
	$scope.requiredDatosEnvioProvincia = requiredDatosEnvioProvincia;
	$scope.requiredOtrosNombrePagador = requiredOtrosNombrePagador;
	$scope.requiredOtrosIdentificador = requiredOtrosIdentificador;
	$scope.requiredOtrosTipoCobro = requiredOtrosTipoCobro;
	$scope.requiredOtrosIBAN = requiredOtrosIBAN;
	$scope.requiredOtrosCNAE = requiredOtrosCNAE;
	$scope.requiredOtrosPeriodo = requiredOtrosPeriodo;
	$scope.requiredOtrosTipoImpresion = requiredOtrosTipoImpresion;
	$scope.requiredOtrosTipoSolicitud = requiredOtrosTipoSolicitud;
	$scope.requiredOtrosModeloContrato = requiredOtrosModeloContrato;
	$scope.requiredOtrosIdiomaContrato = requiredOtrosIdiomaContrato;
	$scope.requiredOtrosCodigoVendedor = requiredOtrosCodigoVendedor;
	$scope.requiredOtrosFechaEfecto = requiredOtrosFechaEfecto;
	$scope.requiredOtrosActivacionPrevista = requiredOtrosActivacionPrevista;

	$scope.ps_codigocomercializadora = CodigoREEComercializadora;

	// Control persona o razon social (En principio es persona por defecto)
	$scope.isPersonaDT = true;
	$scope.isRazonSocialDT = false;

	// Vars control de errores
	$scope.msg_cupsincorrecto = false;
	$scope.msg_codpostalpsnoencontrado = false;
	$scope.msg_codpostaldtnoencontrado = false;
	$scope.msg_consumoanualnocalculado = false;
	$scope.msg_coincorrecto = false;
	$scope.msg_identificadordtincorrecto = false;

	/**
	 * Buscar código postal PS
	 */
	$scope.buscarCodigoPostalPS = function (codigo_postal) {
		$http({
			method: "POST",
			url: urlCallejeroNew + "Ciudad/GetDTOALLCiudades/CodigoPostal/" + codigo_postal
		}).then(function (response) {
			var municipios = response.data.listaMunicipios;
			if (municipios != null && municipios.length > 0) {
				$scope.ps_provincia = municipios[0].desProvincia.toUpperCase();
				var municipio = municipios[0].codMunicipio;
				var provincia = municipios[0].codProvincia;
				buscarCiudadesPS(provincia, municipio);
				buscarCallesPS(provincia, municipio, codigo_postal);
				$scope.msg_codpostalpsnoencontrado = false;
				show_toast('Datos del Código postal actualizados');
			} else {
				$scope.msg_codpostalpsnoencontrado = true;
			}
		});
	}

	/**
	 * Buscar código postal DT
	 */
	$scope.buscarCodigoPostalDT = function (codigo_postal) {
		$http({
			method: "POST",
			url: urlCallejeroNew + "Ciudad/GetDTOALLCiudades/CodigoPostal/" + codigo_postal
		}).then(function (response) {
			var municipios = response.data.listaMunicipios;
			if (municipios != null && municipios.length > 0) {
				$scope.dt_provincia = municipios[0].desProvincia.toUpperCase();
				var municipio = municipios[0].codMunicipio;
				var provincia = municipios[0].codProvincia;
				buscarCiudadesDT(provincia, municipio);
				buscarCallesDT(provincia, municipio, codigo_postal);
				$scope.msg_codpostaldtnoencontrado = false;
				show_toast('Datos del Código postal actualizados');
			} else {
				$scope.msg_codpostaldtnoencontrado = true;
			}
		});
	}

	/**
	 * Buscar CUPS
	 * Se ejecuta tras introducir un código CUPS
	 */
	$scope.buscarCups = function (cups) {
		if ($scope.altaContrato.ps_cups.$valid) {
			validarCups(cups);
			if ($scope.msg_cupsincorrecto != true) {
				serviciosApi.getSips(cups, "", "", "", "", "", "").then(function successCallback(response) {
					var resp = response.data;
					var datosCliente = response.data.ClientesSips[0];
					var datosConsumos = resp.ConsumosSips;

					if (resp != undefined && resp != null && datosCliente) {
						// Rellenar campos
						// $scope.ps_nombredistribuidora = datosCliente.NombreEmpresaDistribuidora;
						$scope.ps_codigodistribuidora = datosCliente.CodigoEmpresaDistribuidora;
						$scope.ps_codigopostal = parseInt(datosCliente.CodigoPostalPS);
						$scope.buscarCodigoPostalPS($scope.ps_codigopostal);
						$scope.ps_provincia = datosCliente.DesProvinciaPS.toUpperCase();
						$scope.ps_ciudad = datosCliente.DesMunicipioPS.toUpperCase();
						$scope.dc_tarifa = convertTarifa(datosCliente.CodigoTarifaATREnVigor); // $scope.dc_tarifa = datosCliente.CodigoTarifaATREnVigor;
						$scope.dc_potencia1 = (datosCliente.PotenciasContratadasEnWP1 > 0) ? datosCliente.PotenciasContratadasEnWP1 : "";
						$scope.dc_potencia2 = (datosCliente.PotenciasContratadasEnWP2 > 0) ? datosCliente.PotenciasContratadasEnWP2 : "";
						$scope.dc_potencia3 = (datosCliente.PotenciasContratadasEnWP3 > 0) ? datosCliente.PotenciasContratadasEnWP3 : "";
						$scope.dc_potencia4 = (datosCliente.PotenciasContratadasEnWP4 > 0) ? datosCliente.PotenciasContratadasEnWP4 : "";
						$scope.dc_potencia5 = (datosCliente.PotenciasContratadasEnWP5 > 0) ? datosCliente.PotenciasContratadasEnWP5 : "";
						$scope.dc_potencia6 = (datosCliente.PotenciasContratadasEnWP6 > 0) ? datosCliente.PotenciasContratadasEnWP6 : "";
						if (datosConsumos) { !$scope.msg_consumoanualnocalculado }
						// (datosConsumos) ? calcularConsumoAnual(datosConsumos) : msg_consumoanualnocalculado;
						$scope.db_cnae = (datosCliente.Cnae) ? datosCliente.Cnae : "";

						show_toast('Datos del CUPS actualizados');
					} else {
						show_toast('No se han encontrado datos del CUPS');
					}

				});
			}
		}
	}

	/**
	 * Llamada - Tarifas
	 */
	$http({
		method: "GET",
		url: urlApi + "Tarifa/GetDTOAll/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
		headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }

	}).then(function successCallback(response) {
		var resp = response.data;
		var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
		var tokenExpirado = jwtHelper.isTokenExpired(resp);

		if (tokenValido && !tokenExpirado) {
			var token = jwtHelper.decodeToken(resp);
			var json = JSON.parse(token.iss);
			$scope.listado_tarifas = json;
		} else {
			$scope.listado_tarifas = "";
			console.log("HASH DE VERIFICACION INCORRECTO");
		}
	});

	/**
	 * Llamada - Buscar Productos
	 * Se ejecuta al seleccionar una tarifa del desplegable
	 */
	$scope.buscarProductos = function (tarifa) {
		if ($scope.altaContrato.dc_tarifa.$valid) {
			$http({
				method: "GET",
				url: urlApi + "Tarifa/GetDTOAllGruposTarifa/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Tarifa/" + tarifa,
				headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
			}).then(function successCallback(response) {
				var resp = response.data;
				var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
				var tokenExpirado = jwtHelper.isTokenExpired(resp);

				if (tokenValido && !tokenExpirado) {
					var token = jwtHelper.decodeToken(resp);
					var json = JSON.parse(token.iss);
					$scope.listado_productos = json;
				} else {
					$scope.listado_productos = "";
					console.log("HASH DE VERIFICACION INCORRECTO");
				}
			});
		}
	}

	/**
	 * Llamada - Perfiles facturación
	 */
	$http({
		method: "GET",
		url: urlApi + "PeriodoFactura/GetDTOAllPeriodos/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Linea/1", // Electricidad - 1
		headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
	}).then(function successCallback(response) {
		var resp = response.data;
		var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
		var tokenExpirado = jwtHelper.isTokenExpired(resp);

		if (tokenValido && !tokenExpirado) {
			var token = jwtHelper.decodeToken(resp);
			var json = JSON.parse(token.iss);
			$scope.listado_perfiles = json;
		} else {
			$scope.listado_perfiles = "";
			console.log("HASH DE VERIFICACION INCORRECTO");
		}
	});

	/**
	 * Llamada - Grupo Producto (Servicios de Valor Añadido)
	 */
	$http({
		method: "GET",
		url: urlApi + "GrupoProducto/GetAllProductoGrupo/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
		headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
	}).then(function successCallback(response) {
		var resp = response.data;
		var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
		var tokenExpirado = jwtHelper.isTokenExpired(resp);

		if (tokenValido && !tokenExpirado) {
			var token = jwtHelper.decodeToken(resp);
			var json = JSON.parse(token.iss);
			$scope.listado_gruposproducto = json;
		} else {
			$scope.listado_gruposproducto = "";
			console.log("HASH DE VERIFICACION INCORRECTO");
		}
	});

	/**
	 * Llamada - Producto (Servicios de Valor Añadido)
	 * Se ejecuta al seleccionar un grupo producto del desplegable
	 */
	$scope.buscarProductosVA = function (grupo_producto) {
		if ($scope.altaContrato.va_grupoproducto.$valid) {
			$http({
				method: "GET",
				url: urlApi + "Producto/GetAllProducto/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/IdProductoGrupo/" + grupo_producto,
				headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
			}).then(function (response) {
				var resp = response.data;
				var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
				var tokenExpirado = jwtHelper.isTokenExpired(resp);

				if (tokenValido && !tokenExpirado) {
					var token = jwtHelper.decodeToken(resp);
					var json = JSON.parse(token.iss);
					$scope.listado_productos_va = json;
				} else {
					$scope.listado_productos_va = "";
					console.log("HASH DE VERIFICACION INCORRECTO");
				}
			});
		}
	}

	/**
	 * Llamada - Precios Vigentes
	 */
	$scope.buscarPreciosVigentes = function (producto) {
		$http({
			method: "GET",
			url: urlApi + "TarifaPeaje/GetDTOAllPreciosFijosVigentes/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/TarifaGrupo/" + producto,
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
		}).then(function successCallback(response) {
			var resp = response.data;
			var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
			var tokenExpirado = jwtHelper.isTokenExpired(resp);

			if (tokenValido && !tokenExpirado) {
				var token = jwtHelper.decodeToken(resp);
				var json = JSON.parse(token.iss);
				$scope.precios_fijos_vigentes = json;
			} else {
				$scope.precios_fijos_vigentes = "";
				console.log("HASH DE VERIFICACION INCORRECTO");
			}
		});
	}

	/**
	 * Calcular comisión Coste Operación
	 */
	$scope.calcularComision = function (co) {
		// console.log(co);
		$scope.msg_coincorrecto = true;
	}

	/**
	 * Buscar ciudades PS
	 * Se ejecuta al introducir un Código Postal PS
	 */
	function buscarCiudadesPS(provincia, municipio) {
		var data = [{ "CodProvincia": provincia, "CodMunicipio": municipio }];
		$http({
			method: "POST",
			url: urlCallejeroNew + "Ciudad/GetDTOALLCiudades/Lista",
			data: JSON.stringify(data)
		}).then(function (response) {
			$scope.ps_listado_ciudades = response.data;
		});
	}

	/**
	 * Buscar ciudades DT
	 * Se ejecuta al introducir un Código Postal DT
	 */
	function buscarCiudadesDT(provincia, municipio) {
		var data = [{ "CodProvincia": provincia, "CodMunicipio": municipio }];
		$http({
			method: "POST",
			url: urlCallejeroNew + "Ciudad/GetDTOALLCiudades/Lista",
			data: JSON.stringify(data)
		}).then(function (response) {
			$scope.dt_listado_ciudades = response.data;
		});
	}

	/**
	 * Buscar calles PS
	 * Se ejecuta al introducir un Código Postal PS
	 */
	function buscarCallesPS(provincia, municipio, codpostal) {
		var data = [{ "codProvincia": provincia, "codMunicipio": municipio, "codPostal": codpostal }];
		$http({
			method: "POST",
			url: urlCallejeroNew + "Calle/GetDTOALLCalles/Lista",
			data: JSON.stringify(data)
		}).then(function (response) {
			$scope.ps_listado_calles = response.data;
		});
	}

	/**
	 * Buscar calles DT
	 * Se ejecuta al introducir un Código Postal DT
	 */
	function buscarCallesDT(provincia, municipio, codpostal) {
		var data = [{ "codProvincia": provincia, "codMunicipio": municipio, "codPostal": codpostal }];
		$http({
			method: "POST",
			url: urlCallejeroNew + "Calle/GetDTOALLCalles/Lista",
			data: JSON.stringify(data)
		}).then(function (response) {
			$scope.dt_listado_calles = response.data;
		});
	}

	/**
	 * Buscar Identificador Datos Titular
	 * Se ejecuta al introducir un Identificador
	 */
	$scope.buscarIdentificadorDT = function (id) {
		if ($scope.altaContrato.dt_identificador.$valid && validarIdentificador(id) != false) {
			$http({
				method: "GET",
				url: urlApi + "Cliente/GetClientePorDni/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Dni/" + id,
				headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
			}).then(function (response) {
				var resp = response.data;
				var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
				var tokenExpirado = jwtHelper.isTokenExpired(resp);

				if (tokenValido && !tokenExpirado) {
					var token = jwtHelper.decodeToken(resp);
					var json = JSON.parse(token.iss);
					// console.log(json);

					$scope.dt_nombre = json.Nombre;
					$scope.dt_apellido1 = json.Apellido1;
					$scope.dt_apellido2 = json.Apellido2;
					$scope.dt_razonsocial = json.RazonSocial;
					$scope.dt_codigopostal = parseInt(json.CodPostal);
					$scope.buscarCodigoPostalDT($scope.dt_codigopostal);
					$scope.dt_ciudad = json.Ciudad;
					$scope.dt_calle = json.Calle;
				}
			});
		}
	}

	/**
	 * Llamada - CNAEs
	 */
	$http({
		method: "GET",
		url: urlApi + "Empresa/GetAllCNAE/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
		headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
	}).then(function successCallback(response) {
		var resp = response.data;
		var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
		var tokenExpirado = jwtHelper.isTokenExpired(resp);

		if (tokenValido && !tokenExpirado) {
			var token = jwtHelper.decodeToken(resp);
			var json = JSON.parse(token.iss);
			$scope.listado_cnae = json;
		} else {
			$scope.listado_cnae = "";
			console.log("HASH DE VERIFICACION INCORRECTO");
		}
	});

	/**
	 * Convertir CodigoTarifaATREnVigor a texto de tarifa
	 */
	function convertTarifa(tarifa) {
		var res = null;
		switch (tarifa) {
			case "001": res = "2.0A ML"; break;
			case "003": res = "3.0A ML"; break;
			case "004": res = "2.0DHA ML"; break;
			case "005": res = "2.1A ML"; break;
			case "006": res = "2.1DHA ML"; break;
			case "007": res = "2.0DHS ML"; break;
			case "008": res = "2.1DHS ML"; break;
			case "011": res = "3.1A ML"; break;
			case "012": res = "6.1A ML"; break;
			case "013": res = "6.2 ML"; break;
			case "014": res = "6.3 ML"; break;
			case "015": res = "6.4 ML"; break;
			case "016": res = "6.5 ML"; break;
			case "017": res = "6.1B ML"; break;
		}
		return res;
	}

	/**
	 * Calcular consumo anual
	 */
	function calcularConsumoAnual(consumos) {
		if (!consumos.length > 0 || consumos == null) {
			$scope.msg_consumoanualnocalculado = true;
		} else {
			var consumo1 = 0, consumo2 = 0, consumo3 = 0, consumo4 = 0, consumo5 = 0, consumo6 = 0;
			consumos.forEach(function(consumo) {
				consumo1 += consumo.Activa1;
				consumo2 += consumo.Activa2;
				consumo3 += consumo.Activa3;
				consumo4 += consumo.Activa4;
				consumo5 += consumo.Activa5;
				consumo6 += consumo.Activa6;
			});

			var sumatorio = consumo1 + consumo2 + consumo3 + consumo4 + consumo5 + consumo6;
			var fechaInicio = new Date(consumos[0]['FechaInicio']).getTime();
			var fechaFin = new Date(consumos[consumos.length - 1].FechaFin).getTime();
			var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);

			$scope.msg_consumoanualnocalculado = false;
			$scope.dc_consumoanual = Math.round((sumatorio / countDias) * 365);
		}
	}

	/**
	 * Mostrar Notificación
	 */
	function show_toast(text) {
		$mdToast.show(
			$mdToast.simple()
				.textContent(text)
				.position('top right')
				.action('cerrar')
				.highlightAction(true)
				.hideDelay(3000)
		);
	}

	/**
	 * Validar CUPS
	 */
	function validarCups(cups) {
		var cod_dist = ($scope.ps_codigodistribuidora) ? $scope.ps_codigodistribuidora : cups.substring(2, 8);;
		var data = {
			"CodigoCUPS": cups,
			"CodigoReeDistribuidora": cod_dist,
			"IdEmpresa": IdEmpresa,
			"IdUsuario": IdUsuario,
		}
		$http({
			method: "POST",
			url: urlApi + "Verificacion/CUPS",
			data: JSON.stringify(data),
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
		}).then(function successCallback(response) {
			$scope.msg_cupsincorrecto = (response.data == true) ? false : true;
			// console.log(response.data);
		}, function errorCallback() {
			$scope.msg_cupsincorrecto = true;
		});
	}

	/**
	 * Validar CUPS
	 */
	function validarIdentificador(id) {
		var data = {
			"Identificador": id,
			"IdEmpresa": IdEmpresa,
			"IdUsuario": IdUsuario,
		}
		$http({
			method: "POST",
			url: urlApi + "Verificacion/Identificador",
			data: JSON.stringify(data),
			headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
		}).then(function (response) {
			var resp = response.data;
			var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
			var tokenExpirado = jwtHelper.isTokenExpired(resp);

			if (tokenValido && !tokenExpirado) {
				var token = jwtHelper.decodeToken(resp);
				var json = JSON.parse(token.iss);

				$scope.isPersonaDT = false;
				$scope.isRazonSocialDT = false;
				$scope.msg_identificadordtincorrecto = false;
				if (json.IsIdentificadorPersonaValido) { $scope.isPersonaDT = true;
				} else if (json.IsRazonSocialValido) { $scope.isRazonSocialDT = true;
				} else { $scope.msg_identificadordtincorrecto = true;
				}
			}
		});
	}

	/**
	 * Enviar el contrato
	 */
	$scope.enviarContrato = function() {
		console.log('Simulación de envío!');

	}

}]);