app.service('emailErrorContratos', ['jwtHelper', function (jwtHelper) {
  this.GetNetworkErrors = function (ErrorRecibido, Entorno) {

    if (Entorno) {
      var NombreEntorno = "GAS";
    } else {
      var NombreEntorno = "ELECTRICIDAD";
    }

    if (ErrorRecibido.status == 200) {
      var incidencias = ErrorRecibido.data.Contratos[0].Incidencias;

      var incidenciasLinea;
      for (var i = 0; i < incidencias.length; i++) {

        if (i > 0) {
          incidenciasLinea = incidenciasLinea + " ////// Error de  " + incidencias[i]['Propiedad'] + ", " + incidencias[i]['Mensaje'];
        } else {
          incidenciasLinea = "Error de  " + incidencias[i]['Propiedad'] + ", " + incidencias[i]['Mensaje'];
        }

      }

    } else {
      var incidencias = "";
    }

    if (ErrorRecibido.config.method == "POST") {
      var Body = ErrorRecibido.config.data;
    } else {
      var Body = "";
    }

    if (ErrorRecibido.config.headers.Authorization == null) {
      var user = "";
      var password = "";
      var authToken = "";
    } else {
      var decodeToken2 = jwtHelper.decodeToken("eyJhbGciOiJIUzI1NiJ9." + ErrorRecibido.config.headers.Authorization + ".pcAEZpmCXZtVNj009viMpnmIJSRbhv0hCc28IJhaoj0");

      var user = atob(decodeToken2.User);
      var password = atob(decodeToken2.Password);
      var authToken = ErrorRecibido.config.headers.Authorization;
    }

    jQuery.ajax({
      type: "POST",
      url: 'lib/php/email_error_contratos.php',
      data: {
        method: ErrorRecibido.config.method,
        body: Body,
        url: ErrorRecibido.config.url,
        status: ErrorRecibido.status,
        statusText: ErrorRecibido.statusText,
        authToken: authToken,
        tokenUser: user,
        tokenPassword: password,
        empresa: nombreEmpresa,
        incidencias: incidenciasLinea,
        entorno: NombreEntorno,
      },

    });

  }

}]);