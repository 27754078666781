app.controller('ResumenCtrl', ['$scope', '$http', '$location', '$mdDialog', function($scope, $http, $location, $mdDialog) {
    'use strict';

    var TipoAgente = atob(localStorage.getItem('tipoAgente'));

    $scope.MostrarComisionesDistribuidor = MostrarComisionesDistribuidor;
    $scope.MostrarComisionesAgente = MostrarComisionesAgente;
    $scope.MostrarComisionesSubAgente = MostrarComisionesSubAgente;
    $scope.TipoAgente = TipoAgente;

    $scope.ResumenClientes = ResumenClientes;
    $scope.ResumenLeads = ResumenLeads;
    $scope.ResumenContratosActivos = ResumenContratosActivos;
    $scope.ResumenTotalContratos = ResumenTotalContratos;
    $scope.ResumenDeuda = ResumenDeuda;
    $scope.ResumenComisiones = ResumenComisiones;
    $scope.ResumenRanking = ResumenRanking;
    $scope.MostrarEnlaceContrato=MostrarEnlaceContrato;

    $scope.ResumenUltimosContratos = ResumenUltimosContratos;
    $scope.ResumenComisionistas = ResumenComisionistas;
    $scope.ResumenProximasTareas = ResumenProximasTareas;
    $scope.ResumenNotificaciones = ResumenNotificaciones;

    $scope.GraficoResumenContratos = GraficoResumenContratos;
    $scope.GraficoResumenTipoContratos = GraficoResumenTipoContratos;

    $scope.MostrarResumenNotificacionesNombre = MostrarResumenNotificacionesNombre;

	// Sugerir cambio de contraseña si no ha sido cambiada nunca
	// if (localStorage.getItem('forzarPassword') === 'true') {
	// 	var confirm = $mdDialog.confirm()
	// 		.title('Cambio de contraseña')
	// 		.htmlContent('La contraseña no ha sido cambiada nunca.<br>Es recomendable cambiarla tras el primer acceso.')
	// 		.ok('Aceptar')
	// 		.cancel('Cerrar');
	// 	$mdDialog.show(confirm).then(function() {
	// 		$location.url("password");
	// 	});
	// 	localStorage.setItem('forzarPassword', false);
	// }

    //Controlo que si el menu esta deshabilitado, no haga ni la llamada
    if (MenuTeleventa) {
        $http.get("lib/php/total_leads.php").then(function(response) {
            $scope.leadsPendientes = response.data[0].total;
        });
    }

    $scope.sendContrato = function(datos) {
        localStorage.setItem('numeroPoliza', btoa(datos.contrato.IdContrato));
        localStorage.setItem("idCliente", btoa(datos.contrato.IdCliente));
        localStorage.setItem("codigoContrato", btoa(datos.contrato.CodigoContrato));
        $location.url("/contratos/resumen");
    };

    $scope.sendCliente = function(datos) {
        localStorage.setItem('identificador', btoa(datos.contrato.IdCliente));
        $location.url("/clientes/perfil");
    };

    $scope.sendCups = function(datos) {
        localStorage.setItem('cups', btoa(datos.contrato.CodigoCUPS));
        $location.url("/contratos/cups");
    };

}]);