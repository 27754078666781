//Fichero para las variables personalizables de cada empresa
var nombreEmpresa = "Remica";
var nombreEmpresaLogin = "Remica";
var CodigoREEComercializadora = '';
var IdContratoSituacionDefecto = '9'; // Pendiente validación
var urlApi = "https://sige.gruporemica.net:82/Remica/api/v1/"; //Remica API
var tokenKey = "cgBhADQAYQAyAEQAegBjAE0AaABZAEQARwBjAGUARwBDADkAOABTAA==";
var urlApiSips = "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips";
var urlApiSipsGas = "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSipsGas";
// var urlCallejero = "https://www.sigeenergia.com:8168/api/v1/";
// var urlCallejeroNew = "https://www.sigeenergia.com:8168/api/v1/";
var urlCallejero = "https://apicallejero.sigeenergia.com/api/v1/";
var urlCallejeroNew = "https://apicallejero.sigeenergia.com/api/v1/";
var informacionSipsCliente = false;
var nombrePortal = "Portal de Agentes Comerciales";

var MostrarExportarDatosContrato = false;
var MostrarImportarDatosContrato = false;
var MostrarContratosValorCO = false;
var disabledCodPostal = false;
var MostrarSipsInfoGeneral = false;

//Variables para mostrar en el login
var MostrarNombreEmpresa = false;
var MostrarSipsPotenciasSige = false;

var MostrarAltaConsumoAnual = true;
var requiredOtrosIdiomaContrato = true;

var TextoDescargarSips = "DESCARGAR INFORMACION GENERAL";
var MostrarContratosConsumoAnualEstimado = false;
var MostrarPorcentajeCO = false;
var MostrarComisionCalculada = false;
var MostrarFechaAlta = true;
var InsertarDocumentoPerfilPDF = true;
var MostrarContratosValorCO = false;

//Variables para mostrar campos del menu
var MenuResumen = true;
var MenuSips = true;

var MenuFacturas = false;
var MenuContratos = true;
var MenuComerciales = true;
var MenuClientes = true;
var MenuComisiones = false;
var MenuIncidencias = true;
var MenuDocumentacion = true;
var MenuAgenda = false;
var MenuTeleventa = false;
var MenuTeleventaFiltrado = false; //Esta variable es para controlar el menu de Leads y el resto esten ocultos.

//Variables de los desplegables
var ValorDesplegablePeriodo = "";
var ValorDesplegableTipoSolicitud = "C1";
var ValorDesplegableModeloContrato = "Contrato Remica Nuevo";
var ValorDesplegableTipoImpresion = "";
var ValorDesplegableIdiomaContrato = "Castellano";
var ValorDesplegableTipoCobro = "Domiciliado 7 días f.f.";
var ValorDesplegableGrupoProducto = "";

var MostrarContratosPorMes = true;
var MostrarAltaServiciosValorAnadido = false;
var MostrarContratoAdjuntarFicheroPDF = false;
var TextoContratosGrupoTarifa = "Producto";
var TextoContratosGrupoTarifaDesplegable = "Seleccione un Producto";

var TextoBotonDescargarSips = true;
var TextoBotonInsertar = "INSERTAR";
var TextoContratos = "Contratos / Facturas";

//Variables para edicion de los campos
//El valor true indica que el campo NO va a ser editable, el false indica que SI es editable
var CampoTarifaEditable = true;
var CampoProductoEditable = false;
var CampoPerfilFacturacionEditable = true;
var CampoPotenciaP1Editable = true;
var CampoPotenciaP2Editable = true;
var CampoPotenciaP3Editable = true;
var CampoPotenciaP4Editable = true;
var CampoPotenciaP5Editable = true;
var CampoPotenciaP6Editable = true;
var CampoCaudalEditable = true;
var CampoPeriodoEditable = false;
var CampoTipoImpresionEditable = false;
var CampoTipoSolicitudEditable = true;
var CampoModeloContratoEditable = true;
var CampoIdiomaContratoEditable = true;
var CampoGrupoProductoEditable = true;
var CampoTipoCobroEditable = true;
var MostrarAltaCosteGestion = false;
var MostrarAltaDatosRepresentante = true;
var MostrarAltaDestinoEnergiaGas = false;
var MostrarAltaMantenimiento = false;
var MostrarFechaFin = true;

var CampoPeriodoOcultable = false;
var CampoCaudalOcultable = false;
var CampoTipoImpresionOcultable = false;
var CampoTipoSolicitudOcultable = false;
var CampoModeloContratoOcultable = false;
var CampoIdiomaContratoOcultable = false;
var CampoTipoCobroOcultable = false;

//Variables para mostrar campos del resumen
var ResumenClientes = true;
var ResumenLeads = false;
var ResumenContratosActivos = true;
var ResumenDeuda = true;
var ResumenComisiones = false;

var ResumenUltimosContratos = true;
var ResumenComisionistas = false;
var ResumenProximasTareas = true;
var ResumenNotificaciones = true;

var GraficoResumenContratos = true;
var GraficoResumenTipoContratos = true;

//Variables para mostrar campos en SIPS
var MostrarSipsCups = true;
var MostrarSipsDistribuidora = true;
var MostrarSipsMunicipio = true;
var MostrarSipsProvincia = true;
var MostrarSipsCodigoPostal = true;
var MostrarSipsTarifa = true;
var MostrarSipsTension = true;
var MostrarSipsPresion = true;
var MostrarSipsCaudal = true;
var MostrarSipsCaudalMaximo = true;
var MostrarSipsP1 = true;
var MostrarSipsP2 = true;
var MostrarSipsP3 = true;
var MostrarSipsP4 = true;
var MostrarSipsP5 = true;
var MostrarSipsP6 = true;
var MostrarSipsConsumoP1 = true;
var MostrarSipsConsumoP2 = true;
var MostrarSipsConsumoP3 = true;
var MostrarSipsConsumoP4 = true;
var MostrarSipsConsumoP5 = true;
var MostrarSipsConsumoP6 = true;
var MostrarContratosDniCif = true;
var MostrarSipsUltimoCambioComercializadora = true;
var MostrarSipsUltimoCambioBIE = true;
var MostrarSipsUltimoCambioContrato = true;
var MostrarSipsConsumoAnual = true;
var MostrarSipsFechaFinContrato = true;
var MostrarIncidenciaSelect = false;
var MostrarIncidenciaInput = true;

//Variables para mostrar campos en Facturas
var MostrarFacturasSerieNumero = true;
var MostrarFacturasContrato = true;
var MostrarFacturasFecha = true;
var MostrarFacturasEnvioEmail = false;
var MostrarFacturasTipoFactura = true;
var MostrarFacturasImporteBase = true;
var MostrarFacturasTipoImpuesto = true;
var MostrarFacturasPorcentaje = true;
var MostrarFacturasImpuesto = true;
var MostrarFacturasTotal = true;
var MostrarFacturasLecturaAnterior = true;
var MostrarFacturasFechaLectura = true;
var MostrarFacturasPotencia = true;
var MostrarFacturasEnergia = true;
var MostrarFacturasReactiva = true;
var MostrarFacturasExcesosPotencia = true;
var MostrarFacturasDtoPotencia = true;
var MostrarFacturasDtoEnergia = true;
var MostrarFacturasAlquiler = true;
var MostrarFacturasProductos = true;
var MostrarFacturasTarifa = true;
var MostrarFacturasRectificativa = true;
var MostrarFacturasAbono = true;
var MostrarFacturasOrigen = true;

//Variables para mostrar campos de Contratos
var MostrarContratosFacturas = true;
var MostrarContratosPDF = true;
var MostrarContratosEnvioEmail = false;
var MostrarContratosIdAgente = false;
var MostrarContratosIdSubagente = true;
var MostrarContratosTarifaGrupo = true;
var MostrarContratosContrato = true;
var MostrarContratosSituacion = true;
var MostrarContratosCaudal = true;
var MostrarContratosDesplegableSituacion = false;

var MostrarContratosNombre = true;
var MostrarContratosCUPS = true;
var MostrarContratosDireccionCUPS = true;
var MostrarContratosCiudadCUPS = true;
var MostrarContratosFechaAlta = true;
var MostrarContratosFechaBaja = true;
var MostrarContratosPotencia = true;
var MostrarContratosTarifa = true;
var MostrarContratosDeuda = true;
var MostrarContratosCategoriaCliente = false;
var MostrarPreciosFijosVigentes = false;
var MostrarContratosFacturacionSegmentacion = true;
var MostrarContratosProximaRenovacion = true;

//Variables para mostrar a la hora de hacer un alta de contrato
var MostrarContratoAltaPerfilFacturacion = true;

//Variables para mostrar en la búsqueda de un contrato
var MostrarContratosBusquedaContrato = true;
var MostrarContratosBusquedaV = true;
var MostrarContratosBusquedaNombre = true;
var MostrarContratosBusquedaCUPS = true;
var MostrarContratosBusquedaDireccionCUPS = true;
var MostrarContratosBusquedaCiudadCUPS = true;
var MostrarContratosBusquedaSituacion = true;
var MostrarContratosBusquedaFechaAlta = true;
var MostrarContratosBusquedaFechaBaja = true;
var MostrarContratosBusquedaPotencia = true;
var MostrarContratosBusquedaTarifa = true;
var MostrarContratosBusquedaDeuda = true;

var MostrarContratosBusquedaCarteraContrato = true;
var MostrarContratosBusquedaCarteraSerieNumero = true;
var MostrarContratosBusquedaCarteraFecha = true;
var MostrarContratosBusquedaCarteraVencimiento = true;
var MostrarContratosBusquedaCarteraFactura = true;
var MostrarContratosBusquedaCarteraImporteNominal = true;
var MostrarContratosBusquedaCarteraImporteGasto = true;
var MostrarContratosBusquedaCarteraImporteTotal = true;
var MostrarContratosBusquedaCarteraSituacionLibre = true;
var MostrarContratosBusquedaCarteraSituacion = true;
var MostrarContratosBusquedaCarteraTipoCobro = true;
var MostrarContratosBusquedaCarteraIBAN = true;
var MostrarContratosBusquedaCarteraFechaRemesar = true;
var MostrarContratosBusquedaCarteraFechaCobro = true;
var MostrarContratosBusquedaCarteraMedioCobro = true;
var MostrarContratosBusquedaCarteraRemesaCobro = true;
var MostrarContratosBusquedaCarteraFechaImpagado = true;
var MostrarContratosBusquedaCarteraRemesaImpagado = true;
var MostrarContratosBusquedaCarteraMotivo = true;
var MostrarContratosBusquedaCarteraFechaAplazamiento = true;
var MostrarContratosBusquedaCarteraTipoAplazamiento = true;
var MostrarContratosBusquedaCarteraDevolucionAutorizada = true;
var MostrarContratosBusquedaCarteraFechaDevolucion = true;
var MostrarContratosBusquedaCarteraFechaAvisoPrevisto = true;
var MostrarContratosBusquedaCarteraFechaAvisoReal = true;
var MostrarContratosBusquedaCarteraFechaAvisoCorte = true;
var MostrarContratosBusquedaCarteraAvisoCorteReal = true;
var MostrarContratosBusquedaCarteraFechaCortePrevisto = true;
var MostrarContratosBusquedaCarteraFechaCorteReal = true;

var MostrarContratosBusquedaCallCenterRegistro = true;
var MostrarContratosBusquedaCallCenterFecha = true;
var MostrarContratosBusquedaCallCenterContrato = true;
var MostrarContratosBusquedaCallCenterMedio = true;
var MostrarContratosBusquedaCallCenterIncidencia = true;
var MostrarContratosBusquedaCallCenterSolucion = true;
var MostrarContratosBusquedaCallCenterReclamacion = true;
var MostrarContratosBusquedaCallCenterCierre = true;

var MostrarContratosBusquedaPresupuestoPresupuesto = true;
var MostrarContratosBusquedaPresupuestoSituacion = true;
var MostrarContratosBusquedaPresupuestoTipo = true;
var MostrarContratosBusquedaPresupuestoContrato = true;
var MostrarContratosBusquedaPresupuestoCUPS = true;
var MostrarContratosBusquedaPresupuestoNombre = true;
var MostrarContratosBusquedaPresupuestoFechaPresupuesto = true;
var MostrarContratosBusquedaPresupuestoFechaFinal = true;
var MostrarContratosBusquedaPresupuestoFechaActivacion = true;
var MostrarContratosBusquedaPresupuestoFechaCierre = true;
var MostrarContratosBusquedaPresupuestoTarifa = true;
var MostrarContratosBusquedaPresupuestoTarificador = true;
var MostrarContratosBusquedaPresupuestoDescripcion = true;

/* MODALES IMPORTACION MASIVA */
var ModalImportacionExcelMasiva = false;
var ModalImportacionExcelMasivaFormatoLumar = false;
var ModalImportacionExcelMasivaComisiones = false;
var MostrarBotonDescargaExcelContratos  = false;


//Variables para mostrar campos de Clientes
var MostrarClientesContrato = true;
var MostrarClientesIdentificador = true;
var MostrarClientesNombre = true;
var MostrarClientesRazonSocial = true;
var MostrarClientesDireccion = true;
var MostrarClientesTelefono = true;
var MostrarClientesEmail = true;

//Variables para mostrar campos en Comisiones
var MostrarComisionesDistribuidor = true;
var MostrarComisionesAgente = true;
var MostrarComisionesSubAgente = true;

var MostrarComisionesNombreAgente = true;
var MostrarComisionesTexto = true;
var MostrarComisionesFechaCalculoComision = true;
var MostrarComisionesFechaFactura = true;
var MostrarComisionesImporteCalculado = true;
var MostrarComisionesImporteBase = true;
var MostrarComisionesNumeroFactura = true;
var MostrarComisionesCodigoCUPS = true;

var MostrarComisionesParametrosTipoComision = true;
var MostrarComisionesParametrosCliente = true;
var MostrarComisionesParametrosTarifaPeaje = true;
var MostrarComisionesParametrosValor = true;
var MostrarComisionesParametrosContrato = true;
var MostrarComisionesParametrosCUPS = true;

//Variables para mostrar campos en Comerciales
var MostrarComercialesIdAgente = true;
var MostrarComercialesNombreAgente = true;
var MostrarComercialesDireccion = true;
var MostrarComercialesCodigoPostal = true;
var MostrarComercialesCiudad = true;
var MostrarComercialesTelefono = true;
var MostrarComercialesMovil = true;
var MostrarComercialesEmail = true;
var MostrarComercialesWeb = true;
var MostrarComercialesIdAgenteGrupo = true;
var MostrarComercialesNotas = true;
var MostrarComercialesCodigoTipoAgente = true;
var MostrarComercialesEmailSolicitud = true;
var MostrarComercialesCodigoVendedor = true;

//Variables para Mostrar Televenta
var MostrarTeleventaNombreCreador = true;
var MostrarTeleventaAsignado = true;
var MostrarTeleventaDni = true;
var MostrarTeleventaNombre = true;
var MostrarTeleventaApellidos = true;
var MostrarTeleventaEmail = true;
var MostrarTeleventaTelefono = true;
var MostrarTeleventaCodPostal = true;
var MostrarTeleventaPoblacion = true;
var MostrarTeleventaProvincia = true;
var MostrarTeleventaNombreVia = true;
var MostrarTeleventaNumeroVia = true;
var MostrarTeleventaInformacionAdicional = true;
var MostrarTeleventaCups = true;
var MostrarTeleventaTarifaAcceso = true;
var MostrarTeleventaDireccionSuministro = true;
var MostrarTeleventaIban = true;
var MostrarTeleventaNombreTarifa = true;
var MostrarTeleventaP1 = true;
var MostrarTeleventaP2 = true;
var MostrarTeleventaP3 = true;
var MostrarTeleventaP4 = true;
var MostrarTeleventaP5 = true;
var MostrarTeleventaP6 = true;
var MostrarTeleventaFechaInsercion = true;
var MostrarTeleventaHorarioLlamada = true;
var MostrarTeleventaEstado = true;
var MostrarTeleventaPlataforma = true;
var MostrarTeleventaTarjetaDisa = true;
var MostrarTeleventaNombreArchivo = true;
var MostrarTeleventaBono = true;

//Variables para Mostrar estados Televenta
var MostrarTeleventaEliminar = true;
var MostrarTeleventaInsertar = true;
var MostrarTeleventaOferta = true;
var MostrarTeleventaConfirmado = true;
var MostrarTeleventaValidado = true;
var MostrarTeleventaFalloLead = true;
var MostrarTeleventaRechazaOferta = true;
var MostrarTeleventaNoConfirmado = true;
var MostrarTeleventaNoValidado = true;

//Variables para mostrar campos en Incidencias
var MostrarIncidenciasCodigoContrato = true;
var MostrarIncidenciasDestino = true;
var MostrarIncidenciasFecha = true;
var MostrarIncidenciasFechaCierre = true;
var MostrarIncidenciasIncidencia = true;
var MostrarIncidenciasSolucion = true;
var MostrarIncidenciasMedio = true;

//Variables para habilitar subida de ficheros en perfiles distribuidor
var SubirFicheros = true;

//Valores requeridos altas

/*------- GENERAL ---------

  --- Punto Suministro ---- */
var requiredGeneralCUPS = true;
var requiredGeneralCodDistribuidora = true;
var requiredGeneralCodComercializadora = false;
var requiredGeneralCiudad = true;
var requiredGeneralCalle = true;
var requiredGeneralNumero = false;
var requiredGeneralAclarador = false;
var requiredGeneralCodPostal = true;
var requiredGeneralProvincia = true;

//------- Potencias ---------
var requiredPotenciasTarifaAcceso = true;
var requiredPotenciasGrupoTarifa = true;
var requiredPotenciasPerfilFacturacion = true;

//------- Servicios ---------
var requiredServiciosGrupoProducto = false;
var requiredServiciosProducto = false;


/*------- CONTRATO ---------

  --- Datos Titular ---- */
var requiredContratoIdentificador = true;
var requiredContratoCodigoPostal = true;
var requiredContratoCiudad = true;
var requiredContratoCalle = true;
var requiredContratoNumero = false;
var requiredContratoAclarador = false;
var requiredContratoProvincia = true;
var requiredContratoTelefono = true;
var requiredContratoMovil = false;
var requiredContratoEmail = true;
var requiredContratoCategoriaCliente = false;
var requiredContratoNombre = true;
var requiredContratoApellido1 = true;
var requiredContratoApellido2 = true;
var requiredContratoRazonSocial = false;
var disabledRazonSocial = true;

//------- Datos Envío ---------
var requiredDatosEnvioNombre = true;
var requiredDatosEnvioCodPostal = true;
var requiredDatosEnvioCiudad = true;
var requiredDatosEnvioCalle = true;
var requiredDatosEnvioNumero = false;
var requiredDatosEnvioAclarador = false;
var requiredDatosEnvioProvincia = true;


/*------- OTROS ---------

  ---- Datos Bancarios ---- */
var requiredOtrosNombrePagador = true;
var requiredOtrosIdentificador = true;
var requiredOtrosTipoCobro = true;
var requiredOtrosIBAN = true;

//------- Otros Datos ---------
var requiredOtrosPeriodo = false;
var requiredOtrosTipoImpresion = false;
var requiredOtrosTipoSolicitud = false;
var requiredOtrosModeloContrato = false;
var requiredOtrosCodigoVendedor = false;
var requiredTarjetaClubDisa = false;
var requiredOtrosFechaEfecto = true;
var requiredOtrosActivacionPrevista = false;

/* Valores a mostrar altas */
var MostrarCategoriaCliente = false;
var MostrarTarjetaClubDisa = false;
var MostrarBonosDisa = false;
var MostrarFechaEfecto = true;
var MostrarActivacionPrevista = true;

/*------- LEAD ---------

  ------- PASO 1 -------- */

var requiredLeadNombre = false;
var requiredLeadTelefono = false;


/*---------- DISA -----------
  --- Avisos Certificados --- */

  var AvisosCertificados = false;

  //---- Alta Contratos LEAD ----
  var ParametrosAltaContratoDefecto = false;
  var GrupoTarifaDefecto = "";
  var PeriodoTarifaDefecto = "MENSUAL";
  var TipoImpresionTarifaDefecto = "E";
  var CNAEDefecto = "9820";
  var TipoSolicitudTarifaDefecto = "CZ";

  //-------- Contratos ---------
  var MostrarDesplegableEstadosContrato = false;
  var EmailCertificadoChecked = false;
  var SMSCertificadoChecked = false;

  var MostrarContratosFacturacionRevisionFacturas = true;
var MostrarContratosGeneralObservaciones = true;

var MostrarContratoBotonAltaElectricidad = true;
var MostrarContratoBotonAltaGas = true;
var requiredContratoCosteOperacion = false;
var requiredOtrosCNAE = true;
var ValorComisionesTabComisiones = "Comisiones";
var ValorComisionesTabAgentes = "Agente Parámetro";
var MostrarComisionesTipoComision = false;
var MostrarComisionesCliente = false;
var MostrarComisionesValor = false;
var MostrarComisionesContrato = false;
var MostrarAltaPCTD = false;
var MostrarAltaObservaciones = true;
var ResumenRanking = false;
var MostrarSipsBuscadorCups = true;
var MostrarSipsBuscadorDistribuidora = true;
var MostrarSipsBuscadorCodigoPostal = true;
var MostrarSipsBuscadorDni = false;
var MostrarSipsBuscadorDireccion = false;
var MostrarSipsBuscadorTitular = false;
var MostrarSipsBuscadorTelefono = false;
var MostrarSipsBuscadorTarifa = true;
var MostrarSipsBuscadorListaCups = true;
var MostrarSipsBuscadorGraficoConsumos = false;
var MenuCambioPassword = false;
var ValorDesplegableDestinoEnergia = "";
var MostrarSipsTrasladarContrato = false;
var MostrarLOPD = false;
var MostrarContratoSegmento = false;
var MostrarContratoTipoCliente = false;
var MostrarContratoCanalOrigen = false;
var MostrarContratoSubcanalOrigen = false;
var MostrarContratoCodigoPromocional = false;
var MostrarSegmento = false;
var MostrarTipoCliente = false;
var MostrarCanalOrigen = false;
var MostrarSubcanalOrigen = false;
var MostrarCodigoPromocional = false;
var MostrarTeleventaSegmento = false;
var MostrarTeleventaTipoCliente = false;
var MostrarTeleventaCanalOrigen = false;
var MostrarTeleventaSubcanalOrigen = false;
var MostrarTeleventaCodigoPromocional = false;
var MostrarSegmento = false;
var MostrarTipoCliente = false;
var MostrarCanalOrigen = false;
var MostrarSubcanalOrigen = false;
var MostrarCodigoPromocional = false;
var CondicionesContractuales = true;
var ValorDesplegableEmpresa = "";
var requiredCaudalContrato = false;

var MostrarContratosValorCO = false;
var TextoContratosAclarador = "Aclarador";
var disabledCodPostal = false;
var MostrarSipsInfoGeneral  = false;
var FechaEstimadaInicioSuministroDisabled = false;
var DiasFechaEstimadaInicioSuministro = 4;
var MostrarContratosFechaVto = true;
var ValorDesplegableFechaEfecto = "Cuanto antes(2017)";
var MostrarContratoAltaDomestico = false;
var requiredMotivoModificacion = false;
var MostrarMotivoModificacion = false;
var requiredContratoAltaDomestico = false;
var MostrarContratosEnlaceDniCif = true;
var MostrarContratoPerfilGeneral = true;
var MostrarContratoPerfilFacturacion = true;
var MostrarContratoPerfilContrato = true;
var MostrarContratoPerfilDocumentos = true;
var MostrarTablaClientes = true;
var ValorDesplegableTipoSolicitudGas = "";
var ValorDesplegableModeloContratoGas = "";
var ValorDesplegableIdiomaContratoGas = "Castellano";
var CampoIdiomaContratoEditableGas = false;
var disabledFechaEfecto = false;
var ValorDesplegableFechaEfectoGas = "";
var FechaEstimadaFinSuministroDisabled = false;
var MostrarBotonDescargaMet  = false;
var MostrarContratosContratoDetalle = true;
var MostrarCheckPasaporte = false;
var SituacionCambio1 = '';
var SituacionCambio1a = [{'TextoSituacion':'A',"IdContratoSituacion":0},{'TextoSituacion':'B',"IdContratoSituacion":0},{'TextoSituacion':'C',"IdContratoSituacion":0}];
var SituacionCambio2 = '';
var SituacionCambio2a = [{'TextoSituacion':'A',"IdContratoSituacion":0},{'TextoSituacion':'B',"IdContratoSituacion":0},{'TextoSituacion':'C',"IdContratoSituacion":0}];
var SituacionCambio3 = '';
var SituacionCambio3a = [{'TextoSituacion':'A',"IdContratoSituacion":0},{'TextoSituacion':'B',"IdContratoSituacion":0},{'TextoSituacion':'C',"IdContratoSituacion":0}];
var ocultarPotenciasVacias = false;
var DiasInicioUsoDomestico = false;
var MostrarClientesPerfilObservaciones = true;
var MostrarContratosCupsObservaciones = true;
var FechaEstimadaFinSuministroDisabledLuz = false;
var MostrarLoginPoliticaUso = false;
var MostrarLoginURLPoliticaUso = "";
var MostrarRecuperarContrasena = false;
var MostrarContratosNombreIndependiente = false;
var MostrarContratosApellidoUno = false;
var MostrarContratosApellidoDos = false;
var MostrarContratosRazonSocial = false;
var MostrarContratosNombreParticionado = false;
var MostrarContratosOrdenacionSituacion = true;
var MostrarContratosOrdenacionListadoFacturas = true;
var MostrarContratosOrdenacionContratoPDF = true;
var MostrarContratosOrdenacionEnvioEmail = true;
var MostrarContratosOrdenacionNombreAgente = true;
var MostrarContratosOrdenacionNombreSubagente = true;
var MostrarContratosOrdenacionTarifaGrupo = true;
var MostrarContratosOrdenacionCodigoContrato = true;
var MostrarContratosOrdenacionDniCif = true;
var MostrarContratosOrdenacionCategoriaCliente = true;
var MostrarContratosOrdenacionNombre = true;
var MostrarContratosOrdenacionApellido1 = true;
var MostrarContratosOrdenacionApellido2 = true;
var MostrarContratosOrdenacionCUPS = true;
var MostrarContratosOrdenacionConsumoAnualEstimado = true;
var MostrarContratosOrdenacionDireccionCUPS = true;
var MostrarContratosOrdenacionCiudadCUPS = true;
var MostrarContratosOrdenacionFechaAlta = true;
var MostrarContratosOrdenacionFechaBaja = true;
var MostrarContratosOrdenacionPotencia1 = true;
var MostrarContratosOrdenacionPotencia2 = true;
var MostrarContratosOrdenacionPotencia3 = true;
var MostrarContratosOrdenacionPotencia4 = true;
var MostrarContratosOrdenacionPotencia5 = true;
var MostrarContratosOrdenacionPotencia6 = true;
 var MostrarContratosOrdenacionCaudal = true;
var MostrarContratosOrdenacionTarifa = true;
var MostrarContratosOrdenacionDeuda = true;
var MostrarContratosOrdenacionValorCO = true;
var MostrarContratosOrdenacionRazonSocial = true;
var MostrarContratosOrdenacionDenominacion = true;
var MostrarContratosDenominacion = true;
var ContratoPerfilDocumentosBloqueadoEstado = "";
var MostrarSubidaDocumentacion = true;
var MostrarAccionesDocumentacion = true;
var MostrarResumenEtiquetaContratoActivo = false;
var MostrarAltaComentarioPiePagina = false;
var ComentarioPiePaginaDisabled = false;
var MostrarContratoFacturacionCuadroTarifaAcceso = true;
var UnidadesValorSeguimientoComisiones = '€';
var MostrarComisionesTarifaGrupo = false;
var MostrarComisionesParametrosTarifaGrupo = false;
var MostrarPeriodoFacturacion = true;
var MostrarAltaTablaPreciosTarifas = false;
var MostrarAltaInfoFeeMinMax = false;
var feedisabled = true;
var MostrarDatosIdentificadorDeAgente = false;
var MostrarContratoPerfilFacturacionPerfilFacturacion = true;
var MostrarContratoPerfilFacturacionSistemaContable = true;
var TextoPoliticaUso = '';
var TextoPoliticaUso2 = '';
var MostrarLoginURLPoliticaUso2 = '';
var TextoPoliticaUso3 = '';
var MostrarLoginURLPoliticaUso3 = '';
var URLAdjuntosLeads = '';
var OcultaEsteModeloContrato = '';
var LlamadaModeloContratoSinAgente = false;
var MostrarEnlaceContrato = true;
var ResumenTotalContratos = false;
var MostrarBIC = false;
var MenuCodigosPromocionales = false;
var MostrarPotenciaMaxima = true;
var AgenteFinal = false;
var MostrarContratosAgenteFinal = false;
var MenuComparador = false;
var cambioNombreMenuIncidenciasXComunicacion = false;
var comprobacionAceptacionLogin = false;
var versionCondicionesUso = "V1";
var agrupacionXEstado = false;
var MostrarContratoFacturacionCuadroPrecioContrato = false;
var MostrarContratosSituacionWeb = false;
var MostrarCheckCertificadoElectronico = false;
var tiempoDeIntervalo = 600000; // CADA 10 MINUTOS LANZARA LAS LLAMADAS DE CARGA EL INTERVAL -> SE ENCUENTRA EN MENU-CTRL.JS
var MostrarListadoContratosColumnaFechaContrato = false;
var MostrarListadoContratosColumnaFechaPActivacion = false;
var noBloquearDistribuidora = false;

var MostrarComunicados = false; // Habilita nueva funcionalidad dentro del perfil del contratos y nº de avisos en la cabecera
var MenuComunicados = false; // Muestra una nueva seccion comunicados en menu | ELEIA
var MostrarContratoPerfilComunicados = false; // muestra tab comunicados -> en perfil contrato


var MostrarComunicadosCodigoContrato = false;

var MostrarComunicadosIdComunicado = false;
var MostrarComunicadosContrato = false;
var MostrarComunicadosFechaBackOffice = false;
var MostrarComunicadosNombre = false;
var MostrarComunicadosCUPS = false;
var MostrarComunicadosUsuario = false;
var MostrarComunicadosFechaRegistro = false;
var MostrarComunicadosEstadobackoffice = false;
var MostrarComunicadosCategoria = false;
var MostrarComunicadosSubcategoria = false;
var numeroDigitosMaximosTelefono = 12;
var TextoPeriodoConsumo = 'Consumo anual';
var PeriodoConsumo = 1;


var MostrarAltaContratoTantosFeesComoPeriodos = false;
var SimplificacionesEnergiaGalega = false;
var ValidacionPatternTelefono = true;
var MostrarMensajeAltaTelematica = false;
var requiredAltaDatosRepresentanteSiEsNif = false;

var comparadorLlamadaPreciosIndexadoEstandar = true;
var impuestoExtraPrecioEnergia = 1;
var  requiredOtrosColectivo = false;


var MostrarResumenNotificacionesNombre = false;

var OcultarNombresIncidenciaSige = false;
var RequerirFeeManual = false;
var urlApiComparadorTemporal = "";
var RepresentanteColectivoSoloPersonaFisica = false;
var ValidacionCupsEmpieceXES = false;
var MostrarNuevaIncidencia = true;
var MostrarAltaConsumoAnualPeriodos = false;

var MostrarContratosEmail = false;
var MostrarContratosTelefono = false;
var MostrarContratosMovil = false;
var IdMarca = "";
var IdMarcaGas = "";
var ObservacionesDisabled = false;
var MostrarContratosComisionAnualEstimada = false;
var MustComprobarRepresentante = false;
var asignacionModelosImpresionEleia = false;
var ValidacionRequeridaIban = true;
var cambioNombreMenuIncidenciasXReclamaciones=false;
var camposAdicionalesListadoContratoOvo = false;
var ObligatorioRepresentantePersonaFisica = false;
var AutoAsignacionColectivoRepresentante = false;


var UrlApiCorrecionImprimirContratoBien = '';
var RedirectAfterLogin = "/resumen";
var asignacionModelosImpresionPorNombreProducto = false;
var permitircallenoencontrada = false;
var MenuAltaRapida = false;
var abrirContratoVentanaNueva = false;
var AnularAlta = false;
var MostrarAltaContratoCaudalGas = true;
var AdjuntarFicheroComparador = false;
var MenuComparadorGas = false;
var AltaContratoIsElectronico = false;
var MostrarParticularOEmpresaFiltroTarifas = false;
var MostrarPerfilContratoDocumentosFacturas = true; // controla que en el perfil del contrato solo carga los documentos y no las facturas en casi de false
var MostrarComparadorGasCaudalContratado = true;
var MostrarComparadorGasCaudalFacturado = true;
var ComparadorGasCalculoConTerminoFijo = false;
var MostrarComparadorGasConCalculoTerminoFijo = false;
var sumaTarifaPeajeAPrecioEnergiaGas = false;
var CambioAgenteXCanalEleia = false;
var NombreTituloPortal = "Portal Agentes Comerciales";
var CaudalPuedeSerNuloCero = true;


var sidebarPosition = "vertical"; // opciones: vertical/horizontal
var ValorDesplegablePeriodoGas = '';
var MostrarSipsBusquedaPorTitular = false;
var MostrarAltaContratoFechaEfecto2017 = true;
var MostrarAltaContratoFechaEfectoSolo2017 = false;
var FechasDisa = false;
var MenuRenovaciones = false;
var MostrarContratosRenovacion = false;
var OcultarComisionAnualEstimada = false;
var MostrarComunicadosCanal = false;
var MostrarComunicadosSubagente = false;
var OrdenarFechasIncidencias = false;
var MenuInformes = false;
var InformesUrlPowerBi = '';

var ContratoAltaInsertarEntornoAclarador = false;
var MostrarAltaContratoRefCatastral = false;
var requiredRefCatastral = false;
var MostrarComisionListadoOneShot = false;
var MostrarComisionListadoCarterizado = false;
var MostrarTipoVenta = false;
var MostrarPrecioFEEComoMinimo = false;

var repartoconsumoBOEalta = false;
var comparadorBOEhabilitado = false;
var FiltrarListadoContratoPorIdMarca = false;
var GuixesCRM = false;
var ValorDesplegableTipoVenta = "";
var OcultarComisionAgentes = false;
var OcultarTabComisiones = false;
var MostrarTablaComisionLuxam = false;
var CambioP3xP2Luciernaga = false;
var MostrarReferenciaExterna1y2 = false;
var IndexadoOculta = false;
var EleiaComisionGasMTFyMTV = false;

var SimplificacionesTarifaAntiguaEleia = false;
var OrdenarGruposTarifaComparador = false;
var PermitirEditarPotenciasDeSips = false;
var DesarrolloFraccionamientoB70 = false;
var ocultarcodigocomercializadora = false;
var IsSistemaBonoActivo = false;
var MostrarSipsCnae = false;
var SVAEneluz = false;
var ImprimirComparadorConCabecera = false;
var SimplificacionesIdiomaLideraEnergia = false;
var RenovacionContratosMesLuxam = false;
var DesarrolloFraccionamientoB70Met = false;
var codigoDistribuidoraGasDomestica = false;
var OcultarBotonesSips = false;
var ImpresionComparadorCustomizada = false;
var MostrarAltaContratoTantosFeesComoPeriodosSoloFijosEspeciales = false;
var MostrarSipsSoloAdmins = false;
var mostrarSiempreFechaAlta = false;
var FirmaDigitalAutomaticaEneluz = false;
var ImpresionComparadorNombreManual = false;
var ImpresionComparadorFraseFinalPersonalizada = false;
var ImpresionComparadorFraseFinalPersonalizadaTexto = "";
var ImpresionComparadorFraseFinalPersonalizadaTexto2 = "";
var MostrarBotonConsumosManualesComparador = false;
var ComparadorConceptosFormulasReales = false;
var ComparadorSumarImporteEnergiaIndexadoPrecio = false;
var DesarrolloGenerali = false;
var fechaActivacionPrevistaDisa = false;
var CertificadoElectronico = false;
var MostrarIsViviendaHabitual = false;
var CopiarDatosTitularEnRepresentante = false;
var muestraProductoYaDesplegado = false;
var MostrarContratoTipoAlquiler = false;
var ApiComparadorPersonalizado = "";
var cambioNombreMenuSipsXTexto = "";
var ComparadorConsumoManualDefecto = false;
var ComparadorSinInformacionCliente = false;
var ComparadorComercializadoraPersonalizada = false;
var DescargaExcelSoloAdministradores = false;
var OcultarFeeAltaContrato = false;
var pruebaBoeGas = true;
var restaCargosComparadorEleia = false;
var tipoCobroNzwei = false;
var tipoFirmaDigitalSmsEmail = false;