app.controller('FacturasCtrl', ['$scope', '$http', 'serviciosApi', 'jwtHelper', '$mdDialog', function($scope, $http, serviciosApi, jwtHelper, $mdDialog) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var Logueado = localStorage.getItem('logueado');
    var IdContrato = atob(localStorage.getItem('numeroPoliza'));
    var IdEmpresa = localStorage.getItem('idEmpresa');

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') == 'true') {
        $scope.VerEnergia = true;
        $scope.VerGas = false;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') == 'true') {
        $scope.VerGas = true;
        $scope.VerEnergia = false;
    }

    // Cambio de IdEmpresa para DRK Gas - solo muestra las facturas si idempresa esta a 5 pero solo muestra los contratos si idempresa esta a 2 (esto se cambia en login-ctrl)
    if(nombreEmpresa == "DRK" && localStorage.getItem('idEmpresa') == 2 && $scope.VerGas == true){
        IdEmpresa = 5;
    }


    if (Logueado == 'true') {

        $scope.MostrarFacturasSerieNumero = MostrarFacturasSerieNumero;
        $scope.MostrarFacturasContrato = MostrarFacturasContrato;
        $scope.MostrarFacturasEnvioEmail = MostrarFacturasEnvioEmail;
        $scope.MostrarFacturasFecha = MostrarFacturasFecha;
        $scope.MostrarFacturasTipoFactura = MostrarFacturasTipoFactura;
        $scope.MostrarFacturasImporteBase = MostrarFacturasImporteBase;
        $scope.MostrarFacturasTipoImpuesto = MostrarFacturasTipoImpuesto;
        $scope.MostrarFacturasPorcentaje = MostrarFacturasPorcentaje;
        $scope.MostrarFacturasImpuesto = MostrarFacturasImpuesto;
        $scope.MostrarFacturasTotal = MostrarFacturasTotal;
        $scope.MostrarFacturasLecturaAnterior = MostrarFacturasLecturaAnterior;
        $scope.MostrarFacturasFechaLectura = MostrarFacturasFechaLectura;
        $scope.MostrarFacturasPotencia = MostrarFacturasPotencia;
        $scope.MostrarFacturasEnergia = MostrarFacturasEnergia;
        $scope.MostrarFacturasReactiva = MostrarFacturasReactiva;
        $scope.MostrarFacturasExcesosPotencia = MostrarFacturasExcesosPotencia;
        $scope.MostrarFacturasDtoPotencia = MostrarFacturasDtoPotencia;
        $scope.MostrarFacturasDtoEnergia = MostrarFacturasDtoEnergia;
        $scope.MostrarFacturasAlquiler = MostrarFacturasAlquiler;
        $scope.MostrarFacturasProductos = MostrarFacturasProductos;
        $scope.MostrarFacturasTarifa = MostrarFacturasTarifa;
        $scope.MostrarFacturasRectificativa = MostrarFacturasRectificativa;
        $scope.MostrarFacturasAbono = MostrarFacturasAbono;
        $scope.MostrarFacturasOrigen = MostrarFacturasOrigen;
        $scope.nombreEmpresa = nombreEmpresa;

        if (IdContrato != undefined) {
            $http({
                method: "GET",
                url: urlApi + "FacturaVentaCabecera/GetByIds/?_IdsAPIDTO={\"IdEmpresa\":" + IdEmpresa + ",\"IdUsuario\":" + IdUsuario + ",\"Ids\":[" + IdContrato + "]}",
                headers: {
                    Authorization: atob(localStorage.getItem('TokenValidador'))
                }
            }).then(function successCallback(response) {
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);
                //VALIDACION CON LA PALABRA SECRETA DE LA API
                var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                    alg: ["HS256"]
                });

                var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                if (tokenValido && !tokenExpirado) {
                    $scope.facturas = playloadJson;
                } else {
                    $scope.facturas = "";
                }

                var original = {
                    "count": playloadJson.length,
                    "data": playloadJson
                };

                $scope.datatable = angular.copy(original);
                $scope.smart = true;

            });

        }

    }

    $scope.selected = [];

    $scope.sendFactura = function(datos) {
        var documento = datos.factura.IdDocumento;
        if (documento != null) {
            serviciosApi.getFacturaPDF(documento);
        } else {
            return false;
        }
    };

    $scope.autocolumn = [
		{
			name: "Fecha",
			display: "Fecha",
			oculta: "Fecha"
		}, {
			name: "Numero",
			display: "Serie/Numero",
			oculta: "SerieNumero"
		}, {
			name: "CodigoContrato",
			display: "Contrato",
			oculta: "Contrato"
		}, {
			name: "",
			display: "Envío Email",
			oculta: "EnvioEmail"
		}, {
			name: "TextoFacturaTipo",
			display: "Tipo Factura",
			oculta: "TipoFactura"
		}, {
			name: "TotalImporteBase",
			display: "Importe Base",
			oculta: "ImporteBase"
		}, {
			name: "TextoImpuesto",
			display: "Tipo Impuesto",
			oculta: "TipoImpuesto"
		}, {
			name: "Porcentaje",
			display: "%",
			oculta: "Porcentaje"
		}, {
			name: "TotalImporteImpuesto",
			display: "Impuesto",
			oculta: "Impuesto"
		}, {
			name: "TotalImporteTotal",
			display: "Total",
			oculta: "Total"
		}, {
			name: "LecturaAnterior",
			display: "Lectura Anterior",
			oculta: "LecturaAnterior"
		}, {
			name: "FechaLecturaAnterior",
			display: "Fecha Lectura",
			oculta: "FechaLectura"
		}, {
			name: "ImportePotencia",
			display: ($scope.VerEnergia) ? 'Potencia' : 'Caudal',
			oculta: "Potencia"
		}, {
			name: "ImporteEnergia",
			display: "Energía",
			oculta: "Energia"
		}, {
			name: "ImporteReactiva",
			display: "Reactiva",
			oculta: "Reactiva"
		}, {
			name: "ImporteExcesosPotencia",
			display: "Excesos Potencia",
			oculta: "ExcesosPotencia"
		}, {
			name: "ImporteDtoPotencia",
			display: "Dto Potencia",
			oculta: "DtoPotencia"
		}, {
			name: "ImporteDtoEnergia",
			display: "Dto Energia",
			oculta: "DtoEnergia"
		}, {
			name: "ImporteAlquiler",
			display: "Alquiler",
			oculta: "Alquiler"
		}, {
			name: "ImporteProductos",
			display: "Productos",
			oculta: "Productos"
		}, {
			name: "TextoTarifa",
			display: "Tarifa",
			oculta: "Tarifa"
		}, {
			name: "SerieRectificativa",
			display: "Rectificativa",
			oculta: "Rectificativa"
		}, {
			name: "SerieAbono",
			display: "Abono",
			oculta: "Abono"
		}, {
			name: "SerieOrigen",
			display: "Origen",
			oculta: "Origen"
		}
	];

    $scope.exportarDatosFacturas = function(selected) {
		var facturas = [];

        selected.forEach(function(obj) {
            facturas.push({
                'Serie': (obj.Serie != null) ? obj.Serie : '' + (obj.Numero != null) ? obj.Numero : '',
                'Codigo Contrato': (obj.CodigoContrato != null) ? obj.CodigoContrato : '',
                'Fecha': (obj.Fecha != null) ? obj.Fecha : '',
                'Texto Factura Tipo': (obj.TextoFacturaTipo != null) ? obj.TextoFacturaTipo : '',
                'Total Importe Base': (obj.TotalImporteBase != null) ? parseFloat(obj.TotalImporteBase).toFixed(2).replace('.',',') : '',
                'Texto Impuesto': (obj.TextoImpuesto != null) ? obj.TextoImpuesto : '',
                'Porcentaje': (obj.Porcentaje != null) ? obj.Porcentaje : '',
                'Total Importe Impuesto': (obj.TotalImporteImpuesto != null) ? parseFloat(obj.TotalImporteImpuesto).toFixed(2).replace('.',',') : '',
                'Total Importe Total': (obj.TotalImporteTotal != null) ? parseFloat(obj.TotalImporteTotal).toFixed(2).replace('.',',') : '',
                'Fecha Lectura Anterior': (obj.FechaLecturaAnterior != null) ? obj.FechaLecturaAnterior : '',
                'Fecha Lectura Actual': (obj.FechaLecturaActual != null) ? obj.FechaLecturaActual : '',
                'Importe Potencia': (obj.ImportePotencia != null) ? parseFloat(obj.ImportePotencia).toFixed(2).replace('.',',') : '',
                'Importe Energia': (obj.ImporteEnergia != null) ? parseFloat(obj.ImporteEnergia).toFixed(2).replace('.',',') : '',
                'Importe Reactiva': (obj.ImporteReactiva != null) ? parseFloat(obj.ImporteReactiva).toFixed(2).replace('.',',') : '',
                'Importe Excesos Potencia': (obj.ImporteExcesosPotencia != null) ? parseFloat(obj.ImporteExcesosPotencia).toFixed(2).replace('.',',') : '',
                'Importe Dto Potencia': (obj.ImporteDtoPotencia != null) ? parseFloat(obj.ImporteDtoPotencia).toFixed(2).replace('.',',') : '',
                'Importe Dto Energia': (obj.ImporteDtoEnergia != null) ? parseFloat(obj.ImporteDtoEnergia).toFixed(2).replace('.',',') : '',
                'Importe Alquiler': (obj.ImporteAlquiler != null) ? parseFloat(obj.ImporteAlquiler).toFixed(2).replace('.',',') : '',
                'Importe Productos': (obj.ImporteProductos != null) ? parseFloat(obj.ImporteProductos).toFixed(2).replace('.',',') : '',
                'Texto Tarifa': (obj.TextoTarifa != null) ? obj.TextoTarifa : '',
                'Numero Rectificativa': (obj.NumeroRectificativa != null) ? obj.NumeroRectificativa : '',
                'Serie Abono': (obj.SerieAbono != null) ? obj.SerieAbono : '',
				'Serie Origen': (obj.SerieOrigen != null) ? obj.SerieOrigen : ''
			});
        });

        /**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
		.excelexportjs({
			containerid: "dvjson",
			datatype: 'json',
			dataset: facturas,
			columns: getColumns(facturas)
		});
    };

    $scope.enviarFacturaEmail = function(data) {
        $http({
            method: 'GET',
            responseType: "blob",
            url: urlApi + "FacturaVentaCabecera/FacturaPreviewPDF/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/Documento/" + data.factura.IdDocumento,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            },
        }).then(function successCallback(response) {
            // Modal prompt enviar email
            var confirm = $mdDialog.prompt()
                .title('La factura se enviará al siguiente email')
                .placeholder('Dirección de correo')
                .ariaLabel('Dirección de correo')
                .initialValue(data.factura.Email)
                .ok('Enviar')
                .cancel('Cancelar');
            $mdDialog.show(confirm).then(function(result) {
                var file = new Blob([response.data], { type: "application/octet-stream" });
                var formdata = new FormData();
                formdata.append("file", file);
                formdata.append("email", result);
                formdata.append("asunto", $scope.nombreEmpresa);
                formdata.append("subject",'Envio factura');
                //Obtener fecha de la factura en formato dd/mm/yyyy:
                
                var fecha=data.factura.Fecha;
                function convertDate(fecha) {
                    function pad(s) { return (s < 10) ? '0' + s : s; }
                    var d = new Date(fecha)
                    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
                  }
                //Cuerpo del email obteniendo los datos necesarios para el mismo
                var body="Estimado cliente<br><br>" + "Procedemos a remitirle una copia de su factura " + data.factura.Serie + " " +  data.factura.Numero +
                " en referencia al " +  convertDate(fecha) + " cuyo importe asociado se corresponde con un total de " + data.factura.TotalImporteTotal
                 + " euros. <br>Dicha factura se encuentra adjunta a este mismo correo.<br><br>" +
                  "Si tiene alguna consulta, puede contestar a este mismo email.<br><br>"
                  + "Gracias por su atención.<br>"
                  + "Un cordial saludo.<br><br>"
                  +"EQUIPO DE: " + $scope.nombreEmpresa;
                formdata.append("body",body);
                 // Enviar pdf adjunto al email introducido
                $.ajax({
                    type: 'POST',
                    url: 'lib/php/enviar_mail_contrato.php',
                    data: formdata,
                    processData: false,
                    contentType: false
                }).fail(function() {
                    $mdDialog.show(
                        $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('')
                        .htmlContent('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> <p class="centrado">Error al enviar el email.</p>')
                        .ariaLabel('Error al enviar email')
                        .ok('Aceptar')
                        .targetEvent(ev)
                    );
                });

            }, function() {});
        });
	}

	$scope.seleccionaTodos = function(){
		$scope.facturas.forEach(function (item) {
			$scope.selected.push(item);
		});
	}
	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

	$scope.recargarDatos =  function(){
		$http({
			method: "GET",
			url: urlApi + "FacturaVentaCabecera/GetByIds/?_IdsAPIDTO={\"IdEmpresa\":" + IdEmpresa + ",\"IdUsuario\":" + IdUsuario + ",\"Ids\":[" + IdContrato + "]}",
			headers: {
				Authorization: atob(localStorage.getItem('TokenValidador'))
			}
		}).then(function successCallback(response) {
			var respuesta = response.data;
			var decodeToken = jwtHelper.decodeToken(respuesta);
			var playloadJson = JSON.parse(decodeToken.iss);
			//VALIDACION CON LA PALABRA SECRETA DE LA API
			var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
				alg: ["HS256"]
			});

			var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
			var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

			if (tokenValido && !tokenExpirado) {
				$scope.facturas = playloadJson;
			} else {
				$scope.facturas = "";
			}

			var original = {
				"count": playloadJson.length,
				"data": playloadJson
			};

			$scope.datatable = angular.copy(original);
			$scope.smart = true;

		});
	}

}]);