app.controller('MenuCtrl', ['moment', 'calendarConfig', '$scope', '$rootScope', '$location', 'Idle', 'Keepalive', '$uibModal', 'serviciosApi', '$state', 'jwtHelper','$interval', function(moment, calendarConfig, $scope, $rootScope, $location, Idle, Keepalive, $uibModal, serviciosApi, $state, jwtHelper,$interval) {
    'use strict';

    var Logueado = localStorage.getItem('logueado');
    var NombreAgente = atob(localStorage.getItem('nombreAgente'));
    var DepartamentoAgente = atob(localStorage.getItem('departamentoAgente'));
    var TipoAgente = atob(localStorage.getItem('tipoAgente'));

    if (Logueado != 'true') {
		localStorage.clear();
        $location.path('/login');
    } else {


        if (localStorage.getItem('idEmpresaGas') == "true"){
            $scope.VerGas = true;
            $scope.VerElectricidad = false;
        }else{
            $scope.VerGas = false;
            $scope.VerElectricidad = true;
        }

        //Parametrización de datos, nombres y visibilidad
        $scope.nombreEmpresa = nombreEmpresa;
        $scope.NombreTituloPortal = NombreTituloPortal;
        $scope.MostrarSipsSoloAdmins = MostrarSipsSoloAdmins;
		$scope.SoyAdmin = false;
		if($scope.MostrarSipsSoloAdmins){
			$scope.SoyAdmin = atob(localStorage.getItem('isAdmin'));
			if($scope.SoyAdmin == 'false'){
				$scope.SoyAdmin = false;
			} else {
				$scope.SoyAdmin = true;
			}
		}

        $scope.NombreAgente = NombreAgente;
        $scope.DepartamentoAgente = DepartamentoAgente;
        $scope.nombreEmpresaLogin = nombreEmpresaLogin;
        $scope.NombrePortal = nombrePortal;

        $scope.tiempoDeIntervalo = tiempoDeIntervalo;

        $scope.sidebarPosition = sidebarPosition;

        $scope.MenuResumen = MenuResumen;
        $scope.MenuSips = MenuSips;
        $scope.MenuFacturas = MenuFacturas;
        $scope.MenuContratos = MenuContratos;
        $scope.MenuAltaRapida = MenuAltaRapida;
        $scope.MenuComerciales = MenuComerciales;
        $scope.MenuClientes = MenuClientes;
        $scope.MenuComisiones = MenuComisiones;
        $scope.MenuTeleventa = MenuTeleventa;
        $scope.MenuIncidencias = MenuIncidencias;
        $scope.MenuDocumentacion = MenuDocumentacion;
        $scope.MenuAgenda = MenuAgenda;
        $scope.MenuTeleventaFiltrado = MenuTeleventaFiltrado;
        $scope.MenuCambioPassword = MenuCambioPassword;
        $scope.ResumenRanking = ResumenRanking;
        $scope.MenuCodigosPromocionales = MenuCodigosPromocionales;
        $scope.MenuComunicados = MenuComunicados;
        $scope.MenuInformes = MenuInformes;
        $scope.agrupacionXEstado = agrupacionXEstado;

        $scope.MenuComparador = MenuComparador;
        $scope.MenuComparadorGas = MenuComparadorGas;
        $scope.cambioNombreMenuIncidenciasXComunicacion = cambioNombreMenuIncidenciasXComunicacion;
        $scope.cambioNombreMenuIncidenciasXReclamaciones = cambioNombreMenuIncidenciasXReclamaciones;
        $scope.cambioNombreMenuSipsXTexto = cambioNombreMenuSipsXTexto;
		if ($scope.cambioNombreMenuSipsXTexto == "") $scope.cambioNombreMenuSipsXTexto = "SIPS";
        $scope.TextoContratos = TextoContratos;

        $scope.MostrarResumenEtiquetaContratoActivo = MostrarResumenEtiquetaContratoActivo;
        $scope.MenuRenovaciones = MenuRenovaciones;
        $scope.IdMarca = IdMarca;
        $scope.FiltrarListadoContratoPorIdMarca = FiltrarListadoContratoPorIdMarca;

        if (TipoAgente == "3" && $scope.MenuTeleventaFiltrado) {
            $scope.MenuResumen = false;
            $scope.MenuSips = false;
            $scope.MenuFacturas = false;
            $scope.MenuContratos = false;
            $scope.MenuComerciales = false;
            $scope.MenuClientes = false;
            $scope.MenuComisiones = false;
            $scope.MenuIncidencias = false;
            $scope.MenuDocumentacion = false;
            $scope.MenuAgenda = false;
            $scope.MenuRenovaciones = false;

            $location.url("/lead");
        }

        $scope.MostrarComisionesDistribuidor = MostrarComisionesDistribuidor;
        $scope.MostrarComisionesAgente = MostrarComisionesAgente;
        $scope.MostrarComisionesSubAgente = MostrarComisionesSubAgente;
		$scope.TipoAgente = TipoAgente;

		// Si tengo las variables en verMenu reemplazar por las del config
		if (localStorage.getItem('verMenu')) {
			var ls = localStorage.getItem('verMenu');
			var verMenu = JSON.parse(atob(ls));
			$scope.MenuSips = verMenu.VerSIPS || MenuSips;
			$scope.MenuContratos = verMenu.VerContratos || MenuContratos;
			$scope.MenuClientes = verMenu.VerClientes || MenuClientes;
			$scope.MenuComisiones = verMenu.VerComisiones || MenuComisiones;
			$scope.MenuComerciales = verMenu.VerComerciales || MenuComerciales;
			$scope.MenuIncidencias = verMenu.VerIncidencias || MenuIncidencias;
			$scope.MenuDocumentacion = verMenu.VerDocumentacion || MenuDocumentacion;
			$scope.MenuAgenda = verMenu.VerAgenda || MenuAgenda;
			$scope.MenuTeleventa = verMenu.VerLead || MenuTeleventa;
			$scope.MenuRenovaciones = verMenu.MenuRenovaciones || MenuRenovaciones;
		}

        //Activa el css en el menu cuando haces click
        $scope.isActive = function(viewLocation) {
            var ruta = $location.path().split("/");
            ruta = "/" + ruta[1];
            var active = (viewLocation === ruta);
            return active;
        };

        //Totales en resumen solo se carga las llamadas cuando esta visible en el menu
        var LlamadaResumenTotal;
        if (!LlamadaResumenTotal && MenuResumen) {

            serviciosApi.getTotalResumen()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.totales = playloadJson;
                    } else {
                        $scope.totales = "";
                    }


                    // Si hay alguno a null lo cambio a cero para que quede bien en la vista.
                    if (playloadJson.TotalClientes == null) { playloadJson.TotalClientes = 0; }
                    if (playloadJson.TotalContratosActivos == null) { playloadJson.TotalContratosActivos = 0; }
                    if (playloadJson.TotalDeuda == null) { playloadJson.TotalDeuda = 0; }
                    if (playloadJson.TotalComision == null) { playloadJson.TotalComision = 0; }

                    var LlamadaResumenTotal = true;
                });

        }

        //Ultimos Contratos
        var LlamadaUltimosContratos;
        if (!LlamadaUltimosContratos && MenuResumen) {

            serviciosApi.getUltimosContratos()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.ultimosContratos = playloadJson;
                    } else {
                        $scope.ultimosContratos = "";
                    }

                    var LlamadaResumenTotal = true;
                });

        }

        //Comisionistas
        var LlamadaComisionistas;
        if (!LlamadaComisionistas && MenuResumen) {
            serviciosApi.getResumenComisionistas()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.comisionistas = playloadJson;
                    } else {
                        $scope.comisionistas = "";
                    }
                    var LlamadaComisionistas = true;
                });

        }

        //Notificaciones
        var LlamadaNotificaciones;
        if (!LlamadaNotificaciones && MenuResumen) {

            serviciosApi.getNotificaciones()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.notificaciones = playloadJson;
                    } else {
                        $scope.notificaciones = "";
                    }

                    var LlamadaNotificaciones = true;
                });

        }

        //Proximas tareas
        var LlamadaProximasTareas;
        if (!LlamadaProximasTareas && MenuResumen) {

            serviciosApi.getProximasTareas()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);
                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                            //verifyAt: IntDate.get('20150601000000Z')
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.proximasTareas = playloadJson;
                    } else {
                        $scope.proximasTareas = "";
                    }

                    var LlamadaProximasTareas = true;
                });

		}

		// Ranking Agentes
        var LlamadaRankingAgentes;
        //console.log($scope.ResumenRanking);
		if (!LlamadaRankingAgentes && MenuResumen && $scope.ResumenRanking) {
			serviciosApi.getRankingAgentes()
				.then(function(response) {
					var resp = response.data;
					var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
					var tokenExpirado = jwtHelper.isTokenExpired(resp);

					if (tokenValido && !tokenExpirado) {
						var token = jwtHelper.decodeToken(resp);
						var json = JSON.parse(token.iss);
						$scope.rankingAgentes = json;
					}
				});
		}



		//Listado Renovaciones
		$scope.getListadoRenovaciones = function(renovado, pdf) {
			if($scope.MenuRenovaciones){
				$scope.mensajeCargaPDF = renovado;
				$scope.RenovacionHecha = pdf;
				serviciosApi.getListadoRenovaciones()
					.then(function(response) {
						var respuesta = response.data;
						var decodeToken = jwtHelper.decodeToken(respuesta);
						var playloadJson = JSON.parse(decodeToken.iss);
						//VALIDACION CON LA PALABRA SECRETA DE LA API
						var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
							alg: ["HS256"]
						});

						var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
						var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

						if (tokenValido && !tokenExpirado) {
							$scope.renovaciones = playloadJson;
							// console.log($scope.contratos);
						}
					});
				}
		};


        // Listado Contratos
        var LlamadaListadoContratos;
		localStorage.setItem('IdMarca', $scope.IdMarca);
        $scope.getListadoContratos = function() {

            if (!LlamadaListadoContratos && MenuContratos) {

                serviciosApi.getListadoContratos($scope.FiltrarListadoContratoPorIdMarca)
                    .then(function(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);
                        //VALIDACION CON LA PALABRA SECRETA DE LA API
                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                            alg: ["HS256"]
                        });

                        var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                        if (tokenValido && !tokenExpirado) {
                            $scope.contratos = playloadJson;
                            // console.log($scope.contratos);
                        } else {
                            $scope.contratos = "";
                        }

                        var GraficoTiposContratos = new Array();

                        // console.log(playloadJson);

                        var arrayOfObjetsDeEstados = new Array();

                        for (var i = 0; i < playloadJson.length; i++) {


                            // Llenamos el array de objetos(arrayOfObjetsDeEstados) con situacion y cantidad
                            // TODO: TENER EN CUENTA QUE HAY AGRUPACIONES PARA ALGUN CLIENTE DE VARIOS ESTADOS
                            if(arrayOfObjetsDeEstados.length > 0){
                                var flag = 0;
                                arrayOfObjetsDeEstados.forEach(function callback(item, index, array) {

                                    // Comprobamos variable config para ELEIA <- TRUE
                                    if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
                                        if(playloadJson[i].SituacionWeb == item.situacion){
                                            flag = 1;
                                        }
                                    // demas clientes lo tendran a false por el momento
                                    }else{
                                        if(playloadJson[i].Situacion == item.situacion){
                                            flag = 1;
                                        }
                                    }

                                });

                                if(flag == 0){
                                    if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
                                        arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].SituacionWeb, "cantidad":1});
                                    }else{
                                        arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].Situacion, "cantidad":1});
                                    }
                                }else{
                                    arrayOfObjetsDeEstados.forEach(function callback(item, index, array) {
                                        if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
                                            if(playloadJson[i].SituacionWeb == item.situacion){
                                                item.cantidad = item.cantidad + 1;
                                            }
                                        }else{
                                            if(playloadJson[i].Situacion == item.situacion){
                                                item.cantidad = item.cantidad + 1;
                                            }
                                        }

                                    });
                                }

                            }else{
                                if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
                                    arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].SituacionWeb, "cantidad":1});
                                }else{
                                    arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].Situacion, "cantidad":1});
                                }
                            }
                            // fin del llenado del array de objetos


                        var estados = new Array();
                        var cantidades = new Array();

                        // Cargamos los 2 arrays de graficos
                        arrayOfObjetsDeEstados.forEach(function callback(item, index, array) {
                            estados.push(item.situacion);
                            cantidades.push(item.cantidad)
                        });


                        }
                        // console.log('Acivos = ', a);
                        // console.log('Situaciones = ', situaciones);
                        $scope.graficaResumenContratos = {

                            labels: estados,
                            datasets: [{
                                data: cantidades,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(153, 255, 255, 0.2)',
                                    'rgba(153, 255, 204, 0.2)',


                                ],
                                hoverBackgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(153, 255, 255, 0.2)',
                                    'rgba(153, 255, 204, 0.2)',
                                ],
                                borderWidth: 1
                            }]
                        };

                        $scope.graficaResumenContratosOpciones = {
                            legend: {
                                display: true,
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                fontSize: 14,
                                text: 'Situación de Contratos'
                            }
                        };

                        $scope.graficaResumenContratosPlugins = [{
                            // Chart.js inline plugins go here
                            // e.g. http://www.chartjs.org/docs/latest/developers/plugins.html#using-plugins
                        }];

                        $scope.onChartClick = function(event) {
                            //console.log(event);
                        };

                        var original = {
                            "count": playloadJson.length,
                            "data": playloadJson
                        };

                        $scope.datatableContratos = angular.copy(original);
                        $scope.smart = true;

                        var LlamadaListadoContratos = true;

                        if (MenuComunicados && MostrarComunicados) {
                            $scope.getListadoComunicados();
                            $scope.getListadoComunicadosAllActivosEmpresa();
                        }

                    });

            }

        };

        // Nos trae los comunicados del agente
        $scope.getListadoComunicados = function() {

            if (MenuComunicados && MostrarComunicados) {

                // console.log("Get Comunicados")

                    serviciosApi.getListadoComunicados()
                    .then(function(response) {
                        var respuesta = response.data;
                        var decodeToken = jwtHelper.decodeToken(respuesta);
                        var playloadJson = JSON.parse(decodeToken.iss);

                        //VALIDACION CON LA PALABRA SECRETA DE LA API
                        var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                            alg: ["HS256"]
                        });

                        var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                        var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                        if (tokenValido && !tokenExpirado) {
                            $scope.comunicados = playloadJson;
                        } else {
                            $scope.comunicados = "";
                        }

                        var abiertos = 0;


                        $scope.comunicados.forEach(function callback(itemComunicado, indexComunicado, arrayComunicado) {
                            if(itemComunicado.Estadobackoffice == 'Abierto'){
                                abiertos++;
                            }

                            var numeroAcciones = 0;
                            var acciones = itemComunicado.ListadoAcciones;

                            // Recorremos las acciones del comunicado
                            acciones.forEach(function callback(accion, indexAccion, arrayAccion) {
                                if(accion.Estado == 'Abierto')
                                    numeroAcciones++;
                            });

                            itemComunicado.accionesAbiertas = numeroAcciones;

                        });


                        var original = {
                            "count": abiertos, // Comunicados abiertos
                            "data": playloadJson
                        };

                        $scope.datatableComunicados = angular.copy(original);
                        // console.log($scope.datatableComunicados);
                        $scope.smart = true;

                    });

            }
        };

        // Trae todos los comunicados de la empresa activos
        $scope.getListadoComunicadosAllActivosEmpresa = function() {

            if (MenuComunicados && MostrarComunicados) {

                serviciosApi.getListadoComunicadosAllActivosEmpresa()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
                    var comunicadosAllActivos = []
                    if (tokenValido && !tokenExpirado) {
                        comunicadosAllActivos = playloadJson;
                    } else {
                        comunicadosAllActivos = "";
                    }

                    $scope.contratos.forEach(function callback(itemContrato, indexContrato, arrayContrato) {
                        var flag = 0;

                        comunicadosAllActivos.forEach(function callback(itemComunicado, indexComunicado, arrayComunicado) {
                            if(itemContrato.CodigoContrato == itemComunicado.CodigoContrato){
                                    flag = 1;
                            }
                        });

                        // Cargamos los contratos con los avisos de comunicados
                        (flag == 1) ? itemContrato.comunicadosAbiertos = true : itemContrato.comunicadosAbiertos = false;
                    })


                });
            }

        };



        // TODO: Listado Contratos NUEVO PARA INTERVAL
        $scope.getListadoContratos();
        $scope.getListadoRenovaciones();

        // TODO: Listado Contratos ANTERIOR
        // var LlamadaListadoContratos;
        // if (!LlamadaListadoContratos && MenuContratos) {

        //     var GraficoSituacionContrato = 0;
        //     var GraficoSituacionBaja = 0;
        //     var GraficoSituacionAnulado = 0;
        //     var GraficoSituacionTramite = 0;
        //     var GraficoSituacionIncidencia = 0;
        //     var GraficoSituacionCortado = 0;
        //     var GraficoSituacionInspeccion = 0;
        //     var GraficoSituacionPendienteFirma = 0;
        //     var GraficoSituacionPotencial = 0;

        //     serviciosApi.getListadoContratos()
        //         .then(function(response) {
        //             var respuesta = response.data;
        //             var decodeToken = jwtHelper.decodeToken(respuesta);
        //             var playloadJson = JSON.parse(decodeToken.iss);
        //             //VALIDACION CON LA PALABRA SECRETA DE LA API
        //             var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
        //                 alg: ["HS256"]
        //             });

        //             var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
        //             var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

        //             if (tokenValido && !tokenExpirado) {
        //                 $scope.contratos = playloadJson;
        //             } else {
        //                 $scope.contratos = "";
        //             }

        //             var GraficoTiposContratos = new Array();

        //             // console.log(playloadJson);

        //             // playloadJson[4].Agrupacion = "TEST";
        //             // playloadJson[2].Agrupacion = "TEST2";
        //             // playloadJson[7].Agrupacion = "TEST";
        //             // playloadJson[12].Agrupacion = "TEST";
        //             // playloadJson[14].Agrupacion = "RAN";

        //             var arrayOfObjetsDeEstados = new Array();

        //             for (var i = 0; i < playloadJson.length; i++) {

        //                 // Llenamos el array de objetos(arrayOfObjetsDeEstados) con situacion y cantidad
        //                 // TODO: TENER EN CUENTA QUE HAY AGRUPACIONES PARA ALGUN CLIENTE DE VARIOS ESTADOS
        //                 if(arrayOfObjetsDeEstados.length > 0){
        //                     var flag = 0;
        //                     arrayOfObjetsDeEstados.forEach(function callback(item, index, array) {

        //                         // Comprobamos variable config para ELEIA <- TRUE
        //                         if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
        //                             if(playloadJson[i].SituacionWeb == item.situacion){
        //                                 flag = 1;
        //                             }
        //                         // demas clientes lo tendran a false por el momento
        //                         }else{
        //                             if(playloadJson[i].Situacion == item.situacion){
        //                                 flag = 1;
        //                             }
        //                         }

        //                     });

        //                     if(flag == 0){
        //                         if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
        //                             arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].SituacionWeb, "cantidad":1});
        //                         }else{
        //                             arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].Situacion, "cantidad":1});
        //                         }
        //                     }else{
        //                         arrayOfObjetsDeEstados.forEach(function callback(item, index, array) {
        //                             if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
        //                                 if(playloadJson[i].SituacionWeb == item.situacion){
        //                                     item.cantidad = item.cantidad + 1;
        //                                 }
        //                             }else{
        //                                 if(playloadJson[i].Situacion == item.situacion){
        //                                     item.cantidad = item.cantidad + 1;
        //                                 }
        //                             }

        //                         });
        //                     }

        //                 }else{
        //                     if(agrupacionXEstado && playloadJson[i].SituacionWeb!=""){
        //                         arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].SituacionWeb, "cantidad":1});
        //                     }else{
        //                         arrayOfObjetsDeEstados.push({"situacion":playloadJson[i].Situacion, "cantidad":1});
        //                     }
        //                 }
        //                 // fin del llenado del array de objetos


        //             var estados = new Array();
        //             var cantidades = new Array();

        //             // Cargamos los 2 arrays de graficos
        //             arrayOfObjetsDeEstados.forEach(function callback(item, index, array) {
        //                 estados.push(item.situacion);
        //                 cantidades.push(item.cantidad)
        //             });



        //             // METODO ANTIGUO DE CARGA DE ESTADOS MANUALMENTE A PIÑON

        //             //     if (playloadJson[i].Situacion == "Contrato" || playloadJson[i].Situacion == "Activo") {
        //             //         GraficoSituacionContrato++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Baja") {
        //             //         GraficoSituacionBaja++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Anulado") {
        //             //         GraficoSituacionAnulado++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Trámite") {
        //             //         GraficoSituacionTramite++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Incidencia") {
        //             //         GraficoSituacionIncidencia++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Cortado") {
        //             //         GraficoSituacionCortado++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Inspección Alta") {
        //             //         GraficoSituacionInspeccion++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Pendiente de firma") {
        //             //         GraficoSituacionPendienteFirma++;
        //             //     }
        //             //     if (playloadJson[i].Situacion == "Potencial") {
        //             //         GraficoSituacionPotencial++;
        //             //     }

        //             }

        //             // METODO ANTIGUO DE CARGA DE ESTADOS MANUALMENTE A PIÑON
        //             // if($scope.MostrarResumenEtiquetaContratoActivo == true){
        //             //     var estados = ['Activo','Baja','Anulado','Trámite','Incidencia','Cortado','Inspección Alta', 'Pendiente de firma', 'Potencial'];
        //             // }else{
        //             //     var estados = ['Contrato','Baja','Anulado','Trámite','Incidencia','Cortado','Inspección Alta', 'Pendiente de firma', 'Potencial'];
        //             // }

        //             $scope.graficaResumenContratos = {

        //                 labels: estados,
        //                 datasets: [{
        //                     data: cantidades,
        //                     backgroundColor: [
        //                         'rgba(255, 99, 132, 0.2)',
        //                         'rgba(54, 162, 235, 0.2)',
        //                         'rgba(255, 206, 86, 0.2)',
        //                         'rgba(75, 192, 192, 0.2)',
        //                         'rgba(153, 102, 255, 0.2)',
        //                         'rgba(255, 159, 64, 0.2)',
        //                         'rgba(255, 99, 132, 0.2)',
        //                         'rgba(153, 255, 255, 0.2)',
        //                         'rgba(153, 255, 204, 0.2)',


        //                     ],
        //                     hoverBackgroundColor: [
        //                         'rgba(255, 99, 132, 0.2)',
        //                         'rgba(54, 162, 235, 0.2)',
        //                         'rgba(255, 206, 86, 0.2)',
        //                         'rgba(75, 192, 192, 0.2)',
        //                         'rgba(153, 102, 255, 0.2)',
        //                         'rgba(255, 159, 64, 0.2)',
        //                         'rgba(255, 99, 132, 0.2)',
        //                         'rgba(153, 255, 255, 0.2)',
        //                         'rgba(153, 255, 204, 0.2)',
        //                     ],
        //                     borderWidth: 1
        //                 }]
        //             };

        //             $scope.graficaResumenContratosOpciones = {
        //                 legend: {
        //                     display: true,
        //                     position: 'bottom'
        //                 },
        //                 title: {
        //                     display: true,
        //                     fontSize: 14,
        //                     text: 'Situación de Contratos'
        //                 }
        //             };

        //             $scope.graficaResumenContratosPlugins = [{
        //                 // Chart.js inline plugins go here
        //                 // e.g. http://www.chartjs.org/docs/latest/developers/plugins.html#using-plugins
        //             }];

        //             $scope.onChartClick = function(event) {
        //                 //console.log(event);
        //             };

        //             var original = {
        //                 "count": playloadJson.length,
        //                 "data": playloadJson
        //             };

        //             $scope.datatableContratos = angular.copy(original);
        //             $scope.smart = true;

        //             var LlamadaListadoContratos = true;

        //         });

        // }

        //Listado Contratos por Mes
        var LlamadaListadoContratosPorMes;
        if (!LlamadaListadoContratosPorMes && MenuContratos) {

            serviciosApi.getContratosPorMes()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    var ResumenContratosPorMes = playloadJson;
                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.resumenContratosPorMes = playloadJson;
                    } else {
                        $scope.resumenContratosPorMes = "";
                    }
                    //$scope.resumenContratosPorMes = ResumenContratosPorMes;


                    var ArrayResumenContratosPorMesFecha = new Array();
                    var ArrayResumenContratosPorMesNumeroContratos = new Array();

                    for (var i = 0; i < ResumenContratosPorMes.length; i++) {
                        ArrayResumenContratosPorMesFecha.push(ResumenContratosPorMes[i].FechaDeContrato);
                        ArrayResumenContratosPorMesNumeroContratos.push(ResumenContratosPorMes[i].NumeroDeContratos);
                    }

                    $scope.graficaResumenContratosPorMes = {

                        labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                        datasets: [{
                            data: ArrayResumenContratosPorMesNumeroContratos,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            hoverBackgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderWidth: 1
                        }]
                    };

                    $scope.graficaResumenContratosPorMesOpciones = {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    steps: 10,
                                    stepValue: 1
                                        //max: 60 //max value for the chart is 60
                                }
                            }]
                        },
                        title: {
                            display: true,
                            fontSize: 14,
                            text: 'Solicitudes de Alta de Contratos'
                        }
                    };

                    $scope.graficaResumenContratosPorMesPlugins = [{
                        // Chart.js inline plugins go here
                        // e.g. http://www.chartjs.org/docs/latest/developers/plugins.html#using-plugins
                    }];

                    $scope.onChartClick = function(event) {
                        //console.log(event);
                    };

                    var LlamadaListadoContratosPorMes = true;
                });

        }

        //Listado Renovacion Contratos
        var LlamadaListadoContratosRenovacion;
        if (!LlamadaListadoContratosRenovacion && MenuContratos) {

            serviciosApi.getContratosRenovacion()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.contratosRenovacion = playloadJson;
                    } else {
                        $scope.contratosRenovacion = "";
                    }


                    var original = {
                        "count": playloadJson.length,
                        "data": playloadJson
                    };

                    $scope.datatableContratosRenovacion = angular.copy(original);
                    $scope.smart = true;

                    var LlamadaListadoContratosRenovacion = true;
                });

        }

        //Listado Clientes
        var LlamadaListadoClientes;
        if (!LlamadaListadoClientes && MenuClientes) {

            serviciosApi.getListadoClientes()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.clientes = playloadJson;
                    } else {
                        $scope.clientes = "";
                    }

                    var original = {
                        "count": playloadJson.length,
                        "data": playloadJson
                    };

                    $scope.datatableClientes = angular.copy(original);
                    $scope.smart = true;

                    var LlamadaListadoClientes = true;
                });

        }

        //Listado Comisiones
        var LlamadaListadoComisiones;
        if (!LlamadaListadoComisiones && MenuComisiones) {

            serviciosApi.getListadoComisiones()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);
                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.comisiones = playloadJson.DatosComision;
                        $scope.parametros = playloadJson.DatosAgente;
                    } else {
                        $scope.comisiones = "";
                        $scope.parametros = "";
                    }

                    var originalComisiones = {
                        "count": playloadJson.DatosComision.length,
                        "data": playloadJson.DatosComision
                    };

                    var originalParametros = {
                        "count": playloadJson.DatosAgente.length,
                        "data": playloadJson.DatosAgente
                    };

                    $scope.datatableComisiones = angular.copy(originalComisiones);
                    $scope.smart = true;

                    $scope.datatableParametros = angular.copy(originalParametros);
                    $scope.smart = true;

                    var LlamadaListadoComisiones = true;
                });

        }

        //Listado Comerciales
        var ListadoComerciales;
        if (!ListadoComerciales && MenuComerciales) {

            serviciosApi.getListadoComerciales()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.comerciales = playloadJson;
                        $rootScope.comerciales = playloadJson;
                    } else {
                        $scope.comerciales = "";
                    }

                    var original = {
                        "count": playloadJson.length,
                        "data": playloadJson
                    };

                    $scope.datatableComerciales = angular.copy(original);
                    $scope.smart = true;

                    var ListadoComerciales = true;
                });

        }

        //Listado Incidencias
        var LlamadaListadoIncidencias;
        if (!LlamadaListadoIncidencias && MenuIncidencias) {

            serviciosApi.getListadoIncidencias()
                .then(function(response) {
                    var respuesta = response.data;
                    var decodeToken = jwtHelper.decodeToken(respuesta);
                    var playloadJson = JSON.parse(decodeToken.iss);

                    //VALIDACION CON LA PALABRA SECRETA DE LA API
                    var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, {
                        alg: ["HS256"]
                    });

                    var fechaExpiracion = jwtHelper.getTokenExpirationDate(respuesta); //calculo fecha de expiracion
                    var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no

                    if (tokenValido && !tokenExpirado) {
                        $scope.incidencias = playloadJson;
                    } else {
                        $scope.incidencias = "";
                    }

                    var original = {
                        "count": playloadJson.length,
                        "data": playloadJson
                    };

                    $scope.datatableIncidencias = angular.copy(original);
                    $scope.smart = true;

                    var LlamadaListadoIncidencias = true;
                });

        }

        //Recupero los datos de el servicio de Proximas Tareas
        serviciosApi.getAgenda()
            .then(function(response) {
                var DatosCalendario = '';
                var ArrayAgenda = new Array();
                var respuesta = response.data;
                var decodeToken = jwtHelper.decodeToken(respuesta);
                var playloadJson = JSON.parse(decodeToken.iss);

                for (var i = 0; i < playloadJson.length; i++) {
                    var objeto = { title: playloadJson[i].Descripcion, color: { primary: "#4C5D7A" }, startsAt: new Date(playloadJson[i].FechaInicio), endsAt: new Date(playloadJson[i].FechaVencimiento) };
                    ArrayAgenda.push(objeto);
                }
                $scope.events = ArrayAgenda;
            });

        //Control de sesions
        $scope.started = false;

        function closeModals() {
            if ($scope.warning) {
                $scope.warning.close();
                $scope.warning = null;
            }

            if ($scope.timedout) {
                $scope.timedout.close();
                $scope.timedout = null;
            }
        }

        $scope.$on('IdleStart', function() {
            closeModals();

            $scope.warning = $uibModal.open({
                templateUrl: 'templates/aviso-cierre-sesion.html',
                windowClass: 'modal-danger'
            });
        });

        $scope.$on('IdleEnd', function() {
            closeModals();
        });

        $scope.$on('IdleTimeout', function() {
            //localStorage.setItem('refresh', btoa(true));
            localStorage.setItem('refresh', btoa(true));
            closeModals();
            $location.path('/login');
            //Creo una cookie o un rooterscoope en el que paso la varible refresh a true, con esto controlo que se refresque en el true
            //window.location.reload();
        });

        $scope.start = function() {
            closeModals();
            Idle.watch();
            $scope.started = true;
        };

        $scope.stop = function() {
            closeModals();
            Idle.unwatch();
            $scope.started = false;
        };


        // Funciona para llamar a todas las funciones de carga de datos: contratos, comunicados etc...
        $scope.intervalGlobalCalls = function() {
            $scope.getListadoContratos();
            // console.log("getListadoContratos -> " + $scope.dateGetListadoContratos );
        }

        // Carga la funcion intervalGlobalCalls cada cierto tiempo, el tiempo se define en una variable en los config de cada empresa
        $interval($scope.intervalGlobalCalls, $scope.tiempoDeIntervalo);

    }
}]);