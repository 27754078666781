app.service('visorPDF', [ function () {
	this.alerta = function(){
		return alert();
	}

	var pagesPDF = [];

	this.showPDF = function(cc, cups){
		document.getElementById('pdfContainer').style.width = (window.screen.width - 270) + 'px';
		var req = new XMLHttpRequest;
		document.getElementById('titlePDF').innerHTML = 'CC: '+ cc + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CUPS: '+ cups;
		req.onreadystatechange = function() {
			if (req.readyState == 4 && req.status == 200) {
				var urls = JSON.parse(req.response).images;
				urls.forEach(function(el){
					var rutas = el.split('/');
					var ruta = 'https://callejeroine.sigeenergia.com/'+rutas[4]+'/'+rutas[5];
					pagesPDF.push(ruta);
					var pdf = document.createElement('img');
					pdf.style.position = 'relative';
					pdf.style.width = '900px';
					pdf.style.margin = 'auto';
					pdf.style.borderColor = 'black';
					pdf.style.borderStyle = 'solid';
					pdf.style.borderWidth = '1px';
					pdf.style.marginBottom = '20px';
					pdf.src = ruta;
					document.getElementById('pdfContent').appendChild(pdf);
				});
				window.scrollTo(0,0);
				document.getElementById('pdfContainer').style.display = 'block';
				return true;
			}else if(req.readyState == 4 && req.status == 400){

			}else if(req.readyState == 4 && req.status == 204){

			}
		}
		req.open("POST", "https://callejeroine.sigeenergia.com/pdf", true);
		req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		req.send();
	}

	this.printPDF = function(){
		var ventana = window.open('', 'PRINT');
		ventana.document.write('<html><head><link rel="stylesheet" href="style.css" media="print">');
		ventana.document.write('</head><body ><style>@media print {@page { margin: 0; }body { margin: 0cm; }}</style>');
		pagesPDF.forEach(function(el){
			ventana.document.write('<img src="'+el+'" style="position:relative;width:100%;">');
		});
		ventana.document.write('</body></html>');
		ventana.document.close();
		ventana.focus();
		ventana.onload = function() {
			ventana.print();
			ventana.close();
		};
		return true;
	}

}]);