app.service('serviciosBOE', ['$http','jwtHelper', function ($http, jwtHelper) {
    this.respuestaSIPS = function (response) {
        var resupuestaPotencias = response.data.ClientesSips; 
        for (var i = 0; i < resupuestaPotencias.length; i++) {
            if (resupuestaPotencias[i].PotenciasContratadasEnWP1 > 0 || resupuestaPotencias[i].PotenciasContratadasEnWP2 > 0 || 
                resupuestaPotencias[i].PotenciasContratadasEnWP3 > 0 || resupuestaPotencias[i].PotenciasContratadasEnWP4 > 0 || 
                resupuestaPotencias[i].PotenciasContratadasEnWP5 > 0 || resupuestaPotencias[i].PotenciasContratadasEnWP6 > 0) 
        {
                /*******************************************************/
                var tarifaBOE = '';
                //resupuestaPotencias[i].CodigoTarifaATREnVigor <= 18 /* <-- Remplazar por el true en el if()*/ 
                if (true) 
                {
                    switch (resupuestaPotencias[i].CodigoTarifaATREnVigor) 
                    {
                        case "001": case "004": case "005": case "006": case "007": case "008": case "018":
                            tarifaBOE = "2.0TD ML"; //2.0A ML
                            break;
                        case "003":
                            tarifaBOE = "3.0TD ML"; //3.0A ML
                            break;   
                        case "011":
                            tarifaBOE = "6.1TD ML"; //3.1A ML
                            break;
                        case "012":
                            tarifaBOE = "6.1TD ML"; //6.1A ML
                            break;
                        case "013":
                            tarifaBOE = "6.2TD ML"; //6.2 ML
                            break;
                        case "014":
                            tarifaBOE = "6.3TD ML"; //6.3 ML
                            break;
                        case "015":
                            tarifaBOE = "6.4TD ML"; //6.4 ML
                            break;
                        case "016":
                            tarifaBOE = "6.4TD ML"; //6.5 ML
                            break;
                        case "017":
                            tarifaBOE = "6.1B ML"; //6.1B ML
                            break;

                        /*******************************************************/   
                        /*Códgio Temportal hasta que el SIPS traiga bien las nuevas Potencias*/
                        case "019":
                            tarifaBOE = "3.0TD ML"; //3.0A ML
                            break;   
                        case "020":
                            tarifaBOE = "6.1TD ML"; //3.1A ML
                            break;
                        case "021":
                            tarifaBOE = "6.2TD ML"; //6.2 ML
                            break;
                        case "022":
                            tarifaBOE = "6.3TD ML"; //6.3 ML
                            break;
                        case "023":
                            tarifaBOE = "6.4TD ML"; //6.4 ML
                            break;
                        case "024":
                            tarifaBOE = "3.0TDVE ML";
                            break;
                        case "025":
                            tarifaBOE = "6.1TDVE ML";
                            break;	
                        /*******************************************************/   
                    }

                    if (tarifaBOE == "2.0TD ML") 
                    {
                        if (resupuestaPotencias[i].PotenciasContratadasEnWP1 == null || resupuestaPotencias[i].PotenciasContratadasEnWP1 == 0) 
                        {
                            if (resupuestaPotencias[i].PotenciasContratadasEnWP2 != null || resupuestaPotencias[i].PotenciasContratadasEnWP2 != 0) 
                            {
                                resupuestaPotencias[i].PotenciasContratadasEnWP1 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                                resupuestaPotencias[i].PotenciasContratadasEnWP2 = 0;
                                if (resupuestaPotencias[i].PotenciasContratadasEnWP3 == null || resupuestaPotencias[i].PotenciasContratadasEnWP3 == 0) 
                                {
                                    resupuestaPotencias[i].PotenciasContratadasEnWP3 = resupuestaPotencias[i].PotenciasContratadasEnWP1;
                                }
                            }
                        } else if (resupuestaPotencias[i].PotenciasContratadasEnWP3 == null || resupuestaPotencias[i].PotenciasContratadasEnWP3 == 0) 
                        {
                            resupuestaPotencias[i].PotenciasContratadasEnWP3 = resupuestaPotencias[i].PotenciasContratadasEnWP1;
                        }
                        resupuestaPotencias[i].PotenciasContratadasEnWP2 = 0;
                        resupuestaPotencias[i].PotenciasContratadasEnWP4 = 0;
                        resupuestaPotencias[i].PotenciasContratadasEnWP5 = 0;
                        resupuestaPotencias[i].PotenciasContratadasEnWP6 = 0;
                    }
                    if ( tarifaBOE == "3.0TD ML" || tarifaBOE == "6.1TD ML") 
                    {
                        if ((resupuestaPotencias[i].PotenciasContratadasEnWP4 == null || resupuestaPotencias[i].PotenciasContratadasEnWP4 == 0) && 
                            (resupuestaPotencias[i].PotenciasContratadasEnWP5 == null || resupuestaPotencias[i].PotenciasContratadasEnWP5 == 0) && 
                            (resupuestaPotencias[i].PotenciasContratadasEnWP6 == null || resupuestaPotencias[i].PotenciasContratadasEnWP6 == 0)) 
                        {
                            if(resupuestaPotencias[i].PotenciasContratadasEnWP1 > resupuestaPotencias[i].PotenciasContratadasEnWP2 && resupuestaPotencias[i].PotenciasContratadasEnWP2 > resupuestaPotencias[i].PotenciasContratadasEnWP3){
                                resupuestaPotencias[i].PotenciasContratadasEnWP2 = resupuestaPotencias[i].PotenciasContratadasEnWP1;
                                resupuestaPotencias[i].PotenciasContratadasEnWP3 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                                resupuestaPotencias[i].PotenciasContratadasEnWP6 = resupuestaPotencias[i].PotenciasContratadasEnWP3;
                                resupuestaPotencias[i].PotenciasContratadasEnWP4 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                                resupuestaPotencias[i].PotenciasContratadasEnWP5 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                            }else{
                                resupuestaPotencias[i].PotenciasContratadasEnWP6 = resupuestaPotencias[i].PotenciasContratadasEnWP3;
                                resupuestaPotencias[i].PotenciasContratadasEnWP3 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                                resupuestaPotencias[i].PotenciasContratadasEnWP4 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                                resupuestaPotencias[i].PotenciasContratadasEnWP5 = resupuestaPotencias[i].PotenciasContratadasEnWP2;
                            }
                            
                            if(resupuestaPotencias[i].PotenciasContratadasEnWP6 < resupuestaPotencias[i].PotenciasContratadasEnWP5){
                                resupuestaPotencias[i].PotenciasContratadasEnWP6 = resupuestaPotencias[i].PotenciasContratadasEnWP5;
                            }
                        }
                    }

                response.data.ClientesSips[i] = resupuestaPotencias[i]
                }
            }   
        }
        return response;
    }
    this.conversionTarifas = function(playloadJson, codZona, resupuestaConsumos) {

        var IdTarifa = "";
        var codigoZ = "";
        
        if (resupuestaConsumos[0].CodigoTarifaATR < 18) 
        {   
            for (var i = 0; i < playloadJson.length; i++) 
            {
                if (resupuestaConsumos[0].CodigoTarifaATR == playloadJson[i].CodigoComunicacion) 
                { 
                    IdTarifa = playloadJson[i].IdTarifa
                }
            }
        }
        switch (codZona.substr(0, 2))
        {
            case "35": case "38":
                codigoZ = "2";
            break;
            case "07":
                codigoZ = "3";
            break;
            case "51":
                codigoZ = "4";
            break;
            case "52":
                codigoZ = "5";
            break;
            default:
                codigoZ = "1";
            break;
        }
        return $http({
            method: "POST",
            url: urlApi + "Tarifa/GetRepartoConsumosTarifa",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            },
            data: JSON.stringify({
                "IdTarifa": IdTarifa,
                "CodigoZona": codigoZ
            })
        });
    }
    this.repartoConsumo = function(valorConversionTarifa, resupuestaConsumos) {
        for(var i = 0 ;i < resupuestaConsumos.length;i++)
        {
            resupuestaConsumos[i].recalculoBOE = 0;
            if(resupuestaConsumos[i].CodigoTarifaATR < 18) 
            {
                resupuestaConsumos[i].recalculoBOE = 1;
                var fechaInicio = new Date(resupuestaConsumos[i].FechaInicio).getTime();
                var fechaFin = new Date(resupuestaConsumos[i].FechaFin).getTime();
     
                var restaFechas = moment( ( ( fechaFin - fechaInicio ) / 2 ) + fechaInicio ).format("MM"); // Diferencia de dias entre INICIO - FIN dividido entre 2 y sumado a fecha INICIO
                
                resupuestaConsumos[i].mesConsumo = restaFechas; //Mes de la diferencia entre fecha inicio y fecha fin
                resupuestaConsumos[i].consumoTotal = resupuestaConsumos[i].Activa1 + resupuestaConsumos[i].Activa2 + resupuestaConsumos[i].Activa3 + resupuestaConsumos[i].Activa4 + resupuestaConsumos[i].Activa5 + resupuestaConsumos[i].Activa6; //Consumo total por tramo de consumo

                for(var k = 0 ;k < valorConversionTarifa.length;k++){
                    if(parseFloat(resupuestaConsumos[i].mesConsumo) == parseFloat(valorConversionTarifa[k].Mes)){
                        resupuestaConsumos[i].Activa1 = Math.round(resupuestaConsumos[i].consumoTotal * valorConversionTarifa[k].Valor1 / 100);
                        resupuestaConsumos[i].Activa2 = Math.round(resupuestaConsumos[i].consumoTotal * valorConversionTarifa[k].Valor2 / 100);
                        resupuestaConsumos[i].Activa3 = Math.round(resupuestaConsumos[i].consumoTotal * valorConversionTarifa[k].Valor3 / 100);
                        resupuestaConsumos[i].Activa4 = Math.round(resupuestaConsumos[i].consumoTotal * valorConversionTarifa[k].Valor4 / 100);
                        resupuestaConsumos[i].Activa5 = Math.round(resupuestaConsumos[i].consumoTotal * valorConversionTarifa[k].Valor5 / 100);
                        resupuestaConsumos[i].Activa6 = Math.round(resupuestaConsumos[i].consumoTotal * valorConversionTarifa[k].Valor6 / 100);
                    }
                }
            }
         }
         return resupuestaConsumos;
    }
    this.TarifasComunicacion = function(){
        return $http({
            method: "POST",
            url: urlApi + "Tarifa/PostDTOAllTarifasComunicacion",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: atob(localStorage.getItem("TokenValidador"))
            },
        });
    }
}]);