app.service('servicioListadoOpcionesSips', [ function () {
	this.getListadoOpcionesSips = function () {
		var opcionesSips = [
			{
				value: "2",
				display: "CUPS"
			}, {
				value: "4",
				display: "MUNICIPIO"
			}, {
				value: "5",
				display: "PROVINCIA"
			}, {
				value: "6",
				display: "CODIGO POSTAL"
			}, {
				value: "9",
				display: "ULTIMO CAMBIO COMERCIALIZADORA"
			}, {
				value: "10",
				display: "DISTRIBUIDORA"
			}, {
				value: "11",
				display: "TENSION"
			}];

		return opcionesSips;
	};
}]);