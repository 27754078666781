app.controller("Sipsv2Ctrl", ['$scope', '$http', '$timeout', 'cfpLoadingBar', 'serviciosApi', 'jwtHelper', '$mdDialog', '$rootScope', '$location', 'servicioListadoDistribuidoras', 'servicioListadoTarifas', 'servicioListadoOpcionesSips', function ($scope, $http, $timeout, cfpLoadingBar, serviciosApi, jwtHelper, $mdDialog, $rootScope, $location, servicioListadoDistribuidoras, servicioListadoTarifas, servicioListadoOpcionesSips) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdEmpresa = localStorage.getItem('idEmpresa');
    var Logueado = localStorage.getItem('logueado');
    var contadorConsumo = 0;
    var map;
    var VerEnergia;
    var VerGas;

    $scope.selected = [];

    $scope.informacionSipsCliente = informacionSipsCliente;
    $scope.TextoDescargarSips = TextoDescargarSips;

    if (localStorage.getItem('idEmpresaEnergia') != undefined && localStorage.getItem('idEmpresaEnergia') == 'true') {
        $scope.VerEnergia = true;
        $scope.VerGas = false;
    }

    if (localStorage.getItem('idEmpresaGas') != undefined && localStorage.getItem('idEmpresaGas') == 'true') {
        $scope.VerGas = true;
        $scope.VerEnergia = false;
    }

    $scope.distribuidoras = servicioListadoDistribuidoras.getListadoDistribuidoras();

    $scope.obtenerDistribuidoras = function (text) {
        text = text.toUpperCase();
        var ret = $scope.distribuidoras.filter(function (d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

    $scope.opcionesSips = servicioListadoOpcionesSips.getListadoOpcionesSips();

    $scope.obtenerOpcionesSips = function (text) {
        text = text.toUpperCase();
        var ret = $scope.opcionesSips.filter(function (d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

    $scope.busquedaFiltroSips = function () {
        var input, filter, tr, td, i, txtValue, valor;
        var valor = ($scope.selectedItemSips === null) ? "4" : $scope.selectedItemSips.value;

        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        tr = document.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[valor];

            if (td) {
                console.log("hay");
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1  || i%2 == 0)  { //por cada tr hay otro con su gráfico, entonces solo queremos modificar los tr impares
                    tr[i].style.display = "";
                } else {
                   /* console.log(tr[(i+1)].style.display +"as");
                    if(tr[i+1].style.display != "none"){
                        console.log("asdas");
                        tr[i+1].style.display = "none";
                    }*/
                    tr[i].style.display = "none";
                }
            }
        }
    }


    $scope.tarifas = servicioListadoTarifas.getListadoTarifas();

    $scope.obtenerTarifas = function (text) {
        text = text.toUpperCase();
        var ret = $scope.tarifas.filter(function (d) {
            return d.display.startsWith(text);
        });
        return ret;
    };

    $scope.MostrarSipsBuscadorCups = MostrarSipsBuscadorCups;
    $scope.MostrarSipsBuscadorDistribuidora = MostrarSipsBuscadorDistribuidora;
    $scope.MostrarSipsBuscadorCodigoPostal = MostrarSipsBuscadorCodigoPostal;
    $scope.MostrarSipsBuscadorDni = MostrarSipsBuscadorDni;
    $scope.MostrarSipsBuscadorDireccion = MostrarSipsBuscadorDireccion;
    $scope.MostrarSipsBuscadorTitular = MostrarSipsBuscadorTitular;
    $scope.MostrarSipsBuscadorTelefono = MostrarSipsBuscadorTelefono;
    $scope.MostrarSipsBuscadorTarifa = MostrarSipsBuscadorTarifa;
    $scope.MostrarSipsBuscadorListaCups = MostrarSipsBuscadorListaCups;
    $scope.MostrarSipsBuscadorGraficoConsumos = MostrarSipsBuscadorGraficoConsumos;

    $scope.MostrarSipsInfoGeneral = MostrarSipsInfoGeneral;
    $scope.MostrarSipsCups = MostrarSipsCups;
    $scope.MostrarSipsDistribuidora = MostrarSipsDistribuidora;
    $scope.MostrarSipsDireccionSuministro = informacionSipsCliente;
    $scope.MostrarSipsMunicipio = MostrarSipsMunicipio;
    $scope.MostrarSipsProvincia = MostrarSipsProvincia;
    $scope.MostrarSipsCodigoPostal = MostrarSipsCodigoPostal;
    $scope.MostrarSipsTarifa = MostrarSipsTarifa;
    $scope.MostrarSipsTension = MostrarSipsTension;
    $scope.MostrarSipsPresion = MostrarSipsPresion;
    $scope.MostrarSipsP1 = MostrarSipsP1;
    $scope.MostrarSipsP2 = MostrarSipsP2;
    $scope.MostrarSipsP3 = MostrarSipsP3;
    $scope.MostrarSipsP4 = MostrarSipsP4;
    $scope.MostrarSipsP5 = MostrarSipsP5;
    $scope.MostrarSipsP6 = MostrarSipsP6;
    $scope.MostrarSipsCaudal = MostrarSipsCaudal;
    $scope.MostrarSipsCaudalMaximo = MostrarSipsCaudalMaximo;
    $scope.MostrarSipsUltimoCambioComercializadora = MostrarSipsUltimoCambioComercializadora;
    $scope.MostrarSipsUltimoCambioBIE = MostrarSipsUltimoCambioBIE;
    $scope.MostrarSipsUltimoCambioContrato = MostrarSipsUltimoCambioContrato;
    $scope.MostrarSipsConsumoAnual = MostrarSipsConsumoAnual;
    $scope.MostrarSipsFechaFinContrato = MostrarSipsFechaFinContrato;
    $scope.MostrarSipsConsumoP1 = MostrarSipsConsumoP1;
    $scope.MostrarSipsConsumoP2 = MostrarSipsConsumoP2;
    $scope.MostrarSipsConsumoP3 = MostrarSipsConsumoP3;
    $scope.MostrarSipsConsumoP4 = MostrarSipsConsumoP4;
    $scope.MostrarSipsConsumoP5 = MostrarSipsConsumoP5;
    $scope.MostrarSipsConsumoP6 = MostrarSipsConsumoP6;
    $scope.MostrarSipsTrasladarContrato = MostrarSipsTrasladarContrato;
    $scope.TextoPeriodoConsumo = TextoPeriodoConsumo;
    $scope.PeriodoConsumo = PeriodoConsumo;

    $scope.autocolumn = [
        {
            name: "InformacionGeneral",
            display: "Descarga Individual",
            oculta: "InfoGeneral"
        }, {
            name: "Cups",
            display: "Cups",
            oculta: "Cups"
        }, {
            name: "DireccionSuministro",
            display: "Dirección Suministro",
            oculta: "DireccionSuministro"
        }, {
            name: "Municipio",
            display: "Municipio",
            oculta: "Municipio"
        }, {
            name: "Provincia",
            display: "Provincia",
            oculta: "Provincia"
        }, {
            name: "CodPostal",
            display: "Código Postal",
            oculta: "CodigoPostal"
        }/*, {
        name: "Tarifa",
        display: "Tarifa",
        oculta: "Tarifa"
    }, {
        name: "ConsumoAnual",
        display: $scope.TextoPeriodoConsumo,
        oculta: "ConsumoAnual && VerEnergia"
    }, {
        name: "ConsumoAnual",
        display: "CAE",
        oculta: "ConsumoAnual && VerGas"
    }, {
        name: "ConsumoP1",
        display: $scope.TextoPeriodoConsumo + " P1",
        oculta: "ConsumoP1  && VerEnergia"
    }, {
        name: "ConsumoP2",
        display: $scope.TextoPeriodoConsumo + " P2",
        oculta: "ConsumoP2  && VerEnergia"
    }, {
        name: "ConsumoP3",
        display: $scope.TextoPeriodoConsumo + " P3",
        oculta: "ConsumoP3  && VerEnergia"
    }, {
        name: "ConsumoP4",
        display: $scope.TextoPeriodoConsumo + " P4",
        oculta: "ConsumoP4  && VerEnergia"
    }, {
        name: "ConsumoP5",
        display: $scope.TextoPeriodoConsumo + " P5",
        oculta: "ConsumoP6  && VerEnergia"
    }, {
        name: "ConsumoP6",
        display: $scope.TextoPeriodoConsumo + " P6",
        oculta: "ConsumoP6  && VerEnergia"
    }, {
        name: "Caudal",
        display: "Caudal Horario",
        oculta: "Caudal && VerGas"
    }, {
        name: "CaudalMaximo",
        display: "Caudal Máximo",
        oculta: "CaudalMaximo && VerGas"
    }, {
        name: "P1",
        display: "P1",
        oculta: "P1  && VerEnergia"
    }, {
        name: "P2",
        display: "P2",
        oculta: "P2  && VerEnergia"
    }, {
        name: "P3",
        display: "P3",
        oculta: "P3  && VerEnergia"
    }, {
        name: "P4",
        display: "P4",
        oculta: "P4  && VerEnergia"
    }, {
        name: "P5",
        display: "P5",
        oculta: "P5  && VerEnergia"
    }, {
        name: "P6",
        display: "P6",
        oculta: "P6  && VerEnergia"
    }*/, {
            name: "UltimoCambioComercializadora",
            display: "Último Cambio Comercializadora",
            oculta: "UltimoCambioComercializadora"

        }, {
            name: "Distribuidora",
            display: "Distribuidora",
            oculta: "Distribuidora"
        }/*, {
        name: "UltimoCambioBIE",
        display: "Último Cambio BIE",
        oculta: "UltimoCambioBIE"
    }*/, {
            name: "Tension",
            display: "Tensión",
            oculta: "Tension && VerEnergia"
        }/*, {
        name: "Presion",
        display: "Presión",
        oculta: "Presion && VerGas"
    }, {
        name: "UltimoCambioContrato",
        display: "Último Cambio Contrato",
        oculta: "UltimoCambioContrato"
	}*/, {
            name: "TrasladarContrato",
            display: "Trasladar Contrato",
            oculta: "TrasladarContrato"
        }];

    $scope.MostrarCampos = function () {

        //Si hay un mapa ya generado lo vacia
        var myobj = document.getElementById("map");

        if (myobj.hasChildNodes()) {
            myobj.removeChild(myobj.childNodes[0]);
        }




        if ($scope.clientesSips != undefined) {
            $scope.clientesSips = [];
            $scope.consumosAnuales = [];
            $scope.selected = [];
        }
        $scope.consumosVisible = false;

        var Cups = ($scope.Cups === undefined) ? "" : $scope.Cups;
        var CodigoPostalPS = ($scope.CodigoPostalPS === undefined) ? "" : $scope.CodigoPostalPS;
        var NombreEmpresaDistribuidora = ($scope.selectedItem === null) ? "" : $scope.selectedItem.value;
        var PotenciasContratadasEnWP12 = ($scope.PotenciasContratadasEnWP12 === null) ? "" : $scope.PotenciasContratadasEnWP12;
        var CodigoTarifaATREnVigor = $scope.selectedItemTarifa == undefined ? "" : $scope.selectedItemTarifa.value;
        var MunicipioPS = $scope.MunicipioPS == undefined ? "" : $scope.MunicipioPS.valor;
        var CodigoProvinciaPS = $scope.CodigoProvinciaPS == undefined ? "" : $scope.CodigoProvinciaPS.valor;
        // TODO: Nuevos inputs
        var DniTitular = ($scope.DniTitular === undefined) ? "" : $scope.DniTitular;
        var DireccionPS = ($scope.DireccionPS === undefined) ? "" : $scope.DireccionPS;
        var NombreTitular = ($scope.NombreTitular === undefined) ? "" : $scope.NombreTitular;
        var Telefono = ($scope.Telefono === undefined) ? "" : $scope.Telefono;

        var ListCUPS = $scope.ListCUPS == undefined ? "" : $scope.ListCUPS;
        var cups = "";
        var resultado = "";

        if (ListCUPS != "") {

            if (ListCUPS.indexOf('.') !== -1) {
                ListCUPS = ListCUPS.replace(/\./g, ",");
            }

            if (ListCUPS.indexOf(';') !== -1) {
                ListCUPS = ListCUPS.replace(/\;/g, ",");
            }

            if (ListCUPS.indexOf(' ') !== -1) {
                ListCUPS = ListCUPS.replace(/ /g, ",");
            }

            if (ListCUPS.indexOf(',,') !== -1) {
                ListCUPS = ListCUPS.replace(/\,\,/g, ",");
            }

            cups = ListCUPS.split(",");

            resultado = cups.map(function (elemento, i) {
                return elemento;
            });
        }
        if (
            //al poner PotenciasContratadasEnWP1, he modificado también menu-svc-.js
            Logueado == 'true' &&
            (Cups != '' || CodigoPostalPS != '' || NombreEmpresaDistribuidora != '' || PotenciasContratadasEnWP12 != '' || CodigoTarifaATREnVigor != '' || MunicipioPS != '' || CodigoProvinciaPS != '' || resultado.length > 0)
        ) {
            serviciosApi.getSips(Cups, NombreEmpresaDistribuidora, MunicipioPS, CodigoProvinciaPS, CodigoPostalPS, CodigoTarifaATREnVigor, resultado, PotenciasContratadasEnWP12)
                .then(function successCallback(response) {
                    console.log(response);
                    contadorConsumo = 0; //reseteo un contador para poder generar graficos de consumo con las id correspondientes

                    //DatosSIPS
                    $scope.clientesSips = response.data.ClientesSips;
                    //Guardo todos los resultados de consumos en un array para luego usarlos en un ng-repeat en la vista
                    $scope.consumosSips = response.data.ConsumosSips;
                    $scope.datosTitular = response.data.DatosTitular;


                    if ($scope.clientesSips.length > 0) {

                        // Si es 2.0 DHA / 2.1 DHA mover P2 a P33
                        if ($scope.VerEnergia && ($scope.clientesSips[0].CodigoTarifaATREnVigor == "004" || $scope.clientesSips[0].CodigoTarifaATREnVigor == "006")) {
                            $scope.clientesSips[0].PotenciasContratadasEnWP3 = $scope.clientesSips[0].PotenciasContratadasEnWP1;
                            $scope.clientesSips[0].PotenciasContratadasEnWP2 = null;
                        }

                        // Inserimos
                        if (MostrarSipsPotenciasSige) {
                            for (var i = 0; i < $scope.clientesSips.length; i++) {
                                var cups = $scope.clientesSips[i].CodigoCUPS;
                                $http
                                    .get(urlApi + "CUPS/VerificarCUPSExistente/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
                                    .then(function (response) {
                                        var playload = JSON.parse(atob(response.data.split('.')[1]));
                                        var playloadJson = JSON.parse(playload.iss);

                                        if (playloadJson) {
                                            $http
                                                .get(urlApi + "CUPS/GetCUPSPotencias/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario + "/CUPS/" + cups)
                                                .success(function (response) {
                                                    var decodeToken = jwtHelper.decodeToken(response);
                                                    var playloadJsonPotencias = JSON.parse(decodeToken.iss);

                                                    var potenciaP1 = document.getElementById('PotenciaP1').textContent;
                                                    var potenciaP2 = document.getElementById('PotenciaP2').textContent;
                                                    var potenciaP3 = document.getElementById('PotenciaP3').textContent;
                                                    var potenciaP4 = document.getElementById('PotenciaP4').textContent;
                                                    var potenciaP5 = document.getElementById('PotenciaP5').textContent;
                                                    var potenciaP6 = document.getElementById('PotenciaP6').textContent;

                                                    if (playloadJsonPotencias[0].TextoTarifa == "2.0A ML" || playloadJsonPotencias[0].TextoTarifa == "2.1A ML") {
                                                        document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
                                                        document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP2').innerHTML = "0";
                                                        document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP4').innerHTML = "0";
                                                        document.getElementById('PotenciaP5').innerHTML = "0";
                                                        document.getElementById('PotenciaP6').innerHTML = "0";
                                                    }

                                                    if (playloadJsonPotencias[0].TextoTarifa == "2.0DHA ML" || playloadJsonPotencias[0].TextoTarifa == "2.1DHA ML") {
                                                        document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
                                                        document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP2').innerHTML = "0";
                                                        document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP4').innerHTML = "0";
                                                        document.getElementById('PotenciaP5').innerHTML = "0";
                                                        document.getElementById('PotenciaP6').innerHTML = "0";
                                                    }

                                                    if (
                                                       playloadJsonPotencias[0].TextoTarifa == "3.0A ML" ||
                                                        playloadJsonPotencias[0].TextoTarifa == "3.1A ML"
                                                    ) {
                                                        document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
                                                        document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
                                                        document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
                                                        document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
                                                        document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
                                                    }

                                                    if (playloadJsonPotencias[0].TextoTarifa == "6.1A ML" || playloadJsonPotencias[0].TextoTarifa == "6.2 ML" || playloadJsonPotencias[0].TextoTarifa == "6.3 ML" || playloadJsonPotencias[0].TextoTarifa == "6.4 ML" || playloadJsonPotencias[0].TextoTarifa == "6.5 ML" || playloadJsonPotencias[0].TextoTarifa == "6.1B ML") {
                                                        document.getElementById('SipsTarifa').innerHTML = playloadJsonPotencias[0].TextoTarifa;
                                                        document.getElementById('PotenciaP1').innerHTML = playloadJsonPotencias[0].PotenciaContratada;
                                                        document.getElementById('PotenciaP2').innerHTML = playloadJsonPotencias[1].PotenciaContratada;
                                                        document.getElementById('PotenciaP3').innerHTML = playloadJsonPotencias[2].PotenciaContratada;
                                                        document.getElementById('PotenciaP4').innerHTML = playloadJsonPotencias[3].PotenciaContratada;
                                                        document.getElementById('PotenciaP5').innerHTML = playloadJsonPotencias[4].PotenciaContratada;
                                                        document.getElementById('PotenciaP6').innerHTML = playloadJsonPotencias[5].PotenciaContratada;
                                                    }
                                                });
                                        } else { }
                                    });
                            }
                        }

                        var original = {
                            "count": response.data.ClientesSips.length,
                            "data": response.data.ClientesSips
                        };

                        $scope.datatable = angular.copy(original);
                        $scope.smart = true;
                    } else {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .htmlContent('No se han encontrado datos.')
                                .ariaLabel('No se han encontrado datos.')
                                .ok('Aceptar')
                        );
                    }

                }, function errorCallback(response) {
                    var alert = $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('SIPS')
                        .htmlContent('La información no está disponible.')
                        .ariaLabel('La información no está disponible.')
                        .ok('Aceptar');
                    $mdDialog.show(alert);
                });
        } else { console.log("Datos vacios"); }
    };

    $scope.calcularConsumoAnual = function (cliente) {

        console.log(cliente);

        $scope.consumosAnuales = [];
        var CupsLinea = cliente.clienteSips.CodigoCUPS;
        if (CupsLinea != undefined) {
            if (localStorage.getItem('idEmpresaGas') == 'true') {
                urlApiSips = urlApiSipsGas;
            }

            $http.get(urlApiSips + "/ConsumoGet/?_SipsCupsFiltersDTO={\"CodigosCUPS\":[\"" + CupsLinea + "\"]}").then(function (response) {
                var BusquedaConsumos = response.data;
                var ConsumosAnualesCups = [];
                var sumatorioActivas = 0;

                if (BusquedaConsumos.length > 0) {
                    var FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFin;
                    if (FechaFin == null) {
                        FechaFin = BusquedaConsumos[BusquedaConsumos.length - 1].FechaFinMesConsumo;
                    }
                    var FechaActual = new Date(FechaFin);


                    var FechaFormateada = FechaActual.getFullYear() + "-" + (FechaActual.getMonth() + 1) + "-" + FechaActual.getDate();
                    var FechaFin2 = FechaFin.split('T');
                    var FechaInicio = new Date(FechaFin2[0]);
                    FechaInicio = FechaInicio.getFullYear() - 1 + "-" + ("0" + (FechaInicio.getMonth() + 1)).slice(-2) + "-" +  ("0" + FechaInicio.getDate()).slice(-2) + 'T00:00:00';
                    $scope.P1 = 0;
                    $scope.P2 = 0;
                    $scope.P3 = 0;
                    $scope.P4 = 0;
                    $scope.P5 = 0;
                    $scope.P6 = 0;

                    if ($scope.VerGas) {
                        var auxCaudalMedioEnWhDia = 0;
                        var auxCaudalMedioEnWhAnual = 0;
                        var auxCaudalMaximoDiario = 0;
                        var doceUltimosMesesInteracion = BusquedaConsumos.length - 12;
                    }
                    $.grep(BusquedaConsumos, function (n, i) {

                        if (BusquedaConsumos[i].FechaInicio == null) {
                            BusquedaConsumos[i].FechaInicio = BusquedaConsumos[i].FechaInicioMesConsumo;
                        }

                        if (n.FechaInicio >= FechaInicio || n.FechaInicio == null) {
                            // console.log(n);
                            ConsumosAnualesCups.push(n);
                            sumatorioActivas += BusquedaConsumos[i].Activa1 + BusquedaConsumos[i].Activa2 + BusquedaConsumos[i].Activa3 +
                                BusquedaConsumos[i].Activa4 + BusquedaConsumos[i].Activa5 + BusquedaConsumos[i].Activa6;

                            $scope.P1 += BusquedaConsumos[i].Activa1;
                            $scope.P2 += BusquedaConsumos[i].Activa2;
                            $scope.P3 += BusquedaConsumos[i].Activa3;
                            $scope.P4 += BusquedaConsumos[i].Activa4;
                            $scope.P5 += BusquedaConsumos[i].Activa5;
                            $scope.P6 += BusquedaConsumos[i].Activa6;

                        }


                        if ($scope.VerGas) {

                            if (n.CaudalMedioEnWhDia > 0) {
                                auxCaudalMedioEnWhDia = n.CaudalMedioEnWhDia;
                                cliente.clienteSips.CaudalHorarioEnWh = auxCaudalMedioEnWhDia;
                            }

                            // consumo anual gas
                            if (i >= doceUltimosMesesInteracion) {
                                auxCaudalMedioEnWhAnual = n.ConsumoEnWhP1 + auxCaudalMedioEnWhAnual;
                            }

                            // console.log(n.CaudalMaximoDiario + " > " + auxCaudalMaximoDiario);
                            if (n.CaudalMaximoDiario != null && n.CaudalMaximoDiario > auxCaudalMaximoDiario) {
                                cliente.clienteSips.CaudalMaximoDiarioEnWh = auxCaudalMaximoDiario = n.auxCaudalMaximoDiario;
                            }

                        }

                    });

                    // console.log("*****");
                    // console.log(cliente.clienteSips.CaudalHorarioEnWh);
                    // console.log(cliente.clienteSips.CaudalMaximoDiarioEnWh);
                    // console.log("*****");

                    var fechaInicialMesConsumo = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
                    var fechaInicio = new Date();
                    if (fechaInicialMesConsumo == null) {
                        fechaInicio = new Date(ConsumosAnualesCups[0].FechaInicioMesConsumo).getTime();
                    } else {
                        fechaInicio = new Date(ConsumosAnualesCups[0].FechaInicio).getTime();
                    }
                    var fechaFin = new Date(FechaFin).getTime();
                    var countDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
                    var consumos = Math.round(((sumatorioActivas / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                    console.log(ConsumosAnualesCups[0].FechaInicio);
                    console.log(FechaFin);
                    console.log(countDias);
                    $scope.P1 = Math.round((($scope.P1 / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                    $scope.P2 = Math.round((($scope.P2 / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                    $scope.P3 = Math.round((($scope.P3 / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                    $scope.P4 = Math.round((($scope.P4 / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                    $scope.P5 = Math.round((($scope.P5 / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                    $scope.P6 = Math.round((($scope.P6 / 1000) / countDias) * 365) / $scope.PeriodoConsumo;
                }

                var tarifa = cliente.clienteSips.CodigoTarifaATREnVigor;

                if ($scope.VerEnergia && (tarifa == "004" || tarifa == "006")) {
                    // Si es 2.0 DHA / 2.1 DHA mover P2 a P3
                    $scope.P3 = $scope.P2;
                    $scope.P2 = "0";
                } else if ($scope.VerEnergia && (tarifa == "003" || tarifa == "011")) {
                    // Suma P1+P4 / P2+P5 / P3+P6 en tarifas 3.0 y 3.1
                    $scope.P1 = $scope.P1 + $scope.P4;
                    $scope.P2 = $scope.P2 + $scope.P5;
                    $scope.P3 = $scope.P3 + $scope.P6;
                    $scope.P4 = "0";
                    $scope.P5 = "0";
                    $scope.P6 = "0";
                }

                // if (consumos == 0) {
                // 	var valorMasAlto = Math.max(cliente.clientesSips[0].PotenciasContratadasEnWP1, cliente.clientesSips[0].PotenciasContratadasEnWP2, cliente.clientesSips[0].PotenciasContratadasEnWP3, cliente.clientesSips[0].PotenciasContratadasEnWP4, cliente.clientesSips[0].PotenciasContratadasEnWP5, cliente.clientesSips[0].PotenciasContratadasEnWP6);
                // 	consumos = valorMasAlto * 8760 * 0.2;
                // }

                if ($scope.VerGas) {
                    consumos = auxCaudalMedioEnWhAnual / 1000;
                }

                $scope.consumosAnuales.push({
                    "CUPS": CupsLinea,
                    "ConsumoAnual": consumos ? Math.round(consumos) : 0,
                    "ConsumoP1": ($scope.P1) ? $scope.P1 : 0,
                    "ConsumoP2": ($scope.P2) ? $scope.P2 : 0,
                    "ConsumoP3": ($scope.P3) ? $scope.P3 : 0,
                    "ConsumoP4": ($scope.P4) ? $scope.P4 : 0,
                    "ConsumoP5": ($scope.P5) ? $scope.P5 : 0,
                    "ConsumoP6": ($scope.P6) ? $scope.P6 : 0,
                    "CaudalHorarioEnWh": cliente.clienteSips.CaudalHorarioEnWh,
                    "CaudalMaximoDiarioEnWh": cliente.clienteSips.CaudalMaximoDiarioEnWh,
                    "CodigoPeajeEnVigor": cliente.clienteSips.CodigoPeajeEnVigor,
                    "CodigoPostal": cliente.clienteSips.CodigoPostalPS,
                    "CodigoPresion": cliente.clienteSips.CodigoPresion,
                    "CodigoTensionV": cliente.clienteSips.CodigoTensionV,
                    "Distribuidora": cliente.clienteSips.NombreEmpresaDistribuidora,
                    "FechaEmisionBIE": cliente.clienteSips.FechaEmisionBIE,
                    "FechaUltimaLectura": cliente.clienteSips.FechaUltimaLectura,
                    "FechaUltimoCambioComercializador": cliente.clienteSips.FechaUltimoCambioComercializador,
                    "FechaUltimoMovimientoContrato": cliente.clienteSips.FechaUltimoMovimientoContrato,
                    "Municipio": cliente.clienteSips.DesMunicipioPS,
                    "PotenciasWP1": cliente.clienteSips.PotenciasContratadasEnWP1,
                    "PotenciasWP2": cliente.clienteSips.PotenciasContratadasEnWP2,
                    "PotenciasWP3": cliente.clienteSips.PotenciasContratadasEnWP3,
                    "PotenciasWP4": cliente.clienteSips.PotenciasContratadasEnWP4,
                    "PotenciasWP5": cliente.clienteSips.PotenciasContratadasEnWP5,
                    "PotenciasWP6": cliente.clienteSips.PotenciasContratadasEnWP6,
                    "Provincia": cliente.clienteSips.DesProvinciaPS,
                    "Tarifa": cliente.clienteSips.CodigoTarifaATREnVigor,
                });

                $scope.busquedaSips = $scope.consumosAnuales;

                $scope.generarMapa(CupsLinea, cliente.clienteSips.DesMunicipioPS, cliente.clienteSips.DesProvinciaPS, cliente.clienteSips.CodigoPostalPS);


                contadorConsumo++;
                $scope.generarGraficoConsumo(response);
            });

        }

    };

    $scope.selected = [];

    $scope.VerConsumos = function (selected) {
        $scope.consumosVisible = !$scope.consumosVisible;
        var cons = [];
        var cupsSelected = [];
        // CUPS seleccionados
        $.grep(selected, function (n) { cupsSelected.push(n.CodigoCUPS.toString()); });
        $.grep($scope.busquedaSips, function (n) {
            // Si el CUPS seleccionado se encuentra
            if (cupsSelected.indexOf(n.CUPS) > -1) {
                cons.push({
                    name: n.CUPS,
                    data: [Number(n.ConsumoP1), Number(n.ConsumoP2), Number(n.ConsumoP3), Number(n.ConsumoP4), Number(n.ConsumoP5), Number(n.ConsumoP6)]
                });
            }
        });
        new Highcharts.Chart({
            chart: { renderTo: 'grafico', type: 'column' },
            title: { text: 'Gráfico de Consumos' },
            yAxis: { title: { text: 'Consumo en kWh' }, min: 0 },
            xAxis: { categories: ['P1', 'P2', 'P3', 'P4', 'P5', 'P6',] },
            series: cons,
            credits: false
        });
    };


    $scope.generarMapa = function (Cups, municipioPS, provinciaPS, codigoPostalPS) {

        $http({
            method: "POST",
            url: "https://apipadomestica.sigeenergia.com/api/v1/CUPS/DireccionCUPS",
            data: { CodigoCups: Cups }


        }).then(function successCallback(response) {
            var dataJson = JSON.parse(response.data.replace(/([a-zA-Z0-9]+?):/g, '"$1":').replace(/'/g, '"'));

            if (dataJson.length > 0) {
                var direccionParticionada = dataJson[0];
                var NombreCalleCups = angular.copy(((direccionParticionada.Via != "") ? direccionParticionada.Via + " " : "") + direccionParticionada.Calle);
                var Numero = angular.copy(parseInt(direccionParticionada.Numero));
                //   var Aclarador = angular.copy(direccionParticionada.Aclarador);

                var Busqueda = NombreCalleCups + " " + Numero + " " + municipioPS + " " + "España" + " " + codigoPostalPS;
                //var DireccionClickPuntero = NombreCalleCups + " " + Numero +  " " + codigoPostalPS;

                var urlBusqueda = Busqueda.replace(/\s/g, '%20');


                //var prueba = "eroski";

                return ($http.get(" https://photon.komoot.io/api/?q=" + urlBusqueda));
            }

        }).then(function (response) {
            var myobj = document.getElementById("map");
            console.log(response);

            console.log(response.config.url);
            //  var DireccionClickPuntero = Busqueda.replace('%20', '%20');
            var DireccionClickPuntero = response.config.url.slice(33, 180);
            console.log(DireccionClickPuntero);
            DireccionClickPuntero = DireccionClickPuntero.replaceAll('%20', ' ');
            console.log(DireccionClickPuntero);



            var longitud = response.data.features[0].geometry.coordinates[0];
            var latitud = response.data.features[0].geometry.coordinates[1];
            var coords = []; coords.push(longitud, latitud);
            var coordsOMS = ol.proj.fromLonLat(coords);
            var coordsCentroEspana = [-3.74922, 40.463667];
            var coordsOMSEspana = ol.proj.fromLonLat(coordsCentroEspana);

            if (myobj.hasChildNodes()) {

                var layer = new ol.layer.Vector({
                    style: (new ol.style.Style({
                        image: new ol.style.Icon(({
                            opacity: 1,
                            scale: 0.06,
                            src: "https://hotelquintadelsol.co/wp-content/uploads/2018/01/google-maps-marker-for-residencelamontagne-hi.png",
                        })),
                    })),
                    source: new ol.source.Vector({
                        features: [
                            new ol.Feature({
                                type: 'click',
                                desc: DireccionClickPuntero,
                                geometry: new ol.geom.Point(coordsOMS)
                            })
                        ]
                    })
                });
                map.addLayer(layer);

                return;
            } else {


            }

            /*var longitud = "";
            var latitud = "";
            console.log(response.data.features);
             var contador2 = 0;
             response.data.features.forEach(function (element) {
                 // console.log(element);
                 // console.log(NombreCalleCups);
                 if (element.properties.street != null) {
                     if (element.properties.street.toLowerCase().includes(NombreCalleCups.toLowerCase())) {
                         console.log(element.properties.street + " ---Coincide con---" + NombreCalleCups.toLowerCase() + contador2);

                     } else {
                         console.log(element.properties.street + " +++No Coincide con +++" + NombreCalleCups.toLowerCase() + contador2);
                     }
                 }
                 contador2++;

             });*/




            var attribution = new ol.control.Attribution({

                label: "Mapa de España",

            });

            map = new ol.Map({


                controls: ol.control.defaults({
                    zoom: false,
                    rotate: false,
                    attribution: false,

                }).extend([attribution]),


                layers: [
                    new ol.layer.Tile({  //ol.layer.tile
                        source: new ol.source.OSM(),
                    })],
                target: 'map',
                view: new ol.View({

                    center: coordsOMSEspana,
                    zoom: 5.5,
                    maxZoom: 18,
                }),

            });
            var layer = new ol.layer.Vector({
                style: (new ol.style.Style({
                    image: new ol.style.Icon(({
                        opacity: 1,
                        scale: 0.06,
                        src: "https://hotelquintadelsol.co/wp-content/uploads/2018/01/google-maps-marker-for-residencelamontagne-hi.png",
                    })),
                })),
                source: new ol.source.Vector({
                    features: [
                        new ol.Feature({
                            type: 'click',
                            desc: DireccionClickPuntero,
                            geometry: new ol.geom.Point(coordsOMS)
                        })
                    ]
                })
            });

            var element = document.getElementById('popup');


            map.addLayer(layer);
            var popup = new ol.Overlay({
                element: element,
                offset: [-80, -55],
            })

            map.addOverlay(popup);



            map.on('click', function (evt) {

                var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                    return feature;
                });
                if (feature) {
                    var coordinates = feature.getGeometry().getCoordinates();
                    popup.setPosition(coordinates);
                    console.log(element);
                    console.log(feature.values_.desc);
                    $(element).html(feature.values_.desc);
                    $(element).css('height', "10px");
                    $(element).css('width', "180px");


                } else {
                    $(element).css('height', "0px");
                    $(element).css('width', "0px");

                    $(element).html("");

                }
            });

        }, function errorCallback(response) {
            console.log("no econtró ninguna calle");

        })


        //ZOOM IN / OUT
        /* document.getElementById('zoom-out').onclick = function () {
             var view = map.getView();
             var zoom = view.getZoom();
             view.setZoom(zoom - 1);
         };

         document.getElementById('zoom-in').onclick = function () {
             var view = map.getView();
             var zoom = view.getZoom();
             view.setZoom(zoom + 1);
         };*/
    }



    $scope.generarGraficoConsumo = function (response) {
        if (response.data.length < 12) {
            var ultimosDoce = 0; // si no tiene +12 meses de consumo no es necesario buscar los ultimos12

        } else {
            var ultimosDoce = response.data.length - 12; // si tenemos 60 arrays, obtendremos el num 48, y más tarde en el for del 48-60

        }

        var datosPotencia1 = []; var datosPotencia2 = []; var datosPotencia3 = []; var datosPotencia4 = []; var datosPotencia5 = []; var datosPotencia6 = [];
        var ArrayPotencia1 = []; var ArrayPotencia2 = []; var ArrayPotencia3 = []; var ArrayPotencia4 = []; var ArrayPotencia5 = []; var ArrayPotencia6 = [];

        for (var i = 1; i < 7; i++) { //Recoge las 6 activas durante 12 meses


            for (var j = 0; j < 12; j++) {
                if (response.data[ultimosDoce + j] == null) { //si no tiene 12 meses registrados, x ej 6, del 7 en adelante skipeará el loop y hará medio gráfico
                    break;
                }
                datosPotencia1.push({ label: response.data[ultimosDoce + j].FechaFin, y: response.data[ultimosDoce + j].Activa1 });
                datosPotencia2.push({ label: response.data[ultimosDoce + j].FechaFin, y: response.data[ultimosDoce + j].Activa2 });
                datosPotencia3.push({ label: response.data[ultimosDoce + j].FechaFin, y: response.data[ultimosDoce + j].Activa3 });
                datosPotencia4.push({ label: response.data[ultimosDoce + j].FechaFin, y: response.data[ultimosDoce + j].Activa4 });
                datosPotencia5.push({ label: response.data[ultimosDoce + j].FechaFin, y: response.data[ultimosDoce + j].Activa5 });
                datosPotencia6.push({ label: response.data[ultimosDoce + j].FechaFin, y: response.data[ultimosDoce + j].Activa6 });
            }

            ArrayPotencia1.push(datosPotencia1); ArrayPotencia2.push(datosPotencia2); ArrayPotencia3.push(datosPotencia3); ArrayPotencia4.push(datosPotencia4); ArrayPotencia5.push(datosPotencia5); ArrayPotencia6.push(datosPotencia6);
            datosPotencia1 = []; datosPotencia2 = []; datosPotencia3 = []; datosPotencia4 = []; datosPotencia5 = []; datosPotencia6 = [];

        }

        var chart = new CanvasJS.Chart("chartContainer" + [contadorConsumo - 1], {
            height: 290,
            width: 500,
            backgroundColor: "rgba(22,22,22,0)",


            animationEnabled: true,
            title: {
                text: "Gráfico de Consumo Cups"
            },
            axisY: {
                includeZero: false,
                prefix: "W"
            },
            axisX: {
                minimum: 0,
                maximum: 11

            },
            toolTip: {
                shared: true
            },
            legend: {
                fontSize: 13
            },
            data: [{
                type: "splineArea",
                showInLegend: true,
                name: "P1",
                yValueFormatString: "W#,##0",
                dataPoints: ArrayPotencia1[0]
            }, {
                type: "splineArea",
                showInLegend: true,
                name: "P2",
                yValueFormatString: "W#,##0",
                dataPoints: ArrayPotencia2[0]
            }, {
                type: "splineArea",
                showInLegend: true,
                name: "P3",
                yValueFormatString: "W#,##0",
                dataPoints: ArrayPotencia3[0]
            }, {
                type: "splineArea",
                showInLegend: true,
                name: "P4",
                color: "black",
                yValueFormatString: "W#,##0",
                dataPoints: ArrayPotencia4[0]
            }, {
                type: "splineArea",
                showInLegend: true,
                name: "P5",
                yValueFormatString: "W#,##0",
                dataPoints: ArrayPotencia5[0]
            }, {
                type: "splineArea",
                showInLegend: true,
                name: "P6",
                yValueFormatString: "W#,##0",
                dataPoints: ArrayPotencia6[0]
            }]
        });

        chart.render();

    }

    $scope.TrasladarContrato = function (contrato) {
        if ($scope.VerEnergia === true && $scope.VerGas === false) { localStorage.setItem('tipoAlta', btoa('e')); }
        if ($scope.VerEnergia === false && $scope.VerGas === true) { localStorage.setItem('tipoAlta', btoa('g')); }
        $rootScope.AltaSips = contrato;
        $location.url("/contratos/alta");
    }

    $scope.MostrarDatosClick = function () {

        if (this.trPrincipal) {
            this.trPrincipal = false;
        } else {
            this.trPrincipal = true;
        }
    }

    $scope.exportarDatosSIPS = function (selected) {
        var myData = [];
        var consumos = [];

        selected.forEach(function (obj) {
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var tension = "";
            var consumosA = [];
            var consAnual = "";
            var consAnualp1 = "";
            var consAnualp2 = "";
            var consAnualp3 = "";
            var consAnualp4 = "";
            var consAnualp5 = "";
            var consAnualp6 = "";

            // if (selected.length == 1) {
            //     //consumoAnual = $scope.consumosAnuales;
            //     $scope.TextoFichero = cups + "_General.xls";
            // }

            // if (selected.length > 1) {
            //     $scope.TextoFichero = "DescargaMasiva_General.xls";

            // }

            consumosA = $scope.busquedaSips.map(function (e) {
                if (e.CUPS == cups) {
                    consAnual = e.ConsumoAnual;
                    consAnualp1 = e.ConsumoP1;
                    consAnualp2 = parseInt(e.ConsumoP2);
                    consAnualp3 = e.ConsumoP3;
                    consAnualp4 = e.ConsumoP4;
                    consAnualp5 = e.ConsumoP5;
                    consAnualp6 = e.ConsumoP6;
                }
            });

            switch (obj.CodigoTarifaATREnVigor) {
                case "001":
                    tarifa = "2.0A";
                    break;
                case "003":
                    tarifa = "3.0A";
                    break;
                case "004":
                    tarifa = "2.0DHA";
                    break;
                case "005":
                    tarifa = "2.1A";
                    break;
                case "006":
                    tarifa = "2.1DHA";
                    break;
                case "007":
                    tarifa = "2.0DHS";
                    break;
                case "008":
                    tarifa = "2.1DHS";
                    break;
                case "011":
                    tarifa = "3.1A";
                    break;
                case "012":
                    tarifa = "6.1A";
                    break;
                case "013":
                    tarifa = "6.2";
                    break;
                case "014":
                    tarifa = "6.3";
                    break;
                case "015":
                    tarifa = "6.4";
                    break;
                case "016":
                    tarifa = "6.5";
                    break;
                case "017":
                    tarifa = "6.1B";
                    break;
            }

            switch (obj.CodigoTensionV) {
                case "01":
                    tension = "1X220";
                    break;
                case "02":
                    tension = "1X230";
                    break;
                case "03":
                    tension = "3X380";
                    break;
                case "04":
                    tension = "3X380/220";
                    break;
                case "05":
                    tension = "3X400";
                    break;
                case "06":
                    tension = "3X400/230";
                    break;
                case "07":
                    tension = "1X127";
                    break;
                case "08":
                    tension = "1X133";
                    break;
                case "09":
                    tension = "2X220";
                    break;
                case "10":
                    tension = "2X230";
                    break;
                case "11":
                    tension = "3X220";
                    break;
                case "12":
                    tension = "3X220/127";
                    break;
                case "13":
                    tension = "3X230";
                    break;
                case "14":
                    tension = "3X230/133";
                    break;
                case "15":
                    tension = "5.000";
                    break;
                case "16":
                    tension = "6.000";
                    break;
                case "17":
                    tension = "7.200";
                    break;
                case "18":
                    tension = "8.000";
                    break;
                case "19":
                    tension = "10.000";
                    break;
                case "20":
                    tension = "11.000";
                    break;
                case "21":
                    tension = "12.000";
                    break;
                case "22":
                    tension = "13.000";
                    break;
                case "23":
                    tension = "13.200";
                    break;
                case "24":
                    tension = "15.000";
                    break;
                case "25":
                    tension = "16.500";
                    break;
                case "26":
                    tension = "17.000";
                    break;
                case "27":
                    tension = "20.000";
                    break;
                case "28":
                    tension = "22.000";
                    break;
                case "29":
                    tension = "25.000";
                    break;
                case "30":
                    tension = "26.500";
                    break;
                case "31":
                    tension = "30.000";
                    break;
                case "32":
                    tension = "36.000";
                    break;
                case "33":
                    tension = "44.000";
                    break;
                case "34":
                    tension = "45.000";
                    break;
                case "35":
                    tension = "66.000";
                    break;
                case "36":
                    tension = "110.000";
                    break;
                case "37":
                    tension = "132.000";
                    break;
                case "38":
                    tension = "220.000";
                    break;
                case "39":
                    tension = "400.000";
                    break;
                case "40":
                    tension = "1.200";
                    break;
                case "41":
                    tension = "2.000";
                    break;
                case "42":
                    tension = "5.500";
                    break;
                case "43":
                    tension = "55.000";
                    break;
                case "44":
                    tension = "130.000";
                    break;
                case "45":
                    tension = "100";
                    break;
                case "46":
                    tension = "150";
                    break;
                case "47":
                    tension = "3x100";
                    break;
                case "48":
                    tension = "3x150/260";
                    break;
                case "49":
                    tension = "3x260";
                    break;
            }

            myData.push({
                'Codigo CUPS': obj.CodigoCUPS,
                'Codigo Empresa Distribuidora': (obj.CodigoEmpresaDistribuidora != null) ? obj.CodigoEmpresaDistribuidora : '',
                'Nombre Empresa Distribuidora': (obj.NombreEmpresaDistribuidora != null) ? obj.NombreEmpresaDistribuidora : '',
                'Codigo Postal PS': (obj.CodigoPostalPS != null) ? obj.CodigoPostalPS : '',
                'Municipio PS': parseInt(obj.MunicipioPS),
                'Codigo Provincia PS': (obj.CodigoProvinciaPS != null) ? obj.CodigoProvinciaPS : '',
                'Tension': tension,
                'Tarifa': tarifa,
                'Fecha Alta Suministro': (obj.FechaAltaSuministro != null) ? obj.FechaAltaSuministro : '',
                'Codigo TensionV': (obj.CodigoTensionV != null) ? obj.CodigoTensionV : '',
                'Potencia Maxima BIEW': (obj.PotenciaMaximaBIEW != null) ? parseInt(obj.PotenciaMaximaBIEW) : '',
                'Potencia Maxima APMW': (obj.PotenciaMaximaAPMW != null) ? parseInt(obj.PotenciaMaximaAPMW) : '',
                'Codigo Clasificacion PS': (obj.CodigoClasificacionPS != null) ? obj.CodigoClasificacionPS : '',
                'Codigo Disponibilidad ICP': (obj.CodigoDisponibilidadICP != null) ? obj.CodigoDisponibilidadICP : '',
                'Tipo Perfil Consumo': (obj.TipoPerfilConsumo != null) ? obj.TipoPerfilConsumo : '',
                'Valor Derechos ExtensionW': parseInt(obj.ValorDerechosExtensionW),
                'Valor Derechos AccesoW': parseInt(obj.ValorDerechosAccesoW),
                'Codigo Propiedad EquipoMedida': (obj.CodigoPropiedadEquipoMedida != null) ? obj.CodigoPropiedadEquipoMedida : '',
                'Codigo Propiedad ICP': (obj.CodigoPropiedadICP != null) ? obj.CodigoPropiedadICP : '',
                'Consumo Anual': parseInt(consAnual),
                'Consumo Anual P1': parseInt(consAnualp1),
                'Consumo Anual P2': parseInt(consAnualp2),
                'Consumo Anual P3': parseInt(consAnualp3),
                'Consumo Anual P4': parseInt(consAnualp4),
                'Consumo Anual P5': parseInt(consAnualp5),
                'Consumo Anual P6': parseInt(consAnualp6),
                'Potencias Contratadas En W P1': (obj.PotenciasContratadasEnWP1 != null) ? parseFloat(obj.PotenciasContratadasEnWP1).toFixed(2).replace('.', ',') : '',
                'Potencias Contratadas En W P2': (obj.PotenciasContratadasEnWP2 != null) ? parseFloat(obj.PotenciasContratadasEnWP2).toFixed(2).replace('.', ',') : '',
                'Potencias Contratadas En W P3': (obj.PotenciasContratadasEnWP3 != null) ? parseFloat(obj.PotenciasContratadasEnWP3).toFixed(2).replace('.', ',') : '',
                'Potencias Contratadas En W P4': (obj.PotenciasContratadasEnWP4 != null) ? parseFloat(obj.PotenciasContratadasEnWP4).toFixed(2).replace('.', ',') : '',
                'Potencias Contratadas En W P5': (obj.PotenciasContratadasEnWP5 != null) ? parseFloat(obj.PotenciasContratadasEnWP5).toFixed(2).replace('.', ',') : '',
                'Potencias Contratadas En W P6': (obj.PotenciasContratadasEnWP6 != null) ? parseFloat(obj.PotenciasContratadasEnWP6).toFixed(2).replace('.', ',') : '',
                'Fecha Ultimo Movimiento Contrato': (obj.FechaUltimoMovimientoContrato != null) ? obj.FechaUltimoMovimientoContrato : '',
                'Fecha Ultimo Cambio Comercializador': (obj.FechaUltimoCambioComercializador != null) ? obj.FechaUltimoCambioComercializador : '',
                'Fecha Limite Derechos Reconocidos': (obj.FechaLimiteDerechosReconocidos != null) ? obj.FechaLimiteDerechosReconocidos : '',
                'Fecha Ultima Lectura': (obj.FechaUltimaLectura != null) ? obj.FechaUltimaLectura : '',
                'Informacion Impagos': (obj.InformacionImpagos != null) ? obj.InformacionImpagos : '',
                'Importe Deposito Garantia Euros': parseInt(obj.ImporteDepositoGarantiaEuros),
                'Tipo Id Titular': (obj.TipoIdTitular != null) ? obj.TipoIdTitular : '',
                'Es Vivienda Habitual': (obj.EsViviendaHabitual != null) ? obj.EsViviendaHabitual : '',
                'Codigo Comercializadora': (obj.CodigoComercializadora != null) ? obj.CodigoComercializadora : '',
                'Codigo Telegestion': (obj.CodigoTelegestion != null) ? obj.CodigoTelegestion : '',
                'Codigo Fases Equipo Medida': (obj.CodigoFasesEquipoMedida != null) ? obj.CodigoFasesEquipoMedida : '',
                'Codigo Autoconsumo': (obj.CodigoAutoconsumo != null) ? obj.CodigoAutoconsumo : '',
                'Codigo TipoContrato': (obj.CodigoTipoContrato != null) ? obj.CodigoTipoContrato : '',
                'Codigo Periodicidad Facturacion': (obj.CodigoPeriodicidadFacturacion != null) ? obj.CodigoPeriodicidadFacturacion : '',
                'Codigo BIE': (obj.CodigoBIE != null) ? obj.CodigoBIE : '',
                'Fecha Emision BIE': (obj.FechaEmisionBIE != null) ? obj.FechaEmisionBIE : '',
                'Fecha Caducidad BIE': (obj.FechaCaducidadBIE != null) ? obj.FechaCaducidadBIE : '',
                'Codigo APM': (obj.CodigoAPM != null) ? obj.CodigoAPM : '',
                'Fecha Emision APM': (obj.FechaEmisionAPM != null) ? obj.FechaEmisionAPM : '',
                'Fecha Caducidad APM': (obj.FechaCaducidadAPM != null) ? obj.FechaCaducidadAPM : '',
                'Relacion Transformacion Intensidad': (obj.RelacionTransformacionIntensidad != null) ? obj.RelacionTransformacionIntensidad : '',
                'CNAE': (obj.Cnae != null) ? obj.Cnae : '',
                'Codigo Modo Control Potencia': (obj.CodigoModoControlPotencia != null) ? obj.CodigoModoControlPotencia : '',
                'Potencia CGPW': (obj.PotenciaCGPW != null) ? obj.PotenciaCGPW : '',
                'Codigo DH Equipo De Medida': (obj.CodigoDHEquipoDeMedida != null) ? obj.CodigoDHEquipoDeMedida : '',
                'Codigo Accesibilidad Contador': (obj.CodigoAccesibilidadContador != null) ? obj.CodigoAccesibilidadContador : '',
                'Codigo PS Contratable': (obj.CodigoPSContratable != null) ? obj.CodigoPSContratable : '',
                'Motivo Estado No Contratable': (obj.MotivoEstadoNoContratable != null) ? obj.MotivoEstadoNoContratable : '',
                'Codigo Tension Medida': (obj.CodigoTensionMedida != null) ? obj.CodigoTensionMedida : '',
                'Codigo Clase Expediente': (obj.CodigoClaseExpediente != null) ? obj.CodigoClaseExpediente : '',
                'Codigo Motivo Expediente': (obj.CodigoMotivoExpediente != null) ? obj.CodigoMotivoExpediente : '',
                'Codigo Tipo Suministro': (obj.CodigoTipoSuministro != null) ? obj.CodigoTipoSuministro : '',
                'Aplicacion Bono Social': (obj.AplicacionBonoSocial != null) ? obj.AplicacionBonoSocial : '',
                'Descripcion Provincia PS': (obj.DesProvinciaPS != null) ? obj.DesProvinciaPS : '',
                'Descripcion Municipio PS': (obj.DesMunicipioPS != null) ? obj.DesMunicipioPS : '',
                'Tipo Via PS': (obj.TipoViaPS != null) ? obj.TipoViaPS : '',
                'Via PS': (obj.ViaPS != null) ? obj.ViaPS : '',
                'Num Finca PS': (obj.NumFincaPS != null) ? obj.NumFincaPS : '',
                'Portal PS': (obj.PortalPS != null) ? obj.PortalPS : '',
                'Escalera PS': (obj.EscaleraPS != null) ? obj.EscaleraPS : '',
                'Piso PS': (obj.PisoPS != null) ? obj.PisoPS : '',
                'Puerta PS': (obj.PuertaPS != null) ? obj.PuertaPS : '',
                'Codigo Presion': (obj.CodigoPresion != null) ? obj.CodigoPresion : '',
                'Codigo Peaje En Vigor': (obj.CodigoPeajeEnVigor != null) ? obj.CodigoPeajeEnVigor : '',
                'Caudal Maximo Diario En Wh': (obj.CaudalMaximoDiarioEnWh != null) ? obj.CaudalMaximoDiarioEnWh : '',
                'Caudal Horario En Wh': (obj.CaudalHorarioEnWh != null) ? obj.CaudalHorarioEnWh : '',
                'Derecho TUR': (obj.DerechoTUR != null) ? obj.DerechoTUR : '',
                'Fecha Ultima Inspeccion': (obj.FechaUltimaInspeccion != null) ? obj.FechaUltimaInspeccion : '',
                'Codigo Resultado Inspeccion': (obj.CodigoResultadoInspeccion != null) ? obj.CodigoResultadoInspeccion : '',
                'Codigo Contador': (obj.CodigoContador != null) ? obj.CodigoContador : '',
                'Calibre Contador': (obj.CalibreContador != null) ? obj.CalibreContador : '',
                'Tipo Contador': (obj.TipoContador != null) ? obj.TipoContador : '',
                'Propiedad Equipo Medida': (obj.PropiedadEquipoMedida != null) ? obj.PropiedadEquipoMedida : '',
                'Codigo Telemedida': (obj.CodigoTelemedida != null) ? obj.CodigoTelemedida : ''

            });

        });

        /**
         * Descargamos el excel limpio de arriba
         */

        $("#dvjson")
            .excelexportjs({
                containerid: "dvjson",
                datatype: 'json',
                dataset: myData,
                columns: getColumns(myData)
            });

    };

    $scope.exportarDatosSIPSGas = function (selected) {

        var myData = [];
        var consumos = [];

        $scope.busquedaSips.forEach(function (obj) {
            var cups = obj.CUPS;
            var tarifa = "";
            var presion = "";

            if (selected.length == 1) {
                $scope.TextoFichero = cups + "_General.xls";
            }

            if (selected.length > 1) {
                $scope.TextoFichero = "DescargaMasiva_General.xls";
            }

            switch (obj.CodigoPeajeEnVigor) {
                case "11":
                    tarifa = "1.1";
                    break;
                case "12":
                    tarifa = "1.2";
                    break;
                case "13":
                    tarifa = "1.3";
                    break;
                case "21":
                    tarifa = "2.1";
                    break;
                case "22":
                    tarifa = "2.2";
                    break;
                case "23":
                    tarifa = "2.3";
                    break;
                case "24":
                    tarifa = "2.4";
                    break;
                case "25":
                    tarifa = "2.5";
                    break;
                case "26":
                    tarifa = "2.6";
                    break;
                case "1B":
                    tarifa = "2.1 bis";
                    break;
                case "2B":
                    tarifa = "2.2 bis";
                    break;
                case "3B":
                    tarifa = "2.3 bis";
                    break;
                case "4B":
                    tarifa = "2.4 bis";
                    break;
                case "5B":
                    tarifa = "2.5 bis";
                    break;
                case "6B":
                    tarifa = "2.6 bis";
                    break;
                case "31":
                    tarifa = "3.1";
                    break;
                case "32":
                    tarifa = "3.2";
                    break;
                case "33":
                    tarifa = "3.3";
                    break;
                case "34":
                    tarifa = "3.4";
                    break;
                case "35":
                    tarifa = "3.5";
                    break;
                case "41":
                    tarifa = "4.1";
                    break;
                case "42":
                    tarifa = "4.2";
                    break;
                case "43":
                    tarifa = "4.3";
                    break;
                case "44":
                    tarifa = "4.4";
                    break;
                case "45":
                    tarifa = "4.5";
                    break;
                case "46":
                    tarifa = "4.6";
                    break;
                case "47":
                    tarifa = "4.7";
                    break;
                case "A1":
                    tarifa = "A1";
                    break;
                case "A2":
                    tarifa = "A2";
                    break;
                case "A3":
                    tarifa = "A3";
                    break;
                case "B1":
                    tarifa = "B1";
                    break;
                case "B2":
                    tarifa = "B2";
                    break;
                case "C1":
                    tarifa = "C1";
                    break;
                case "C2":
                    tarifa = "C2";
                    break;
                case "D1":
                    tarifa = "D1";
                    break;
                case "D2":
                    tarifa = "D2";
                    break;
                case "M1":
                    tarifa = "M1";
                    break;
                case "M2":
                    tarifa = "M2";
                    break;
                case "99":
                    tarifa = "99";
                    break;
            }

            var consumoAnual = 0;

            consumos = $scope.consumosSips.filter(function (n) { return n.CodigoCUPS == obj.CodigoCUPS; });
            consumos = consumos.filter(function (n) { return n != undefined; });

            if (consumos.length >= 12) {
                for (var i = consumos.length - 1; i >= consumos.length - 12; i--) {
                    consumoAnual += consumos[i].ConsumoEnWhP1;
                }
            } else {
                for (var z = 0; z < consumos.length; z++) {
                    consumoAnual += consumos[z].ConsumoEnWhP1;
                }
            }

            myData.push({
                CodigoCUPS: obj.CodigoCUPS,
                CodigoEmpresaDistribuidora: obj.CodigoEmpresaDistribuidora,
                NombreEmpresaDistribuidora: obj.NombreEmpresaDistribuidora,
                CodigoPostalPS: obj.CodigoPostalPS,
                MunicipioPS: obj.MunicipioPS,
                CodigoProvinciaPS: obj.CodigoProvinciaPS,
                t: tarifa,
                p: presion,
                consumoAnual: consumoAnual,
                FechaAltaSuministro: obj.FechaAltaSuministro,
                CodigoTarifaATREnVigor: obj.CodigoTarifaATREnVigor,
                CodigoTensionV: obj.CodigoTensionV,
                PotenciaMaximaBIEW: obj.PotenciaMaximaBIEW,
                PotenciaMaximaAPMW: obj.PotenciaMaximaAPMW,
                CodigoClasificacionPS: obj.CodigoClasificacionPS,
                CodigoDisponibilidadICP: obj.CodigoDisponibilidadICP,
                TipoPerfilConsumo: obj.TipoPerfilConsumo,
                ValorDerechosExtensionW: obj.ValorDerechosExtensionW,
                ValorDerechosAccesoW: obj.ValorDerechosAccesoW,
                CodigoPropiedadEquipoMedida: obj.CodigoPropiedadEquipoMedida,
                CodigoPropiedadICP: obj.CodigoPropiedadICP,
                PotenciasContratadasEnWP1: obj.PotenciasContratadasEnWP1,
                PotenciasContratadasEnWP2: obj.PotenciasContratadasEnWP2,
                PotenciasContratadasEnWP3: obj.PotenciasContratadasEnWP3,
                PotenciasContratadasEnWP4: obj.PotenciasContratadasEnWP4,
                PotenciasContratadasEnWP5: obj.PotenciasContratadasEnWP5,
                PotenciasContratadasEnWP6: obj.PotenciasContratadasEnWP6,
                FechaUltimoMovimientoContrato: obj.FechaUltimoMovimientoContrato,
                FechaUltimoCambioComercializador: obj.FechaUltimoCambioComercializador,
                FechaLimiteDerechosReconocidos: obj.FechaLimiteDerechosReconocidos,
                FechaUltimaLectura: obj.FechaUltimaLectura,
                InformacionImpagos: obj.InformacionImpagos,
                ImporteDepositoGarantiaEuros: obj.ImporteDepositoGarantiaEuros,
                TipoIdTitular: obj.TipoIdTitular,
                EsViviendaHabitual: obj.EsViviendaHabitual,
                CodigoComercializadora: obj.CodigoComercializadora,
                CodigoTelegestion: obj.CodigoTelegestion,
                CodigoFasesEquipoMedida: obj.CodigoFasesEquipoMedida,
                CodigoAutoconsumo: obj.CodigoAutoconsumo,
                CodigoTipoContrato: obj.CodigoTipoContrato,
                CodigoPeriodicidadFacturacion: obj.CodigoPeriodicidadFacturacion,
                CodigoBIE: obj.CodigoBIE,
                FechaEmisionBIE: obj.FechaEmisionBIE,
                FechaCaducidadBIE: obj.FechaCaducidadBIE,
                CodigoAPM: obj.CodigoAPM,
                FechaEmisionAPM: obj.FechaEmisionAPM,
                FechaCaducidadAPM: obj.FechaCaducidadAPM,
                RelacionTransformacionIntensidad: obj.RelacionTransformacionIntensidad,
                Cnae: obj.Cnae,
                CodigoModoControlPotencia: obj.CodigoModoControlPotencia,
                PotenciaCGPW: obj.PotenciaCGPW,
                CodigoDHEquipoDeMedida: obj.CodigoDHEquipoDeMedida,
                CodigoAccesibilidadContador: obj.CodigoAccesibilidadContador,
                CodigoPSContratable: obj.CodigoPSContratable,
                MotivoEstadoNoContratable: obj.MotivoEstadoNoContratable,
                CodigoTensionMedida: obj.CodigoTensionMedida,
                CodigoClaseExpediente: obj.CodigoClaseExpediente,
                CodigoMotivoExpediente: obj.CodigoMotivoExpediente,
                CodigoTipoSuministro: obj.CodigoTipoSuministro,
                AplicacionBonoSocial: obj.AplicacionBonoSocial,
                DesProvinciaPS: obj.DesProvinciaPS,
                DesMunicipioPS: obj.DesMunicipioPS,
                TipoViaPS: obj.TipoViaPS,
                ViaPS: obj.ViaPS,
                NumFincaPS: obj.NumFincaPS,
                PortalPS: obj.PortalPS,
                EscaleraPS: obj.EscaleraPS,
                PisoPS: obj.PisoPS,
                PuertaPS: obj.PuertaPS,
                CodigoPresion: obj.CodigoPresion,
                CodigoPeajeEnVigor: obj.CodigoPeajeEnVigor,
                CaudalMaximoDiarioEnWh: obj.CaudalMaximoDiarioEnWh,
                CaudalHorarioEnWh: obj.CaudalHorarioEnWh,
                DerechoTUR: obj.DerechoTUR,
                FechaUltimaInspeccion: obj.FechaUltimaInspeccion,
                CodigoResultadoInspeccion: obj.CodigoResultadoInspeccion,
                CodigoContador: obj.CodigoContador,
                CalibreContador: obj.CalibreContador,
                TipoContador: obj.TipoContador,
                PropiedadEquipoMedida: obj.PropiedadEquipoMedida,
                CodigoTelemedida: obj.CodigoTelemedida
            });

        });

        // return myData;
        /**
         * Descargamos el excel limpio de arriba
         */

        $("#dvjson")
            .excelexportjs({
                containerid: "dvjson",
                datatype: 'json',
                dataset: myData,
                columns: getColumns(myData)
            });
    };

    $scope.exportarDatosSIPSConsumos = function (selected) {

        var myData = [];
        var consumos = [];

        selected.forEach(function (obj) {
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var tension = "";

            // if (selected.length == 1) {
            //     $scope.TextoFicheroConsumos = cups + "_Consumos.xls";
            // }

            // if (selected.length > 1) {
            //     $scope.TextoFicheroConsumos = "DescargaMasiva_Consumos.xls";
            // }

            myData.push({
                'CUPS': obj.CodigoCUPS,
                'Fecha Lectura Inicial': "",
                'Fecha Lectura Final': "",
                'P1 Activa': "",
                'P2 Activa': "",
                'P3 Activa': "",
                'P4 Activa': "",
                'P5 Activa': "",
                'P6 Activa': "",
                'P1 Reactiva': "",
                'P2 Reactiva': "",
                'P3 Reactiva': "",
                'P4 Reactiva': "",
                'P5 Reactiva': "",
                'P6 Reactiva': "",
                'P1 Maximetro': "",
                'P2 Maximetro': "",
                'P3 Maximetro': "",
                'P4 Maximetro': "",
                'P5 Maximetro': "",
                'P6 Maximetro': ""
            });

            consumos = $scope.consumosSips.map(function (obj) {
                if (obj.CodigoCUPS == cups) {
                    if (obj.FechaInicio == null && obj.FechaFin == null) { } else {
                        return {
                            'CUPS': "",
                            'Fecha Lectura Inicial': obj.FechaInicio,
                            'Fecha Lectura Final': obj.FechaFin,
                            // parseFloat(obj.PotenciasContratadasEnWP1).toFixed(2).replace('.',',')
                            'P1 Activa': (obj.Activa1 == null) ? "0" : parseFloat(obj.Activa1).toFixed(3).replace('.', ','),
                            'P2 Activa': (obj.Activa2 == null) ? "0" : parseFloat(obj.Activa2).toFixed(3).replace('.', ','),
                            'P3 Activa': (obj.Activa3 == null) ? "0" : parseFloat(obj.Activa3).toFixed(3).replace('.', ','),
                            'P4 Activa': (obj.Activa4 == null) ? "0" : parseFloat(obj.Activa4).toFixed(3).replace('.', ','),
                            'P5 Activa': (obj.Activa5 == null) ? "0" : parseFloat(obj.Activa5).toFixed(3).replace('.', ','),
                            'P6 Activa': (obj.Activa6 == null) ? "0" : parseFloat(obj.Activa6).toFixed(3).replace('.', ','),
                            'P1 Reactiva': (obj.Reactiva1 == null) ? "0" : parseFloat(obj.Reactiva1).toFixed(3).replace('.', ','),
                            'P2 Reactiva': (obj.Reactiva2 == null) ? "0" : parseFloat(obj.Reactiva2).toFixed(3).replace('.', ','),
                            'P3 Reactiva': (obj.Reactiva3 == null) ? "0" : parseFloat(obj.Reactiva3).toFixed(3).replace('.', ','),
                            'P4 Reactiva': (obj.Reactiva4 == null) ? "0" : parseFloat(obj.Reactiva4).toFixed(3).replace('.', ','),
                            'P5 Reactiva': (obj.Reactiva5 == null) ? "0" : parseFloat(obj.Reactiva5).toFixed(3).replace('.', ','),
                            'P6 Reactiva': (obj.Reactiva6 == null) ? "0" : parseFloat(obj.Reactiva6).toFixed(3).replace('.', ','),
                            'P1 Maximetro': (obj.Potencia1 == null) ? "0" : parseFloat(obj.Potencia1).toFixed(3).replace('.', ','),
                            'P2 Maximetro': (obj.Potencia2 == null) ? "0" : parseFloat(obj.Potencia2).toFixed(3).replace('.', ','),
                            'P3 Maximetro': (obj.Potencia3 == null) ? "0" : parseFloat(obj.Potencia3).toFixed(3).replace('.', ','),
                            'P4 Maximetro': (obj.Potencia4 == null) ? "0" : parseFloat(obj.Potencia4).toFixed(3).replace('.', ','),
                            'P5 Maximetro': (obj.Potencia5 == null) ? "0" : parseFloat(obj.Potencia5).toFixed(3).replace('.', ','),
                            'P6 Maximetro': (obj.Potencia6 == null) ? "0" : parseFloat(obj.Potencia6).toFixed(3).replace('.', ',')
                        };
                    }
                }
            });

            consumos = consumos.filter(function (n) { return n != undefined; });

            myData.push.apply(myData, consumos);
        });

        // return myData;
        /**
         * Descargamos el excel limpio de arriba
         */

        $("#dvjson")
            .excelexportjs({
                containerid: "dvjson",
                datatype: 'json',
                dataset: myData,
                columns: getColumns(myData)
            });
    };

    $scope.exportarDatosSIPSGasConsumos = function (selected) {

        var myData = [];
        var consumos = [];

        selected.forEach(function (obj) {
            var cups = obj.CodigoCUPS;
            var tarifa = "";
            var tension = "";

            if (selected.length == 1) {
                $scope.TextoFicheroConsumos = cups + "_Consumos.xls";
            }

            if (selected.length > 1) {
                $scope.TextoFicheroConsumos = "DescargaMasiva_Consumos.xls";
            }


            myData.push({
                CUPS: obj.CodigoCUPS,
                FechaInicioMesConsumo: "",
                FechaFinMesConsumo: "",
                ConsumoEnWhP1: "",
                CaudalMaximoDiario: "",
                CaudalMedioEnWhDia: "",
                CaudalMinimoDiario: "",
                TarifaPeaje: "",
                p1reactiva: "",
                p2reactiva: "",
                p3reactiva: "",
                p4reactiva: "",
                p5reactiva: "",
                p6reactiva: "",
                p1maximetro: "",
                p2maximetro: "",
                p3maximetro: "",
                p4maximetro: "",
                p5maximetro: "",
                p6maximetro: ""
            });

            consumos = $scope.consumosSips.map(function (obj) {
                if (obj.CodigoCUPS == cups) {
                    if (obj.FechaInicioMesConsumo == null && obj.FechaFinMesConsumo == null) { } else {
                        // console.log(obj);
                        return {
                            CUPS: "",
                            FechaInicioMesConsumo: obj.FechaInicioMesConsumo,
                            FechaFinMesConsumo: obj.FechaFinMesConsumo,
                            ConsumoEnWhP1: (obj.ConsumoEnWhP1 == null ? "0" : obj.ConsumoEnWhP1),
                            CaudalMaximoDiario: (obj.CaudalMaximoDiario == null ? "0" : obj.CaudalMaximoDiario),
                            CaudalMedioEnWhDia: (obj.CaudalMedioEnWhDia == null ? "0" : obj.CaudalMedioEnWhDia),
                            CaudalMinimoDiario: (obj.CaudalMinimoDiario == null ? "0" : obj.CaudalMinimoDiario),
                            TarifaPeaje: obj.CodigoTarifaPeaje.toString().replace(/(\d)(?=(\d)+(?!\d))/g, "$1.")
                        };
                    }
                }
            });

            consumos = consumos.filter(function (n) { return n != undefined; });

            myData.push.apply(myData, consumos);
        });

        // return myData;
        /**
         * Descargamos el excel limpio de arriba
         */

        $("#dvjson")
            .excelexportjs({
                containerid: "dvjson",
                datatype: 'json',
                dataset: myData,
                columns: getColumns(myData)
            });
    };

    $scope.exportarDatosSIPSBoton = function (data) {
        var myData = [];
        var tarifa = "";
        var tension = "";
        $scope.TextoFichero = data.CUPS + "_General.xls";

        switch (data.Tarifa) {
            case "001":
                tarifa = "2.0A";
                break;
            case "003":
                tarifa = "3.0A";
                break;
            case "004":
                tarifa = "2.0DHA";
                break;
            case "005":
                tarifa = "2.1A";
                break;
            case "006":
                tarifa = "2.1DHA";
                break;
            case "007":
                tarifa = "2.0DHS";
                break;
            case "008":
                tarifa = "2.1DHS";
                break;
            case "011":
                tarifa = "3.1A";
                break;
            case "012":
                tarifa = "6.1A";
                break;
            case "013":
                tarifa = "6.2";
                break;
            case "014":
                tarifa = "6.3";
                break;
            case "015":
                tarifa = "6.4";
                break;
            case "016":
                tarifa = "6.5";
                break;
            case "017":
                tarifa = "6.1B";
                break;
            case "018":
                tarifa = "2.0TD";
                break;
            case "019":
                tarifa = "3.0TD";
                break;
            case "020":
                tarifa = "6.1TD";
                break;
            case "021":
                tarifa = "6.2TD";
                break;
            case "022":
                tarifa = "6.3TD";
                break;
            case "023":
                tarifa = "6.4TD";
                break;
        }

        switch (data.CodigoTensionV) {
            case "01":
                tension = "1X220";
                break;
            case "02":
                tension = "1X230";
                break;
            case "03":
                tension = "3X380";
                break;
            case "04":
                tension = "3X380/220";
                break;
            case "05":
                tension = "3X400";
                break;
            case "06":
                tension = "3X400/230";
                break;
            case "07":
                tension = "1X127";
                break;
            case "08":
                tension = "1X133";
                break;
            case "09":
                tension = "2X220";
                break;
            case "10":
                tension = "2X230";
                break;
            case "11":
                tension = "3X220";
                break;
            case "12":
                tension = "3X220/127";
                break;
            case "13":
                tension = "3X230";
                break;
            case "14":
                tension = "3X230/133";
                break;
            case "15":
                tension = "5.000";
                break;
            case "16":
                tension = "6.000";
                break;
            case "17":
                tension = "7.200";
                break;
            case "18":
                tension = "8.000";
                break;
            case "19":
                tension = "10.000";
                break;
            case "20":
                tension = "11.000";
                break;
            case "21":
                tension = "12.000";
                break;
            case "22":
                tension = "13.000";
                break;
            case "23":
                tension = "13.200";
                break;
            case "24":
                tension = "15.000";
                break;
            case "25":
                tension = "16.500";
                break;
            case "26":
                tension = "17.000";
                break;
            case "27":
                tension = "20.000";
                break;
            case "28":
                tension = "22.000";
                break;
            case "29":
                tension = "25.000";
                break;
            case "30":
                tension = "26.500";
                break;
            case "31":
                tension = "30.000";
                break;
            case "32":
                tension = "36.000";
                break;
            case "33":
                tension = "44.000";
                break;
            case "34":
                tension = "45.000";
                break;
            case "35":
                tension = "66.000";
                break;
            case "36":
                tension = "110.000";
                break;
            case "37":
                tension = "132.000";
                break;
            case "38":
                tension = "220.000";
                break;
            case "39":
                tension = "400.000";
                break;
            case "40":
                tension = "1.200";
                break;
            case "41":
                tension = "2.000";
                break;
            case "42":
                tension = "5.500";
                break;
            case "43":
                tension = "55.000";
                break;
            case "44":
                tension = "130.000";
                break;
            case "45":
                tension = "100";
                break;
            case "46":
                tension = "150";
                break;
            case "47":
                tension = "3x100";
                break;
            case "48":
                tension = "3x150/260";
                break;
            case "49":
                tension = "3x260";
                break;
        }

        myData.push({
            CodigoCUPS: data.CUPS,
            DireccionSuministro: data.DireccionSuministro,
            Municipio: data.Municipio,
            Provincia: data.Provincia,
            CodigoPostal: data.CodigoPostal,
            Tarifa: tarifa,
            ConsumoAnual: data.ConsumoAnual,
            ConsumoAnualP1: data.ConsumoP1,
            ConsumoAnualP2: data.ConsumoP2,
            ConsumoAnualP3: data.ConsumoP3,
            ConsumoAnualP4: data.ConsumoP4,
            ConsumoAnualP5: data.ConsumoP5,
            ConsumoAnualP6: data.ConsumoP6,
            P1: data.PotenciasWP1,
            P2: data.PotenciasWP2,
            P3: data.PotenciasWP3,
            P4: data.PotenciasWP4,
            P5: data.PotenciasWP5,
            P6: data.PotenciasWP6,
            Distribuidora: data.Distribuidora,
            UltCambioComer: data.FechaUltimoCambioComercializador,
            UltCambioBIE: data.FechaEmisionBIE,
            Tension: tension,
            UltCambioCont: data.FechaUltimoMovimientoContrato

        });
        return myData;

    }

    $scope.seleccionaTodos = function () {
        $scope.clientesSips.forEach(function (item) {
            $scope.selected.push(item);
        });
    }

    $scope.deseleccionaTodos = function () {
        $scope.selected = [];
    }

}]);