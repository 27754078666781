app.controller('ComunicadosCtrl', ['$scope', '$location', '$http', '$mdDialog', 'jwtHelper', '$state', 'serviciosApi', function($scope, $location, $http, $mdDialog, jwtHelper, $state, serviciosApi) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdEmpresa = localStorage.getItem('idEmpresa');

    $scope.MostrarComunicadosIdComunicado = MostrarComunicadosIdComunicado;
    $scope.MostrarComunicadosContrato = MostrarComunicadosContrato;
    $scope.MostrarComunicadosFechaBackOffice = MostrarComunicadosFechaBackOffice;
    $scope.MostrarComunicadosNombre = MostrarComunicadosNombre;
    $scope.MostrarComunicadosCanal = MostrarComunicadosCanal;
    $scope.MostrarComunicadosSubagente = MostrarComunicadosSubagente;
    $scope.MostrarComunicadosCUPS = MostrarComunicadosCUPS;
    $scope.MostrarComunicadosUsuario = MostrarComunicadosUsuario;
    $scope.MostrarComunicadosFechaRegistro = MostrarComunicadosFechaRegistro;
    $scope.MostrarComunicadosEstadobackoffice = MostrarComunicadosEstadobackoffice;
    $scope.MostrarComunicadosCategoria = MostrarComunicadosCategoria;
    $scope.MostrarComunicadosSubcategoria = MostrarComunicadosSubcategoria;
    $scope.OcultarNombresIncidenciaSige = OcultarNombresIncidenciaSige;
    $scope.OrdenarFechasIncidencias = OrdenarFechasIncidencias;

    $scope.currentPage = 1;


    $scope.nuevacomunicado = function(mostrarNuevacomunicado) {
        if (mostrarNuevacomunicado == undefined)
            mostrarNuevacomunicado = false;
        $scope.mostrarNuevacomunicado = !mostrarNuevacomunicado;
    };

	$scope.seleccionaTodos = function(){
		$scope.selected = [];
		$scope.comunicadosFound.forEach(function (item) {
			$scope.selected.push(item);
		});
	}
	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

	$scope.recargarDatos =  function(){

		serviciosApi.getListadoComunicados()
		.then(function(response) {
			var respuesta = response.data;
			var decodeToken = jwtHelper.decodeToken(respuesta);
			var playloadJson = JSON.parse(decodeToken.iss);
			var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]	});

			var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
			if (tokenValido && !tokenExpirado) {
                $scope.datatableComunicados.data = angular.copy(playloadJson);

                $scope.datatableComunicados.data.forEach(function callback(itemComunicado, indexComunicado, arrayComunicado) {

                    var numeroAcciones = 0;
                    var acciones = itemComunicado.ListadoAcciones;

                    // Recorremos las acciones del comunicado
                    acciones.forEach(function callback(accion, indexAccion, arrayAccion) {
                        if(accion.Estado == 'Abierto')
                            numeroAcciones++;
                    });

                    itemComunicado.accionesAbiertas = numeroAcciones;

                });



                // console.log($scope.datatableComunicados);
			} else {
				$scope.comunicados = angular.copy("");
			}

		});
	}

    $scope.sendContrato = function(datos) {
        // console.log(datos);
        localStorage.setItem("numeroPoliza", btoa(datos.comunicado.IdContrato));
        // localStorage.setItem("idCliente", btoa(datos.contrato.IdCliente));
        // localStorage.setItem("codigoContrato", btoa(datos.comunicado.CodigoContrato));
        $location.url("/contratos/resumen");
    };


    $scope.showAlert = function(ev) {
        $mdDialog.show($mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("")
            .htmlContent('<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Incidencia insertada correctamente.</p>')
            .ariaLabel("Incidencia Insertada")
            .ok("Aceptar")
            .targetEvent(ev));
    };

	// $http({
	// 	method: "GET",
	// 	url: urlApi + "CallCenter/GetAllMedios/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
	// 	headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
	// }).then(function successCallback(response) {
	// 	var resp = response.data;
	// 	var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
	// 	var tokenExpirado = jwtHelper.isTokenExpired(resp);

	// 	if (tokenValido && !tokenExpirado) {
	// 		var token = jwtHelper.decodeToken(resp);
	// 		var json = JSON.parse(token.iss);
	// 		$scope.Medios = json;
	// 	} else {
	// 		$scope.Medios = "";
	// 	}
	// });

    // $scope.Insertarcomunicado = function() {
    //     var idContrato = $scope.selectedItem.IdContrato;
    //     var codigoContrato = $scope.selectedItem.CodigoContrato;
    //     var idCliente = $scope.selectedItem.IdCliente;
    //     var medio = $scope.Medio;
    //     var descripcion = document.getElementsByName("descripcion")[0].value;

    //     var today = new Date();
    //     var dd = today.getDate();
    //     var mm = today.getMonth() + 1; //January is 0!
    //     var yyyy = today.getFullYear();

    //     if (dd < 10) {
    //         dd = '0' + dd;
    //     }

    //     if (mm < 10) {
    //         mm = '0' + mm;
    //     }

    //     today = yyyy + '-' + mm + '-' + dd;

    //     $http({
    //         method: "GET",
    //         url: urlApi + "CallCenter/InsertDatosCallCenter/?Contratacion_CallsCentersAPIDTO={'IdEmpresa':'" + IdEmpresa + "','IdUsuario':'" + IdUsuario + "','CallCenter':[{'IdCliente':'" + idCliente + "','CodigoContrato':'" + codigoContrato + "','IdUsuario':'" + IdUsuario + "','Fecha':'" + today + "','TextoComunicado':'" + descripcion + "','Medio':'" + medio + "'}]}",
    //         headers: {
    //             Authorization: atob(localStorage.getItem('TokenValidador'))
    //         }
    //     }).then(function successCallback(response) {
    //         $scope.showAlert();
    //         $state.go("comunicados", {}, { reload: true });
    //     });
    // };

    $scope.autocolumn = [
		{
			name: "IdIncidencia",
			display: "Expediente",
            oculta: "IdComunicado"

		}, {
            name: "Estadobackoffice",
			display: "Estado back office",
			oculta: "Estadobackoffice"
		}, {
			name: "CodigoContrato",
			display: "Codigo Contrato",
			oculta: "Contrato"
		}, {
			name: "Canal",
			display: "Canal",
			oculta: "Canal"
		}, {
			name: "Subagente",
			display: "SubAgente",
			oculta: "Subagente"
		}, {
			name: "Nombre",
			display: "Nombre",
			oculta: "Nombre"
		}, {
			name: "CodigoCUPS",
			display: "CUPS",
			oculta: "CUPS"
		}, {
			name: "Usuario",
			display: "Usuario",
			oculta: "Usuario"
		}, {
			name: "FechaHoraRegistro",
			display: "FechaRegistro",
			oculta: "FechaRegistro"
		}, {
			name: "FechaBackOffice",
			display: "FechaBackOffice",
			oculta: "FechaBackOffice"
		}, {
			name: "Categoria",
			display: "Categoria",
			oculta: "Categoria"
		}, {
			name: "Subcategoria",
			display: "Subcategoria",
			oculta: "Subcategoria"
		}
	];
	if ($scope.OrdenarFechasIncidencias == true) {
		$scope.autocolumn = [
			{
				name: "IdIncidencia",
				display: "Expediente",
				oculta: "IdComunicado"

			}, {
				name: "Estadobackoffice",
				display: "Estado back office",
				oculta: "Estadobackoffice"
			}, {
				name: "CodigoContrato",
				display: "Codigo Contrato",
				oculta: "Contrato"
			}, {
				name: "Canal",
				display: "Canal",
				oculta: "Canal"
			}, {
				name: "Subagente",
				display: "SubAgente",
				oculta: "Subagente"
			}, {
				name: "Nombre",
				display: "Nombre",
				oculta: "Nombre"
			}, {
				name: "CodigoCUPS",
				display: "CUPS",
				oculta: "CUPS"
			}, {
				name: "Usuario",
				display: "Usuario",
				oculta: "Usuario"
			}, {
				name: "FechaHoraRegistroFormat",
				display: "FechaRegistro",
				oculta: "FechaRegistro"
			}, {
				name: "FechaBackOfficeFormat",
				display: "FechaBackOffice",
				oculta: "FechaBackOffice"
			}, {
				name: "Categoria",
				display: "Categoria",
				oculta: "Categoria"
			}, {
				name: "Subcategoria",
				display: "Subcategoria",
				oculta: "Subcategoria"
			}
		];
	}
    $scope.selected = [];

    $scope.exportarDatosComunicados = function(selected) {
		var comunicados = [];
		/**
		 * Se filtran aqui, si los campos estan en la vista, los añadimos aqui y aparecen en la descarga.
		 */

        selected.forEach(function(obj) {
			if ($scope.OcultarNombresIncidenciaSige) {
				if($scope.MostrarComunicadosCanal && $scope.MostrarComunicadosSubagente){
					comunicados.push({
						'Codigo Contrato': obj.CodigoContrato,
						'Canal': obj.Canal,
						'SubAgente': obj.SubAgente,
						'Nombre': obj.Nombre,
						'EstadoBackoffice': obj.Estadobackoffice,
						'CodigoCUPS': obj.CodigoCUPS,
						'FechaHoraRegistro': obj.FechaHoraRegistro,
						'FechaBackoffice': obj.FechaBackOffice,
						'Categoria': obj.ListadoAcciones[0].Categoria,
						'Subcategoria': obj.ListadoAcciones[0].Subcategoria
					});
				} else {
					comunicados.push({
						'Codigo Contrato': obj.CodigoContrato,
						'Nombre': obj.Nombre,
						'EstadoBackoffice': obj.Estadobackoffice,
						'CodigoCUPS': obj.CodigoCUPS,
						'FechaHoraRegistro': obj.FechaHoraRegistro,
						'FechaBackoffice': obj.FechaBackOffice,
						'Categoria': obj.ListadoAcciones[0].Categoria,
						'Subcategoria': obj.ListadoAcciones[0].Subcategoria
					});
				}
			} else {
				if($scope.MostrarComunicadosCanal && $scope.MostrarComunicadosSubagente){
					comunicados.push({
						'Codigo Contrato': obj.CodigoContrato,
						'Canal': obj.Canal,
						'SubAgente': obj.SubAgente,
						'Nombre': obj.Nombre,
						'EstadoBackoffice': obj.EstadoBackoffice,
						'CodigoCUPS': obj.CodigoCUPS,
						'Usuario': obj.Usuario,
						'FechaHoraRegistro': obj.FechaHoraRegistro,
						'FechaBackoffice': obj.FechaBackoffice,
						'Categoria': obj.Categoria,
						'Subcategoria': obj.Subcategoria
					});
				} else {
					comunicados.push({
						'Codigo Contrato': obj.CodigoContrato,
						'Nombre': obj.Nombre,
						'EstadoBackoffice': obj.EstadoBackoffice,
						'CodigoCUPS': obj.CodigoCUPS,
						'Usuario': obj.Usuario,
						'FechaHoraRegistro': obj.FechaHoraRegistro,
						'FechaBackoffice': obj.FechaBackoffice,
						'Categoria': obj.Categoria,
						'Subcategoria': obj.Subcategoria
					});
				}
			}
		});

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: comunicados,
          columns: getColumns(comunicados)
        });
    };

    // $scope.obtenerContratos = function(text) {
    //     if ($scope.contratos) {
    //         text = text.toUpperCase();
    //         var ret = $scope.contratos.filter(function(d) {
    //             return d.CodigoContrato.toString().includes(text) || d.Denominacion.toString().includes(text);
    //         });
    //         return ret;
    //     } else {
    //         return "";
    //     }
    // };

    // Cuando venimos de otra paginacion
    $scope.$watch('query.filter', function(val) {
        $scope.query.page = 1;
    });

}]);