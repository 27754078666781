app.controller('IncidenciasCtrl', ['$scope', '$location', '$http', '$mdDialog', 'jwtHelper', '$state', 'serviciosApi', function($scope, $location, $http, $mdDialog, jwtHelper, $state, serviciosApi) {
    'use strict';

    var IdUsuario = localStorage.getItem('idUsuario');
    var IdEmpresa = localStorage.getItem('idEmpresa');

    $scope.MostrarIncidenciasDestino = MostrarIncidenciasDestino;
    $scope.MostrarIncidenciasFecha = MostrarIncidenciasFecha;
    $scope.MostrarIncidenciasFechaCierre = MostrarIncidenciasFechaCierre;
    $scope.MostrarIncidenciasIncidencia = MostrarIncidenciasIncidencia;
    $scope.MostrarIncidenciasSolucion = MostrarIncidenciasSolucion;
    $scope.MostrarIncidenciasMedio = MostrarIncidenciasMedio;
    $scope.MostrarIncidenciaSelect = MostrarIncidenciaSelect;
    $scope.MostrarIncidenciaInput = MostrarIncidenciaInput;
    $scope.MostrarIncidenciasCodigoContrato = MostrarIncidenciasCodigoContrato;

    $scope.cambioNombreMenuIncidenciasXComunicacion = cambioNombreMenuIncidenciasXComunicacion;
    $scope.cambioNombreMenuIncidenciasXReclamaciones = cambioNombreMenuIncidenciasXReclamaciones;
    $scope.OcultarNombresIncidenciaSige = OcultarNombresIncidenciaSige;
    $scope.MostrarNuevaIncidencia = MostrarNuevaIncidencia;

    $scope.nuevaIncidencia = function(mostrarNuevaIncidencia) {
        if (mostrarNuevaIncidencia == undefined)
            mostrarNuevaIncidencia = false;
        $scope.mostrarNuevaIncidencia = !mostrarNuevaIncidencia;
    };

	$scope.seleccionaTodos = function(){
		$scope.selected = [];
		$scope.incidencias.forEach(function (item) {
			$scope.selected.push(item);
		});
	}
	$scope.deseleccionaTodos =  function(){
		$scope.selected = [];
	}

	$scope.recargarDatos =  function(){

		serviciosApi.getListadoIncidencias()
		.then(function(response) {
			var respuesta = response.data;
			var decodeToken = jwtHelper.decodeToken(respuesta);
			var playloadJson = JSON.parse(decodeToken.iss);
			var tokenValido = KJUR.jws.JWS.verify(respuesta, tokenKey, { alg: ["HS256"]	});

			var tokenExpirado = jwtHelper.isTokenExpired(respuesta); //sabemos si esta expirado o no
			if (tokenValido && !tokenExpirado) {
				$scope.incidencias = angular.copy(playloadJson);
			} else {
				$scope.incidencias = angular.copy("");
			}

		});
	}

    $scope.sendContrato = function(datos) {
        localStorage.setItem("numeroPoliza", btoa(datos.incidencia.IdContrato));
        $location.url("/contratos/resumen");
    };

    $scope.showAlert = function(ev) {
        $mdDialog.show($mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("")
            .htmlContent(($scope.cambioNombreMenuIncidenciasXComunicacion) ? '<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Comunicado correctamente.</p>' : '<i class="fa fa-check-circle" aria-hidden="true"></i> <p class="centrado">Incidencia insertada correctamente.</p>')
            .ariaLabel(($scope.cambioNombreMenuIncidenciasXComunicacion) ? "Comunicado insertado" : "Incidencia Insertada")
            .ok("Aceptar")
            .targetEvent(ev));
    };

	$http({
		method: "GET",
		url: urlApi + "CallCenter/GetAllMedios/Empresa/" + IdEmpresa + "/Usuario/" + IdUsuario,
		headers: { Authorization: atob(localStorage.getItem('TokenValidador')) }
	}).then(function successCallback(response) {
		var resp = response.data;
		var tokenValido = KJUR.jws.JWS.verify(resp, tokenKey, { alg: ["HS256"] });
		var tokenExpirado = jwtHelper.isTokenExpired(resp);

		if (tokenValido && !tokenExpirado) {
			var token = jwtHelper.decodeToken(resp);
			var json = JSON.parse(token.iss);
			$scope.Medios = json;
		} else {
			$scope.Medios = "";
		}
	});

    $scope.InsertarIncidencia = function() {
        var idContrato = $scope.selectedItem.IdContrato;
        var codigoContrato = $scope.selectedItem.CodigoContrato;
        var idCliente = $scope.selectedItem.IdCliente;
        var medio = $scope.Medio;
        var descripcion = document.getElementsByName("descripcion")[0].value;

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = yyyy + '-' + mm + '-' + dd;

        $http({
            method: "GET",
            url: urlApi + "CallCenter/InsertDatosCallCenter/?Contratacion_CallsCentersAPIDTO={'IdEmpresa':'" + IdEmpresa + "','IdUsuario':'" + IdUsuario + "','CallCenter':[{'IdCliente':'" + idCliente + "','CodigoContrato':'" + codigoContrato + "','IdUsuario':'" + IdUsuario + "','Fecha':'" + today + "','TextoIncidencia':'" + descripcion + "','Medio':'" + medio + "'}]}",
            headers: {
                Authorization: atob(localStorage.getItem('TokenValidador'))
            }
        }).then(function successCallback(response) {
            $scope.showAlert();
            $state.go("incidencias", {}, { reload: true });
        });
    };
	$scope.termino = 'Incidencia';
	if($scope.cambioNombreMenuIncidenciasXComunicacion){
		$scope.termino = 'Comunicado';
	}
	if($scope.cambioNombreMenuIncidenciasXReclamaciones){
		$scope.termino = 'Reclamacion';
	}
    $scope.autocolumn = [
		{
			name: "CodigoContrato",
			display: "Codigo Contrato",
			oculta: "CodigoContrato"
		}, {
			name: "Destino",
			display: "Destino",
			oculta: "Destino"
		}, {
			name: "Fecha",
			display: "Fecha Alta",
			oculta: "Fecha"
		}, {
			name: "FechaCierre",
			display: "Fecha Cierre",
			oculta: "FechaCierre"
		}, {
			name: "TextoIncidencia",
			display: $scope.termino,
			oculta: "Incidencia"
		}, {
			name: "TextoSolucion",
			display: "Solucion",
			oculta: "Solucion"
		}, {
			name: "TextoMedio",
			display: "Medio",
			oculta: "Medio"
		}
	];

    $scope.selected = [];

    $scope.exportarDatosIncidencias = function(selected) {
		var incidencias = [];

		/**
		 * Se filtran aqui, si los campos estan en la vista, los añadimos aqui y aparecen en la descarga.
		 */

        selected.forEach(function(obj) {
			if($scope.OcultarNombresIncidenciaSige) {
				incidencias.push({
					'Codigo Contrato': obj.CodigoContrato,
					'Fecha Alta': obj.Fecha,
					'Fecha Cierre': obj.FechaCierre,
					'Mensaje': obj.TextoIncidencia,
					'Solucion': obj.TextoSolucion,
					'Medio': obj.TextoMedio
				});
			} else {
				incidencias.push({
					'Codigo Contrato': obj.CodigoContrato,
					'Destino': obj.Destino,
					'Fecha Alta': obj.Fecha,
					'Fecha Cierre': obj.FechaCierre,
					'Mensaje': obj.TextoIncidencia,
					'Solucion': obj.TextoSolucion,
					'Medio': obj.TextoMedio
				});
			}

		});

		/**
		 * Descargamos el excel limpio de arriba
		 */

		$("#dvjson")
        .excelexportjs({
          containerid: "dvjson",
          datatype: 'json',
          dataset: incidencias,
          columns: getColumns(incidencias)
        });
    };

    $scope.obtenerContratos = function(text) {
        if ($scope.contratos) {
            text = text.toUpperCase();
            var ret = $scope.contratos.filter(function(d) {
                return d.CodigoContrato.toString().includes(text) || d.Denominacion.toString().includes(text);
            });
            return ret;
        } else {
            return "";
        }
    };

}]);